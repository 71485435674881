import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FiEdit, FiTrash } from 'react-icons/fi';

import api from '../../../services/api';
import { ITipoUsuario, IUsuario } from '../index';
import getResponseErrors from '../../../utils/getResponseErrors';

import { useToast } from '../../../hooks/toast';

import Modal from '../../../components/Modal';
import UsuarioCadastro from '../UsuarioCadastro';
import { formataTelefone } from '../../../utils/funcoes';

interface componentProps {
  usuario: IUsuario;
  tipoUsuario: ITipoUsuario;
  ehAdmin: boolean;
}

const Linha: React.FC<componentProps> = ({ usuario, ehAdmin, tipoUsuario }) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [usuarioLocal, setUsuarioLocal] = useState<IUsuario>(usuario);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<
    number | undefined
  >(undefined);

  const handleDeleteUsuario = useCallback(
    async (codigo: number) => {
      setLoading(true);
      try {
        await api.delete(`/users/${codigo}`);
        // setUsuarios(state => state?.filter(usu => usu.id !== codigo));
        addToast({
          type: 'success',
          title: 'Registro excluído',
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);

        addToast({
          type: 'error',
          title: 'Não foi possível excluir o registro',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleAtualizouUsuario = useCallback((dados: IUsuario) => {
    setUsuarioLocal(state => {
      return {
        ...state,
        ...dados,
        telefoneFormatado: formataTelefone(dados.telefone),
      };
    });
    setShowModalCadastro(false);
  }, []);

  return (
    <>
      {showModalCadastro && (usuarioSelecionado || usuarioSelecionado === 0) && (
        <Modal
          show={showModalCadastro}
          titulo={`Cadastro de ${tipoUsuario.label}`}
          large
          handleFecharModal={() => {
            setShowModalCadastro(false);
            setUsuarioSelecionado(undefined);
          }}
        >
          <UsuarioCadastro
            id={usuarioSelecionado}
            tipoUsuario={tipoUsuario}
            ehAdmin={ehAdmin}
            handleRetornaDados={user => handleAtualizouUsuario(user)}
          />
        </Modal>
      )}

      <tr>
        <td>
          <h2 className="table-avatar">
            <img
              className="avatar avatar-img"
              src={usuarioLocal.avatar_url}
              alt="avatar"
            />
            <div>
              <span className="user-name d-block">{usuarioLocal.nome}</span>
              <span className="text-muted">{usuarioLocal.tipoFormatado}</span>
            </div>
          </h2>
        </td>
        <td>
          <span className="user-name">{usuarioLocal.email}</span>
          <br />
          {usuarioLocal.telefoneFormatado}
        </td>
        <td>
          <span>De {usuarioLocal.loginIni}</span>
          <br />
          as {usuarioLocal.loginFim}
        </td>
        <td>{usuarioLocal.ativo ? 'Sim' : 'Não'}</td>
        <td>
          <Button
            type="button"
            variant="outline-primary"
            size="sm"
            data-id={usuarioLocal.id}
            onClick={() => {
              setUsuarioSelecionado(usuarioLocal.id);
              setShowModalCadastro(true);
            }}
          >
            <FiEdit />
          </Button>
        </td>
        <td>
          {usuarioLocal.exibeBotaoAcao && (
            <Button
              type="button"
              variant="outline-danger"
              size="sm"
              className="border-0"
              data-id={usuarioLocal.id}
              title={`Excluir: ${usuarioLocal.id.toString()}`}
              onClick={() => handleDeleteUsuario(usuarioLocal.id)}
            >
              <FiTrash />
            </Button>
          )}
        </td>
      </tr>
    </>
  );
};

export default Linha;
