/* eslint-disable import/no-duplicates */
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';

import { Row, Col, Badge } from 'react-bootstrap';

import api from '../../../../services/api';

import {
  formatPrice,
  ORIGEM_PAGAMENTO,
  TIPO_USUARIO,
} from '../../../../utils/funcoes';

import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';
import Pagamento from '../../../../components/Pagamento';

import getResponseErrors from '../../../../utils/getResponseErrors';

interface IDadosPagamento {
  tituloPagamento: string;
  pagamentoSelecionado?: number;
  codOrigem?: number;
}

interface IItem {
  codigo: number;
  descricao: string;
  valor: number;
  valorFormatado: string;
}

interface IRelatorio {
  despesas: IItem[];
  totalDespesas: number;
  totalDespesasFormatado: string;
  receitas: IItem[];
  totalReceitas: number;
  totalReceitasFormatado: string;
  trocos: IItem[];
  sangrias: IItem[];
  totalCaixaFormatado: string;
  totalGeral: number;
  totalGeralFormatado: string;
}

interface IDadosPagamentoOrigem extends IDadosPagamento {
  CprOrigem: number;
}

type ComponentProps = {
  data: Date;
  clinica_id: number;
  usuario_id?: number;
};

const Fechamento: React.FC<ComponentProps> = ({
  data,
  clinica_id,
  usuario_id,
}) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [showModalPagamento, setShowModalPagamento] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorio>();
  const [dadosPagamento, setDadosPagamento] = useState<
    IDadosPagamentoOrigem | undefined
  >();

  // verificar esta permissão
  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.ATENDENTE_CAIXA) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const handleSubmitBusca = useCallback(async () => {
    setLoading(true);
    try {
      const dataFormatada = format(data, 'yyyy-MM-dd');
      const usuario = usuario_id ? `&usuario_id=${usuario_id}` : '';

      const response = await api.get<IRelatorio>(
        `/relatorios/fechamentocaixa?data=${dataFormatada}&clinica_id=${clinica_id}${usuario}`,
      );

      const totalDespesas = response.data.despesas.reduce((tot, item) => {
        return tot + Number(item.valor);
      }, 0);

      const totalReceitas = response.data.receitas.reduce((tot, item) => {
        return tot + Number(item.valor);
      }, 0);

      const totalDinheiroReceitas =
        response.data.receitas.find(
          item => item.descricao.toUpperCase() === 'DINHEIRO',
        )?.valor || 0;

      const totalTrocos = response.data.trocos.reduce((tot, item) => {
        return tot + Number(item.valor);
      }, 0);

      const totalSangrias = response.data.sangrias.reduce((tot, item) => {
        return tot + Number(item.valor);
      }, 0);

      const totalCaixa =
        totalDinheiroReceitas - totalSangrias + totalTrocos - totalDespesas;

      const dadosFormatados: IRelatorio = {
        ...response.data,
        despesas: response.data.despesas.map(des => {
          return { ...des, valorFormatado: formatPrice(des.valor) };
        }),
        receitas: response.data.receitas.map(des => {
          return { ...des, valorFormatado: formatPrice(des.valor) };
        }),
        trocos: response.data.trocos.map(des => {
          return { ...des, valorFormatado: formatPrice(des.valor) };
        }),
        sangrias: response.data.sangrias.map(des => {
          return { ...des, valorFormatado: formatPrice(des.valor) };
        }),
        totalDespesas,
        totalDespesasFormatado: formatPrice(totalDespesas),
        totalReceitas,
        totalReceitasFormatado: formatPrice(totalReceitas),
        totalGeral: totalReceitas - totalDespesas,
        totalGeralFormatado: formatPrice(totalReceitas - totalDespesas),
        totalCaixaFormatado: formatPrice(totalCaixa),
      };

      setDadosRelatorio(dadosFormatados);
    } catch (error) {
      const mensagem = getResponseErrors(error);
      addToast({
        type: 'error',
        title: 'Não foi possível carregar dados',
        description: mensagem,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, clinica_id, data, usuario_id]);

  useEffect(() => {
    handleSubmitBusca();
  }, [handleSubmitBusca]);

  const handleFecharModalPagamento = useCallback(async (load: boolean) => {
    setShowModalPagamento(false);
    if (load) {
      setDadosPagamento(undefined);
    }
  }, []);

  const handlePagamento = useCallback(
    (dados: IDadosPagamentoOrigem) => {
      if (user.id === 1) {
        setDadosPagamento(dados);

        setShowModalPagamento(true);
      }
    },
    [user.id],
  );

  return (
    <>
      {loading && <Loading />}
      {showModalPagamento && (
        <Modal
          show={showModalPagamento}
          titulo={dadosPagamento?.tituloPagamento || ''}
          handleFecharModal={() => setShowModalPagamento(false)}
        >
          <Pagamento
            handleFechar={handleFecharModalPagamento}
            tipo={
              dadosPagamento?.CprOrigem?.toString() ||
              ORIGEM_PAGAMENTO.SANGRIA.toString()
            }
            cprCodigo={dadosPagamento?.pagamentoSelecionado}
          />
        </Modal>
      )}
      {dadosRelatorio && user && (
        <>
          <div className="table-responsive" style={{ maxHeight: '600px' }}>
            <table className="datatable table  hover-table dataTable no-footer">
              <thead className="thead-light">
                <tr>
                  <th>Descrição</th>
                  <th style={{ width: '92px' }}>Valor</th>
                </tr>
              </thead>
              <tbody>
                {dadosRelatorio.despesas &&
                  dadosRelatorio.despesas.map(relatorio => (
                    <tr
                      key={`despesas-${relatorio.codigo}`}
                      style={{ fontSize: 'Small', height: '30px' }}
                    >
                      <td>{relatorio.descricao}</td>
                      <td>{relatorio.valorFormatado}</td>
                    </tr>
                  ))}
                <tr>
                  <td>
                    <b>Valor Total das Despesas</b>
                  </td>
                  <td>
                    <b>{dadosRelatorio.totalDespesasFormatado}</b>
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <br />
                  </td>
                </tr>

                {dadosRelatorio.receitas &&
                  dadosRelatorio.receitas.map(relatorio => (
                    <tr
                      key={`receitas-${relatorio.codigo}`}
                      style={{ fontSize: 'Small', height: '30px' }}
                    >
                      <td>{relatorio.descricao}</td>
                      <td>{relatorio.valorFormatado}</td>
                    </tr>
                  ))}
                <tr>
                  <td>
                    <b>Valor Total das Receitas</b>
                  </td>
                  <td>
                    <b>{dadosRelatorio.totalReceitasFormatado}</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <br />
                  </td>
                </tr>
                {dadosRelatorio.trocos &&
                  dadosRelatorio.trocos.map(relatorio => (
                    <tr
                      key={`trocos-${relatorio.codigo}`}
                      style={{ fontSize: 'Small', height: '30px' }}
                      className="text-primary"
                    >
                      <td>
                        <Badge
                          style={{ fontSize: '12px', paddingLeft: '0px' }}
                          title={`${relatorio.descricao}`}
                          onClick={
                            () =>
                              handlePagamento({
                                CprOrigem: ORIGEM_PAGAMENTO.TROCO,
                                tituloPagamento: `${relatorio.descricao}`,
                                pagamentoSelecionado: relatorio.codigo,
                              })
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        >
                          {relatorio.descricao}
                        </Badge>
                      </td>
                      <td>{relatorio.valorFormatado}</td>
                    </tr>
                  ))}
                {dadosRelatorio.sangrias &&
                  dadosRelatorio.sangrias.map(relatorio => (
                    <tr
                      key={`sangrias-${relatorio.codigo}`}
                      style={{ fontSize: 'Small', height: '30px' }}
                      className="text-danger"
                    >
                      <td>
                        <Badge
                          style={{ fontSize: '12px', paddingLeft: '0px' }}
                          title={`${relatorio.descricao}`}
                          onClick={
                            () =>
                              handlePagamento({
                                CprOrigem: ORIGEM_PAGAMENTO.SANGRIA,
                                tituloPagamento: `${relatorio.descricao}`,
                                pagamentoSelecionado: relatorio.codigo,
                              })
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        >
                          {relatorio.descricao}
                        </Badge>
                      </td>
                      <td>{relatorio.valorFormatado}</td>
                    </tr>
                  ))}
                <tr>
                  <td>
                    <b>
                      Dinheiro Caixa (Total Dinheiro + Troco - Sangria -
                      Despesas)
                    </b>
                  </td>
                  <td>
                    <b>{dadosRelatorio?.totalCaixaFormatado}</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Total geral, Receita - Despesa</b>
                  </td>
                  <td>
                    <b>{dadosRelatorio?.totalGeralFormatado}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Row className="mt-5">
            <Col className="text-center">
              _____________________________________
              <br />
              Administração
            </Col>
            <Col className="text-center">
              _____________________________________
              <br />
              Caixa
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Fechamento;
