import styled from 'styled-components';

/* Time Line */
export const Timeline = styled.div`
  position: relative;
  margin-bottom: 30px;
  float: left;
  ::before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    background: #f5f5f6;
    /* left: 50%; */
    top: 20px;
    bottom: 20px;
    margin-left: 30px;
  }
  ::after {
    content: ' ';
    display: table;
    clear: both;
  }
`;

export const Article = styled.article`
  position: relative;
  margin-top: 5px;
  margin-left: 30px;
  margin-bottom: 10px;
  clear: both;
  color: #737881;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  margin: 0 0 0 12px;

  line-height: 1.428571429;
  padding-top: 24px;
  ::before {
    content: ' ';
    display: table;
  }
  ::after {
    content: ' ';
    display: table;
    clear: both;
  }
  /* div {
    margin-left: 0;
    margin-right: -18px;
    position: relative;
    margin-left: -20px;
    left: auto;
    right: -100px;
    text-align: left;
    float: right;
  } */
`;

export const TimelineLabel = styled.div`
  margin-left: 0;
  margin-right: 70px;
  position: relative;
  background: #f5f5f6;
  padding: 1em;
  margin-left: 60px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent #f5f5f6 transparent transparent;
    left: 0;
    top: 10px;
    margin-left: -9px;
  }
  h2 {
    color: #737881;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    margin: 0;
    line-height: 1.428571429;
    span {
      font-size: 13px;
      color: #a2a19f;
    }
  }
  p {
    color: #737881;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    margin: 0;
    line-height: 1.428571429;
  }
  p + p {
    margin-top: 15px;
  }
`;

export const Icon = styled.span`
  background: #fff;
  color: #737881;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-align: center;
  -moz-box-shadow: 0 0 0 5px #f5f5f6;
  -webkit-box-shadow: 0 0 0 5px #f5f5f6;
  box-shadow: 0 0 0 5px #f5f5f6;
  line-height: 40px;
  font-size: 15px;
  float: left;
`;
export const TimelineEntry = styled.div`
  position: relative;
  margin-left: 12 px;
  > span {
    display: block;
  }
  ::before,
  ::after {
    content: ' ';
    display: table;
  }
  ::after {
    clear: both;
  }
`;

//   .timeline-centered .timeline-entry.begin {
//           margin-bottom: 0;
//       }

//       .timeline-centered .timeline-entry.left-aligned {
//           float: left;
//       }

//           .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
//
//           }

//               .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
//
//               }

//               .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
//
//               }

//               .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
//
//               }

//       .timeline-centered .timeline-entry .timeline-entry-inner {
//           position: relative;
//           margin-left: -20px;
//       }

//           .timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
//               content: " ";
//               display: table;
//           }

//           .timeline-centered .timeline-entry .timeline-entry-inner:after {
//
//           }

//           .timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
//               content: " ";
//               display: table;
//           }

//           .timeline-centered .timeline-entry .timeline-entry-inner:after {
//               clear: both;
//           }

//           .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
//               position: absolute;
//               left: -100px;
//               text-align: right;
//               padding: 10px;
//               -webkit-box-sizing: border-box;
//               -moz-box-sizing: border-box;
//               box-sizing: border-box;
//           }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
//                   display: block;
//               }

//                   .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time >

//           .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
//
//           }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
//                   background-color: #303641;
//                   color: #fff;
//               }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-secondary {
//                   background-color: #ee4749;
//                   color: #fff;
//               }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
//                   background-color: #00a651;
//                   color: #fff;
//               }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-info {
//                   background-color: #21a9e1;
//                   color: #fff;
//               }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
//                   background-color: #fad839;
//                   color: #fff;
//               }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
//                   background-color: #cc2424;
//                   color: #fff;
//               }

//           .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
//
//           }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
//                   content: '';
//                   display: block;
//                   position: absolute;
//                   width: 0;
//                   height: 0;
//                   border-style: solid;
//                   border-width: 9px 9px 9px 0;
//                   border-color: transparent #f5f5f6 transparent transparent;
//                   left: 0;
//                   top: 10px;
//                   margin-left: -9px;
//               }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
//                   color: #737881;
//                   font-family: "Noto Sans",sans-serif;
//                   font-size: 12px;
//                   margin: 0;
//                   line-height: 1.428571429;
//               }

//                   .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
//                       margin-top: 15px;
//                   }

//               .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
//                   font-size: 16px;
//                   margin-bottom: 10px;
//               }

//                   .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
//                       color: #303641;
//                   }

//                   .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 span {
//                       /*-webkit-opacity: .6;
//                       -moz-opacity: .6;
//                       opacity: .6;
//                       -ms-filter: alpha(opacity=60);
//                       filter: alpha(opacity=60);*/
//                       font-size: 13px;
//                       color: #a2a19f;
//                   }
