import styled from 'styled-components';

export const LinkDetalhe = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  i {
    height: 14px;
    min-height: 14px;
    width: 14px;
    min-width: 14px;
    margin-right: 2px;
    color: #747474;
    font-size: 24px;
    margin: auto;
    display: inline-flex;
    align-items: center;
    fill: currentColor;
    text-rendering: optimizeLegibility;
    vertical-align: middle;

    svg {
      fill: currentColor;
      width: 100%;
      height: 100%;
    }
  }

  a {
    color: #747474;
    text-decoration: none;
    font-size: 13px;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const Toggle = styled.div`
  top: 10px;
  width: auto;
  margin: 16px 8px 16px 0;
  display: inline-flex;
  position: relative;

  div {
    width: 34px;
    height: 14px;
    position: relative;
    border-radius: 14px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-color: rgba(0, 0, 0, 0.38);

    div {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 0;
      background-color: #fafafa;
      border-radius: 50%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
      transition: all 0.15s linear;
      input {
        font-size: 16px;
        color: #303030;
        position: absolute;
        left: -999em;
      }
      button {
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        background: none;
        border: none;
        transform: translate(-50%, -50%);
      }

      div {
        top: -16px;
        right: -16px;
        bottom: -16px;
        left: -16px;
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.54);
        pointer-events: none;
        overflow: hidden;
        position: absolute;
        -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 0);
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);

        div {
          width: 48px !important;
          height: 48px !important;
          top: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          left: 0 !important;
          opacity: 0 !important;
          transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
          transition-duration: 0.6s;
          animation: ripple 1s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
          position: absolute;
          background-color: currentColor;
          border-radius: 50%;
          transform: scale(0) translateZ(0);
          will-change: background-color, opacity, transform, width, height, top,
            left;
        }
      }
    }
  }
`;
