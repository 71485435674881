import React, { useState, useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns';

// import { Container } from './styles';

import { FaClock } from 'react-icons/fa';
import { completaZero } from '../../../utils/funcoes';

type Props = {
  id: number;
  textId: string;
  texto?: string;
  dataInicial: string;
  limit: {
    alertAt: number;
    dangerAt: number;
    stopAt: number;
  };
  tempoRegressivo?: boolean;
  handleAtingiuLimite?(): void;
};

const Timer: React.FC<Props> = ({
  id,
  textId,
  texto = '',
  dataInicial,
  tempoRegressivo = false,
  limit,
  handleAtingiuLimite,
}) => {
  const [tempo, setTempo] = useState('');
  const [estilo, setEstilo] = useState('');

  useEffect(() => {
    const countDownDate = new Date(dataInicial).getTime();
    const agora = new Date();

    const x = setInterval(() => {
      const horas = agora.getTime();
      agora.setTime(horas + 1000);

      const distance = tempoRegressivo
        ? differenceInMilliseconds(countDownDate > 0 ? countDownDate : 1, agora)
        : differenceInMilliseconds(
            agora,
            countDownDate > 0 ? countDownDate : 1,
          );

      if (
        (tempoRegressivo && limit.stopAt <= distance) ||
        (!tempoRegressivo && (limit.stopAt === 0 || limit.stopAt >= distance))
      ) {
        const hour = Math.floor(
          (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = `0${Math.floor((distance % (1000 * 60)) / 1000)}`.slice(
          -2,
        );
        try {
          let horario = `${completaZero(minutes, '0', 2)}:${seconds}`;
          if (hour > 0) horario = `${completaZero(hour, '0', 2)}:${horario}`;

          setTempo(horario);

          if (tempoRegressivo) {
            if (distance <= limit.alertAt && distance >= limit.dangerAt)
              setEstilo('bg-warning');
            if (distance <= limit.dangerAt) setEstilo('bg-danger');
          }
          if (!tempoRegressivo) {
            if (distance >= limit.alertAt && distance <= limit.dangerAt)
              setEstilo('bg-warning');
            if (distance >= limit.dangerAt) setEstilo('bg-danger');
          }
        } catch (error) {
          return '';
        }
      } else {
        clearInterval(x);
        if (handleAtingiuLimite) handleAtingiuLimite();
      }
      return '';
    }, 1000);
    return () => clearInterval(x);
  }, [dataInicial, handleAtingiuLimite, limit, tempoRegressivo]);

  return (
    <>
      <div
        id="duracao"
        className={`alert alert-info border text-center p-1 m-0 ${estilo}`}
        style={{ fontSize: '0.9rem', width: '63px' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span id="tempo">
            <FaClock size="12" />
          </span>
          <span id="numberCountdown">{tempo}</span>
        </div>
      </div>
    </>
  );
};

export default Timer;
