/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import Select, { OptionTypeBase, Props as SelectProps } from 'react-select';

import { Container, Error } from './styles';

export interface ProviderProps {
  [key: string]: any;
}
export interface IOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface Props extends SelectProps<OptionTypeBase, boolean> {
  name: string;
  label: string;
  containerStyle?: React.CSSProperties;
  options: IOption[];
  tipoBusca?: boolean;
  size?: 'sm' | 'lg' | 'gr';
  handleSelectChange(data: IOption): void;
  // icon?: React.ComponentType<IconBaseProps>;
}

const SelectSync: React.FC<Props> = ({
  name,
  containerStyle = {},
  options,
  tipoBusca = false,
  handleSelectChange,
  icon: Icon,
  label,
  size = 'sm',
  ...rest
}) => {
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    // setIsFilled(!!selectRef.current?.value);
  }, []);

  function tamanho(sz: string): number {
    switch (sz) {
      case 'sm':
        return 31;
      case 'gr':
        return 60;
      default:
        return 38;
    }
  }

  const customStyles = {
    container: (provided: ProviderProps) => ({
      ...provided,
    }),
    control: (provided: ProviderProps) => ({
      ...provided,
      borderWidth: '1px',
      borderRadius: '16px',
      height: tamanho(size),
      minHeight: tamanho(size),
      alignContent: 'center',
    }),
    input: (provided: ProviderProps) => ({
      ...provided,
      color: isFocused ? '#543543' : '#495057;',
      width: '100%',
    }),
    singleValue: (provided: ProviderProps) => ({
      ...provided,
      width: '100%',
      color: '#495057',
    }),
    multiValue: (provided: ProviderProps) => ({
      ...provided,
      color: '#0573e0',
    }),
    valueContainer: (provided: ProviderProps) => ({
      ...provided,
      paddingTop: 0,
      paddingBotton: 0,
      // ...(rest.isMulti && { marginTop: '28px' }),
      width: '100%',
    }),
    option: (provided: ProviderProps) => ({
      ...provided,
      padding: 10,
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      // path: undefined,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value: string) => {
        // ref.select.setValue(value || null);
        // if (value && value !== '')
        try {
          if (value && value !== '') {
            // eslint-disable-next-line prefer-destructuring
            ref.state.value = ref.select.props.options.filter(
              (option: OptionTypeBase) => option.value === value,
            )[0];
          }
        } catch (err) {
          console.log(err);
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleOnChange = useCallback(
    (selected: OptionTypeBase | null) => {
      if (selected) {
        handleSelectChange({ value: selected?.value, label: selected?.label });
      }
    },
    [handleSelectChange],
  );

  return (
    <Container
      style={containerStyle}
      isErrored={!!error}
      isFocused={isFocused}
      isFilled={isFilled}
    >
      {Icon && <Icon size={20} />}
      {label && (
        <b style={{ marginBottom: '4px', display: 'block' }}>{label}</b>
      )}
      <Select
        ref={selectRef}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        loadingMessage={() => 'Procurando...'}
        noOptionsMessage={() => 'Sem opções'}
        onChange={handleOnChange}
        styles={customStyles}
        isLoading={false}
        options={options}
        defaultValue={
          defaultValue && options.find(option => option.value === defaultValue)
        }
        // components={
        //   tipoBusca && { DropdownIndicator, IndicatorSeparator: null }
        // }
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />{' '}
        </Error>
      )}
    </Container>
  );
};

export default SelectSync;
