import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 1.4rem;
  font-weight: 600;
  color: #3a3a3a;
  margim-bottom: 10px;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const CardHeader = styled.div`
  background-color: #fff;
  text-align: right;
  border-bottom-width: 0;

  p {
    color: #6c757d;
    margin-bottom: 0.25rem;
  }
`;

export const CardIconContainer = styled.div`
  padding: 0.9rem 1rem;
  margin-right: 0;
  color: #fff;
  border-radius: 0.25rem;
`;

export const CardTextContainer = styled.div`
  flex: 1;
  text-align: left;
  margin-left: 10px;

  a {
    color: #131523;
    font-weight: 600;
  }
`;
