import React, { useCallback, useEffect, useState } from 'react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step = 1): number[] => {
  let i = from;
  const values: number[] = [];

  while (i <= to) {
    values.push(i);
    i += step;
  }

  return values;
};

interface Props {
  totalRegistros: number | undefined;
  limitePagina: number;
  paginasVizinhas: number;
  paginaInicio?: number;
  handleProximaPagina(page: number): void;
}

const Paginacao: React.FC<Props> = ({
  totalRegistros,
  limitePagina,
  paginasVizinhas,
  paginaInicio,
  handleProximaPagina,
}) => {
  const [paginaAtual, setPaginaAtual] = useState(paginaInicio || 1);
  const [totalPaginas, setTotalPaginas] = useState(
    totalRegistros ? Math.ceil(totalRegistros / limitePagina) : 0,
  );
  const [paginas, setPaginas] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    setPaginaAtual(paginaInicio || 1);
  }, [paginaInicio]);

  useEffect(() => {
    setTotalPaginas(
      totalRegistros ? Math.ceil(totalRegistros / limitePagina) : 0,
    );
  }, [totalRegistros, limitePagina]);

  const fetchPageNumbers = useCallback(() => {
    /**
     * totalNumeros: the total page numbers to show on the control
     * totalBlocos: totalNumeros + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumeros = paginasVizinhas * 2 + 3;
    const totalBlocos = totalNumeros + 2;

    if (totalPaginas > totalBlocos) {
      const startPage = Math.max(2, paginaAtual - paginasVizinhas);
      const endPage = Math.min(totalPaginas - 1, paginaAtual + paginasVizinhas);
      let pages: any[] = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPaginas - endPage > 1;
      const spillOffset = totalNumeros - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPaginas];
    }

    return range(1, totalPaginas);
  }, [paginasVizinhas, paginaAtual, totalPaginas]);

  useEffect(() => {
    setPaginas(fetchPageNumbers());
  }, [fetchPageNumbers]);

  const handleTrocaPagina = useCallback(
    (page: number) => {
      setPaginaAtual(page);
      handleProximaPagina(page);
      window.scrollTo(0, 150);
    },
    [handleProximaPagina],
  );
  return (
    <>
      {totalRegistros && totalPaginas > 1 && paginas && (
        <nav aria-label="Countries Pagination">
          <ul className="pagination justify-content-center">
            {paginas.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <li key={`pags-${page}`} className="page-item">
                    <button
                      type="button"
                      className="page-link"
                      aria-label="Previous"
                      // eslint-disable-next-line prettier/prettier
                      onClick={() => handleTrocaPagina(paginaAtual - paginasVizinhas * 2 - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </button>
                  </li>
                );

              if (page === RIGHT_PAGE)
                return (
                  <li key={`pags-${page}`} className="page-item">
                    <button
                      className="page-link"
                      type="button"
                      aria-label="Next"
                      // eslint-disable-next-line prettier/prettier
                      onClick={() => handleTrocaPagina(paginaAtual + paginasVizinhas * 2 + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </button>
                  </li>
                );

              return (
                <li
                  key={`pags-${page}`}
                  className={`page-item${
                    paginaAtual === page ? ' active' : ''
                  }`}
                >
                  <button
                    className={`page-link ${
                      paginaAtual !== page && 'text-primary'
                    }`}
                    type="button"
                    onClick={() => handleTrocaPagina(page)}
                  >
                    {page}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </>
  );
};

export default Paginacao;
