import React, { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { FiCheckSquare } from 'react-icons/fi';
import { Row, Col } from 'react-bootstrap';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import getResponseErrors from '../../utils/getResponseErrors';

import { IDadosRodape } from './index';
import {
  formataCep,
  formataCPF,
  formataIdade,
  formataTelefone,
  STATUS_AGENDA,
} from '../../utils/funcoes';

import { Exame } from './styles';

export interface IPaciente {
  PacCodigo: number;
  PacNome: string;
  PacDataNasc: Date;
  PacSexo: string;
  PacDataNascFormatada: string;
  PacRG: string;
  PacCPF: string;
  PacIdade: string;
}

interface IMedicoPedido {
  medico: {
    UsuCodigo: number;
    UsuNome: string;
    UsuCRM: string;
    UsuConselho: string;
    UsuTelefone: string;
    UsuEspecialidade: string;
    UsuEndereco: string;
    UsuNumero: string;
    UsuBairro: string;
    UsuCidade: string;
    UsuCEP: string;
    UsuUF: string;
    UsuComplemento: string;
  };
}
export interface IAgendamento {
  AgeCodigo: number;
  AgeConfCodigo: number;
  AgeMedUsuCodigo?: number;
  AgeAteUsuCodigo: number;
  AgeAteUsuClinica: number;
  AgePacCodigo: number;
  AgeTipo: string;
  AgeObs: string;
  AgeInicio: Date;
  AgeFim: Date;
  AgeStatus: number;
  AgeFormaPag: number;
  AgeDataLiberacao?: Date;
  AgeEmail: Date;
  AgePlano: boolean;
  AgeClinica: number;
  pago: boolean;
  paciente: IPaciente;
  medico: {
    UsuCodigo: number;
    UsuNome: string;
    UsuCRM: string;
    UsuConselho: string;
    UsuTelefone: string;
    UsuEspecialidade: string;
    UsuEndereco: string;
    UsuNumero: string;
    UsuBairro: string;
    UsuCidade: string;
    UsuCEP: string;
    UsuUF: string;
    UsuComplemento: string;
  };
  procedimentos: [
    {
      ProNome: string;
      ProCodigo: number;
    },
  ];
  PedIndicacao: string;
}

interface IComponentProps {
  AgeCodigo: number;
  handleDadosRodape?(dadosRodape: IDadosRodape): void;
}

const PedidoExame: React.FC<IComponentProps> = ({
  AgeCodigo,
  handleDadosRodape,
}) => {
  const { addToast } = useToast();
  const [agendamento, setAgendamento] = useState<IAgendamento>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        const response = await api.get<IAgendamento>(
          `/agendamentos/${AgeCodigo}/impressao`,
        );

        const dataNascimento = parseISO(
          response.data.paciente.PacDataNasc.toString(),
        );

        let dadosFormatados: IAgendamento = {
          ...response.data,
          pago:
            response.data.AgeStatus === STATUS_AGENDA.AGUARDANDO_ATENDIMENTO ||
            response.data.AgeStatus === STATUS_AGENDA.ATENDIDO ||
            response.data.AgeStatus === STATUS_AGENDA.PAGAMENTO_ANTECIPADO,
          paciente: {
            ...response.data.paciente,
            PacDataNasc: dataNascimento,
            PacDataNascFormatada: format(dataNascimento, 'dd/MM/yyyy'),
            PacIdade: formataIdade(dataNascimento, true),
            PacCPF: formataCPF(response.data.paciente.PacCPF),
          },
          medico: {
            ...response.data.medico,
            UsuCEP: formataCep(response.data.medico.UsuCEP),
            UsuTelefone: formataTelefone(response.data.medico.UsuTelefone),
          },
        };

        if (!dadosFormatados.pago) {
          const responseMedico = await api.get<IMedicoPedido | undefined>(
            `/pedidoexames/medico/${AgeCodigo}`,
          );
          if (responseMedico.data) {
            dadosFormatados = {
              ...dadosFormatados,
              medico: {
                ...responseMedico.data.medico,
                UsuCEP: formataCep(responseMedico.data.medico.UsuCEP),
                UsuTelefone: formataTelefone(
                  responseMedico.data.medico.UsuTelefone,
                ),
              },
            };
          }
        }

        setAgendamento(dadosFormatados);
        if (handleDadosRodape)
          handleDadosRodape({
            CliCodigo: response.data.AgeClinica,
            assinatura: dadosFormatados.pago
              ? undefined
              : {
                  nome: dadosFormatados.medico.UsuNome,
                  especialidade: dadosFormatados.medico.UsuEspecialidade,
                  documento: dadosFormatados.medico.UsuCRM,
                  conselho: dadosFormatados.medico.UsuConselho,
                },
          });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setTimeout(() => {
          window.print();
        }, 2000);
      }
    }
    loadDados();
  }, [AgeCodigo, addToast, handleDadosRodape]);

  return (
    <div style={{ fontSize: '1rem' }}>
      {agendamento && (
        <>
          <h5 className="text-center mb-5">
            REQUISIÇÃO DE EXAMES
            {agendamento.pago && (
              <span className="float-right text-verde">
                COD. {agendamento.AgeCodigo}
              </span>
            )}
          </h5>

          <table
            className="table table-hover table-sm table-bordered"
            style={{ borderWidth: '0px' }}
          >
            <tbody style={{ fontSize: '1rem' }}>
              <tr>
                <td>
                  <b>NOME: </b> {agendamento.paciente.PacNome}
                </td>
                <td>
                  <b>SEXO: </b> {agendamento.paciente.PacSexo}
                </td>
              </tr>
              <tr>
                <td>
                  <b>RG: </b> {agendamento.paciente.PacRG}
                </td>
                <td>
                  <b>CPF: </b> {agendamento.paciente.PacCPF}
                </td>
              </tr>
              <tr>
                <td>
                  <b>DATA NASC: </b> {agendamento.paciente.PacDataNascFormatada}
                </td>
                <td>
                  <b>IDADE: </b> {agendamento.paciente.PacIdade}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>INDICAÇÃO: </b> {agendamento.PedIndicacao}
                </td>
              </tr>
            </tbody>
          </table>

          {agendamento.pago && (
            <table
              className="table table-hover table-sm table-bordered"
              style={{ borderWidth: '0px' }}
            >
              <tbody style={{ fontSize: '1rem' }}>
                <tr>
                  <td>
                    <b>LOCAL: </b> {agendamento.medico.UsuNome}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>ENDEREÇO: </b>{' '}
                    {`${agendamento.medico.UsuEndereco}, ${agendamento.medico.UsuNumero} ${agendamento.medico.UsuComplemento}`}{' '}
                    {`${agendamento.medico.UsuBairro}, ${agendamento.medico.UsuCidade}/${agendamento.medico.UsuUF} - CEP ${agendamento.medico.UsuCEP}`}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>TELEFONE: </b> {agendamento.medico.UsuTelefone}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <br />
          <Row className="mt-3">
            {agendamento.procedimentos.map(pro => (
              <Col md={6} key={`pro-${pro.ProCodigo}`}>
                <Exame>
                  <FiCheckSquare size={22} /> {pro.ProNome}
                </Exame>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default PedidoExame;
