import React, { useCallback, useEffect, useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { format, parseISO } from 'date-fns/esm';
import { addHours } from 'date-fns';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Paginacao from '../../../components/Paginacao';
import Skeleton from './Skeleton';

import { optionsTipoModelo } from '../../../utils/options';
import { IModelo } from '..';
import getResponseErrors from '../../../utils/getResponseErrors';

interface IRespostaModelo {
  data: IModelo[];
  count: number;
}

export interface IPaginacao {
  termoBusca: string;
  limite: number;
  pagina: number;
}

type Props = {
  modeloMensagem?: boolean;
  showModal: boolean;
  setShowModal(value: boolean): void;
  setModeloSelecionado(model: IModelo): void;
};
const ListaModelos: React.FC<Props> = ({
  modeloMensagem = false,
  showModal,
  setShowModal,
  setModeloSelecionado,
}) => {
  const { addToast } = useToast();
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    termoBusca: '',
    pagina: 1,
    limite: 20,
  });
  const [loading, setLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );
  const [modelos, setModelos] = useState<IModelo[]>([]);

  useEffect(() => {
    async function buscaModelos(): Promise<void> {
      if (paginacao && !showModal) {
        try {
          setLoading(true);

          const parametroModelo = `&modeloMensagem=${modeloMensagem}`;

          const response = await api.get<IRespostaModelo>(
            `/modelos?pagina=${paginacao.pagina}&limite=${paginacao.limite}${parametroModelo}`,
          );

          const { data, count } = response.data;

          const ModelosFormatado = data.map((model: IModelo) => {
            return {
              ...model,
              dataAtualizacaoFormatada: format(
                addHours(parseISO(model.updated_at.toString()), -3),
                'dd/MM/yyyy HH:mm:ss',
              ),
              nomeTipo:
                optionsTipoModelo.find(
                  op => op.value === model.tipo?.toString(),
                )?.label || '',
              ativoFormatado: model.ativo ? 'Sim' : 'Não',
            };
          });

          setTotalRegistros(count);

          setModelos(ModelosFormatado);
        } catch (error) {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Erro ao carregar modelos',
            type: 'error',
            description: mensagemErro,
          });
          setTotalRegistros(0);
          setModelos([]);
        } finally {
          setLoading(false);
        }
      }
    }
    if (paginacao) buscaModelos();
  }, [paginacao, showModal, addToast, modeloMensagem]);

  const handleDeleteModelo = useCallback(
    async (codigo: number) => {
      setLoading(true);
      try {
        await api.delete(`/modelos/${codigo}`);
        setModelos(ModelosState =>
          ModelosState?.filter(model => model.id !== codigo),
        );
        addToast({
          type: 'success',
          title: 'Modelo excluído',
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Erro ao deletar modelo',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  const handleEditar = useCallback(
    model => {
      setModeloSelecionado(model);
      setShowModal(true);
    },
    [setShowModal, setModeloSelecionado],
  );

  return (
    <>
      {!showModal && (
        <>
          <div className="table-responsive">
            <table className="datatable table table-borderless hover-table dataTable no-footer">
              <thead className="thead-light">
                <tr>
                  <th>Nome</th>
                  <th>Atualização</th>
                  <th>Colaborador</th>
                  {modeloMensagem === false && (
                    <>
                      <th style={{ width: '100px' }}>Tipo</th>
                      <th style={{ width: '60px' }}>Ativo</th>
                    </>
                  )}
                  <th style={{ width: '40px' }}>&nbsp;</th>
                  <th style={{ width: '40px' }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {modelos &&
                  !loading &&
                  modelos.map((model: IModelo) => (
                    <tr
                      key={`Modelo-${model.id}`}
                      className={`${!model.ativo ? 'text-muted' : ''}`}
                    >
                      <td>{model.nome}</td>
                      <td>{model.dataAtualizacaoFormatada}</td>
                      <td>{model.usuarioEditor.nome}</td>
                      {modeloMensagem === false && (
                        <>
                          <td>{model.nomeTipo}</td>
                          <td>{model.ativoFormatado}</td>
                        </>
                      )}
                      <td>
                        <Button
                          type="button"
                          variant="primary-outline"
                          className="py-0"
                          title={model.id.toString()}
                          onClick={() => handleEditar(model)}
                        >
                          <FiEdit />
                        </Button>
                      </td>

                      <td>
                        {!modeloMensagem && (
                          <Button
                            type="button"
                            variant="secondary-outline"
                            className="py-0"
                            title={model.id.toString()}
                            onClick={() => {
                              handleDeleteModelo(model.id);
                            }}
                          >
                            <FiTrash />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                {loading && (
                  <>
                    <Skeleton />
                    <Skeleton />
                  </>
                )}
                <tr>
                  <td colSpan={4} />
                </tr>
              </tbody>
            </table>
          </div>

          {(!modelos || modelos.length === 0) && !loading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: '250px' }}
            >
              <h5>Nenhum registro encontrado.</h5>
            </div>
          )}

          <Paginacao
            limitePagina={paginacao?.limite || 15}
            paginasVizinhas={2}
            totalRegistros={totalRegistros}
            paginaInicio={paginacao?.pagina}
            handleProximaPagina={handleProximaPagina}
          />
        </>
      )}
    </>
  );
};

export default ListaModelos;
