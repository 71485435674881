import React, { useRef, useCallback, useEffect, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'react-bootstrap';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useClinica } from '../../hooks/clinica';

import SelectSync from '../SelectSync';

import { Container } from './styles';
import getResponseErrors from '../../utils/getResponseErrors';

type AgendaProps = {
  handleSelecionaAgenda(id: number): void;
};

interface ProfileFormDados {
  ConCodigo: string;
}

interface OptionAgenda {
  value: string;
  label: string;
}

interface IAgenda {
  id: number;
  nome: string;
}

interface IAgendaOutraClinica {
  id: number;
  nome: string;
  clinica: {
    id: number;
    nomeAbreviado: string;
  };
}

const TrocarAgenda: React.FC<AgendaProps> = ({ handleSelecionaAgenda }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica } = useClinica();

  const [ehMultiClinica, _] = useState(optionsClinica.length > 1);

  const [optionsAgendas, setOptionsAgendas] = useState<OptionAgenda[]>([]);
  const [outraClinica, setOutraClinica] = useState(false);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        const response = await api.get<IAgenda[]>('agendas');

        const dados = response.data.map(agenda => ({
          label: agenda.nome,
          value: agenda.id.toString(),
        }));

        setOptionsAgendas(dados);
      } catch (err) {
        const mensagemErro = getResponseErrors(err);

        addToast({
          type: 'error',
          title: 'Erro ao buscar dados',
          description: mensagemErro,
        });
      }
    }
    loadDados();
  }, [addToast]);

  const handleSubmit = useCallback(
    (dados: ProfileFormDados) => {
      handleSelecionaAgenda(Number(dados.ConCodigo));
    },
    [handleSelecionaAgenda],
  );

  const handleBuscaOutraClinica = useCallback(async () => {
    try {
      const response = await api.get<IAgendaOutraClinica[]>(
        `agendas/agendasoutrasclinicas/${user.clinica_id}`,
      );

      const dados = response.data.map(agenda => ({
        label: `${agenda.clinica.nomeAbreviado} / ${agenda.nome}`,
        value: agenda.id.toString(),
      }));

      setOptionsAgendas(dados);

      setOutraClinica(true);
    } catch (err) {
      const mensagemErro = getResponseErrors(err);

      addToast({
        type: 'error',
        title: 'Erro ao buscar dados',
        description: mensagemErro,
      });
    }
  }, [user.clinica_id, addToast]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <SelectSync
          name="ConCodigo"
          label=""
          // isClearable={false}
          placeholder="Selecione"
          handleSelectChange={() => {
            console.log('Seleciona');
          }}
          options={optionsAgendas}
        />
        <Button type="submit" size="sm" className="mt-3">
          Selecionar
        </Button>

        {ehMultiClinica && !outraClinica && (
          <Button
            type="button"
            size="sm"
            variant="info"
            className="mt-2 float-right"
            onClick={handleBuscaOutraClinica}
          >
            Agendar Outra Clinica
          </Button>
        )}
      </Form>
    </Container>
  );
};

export default TrocarAgenda;
