import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

import { IOption } from '../components/SelectSync';
// import { IConfigAgendaBasica } from '../pages/Agenda';
import { useToast } from './toast';
import getResponseErrors from '../utils/getResponseErrors';

export interface IEspecialidade {
  id: number;
  nome: string;
  tipo: number;
  integracaoAlvaro: boolean;
  ativo: boolean;
}

interface IReturnEspecialidade {
  data: IEspecialidade[];
  count: number;
}

interface IOptionsEspecialidade {
  optionEspecialidades: IOption[];
}
interface IEspecialidades {
  especialidade: IEspecialidade[];
}

interface EspecialidadeContextData {
  loading: boolean;
  optionsEspecialidade: IOptionsEspecialidade;
  especialidade: IEspecialidades;
  buscaEspecialidade(): Promise<void>;
  limparEspecialidade(): void;
}

const EspecialidadeContext = createContext<EspecialidadeContextData>(
  {} as EspecialidadeContextData,
);

const EspecialidadeProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const [especialidade, setEspecialidade] = useState<IEspecialidades>(() => {
    const especs = localStorage.getItem('@AgendaFacil:especialidade');
    if (especs) return JSON.parse(especs);

    return {} as IEspecialidades;
  });

  const [
    optionsEspecialidade,
    setOptionsEspecialidade,
  ] = useState<IOptionsEspecialidade>(() => {
    const optionEspecialidades = localStorage.getItem(
      '@AgendaFacil:optionsEspecialidade',
    );
    if (optionEspecialidades) return JSON.parse(optionEspecialidades);

    return {} as IOptionsEspecialidade;
  });

  const buscaEspecialidade = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IReturnEspecialidade>(
        `/especialidades?limite=1000`,
      );

      const { data } = response.data;

      const options: IOption[] = data.map((op: IEspecialidade) => ({
        value: op.id.toString(),
        label: op.nome,
        isDisabled: !op.ativo,
      }));

      const localEspecialidades: IEspecialidades = {
        especialidade: data,
      };

      const localOptions: IOptionsEspecialidade = {
        optionEspecialidades: options,
      };

      localStorage.setItem(
        '@AgendaFacil:especialidade',
        JSON.stringify(localEspecialidades),
      );
      localStorage.setItem(
        '@AgendaFacil:optionsEspecialidade',
        JSON.stringify(localOptions),
      );
      setEspecialidade(localEspecialidades);

      setOptionsEspecialidade(localOptions);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar especialidade',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    async function load(): Promise<void> {
      const tokenLocal = localStorage.getItem('@AgendaFacil:token');
      if (tokenLocal) {
        buscaEspecialidade();
      }
    }
    load();
  }, [buscaEspecialidade]);

  const limparEspecialidade = useCallback(() => {
    localStorage.removeItem('@AgendaFacil:optionsEspecialidade');
    localStorage.removeItem('@AgendaFacil:especialidade');
    setEspecialidade({} as IEspecialidades);
    setOptionsEspecialidade({} as IOptionsEspecialidade);
  }, []);

  return (
    <EspecialidadeContext.Provider
      value={{
        loading,
        especialidade,
        optionsEspecialidade,
        buscaEspecialidade,
        limparEspecialidade,
      }}
    >
      {children}
    </EspecialidadeContext.Provider>
  );
};

function useEspecialidade(): EspecialidadeContextData {
  const context = useContext(EspecialidadeContext);

  if (!context) {
    throw new Error(
      'useEspecialidade deve ser usado com o Especialidade Provider',
    );
  }

  return context;
}

export { EspecialidadeProvider, useEspecialidade };
