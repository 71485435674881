import styled from 'styled-components';

export const ContainerDados = styled.div`
  background-color: #fff;
  padding: 0.75rem;
  border: 1px solid #d7d7d7;
  position: relative;
`;

export const ButtonDelete = styled.button`
  border: 0;
  background-color: transparent;
  color: #000;
  padding: 2px;
  border: 1px solid #fff;
  border-radius: 2px;
  &:hover {
    color: red;
    border: 1px solid red;
  }
`;
