import React, { useCallback, useState } from 'react';

import { FaWhatsapp, FaCheckCircle, FaSpinner, FaCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import { Spinner } from '../../../../components/Agendamento/styles';

import getResponseErrors from '../../../../utils/getResponseErrors';

import { IPaciente } from '../index';
import { htmlDecode } from '../../../../utils/funcoes';

type ITemTabela = {
  paciente: IPaciente;
};

const ItemTabela: React.FC<ITemTabela> = ({ paciente }) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [conferido, setConferido] = useState(paciente.conferido);

  const handleSalvaNotificacao = useCallback(async () => {
    try {
      setLoading(true);
      if (!conferido) {
        await api.post(`clientes/aniversariantes`, {
          RegPacCodigo: paciente.id,
        });
      } else {
        await api.delete(`clientes/aniversariantes/${paciente.id}`);
      }

      setConferido(!conferido);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível salvar a notificação',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, conferido, paciente.id]);

  const handleEnviaConfirmacao = useCallback((pacient: IPaciente) => {
    const textoDecode = htmlDecode(pacient.textoMensagem);
    const textoMensagem = encodeURIComponent(textoDecode);

    console.log(pacient.textoMensagem, textoDecode, textoMensagem);
    const linkWhatsapp = `https://api.whatsapp.com/send?phone=55${pacient.celular}&text=${textoMensagem}`;
    window.open(linkWhatsapp, '_blank');
  }, []);

  return (
    <>
      <tr
        style={{
          fontSize: 'Small',
          height: '30px',
        }}
      >
        <td>{paciente.nome}</td>
        <td>{paciente.dataNascimentoFormatado}</td>
        <td>{paciente.idade}</td>
        <td>{paciente.dataUltimaConsultaFormatado}</td>
        <td>{paciente.telefoneFormatado}</td>
        <td>{paciente.email}</td>
        <td style={{ textAlign: 'center' }}>
          <Button
            type="button"
            variant="success"
            size="sm"
            onClick={() => handleEnviaConfirmacao(paciente)}
          >
            <FaWhatsapp size={16} />
          </Button>
        </td>
        <td>
          {loading ? (
            <Spinner className="px-2 py-1 border rounded ml-1">
              <FaSpinner size={17} />
            </Spinner>
          ) : (
            <Button
              type="button"
              variant={conferido ? 'success' : 'outline-secondary'}
              size="sm"
              data-id={paciente.id}
              onClick={() => handleSalvaNotificacao()}
            >
              {conferido ? <FaCheckCircle size={16} /> : <FaCircle size={16} />}
            </Button>
          )}
          {/* <Conferido
            PacCodigo={paciente.PacCodigo}
            conferido={paciente.conferido}
          /> */}
        </td>
      </tr>
    </>
  );
};

export default ItemTabela;
