/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  addMinutes,
  format,
  parseISO,
  isBefore,
  setHours,
  differenceInMinutes,
  differenceInYears,
} from 'date-fns';
import * as Yup from 'yup';

import { Button, Row, Col } from 'react-bootstrap';

import {
  FiThumbsUp,
  FiThumbsDown,
  FiTrash,
  FiLoader,
  FiXCircle,
  FiPlus,
  FiAlertCircle,
} from 'react-icons/fi';

import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/toast';
import { useClinica } from '../../hooks/clinica';

import SelectAsync from '../SelectAsync';
import SelectSync from '../SelectSync';
import Input from '../Input';
import Select from '../Select';
import Loading from '../Loading';
import TextArea from '../TextArea';
import PacienteCabecalho from '../PacienteCabecalho';
import PacienteCadastro from '../PacienteCadastro';
import Modal from '../Modal';
import SelectAsyncCadastro from '../SelectAsyncCadastro';
import MedicoCadastro, { IMedico } from '../../pages/Medico/MedicoCadastro';

import {
  formatPrice,
  optionsHorasAtendimento,
  optionsStatusAgendamento,
  STATUS_AGENDA,
  CONFIRMACAO_AGENDA,
  validarCPF,
  ORIGEM_AGENDAMENTO,
} from '../../utils/funcoes';

import { Container, PermissaoAdmin, Spinner } from './styles';
import api from '../../services/api';

import getResponseErrors from '../../utils/getResponseErrors';
import Skeleton from '../Skeleton';

interface IBuscaProcedimento {
  especialidadesBusca: string;
  convenioBusca: string;
  alterouConvenio: boolean;
}

interface IAgendamentoListagem {
  id: number;
  obs: string;
  inicio: Date;
  fim: Date;
  status: number;
  confirmado: number;
  cliente: {
    id: number;
    nome: string;
  };
  top?: number;
  left?: string;
  width?: string;
  height?: string;
  statusFormatado?: {
    id: number;
    nome: string;
    color: string;
    icon: string;
  };
}

type AgendamentoProps = {
  id?: number;
  agenda_id?: number;
  especialidadesAgenda?: string;
  data?: Date;
  handleFecharAgendamento(
    agendamentoListagem: IAgendamentoListagem | undefined,
  ): void;
};

export interface IPaciente {
  id: number;
  nome: string;
  dataNasc: Date;
  idade: string;
  dataNascFormatada: string;
  cpf: string;
  rg: string;
  telefone: string;
  celular: string;
  email: string;
  dataCadastro: Date;
  sexo: string;
  ativo: boolean;
  restrito: boolean;
  avatar_url: string;
  responsavel_id?: number;
}

interface IPacienteDadosObrigatorios {
  dadosValidos: boolean;
  msg: string;
}

export interface IAgendamentosPaciente {
  id: number;
  inicio: Date;
  fim: Date;
  status: number;
  confirmado: number;
  nome: string;
  profissional_id?: number;
  obs?: string;
  ConAtende?: boolean;
  agenda?: {
    atende: boolean;
    nome: string;
  };
}

interface IPacienteMaisAgendamentosDTO extends IPaciente {
  agendamentosPaciente: IAgendamentosPaciente[];
  atendimentos: number;
  faltas: number;
}

interface IHistorico {
  id: number;
  log: string;
  data: Date;
  dataFormatada: string;
}

export interface IAgendamento {
  agenda_id: number;
  profissional_id?: number;
  usuario_id: number;
  cliente_id: number;
  tipo: number;
  convenio_id: number;
  obs: string;
  guia: string;
  inicio: Date;
  fim: Date;
  status: number;
  confirmado?: number;
  formaPag: number;
  dataLiberacao?: Date;
  email?: Date;
  clinica_id: number;
  origem: number;
  updated_at: Date;
  medico_id: number;
  procedimentos: [
    {
      id: number;
      nome: string;
      especialidade_id: number;
      valorCobrado: number;
      duracao: number;
    },
  ];
  cliente: IPaciente;
  agendamentos: IAgendamentosPaciente[];
  atendimentos: number;
  faltas: number;
  medicoSolicitante?: IMedico;
}

interface ProfileFormDados {
  pacienteID: string;
}

interface AgendamentoFormDados {
  data: Date;
  hora: string;
  status: string;
  convenio: string;
  confirmado?: string;
  obs: string;
  guia: string;
  duracaoTotal: string;
  medico_id: number;
  email?: string;
  senha?: string;
}

interface IProcedimento {
  id: number;
  nome: string;
  duracao: number;
  valor: number;
  valorFormatado: string;
}

export interface IProcedimentoAgendamento {
  id: number;
  nome: string;
  valor: number;
  valorFormatado: string;
  duracao: number;
}

interface IOptions {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface IAgendamentoAntecipadoSubmit {
  id?: number;
  obs: string;
  inicio: Date;
  fim: Date;
  confirmado: number;
  updated_at: Date;
  medico_id: number;
  paciente?: { id: number; nome: string };
  permissao?: { email?: string; senha?: string };
}

const Agendamento: React.FC<AgendamentoProps> = ({
  id = undefined,
  agenda_id = undefined,
  especialidadesAgenda = undefined,
  data,
  handleFecharAgendamento,
}) => {
  const { imagensClinica, buscaConvenios, optionsConvenio } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const validaDadosEnviarCaixa = false;

  const [especialidades, setEspecialidades] = useState<string | undefined>(
    especialidadesAgenda,
  );
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [showModalPacienteCad, setShowModalPacienteCad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProcedimento, setLoadingProcedimento] = useState(false);
  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [agendamentoBloqueado, setAgendamentoBloqueado] = useState(false);
  const [bloqueiaDiaPassado, setBloqueiaDiaPassado] = useState(false);
  const [bloqueiaDadosIncompletos, setBloqueiaDadosIncompletos] = useState(
    false,
  );
  const [exibePermissaoAdmin, setExibePermissaoAdmin] = useState(false);
  const [mensagemPermissaoAdmin, setMensagemPermissaoAdmin] = useState('');
  const formRefAgendamento = useRef<FormHandles>(null);
  const formRefPaciente = useRef<FormHandles>(null);
  const [pacienteID, setPacienteID] = useState<number | undefined>();
  const [paciente, setPaciente] = useState<IPaciente>();
  const [
    pacienteDadosObrigatorios,
    setPacienteDadosObrigatorios,
  ] = useState<IPacienteDadosObrigatorios>();

  const [status, setStatus] = useState(STATUS_AGENDA.AGENDADO);
  const [medico, setMedico] = useState({ id: 0, nome: '' });
  const [showModalMedico, setShowModalMedico] = useState(false);

  const [confirmado, setConfirmado] = useState(
    CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO,
  );

  const [agendamentoDados, setAgendamentoDados] = useState<
    { duracao: number; obs: string; guia: string } | undefined
  >(undefined);
  const [showHistorico, setShowHistorico] = useState(false);
  const [historico, setHistorico] = useState<IHistorico[]>([]);
  const [agendamentosPaciente, setAgendamentosPaciente] = useState<
    IAgendamentosPaciente[]
  >([]);
  const [atendimentos, setAtendimentos] = useState(0);
  const [faltas, setFaltas] = useState(0);
  const [procedimentos, setProcedimentos] = useState<IProcedimento[]>();

  const [procedimentosAgendamento, setProcedimentosAgendamento] = useState<
    IProcedimentoAgendamento[]
  >([]);
  const [dataSelecionada, setDataSelecionada] = useState(
    data ? parseISO(data.toString()) : new Date(),
  );
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [agendaId, setAgendaId] = useState(agenda_id);

  const { addToast } = useToast();

  const optionsHora = useMemo(() => {
    return optionsHorasAtendimento();
  }, []);

  const [optionsProcedimentos, setOptionsProcedimentos] = useState<IOptions[]>(
    [],
  );

  const handleVerificaDadosObrigatoriosPaciente = useCallback(
    (dados: IPaciente) => {
      if (validaDadosEnviarCaixa) {
        let mensagem = '';
        const dataNascimento = new Date(dados.dataNasc.toString());
        const menorIdade = differenceInYears(new Date(), dataNascimento) < 18;
        if (!menorIdade) {
          if (!dados.cpf || dados.cpf.length !== 11) {
            mensagem = 'CPF não cadastrado';
          } else if (!validarCPF(dados.cpf)) mensagem = 'CPF inválido';

          if (!dados.rg || dados.rg.length < 4)
            mensagem = `${mensagem} - RG não cadastrado`;
        }

        if (menorIdade) {
          if (!dados.responsavel_id) {
            mensagem = 'Responsável não cadastrado';
          }
        }

        if (mensagem === '') {
          setPacienteDadosObrigatorios({ dadosValidos: true, msg: '' });
          setBloqueiaDadosIncompletos(false);
        }
        if (mensagem !== '') {
          setPacienteDadosObrigatorios({ dadosValidos: false, msg: mensagem });
          setBloqueiaDadosIncompletos(true);
        }
      }
    },
    [validaDadosEnviarCaixa],
  );

  const [convenio, setConvenio] = useState<number | undefined>();

  const buscaProcedimentos = useCallback(
    async (dados: IBuscaProcedimento) => {
      setLoadingProcedimento(true);
      try {
        const { especialidadesBusca, convenioBusca, alterouConvenio } = dados;
        const response = await api.get<IProcedimento[]>(
          `/procedimentos/especialidade/${especialidadesBusca}?convenio_id=${convenioBusca}`,
        );

        const proceds = response.data.map(procedimento => {
          return {
            ...procedimento,
            valorFormatado: formatPrice(procedimento.valor),
          };
        });

        const optionsProcedimento = response.data.map(procedimento => {
          return {
            value: procedimento.id.toString(),
            label: procedimento.nome,
            isDisabled: false,
          };
        });

        if (alterouConvenio) {
          //  faz uma varredura para atualizar valor pois mudou convenio

          setProcedimentosAgendamento(state => {
            const procedimentosFiltrados = state.filter(pro =>
              proceds.some(b => pro.id === b.id),
            );

            if (!procedimentosFiltrados) return [];

            const novosProcedimentos = procedimentosFiltrados.map(pro => {
              const procedimentoFind = proceds.find(proc => proc.id === pro.id);
              return {
                ...pro,
                ...(procedimentoFind && {
                  valor: procedimentoFind.valor,
                  valorFormatado: procedimentoFind.valorFormatado,
                }),
              };
            });

            if (novosProcedimentos) return novosProcedimentos;
            return [];
          });
        }
        return {
          procedimentos: proceds,
          optionsProcedimento,
        };
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar procedimentos',
          type: 'error',
          description: mensagemErro,
        });
        return undefined;
      } finally {
        setLoadingProcedimento(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    async function Busca(): Promise<void> {
      await buscaConvenios();
    }
    if (!optionsConvenio || optionsConvenio.length === 0) Busca();
  }, [buscaConvenios, optionsConvenio, id]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IAgendamento>(
          `/agendamentos/${id}/detalhe`,
        );

        setAgendaId(response.data.agenda_id);
        setUpdatedAt(response.data.updated_at);
        const dataIni = parseISO(response.data.inicio.toString());
        const dataFim = parseISO(response.data.fim.toString());

        if (response.data.medicoSolicitante)
          setMedico({
            id: response.data.medicoSolicitante.id,
            nome: `${response.data.medicoSolicitante.nome} - ${response.data.medicoSolicitante.nomeConselho}: ${response.data.medicoSolicitante.numeroConselho}`,
          });

        setAgendamentoDados({
          obs: response.data.obs,
          duracao: differenceInMinutes(dataFim, dataIni),
          guia: response.data.guia,
        });
        if (response.data.status === STATUS_AGENDA.AGENDADO) {
          setStatus(
            response.data.confirmado ===
              CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO
              ? STATUS_AGENDA.AGENDADO
              : STATUS_AGENDA.AGENDADO + 1,
          );
        } else {
          setStatus(response.data.status);
        }

        setConfirmado(
          response.data.confirmado || CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO,
        );
        setDataSelecionada(dataIni);
        setPacienteID(response.data.cliente_id);

        setPaciente(response.data.cliente);

        handleVerificaDadosObrigatoriosPaciente(response.data.cliente);

        setAgendamentosPaciente(response.data.agendamentos);
        setAtendimentos(response.data.atendimentos);
        setFaltas(response.data.faltas);

        setConvenio(response.data.convenio_id);

        formRefAgendamento.current?.setFieldValue(
          'convenio',
          response.data.convenio_id.toString(),
        );

        if (
          response.data.status !== STATUS_AGENDA.AGENDADO ||
          (response.data.status === STATUS_AGENDA.AGENDADO &&
            response.data.origem !== ORIGEM_AGENDAMENTO.SISTEMA)
        ) {
          setAgendamentoBloqueado(true);
        } else {
          const responsePerfis = await api.get<number[]>(
            `/agendas/${response.data.agenda_id}/especialidades`,
          );

          setEspecialidades(responsePerfis.data.join(','));

          const respostaPro = await buscaProcedimentos({
            especialidadesBusca: responsePerfis.data.join(','),
            convenioBusca: response.data.convenio_id.toString(),
            alterouConvenio: false,
          });

          if (respostaPro) {
            setProcedimentos(respostaPro.procedimentos);
            setOptionsProcedimentos(respostaPro.optionsProcedimento);
          }
        }
        const procedimentosAgendamentoFormatados = response.data.procedimentos.map(
          pro => {
            return {
              id: pro.id,
              nome: pro.nome,
              valor: pro.valorCobrado,
              valorFormatado: formatPrice(pro.valorCobrado),
              duracao: pro.duracao,
            };
          },
        );
        setProcedimentosAgendamento(procedimentosAgendamentoFormatados);
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Não foi possível buscar o agendamento',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
    if (id && id !== 0) loadDados();
  }, [
    id,
    addToast,
    buscaProcedimentos,
    handleVerificaDadosObrigatoriosPaciente,
    semFoto,
  ]);

  useEffect(() => {
    async function busca(): Promise<void> {
      const resposta = await buscaProcedimentos({
        especialidadesBusca: especialidades || '0',
        convenioBusca:
          optionsConvenio && optionsConvenio.length > 0
            ? optionsConvenio[0].value
            : '1',
        alterouConvenio: false,
      });

      if (resposta) {
        setProcedimentos(resposta.procedimentos);
        setOptionsProcedimentos(resposta.optionsProcedimento);
      }
    }

    if (especialidades && especialidades.length !== 0 && !id) busca();
  }, [especialidades, id, buscaProcedimentos, optionsConvenio]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        setLoading(true);
        const response = await api.get<IPacienteMaisAgendamentosDTO>(
          `/clientes/${pacienteID}`,
        );
        setConvenio(Number(optionsConvenio[0].value));

        setPaciente(response.data);

        handleVerificaDadosObrigatoriosPaciente(response.data);

        setAgendamentosPaciente(response.data.agendamentosPaciente);
        setAtendimentos(response.data.atendimentos);
        setFaltas(response.data.faltas);
      } catch (error) {
        addToast({
          title: 'Não foi possível buscar o paciente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
    if (pacienteID && !id) loadDados();
  }, [
    pacienteID,
    id,
    addToast,
    handleVerificaDadosObrigatoriosPaciente,
    semFoto,
    optionsConvenio,
  ]);

  const handleSubmitPaciente = useCallback((dados: ProfileFormDados) => {
    console.log('foi', dados);
  }, []);

  const handleSelectClienteChange = useCallback(({ value, label }) => {
    if (value) {
      setPacienteID(Number(value));
    }
  }, []);

  // const handleProcedimentoChange = useCallback(
  //   ({ value, label }) => {
  //     if (value) {
  //       const procedimento = procedimentos?.find(
  //         proced => proced.id === Number(value),
  //       );
  //       setProcedimentoSelecionado(procedimento);
  //     } else {
  //       setProcedimentoSelecionado(undefined);
  //     }
  //   },
  //   [procedimentos],
  // );

  const total = useMemo(() => {
    const valor = procedimentosAgendamento.reduce((tot, prod) => {
      return tot + Number(prod.valor);
    }, 0);
    return {
      duracao: procedimentosAgendamento.reduce((tot, prod) => {
        return tot + Number(prod.duracao);
      }, 0),
      valor,
      valorFormatado: formatPrice(valor),
    };
  }, [procedimentosAgendamento]);

  const temProcedimentos = useMemo(() => {
    return !!(procedimentosAgendamento && procedimentosAgendamento.length > 0);
  }, [procedimentosAgendamento]);

  const handleAddProcedimento = useCallback(
    async ({ value, label }) => {
      if (!value) return;

      const procedimento = procedimentos?.find(
        proced => proced.id === Number(value),
      );
      if (!procedimento) return;
      const valorCobrado = procedimento.valor;

      setProcedimentosAgendamento(state => [
        ...state,
        {
          id: procedimento.id,
          nome: procedimento.nome,
          valor: valorCobrado,
          valorFormatado: formatPrice(valorCobrado),
          duracao: procedimento.duracao,
        },
      ]);
      // setProcedimentoSelecionado(undefined);

      const duracaoTotal = total.duracao + procedimento.duracao;
      if (formRefAgendamento && formRefAgendamento.current) {
        formRefAgendamento.current.setFieldValue(
          'duracaoTotal',
          duracaoTotal.toString(),
        );
      }
    },
    [procedimentos, total.duracao],
  );

  const handleRemoveProcedimento = useCallback((procedimento_id: Number) => {
    setProcedimentosAgendamento(state =>
      state.filter(pro => pro.id !== procedimento_id),
    );
  }, []);

  const handleSubmitMudaStatus = useCallback((statusCod: number) => {
    if (formRefAgendamento && formRefAgendamento.current) {
      formRefAgendamento.current.setFieldValue('status', statusCod.toString());
      formRefAgendamento.current.submitForm();
    }
  }, []);

  const dataHoraInitialDataForm = useMemo(() => {
    return {
      data: format(dataSelecionada, 'yyyy-MM-dd'),
      hora: format(dataSelecionada, 'HH:mm'),
      convenio: convenio?.toString(),
      status: status.toString(),
    };
  }, [convenio, dataSelecionada, status]);

  const handleAlteraDataAntecipado = useCallback(
    async (dados: IAgendamentoAntecipadoSubmit) => {
      try {
        const codigo = dados.id;
        const pacient = dados.paciente;
        delete dados.id;
        delete dados.paciente;
        const response = await api.put(
          `/agendamentoantecipado/${codigo}/alteradata`,
          dados,
        );
        handleFecharAgendamento({
          ...response.data,
          cliente: pacient || { id: 0, nome: '' },
        });
      } catch (error: any) {
        const mensagemErro = getResponseErrors(error);
        const statusErro = error?.response?.status;
        if (statusErro === 403) {
          setExibePermissaoAdmin(true);
          setMensagemPermissaoAdmin(mensagemErro);
        }
        addToast({
          type: 'error',
          title: 'Erro ao salvar agendamento',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleFecharAgendamento],
  );

  const handleEncaminhaAntecipado = useCallback(async () => {
    try {
      setLoading(true);
      if (!id) throw new Error('Nenhuma agenda selecionada.');
      const response = await api.put(`/agendamentoantecipado/${id}/encaminhar`);
      handleFecharAgendamento({
        ...response.data,
        cliente: paciente || { id: 0, nome: '' },
      });
    } catch (error: any) {
      const mensagemErro = getResponseErrors(error);
      const statusErro = error?.response?.status;
      if (statusErro === 403) {
        setExibePermissaoAdmin(true);
        setMensagemPermissaoAdmin(mensagemErro);
      }
      addToast({
        type: 'error',
        title: 'Erro ao salvar agendamento',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, handleFecharAgendamento, id, paciente]);

  const handleSalvarObservacao = useCallback(async () => {
    try {
      setLoading(true);
      if (!id) throw new Error('Nenhuma agenda selecionada.');
      const obs = formRefAgendamento.current?.getFieldValue('obs');
      const response = await api.put(`/agendamentos/observacao/${id}`, {
        obs,
        updated_at: updatedAt,
      });
      handleFecharAgendamento({
        ...response.data,
        cliente: paciente || { id: 0, nome: '' },
      });
    } catch (error: any) {
      const mensagemErro = getResponseErrors(error);
      const statusErro = error?.response?.status;
      if (statusErro === 403) {
        setExibePermissaoAdmin(true);
        setMensagemPermissaoAdmin(mensagemErro);
      }
      addToast({
        type: 'error',
        title: 'Erro ao salvar agendamento',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, handleFecharAgendamento, id, paciente, updatedAt]);

  const handleConfirmarAgenda = useCallback(
    async (confirmadoCod: number) => {
      try {
        setLoading(true);
        if (!id) throw new Error('Nenhuma agenda selecionada.');
        const response = await api.put(`/agendamentos/confirmar/${id}`, {
          updated_at: updatedAt,
          confirmado: confirmadoCod,
        });
        handleFecharAgendamento({
          ...response.data,
          cliente: paciente || { id: 0, nome: '' },
        });
      } catch (error: any) {
        const mensagemErro = getResponseErrors(error);
        const statusErro = error?.response?.status;
        if (statusErro === 403) {
          setExibePermissaoAdmin(true);
          setMensagemPermissaoAdmin(mensagemErro);
        }
        addToast({
          type: 'error',
          title: 'Erro ao salvar agendamento',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleFecharAgendamento, id, paciente, updatedAt],
  );

  const dadosMedico = useCallback(
    (medico_id: number): number | undefined => {
      if (medico_id.toString() !== '') return Number(medico_id);
      if (medico.id !== 0) return medico.id;
      return undefined;
    },
    [medico.id],
  );

  const handleSubmitAgendamento = useCallback(
    async (dados: AgendamentoFormDados) => {
      setLoading(true);
      try {
        formRefAgendamento.current?.setErrors({});
        const schema = Yup.object().shape({
          data: Yup.date().required(),
          hora: Yup.string().required('E-mail obrigatório'),
          duracaoTotal: Yup.number().required(),
          guia: Yup.string(),
          obs: Yup.string(),
          medico_id: Yup.string(),
          ...(exibePermissaoAdmin
            ? {
                email: Yup.string().required('Email é obrigatório'),
                senha: Yup.string().required('Senha é obrigatória'),
              }
            : {}),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        const dataAgendamento = parseISO(`${dados.data} ${dados.hora}:00`);

        const novoAgendamento = !id || id === 0;

        if (Number(dados.status) === STATUS_AGENDA.PAGAMENTO_ANTECIPADO) {
          // passar os parametros aqui
          handleAlteraDataAntecipado({
            id: id || 0,
            obs: dados.obs,
            inicio: dataAgendamento,
            fim: addMinutes(dataAgendamento, Number(dados.duracaoTotal)),
            ...(exibePermissaoAdmin
              ? { permissao: { email: dados.email, senha: dados.senha } }
              : {}),
            updated_at: updatedAt,
            confirmado,
            medico_id: dadosMedico(dados.medico_id) || 0,
            paciente: {
              id: pacienteID || 0,
              nome: paciente?.nome || '',
            },
          });
          return;
        }

        if (
          !procedimentosAgendamento ||
          procedimentosAgendamento.length === 0
        ) {
          addToast({ title: 'Adicione procedimentos', type: 'error' });
          return;
        }
        const agendamento = {
          ...(novoAgendamento
            ? {
                agenda_id: agendaId,
                cliente_id: pacienteID,
                formaPag: 9,
              }
            : { updated_at: updatedAt }),
          convenio_id: Number(dados.convenio),
          obs: dados.obs.toUpperCase(),
          guia: dados.guia.toUpperCase(),
          medico_id: dadosMedico(dados.medico_id),
          inicio: dataAgendamento,
          fim: addMinutes(dataAgendamento, Number(dados.duracaoTotal)),
          status:
            Number(dados.status) === STATUS_AGENDA.AGENDADO ||
            Number(dados.status) === STATUS_AGENDA.AGENDADO + 1
              ? STATUS_AGENDA.AGENDADO
              : Number(dados.status),
          confirmado,
          duracao: dados.duracaoTotal,
          procedimentos: procedimentosAgendamento.map(pro => {
            return {
              id: pro.id,
              nome: pro.nome,
              valor: pro.valor,
            };
          }),
          ...(exibePermissaoAdmin
            ? { permissao: { email: dados.email, senha: dados.senha } }
            : {}),
        };

        if (novoAgendamento) {
          const agendamentoSalvao = await api.post(
            '/agendamentos',
            agendamento,
          );

          addToast({ title: 'Agendamento salvo', type: 'success' });
          handleFecharAgendamento({
            ...agendamentoSalvao.data,
            cliente: { id: pacienteID, nome: paciente?.nome },
          });
        } else {
          const agendamentoAtualizado = await api.put(
            `/agendamentos/${id}`,
            agendamento,
          );

          addToast({ title: 'Agendamento atualizado', type: 'success' });
          handleFecharAgendamento({
            ...agendamentoAtualizado.data,
            cliente: { id: pacienteID, nome: paciente?.nome },
          });
        }
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefAgendamento.current?.setErrors(errors);
          return;
        }
        const mensagemErro = getResponseErrors(error);
        const statusErro = error?.response?.status;
        if (statusErro === 403) {
          setExibePermissaoAdmin(true);
          setMensagemPermissaoAdmin(mensagemErro);
        }
        addToast({
          type: 'error',
          title: 'Erro ao salvar agendamento',
          description: mensagemErro,
        });
      } finally {
        // setLoading(false);
      }
    },
    [
      exibePermissaoAdmin,
      id,
      procedimentosAgendamento,
      agendaId,
      pacienteID,
      updatedAt,
      dadosMedico,
      confirmado,
      handleAlteraDataAntecipado,
      paciente?.nome,
      addToast,
      handleFecharAgendamento,
    ],
  );

  const exibeAgendamento = useMemo(() => {
    return !!(pacienteID && agendaId && paciente);
  }, [pacienteID, agendaId, paciente]);

  const handleAlteraData = useCallback(() => {
    if (formRefAgendamento && formRefAgendamento.current) {
      const dataString = String(
        formRefAgendamento.current.getFieldValue('data'),
      );

      if (dataString.length !== 10) {
        return;
      }

      const dataConvertida = parseISO(dataString);

      setDataSelecionada(dataConvertida);
    }
  }, []);

  useEffect(() => {
    setBloqueiaDiaPassado(isBefore(setHours(dataSelecionada, 23), new Date()));
  }, [dataSelecionada]);

  useEffect(() => {
    if (confirmado > CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO)
      formRefAgendamento.current?.submitForm();
  }, [confirmado]);

  useEffect(() => {
    setOptionsProcedimentos(state =>
      state.map(opt => {
        return {
          ...opt,
          isDisabled: !!procedimentosAgendamento.find(
            pro => pro.id === Number(opt.value),
          ),
        };
      }),
    );
  }, [procedimentosAgendamento]);

  const handleCancelaAgendamento = useCallback(
    async (falta: boolean) => {
      setLoading(true);
      try {
        if (!id || id === 0) {
          addToast({
            title: 'Agendamento inválido',
            type: 'error',
          });
          return;
        }

        await api.delete(
          `/agendamentos/${id}?updated_at=${updatedAt}${
            falta ? '&adicionaFalta=true' : ''
          }`,
        );
        handleFecharAgendamento({
          id,
          inicio: new Date(),
          fim: new Date(),
          obs: '',
          status: falta ? STATUS_AGENDA.FALTA : STATUS_AGENDA.CANCELADO,
          confirmado,
          cliente: { id: 0, nome: 'teste' },
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível cancelar o agendamento',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [id, updatedAt, addToast, handleFecharAgendamento, confirmado],
  );

  const handleBuscaHistorico = useCallback(async () => {
    setLoadingHistorico(true);
    try {
      if (!id || id === 0) {
        addToast({
          title: 'Agendamento inválido',
          type: 'error',
        });
        return;
      }

      const response = await api.get<IHistorico[]>(
        `/agendamentos/historico/${id}`,
      );

      setHistorico(
        response.data.map(his => {
          return {
            ...his,
            dataFormatada: format(
              parseISO(his.data.toString()),
              'dd/MM/yyyy HH:mm:ss',
            ),
          };
        }),
      );
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível buscar o histórico',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoadingHistorico(false);
    }
  }, [id, addToast]);

  const handleExibeHistorico = useCallback(
    historicoEstaVisivel => {
      if (historicoEstaVisivel) setShowHistorico(false);
      if (!historicoEstaVisivel) {
        if (historico.length === 0) handleBuscaHistorico();
        setShowHistorico(true);
      }
    },
    [handleBuscaHistorico, historico.length],
  );

  const handleOnChangeConvenio = useCallback(
    async ({ value }) => {
      setConvenio(Number(value));

      const resposta = await buscaProcedimentos({
        especialidadesBusca: especialidades || '0',
        convenioBusca: value,
        alterouConvenio: true,
      });

      if (resposta) {
        setProcedimentos(resposta.procedimentos);
        setOptionsProcedimentos(resposta.optionsProcedimento);
      }
    },
    [buscaProcedimentos, especialidades],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handleCadastrouPaciente = useCallback((PacCodigo: number) => {
    setShowModalPacienteCad(false);
    setPacienteID(PacCodigo);
  }, []);

  return (
    <Container>
      {showModalPacienteCad && (
        <Modal
          titulo="Cadastro de Paciente"
          show={showModalPacienteCad}
          handleFecharModal={
            () => {
              setShowModalPacienteCad(false);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <PacienteCadastro
            exibeMenu={false}
            id={undefined}
            handleRetornaDados={dados => handleCadastrouPaciente(dados.id)}
          />
        </Modal>
      )}
      {loading && <Loading backgroundBlack={false} />}
      {showModalCancelar && (
        <Modal
          titulo="Cancelar agendamento"
          show={showModalCancelar}
          large={false}
          className="mt-4"
          handleFecharModal={() => setShowModalCancelar(false)}
        >
          <div className="text-center">
            <FiAlertCircle size={48} color="#dc3545" />
            <h6>
              Deseja apenas cancelar o agendamento ou adicionar na tabela de
              faltas para remarcação?
            </h6>
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                variant="danger"
                size="sm"
                className="float-right ml-2"
                onClick={() => handleCancelaAgendamento(false)}
                disabled={loading}
              >
                CANCELAR
              </Button>

              <Button
                type="button"
                variant="danger"
                size="sm"
                className="float-right ml-2"
                onClick={() => handleCancelaAgendamento(true)}
                disabled={loading}
              >
                ADICIONAR FALTA
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {exibeAgendamento ? (
        <>
          {paciente && pacienteID && (
            <>
              <PacienteCabecalho
                paciente={paciente}
                pacienteID={pacienteID}
                exibeEditar
                exibeInfoCartao
                handleAlterouDados={dados =>
                  handleVerificaDadosObrigatoriosPaciente(dados)
                }
              />
              {/* <AgendamentosPaciente
                id={pacienteID}
                nome={paciente.nome}
                agendamentos={agendamentosPaciente}
                atendimentos={atendimentos}
                faltas={faltas}
              /> */}

              {pacienteDadosObrigatorios &&
                pacienteDadosObrigatorios.dadosValidos === false && (
                  <div className="w-100">
                    <span
                      className="badge badge-pill badge-danger"
                      style={{ fontSize: '.9rem' }}
                    >
                      {pacienteDadosObrigatorios.msg}
                    </span>
                  </div>
                )}
            </>
          )}
          {(!id || agendamentoDados) && (
            <Form
              className="mt-3"
              ref={formRefAgendamento}
              initialData={{
                ...dataHoraInitialDataForm,
                duracaoTotal: agendamentoDados
                  ? agendamentoDados.duracao
                  : total.duracao,
                obs: agendamentoDados?.obs,
                guia: agendamentoDados?.guia,
              }}
              onSubmit={handleSubmitAgendamento}
            >
              {exibePermissaoAdmin && (
                <PermissaoAdmin className="shadow border">
                  <div>
                    <h6>Permissão necessária</h6>
                    <p>{mensagemPermissaoAdmin}</p>
                    <Input name="email" label="Código ou Email" />
                    <Input
                      name="senha"
                      label="Senha"
                      autoComplete="off"
                      type="password"
                    />
                    <Button
                      type="submit"
                      variant="primary mt-2"
                      size="sm"
                      disabled={loading}
                    >
                      {loading ? 'Enviando...' : 'Confirmar'}
                    </Button>

                    <Button
                      type="button"
                      variant="outline-secondary mt-2 float-right"
                      size="sm"
                      disabled={loading}
                      onClick={() => {
                        setExibePermissaoAdmin(false);
                        setMensagemPermissaoAdmin('');
                      }}
                    >
                      Voltar
                    </Button>
                  </div>
                </PermissaoAdmin>
              )}
              <Row>
                <Col md={12} className="my-2">
                  <Row noGutters>
                    <Col md={3}>
                      <Input
                        name="data"
                        label="Data"
                        type="date"
                        onBlur={handleAlteraData}
                      />
                    </Col>
                    <Col md={3} className="pl-2">
                      <SelectSync
                        name="hora"
                        label="Hora"
                        placeholder="Selecione"
                        isClearable={false}
                        // isDisabled={agendamentoBloqueado}
                        handleSelectChange={handleOnChangeVazio}
                        options={optionsHora}
                      />
                    </Col>
                    <Col md={3} className="pl-2">
                      <SelectSync
                        name="convenio"
                        label={
                          loadingProcedimento
                            ? 'Atualizando valores...'
                            : 'Convênio'
                        }
                        placeholder="Selecione"
                        isClearable={false}
                        handleSelectChange={handleOnChangeConvenio}
                        options={optionsConvenio}
                        isDisabled={agendamentoBloqueado}
                      />
                    </Col>
                    <Col md={3} className="pl-2">
                      <Input name="guia" label="Guia" />
                    </Col>
                    <Col md={12} className="mt-2">
                      <SelectAsyncCadastro
                        urlApi="medico/combo?termoBusca="
                        name="medico_id"
                        isDisabled={false}
                        valorSelecionado={medico}
                        labelSelect="Medico Solicitante"
                        showModal={showModalMedico}
                        setShowModal={setShowModalMedico}
                        limparValor={() => setMedico({ id: 0, nome: '' })}
                      >
                        <MedicoCadastro
                          medico={undefined}
                          setShowModal={setShowModalMedico}
                        />
                      </SelectAsyncCadastro>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="mt-2 bg-light px-2 py-3 rounded">
                {!agendamentoBloqueado && (
                  <>
                    <SelectSync
                      name="procedimento"
                      label="Adicionar procedimentos"
                      placeholder="Selecione"
                      containerStyle={{ backgroundColor: 'transparent' }}
                      handleSelectChange={handleAddProcedimento}
                      options={optionsProcedimentos}
                      isDisabled={agendamentoBloqueado}
                    />
                    <hr />
                  </>
                )}

                {procedimentosAgendamento &&
                  procedimentosAgendamento.length > 0 && (
                    <>
                      {procedimentosAgendamento.map(proced => (
                        <Row
                          className="align-items-center p-1"
                          key={`pro-${proced.id.toString()}`}
                        >
                          <Col xs={8} md={9}>
                            {proced.nome}
                          </Col>
                          <Col xs={2} md={2}>
                            {loadingProcedimento ? (
                              <Skeleton />
                            ) : (
                              proced.valorFormatado
                            )}
                          </Col>
                          <Col xs={2} md={1}>
                            {!agendamentoBloqueado && (
                              <Button
                                type="button"
                                variant="outline-danger"
                                size="sm"
                                className="py-0 border-0"
                                onClick={() => {
                                  handleRemoveProcedimento(proced.id);
                                }}
                              >
                                <FiTrash />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      ))}
                      <hr />
                      <Row className="mt-2 ">
                        <Col md={3}>
                          <Input
                            label="Duracao"
                            name="duracaoTotal"
                            type="number"
                            min="5"
                            max="120"
                            step="5"
                          />
                        </Col>
                        <Col
                          md={9}
                          className="d-flex justify-content-end align-items-end"
                          style={{ fontSize: '.9rem' }}
                        >
                          <span className="pr-1">Valor total:</span>
                          {loadingProcedimento ? (
                            <Skeleton width="66px" />
                          ) : (
                            <b>{total.valorFormatado}</b>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
              </div>
              <hr />
              <Row>
                <Col md={7}>
                  <TextArea name="obs" label="Observação" rows={5} />
                </Col>
                <Col md={5}>
                  <Select
                    label="Status"
                    name="status"
                    options={optionsStatusAgendamento}
                    disabled
                  />
                  <Row>
                    {(status === STATUS_AGENDA.AGENDADO ||
                      status === STATUS_AGENDA.AGENDADO + 1 ||
                      status === STATUS_AGENDA.PAGAMENTO_ANTECIPADO) &&
                      id &&
                      temProcedimentos && (
                        <>
                          <Col md={6}>
                            <Button
                              type="button"
                              style={{ marginTop: '18px' }}
                              size="sm"
                              variant="outline-secondary"
                              onClick={() =>
                                handleConfirmarAgenda(
                                  CONFIRMACAO_AGENDA.CONFIRMADO,
                                )
                              }
                              disabled={
                                (agendamentoBloqueado &&
                                  status !==
                                    STATUS_AGENDA.PAGAMENTO_ANTECIPADO) ||
                                bloqueiaDiaPassado ||
                                loading ||
                                confirmado >
                                  CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO
                              }
                            >
                              {confirmado ===
                              CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO ? (
                                <>
                                  <FiThumbsUp /> Confirmar{' '}
                                </>
                              ) : (
                                <>
                                  <FiThumbsUp /> Confirmado{' '}
                                </>
                              )}
                            </Button>
                          </Col>
                          <Col md={6}>
                            <Button
                              type="button"
                              style={{ marginTop: '18px' }}
                              size="sm"
                              variant="outline-secondary"
                              title="Desconfirmar"
                              onClick={() =>
                                handleConfirmarAgenda(
                                  CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO,
                                )
                              }
                              disabled={
                                (agendamentoBloqueado &&
                                  status !==
                                    STATUS_AGENDA.PAGAMENTO_ANTECIPADO) ||
                                bloqueiaDiaPassado ||
                                loading ||
                                confirmado ===
                                  CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO
                              }
                            >
                              <FiThumbsDown />
                            </Button>
                          </Col>
                        </>
                      )}
                  </Row>
                </Col>
              </Row>

              <br />
              <br />
              {temProcedimentos && !agendamentoBloqueado && (
                <Button
                  type="submit"
                  className="mr-2 px-4"
                  disabled={
                    agendamentoBloqueado || bloqueiaDiaPassado || loading
                  }
                >
                  Salvar
                </Button>
              )}
              {temProcedimentos && status === STATUS_AGENDA.FALTA && (
                <Button
                  type="button"
                  className="mr-2"
                  size="sm"
                  variant="outline-success"
                  onClick={() => setConfirmado(CONFIRMACAO_AGENDA.CONFIRMADO)}
                >
                  Reagendar
                </Button>
              )}

              {(status === CONFIRMACAO_AGENDA.CONFIRMADO ||
                status === CONFIRMACAO_AGENDA.CONFIRMADO + 1) &&
                temProcedimentos && (
                  <Button
                    type="button"
                    variant="secondary"
                    className="px-3"
                    onClick={() =>
                      handleSubmitMudaStatus(STATUS_AGENDA.ENCAMINHADO_CAIXA)
                    }
                    disabled={
                      agendamentoBloqueado ||
                      bloqueiaDiaPassado ||
                      bloqueiaDadosIncompletos ||
                      loading
                    }
                  >
                    Enviar Caixa
                  </Button>
                )}
              {status === STATUS_AGENDA.PAGAMENTO_ANTECIPADO && (
                <>
                  <Button
                    type="button"
                    className="ml-2"
                    size="sm"
                    disabled={bloqueiaDadosIncompletos || loading}
                    onClick={handleEncaminhaAntecipado}
                  >
                    Encaminhar para atendimento
                  </Button>
                  <Button
                    type="submit"
                    className="ml-2"
                    size="sm"
                    disabled={loading}
                  >
                    Alterar Data
                  </Button>
                  <Button
                    type="button"
                    className="ml-2"
                    variant="success"
                    size="sm"
                    disabled={loading}
                    onClick={handleSalvarObservacao}
                  >
                    Salvar Observação
                  </Button>
                </>
              )}

              {id && id !== 0 && !agendamentoBloqueado && (
                <Button
                  type="button"
                  variant="outline-secondary"
                  className="float-right ml-2 border-0"
                  onClick={() => setShowModalCancelar(true)}
                  disabled={loading}
                >
                  <FiXCircle /> Cancelar
                </Button>
              )}

              {id && id !== 0 && (
                <Button
                  type="button"
                  variant="outline-secondary"
                  className="float-right ml-2"
                  onClick={() => handleExibeHistorico(showHistorico)}
                >
                  {loadingHistorico && (
                    <Spinner>
                      <FiLoader />
                    </Spinner>
                  )}{' '}
                  Histórico
                </Button>
              )}
            </Form>
          )}
          {showHistorico && historico && historico.length > 0 && (
            <div className="w-100 p-2" style={{ clear: 'both' }}>
              <Row className="mt-2 bg-light py-1 border-bottom">
                <Col md={2}>
                  <b>Data</b>
                </Col>
                <Col xl={9} md={9}>
                  <b>Histórico</b>
                </Col>
              </Row>

              {historico.map(his => (
                <Row
                  className="align-items-center border-bottom p-1"
                  key={`his-${his.id.toString()}`}
                >
                  <Col xl={2} md={2}>
                    {his.dataFormatada}
                  </Col>
                  <Col xl={9} md={9}>
                    <span dangerouslySetInnerHTML={{ __html: his.log }} />
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <Form
            ref={formRefPaciente}
            style={{
              minHeight: '300px',
              padding: '.5rem 2rem',
              textAlign: 'center',
            }}
            onSubmit={handleSubmitPaciente}
          >
            <h5 className="text-muted">SELECIONE UM PACIENTE</h5>
            <SelectAsync
              name="pacienteID"
              label=""
              className="text-left"
              urlApi="clientes/combo?termoBusca="
              placeholder="Digite pelo menos 3 letras para buscar"
              handleSelectChange={handleSelectClienteChange}
            />

            <h5 className="text-muted mt-5">OU CADASTRE UM NOVO PACIENTE</h5>
            <Button
              className={loading ? 'd-none' : 'mt-3'}
              variant="outline-primary"
              size="sm"
              onClick={() => setShowModalPacienteCad(true)}
              disabled={loading}
            >
              <FiPlus /> Novo Paciente
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Agendamento;
