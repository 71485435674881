import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Paginacao from '../../../components/Paginacao';
import PerfilCadastro from '../PerfilCadastro';

import Modal from '../../../components/Modal';

import { optionsTipoPerfil } from '../../../utils/options';
import { IFormBusca } from '..';
import getResponseErrors from '../../../utils/getResponseErrors';

export interface IPaginacao {
  termoBusca: IFormBusca;
  limite: number;
  pagina: number;
}

export interface IPerfil {
  PerCodigo: number;
  PerNome: string;
  PerTipo: number;
  PerAtivo: boolean;
  ativoFormatado?: string;
  nomeTipo?: string;
}

export interface RefObject {
  addPerfil(dadosPerfil: IPerfil, perCodigo: number | undefined): Promise<void>;
}
type Props = {
  dados?: IFormBusca;
  ref: Ref<RefObject>;
};

const ListaPerfis: React.FC<Props> = forwardRef(
  (prop: Props, ref: Ref<RefObject>) => {
    const [showModalCadastro, setShowModalCadastro] = useState(false);
    const { addToast } = useToast();
    const [paginacao, setPaginacao] = useState<IPaginacao | undefined>(
      undefined,
    );
    const [loading, setLoading] = useState(false);
    const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
      undefined,
    );
    const [perfis, setPerfis] = useState<IPerfil[]>([]);

    const [perfiSelecionado, setPerfiSelecionado] = useState<
      IPerfil | undefined
    >(undefined);

    useEffect(() => {
      setPaginacao({
        termoBusca: prop.dados || { nome: '', tipo: '' },
        pagina: 1,
        limite: 20,
      });
    }, [prop.dados]);

    useEffect(() => {
      async function buscaPerfis(): Promise<void> {
        if (paginacao) {
          try {
            setLoading(true);
            const { nome, tipo } = paginacao.termoBusca;
            let query = '';
            if (nome !== '') query = `&PerNome=${nome}`;
            if (tipo !== '') query = `${query}&PerTipo=${tipo}`;

            const response = await api.get(
              `/perfis?pagina=${paginacao.pagina}&limite=${paginacao.limite}${query}`,
            );

            const perfisApi: IPerfil[] = response.data[0];

            const perfisFormatado = perfisApi.map((perf: IPerfil) => {
              return {
                ...perf,
                nomeTipo:
                  optionsTipoPerfil.find(
                    op => op.value === perf.PerTipo?.toString(),
                  )?.label || '',
                ativoFormatado: perf.PerAtivo ? 'Sim' : 'Não',
              };
            });

            setTotalRegistros(
              response.data[1] === 0 ? undefined : response.data[1],
            );

            setPerfis(perfisFormatado);
          } catch (error) {
            const mensagem = getResponseErrors(error);
            addToast({
              type: 'error',
              title: 'Não foi possível carregar perfis',
              description: mensagem,
            });
            setTotalRegistros(0);
            setPerfis([]);
          } finally {
            setLoading(false);
          }
        }
      }
      if (paginacao) buscaPerfis();
    }, [paginacao, addToast]);

    const deletePerfil = useCallback(
      async (codigo: number) => {
        setLoading(true);
        try {
          await api.delete(`/perfis/${codigo}`);
          setPerfis(perfisState =>
            perfisState?.filter(pro => pro.PerCodigo !== codigo),
          );
        } catch (error) {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível deletar perfil',
            description: mensagem,
          });
        } finally {
          setLoading(false);
        }
      },
      [addToast],
    );

    const addPerfil = useCallback(
      async (dadosPerfil: IPerfil, perCodigo: number | undefined) => {
        let response;
        setLoading(true);

        try {
          if (perCodigo) {
            response = await api.put<IPerfil>(
              `/perfis/${perCodigo}`,
              dadosPerfil,
            );
          } else {
            response = await api.post<IPerfil>('/perfis', dadosPerfil);
          }

          const retornoPerfil: IPerfil = {
            ...response.data,
            nomeTipo:
              optionsTipoPerfil.find(
                op => op.value === dadosPerfil.PerTipo.toString(),
              )?.label || '',
            ativoFormatado: dadosPerfil.PerAtivo ? 'Sim' : 'Não',
          };

          setPerfis(state => {
            return state
              ? [retornoPerfil].concat(
                  state.filter(p => p.PerCodigo !== retornoPerfil.PerCodigo),
                )
              : [retornoPerfil];
          });
          setShowModalCadastro(false);
          addToast({ title: 'Perfil salvo', type: 'success' });
        } catch (error) {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível salvar perfil',
            description: mensagem,
          });
        } finally {
          setLoading(false);
        }
      },
      [addToast],
    );

    useImperativeHandle(ref, () => ({ addPerfil }));

    const handleProximaPagina = useCallback((page: number) => {
      setPaginacao(state => {
        return state && { ...state, pagina: page };
      });
    }, []);

    const handleDeletePerfil = useCallback(
      async (codigo: number) => {
        try {
          await deletePerfil(codigo);
          addToast({
            type: 'success',
            title: 'Perfil excluído',
          });
        } catch (error) {
          const mensagemErro = getResponseErrors(error);

          addToast({
            type: 'error',
            title: 'Não foi possível excluir o perfil',
            description: mensagemErro,
          });
        }
      },
      [addToast, deletePerfil],
    );

    const handleEditar = useCallback(perf => {
      setPerfiSelecionado(perf);
      setShowModalCadastro(true);
    }, []);

    return (
      <>
        {showModalCadastro && (
          <Modal
            show={showModalCadastro}
            titulo="Cadastro de Perfil"
            handleFecharModal={() => setShowModalCadastro(false)}
            large
          >
            <PerfilCadastro perfil={perfiSelecionado} addPerfil={addPerfil} />
          </Modal>
        )}
        {loading && <Loading />}

        {perfis && perfis.length > 0 ? (
          <>
            <table
              className="table table-hover table-sm table-font-small"
              style={{ borderWidth: '0px' }}
            >
              <tbody>
                <tr className="bg-light" style={{ fontSize: 'Small' }}>
                  <th scope="col">Nome</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Ativo</th>
                  <th scope="col">&nbsp;</th>
                  <th scope="col">&nbsp;</th>
                </tr>
                {perfis.map((perf: IPerfil) => (
                  <tr
                    key={`perfil-${perf.PerCodigo}`}
                    style={{ fontSize: 'Small', height: '30px' }}
                    className={`${!perf.PerAtivo ? 'text-muted' : ''}`}
                  >
                    <td>{perf.PerNome}</td>
                    <td style={{ width: '100px' }}>{perf.nomeTipo}</td>
                    <td style={{ width: '60px' }}>{perf.ativoFormatado}</td>
                    <td className="p-0" style={{ width: '40px' }}>
                      <Button
                        type="button"
                        variant="primary-outline"
                        className="py-0"
                        title={perf.PerCodigo.toString()}
                        onClick={() => handleEditar(perf)}
                      >
                        <FaPencilAlt />
                      </Button>
                    </td>

                    <td className="p-0" style={{ width: '40px' }}>
                      <Button
                        type="button"
                        variant="secondary-outline"
                        className="py-0"
                        title={perf.PerCodigo.toString()}
                        onClick={() => {
                          handleDeletePerfil(perf.PerCodigo);
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginacao
              limitePagina={paginacao?.limite || 15}
              paginasVizinhas={2}
              totalRegistros={totalRegistros}
              paginaInicio={paginacao?.pagina}
              handleProximaPagina={handleProximaPagina}
            />
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: '250px' }}
          >
            <h5>
              Nenhum registro encontrado. Pesquise perfis pelo Nome e/ou tipo
            </h5>
          </div>
        )}
      </>
    );
  },
);

export default ListaPerfis;
