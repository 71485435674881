import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container } from 'react-bootstrap';

import { format, parseISO } from 'date-fns';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useClinica } from '../../hooks/clinica';

import Input from '../../components/Input';
import { IOption } from '../../components/SelectSync';

import Select from '../../components/Select';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import Loading from '../../components/Loading';
// import ListaAtendimentoExame from '../../components/ListaAtendimentoExame';
import ListaAtendimentoConsulta from './ListaAtendimentoConsulta';
import {
  formataTelefone,
  statusAgendamento,
  STATUS_AGENDA,
  TIPO_USUARIO,
} from '../../utils/funcoes';
import { ISendMessage } from '../../components/Chat';
import socket from '../../services/socket';
import PacienteHistorico from '../../components/PacienteHistorico';
import getResponseErrors from '../../utils/getResponseErrors';
import Toggle from '../../components/Exame/Toggle';
import { IListagemAgendas } from '../AgendaCadastro/ListaAgendas';
import ListaAtendimentoExame from './ListaAtendimentoExame';

interface IAgendaLista {
  id: number;
  nome: string;
  ativo: boolean;
  nomeAbreviado: string;
  tempoPadrao: number;
  atende: boolean;
  profissionalAgendaCompleta: boolean;
  integracaoAlvaro: boolean;
  especialidade: string;
  nome_profissional: string;
}

interface IRetornoListagemAgenda {
  data: IAgendaLista[];
  count: number;
}

export interface IFormBusca {
  dataBusca: string;
  agenda: string;
  somenteLista: string;
}

export interface IListaAgendamentosHojeDTO {
  id: number;
  dataLiberacao: Date;
  inicio: Date;
  fim: Date;
  status: number;
  confirmado: number;
  integracaoAlvaro: number;
  procedimento: string;
  dataPagamento: Date;
  cliente: {
    id: number;
    nome: string;
    telefone: string;
    celular: string;
    sexo: string;
    avatar_url?: string;
  };
  horario?: string;
  liberado?: string;
  icone?: { id: number; nome: string; color: string; icon: string };
  // atendido: boolean;
  exibeBotaoAtendimento: boolean;
  corLinha: string;
  exame?: {
    id: number;
    codigoOS: string;
    codItens: string;
  };
}

const Atendimento: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  function useQuery(): URLSearchParams {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();

  const { addToast } = useToast();
  const { user } = useAuth();
  const { imagensClinica } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const [loading, setLoading] = useState(false);
  const [loadingListaEspera, setLoadingListaEspera] = useState(false);
  const [listaEspera, setListaEspera] = useState<IListaAgendamentosHojeDTO[]>(
    [],
  );
  const [totalAtendido, setTotalAtendido] = useState<number>(0);

  const formRefBusca = useRef<FormHandles>(null);

  const [optionsAgenda, setOptionsAgenda] = useState<IOption[]>([]);
  const [agendas, setAgendas] = useState<IListagemAgendas[]>([]);
  const [integracaoAlvaro, setIntegracaoAlvaro] = useState(0);
  const [showModalHistorico, setShowModalHistorico] = useState(false);
  const [pacienteSelecionado, setPacienteSelecionado] = useState<
    number | undefined
  >(undefined);

  const [exibeAgendaCompleta, setExibeAgendaCompleta] = useState(false);

  const [dadosBusca, setDadosBusca] = useState(() => {
    return {
      codigoAgenda: query.get('agenda') || undefined,
      dataAgenda: query.get('data') || format(new Date(), 'yyyy-MM-dd'),
      somenteLista: query.get('somenteLista')
        ? query.get('somenteLista') === 'true'
        : true,
      atualiza: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };
  });

  useEffect(() => {
    if (dadosBusca.codigoAgenda) {
      const ag = agendas.find(
        (agen: IListagemAgendas) => agen.id === Number(dadosBusca.codigoAgenda),
      );
      if (ag) setIntegracaoAlvaro(ag.integracaoAlvaro ? 1 : 0);
    } else if (agendas[0])
      setIntegracaoAlvaro(agendas[0].integracaoAlvaro ? 1 : 0);
  }, [agendas, dadosBusca]);

  useEffect(() => {
    const handleNewAtendimento = (codAgenda: number): void => {
      if (
        dadosBusca.codigoAgenda &&
        codAgenda === Number(dadosBusca.codigoAgenda)
      )
        formRefBusca.current?.submitForm();
    };

    socket.on('atendimento', handleNewAtendimento);
    return () => {
      socket.removeEventListener('atendimento', handleNewAtendimento);
    };
  }, [dadosBusca.codigoAgenda]);

  useEffect(() => {
    async function loadAgendas(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IRetornoListagemAgenda>(
          `listagemAgendas?ativo=true&atende=true&limite=30`,
        );
        const { data } = response.data;

        setAgendas(data);

        const options: IOption[] = data.map(lista => {
          return {
            label: `${lista.nomeAbreviado} / ${lista.nome}`,
            value: lista.id.toString(),
          };
        });

        setOptionsAgenda(options);

        if (!dadosBusca.codigoAgenda) {
          if (data.length > 0) {
            setDadosBusca(state => {
              return {
                ...state,
                somenteLista: !data[0].profissionalAgendaCompleta,
              };
            });

            if (data[0].profissionalAgendaCompleta !== undefined)
              setExibeAgendaCompleta(data[0].profissionalAgendaCompleta);
          }
        } else {
          const exibiCompleta =
            data.find(con => con.id === Number(dadosBusca.codigoAgenda))
              ?.profissionalAgendaCompleta || false;
          setExibeAgendaCompleta(exibiCompleta);
        }
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível listar agendas',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (user.tipo !== TIPO_USUARIO.MEDICO) {
      history.push('/dashboard');
    } else {
      loadAgendas();
    }
  }, [user.id, user.tipo, addToast, history, dadosBusca.codigoAgenda]);

  const initialData = {
    dataBusca: dadosBusca.dataAgenda,
    agenda: dadosBusca.codigoAgenda || '',
    somenteLista: dadosBusca.somenteLista,
  };

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    formRefBusca.current?.setErrors({});
    const schema = Yup.object().shape({
      dataBusca: Yup.date()
        .typeError('Digite uma data válida')
        .required('Obrigatório'),
      agenda: Yup.number()
        .min(1, 'Selecione uma agenda')
        .typeError('Selecione uma agenda')
        .required('Obrigatório'),
    });

    await schema.validate(dados, {
      abortEarly: false,
    });

    setDadosBusca({
      codigoAgenda: dados.agenda,
      dataAgenda: dados.dataBusca,
      somenteLista: dados.somenteLista.toString() === 'true',
      atualiza: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });
  }, []);

  useEffect(() => {
    async function buscarLista(): Promise<void> {
      setLoadingListaEspera(true);
      try {
        const response = await api.get<IListaAgendamentosHojeDTO[]>(
          `atendimentos/emespera?agenda_id=${
            dadosBusca.codigoAgenda
          }&data=${format(
            parseISO(dadosBusca.dataAgenda),
            'yyyy-MM-dd',
          )}&completa=${
            exibeAgendaCompleta
              ? !(dadosBusca.somenteLista.toString() === 'true')
              : false
          }`,
        );
        if (response.data.length === 0) setListaEspera([]);

        const retornoFormatado = response.data.map(lista => ({
          ...lista,
          horario: format(parseISO(lista.inicio.toString()), 'HH:mm'),
          liberado:
            lista.dataLiberacao &&
            lista.dataLiberacao.toString() !== '0000-00-00 00:00:00'
              ? format(parseISO(lista.dataLiberacao.toString()), 'HH:mm')
              : '',
          cliente: {
            ...lista.cliente,
            celular: formataTelefone(lista.cliente.celular),
            telefone: formataTelefone(lista.cliente.telefone),
            avatar_url: lista.cliente.avatar_url
              ? lista.cliente.avatar_url
              : semFoto,
          },

          icone: statusAgendamento.find(
            status => status.id === Number(lista.status),
          ),
          // atendido: lista.status === STATUS_AGENDA.ATENDIDO,
          corLinha:
            lista.status === STATUS_AGENDA.AGUARDANDO_ATENDIMENTO
              ? 'text-purple'
              : '',
          exibeBotaoAtendimento:
            lista.status < STATUS_AGENDA.AGUARDANDO_ATENDIMENTO &&
            lista.status !== STATUS_AGENDA.PAGAMENTO_ANTECIPADO,
        }));
        setListaEspera(retornoFormatado);
        const totalAtendid = response.data.filter(
          age => age.status === STATUS_AGENDA.ATENDIDO,
        );
        setTotalAtendido(totalAtendid ? totalAtendid.length : 0);
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Não foi possível carregar lista de espera',
            type: 'error',
            description: mensagemErro,
          });
        }
      } finally {
        setLoadingListaEspera(false);
      }
    }

    if (dadosBusca.codigoAgenda) buscarLista();
  }, [
    dadosBusca,
    history,
    location.search,
    addToast,
    exibeAgendaCompleta,
    semFoto,
  ]);

  const handleOnChange = useCallback(
    event => {
      const ag = agendas.find(
        (agen: IListagemAgendas) => agen.id === Number(event.target.value),
      );

      if (ag) {
        setDadosBusca(state => {
          return {
            ...state,
            codigoAgenda: ag.id.toString(),
            ...(ag.profissionalAgendaCompleta ? {} : { somenteLista: true }),
          };
        });
        setExibeAgendaCompleta(ag.profissionalAgendaCompleta);
        setIntegracaoAlvaro(ag.integracaoAlvaro ? 1 : 0);
      }
    },
    [agendas],
  );

  const handleTogleOnChange = useCallback(valor => {
    setDadosBusca(state => {
      return {
        ...state,
        somenteLista: valor,
      };
    });
  }, []);

  const handleChamarPaciente = useCallback(
    (pacNome: string) => {
      const mensagem: ISendMessage = {
        clinica: user.clinica_id.toString(),
        from: user.nome,
        idFrom: user.id,
        to: 'chamar_paciente',
        idTo: 0,
        mensagem: `Chamar Paciente ${pacNome}`,
        lida: false,
        created_at: new Date(),
      };
      socket.emit('chamar_paciente', mensagem);
    },
    [user],
  );

  const handleHistorico = useCallback(pacCodigo => {
    setPacienteSelecionado(pacCodigo);
    setShowModalHistorico(true);
  }, []);

  const handleFecharHistorico = useCallback(() => {
    setPacienteSelecionado(undefined);
    setShowModalHistorico(false);
  }, []);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading backgroundBlack={false} />}
      <>
        {showModalHistorico && (
          <Modal
            show={showModalHistorico}
            titulo="Historico"
            handleFecharModal={handleFecharHistorico}
            large
          >
            {pacienteSelecionado && (
              <PacienteHistorico cliente_id={pacienteSelecionado} />
            )}
          </Modal>
        )}
        {!loading && optionsAgenda.length > 0 && (
          <>
            <div className="bg-white border rounded p-2 mb-2">
              <Form
                ref={formRefBusca}
                initialData={initialData}
                onSubmit={handleSubmitBusca}
              >
                <div className="row">
                  <div className="col-md-2 form-group">
                    <Input
                      name="dataBusca"
                      label="Data"
                      placeholder=""
                      type="date"
                    />
                  </div>
                  <div className="col-md-5 form-group">
                    <Select
                      name="agenda"
                      label="Agenda"
                      placeholder="Selecione"
                      options={optionsAgenda}
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className="col-md-2 form-group">
                    <div className={`${!exibeAgendaCompleta && 'd-none'}`}>
                      <Toggle
                        nome="somenteLista"
                        label="Somente em espera"
                        isDisabled={!exibeAgendaCompleta}
                        handleOnChange={handleTogleOnChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-group-append mt-3">
                      <Button type="submit" variant="secondary-outline">
                        BUSCAR
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>

            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-3">
                    <h5 id="topoBusca" className="card-title">
                      Pacientes
                    </h5>
                  </div>
                  <div className="col d-flex justify-content-end align-items-center">
                    Total Atendidos{' '}
                    <span className="bg-secondary text-white px-2 py-1 ml-1">
                      {totalAtendido}
                    </span>
                  </div>
                </div>
              </div>

              <div className="card-body p-0">
                {integracaoAlvaro === 1 ? (
                  <ListaAtendimentoExame
                    dadosBusca={dadosBusca}
                    listaEspera={listaEspera}
                    chamar={handleChamarPaciente}
                    abrirHistorico={handleHistorico}
                    loading={loadingListaEspera}
                  />
                ) : (
                  <ListaAtendimentoConsulta
                    loading={loadingListaEspera}
                    dadosBusca={dadosBusca}
                    listaEspera={listaEspera}
                    chamar={handleChamarPaciente}
                    abrirHistorico={handleHistorico}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {loading && (
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Pacientes</h5>
            </div>
            <div className="card-body py-5">
              <h4>Carregando agendas...</h4>
            </div>
          </div>
        )}
        {!loading && optionsAgenda.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '5%',
            }}
          >
            <span>
              <h3>
                Profissional não possui agenda configurada para atender em
                consultório
              </h3>
            </span>
          </div>
        )}
      </>
    </Container>
  );
};

export default Atendimento;
