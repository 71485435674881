import React from 'react';

import { Container } from './styles';

interface InputProps {
  value: string | number | undefined;
  containerStyle?: React.CSSProperties;
  large?: boolean;
  label?: string;
  disabled?: boolean;
}

const InputFake: React.FC<InputProps> = ({
  value,
  containerStyle = {},
  large = false,
  label = '',
  disabled = false,
  ...rest
}) => {
  return (
    <>
      {label && (
        <b style={{ marginBottom: '4px', display: 'block' }}>{label}</b>
      )}
      <Container
        style={containerStyle}
        // isErrored={!!error}
        large={large}
        disabled={disabled}
      >
        <div>{value}</div>

        {/* {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />{' '}
          </Error>
        )} */}
      </Container>
    </>
  );
};

export default InputFake;
