import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';

import LayoutImpressao from '../../components/LayoutImpressao';
import Documento from './documento';
import Prontuario from './prontuario';
import ProntuarioImpresso from './prontuarioImpresso';
import PedidoExame from './pedidoExame';
import PedidoExameNaoAgendado from './pedidoExameNaoAgendado';

export interface IAssinatura {
  nome: string;
  especialidade: string;
  documento: string;
  conselho: string;
}
export interface IDadosRodape {
  CliCodigo: number;
  assinatura?: IAssinatura;
}

interface IUrlParams {
  id: string;
  tipo: string;
}

const ImpressaoDocumento: React.FC = () => {
  const { params } = useRouteMatch<IUrlParams>();
  const { tipo, id } = params;
  const [cliCodigo, setCliCodigo] = useState<number>();
  const [dadosAssinatura, setDadosAssinatura] = useState<IAssinatura>();

  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  // const duasVias = true;
  const duasVias = useMemo(() => {
    return !!(query.get('vias') && query.get('vias') === '2');
  }, [query]);

  // console.log(duasVias, query.get('vias'));

  const handleRetornaDadosParaRodape = useCallback((dados: IDadosRodape) => {
    setCliCodigo(dados.CliCodigo);
    setDadosAssinatura(dados.assinatura);
  }, []);

  const handleTerminouBusca = useCallback(() => {
    setTimeout(() => {
      window.print();
    }, 2000);
  }, []);

  return (
    <div className={duasVias ? 'd-flex justify-content-between' : ''}>
      <LayoutImpressao
        CliCodigo={cliCodigo}
        assinatura={dadosAssinatura}
        duasVias={duasVias}
        rodape
      >
        {id && tipo === 'documento' && (
          <Documento
            DocCodigo={Number(id)}
            duasVias={duasVias}
            handleDadosRodape={handleRetornaDadosParaRodape}
            handleTerminouBusca={handleTerminouBusca}
          />
        )}
        {id && tipo === 'prontuario' && (
          <Prontuario
            AgeCodigo={Number(id)}
            handleDadosRodape={handleRetornaDadosParaRodape}
          />
        )}
        {id && tipo === 'prontuarioimpresso' && (
          <ProntuarioImpresso
            AgeCodigo={Number(id)}
            handleDadosRodape={handleRetornaDadosParaRodape}
          />
        )}
        {id && tipo === 'pedidoexame' && (
          <PedidoExame
            AgeCodigo={Number(id)}
            handleDadosRodape={handleRetornaDadosParaRodape}
          />
        )}
        {id && tipo === 'pedidoexamenaoagendado' && (
          <PedidoExameNaoAgendado
            PedCodigo={Number(id)}
            handleDadosRodape={handleRetornaDadosParaRodape}
          />
        )}
      </LayoutImpressao>
      {duasVias && (
        <LayoutImpressao
          CliCodigo={cliCodigo}
          assinatura={dadosAssinatura}
          duasVias={duasVias}
          rodape
        >
          {id && tipo === 'documento' && (
            <Documento
              DocCodigo={Number(id)}
              duasVias={duasVias}
              handleDadosRodape={handleRetornaDadosParaRodape}
            />
          )}
        </LayoutImpressao>
      )}
    </div>
  );
};

export default ImpressaoDocumento;
