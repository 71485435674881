import styled from 'styled-components';

import Tooltip from '../Tooltip';

// interface ContainerProps {
//   isFocused: boolean;
//   isFilled: boolean;
//   isErrored: boolean;
// }

// export const Container = styled.div<ContainerProps>`
//   width: 100%;
//   border-radius: 5px;
//   background-color: #fff;

//   & + div {
//     margin-top: 8px;
//   }

//   ${props =>
//     props.isErrored &&
//     css`
//       > div > div {
//         border-color: #c53030 !important;
//       }
//     `}

//   ${props =>
//     props.isFocused &&
//     css`
//       > div > div {
//         border-color: #ff9000 !important;
//       }
//     `}

//   ${props =>
//     props.isFilled &&
//     css`
//       > div > div {
//         color: #ff9000;
//       }
//     `}
// `;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 12px;
  position: absolute;
  right: 58px;
  top: 27px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
