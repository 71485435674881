/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import { FaFlask, FaMoneyBill, FaThumbsUp, FaPercent } from 'react-icons/fa';

import api from '../../../services/api';

import {
  TIPO_USUARIO,
  formatPrice,
  formatNumber2CasasDecimais,
} from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';

import Select from '../../../components/Select';
import { IOption } from '../../../components/SelectSync';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';

import { RelatorioHeader, CardHeader, CardIconContainer } from './styles';
import getResponseErrors from '../../../utils/getResponseErrors';

interface IRetornoDescricaoTotal {
  descricao: string;
  total: number;
  totalFormatado: string;
}
interface IRelatorio {
  totalCobrado: number;
  totalRecebido: number;
  totalDespesa: number;
  lucro: number;
  lucroPercentual: string;
  despesas: IRetornoDescricaoTotal[];
  despesaInicio: Date;
  despesaFim: Date;
  totalCobradoFormatado: string;
  totalRecebidoFormatado: string;
  totalDespesaFormatado: string;
  lucroFormatado: string;
  despesaInicioFormatado: string;
  despesaFimFormatado: string;
}

interface IFormBusca {
  Fatura: string;
  clinica_id: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

const LucroExamesAlvaro: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica, imagensClinica } = useClinica();

  const logo =
    imagensClinica.find(img => img.nome === 'logoRelatorio')?.arquivo_url || '';

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [optionsFaturas, setOptionsFaturas] = useState<IOption[]>([]);
  const [dadosRelatorio, setdadosRelatorio] = useState<IRelatorio>();

  const initialData = {
    clinica_id: user.clinica_id.toString(),
  };
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinica, setClinica] = useState('');

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('/relatorios/faturasalvaro');

        setOptionsFaturas(response.data);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível buscar dados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }

    loadDados();
  }, [addToast]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          clinica_id: Yup.string().nullable(true),
          Fatura: Yup.string().required(),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinica(
          optionsClinica.find(cli => cli.value === dados.clinica_id)?.label ||
            '',
        );

        const clinicaBusca = dados.clinica_id
          ? `&clinica_id=${dados.clinica_id}`
          : '';

        const response = await api.get<IRelatorio>(
          `/relatorios/lucroalvaro?Fatura=${dados.Fatura}${clinicaBusca}`,
        );

        const { totalCobrado, totalRecebido, totalDespesa } = response.data;

        const lucro = totalRecebido - totalDespesa;

        const lucroPercentual = (lucro / totalRecebido) * 100;

        const dadosFormatados = {
          ...response.data,
          lucro,
          lucroFormatado: formatPrice(lucro),
          lucroPercentual: formatNumber2CasasDecimais(
            lucroPercentual.toString(),
          ),
          totalCobradoFormatado: formatPrice(totalCobrado),
          totalRecebidoFormatado: formatPrice(totalRecebido),
          totalDespesaFormatado: formatPrice(totalDespesa),
          despesaInicioFormatado: format(
            parseISO(response.data.despesaInicio.toString()),
            'dd/MM/yyyy',
          ),
          despesaFimFormatado: format(
            parseISO(response.data.despesaFim.toString()),
            'dd/MM/yyyy',
          ),
          despesas: response.data.despesas.map(des => {
            return { ...des, totalFormatado: formatPrice(des.total) };
          }),
        };

        setDataExibe({
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
          dataDiaSemana: '',
        });

        setdadosRelatorio(dadosFormatados);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica],
  );

  return (
    <Container fluid className="mt-2">
      {loading && <Loading />}
      <>
        <div className="d-print-none bg-white border p-2 mb-2">
          <div className="p-2 mb-2">
            <h2 id="topoBusca">Lucro Exames Cobrados Álvaro</h2>
          </div>
          {optionsClinica.length > 0 && user && (
            <Form
              ref={formRefBusca}
              initialData={initialData}
              onSubmit={handleSubmitBusca}
            >
              <Row>
                <Col md={3}>
                  <Select
                    name="Fatura"
                    label="Fatura"
                    options={optionsFaturas}
                  />
                </Col>
                <Col md={3}>
                  <Select
                    name="clinica_id"
                    label="Clínica"
                    options={optionsClinica}
                    disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                  />
                </Col>
                <Col md={2}>
                  <br />
                  <Button type="submit" size="sm" variant="outline-secondary">
                    BUSCAR
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>

        <div className="bg-white border p-2 my-3">
          <img src={logo} alt="Agenda Facil" /> Impresso em{' '}
          {dataExibe?.dataHora}
        </div>

        <RelatorioHeader>
          LUCRO EXAMES ÁLVARO
          {dadosRelatorio && (
            <>
              {' '}
              FATURA DE {dadosRelatorio.despesaInicioFormatado} ATÉ
              {dadosRelatorio.despesaFimFormatado}
              {` - ${clinica}`}
            </>
          )}
        </RelatorioHeader>
        <div className="bg-white border p-2">
          {dadosRelatorio && (
            <>
              <Row className="my-5">
                <Col md={6} lg={4} xl={3}>
                  <div className="shadow">
                    <CardHeader className="card-header">
                      <CardIconContainer className="bg-danger">
                        <FaFlask size={22} />
                      </CardIconContainer>
                      <p>*Valor Cobrado</p>
                      <h3 className="card-title">
                        {dadosRelatorio.totalCobradoFormatado}
                      </h3>
                    </CardHeader>
                  </div>
                </Col>
                <Col md={6} lg={4} xl={3}>
                  <div className="shadow mt-4 mt-md-0">
                    <CardHeader className="card-header">
                      <CardIconContainer className="bg-primary">
                        <FaMoneyBill size={22} />
                      </CardIconContainer>
                      <p>**Valor Recebido</p>
                      <h3 className="card-title">
                        {dadosRelatorio.totalRecebidoFormatado}
                      </h3>
                    </CardHeader>
                  </div>
                </Col>
                <Col md={6} lg={4} xl={2}>
                  <div className="shadow mt-4 mt-md-5 mt-lg-0">
                    <CardHeader className="card-header">
                      <CardIconContainer className="bg-danger">
                        <FaFlask size={22} />
                      </CardIconContainer>
                      <p>Valor Despesas</p>
                      <h3 className="card-title">
                        {dadosRelatorio.totalDespesaFormatado}
                      </h3>
                    </CardHeader>
                  </div>
                </Col>

                <Col md={6} lg={4} xl={2}>
                  <div className="shadow mt-4 mt-md-5 mt-lg-5 mt-xl-0">
                    <CardHeader className="card-header">
                      <CardIconContainer className="bg-success">
                        <FaThumbsUp size={22} />
                      </CardIconContainer>
                      <p>Lucro</p>
                      <h3 className="card-title">
                        {dadosRelatorio.lucroFormatado}
                      </h3>
                    </CardHeader>
                  </div>
                </Col>
                <Col md={6} lg={4} xl={2}>
                  <div className="shadow mt-4 mt-md-5 mt-lg-5 mt-xl-0">
                    <CardHeader className="card-header">
                      <CardIconContainer className="bg-primary">
                        <FaPercent size={22} />
                      </CardIconContainer>
                      <p>%</p>
                      <h3 className="card-title">
                        {dadosRelatorio.lucroPercentual}
                      </h3>
                    </CardHeader>
                  </div>
                </Col>
              </Row>
              <p>
                * Valor total dos exames importados da fatura do Álvaro <br />
                ** Valores dos exames enviados via integração, exames não
                enviados via integração não entram no somatório
              </p>
              <div className="d-flex w-100 justify-content-center mt-4">
                <div>
                  <b>
                    Despesas no período da fatura de{' '}
                    {dadosRelatorio.despesaInicioFormatado} até{' '}
                    {dadosRelatorio.despesaFimFormatado}
                  </b>
                  <table
                    className="table table-hover table-sm table-font-small table-bordered"
                    style={{ borderWidth: '0px', maxWidth: '400px' }}
                  >
                    <thead>
                      <tr className="text-center">
                        <th>Categoria</th>
                        <th style={{ width: '115px' }}>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dadosRelatorio.despesas.map(des => (
                        <tr
                          key={`des-${des.descricao}`}
                          style={{
                            fontSize: '.7rem',
                            height: '30px',
                          }}
                        >
                          <td>{des.descricao}</td>
                          <td>{des.totalFormatado}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </Container>
  );
};

export default LucroExamesAlvaro;
