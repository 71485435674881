/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Card } from 'react-bootstrap';

import { format } from 'date-fns';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import Loading from '../../components/Loading';

import { Container, AgendaDoDiaContainer, AgendaDoDiaButton } from './styles';
import getResponseErrors from '../../utils/getResponseErrors';

interface IAgendasDoDia {
  ConCodigo: number;
  ConNome: string;
  HorHoraIni: string;
  HorHoraFim: string;
  Agendamentos: number;
  Disponivel: boolean;
}

const Agenda: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [agendasDoDia, setAgendasDoDia] = useState<IAgendasDoDia[]>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IAgendasDoDia[]>(
          `agendas/agendasdodia?data=${format(new Date(), 'yyyy-MM-dd')}`,
        );

        response.data.sort(
          (a, b) => Number(b.Disponivel) - Number(a.Disponivel),
        );

        setAgendasDoDia(response.data);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar agendas do dia',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    loadDados();
  }, [addToast]);

  return (
    <Container>
      {loading && <Loading backgroundBlack={false} />}
      {agendasDoDia && agendasDoDia.length > 0 ? (
        <>
          <h5 className="text-center my-4">
            Agendas configuradas para a data de hoje.
            <Link
              to="agendaenvioconfirmacao"
              className="float-right btn btn-sm btn-primary"
            >
              Enviar confirmação
            </Link>
          </h5>

          <AgendaDoDiaContainer>
            {agendasDoDia.map(agen => (
              <AgendaDoDiaButton
                key={`agen-${agen.ConCodigo}`}
                type="button"
                onClick={() => history.push(`/agenda/${agen.ConCodigo}`)}
                Disponivel={agen.Disponivel}
              >
                {/* {agen.Disponivel && <FaCheckCircle size={14} color="green" />}{' '} */}
                <h6>{agen.ConNome}</h6>
                {agen.Disponivel && (
                  <div>{`${agen.HorHoraIni} as ${agen.HorHoraFim}`}</div>
                )}
              </AgendaDoDiaButton>
            ))}
          </AgendaDoDiaContainer>
        </>
      ) : (
        <Card
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '250px' }}
        >
          <h5>Nenhuma agenda configurada para a data de hoje.</h5>
        </Card>
      )}
    </Container>
  );
};

export default Agenda;
