/* eslint-disable import/no-duplicates */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useRouteMatch, useLocation, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  addDays,
  format,
  parseISO,
  startOfWeek,
  differenceInMinutes,
  endOfDay,
  isWithinInterval,
  differenceInDays,
  startOfDay,
  isSameDay,
  isSaturday,
  isMonday,
  isSunday,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Button, ButtonGroup, Row, Col, Container } from 'react-bootstrap';

import {
  FiChevronLeft,
  FiChevronRight,
  FiCalendar,
  FiInfo,
  FiSlash,
  FiHelpCircle,
  FiCheckCircle,
  FiDollarSign,
  FiClock,
  FiThumbsUp,
  FiThumbsDown,
  FiChevronDown,
} from 'react-icons/fi';

import { FaWhatsapp } from 'react-icons/fa';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useClinica } from '../../hooks/clinica';
import api from '../../services/api';
import {
  CONFIRMACAO_AGENDA,
  statusAgendamento,
  STATUS_AGENDA,
} from '../../utils/funcoes';

import Agendamento from '../../components/Agendamento';
import TrocarAgenda from '../../components/TrocarAgenda';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Loading from '../../components/Loading';

import {
  HeaderAgenda,
  Calendario,
  DiaCabecalho,
  Hora,
  DiasContainer,
  Marcacao,
  AgendamentoContainer,
  CalendarioAddContainer,
  ButtonAdd,
} from './styles';
import getResponseErrors from '../../utils/getResponseErrors';

export interface IConfigAgendaBasica {
  id: number;
  especialidade: string;
  especialidade_ids: string;
  nome: string;
  tempoPadrao: number;
  obs: string;
  clinica_id: number;
  semanal: number;
}
interface IConfigAgenda extends IConfigAgendaBasica {
  horarios?: [
    {
      id: number;
      agenda_id: number;
      dia: number;
      intervaloIni: string;
      intervaloFim: string;
      horaIni: string;
      horaFim: string;
    },
  ];
  horarioRecorrente?: {
    id: number;
    agenda_id: number;
    dataIni: Date;
    diasRecorre: number;
    intervaloIni: string;
    intervaloFim: string;
    horaIni: string;
    horaFim: string;
  };
  perfil: {
    PerCodigo: number;
    PerNome: string;
    PerTipo: number;
  }[];
}

interface IAgenda {
  agenda: IConfigAgenda;
  disponibilidade: [
    {
      data: Date;
      dataFormatada: string;
      temHorarioDisponivel: boolean;
      horarios: [
        {
          data: Date;
          hora: number;
          minuto: string;
          liberado: boolean;
          obs: string;
        },
      ];
    },
  ];
}

interface IAgendamento {
  id: number;
  obs: string;
  inicio: Date;
  fim: Date;
  status: number;
  confirmado: number;
  cliente: {
    id: number;
    nome: string;
  };
  top?: number;
  left?: string;
  width?: string;
  height?: string;
  statusFormatado?: {
    id: number;
    nome: string;
    color: string;
    icon: string;
  };
}

// interface IAgendamentoHoje {
//   id: number;
//   inicio: Date;
//   fim: Date;
//   horaFormatada: string;
//   status: number;
//   confirmado: number;
//   PacNome: string;
//   eRetorno: boolean;
//   convenio: string;
//   statusFormatado?: {
//     id: number;
//     nome: string;
//     color: string;
//     icon: string;
//   };
// }
interface IAgendaParams {
  id: string;
  dia: string;
}

interface IIcons {
  [key: string]: JSX.Element;
}

const icons: IIcons = {
  ban: <FiSlash size={15} />,
  question: <FiHelpCircle size={15} />,
  check: <FiCheckCircle size={15} />,
  money: <FiDollarSign size={15} />,
  clock: <FiClock size={15} />,
  thumbs: <FiThumbsUp size={15} />,
  calendar: <FiCalendar size={15} />,
  thumbsDown: <FiThumbsDown size={15} />,
};

const Agenda: React.FC = () => {
  const history = useHistory();
  const formRefData = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user, clinica } = useAuth();
  const { params } = useRouteMatch<IAgendaParams>();

  const { optionsClinica } = useClinica();

  const [agendandoOutraClinica, setAgendandoOutraClinica] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModalAgendas, setShowModalAgendas] = useState(false);
  const [showModalData, setShowModalData] = useState(false);
  const [showModalHorarios, setShowModalHorarios] = useState(false);

  const { id } = params;

  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const [idAgenda, setIdAgenda] = useState(() => {
    return id ? Number(id) : 50;
  });

  const [diasExibicao, setDiasExibicao] = useState(() => {
    return query.get('dias') && Number(query.get('dias')) === 7 ? 7 : 1;
  });

  const dataHoje = isSunday(new Date()) ? addDays(new Date(), 1) : new Date();

  const [diaSelecionado, setDiaSelecionado] = useState<Date>(() => {
    return query.get('data') && query.get('data')?.length === 10
      ? parseISO(`${query.get('data')} 03:00:00`)
      : dataHoje;
  });

  const [agenda, setAgenda] = useState<IAgenda>();
  const [agendamentos, setAgendamentos] = useState<IAgendamento[]>();
  // const [agendamentosHoje, setAgendamentosHoje] = useState<
  //   IAgendamentoHoje[]
  // >();
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<
    number | undefined
  >(undefined);

  const [showAgendando, setShowAgendando] = useState(false);

  const [agendamentoData, setAgendamentoData] = useState(diaSelecionado);

  // DESATIVADO, POR ENQUANTO NÃO VAI TER BUSCA ESPECIFICA POIS NAO VAI EXIBIR
  // const handleBuscaAgendamentosHoje = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const data = diasExibicao === 1 ? diaSelecionado : new Date();
  //     const response = await api.get<IAgendamentoHoje[]>(
  //       `agendamentos/dehoje?ConCodigo=${idAgenda}&Data=${format(
  //         data,
  //         'yyyy-MM-dd',
  //       )}`,
  //     );
  //     setAgendamentosHoje(
  //       response.data.map(age => {
  //         return {
  //           ...age,
  //           horaFormatada: `${format(
  //             parseISO(age.inicio.toString()),
  //             'HH:mm',
  //           )} - ${format(parseISO(age.fim.toString()), 'HH:mm')}`,
  //           statusFormatado: statusAgendamento.find(status =>
  //             age.status === STATUS_AGENDA.AGENDADO
  //               ? status.id === (age.confirmado + 1 >= 2 ? 2 : 1)
  //               : status.id === age.status,
  //           ),
  //         };
  //       }),
  //     );
  //   } catch (error) {
  //     const mensagemErro = getResponseErrors(error);
  //     addToast({
  //       title: 'Não foi possível buscar agendamento de hoje',
  //       type: 'error',
  //       description: mensagemErro,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [idAgenda, diaSelecionado, diasExibicao, addToast]);

  const handleFormataAgendamentoAdicionado = useCallback(
    async (agendamentoFormatar: IAgendamento) => {
      if (!agenda) {
        return;
      }

      // await handleBuscaAgendamentosHoje();

      const AgeInicio = parseISO(agendamentoFormatar.inicio.toString());

      const horaInicialAgenda = parseISO(
        agenda.disponibilidade[0].horarios[0].data.toString(),
      );
      const inicioAgenda = startOfDay(horaInicialAgenda);
      const fimAgenda = endOfDay(addDays(horaInicialAgenda, 5));
      const agendamentoForaDataExibicao =
        diasExibicao === 1
          ? !isSameDay(inicioAgenda, AgeInicio)
          : !isWithinInterval(AgeInicio, {
              start: inicioAgenda,
              end: fimAgenda,
            });

      if (
        agendamentoFormatar.status < STATUS_AGENDA.AGENDADO ||
        agendamentoForaDataExibicao
      ) {
        setAgendamentos(state => {
          return state?.filter(age => age.id !== agendamentoFormatar.id);
        });
        return;
      }

      const tempoAtendimento = agenda.agenda.tempoPadrao;

      const width =
        diasExibicao === 1
          ? 'calc(100% - 47px)'
          : `calc(16% - ${47 / diasExibicao}px)`;

      const diaCalculo = differenceInDays(startOfDay(AgeInicio), inicioAgenda);

      const left =
        diasExibicao === 1 ? '47px' : `calc(${diaCalculo * 16}% + 47px)`;

      const dataSelecionada = addDays(horaInicialAgenda, diaCalculo);

      const diferencaMinutos = differenceInMinutes(AgeInicio, dataSelecionada);
      const top = (diferencaMinutos / tempoAtendimento) * 50 + 28;
      const diferencaMinutosAgendamento = differenceInMinutes(
        parseISO(agendamentoFormatar.fim.toString()),
        AgeInicio,
      );

      const height =
        diferencaMinutosAgendamento === tempoAtendimento
          ? '50px'
          : `${(diferencaMinutosAgendamento / tempoAtendimento) * 50}px`;

      const agendamentoFormatado: IAgendamento = {
        ...agendamentoFormatar,
        statusFormatado: statusAgendamento.find(status =>
          agendamentoFormatar.status === STATUS_AGENDA.AGENDADO
            ? status.id === (agendamentoFormatar.confirmado + 1 >= 2 ? 2 : 1)
            : status.id === agendamentoFormatar.status,
        ),
        top,
        left,
        height,
        width,
      };

      setAgendamentos(state => {
        const findAgendamento = state?.find(
          age => age.id === agendamentoFormatar.id,
        );

        if (findAgendamento && state) {
          const agendamentosOK = state.filter(
            age => age.id !== agendamentoFormatar.id,
          );
          const agendamentosCertos = agendamentosOK.concat([
            agendamentoFormatado,
          ]);
          return agendamentosCertos;
        }

        return state?.concat([agendamentoFormatado]);
      });
    },
    [diasExibicao, agenda],
  );

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        setAgendamentos(undefined);
        const response = await api.get<IAgenda>(
          `agendas/disponibilidade?id=${idAgenda}&data=${format(
            diaSelecionado,
            'yyyy-MM-dd',
          )}&dias=${diasExibicao}`,
        );

        setAgenda(response.data);
        if (user.clinica_id !== response.data.agenda.clinica_id)
          setAgendandoOutraClinica(
            `AGENDANDO NA ${
              optionsClinica.find(
                cli => cli.value === response.data.agenda.clinica_id.toString(),
              )?.label || 'VAZIO'
            }`,
          );

        const responseAgendamentos = await api.get<IAgendamento[]>(
          `agendamentos/agenda?agenda_id=${idAgenda}&data=${format(
            diaSelecionado,
            'yyyy-MM-dd',
          )}&dias=${diasExibicao}`,
        );
        // handleFormataAgendamentos(response.data);
        if (!response.data) {
          return;
        }
        const horaInicialAgenda = parseISO(
          response.data.disponibilidade[0].horarios[0].data.toString(),
        );
        const tempoAtendimento = response.data.agenda.tempoPadrao;

        let agendamentosFormatados: IAgendamento[] | undefined;
        const dias = diasExibicao === 1 ? 1 : 6;
        const width =
          diasExibicao === 1
            ? 'calc(100% - 47px)'
            : `calc(16% - ${47 / diasExibicao}px)`;

        for (let diaIndex = 0; diaIndex < dias; ) {
          const dataSelecionada = addDays(horaInicialAgenda, diaIndex);
          const inicioDia = startOfDay(dataSelecionada);
          const fimDia = endOfDay(dataSelecionada);

          const left =
            diasExibicao === 1 ? '47px' : `calc(${diaIndex * 16}% + 47px)`;
          const agendamentosSelecionadosDia = responseAgendamentos.data.filter(
            age =>
              isWithinInterval(parseISO(age.inicio.toString()), {
                start: inicioDia,
                end: fimDia,
              }) && age.status > STATUS_AGENDA.CANCELADO,
          );

          const teste = agendamentosSelecionadosDia.map(age => {
            const dataInicio = parseISO(age.inicio.toString());
            const diferencaMinutos = differenceInMinutes(
              dataInicio,
              dataSelecionada,
            );
            const incrementoTop =
              (diferencaMinutos / tempoAtendimento) * 50 + 28;
            const diferencaMinutosAgendamento = differenceInMinutes(
              parseISO(age.fim.toString()),
              dataInicio,
            );

            const height =
              diferencaMinutosAgendamento === tempoAtendimento
                ? '50px'
                : `${(diferencaMinutosAgendamento / tempoAtendimento) * 50}px`;

            return {
              ...age,
              statusFormatado: statusAgendamento.find(status =>
                age.status === STATUS_AGENDA.AGENDADO
                  ? status.id === (age.confirmado + 1 >= 2 ? 2 : 1)
                  : status.id === age.status,
              ),
              top: incrementoTop,
              left,
              height,
              width,
            };
          });
          diaIndex += 1;

          if (agendamentosFormatados) {
            agendamentosFormatados = agendamentosFormatados.concat(teste);
          } else {
            agendamentosFormatados = teste;
          }
        }
        setAgendamentos(agendamentosFormatados);

        // await handleBuscaAgendamentosHoje();
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar disponibilidade',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (idAgenda) {
      loadDados();
      history.replace(
        `/agenda/${idAgenda}?data=${format(
          diaSelecionado,
          'yyyy-MM-dd',
        )}&dias=${diasExibicao}`,
      );
    }
  }, [
    idAgenda,
    diaSelecionado,
    diasExibicao,
    optionsClinica,
    user.clinica_id,
    addToast,
    history,
  ]);

  useEffect(() => {
    document.title = agenda?.agenda.nome
      ? `Agenda ${agenda?.agenda.nome}`
      : 'Agenda';
  }, [agenda?.agenda.nome]);

  const horarioAgenda = useMemo(() => {
    if (!agenda?.agenda.horarios) {
      return [];
    }
    const diasSemana: { [key: string]: string } = {
      dia2: 'seg',
      dia3: 'ter',
      dia4: 'qua',
      dia5: 'qui',
      dia6: 'sex',
      dia7: 'sab',
    };
    const horariosFormatados = agenda?.agenda.horarios.map(horario => {
      return {
        dia: diasSemana[`dia${horario.dia}`],
        horaIni: horario.horaIni,
        horaFim: horario.horaFim,
        intervaloIni: horario.intervaloIni,
        intervaloFim: horario.intervaloFim,
      };
    });
    return horariosFormatados;
  }, [agenda?.agenda]);

  const handleSelecionaAgendamento = useCallback((AgeCodigo: number) => {
    setAgendamentoSelecionado(AgeCodigo);
    setShowAgendando(true);
  }, []);

  const handleSelecionaHorario = useCallback(
    (idAgendaSelecionado: number, data: Date) => {
      setAgendamentoData(data);
      setShowAgendando(true);
    },
    [],
  );

  const handleFecharAgendamentoAgenda = useCallback(
    (agendamentoListagem: IAgendamento | undefined) => {
      if (agendamentoListagem) {
        handleFormataAgendamentoAdicionado(agendamentoListagem);
      }
      setAgendamentoSelecionado(undefined);
      setShowAgendando(false);
    },
    [handleFormataAgendamentoAdicionado],
  );

  const dataFormatada = useMemo(() => {
    const data =
      diasExibicao === 1
        ? diaSelecionado
        : startOfWeek(diaSelecionado, { weekStartsOn: 1 });
    return diasExibicao === 1
      ? format(data, "EEE', 'dd MMM 'de' yyyy", { locale: ptBR })
      : `${format(data, 'dd/MM')} - ${format(
          addDays(data, 5),
          'dd/MM',
        )} de ${data.getFullYear()}`;
  }, [diaSelecionado, diasExibicao]);

  const handleTrocaAgenda = useCallback((idAgendaTroca: number) => {
    setShowModalAgendas(false);
    setIdAgenda(idAgendaTroca);
  }, []);

  const handleTrocaData = useCallback(formData => {
    setShowModalData(false);
    setDiaSelecionado(parseISO(formData.dataTroca));
  }, []);

  const handleNextDay = useCallback(() => {
    if (diasExibicao === 1) {
      setDiaSelecionado(state =>
        isSaturday(state) ? addDays(state, 2) : addDays(state, 1),
      );
      return;
    }
    setDiaSelecionado(state =>
      addDays(startOfWeek(state, { weekStartsOn: 1 }), 7),
    );
  }, [diasExibicao]);

  const handlePreviousDay = useCallback(() => {
    if (diasExibicao === 1) {
      setDiaSelecionado(state =>
        isMonday(state) ? addDays(state, -2) : addDays(state, -1),
      );
      return;
    }
    setDiaSelecionado(state =>
      addDays(startOfWeek(state, { weekStartsOn: 1 }), -6),
    );
  }, [diasExibicao]);

  const handleToday = useCallback(() => {
    setDiaSelecionado(new Date());
  }, []);

  const handleChangeView = useCallback((dias: number) => {
    setDiasExibicao(dias);
  }, []);

  // const totalAtendimentosHoje = useMemo(() => {
  //   let totalGeral = 0;
  //   let totalParticular = 0;
  //   let totalCartao = 0;
  //   let totalRetorno = 0;
  //   let totalConfirmado = 0;

  //   if (agendamentosHoje) {
  //     totalGeral = agendamentosHoje.filter(
  //       age => age.status > STATUS_AGENDA.CANCELADO,
  //     ).length;
  //     totalParticular = agendamentosHoje.filter(
  //       age =>
  //         age.convenio !== 'Particular' && age.status > STATUS_AGENDA.CANCELADO,
  //     ).length;
  //     totalCartao = totalGeral - totalParticular;
  //     totalRetorno = agendamentosHoje.filter(
  //       age => age.eRetorno === true && age.status > STATUS_AGENDA.CANCELADO,
  //     ).length;
  //     totalConfirmado = agendamentosHoje.filter(
  //       age =>
  //         age.confirmado > CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO &&
  //         age.status >= STATUS_AGENDA.AGENDADO,
  //     ).length;
  //   }

  //   return {
  //     totalGeral,
  //     totalParticular,
  //     totalCartao,
  //     totalRetorno,
  //     totalConfirmado,
  //   };
  // }, []);

  return (
    <Container fluid className="">
      {showModalData && (
        <Modal
          show={showModalData}
          titulo="Escolha uma data"
          handleFecharModal={() => setShowModalData(false)}
          large={false}
        >
          <Form
            ref={formRefData}
            initialData={{ dataTroca: format(diaSelecionado, 'yyyy-MM-dd') }}
            onSubmit={handleTrocaData}
            className="py-4"
          >
            <Input
              name="dataTroca"
              type="date"
              placeholder="Selecione uma data"
              containerStyle={{ maxWidth: '200px' }}
            />

            <Button type="submit" className="mt-3" size="sm">
              Selecionar
            </Button>
          </Form>
        </Modal>
      )}
      {showModalAgendas && (
        <Modal
          show={showModalAgendas}
          titulo="Selecionar outra Agenda"
          handleFecharModal={() => setShowModalAgendas(false)}
          large={false}
        >
          <div className="py-4">
            <TrocarAgenda handleSelecionaAgenda={handleTrocaAgenda} />
          </div>
        </Modal>
      )}
      {showAgendando && agenda && (
        <Modal
          show={showAgendando}
          titulo={`Agendamento #${agendamentoSelecionado}`}
          handleFecharModal={
            () => {
              setAgendamentoSelecionado(undefined);
              setShowAgendando(false);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <Agendamento
            agenda_id={idAgenda}
            especialidadesAgenda={
              agendamentoSelecionado
                ? undefined
                : agenda.agenda.especialidade_ids
            }
            data={agendamentoSelecionado ? undefined : agendamentoData}
            id={agendamentoSelecionado}
            handleFecharAgendamento={agendamentoListagem =>
              // eslint-disable-next-line prettier/prettier
              handleFecharAgendamentoAgenda(agendamentoListagem)}
          />
        </Modal>
      )}
      {showModalHorarios && (
        <Modal
          show={showModalHorarios}
          titulo="Configurações da Agenda"
          handleFecharModal={() => setShowModalHorarios(false)}
          large={false}
        >
          <table className="table table-sm table-bordered table-striped">
            <thead>
              <tr>
                <th>Dia</th>
                <th>Hora Inicial</th>
                <th>Intervalo</th>
                <th>Hora Final</th>
              </tr>
            </thead>
            <tbody>
              {horarioAgenda &&
                horarioAgenda.map(horario => (
                  <tr key={horario.dia}>
                    <td>{horario.dia}</td>
                    <td>{horario.horaIni}</td>
                    <td>{`${horario.intervaloIni} às ${horario.intervaloIni}`}</td>
                    <td>{horario.horaFim}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {agenda && agenda.agenda.obs.length > 0 && (
            <div className="border rounded p-3">
              <h6>
                <b>Observações:</b>
              </h6>
              {agenda.agenda.obs.split('\n').map(obs => (
                <p>{obs}</p>
              ))}
            </div>
          )}
        </Modal>
      )}

      {loading && <Loading backgroundBlack={false} />}

      <div className="w-100 d-flex justify-content-between pt-3 pb-1">
        <h6 className="mb-0">Calendário</h6>

        {agendandoOutraClinica.length === 0 ? (
          <h6 className="mb-0">
            {clinica.nomeAbreviado} -{' '}
            <span className="text-secondary">
              {' '}
              <b>{agenda?.agenda.nome}</b>
            </span>
          </h6>
        ) : (
          <h6 className="mb-0 text-danger">
            {agendandoOutraClinica} -{' '}
            <span className="text-primary"> {agenda?.agenda.nome}</span>
          </h6>
        )}
        <Button
          type="button"
          variant="outline-primary"
          size="sm"
          className="rounded ml-2"
          onClick={() => setShowModalAgendas(true)}
        >
          Trocar <FiChevronDown size={18} />
        </Button>
      </div>

      <Row noGutters>
        <Col md={12}>
          <div className="bg-white border rounded p-2 mt-2">
            <HeaderAgenda>
              <div>
                <ButtonGroup size="sm">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={handlePreviousDay}
                  >
                    <FiChevronLeft />
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={handleNextDay}
                  >
                    <FiChevronRight />
                  </Button>
                </ButtonGroup>

                <Button
                  type="button"
                  variant="outline-secondary"
                  size="sm"
                  className="rounded ml-2"
                  onClick={handleToday}
                >
                  Hoje
                </Button>

                <Button
                  type="button"
                  variant="outline-secondary"
                  size="sm"
                  className="rounded ml-2"
                  title="Escolher uma data"
                  onClick={() => setShowModalData(true)}
                >
                  <FiCalendar size={16} />
                </Button>
              </div>
              <div>
                <strong>{dataFormatada}</strong>
              </div>
              <div>
                <Link
                  to="/agendaenvioconfirmacao"
                  className="btn btn-sm rounded btn-secondary mr-2"
                  title="Envio de confirmação"
                  target="_blank"
                >
                  <FaWhatsapp size={16} />
                </Link>

                <Link
                  to={`/agendabusca?status=${STATUS_AGENDA.FALTA}`}
                  className="btn btn-sm rounded btn-outline-secondary mr-2"
                >
                  Faltas
                </Link>

                <Button
                  type="button"
                  variant="outline-secondary"
                  size="sm"
                  className="mr-2 rounded"
                  onClick={() => setShowModalHorarios(true)}
                >
                  <FiInfo size={16} />
                </Button>

                <ButtonGroup size="sm">
                  <Button
                    type="button"
                    variant="secondary"
                    size="sm"
                    onClick={() => handleChangeView(1)}
                  >
                    Dia
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    size="sm"
                    onClick={() => handleChangeView(7)}
                  >
                    Semana
                  </Button>
                </ButtonGroup>
              </div>
            </HeaderAgenda>

            <Calendario>
              <Hora>
                <DiaCabecalho
                  temHorarioDisponivel
                  style={{ borderRightWidth: '0', borderRadius: '4px 0' }}
                />
                {agenda?.disponibilidade[0].horarios.map(linha => (
                  <div key={`${linha.hora}:${linha.minuto}`}>
                    {linha.hora}:{linha.minuto}
                  </div>
                ))}
              </Hora>
              <DiasContainer>
                {agendamentos &&
                  agendamentos.map(agendamento => (
                    <AgendamentoContainer
                      onClick={() =>
                        // eslint-disable-next-line prettier/prettier
                handleSelecionaAgendamento(agendamento.id)}
                      key={agendamento.id.toString()}
                      data-id={agendamento.id}
                      style={{
                        top: agendamento.top,
                        left: agendamento.left,
                        width: agendamento.width,
                        height: agendamento.height,
                        backgroundColor:
                          agendamento.status ===
                            STATUS_AGENDA.PAGAMENTO_ANTECIPADO &&
                          agendamento.confirmado === CONFIRMACAO_AGENDA.NAO_VAI
                            ? '#dc3545'
                            : agendamento.statusFormatado?.color,
                      }}
                      title={
                        agendamento.obs ? ` Observação: ${agendamento.obs}` : ''
                      }
                    >
                      {
                        icons[
                          agendamento.statusFormatado
                            ? agendamento.statusFormatado.icon
                            : 'question'
                        ]
                      }
                      {`#${agendamento.id} `}
                      {agendamento.status ===
                        STATUS_AGENDA.PAGAMENTO_ANTECIPADO &&
                      agendamento.confirmado < CONFIRMACAO_AGENDA.NAO_VAI
                        ? icons[
                            agendamento.confirmado ===
                            CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO
                              ? 'question'
                              : 'check'
                          ]
                        : ''}
                      {agendamento.status ===
                        STATUS_AGENDA.PAGAMENTO_ANTECIPADO &&
                      agendamento.confirmado === CONFIRMACAO_AGENDA.NAO_VAI
                        ? icons.thumbsDown
                        : ''}
                      {agendamento.cliente.nome}
                      {agendamento.obs ? ` Observação: ${agendamento.obs}` : ''}
                    </AgendamentoContainer>
                  ))}

                {agenda?.disponibilidade.map(dia => (
                  <Marcacao key={dia.dataFormatada}>
                    <DiaCabecalho
                      temHorarioDisponivel={dia.temHorarioDisponivel}
                    >
                      {dia.dataFormatada}
                    </DiaCabecalho>
                    {dia.horarios.map(linha => (
                      <CalendarioAddContainer
                        key={`${linha.hora}:${linha.minuto}`}
                      >
                        {linha.liberado ? (
                          <ButtonAdd
                            onClick={() =>
                              // eslint-disable-next-line prettier/prettier
                        handleSelecionaHorario(idAgenda, linha.data)}
                          >
                            Horário livre
                          </ButtonAdd>
                        ) : (
                          <span>{linha.obs}</span>
                        )}
                      </CalendarioAddContainer>
                    ))}
                  </Marcacao>
                ))}
              </DiasContainer>
            </Calendario>
            {agenda && agenda.agenda.obs.length > 0 && (
              <div className="border rounded p-2 mx-2 mt-2">
                <h6>
                  <b>Observações:</b>
                </h6>
                {agenda.agenda.obs.split('\n').map(obs => (
                  <p>{obs}</p>
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Agenda;
