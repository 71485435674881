/* eslint-disable react/no-danger */
import React, { useEffect, useState, useCallback } from 'react';

import {
  FaAddressCard,
  FaCommentDollar,
  FaFileWord,
  FaHSquare,
  FaMedkit,
  FaPrint,
  FaRegCalendarCheck,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button } from 'react-bootstrap';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import { Timeline, Article, Icon, TimelineLabel, TimelineEntry } from './style';
import Loading from '../Loading';
import { htmlDecode, TIPO_USUARIO } from '../../utils/funcoes';
import Toggle from '../Exame/Toggle';
import getResponseErrors from '../../utils/getResponseErrors';
import { IAgendamentoSelecionado } from '../../pages/AgendaBusca';
import Modal from '../Modal';
import Agendamento from '../Agendamento';

interface IIcons {
  [key: string]: JSX.Element;
}

const icons: IIcons = {
  faUsd: <FaCommentDollar size={23} />,
  faAddressCardO: <FaAddressCard size={23} />,
  faFileText: <FaFileWord size={23} />,
  faHSquare: <FaHSquare size={23} />,
  faMedkit: <FaMedkit size={23} />,
  faCalendar: <FaRegCalendarCheck size={23} />,
};
interface IHistorico {
  codigo: string;
  confCodigo: string;
  data: string;
  tipo: string;
  icone: string;
  cor: string;
  titulo: string;
  texto: string;
  impressao: string;
}

type Props = {
  cliente_id: number;
};

interface IRequestImpressao {
  tipo: string;
  codigo: string;
}

const PacienteHistorico: React.FC<Props> = ({ cliente_id }) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [showAgendamento, setShowAgendamento] = useState(false);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<
    IAgendamentoSelecionado | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const [podeImprimir, _] = useState(
    user.tipo === TIPO_USUARIO.ADMINISTRADOR ||
      user.tipo === TIPO_USUARIO.MEDICO,
  );
  const [historico, setHistorico] = useState<IHistorico[]>([]);
  const [historicoFiltrado, setHistoricoFiltrado] = useState<IHistorico[]>([]);

  const formataLink = useCallback(
    (dados: IRequestImpressao) => {
      if (dados.tipo === 'Agenda' && podeImprimir)
        return `/impressaodocumento/${dados.codigo}/prontuario`;
      if (dados.tipo === '0' || dados.tipo === '1')
        return `/impressaodocumento/${dados.codigo}/documento`;
      return '';
    },
    [podeImprimir],
  );

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        setLoading(true);
        const retornoHistorico = await api.get<IHistorico[]>(
          `/atendimentos/historico?cliente_id=${cliente_id}&buscaConsulta=1`,
        );

        const historicoFormatado = retornoHistorico.data.map(his => {
          return {
            ...his,
            impressao: formataLink({ tipo: his.tipo, codigo: his.codigo }),
          };
        });

        setHistorico(historicoFormatado);
        setHistoricoFiltrado(historicoFormatado);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Erro ao buscar histórico do paciente',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    // if (id && Number(id) !== 0) loadDados();
    loadDados();
  }, [addToast, cliente_id, formataLink]);

  const handleFiltrar = useCallback(
    dados => {
      let dadosFiltrados = historico;
      if (dados.consultas !== 'true')
        dadosFiltrados = dadosFiltrados.filter(
          hist => hist && hist.tipo !== 'Agenda',
        );
      if (dados.atestados !== 'true')
        dadosFiltrados = dadosFiltrados.filter(
          hist => hist && hist.tipo !== '0',
        );
      if (dados.recibos !== 'true')
        dadosFiltrados = dadosFiltrados.filter(
          hist => hist && hist.tipo !== 'Recibo',
        );
      setHistoricoFiltrado(dadosFiltrados);
    },
    [historico],
  );
  return (
    <>
      {loading && <Loading backgroundBlack={false} />}
      {showAgendamento && agendamentoSelecionado && (
        <Modal
          show={showAgendamento}
          titulo="Agendamento"
          handleFecharModal={
            () => {
              setAgendamentoSelecionado(undefined);
              setShowAgendamento(false);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <Agendamento
            agenda_id={agendamentoSelecionado.agenda_id}
            especialidadesAgenda={undefined}
            data={undefined}
            id={agendamentoSelecionado.id}
            handleFecharAgendamento={agenda => {
              setShowAgendamento(false);
            }}
          />
        </Modal>
      )}
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-12">
            <div className="bg-white  rounded">
              <div className="border ">
                <div id="dados">
                  <div className="p-2">
                    <div className="p-2">
                      <Form
                        onSubmit={handleFiltrar}
                        initialData={{
                          consultas: 'true',
                          atestados: 'true',
                          recibos: 'true',
                        }}
                      >
                        <div className="d-flex flex-row justify-content-center">
                          <div
                            className="d-flex flex-reverse"
                            style={{ paddingTop: '7px' }}
                          >
                            <Toggle
                              nome="consultas"
                              label="Consultas"
                              isDisabled={false}
                              classSpam={{
                                paddingRight: '3px',
                                paddingLeft: '10px',
                                paddingTop: '2px',
                                fontWeight: 'bold',
                              }}
                            />
                          </div>
                          <div
                            className="d-flex flex-reverse"
                            style={{ paddingTop: '7px' }}
                          >
                            <Toggle
                              nome="atestados"
                              label="Documentos"
                              isDisabled={false}
                              classSpam={{
                                paddingRight: '3px',
                                paddingLeft: '10px',
                                paddingTop: '2px',
                                fontWeight: 'bold',
                              }}
                            />
                          </div>
                          <div
                            className="d-flex flex-reverse"
                            style={{ paddingTop: '7px' }}
                          >
                            <Toggle
                              nome="recibos"
                              label="Recibos"
                              isDisabled={false}
                              classSpam={{
                                paddingRight: '3px',
                                paddingLeft: '10px',
                                paddingTop: '2px',
                                fontWeight: 'bold',
                              }}
                            />
                          </div>
                          <div className="d-flex flex-row">
                            <Button
                              className="btn btn-sm btn-primary ml-3"
                              type="submit"
                            >
                              Filtrar
                            </Button>
                          </div>
                        </div>
                      </Form>
                      <Timeline style={{ width: '100%' }}>
                        {historicoFiltrado &&
                          historicoFiltrado.map(hist => {
                            if (hist !== null)
                              return (
                                <Article
                                  key={`hist-${hist.codigo}${hist.tipo}`}
                                >
                                  <TimelineEntry className="timeline-entry-inner">
                                    <Icon className={hist.cor}>
                                      {icons[hist.icone]}
                                    </Icon>
                                    <TimelineLabel className="timeline-label">
                                      <h2>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: hist.titulo,
                                          }}
                                        />
                                      </h2>

                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: htmlDecode(hist.texto),
                                        }}
                                      />
                                    </TimelineLabel>
                                  </TimelineEntry>
                                  {hist.impressao !== '' ? (
                                    <Link
                                      className="float-right btn btn-sm btn-outline-secondary mt-1"
                                      style={{ margin: '0 5rem 0 2px' }}
                                      to={hist.impressao}
                                      target="_blank"
                                    >
                                      <FaPrint size="15" />
                                    </Link>
                                  ) : (
                                    ''
                                  )}
                                  {hist.impressao !== '' ? (
                                    <>
                                      <div
                                        className={`${
                                          hist.tipo === 'Agenda' &&
                                          user.tipo !== TIPO_USUARIO.MEDICO
                                            ? ''
                                            : 'd-none'
                                        }`}
                                        style={{ textAlign: 'right' }}
                                      >
                                        <Button
                                          type="button"
                                          variant="outline-secondary"
                                          size="sm"
                                          data-id={hist.codigo}
                                          className={`btn btn-sm ml-3 mt-1${
                                            hist.tipo === 'Agenda'
                                              ? ''
                                              : 'd-none'
                                          }`}
                                          onClick={() => {
                                            setAgendamentoSelecionado({
                                              id: Number(hist.codigo),
                                              agenda_id: Number(
                                                hist.confCodigo,
                                              ),
                                            });
                                            setShowAgendamento(true);
                                          }}
                                        >
                                          Ver Agendamento
                                        </Button>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </Article>
                              );
                            return <></>;
                          })}
                      </Timeline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PacienteHistorico;
