import styled from 'styled-components';

export const ContainerDados = styled.div`
  background-color: #fff;
  padding: 6px;
  border: 1px solid #d7d7d7;
`;

export const RelatorioHeader = styled.div`
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-bottom-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.75rem 1.25rem;
  font-weight: bold;
  text-align: center;
`;

export const CardHeader = styled.div`
  background-color: #fff;
  text-align: right;
  border-bottom-width: 0;
  min-width: 168px;
  padding: 0.75rem 1rem;

  p {
    color: #6c757d;
    margin-bottom: 0.25rem;
  }

  h3 {
    font-size: 1.5rem;
  }
`;

export const CardIconContainer = styled.div`
  padding: 0.5rem 0.8rem;
  margin-top: -25px;
  margin-left: -10px;
  float: left;
  color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
`;
