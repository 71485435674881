import React from 'react';

import { Skelet } from './styles';

type ComponentProps = {
  width?: string;
  height?: string;
  radius?: string;
};

const Skeleton: React.FC<ComponentProps> = ({
  width = '100%',
  height = '18px',
  radius = '0.25rem',
}) => <Skelet style={{ width, height, borderRadius: radius }} />;

export default Skeleton;
