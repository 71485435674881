import axios, { AxiosInstance } from 'axios';

// const apiUrl = 'http://localhost:3000';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.response.use(
  response => {
    //  Do something with response data
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      const requestConfig = error.config;
      window.location.replace('/sair');
      return axios(requestConfig);
    }
    return Promise.reject(error);
  },
);

function apiGenerica(url: string): AxiosInstance {
  return axios.create({
    // baseURL: 'http://localhost:3000',
    baseURL: url,
  });
}

export { api as default, apiGenerica };
