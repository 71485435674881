import React, { useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

import Input from '../../../../components/Input';
import getResponseErrors from '../../../../utils/getResponseErrors';

type ConferidoProps = {
  id: number;
  conferido: boolean;
  handleConferir(cprCodigo: number, conferido: boolean): void;
};

const Conferido: React.FC<ConferidoProps> = ({
  id,
  conferido,
  handleConferir,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const name = `name-${id}`;

  const [loading, setLoading] = useState(false);
  const [conferidoLocal, setConferidoLocal] = useState(conferido);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    try {
      await api.patch<ConferidoProps>(`pagamento/confere/${id}`, {
        conferido: !conferidoLocal,
      });

      setConferidoLocal(!conferidoLocal);
      handleConferir(id, !conferidoLocal);
    } catch (error) {
      const mensagem = getResponseErrors(error);
      addToast({
        type: 'error',
        title: 'Não foi possível atualizar informação',
        description: mensagem,
      });
    } finally {
      setLoading(false);
    }
  }, [id, conferidoLocal, addToast, handleConferir]);

  const handleSubmit = useCallback(() => {
    console.log('clicou');
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input
        label=""
        disabled={loading}
        name={name}
        onClick={handleOnClick}
        type="checkbox"
        onChange={handleSubmit}
        checked={conferidoLocal}
        containerStyle={{ borderWidth: 0 }}
      />
    </Form>
  );
};

export default Conferido;
