import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

import { IOption } from '../components/SelectSync';
import { useToast } from './toast';
import getResponseErrors from '../utils/getResponseErrors';

interface IImagemClinica {
  id: number;
  nome: string;
  arquivo_url: string;
}

interface IConvenio {
  data: { id: number; nome: string }[];
  count: number;
}

interface ClinicaContextData {
  loading: boolean;
  optionsClinica: IOption[];
  optionsConvenio: IOption[];
  imagensClinica: IImagemClinica[];
  dataHoraServidor: Date | undefined;
  buscaClinicas(): Promise<void>;
  buscaConvenios(): Promise<void>;
  limparClinica(): void;
}

const ClinicaContext = createContext<ClinicaContextData>(
  {} as ClinicaContextData,
);

const ClinicaProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const [optionsClinica, setOptionsClinica] = useState<IOption[]>([]);
  const [optionsConvenio, setOptionsConvenio] = useState<IOption[]>([]);
  const [imagensClinica, setImagensClinica] = useState<IImagemClinica[]>([]);
  const [dataHoraServidor, setDataHoraServidor] = useState<Date | undefined>(
    undefined,
  );

  const buscaClinicas = useCallback(async () => {
    setLoading(true);
    try {
      const optionClinicas = localStorage.getItem(
        '@Laboratorio:optionClinicas',
      );

      if (optionClinicas) {
        setOptionsClinica(JSON.parse(optionClinicas));
      } else {
        const response = await api.get<IOption[]>('clinicas/combo');

        localStorage.setItem(
          '@Laboratorio:optionClinicas',
          JSON.stringify(response.data),
        );
        setOptionsClinica(response.data);
      }

      const imagens = localStorage.getItem('@Laboratorio:imagensClinica');
      if (imagens) {
        setImagensClinica(JSON.parse(imagens));
      } else {
        const response = await api.get<IImagemClinica[]>('clinicas/imagens');

        localStorage.setItem(
          '@Laboratorio:imagensClinica',
          JSON.stringify(response.data),
        );
        setImagensClinica(response.data);
      }

      const response = await api.get('clinicas/datahora');

      setDataHoraServidor(response.data.dataHora);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar dados da clinica',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    buscaClinicas();
  }, [buscaClinicas]);

  const buscaConvenios = useCallback(async () => {
    setLoading(true);
    try {
      const optionConvenios = localStorage.getItem(
        '@Laboratorio:optionConvenios',
      );

      if (optionConvenios) {
        setOptionsConvenio(JSON.parse(optionConvenios));
      } else {
        const response = await api.get<IConvenio>('convenios?ativo=true');
        const optionsFormatado = response.data.data.map(con => {
          return {
            label: con.nome,
            value: con.id.toString(),
          };
        });
        localStorage.setItem(
          '@Laboratorio:optionConvenios',
          JSON.stringify(optionsFormatado),
        );
        setOptionsConvenio(optionsFormatado);
      }
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar dados dos convenios',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  const limparClinica = useCallback(() => {
    localStorage.removeItem('@Laboratorio:optionClinicas');
    localStorage.removeItem('@Laboratorio:imagensClinica');
    localStorage.removeItem('@Laboratorio:dataHora');
    localStorage.removeItem('@Laboratorio:optionConvenios');
    setOptionsClinica([]);
    setImagensClinica([]);
    setOptionsConvenio([]);
    setDataHoraServidor(undefined);
  }, []);

  return (
    <ClinicaContext.Provider
      value={{
        loading,
        optionsClinica,
        dataHoraServidor,
        imagensClinica,
        buscaClinicas,
        limparClinica,
        buscaConvenios,
        optionsConvenio,
      }}
    >
      {children}
    </ClinicaContext.Provider>
  );
};

function useClinica(): ClinicaContextData {
  const context = useContext(ClinicaContext);

  if (!context) {
    throw new Error('useClinica deve ser usado com o ClinicaProvider');
  }

  return context;
}

export { ClinicaProvider, useClinica };
