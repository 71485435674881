import * as Yup from 'yup';
import { NumberSchema } from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';

const ValidaAdicionais = (
  dado: string,
): {
  [key: string]:
    | RequiredStringSchema<string | undefined, Record<string, unknown>>
    | NumberSchema<number | undefined, Record<string, unknown>>;
} => {
  switch (dado) {
    case 'Volume urinário': {
      return {
        volume: Yup.string()
          .matches(/^\d/g, 'Digite o volume em ml, (ex. 100)')
          .required('Digite o volume em ml.'),
      };
    }
    case 'Volume': {
      return {
        volume: Yup.string()
          .matches(/^\d/g, 'Digite o volume em ml, (ex. 100)')
          .required('Digite o volume em ml.'),
      };
    }
    case 'Peso': {
      return {
        peso: Yup.string()
          .matches(/^\d/g, 'Digite o peso, (ex. 80.1)')
          .required('Digite o peso.'),
      };
    }
    case 'Altura': {
      return {
        altura: Yup.string()
          .matches(/^\d/g, 'Digite a altura, (ex. 175)')
          .required('Digite a altura.'),
      };
    }
    case 'Valor do Hematócrito': {
      return {
        hematocrito: Yup.number().test(
          'is-decimal',
          'numero inválido, insira um numero decimal (18.2)',
          value => ValidaNumero(value),
        ),
      };
    }
    case 'Tipo de amostra:': {
      return {
        tipoamostra: Yup.string().required('Digite o tipo da amostra'),
      };
    }
    case 'Tipo de Amostra': {
      return {
        tipoamostra: Yup.string().required('Digite o tipo da amostra'),
      };
    }
    case 'Número de leucócitos': {
      return {
        leucocitos: Yup.string().required('Digite o Número de leucócitos'),
      };
    }
    case 'NOROVÍRUS RNA': {
      return {
        norovirusrna: Yup.string().required('Digite o NOROVÍRUS RNA'),
      };
    }
    case 'Idade Materna': {
      return {
        idadematerna: Yup.string()
          .matches(/^\d/g, 'Digite a Idade da mãe, (ex. 30)')
          .required('Digite a Idade da mãe.'),
      };
    }
    case 'Data da última menstruação': {
      return {
        dum: Yup.string()
          .matches(/^\d/g, 'Digite a DUM, (ex. 31122019)')
          .min(8, 'Formato Inválido, digite a data sem barras, (ex. 31122019)')
          .required('Digite a DUM.'),
      };
    }
    case 'Idade Gestacional (semanas)': {
      return {
        idadegestacionalsemanas: Yup.string()
          .matches(/^\d/g, 'Idade Gestacional em semanas, ex. 12')
          .required('Digite a Idade Gestacional em semanas.'),
      };
    }
    case 'CPF': {
      return {
        cpf: Yup.string()
          .matches(/^\d/g, 'Digite o CPF, sem ponto ou traço.')
          .min(11, 'Digite o CPF, sem ponto ou traço. (ex. 11122233399)')
          .required('Digite o CPF.'),
      };
    }
    case 'RG': {
      return {
        rg: Yup.string()
          .matches(/^\d/g, 'Digite o RG, sem ponto ou traço.')
          .min(9, 'Digite o RG, sem ponto ou traço. (ex. 112223339)')
          .required('Digite o RG.'),
      };
    }
    case 'NÚMERO DA CADEIA DE CUSTÓDIA': {
      return {
        numerocadeiacustodia: Yup.string()
          .matches(
            /^\d/g,
            'Digite o NÚMERO DA CADEIA DE CUSTÓDIA, sem ponto ou traço.',
          )
          .min(
            9,
            'Digite o NÚMERO DA CADEIA DE CUSTÓDIA, sem ponto ou traço. (ex. 112223339)',
          )
          .required('Digite o NÚMERO DA CADEIA DE CUSTÓDIA.'),
      };
    }
    default:
      return {};
  }
};
const ValidaNumero = (valor: Number | undefined): boolean => {
  if (!valor) return false;

  const retorno = `${valor}`.match(/^\d*\.{1}\d*$/);

  return retorno !== null;
};

export default ValidaAdicionais;
