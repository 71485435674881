/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-duplicates */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO, isSameDay } from 'date-fns';
import { Button, Col, Row, Container } from 'react-bootstrap';

import { FaRedo } from 'react-icons/fa';
import {
  FiArrowUp,
  FiArrowDown,
  FiAlertTriangle,
  FiFileText,
  FiLayers,
} from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { formatPrice, TIPO_USUARIO } from '../../utils/funcoes';

import Pagamento from '../../components/Pagamento';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import InputFake from '../../components/InputFake';

import { ORIGEM_PAGAMENTO, STATUS_AGENDA } from '../../utils/funcoes';

import { HeaderCaixa } from './styles';
import IDadosAgendamento from '../../components/Pagamento/IDadosAgendamento';
import getResponseErrors from '../../utils/getResponseErrors';

import LinhaAgendamentos from './LinhaAgendamentos';
import SkeletonAgendamentos from './SkeletonAgendamentos';
import SkeletonMovimentacoes from './SkeletonMovimentacoes';
import FechamentoCaixa from '../Relatorios/FechamentoCaixa/Fechamento';

interface ISangriaTroco {
  id: number;
  descricao: string;
  origem: number;
  dataPagamento: Date;
  valor: number;
  ehSangria?: boolean;
  hora?: string;
  valorFormatado?: string;
}

const Caixa: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const formRefSenha = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const dataAtualFormatada = useMemo(() => {
    return format(new Date(), 'dd/MM/yyyy');
  }, []);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.ATENDENTE_CAIXA) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const [loading, setLoading] = useState(false);
  const [loadingAgendamentos, setLoadingAgendamentos] = useState(false);
  const [loadingMovimentacoes, setLoadingMovimentacoes] = useState(false);
  const [showModalSenha, setShowModalSenha] = useState(false);
  const [showFechamentoCaixa, setShowFechamentoCaixa] = useState(false);
  const [autenticado, setAutenticado] = useState(false);
  const [agendamentosHoje, setAgendamentosHoje] = useState<IDadosAgendamento[]>(
    [],
  );
  const [sangriaTroco, setSangriaTroco] = useState<ISangriaTroco[]>([]);
  const [despesas, setDespesas] = useState<ISangriaTroco[]>([]);
  const [agendamentosEncaminhados, setAgendamentosEncaminhados] = useState<
    IDadosAgendamento[]
  >([]);

  const [pagamentoSelecionado, setPagamentoSelecionado] = useState<
    IDadosAgendamento | undefined
  >(undefined);
  const [sangriaTrocoSelecionado, setSangriaTrocoSelecionado] = useState<
    number | undefined
  >(undefined);
  const [showModalPagamento, setShowModalPagamento] = useState(false);
  const [tituloPagamento, setTituloPagamento] = useState(
    'Pagar Consulta/Exame',
  );
  const [tipoPagamento, setTipoPagamento] = useState(ORIGEM_PAGAMENTO.DESPESA);

  const loadDadosAgendamentos = useCallback(async (): Promise<void> => {
    setLoadingAgendamentos(true);
    try {
      const response = await api.get<IDadosAgendamento[]>(
        `caixa/agendamentosEncaminhados`,
      );

      const agendas = response.data.map(agenda => {
        const inicio = parseISO(agenda.agendamento.inicio.toString());
        const horaIni = format(inicio, 'HH:mm');
        const horaFim = format(
          parseISO(agenda.agendamento.fim.toString()),
          'HH:mm',
        );

        const consultaDoDia = isSameDay(inicio, new Date());

        const pagamentoAntecipado =
          agenda.agendamento.status === STATUS_AGENDA.PAGAMENTO_ANTECIPADO &&
          !consultaDoDia;

        return {
          ...agenda,
          valorFormatado: formatPrice(agenda.valor),
          ...(agenda.pago && {
            valorPagoFormatado: formatPrice(agenda.valorPago),
          }),
          agendamento: {
            ...agenda.agendamento,
            inicioFormatado: format(inicio, 'dd/MM/yyyy'),
          },
          horaIni,
          horaFim,
          consultaDoDia,
          pagamentoAntecipado,
        };
      });

      setAgendamentosEncaminhados(agendas.filter(agenda => !agenda.pago));
      setAgendamentosHoje(agendas.filter(agenda => agenda.pago));
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar agendamentos encaminhados',
        type: 'error',
        description: mensagemErro,
      });
      setAgendamentosEncaminhados([]);
      setAgendamentosHoje([]);
    } finally {
      setLoadingAgendamentos(false);
    }
  }, [addToast]);

  const loadDadosMovimentacoes = useCallback(async (): Promise<void> => {
    setLoadingMovimentacoes(true);
    try {
      const responseSangriaTroco = await api.get<ISangriaTroco[]>(
        `pagamento/sangriatroco?clinica_id=${
          user.clinica_id
        }&data=${new Date()}`,
      );

      const sangriasTrocos = responseSangriaTroco.data.map(st => {
        const hora = format(parseISO(st.dataPagamento.toString()), 'HH:mm');
        return {
          ...st,
          hora,
          descricao: st.descricao
            .replace('SANGRIA - ', '')
            .replace('TROCO - ', ''),
          ehSangria: st.origem === ORIGEM_PAGAMENTO.SANGRIA,
          valorFormatado: formatPrice(st.valor),
        };
      });
      setSangriaTroco(
        sangriasTrocos.filter(op => op.origem !== ORIGEM_PAGAMENTO.DESPESA),
      );
      setDespesas(
        sangriasTrocos.filter(op => op.origem === ORIGEM_PAGAMENTO.DESPESA),
      );
    } catch (error) {
      console.log(error);
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar dados de sangria e troco',
        type: 'error',
        description: mensagemErro,
      });
      setSangriaTroco([]);
      setDespesas([]);
    } finally {
      setLoadingMovimentacoes(false);
    }
  }, [addToast, user.clinica_id]);

  useEffect(() => {
    setShowModalSenha(!autenticado);
    if (autenticado) {
      loadDadosAgendamentos();
      loadDadosMovimentacoes();
    }
  }, [loadDadosAgendamentos, autenticado, loadDadosMovimentacoes]);

  // const totalAgendamentos = useMemo(() => {
  //   const total = [...agendamentosHoje, ...agendamentosEncaminhados];
  //   return total.length;
  // }, [agendamentosEncaminhados, agendamentosHoje]);

  const handleAutenticar = useCallback(
    async data => {
      setLoading(true);
      try {
        const dados = {
          email: user.email,
          senha: data.password,
        };
        await api.post(`users/validacaixa`, dados);

        setShowModalSenha(false);
        setAutenticado(true);
      } catch (err) {
        addToast({ type: 'error', title: 'Usuário ou senha Inválidos' });
      } finally {
        setLoading(false);
      }
    },
    [addToast, user.email],
  );

  const handlePagamento = useCallback(
    (
      titulo: string,
      tpPagamento: number,
      pagamento: IDadosAgendamento | undefined,
    ) => {
      setTituloPagamento(titulo);
      setTipoPagamento(tpPagamento);
      if (pagamento) {
        setPagamentoSelecionado(pagamento);
      }

      setShowModalPagamento(true);
    },
    [],
  );

  const handleTrocoSangria = useCallback(
    (titulo: string, tpPagamento: number, contapr_id: number | undefined) => {
      setTituloPagamento(titulo);
      setTipoPagamento(tpPagamento);
      if (contapr_id) {
        setSangriaTrocoSelecionado(contapr_id);
      }

      setShowModalPagamento(true);
    },
    [],
  );

  const handleFecharModal = useCallback(
    async (load: boolean) => {
      setShowModalPagamento(false);
      setPagamentoSelecionado(undefined);
      setSangriaTrocoSelecionado(undefined);
      if (load) {
        if (tipoPagamento === ORIGEM_PAGAMENTO.RECEITA) {
          await loadDadosAgendamentos();
        } else {
          await loadDadosMovimentacoes();
        }
      }
    },
    [loadDadosAgendamentos, loadDadosMovimentacoes, tipoPagamento],
  );

  return (
    <Container fluid className="pt-2">
      {showModalSenha && (
        <Modal
          show={showModalSenha}
          titulo="Digite a senha novamente"
          handleFecharModal={
            () =>
              addToast({
                type: 'info',
                title: 'não é possivel fechar esta janela sem autenticar',
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
          large={false}
        >
          <Form ref={formRefSenha} onSubmit={handleAutenticar}>
            <InputFake value={user.email} label="E-mail" />
            <Input
              label="Senha"
              name="password"
              type="password"
              containerStyle={{ maxWidth: '200px' }}
            />
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                className="mt-2"
                size="sm"
                disabled={loading}
              >
                {loading ? 'Autenticando...' : 'Autenticar'}
              </Button>
            </div>
          </Form>
        </Modal>
      )}

      {showModalPagamento && (
        <Modal
          show={showModalPagamento}
          titulo={tituloPagamento}
          handleFecharModal={() => handleFecharModal(false)}
        >
          <Pagamento
            handleFechar={handleFecharModal}
            tipo={tipoPagamento.toString()}
            cprCodigo={pagamentoSelecionado?.id || sangriaTrocoSelecionado}
            pacCodigo={pagamentoSelecionado?.cliente.id}
          />
        </Modal>
      )}
      <Row>
        <Col md="8">
          {!showFechamentoCaixa && (
            <>
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    Aguardando Pagamento{' '}
                    <a
                      href="#"
                      className="text-primary"
                      onClick={loadDadosAgendamentos}
                    >
                      <FaRedo size="20" />
                    </a>
                  </h5>
                </div>
                <div className="card-body p-0">
                  <div className="mt-0">
                    <div
                      className="table-responsive"
                      style={{ height: '200px' }}
                    >
                      <table className="datatable table table-borderless hover-table dataTable no-footer">
                        <tbody>
                          {!loadingAgendamentos &&
                            agendamentosEncaminhados.length > 0 &&
                            agendamentosEncaminhados.map(agendamento => (
                              <LinhaAgendamentos
                                key={`encaminhado-${agendamento.agendamento.id}`}
                                agendamento={agendamento}
                                handleClick={
                                  () =>
                                    handlePagamento(
                                      `Pagar Consulta/Exame - ${agendamento.cliente.nome}`,
                                      ORIGEM_PAGAMENTO.RECEITA,
                                      agendamento,
                                    )
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                              />
                            ))}
                          {loadingAgendamentos && (
                            <>
                              <SkeletonAgendamentos />
                              <SkeletonAgendamentos />
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title">Pagamentos Recebidos</h5>
                  <a
                    href="https://e-gov.betha.com.br/e-nota"
                    className="btn btn-sm btn-outline-secondary"
                    title="Emitir NFS-e"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiLayers /> NFS-e Prefeitura
                  </a>
                  {/* <span className="text-primary small">
                <b>Total Caixa: {totalAgendamentos}</b>
              </span> */}
                </div>
                <div className="card-body p-0">
                  <div
                    className="mt-0"
                    style={{ minHeight: '200px', maxHeight: '600px' }}
                  >
                    <div className="table-responsive">
                      <table className="datatable table table-borderless hover-table dataTable no-footer">
                        <tbody>
                          {!loadingAgendamentos &&
                            agendamentosHoje.length > 0 &&
                            agendamentosHoje.map(agendamento => (
                              <LinhaAgendamentos
                                key={`pagamento-${agendamento.agendamento.id}`}
                                agendamento={agendamento}
                                handleClick={
                                  () =>
                                    handlePagamento(
                                      `Pagar Consulta/Exame - ${agendamento.cliente.nome}`,
                                      ORIGEM_PAGAMENTO.RECEITA,
                                      agendamento,
                                    )
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                              />
                            ))}
                          {loadingAgendamentos && (
                            <>
                              <SkeletonAgendamentos />
                              <SkeletonAgendamentos />
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showFechamentoCaixa && (
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Resumo Caixa</h5>
              </div>
              <div className="card-body p-0">
                <div className="mt-0">
                  <FechamentoCaixa
                    data={new Date()}
                    clinica_id={user.clinica_id}
                  />
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col md="4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Caixa {dataAtualFormatada}</h5>
            </div>
            <div className="card-body">
              <HeaderCaixa>
                <Button
                  type="button"
                  variant="outline-secondary"
                  size="sm"
                  onClick={
                    () =>
                      handlePagamento(
                        'Inserir Sangria',
                        ORIGEM_PAGAMENTO.SANGRIA,
                        undefined,
                      )
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <FiArrowUp size="15" /> Sangria
                </Button>
                <Button
                  type="button"
                  variant="outline-secondary"
                  size="sm"
                  onClick={
                    () =>
                      handlePagamento(
                        'Inserir Troco',
                        ORIGEM_PAGAMENTO.TROCO,
                        undefined,
                      )
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <FiArrowDown size="15" /> Troco
                </Button>
              </HeaderCaixa>

              <div className="w-100 pt-4" />

              <HeaderCaixa>
                <Button
                  type="button"
                  variant="outline-secondary"
                  size="sm"
                  onClick={
                    () =>
                      handlePagamento(
                        'Inserir Despesa',
                        ORIGEM_PAGAMENTO.DESPESA,
                        undefined,
                      )
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <FiAlertTriangle size="15" /> Despesa
                </Button>

                <Button
                  type="button"
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => setShowFechamentoCaixa(state => !state)}
                >
                  <FiFileText size="15" />{' '}
                  {showFechamentoCaixa ? 'Pagamentos' : 'Resumo'}
                </Button>
              </HeaderCaixa>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <FiArrowDown size={15} />
                <FiArrowUp size={15} className="mr-2" />
                Movimentações do Caixa
              </h5>
            </div>
            <div className="card-body">
              <div
                className="mt-0"
                style={{ minHeight: '100px', maxHeight: '250px' }}
              >
                <div className="table-responsive">
                  <table className="datatable table table-sm table-borderless hover-table dataTable no-footer">
                    <tbody>
                      {!loadingMovimentacoes &&
                        sangriaTroco.length > 0 &&
                        sangriaTroco.map(movimento => (
                          <tr
                            role="button"
                            key={`sangria-${movimento.id}`}
                            onClick={
                              () =>
                                handleTrocoSangria(
                                  movimento.descricao,
                                  movimento.origem,
                                  movimento.id,
                                )
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <td style={{ width: '90px' }}>
                              <div
                                title={`${movimento.id}`}
                                className={`px-3 py-1 text-white rounded text-center ${
                                  movimento.ehSangria
                                    ? 'bg-warning'
                                    : 'bg-success'
                                }`}
                              >
                                {movimento.ehSangria ? 'Sangria' : 'Troco'}
                              </div>
                            </td>
                            <td className="text-center">
                              {movimento.descricao} as {movimento.hora}
                            </td>
                            <td style={{ width: '90px' }}>
                              <b>{movimento.valorFormatado}</b>
                            </td>
                          </tr>
                        ))}
                      {!loadingMovimentacoes && sangriaTroco.length === 0 && (
                        <tr>
                          <td colSpan={3}>
                            <span className="text-center pt-3 text-muted">
                              Nenhuma movimentação de sangria ou troco
                            </span>
                          </td>
                        </tr>
                      )}
                      {loadingMovimentacoes && (
                        <>
                          <SkeletonMovimentacoes />
                          <SkeletonMovimentacoes />
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <FiAlertTriangle size={15} className="mr-2" />
                Despesas
              </h5>
            </div>
            <div className="card-body">
              <div
                className="mt-0"
                style={{ minHeight: '200px', maxHeight: '300px' }}
              >
                <div className="table-responsive">
                  <table className="datatable table table-sm table-borderless hover-table dataTable no-footer">
                    <tbody>
                      {!loadingMovimentacoes &&
                        despesas.length > 0 &&
                        despesas.map(despesa => (
                          <tr
                            role="button"
                            key={`despesa-${despesa.id}`}
                            onClick={
                              () =>
                                handleTrocoSangria(
                                  despesa.descricao,
                                  despesa.origem,
                                  despesa.id,
                                )
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <td style={{ width: '90px' }}>
                              <div
                                title={`${despesa.id}`}
                                className="px-3 py-1 text-white rounded text-center bg-success"
                              >
                                Despesa
                              </div>
                            </td>
                            <td className="text-center">{despesa.descricao}</td>
                            <td style={{ width: '90px' }}>
                              <b>{despesa.valorFormatado}</b>
                            </td>
                          </tr>
                        ))}
                      {!loadingMovimentacoes && despesas.length === 0 && (
                        <tr>
                          <td colSpan={3}>
                            <span className="text-center pt-3 text-muted">
                              Nenhuma despesa lançada
                            </span>
                          </td>
                        </tr>
                      )}
                      {loadingMovimentacoes && (
                        <>
                          <SkeletonMovimentacoes />
                          <SkeletonMovimentacoes />
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Caixa;
