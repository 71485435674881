import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { FiEdit, FiTrash } from 'react-icons/fi';
import { FaTrashAlt } from 'react-icons/fa';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Button from '../../../components/Button';

import Paginacao from '../../../components/Paginacao';
import ProcedimentoCadastroPagina from '../ProcedimentoCadastro';
import { IOption } from '../../../components/SelectSync';
import Skeleton from './Skeleton';

import Modal from '../../../components/Modal';

import { IFormBusca } from '..';
import { IEspecialidade } from '../../../hooks/especialidade';

import getResponseErrors from '../../../utils/getResponseErrors';

export interface IPaginacao {
  termoBusca: IFormBusca;
  limite: number;
  pagina: number;
}

export interface IValoresProcedimento {
  procedimento_id: number;
  convenio_id: number;
  convenio: string;
  valor: number;
  valorFormatado: string;
}

export interface IProcedimento {
  id: number;
  especialidade_id?: number;
  nome: string;
  cor: string;
  icone: string;
  duracao: number;
  ativo: boolean;
  ativoFormatado?: string;
  codigoAlvaro?: string;
  exameAlvaro?: { id: number; codigo: string; nome: string };
  especialidade?: IEspecialidade;
  valoresProcedimento: IValoresProcedimento[];
}

interface IRetornoProcedimentos {
  data: IProcedimento[];
  count: number;
}

export interface RefObject {
  addProcedimento(
    dadosProcedimento: IProcedimento,
    perf: IOption | undefined,
    id: number | undefined,
  ): Promise<boolean>;
}
type Props = {
  dados?: IFormBusca;
  ref: Ref<RefObject>;
  handleRetornaTotal(total: number): void;
};

const ListaProcedimentos: React.FC<Props> = forwardRef(
  (prop: Props, ref: Ref<RefObject>) => {
    const [showModalCadastro, setShowModalCadastro] = useState(false);
    const [showModalExcluir, setShowModalExcluir] = useState<number>();
    const { addToast } = useToast();
    const [paginacao, setPaginacao] = useState<IPaginacao | undefined>(
      undefined,
    );
    const [loading, setLoading] = useState(false);
    const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
      undefined,
    );
    const [procedimentos, setProcedimentos] = useState<IProcedimento[]>([]);

    const [procedimentoSelecionado, setProcedimentoSelecionado] = useState<
      IProcedimento | undefined
    >(undefined);

    useEffect(() => {
      setPaginacao({
        termoBusca: prop.dados || {
          nome: '',
          especialidade_id: '',
          situacao: '',
          codigoAlvaro: '',
        },
        pagina: 1,
        limite: 20,
      });
    }, [prop.dados]);

    useEffect(() => {
      async function buscaProcedimentos(): Promise<void> {
        if (paginacao) {
          try {
            setLoading(true);
            const {
              nome,
              especialidade_id,
              situacao,
              codigoAlvaro,
            } = paginacao.termoBusca;
            let query = '';
            if (nome !== '') query = `&nome=${nome}`;
            if (codigoAlvaro !== '') query = `&codigoAlvaro=${codigoAlvaro}`;
            if (especialidade_id !== '')
              query = `${query}&especialidade_id=${especialidade_id}`;
            if (situacao !== '') query = `${query}&ativo=${situacao}`;

            const response = await api.get<IRetornoProcedimentos>(
              `/procedimentos?pagina=${paginacao.pagina}&limite=${paginacao.limite}${query}`,
            );

            const { data, count } = response.data;

            const prodedimentosFormatado = data.map(proc => {
              return {
                ...proc,
                ativoFormatado: proc.ativo ? 'Sim' : 'Não',
              };
            });

            setTotalRegistros(count);
            setProcedimentos(prodedimentosFormatado);
            prop.handleRetornaTotal(count);
          } catch (error) {
            const mensagemErro = getResponseErrors(error);
            addToast({
              title: 'Não foi possível buscar procedimentos',
              type: 'error',
              description: mensagemErro,
            });
          } finally {
            setLoading(false);
          }
        }
      }
      if (paginacao) buscaProcedimentos();
    }, [paginacao, addToast, prop]);

    const deleteProcedimento = useCallback(
      async (codigo: number) => {
        setLoading(true);
        try {
          await api.delete(`/procedimentos/${codigo}`);
          setProcedimentos(procedimentosState =>
            procedimentosState?.filter(pro => pro.id !== codigo),
          );
        } catch (error) {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Não foi deletar registro',
            type: 'error',
            description: mensagemErro,
          });
        } finally {
          setLoading(false);
        }
      },
      [addToast],
    );

    const addProcedimento = useCallback(
      async (
        dadosProcedimento: IProcedimento,
        espec: IOption | undefined,
        proCodigo: number | undefined,
      ): Promise<boolean> => {
        let response;
        setLoading(true);
        let retorno = false;
        try {
          if (proCodigo) {
            response = await api.put<IProcedimento>(
              `/procedimentos/${proCodigo}`,
              dadosProcedimento,
            );
          } else {
            response = await api.post<IProcedimento>(
              '/procedimentos',
              dadosProcedimento,
            );
          }

          const retornoProcedimento: IProcedimento = {
            ...response.data,
            ativoFormatado: dadosProcedimento.ativo ? 'Sim' : 'Não',
            especialidade_id: Number(espec?.value),
            especialidade: {
              id: Number(espec?.value),
              nome: espec?.label || '',
              integracaoAlvaro: false, // VERIFICAR
              ativo: true,
              tipo: 1,
            },
          };

          setProcedimentos(state => {
            return state
              ? [retornoProcedimento].concat(
                  state.filter(p => p.id !== retornoProcedimento.id),
                )
              : [retornoProcedimento];
          });

          addToast({ title: 'Procedimento salvo', type: 'success' });
          retorno = true;
        } catch (error) {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Não foi possível salvar procedimento',
            type: 'error',
            description: mensagemErro,
          });
        } finally {
          setLoading(false);
        }

        setShowModalCadastro(false);
        return retorno;
      },
      [addToast],
    );

    useImperativeHandle(ref, () => ({ addProcedimento }));

    const handleProximaPagina = useCallback((page: number) => {
      setPaginacao(state => {
        return state && { ...state, pagina: page };
      });
    }, []);

    const handleDeleteProcedimento = useCallback(
      async (codigo: number) => {
        setShowModalExcluir(undefined);
        try {
          await deleteProcedimento(codigo);
          addToast({
            type: 'success',
            title: 'Procedimento excluído',
          });
        } catch (error) {
          const mensagemErro = getResponseErrors(error);

          addToast({
            type: 'error',
            title: 'Não foi possível excluir o procedimento',
            description: mensagemErro,
          });
        }
      },
      [addToast, deleteProcedimento],
    );

    const handleEditar = useCallback(proc => {
      setProcedimentoSelecionado(proc);
      setShowModalCadastro(true);
    }, []);

    return (
      <>
        {showModalCadastro && (
          <Modal
            show={showModalCadastro}
            titulo="Cadastro de Procedimento"
            handleFecharModal={() => setShowModalCadastro(false)}
            large
          >
            <ProcedimentoCadastroPagina
              id={procedimentoSelecionado?.id || 0}
              addProcedimento={addProcedimento}
            />
          </Modal>
        )}

        {showModalExcluir && (
          <Modal
            handleFecharModal={() => setShowModalExcluir(undefined)}
            show={!!showModalExcluir}
            titulo="Excluir registro"
            large={false}
          >
            <p className="my-3">
              Essa operação não pode ser desfeita, deseja realmente prosseguir?
            </p>
            <div className="d-flex justify-content-between mt-2">
              <Button
                type="submit"
                variant="secondary"
                // size="sm"
                onClick={() => setShowModalExcluir(undefined)}
              >
                Não, voltar
              </Button>

              <Button
                type="button"
                variant="danger"
                onClick={() => handleDeleteProcedimento(showModalExcluir)}
                // size="sm"
              >
                <FaTrashAlt /> Sim, excluir
              </Button>
            </div>
          </Modal>
        )}

        <div className="table-responsive">
          <table className="datatable table table-borderless hover-table dataTable no-footer">
            <thead className="thead-light">
              <tr className="bg-light" style={{ fontSize: 'Small' }}>
                <th>Nome</th>
                <th style={{ width: '98px' }}>Cod. Álvaro</th>
                <th>Especialidade</th>

                <th style={{ width: '80px' }}>Duracao</th>
                <th style={{ width: '60px' }}>Ativo</th>
                <th style={{ width: '55px' }}>&nbsp;</th>
                <th style={{ width: '55px' }}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {procedimentos &&
                !loading &&
                procedimentos.map((proc: IProcedimento) => (
                  <tr
                    key={proc.id}
                    className={`${!proc.ativo ? 'text-muted' : ''}`}
                    data-id={proc.id}
                  >
                    <td>{proc.nome}</td>
                    <td>{proc.codigoAlvaro}</td>
                    <td>{proc.especialidade?.nome}</td>
                    <td>{proc.duracao}</td>
                    <td>{proc.ativoFormatado}</td>
                    <td>
                      <Button
                        type="button"
                        variant="primary-outline"
                        className="py-0"
                        title={proc.id.toString() || ''}
                        onClick={() => handleEditar(proc)}
                      >
                        <FiEdit />
                      </Button>
                    </td>

                    <td>
                      <Button
                        type="button"
                        variant="secondary-outline"
                        className="py-0"
                        title={proc.id.toString() || ''}
                        onClick={() => {
                          setShowModalExcluir(proc.id);
                        }}
                      >
                        <FiTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              {loading && (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              )}
              <tr>
                <td colSpan={4} />
              </tr>
            </tbody>
          </table>
        </div>
        <Paginacao
          limitePagina={paginacao?.limite || 15}
          paginasVizinhas={2}
          totalRegistros={totalRegistros}
          paginaInicio={paginacao?.pagina}
          handleProximaPagina={handleProximaPagina}
        />
      </>

      // <h5>Pesquise procedimentos pelo Nome, Perfil e/ou Situação</h5>
    );
  },
);

export default ListaProcedimentos;
