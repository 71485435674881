import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import api from '../../services/api';

import Loading from '../../components/Loading';
import { TIPO_USUARIO } from '../../utils/funcoes';

import { useAuth } from '../../hooks/auth';

const Configuracao: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('sessions/logado');
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    loadDados();
  }, []);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading backgroundBlack={false} />}
      <div className="bg-white border p-2 mb-2" style={{ minHeight: '400px' }}>
        <h2>Configurações do Sistema</h2>
        <p className="my-4">
          Gerencie usuários, permissões, imprima relatórios e outros através do
          menu administrativo
        </p>
      </div>
    </Container>
  );
};

export default Configuracao;
