import React, { useCallback, useState } from 'react';

import { FaPlusCircle, FaTrash } from 'react-icons/fa';
import SelectAsync from '../SelectAsync';
import Modal from '../Modal';
import { ButtonAdd } from './styles';

interface ValorSelecionado {
  id: number;
  nome: string;
}
interface SelectProps {
  name: string;
  isDisabled: boolean;
  urlApi: string;
  nameclass?: string;
  valorSelecionado: ValorSelecionado;
  labelSelect: string;
  showModal: boolean;
  limparValor(): void;
  setShowModal(values: boolean): void;
}

const SelectAsyncCadastro: React.FC<SelectProps> = ({
  name,
  urlApi,
  valorSelecionado,
  children,
  labelSelect,
  showModal,
  limparValor,
  setShowModal,
}) => {
  const [selecionado, setSelecionado] = useState(valorSelecionado);

  const handleSelectChange = useCallback(({ value, label }) => {
    if (value && value !== '') {
      setSelecionado({ id: Number(value), nome: label });
    }
  }, []);

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          titulo={`Cadastro de ${labelSelect}`}
          handleFecharModal={() => setShowModal(false)}
        >
          {children}
        </Modal>
      )}

      <div>
        {selecionado && selecionado.nome !== '' && (
          <>
            <b style={{ marginBottom: '4px', display: 'block' }}>
              {labelSelect}{' '}
            </b>
            <div className="d-flex">
              <span
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '5px',
                  padding: '7px',
                  backgroundColor: '#e9ecef',
                }}
              >
                {selecionado.nome}
              </span>

              <div className="w-10 pl-2">
                <ButtonAdd
                  type="button"
                  title="Excluir"
                  onClick={() => {
                    setSelecionado({ id: 0, nome: '' });
                    limparValor();
                  }}
                >
                  <FaTrash size={14} />
                </ButtonAdd>
              </div>
            </div>
          </>
        )}

        <div
          className={`${
            !selecionado || selecionado.nome !== '' ? 'd-none' : 'd-flex'
          }`}
        >
          <div style={{ flex: 'auto' }}>
            <SelectAsync
              name={name}
              label={labelSelect}
              urlApi={urlApi}
              placeholder="Digite para buscar"
              handleSelectChange={handleSelectChange}
              isClearable
            />
          </div>
          <div className="w-10 pl-2" style={{ paddingTop: '1.2rem' }}>
            <ButtonAdd
              type="button"
              title="Adicionar"
              onClick={() => setShowModal(true)}
            >
              <FaPlusCircle size={14} />
            </ButtonAdd>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectAsyncCadastro;
