import styled from 'styled-components';

export const ItemLinha = styled.div`
  margin-right: -3px;
  margin-left: -3px;
  padding: 6px 0;
  font-size: 0.7rem;

  & + div {
    border-top: 1px solid #cfcfcf;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ItemLista = styled.div`
  display: flex;
  align-items: center;

  > img {
    border-radius: 4px;
    margin-right: 8px;
    width: 40px;
  }

  button {
    font-size: 0.7rem;
  }

  span {
    color: #6c757d;
  }
`;
