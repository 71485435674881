import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO, addDays } from 'date-fns';

import { FiArrowUpCircle } from 'react-icons/fi';
import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';
import SelectSync from '../../components/SelectSync';
import Paginacao from '../../components/Paginacao';
import SkeletonBusca from './Skeleton';
import Linha from './Linha';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAgenda } from '../../hooks/agenda';

import getResponseErrors from '../../utils/getResponseErrors';

import {
  optionsStatusAgendamento,
  formataTelefone,
  STATUS_AGENDA,
} from '../../utils/funcoes';

export interface IAgendamentoBusca {
  id: number;
  agenda_id: number;
  agenda_nome: string;
  inicio: Date;
  fim: Date;
  dataFormatada: string;
  dataFormatadaApi: string;
  horaFormatada: string;
  status: number;
  guia: string;
  confirmado: number;
  statusFormatado: string;
  cliente: {
    nome: string;
    telefone: string;
    celular: string;
    telefonesFormatado: string;
  };
}

interface IAgendamentoReturnApi {
  data: IAgendamentoBusca[];
  count: number;
}

export interface IAgendamentoSelecionado {
  id: number;
  agenda_id: number;
}

interface IFormBusca {
  nome_cliente: string;
  agenda_id: number;
  status: number;
  inicio: Date;
  fim: Date;
  guia: string;
}

interface IPaginacao {
  nome_cliente?: string;
  agenda_id?: number;
  status?: number;
  guia?: string;
  inicio: Date;
  fim: Date;
  dataFake: Date;
  limite: number;
  pagina: number;
}

export interface IOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

const AgendaBusca: React.FC = () => {
  const { addToast } = useToast();
  const formRefBusca = useRef<FormHandles>(null);
  const [tipoFalta, setTipoFalta] = useState(false);

  const [agendamentos, setAgendamentos] = useState<
    IAgendamentoBusca[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [paginacao, setPaginacao] = useState<IPaginacao>();
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );
  const { retornaDadosAgenda } = useAgenda();
  const [optionsAgenda, setOptionsAgenda] = useState<IOption[]>([]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      const options = await retornaDadosAgenda();
      setOptionsAgenda(options);
    }
    loadDados();
  }, [retornaDadosAgenda]);

  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const initialData = useMemo(() => {
    let inicio = format(new Date(), 'yyyy-MM-dd');
    let fim = format(addDays(new Date(), 5), 'yyyy-MM-dd');
    if (query.get('data') && query.get('data')?.length === 10) {
      inicio = format(parseISO(`${query.get('data')} 03:00:00`), 'yyyy-MM-dd');
      if (query.get('datafim') && query.get('datafim')?.length === 10) {
        fim = format(
          parseISO(`${query.get('datafim')} 03:00:00`),
          'yyyy-MM-dd',
        );
      } else {
        fim = format(parseISO(`${query.get('data')} 03:00:00`), 'yyyy-MM-dd');
      }
    }

    const periodo = {
      inicio,
      fim,
    };

    const status = query.get('status') || 0;
    const agenda_id = query.get('agenda') || 0;
    const nome_cliente = query.get('paciente') || '';

    return { ...periodo, status, agenda_id, nome_cliente };
  }, [query]);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        nome_cliente: Yup.string(),
        agenda_id: Yup.number()
          .nullable()
          .transform(x => x || null),
        status: Yup.number()
          .nullable()
          .transform(x => x || null),
        inicio: Yup.date().required('Início precisa ser uma data'),
        fim: Yup.date().required('Fim precisa ser uma data'),
        guia: Yup.string(),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });

      setAgendamentos(undefined);

      const { inicio, fim, nome_cliente, agenda_id, status, guia } = dados;

      setPaginacao({
        ...(nome_cliente.toString().length > 1 ? { nome_cliente } : {}),
        ...(guia.toString().length > 1 ? { guia } : {}),
        ...(agenda_id.toString().length > 0
          ? { agenda_id: Number(agenda_id) }
          : {}),
        ...(status.toString().length > 0 && status.toString() !== '00'
          ? { status: Number(status) }
          : {}),
        inicio: parseISO(inicio.toString()),
        fim: parseISO(fim.toString()),
        dataFake: new Date(),
        pagina: 1,
        limite: 20,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  useEffect(() => {
    formRefBusca.current?.submitForm();
  }, []);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        if (!paginacao) return;
        setTipoFalta(false);

        let parametros = '';
        const {
          inicio,
          fim,
          nome_cliente,
          agenda_id,
          status,
          guia,
        } = paginacao;
        if (nome_cliente) parametros += `&nome_cliente=${nome_cliente}`;
        if (agenda_id) parametros += `&agenda_id=${agenda_id}`;
        if (status !== undefined) {
          parametros += `&status=${status}`;
          if (status === STATUS_AGENDA.FALTA) setTipoFalta(true);
        }
        if (guia) parametros += `&guia=${guia}`;

        const InicioFormatado = format(inicio, 'yyyy-MM-dd');
        const FimFormatado = format(fim, 'yyyy-MM-dd');

        const response = await api.get<IAgendamentoReturnApi>(
          `/agendamentos/busca?&pagina=${paginacao.pagina}&limite=${paginacao.limite}&inicio=${InicioFormatado}&fim=${FimFormatado}${parametros}`,
        );
        const { data, count } = response.data;

        setTotalRegistros(count);

        const agendamentosFormatados = data.map(age => {
          const inicioAge = parseISO(age.inicio.toString());

          const telefonesFormatado =
            age.cliente.telefone && age.cliente.telefone.length > 0
              ? `${formataTelefone(age.cliente.celular)} - ${formataTelefone(
                  age.cliente.telefone,
                )}`
              : formataTelefone(age.cliente.celular);

          return {
            ...age,
            dataFormatada: format(inicioAge, 'dd/MM/yyyy'),
            dataFormatadaApi: format(inicioAge, 'yyyy-MM-dd'),
            horaFormatada: `${format(inicioAge, 'HH:mm')} - ${format(
              parseISO(age.fim.toString()),
              'HH:mm',
            )}`,
            statusFormatado:
              optionsStatusAgendamento.find(sta =>
                age.status === STATUS_AGENDA.AGENDADO
                  ? sta.value === (age.confirmado + 1 >= 2 ? 2 : 1).toString()
                  : sta.value === age.status.toString(),
              )?.label || 'vazio',
            cliente: {
              ...age.cliente,
              telefonesFormatado,
            },
          };
        });

        setAgendamentos(agendamentosFormatados);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar agendamentos',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }

    if (paginacao) loadDados();
  }, [addToast, paginacao]);

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="pt-2">
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Agenda pesquisa
            </span>
          </div>
          <div className="col-auto d-flex justify-content-end">
            <div className="doc-badge me-3 small">
              Total registros{' '}
              <span className="bg-danger text-white px-2 py-1">
                {totalRegistros}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
          >
            <Row>
              <Col sm={12} md={5} lg={4} className="pr-1">
                <Input
                  name="nome_cliente"
                  label="Nome"
                  maxLength={100}
                  minLength={3}
                />
              </Col>
              <Col sm={6} md={3} lg={2} className="pr-1">
                <Input name="inicio" label="Período" type="date" />
              </Col>
              <Col sm={6} md={4} lg={2} className="pr-1">
                <Input name="fim" label="Até" type="date" />
              </Col>
              <div className="w-100 mb-2" />
              <Col sm={12} md={4} lg={4} className="pr-1">
                <SelectSync
                  name="agenda_id"
                  label="Agenda"
                  handleSelectChange={handleOnChangeVazio}
                  options={optionsAgenda}
                />
              </Col>
              <Col sm={6} md={4} lg={2} className="pr-1">
                <SelectSync
                  name="status"
                  label="Status"
                  handleSelectChange={handleOnChangeVazio}
                  options={optionsStatusAgendamento}
                />
              </Col>
              <Col sm={6} md={2} lg={2} className="pr-1">
                <Input name="guia" label="Guia" />
              </Col>
              <Col sm={12} md={2} lg={1} className="pr-1">
                <Button
                  type="submit"
                  size="sm"
                  className="mt-3"
                  variant="primary"
                >
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="datatable table table-borderless hover-table dataTable no-footer">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: '108px' }}>Data</th>
                  <th>Paciente</th>
                  <th>Agenda</th>
                  <th>Status</th>
                  <th style={{ width: '120px' }}>Guia</th>
                  <th style={{ width: '130px' }}>-</th>
                  <th style={{ width: '92px' }}>-</th>
                </tr>
              </thead>
              <tbody>
                {agendamentos &&
                  !loading &&
                  agendamentos.map(agendamento => (
                    <Linha
                      key={`agendamento-${agendamento.id}`}
                      agendamento={agendamento}
                      tipoFalta={tipoFalta}
                    />
                  ))}
                {loading && (
                  <>
                    <SkeletonBusca />
                    <SkeletonBusca />
                    <SkeletonBusca />
                  </>
                )}
                <tr>
                  <td colSpan={6} />
                </tr>
              </tbody>
            </table>

            {(!agendamentos || agendamentos.length === 0) && !loading && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: '250px' }}
              >
                <h5>
                  Pesquise pelo nome do paciente, data do agendamento, status ou
                  agenda.
                </h5>
              </div>
            )}
          </div>

          {paginacao && (
            <Paginacao
              limitePagina={paginacao?.limite || 20}
              paginasVizinhas={2}
              totalRegistros={totalRegistros}
              paginaInicio={paginacao?.pagina}
              handleProximaPagina={handleProximaPagina}
            />
          )}

          {agendamentos && (
            <a
              href="#topoBusca"
              className="btn btn-primary"
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '35px',
                opacity: 0.8,
              }}
            >
              <FiArrowUpCircle size={20} />
            </a>
          )}
        </div>
      </div>
    </Container>
  );
};

export default AgendaBusca;
