import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import CurrencyInput from 'react-currency-input-field';

import { useField } from '@unform/core';
import { Container, Error } from '../Input/styles';
import { replaceCurrency } from '../../utils/funcoes';

interface InputPropsHtml extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: React.CSSProperties;
  icon?: React.ComponentType<IconBaseProps>;
  large?: boolean;
  label?: string;
  disabled?: boolean;
}

const InputMoney: React.FC<InputPropsHtml> = ({
  name,
  containerStyle = {},
  large = false,
  icon: Icon,
  label = '',
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [valor, setValor] = useState(defaultValue || '0');
  const [valorAuxiliar, setValorAuxiliar] = useState(defaultValue || '0');

  useEffect(() => {
    setValor(defaultValue || '0');
  }, [defaultValue]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, valord) => {
        setValorAuxiliar(valord);
        setValor(valord);
      },
    });
  }, [fieldName, registerField]);

  const handleMoney = useCallback(e => {
    setIsFilled(!!e);
    if (!e) {
      setValorAuxiliar('0');
      return setValor('0');
    }
    setValorAuxiliar(e);
    return setValor(replaceCurrency(e));
  }, []);
  return (
    <>
      {label && (
        <b style={{ marginBottom: '4px', display: 'block' }}>{label}</b>
      )}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        large={large}
        disabled={disabled}
        type={rest.type}
      >
        {Icon && <Icon size={20} />}
        <CurrencyInput
          defaultValue={valor}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          disabled={disabled}
          name={`money${fieldName}`}
          prefix="R$"
          // value={valorFormatado}
          decimalsLimit={2}
          onValueChange={e => handleMoney(e)}
          value={valorAuxiliar}
        />
        <input
          className="d-none"
          ref={inputRef}
          defaultValue={defaultValue}
          value={valor}
          disabled={disabled}
          {...rest}
          readOnly
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />{' '}
          </Error>
        )}
      </Container>
    </>
  );
};

export default InputMoney;
