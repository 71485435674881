import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

import { IOption } from '../components/SelectSync';
import { useToast } from './toast';
import getResponseErrors from '../utils/getResponseErrors';

export interface ICategoria {
  id: number;
  nome: string;
  pai: number;
  receita: boolean;
  ativo: boolean;
}

export interface ICategorias {
  despesa: ICategoria[];
  receita: ICategoria[];
}

export interface IOptionsCategorias {
  despesa: IOption[];
  receita: IOption[];
}

interface CategoriaContextData {
  loading: boolean;
  optionCategorias: IOptionsCategorias;
  categorias: ICategorias;
  buscaCategoria(): Promise<void>;
  limparCategoria(): void;
}

const CategoriaContext = createContext<CategoriaContextData>(
  {} as CategoriaContextData,
);

const CategoriaProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const [categorias, setCategorias] = useState<ICategorias>(() => {
    const categs = localStorage.getItem('@Laboratorio:categorias');
    if (categs) return JSON.parse(categs);

    return {} as ICategorias;
  });

  const [optionCategorias, setOptionCategorias] = useState<IOptionsCategorias>(
    () => {
      const optionCategs = localStorage.getItem(
        '@Laboratorio:optionCategorias',
      );
      if (optionCategs) return JSON.parse(optionCategs);

      return {} as IOptionsCategorias;
    },
  );

  const buscaCategoria = useCallback(async () => {
    setLoading(true);
    try {
      const responseReceita = await api.get<ICategoria[]>(
        `/categoria?ativo=true&receita=true`,
      );

      const responseDespesa = await api.get<ICategoria[]>(
        `/categoria?ativo=true&receita=false`,
      );

      const optsReceita: IOption[] = responseReceita.data.map(
        (op: ICategoria) => ({
          label: op.nome,
          value: op.id.toString(),
        }),
      );

      const optsDespesa: IOption[] = responseDespesa.data.map(
        (op: ICategoria) => ({
          label: op.nome,
          value: op.id.toString(),
        }),
      );

      const localCategorias = {
        receita: responseReceita.data,
        despesa: responseDespesa.data,
      };

      const localOptions = {
        receita: optsReceita,
        despesa: optsDespesa,
      };

      localStorage.setItem(
        '@Laboratorio:categorias',
        JSON.stringify(localCategorias),
      );
      localStorage.setItem(
        '@Laboratorio:optionCategorias',
        JSON.stringify(localOptions),
      );
      setCategorias(localCategorias);

      setOptionCategorias(localOptions);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar categorias',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    async function load(): Promise<void> {
      const tokenLocal = localStorage.getItem('@Laboratorio:token');
      if (tokenLocal) {
        await buscaCategoria();
      }
    }
    load();
  }, [buscaCategoria]);

  const limparCategoria = useCallback(() => {
    localStorage.removeItem('@Laboratorio:optionCategorias');
    localStorage.removeItem('@Laboratorio:categorias');
    setCategorias({} as ICategorias);
    setOptionCategorias({} as IOptionsCategorias);
  }, []);
  return (
    <CategoriaContext.Provider
      value={{
        loading,
        categorias,
        optionCategorias,
        buscaCategoria,
        limparCategoria,
      }}
    >
      {children}
    </CategoriaContext.Provider>
  );
};

function useCategoria(): CategoriaContextData {
  const context = useContext(CategoriaContext);

  if (!context) {
    throw new Error('useCategoria deve ser usado com o CategoriaProvider');
  }

  return context;
}

export { CategoriaProvider, useCategoria };
