import styled from 'styled-components';

export const ContentTitle = styled.div`
  h2 {
    font-size: 16px;
    color: #303030;
    font-weight: 400;
    margin: 14px 0 8px;
    border-bottom: 1px solid #303030;
  }
`;

export const ContentPrincipal = styled.div`
  flex-wrap: wrap;
  column-count: 8;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 10px 6px;
`;

export const Container = styled.div`
  /*width: 8%;
   padding-bottom: 8px;
   display: flex;
   align-items: center;*/

  label {
    font-size: 13px;
    text-align: left;
    padding-left: 15px;

    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: -5px;
    margin-bottom: 0;
    /* cursor: pointer; */
    color: #858585;
  }
`;
