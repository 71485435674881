import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  FiCheck,
  FiCheckCircle,
  FiCalendar,
  FiBarChart,
  FiCheckSquare,
  FiClock,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Chart } from 'react-google-charts';

import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import Input from '../../components/Input';
import Loading from '../../components/Loading';
import { useToast } from '../../hooks/toast';

import { CONFIRMACAO_AGENDA, STATUS_AGENDA } from '../../utils/funcoes';
import { Colors } from '../../styles/constants';

import api from '../../services/api';

import {
  Title,
  CardIconContainer,
  CardHeader,
  SpaceBetween,
  CardTextContainer,
} from './styles';
import getResponseErrors from '../../utils/getResponseErrors';

interface ITotalPorStatus {
  total: number;
  status: number;
}

interface IReturnAgendasDoDiaDTO {
  id: number;
  nome: string;
  horaIni: string;
  horaFim: string;
  agendamentos: number;
  atendidos: number;
  disponivel: boolean;
}

export interface IAgendamentosPorDia {
  dia: string;
  qtd: number;
}

interface IRetornoAgendamentosDashboardDTO {
  totais: ITotalPorStatus[];
  agendas: IReturnAgendasDoDiaDTO[];
  agendamentosSemana: IAgendamentosPorDia[];
}

interface ITotais {
  confirmados: number;
  aguardando: number;
  atendidos: number;
  cancelados: number;
  agendados: number;
  recebidos: number;
}

const fakeAgendamentos = [
  ['Dia', 'Agendamentos'],
  ['24/10', 55],
  ['25/10', 83],
  ['26/10', 156],
  ['27/10', 54],
  ['28/10', 60],
  ['29/10', 107],
];

interface IGenericType {
  [key: string]: any;
}

const Dashboard: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dataFormatada, setDataFormatada] = useState(
    format(new Date(), 'yyyy-MM-dd'),
  );
  const [dataExibeFormatada, setDataExibeFormatada] = useState(
    format(new Date(), 'dd/MM/yyyy'),
  );
  const [totais, setTotais] = useState<ITotais>({
    confirmados: 2,
    aguardando: 1,
    atendidos: 5,
    cancelados: 55,
    agendados: 10,
    recebidos: 5,
  });
  const [agendas, setAgendas] = useState<IReturnAgendasDoDiaDTO[]>([]);
  const [agendamentosSemana, setAgendamentosSemana] = useState<IGenericType[]>(
    fakeAgendamentos,
  );

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IRetornoAgendamentosDashboardDTO>(
          `agendamentos/dashboard?data=${dataFormatada}`,
        );
        const confirmados =
          response.data.totais.find(
            tot => tot.status === CONFIRMACAO_AGENDA.CONFIRMADO + 1, // AgeConfirmado>0 === 2
          )?.total || 0;
        const aguardando =
          response.data.totais.find(
            tot => tot.status === CONFIRMACAO_AGENDA.AGUARDANDO_CONFIRMACAO + 1, // AgeConfirmado=0 === 1
          )?.total || 0;
        const atendidos =
          response.data.totais.find(
            tot => tot.status === STATUS_AGENDA.ATENDIDO,
          )?.total || 0;
        const cancelados =
          response.data.totais.find(
            tot => tot.status === STATUS_AGENDA.CANCELADO,
          )?.total || 0;
        setTotais({
          confirmados,
          aguardando,
          atendidos,
          cancelados,
          agendados: confirmados + aguardando + atendidos,
          recebidos: aguardando + atendidos,
        });
        setAgendas(response.data.agendas);
        const dadosGrafico: IGenericType[] = response.data.agendamentosSemana.map(
          age => {
            return [age.dia, age.qtd];
          },
        );
        const graficoLegenda: IGenericType[] = [['Dia', 'Qtd']];

        setAgendamentosSemana(graficoLegenda.concat(dadosGrafico));
      } catch (err) {
        const mensagemErro = getResponseErrors(err);
        addToast({
          title: 'Erro ao buscar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    loadDados();
  }, [dataFormatada, addToast]);

  const handleSubmit = useCallback((dados: { data: Date }) => {
    const dataParsed = new Date(
      `${dados.data.toString().replace(/-/g, '/')} 03:00:00`,
    );
    setDataFormatada(format(dataParsed, 'yyyy-MM-dd'));
    setDataExibeFormatada(format(dataParsed, 'dd/MM/yyyy'));
  }, []);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading backgroundBlack={false} />}
      <SpaceBetween>
        <Title>Dashboard</Title>
        <Form
          ref={formRef}
          initialData={{ data: dataFormatada }}
          onSubmit={handleSubmit}
        >
          <div className="d-flex align-items-center">
            Data
            <div className="px-2">
              <Input type="date" name="data" required placeholder="Data" />
            </div>
            <Button type="submit" size="sm" variant="outline-primary">
              <FiCheckCircle />
            </Button>
          </div>
        </Form>
      </SpaceBetween>

      <div className="card-deck pt-2 pb-4">
        <div className="card">
          <CardHeader className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <CardIconContainer className="bg-primary">
                  <FiCheckCircle size={24} />
                </CardIconContainer>
              </div>
              <CardTextContainer>
                <p>Atendidos</p>
                <h3 className="card-title">
                  <Link to={`agendabusca?status=2&data=${dataFormatada}`}>
                    {totais?.atendidos}
                  </Link>
                </h3>
              </CardTextContainer>
            </div>
          </CardHeader>
        </div>
        <div className="card">
          <CardHeader className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <CardIconContainer className="bg-secondary">
                  <FiClock size={24} />
                  {/* <FiCheck size={24} /> */}
                </CardIconContainer>
              </div>
              <CardTextContainer>
                <p>Aguardando</p>
                <h3 className="card-title">
                  <Link to={`agendabusca?status=2&data=${dataFormatada}`}>
                    {totais?.aguardando}
                  </Link>
                </h3>
              </CardTextContainer>
            </div>
          </CardHeader>
        </div>
        <div className="card">
          <CardHeader className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <CardIconContainer className="bg-warning">
                  <FiCalendar size={24} />
                </CardIconContainer>
              </div>
              <CardTextContainer>
                <p>Agendados</p>
                <h3 className="card-title">
                  <Link to={`agendabusca?status=2&data=${dataFormatada}`}>
                    {totais?.agendados}
                  </Link>
                </h3>
              </CardTextContainer>
            </div>
          </CardHeader>
        </div>
        <div className="card">
          <CardHeader className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <CardIconContainer className="bg-danger">
                  <FiBarChart size={24} />
                </CardIconContainer>
              </div>
              <CardTextContainer>
                <p>Recebidos</p>
                <h3 className="card-title">
                  <Link to={`agendabusca?status=2&data=${dataFormatada}`}>
                    {totais?.recebidos}
                  </Link>
                </h3>
              </CardTextContainer>
            </div>
          </CardHeader>
        </div>
      </div>
      <br />
      <Row>
        <Col md={9} lg={8}>
          <div className="card">
            <div className="card-header py-2">
              <div className="row align-items-center">
                <div className="col">
                  <div className="card-title">
                    <b>Agendamentos</b>
                  </div>
                </div>
                <div className="col-auto d-flex">
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <div className="d-flex">
                      <div style={{ flex: 1 }}>
                        <Input
                          name="termoBusca"
                          placeholder="Procurar por nome, telefone ou cpf"
                          textUppercase={false}
                          maxLength={100}
                          minLength={3}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              {agendamentosSemana && agendamentosSemana.length > 1 ? (
                <Chart
                  chartType="ColumnChart"
                  data={agendamentosSemana}
                  options={{
                    title: 'Agendamentos da semana',
                    legend: 'none',
                    colors: [Colors.primary],
                  }}
                  width="100%"
                  height="300px"
                  legendToggle
                />
              ) : (
                <h6 className="p-5">Sem agendamentos na semana</h6>
              )}
            </div>
          </div>
        </Col>
        <Col md={3} lg={4}>
          <div className="card">
            <div className="card-header py-2">
              <div className="row align-items-center">
                <div className="col">
                  <div className="card-title">
                    <b>{`Agendamentos de ${dataExibeFormatada}`}</b>
                  </div>
                </div>
                <div className="col-auto d-flex">
                  <Link
                    to="agendaenvioconfirmacao"
                    className="float-right btn btn-sm btn-primary mb-1"
                  >
                    <FiCheckSquare />
                  </Link>
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              {agendas && (
                <div
                  className="table-responsive"
                  style={{ maxHeight: '400px', overflow: 'auto' }}
                >
                  <Table className="text-secondary" hover size="sm">
                    <thead>
                      <tr className="bg-light text-dark">
                        <th>Agenda</th>
                        <th>Agendamentos</th>
                        <th>Atendidos</th>
                        <th style={{ minWidth: '100px' }}>Horário</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agendas.map(age => (
                        <tr key={`con-${age.id.toString()}`}>
                          <td>
                            <Link
                              to={`agenda/${age.id}?data=${dataFormatada}`}
                              className="text-reset"
                            >
                              {age.nome}
                            </Link>
                          </td>
                          <td>{age.agendamentos}</td>
                          <td>{age.atendidos}</td>
                          <td> {`${age.horaIni} as ${age.horaFim}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
