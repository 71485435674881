import React from 'react';

import Skeleton from '../../../components/Skeleton';

interface ComponentProps {
  linhas?: number;
}

const SkeletonCliente: React.FC<ComponentProps> = ({ linhas = 1 }) => {
  return (
    <tr>
      <td width="90px">
        <Skeleton height="30px" />
      </td>
      <td>
        <Skeleton height="30px" />
      </td>

      <td>
        <Skeleton height="30px" />
      </td>
    </tr>
  );
};

export default SkeletonCliente;
