import React, { useCallback, useState } from 'react';

import { format } from 'date-fns';

import { FaWhatsapp, FaCheckCircle, FaSpinner, FaCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import Modal from '../../../components/Modal';
import Agendamento from '../../../components/Agendamento';
import { Spinner } from '../../../components/Agendamento/styles';

import getResponseErrors from '../../../utils/getResponseErrors';

import { IAgendamento } from '../index';
import { PacienteLinha, PacienteLista } from '../styles';
import { htmlDecode } from '../../../utils/funcoes';

type ITemTabela = {
  agendamento: IAgendamento;
};

const ItemTabela: React.FC<ITemTabela> = ({ agendamento }) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [dataUltimoEnvioFormatado, setDataUltimoEnvioFormatado] = useState(
    agendamento.dataUltimaNotificacaoFormatado,
  );
  const [showAgendamento, setShowAgendamento] = useState(false);

  const handleSalvaNotificacao = useCallback(async () => {
    try {
      setLoading(true);
      const dados = {
        agendamento_id: agendamento.id,
        metodo: 'Whatsapp',
      };
      await api.post(`/agendamentos/notificacao`, dados);
      setDataUltimoEnvioFormatado(format(new Date(), 'dd/MM/yyyy HH:mm'));
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível salvar a notificação',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, agendamento.id]);

  const handleEnviaConfirmacao = useCallback((agendament: IAgendamento) => {
    const textoDecode = htmlDecode(agendament.textoMensagem);
    const textoMensagem = encodeURIComponent(textoDecode);

    const linkWhatsapp = `https://api.whatsapp.com/send?phone=55${agendament.cliente.celular}&text=${textoMensagem}`;
    window.open(linkWhatsapp, '_blank');
  }, []);

  return (
    <>
      {showAgendamento && (
        <Modal
          show={showAgendamento}
          titulo="Agendamento"
          handleFecharModal={
            () => {
              setShowAgendamento(false);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <Agendamento
            agenda_id={agendamento.agenda_id}
            especialidadesAgenda={undefined}
            data={undefined}
            id={agendamento.id}
            handleFecharAgendamento={() => {
              setShowAgendamento(false);
            }}
          />
        </Modal>
      )}

      <tr>
        <td>
          <span className="user-name">{agendamento.dataFormatada}</span>
          <br />
          {agendamento.horaFormatada}
        </td>

        <td>
          <h2 className="table-avatar">
            <a href={`/pacientecadastro/${agendamento.id}`}>
              <span className="user-name">{agendamento.cliente.nome}</span>
              <span className="text-muted">
                {agendamento.cliente.telefonesFormatado}
              </span>
            </a>
          </h2>
        </td>
        <td>{agendamento.especialidade_nome}</td>
        <td>{agendamento.statusFormatado}</td>
        <td>
          <Button
            type="button"
            variant="primary"
            size="sm"
            data-id={agendamento.id}
            onClick={() => {
              setShowAgendamento(true);
            }}
          >
            Agendamento
          </Button>
        </td>
        <td>
          <Button
            type="button"
            variant="success"
            size="sm"
            className="ml-1"
            data-id={agendamento.id}
            onClick={() => handleEnviaConfirmacao(agendamento)}
          >
            <FaWhatsapp size={16} />
          </Button>

          {loading ? (
            <Spinner className="px-2 py-1 border rounded ml-1">
              <FaSpinner size={17} />
            </Spinner>
          ) : (
            <Button
              type="button"
              variant={
                dataUltimoEnvioFormatado !== ''
                  ? 'success'
                  : 'outline-secondary'
              }
              size="sm"
              className="ml-1"
              title={dataUltimoEnvioFormatado}
              data-id={agendamento.id}
              onClick={() => handleSalvaNotificacao()}
            >
              {dataUltimoEnvioFormatado !== '' ? (
                <FaCheckCircle size={16} />
              ) : (
                <FaCircle size={16} />
              )}
            </Button>
          )}
        </td>
      </tr>
    </>
  );
};

export default ItemTabela;
