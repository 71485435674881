import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface FormProps {
  hasError: boolean;
}

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;

  width: 100%;
  max-width: 600px;

  > form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      font-size: 32px;
      color: #3a3a3a;
      margin-bottom: 24px;
    }

    a {
      color: #3b3b3b;
      display: block;
      text-decoration: none;
      margin-top: 18px;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.6, '#3b3b3b')};
      }
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: #0f0f0f no-repeat;
  background-size: cover;
`;

export const Form = styled.form<FormProps>``;

export const Error = styled.span`
  display: block;
  color: #c53030;
  margin-top: 8px;
`;
