import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  FaRegQuestionCircle,
  FaRegCheckCircle,
  FaRegMoneyBillAlt,
  FaRegThumbsUp,
  FaBan,
  FaRegClock,
  FaRegCalendarCheck,
} from 'react-icons/fa';

import { Button } from 'react-bootstrap';
import Skeleton from '../ListaAtendimentoConsulta/Skeleton';
import { STATUS_AGENDA } from '../../../utils/funcoes';

import { IListaAgendamentosHojeDTO } from '../index';
// import Button from '../Button';  import { Colors } from '../../styles/constants';
import api from '../../../services/api';

interface IIcons {
  [key: string]: JSX.Element;
}

const icons: IIcons = {
  ban: <FaBan size={15} />,
  question: <FaRegQuestionCircle size={15} />,
  check: <FaRegCheckCircle size={15} />,
  money: <FaRegMoneyBillAlt size={15} />,
  clock: <FaRegClock size={15} />,
  thumbs: <FaRegThumbsUp size={15} />,
  calendar: <FaRegCalendarCheck size={15} />,
};

type listaProps = {
  loading: boolean;
  listaEspera: IListaAgendamentosHojeDTO[];
  dadosBusca: {
    dataAgenda: string;
    codigoAgenda: string | undefined;
    somenteLista: boolean;
  };
  chamar(pacNome: string): void;
  abrirHistorico(pacCodigo: number): void;
};

const ListaAtendimentoExame: React.FC<listaProps> = ({
  loading,
  listaEspera,
  dadosBusca,
  chamar,
  abrirHistorico,
}) => {
  const handleImprimir = useCallback((cods: string) => {
    api.get(`/exame/imprimiretiquetas?cods=${cods}`);
  }, []);

  return (
    <>
      <div className="table-responsive">
        <table className="datatable table table-borderless hover-table dataTable no-footer">
          <thead className="thead-light">
            <tr>
              <th style={{ width: '50px' }}>&nbsp;</th>
              <th style={{ width: '60px' }}>ID</th>
              <th style={{ width: '70px' }}>Horário</th>
              <th style={{ width: '98px' }}>Liberado às</th>
              <th>Paciente</th>
              <th style={{ width: '120px' }}>Telefone</th>
              <th>Procedimento</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              listaEspera.length > 0 &&
              listaEspera.map((lista: IListaAgendamentosHojeDTO) => (
                <tr key={`lista-${lista.id}`} className={lista.corLinha}>
                  <td
                    style={{
                      color: lista.icone?.color,
                      textAlign: 'center',
                    }}
                  >
                    {icons[lista.icone?.icon || 'question']}
                  </td>
                  <td>
                    <b>#{lista.id}</b>
                  </td>
                  <td>{lista.horario}</td>
                  <td>{lista.liberado}</td>
                  <td>
                    <h2 className="table-avatar">
                      <img
                        className="avatar avatar-img"
                        src={lista.cliente.avatar_url}
                        alt="avatar"
                      />

                      <div>
                        <span className="user-name">{lista.cliente.nome}</span>
                        <span className="text-muted" />
                        {lista.cliente.sexo}
                      </div>
                    </h2>
                  </td>
                  <td>{`${lista.cliente.celular}`}</td>
                  <td>{lista.procedimento}</td>
                  <td className="p-0" style={{ width: '330px' }}>
                    <Link
                      className={`btn btn-primary ${
                        lista.status < STATUS_AGENDA.AGUARDANDO_ATENDIMENTO &&
                        lista.status !== STATUS_AGENDA.PAGAMENTO_ANTECIPADO
                          ? 'd-none'
                          : ''
                      }`}
                      style={{
                        height: '23px',
                        lineHeight: 1,
                        padding: '4px 16px',
                        fontSize: '14px',
                      }}
                      to={`/pacienteatendimentoalvaro/${lista.id.toString()}?agenda=${
                        dadosBusca.codigoAgenda
                      }&data=${dadosBusca.dataAgenda}&somenteLista=${
                        dadosBusca.somenteLista
                      }`}
                    >
                      {lista.status === STATUS_AGENDA.ATENDIDO
                        ? 'Atendido'
                        : 'Atender'}
                    </Link>
                    {/* &nbsp;&nbsp;
                  <Button
                    type="button"
                    variant="primary-outline"
                    className={`py-0 ${
                      lista.atendido ||
                      (lista.AgeStatus < STATUS_AGENDA.AGUARDANDO_ATENDIMENTO &&
                        lista.AgeStatus !== STATUS_AGENDA.PAGAMENTO_ANTECIPADO)
                        ? 'd-none'
                        : ''
                    }`}
                    style={{ height: '23px' }}
                    onClick={() => chamar(lista.PacNome)}
                  >
                    Chamar Paciente
                  </Button> */}
                    {/* &nbsp;&nbsp;
                  <Button
                    type="button"
                    variant="primary-outline"
                    className="py-0"
                    style={{ height: '23px' }}
                    onClick={() => abrirHistorico(lista.PacCodigo)}
                    title={lista.PacCodigo.toString()}
                  >
                    Histórico
                  </Button> */}
                    &nbsp;&nbsp;
                    {lista.exame?.codItens && (
                      <>
                        <Button
                          type="button"
                          variant="secondary"
                          className={`py-0 ${
                            lista.exame.codItens && lista.exame.codItens !== ''
                              ? ''
                              : 'd-none'
                          }`}
                          title={lista.exame.codigoOS}
                          style={{ height: '23px' }}
                          onClick={
                            () => handleImprimir(lista.exame?.codItens || '')
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        >
                          Imprimir
                        </Button>
                        {lista.exame.codigoOS}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            {loading && (
              <>
                <Skeleton />
                <Skeleton />
              </>
            )}
            {!loading && listaEspera.length === 0 && (
              <tr>
                <td colSpan={7}>
                  <h5 className="text-center mt-5">
                    Nenhum paciente na lista de espera
                  </h5>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ListaAtendimentoExame;
