import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  // isErrored: boolean;
  large?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  min-height: 31px;
  border-radius: 16px;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #495057;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  padding: 6px 10px;
  ${props =>
    props.large &&
    css`
      padding: 12px 10px;
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: #e9ecef;
    `}

  div {
    flex: 1;
    border: 0;
    width: 100%;

    &::placeholder {
      color: #a8a8b3;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 12px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
