/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import { TIPO_USUARIO, formatPrice } from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';

import Select from '../../../components/Select';
import { IOption } from '../../../components/SelectSync';
import Loading from '../../../components/Loading';
import Paginacao from '../../../components/Paginacao';
import Agendamento from '../../../components/Agendamento';
import Modal from '../../../components/Modal';

import getValidationErrors from '../../../utils/getValidationErrors';

import { RelatorioHeader } from './styles';
import getResponseErrors from '../../../utils/getResponseErrors';

interface IRelatorio {
  id: number;
  agendamento_id: number;
  cliente_nome: string;
  codigoAlvaro: string;
  nomeAlvaro: string;
  procedimento_nome: string;
  valor: number;
  valorFormatado: string;
  quantidade: number;
  os: string;
  data: Date;
  dataFormatado: string;
  tipoConferencia: 0 | 1 | 2;
  tipoConferenciaFormatado: string;
}

interface IFormBusca {
  Fatura: string;
  clinica_id: string;
  tipoConferencia: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

interface IPaginacao extends IFormBusca {
  limite: number;
  pagina: number;
}

const TIPOS_CONFERENCIA = {
  0: 'NÃO ENCONTRADO',
  1: 'INTEGRAÇÃO',
  2: 'BUSCA AGENDA',
};

const optionsTipoConferencia = [
  { value: '1', label: 'CONFERIDO INTEGRAÇÃO' },
  { value: '2', label: 'CONFERIDO POR BUSCA' },
  { value: '0', label: 'NÃO CONFERIDO' },
];

const ConferenciaExamesAlvaro: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica, imagensClinica } = useClinica();

  const logo =
    imagensClinica.find(img => img.nome === 'logoRelatorio')?.arquivo_url || '';

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [optionsFaturas, setOptionsFaturas] = useState<IOption[]>([]);
  const [dadosRelatorio, setdadosRelatorio] = useState<IRelatorio[]>();

  const [paginacao, setPaginacao] = useState<IPaginacao>();
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );

  const initialData = {
    clinica_id: user.clinica_id.toString(),
  };
  const [showAgendamento, setShowAgendamento] = useState(false);
  const [agendamento_id, setAgendamentoId] = useState<number>();
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinica, setClinica] = useState('');

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('/relatorios/faturasalvaro');

        setOptionsFaturas(response.data);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível buscar dados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }

    loadDados();
  }, [addToast]);

  const handleFormataDados = useCallback((dados: IRelatorio[]) => {
    const dadosFormatados = dados.map(rel => {
      return {
        ...rel,
        dataFormatado: format(parseISO(rel.data.toString()), 'dd/MM/yyyy'),
        valorFormatado: formatPrice(
          Number(rel.valor.toString().replace(',', '.')),
        ),
        tipoConferenciaFormatado: TIPOS_CONFERENCIA[rel.tipoConferencia],
      };
    });
    return dadosFormatados;
  }, []);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        if (!paginacao) return;
        const {
          Fatura,
          tipoConferencia,
          clinica_id,
          limite,
          pagina,
        } = paginacao;

        const conferenciaBusca = tipoConferencia
          ? `&tipoConferencia=${tipoConferencia}`
          : '';

        const response = await api.get(
          `/relatorios/conferenciaalvaro?Fatura=${Fatura}&clinica_id=${clinica_id}${conferenciaBusca}&limite=${limite}&pagina=${pagina}`,
        );
        const dadosApi: IRelatorio[] = response.data[0];
        setdadosRelatorio(handleFormataDados(dadosApi));
        setTotalRegistros(
          response.data[1] === 0 ? undefined : response.data[1],
        );

        setDataExibe({
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
          dataDiaSemana: '',
        });
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível carregar dados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }

    if (paginacao) loadDados();
  }, [paginacao, addToast, handleFormataDados]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          clinica_id: Yup.string().required(),
          Fatura: Yup.string().required(),
          tipoConferencia: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinica(
          optionsClinica.find(cli => cli.value === dados.clinica_id)?.label ||
            '',
        );

        setPaginacao({
          clinica_id: dados.clinica_id,
          Fatura: dados.Fatura,
          tipoConferencia: dados.tipoConferencia,
          pagina: 1,
          limite: 50,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        }
      } finally {
        setLoading(false);
      }
    },
    [optionsClinica],
  );

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading />}
      {showAgendamento && agendamento_id && (
        <Modal
          show={showAgendamento}
          titulo="Agendamento"
          handleFecharModal={
            () => {
              setShowAgendamento(false);
              setAgendamentoId(undefined);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <Agendamento
            id={agendamento_id}
            handleFecharAgendamento={() => {
              setShowAgendamento(false);
              setAgendamentoId(undefined);
            }}
          />
        </Modal>
      )}
      <>
        <div className="d-print-none bg-white border p-2 mb-2">
          <div className="p-2 mb-2">
            <h2 id="topoBusca">Conferência Exames Cobrados Álvaro</h2>
          </div>

          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
          >
            <Row>
              <Col md={3}>
                <Select name="Fatura" label="Fatura" options={optionsFaturas} />
              </Col>
              <Col md={3}>
                <Select
                  name="tipoConferencia"
                  label="Situação"
                  options={optionsTipoConferencia}
                />
              </Col>
              <Col md={3}>
                <Select
                  name="clinica_id"
                  label="Clínica"
                  options={optionsClinica}
                  disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                />
              </Col>
              <Col md={2}>
                <br />
                <Button type="submit" size="sm" variant="outline-secondary">
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="bg-white border p-2 my-3">
          <img src={logo} alt="Agenda Facil" /> Impresso em{' '}
          {dataExibe?.dataHora}
        </div>

        <RelatorioHeader>
          CONFERÊNCIA EXAMES ÁLVARO {`- ${clinica}`}
        </RelatorioHeader>
        <div className="bg-white border p-2">
          {dadosRelatorio && (
            <>
              <b>Total de {totalRegistros} registros</b>
              <table
                className="table table-hover table-sm table-font-small table-bordered"
                style={{ borderWidth: '0px' }}
              >
                <thead>
                  <tr className="text-center">
                    <th>Paciente</th>
                    <th style={{ width: '80px' }}>Código</th>
                    <th>Nome Álvaro</th>
                    <th>Nome Sistema</th>
                    <th style={{ width: '80px' }}>Valor</th>
                    <th style={{ width: '80px' }}>QTD</th>
                    <th style={{ width: '80px' }}>OS</th>
                    <th style={{ width: '110px' }}>Data</th>
                    <th>Conferência</th>
                    <th style={{ width: '99px' }}>Agenda</th>
                  </tr>
                </thead>
                <tbody>
                  {dadosRelatorio.map(rel => (
                    <tr
                      key={`novos-${rel.id}`}
                      style={{
                        fontSize: '.7rem',
                        height: '30px',
                      }}
                    >
                      <td>{rel.cliente_nome}</td>
                      <td>{rel.codigoAlvaro}</td>
                      <td>{rel.nomeAlvaro}</td>
                      <td>{rel.procedimento_nome}</td>
                      <td>{rel.valorFormatado}</td>
                      <td>{rel.quantidade}</td>
                      <td>{rel.os}</td>
                      <td>{rel.dataFormatado}</td>
                      <td>{rel.tipoConferenciaFormatado}</td>
                      <td>
                        {rel.agendamento_id > 0 && (
                          <Button
                            type="button"
                            size="sm"
                            variant="outline-success"
                            onClick={() => {
                              setAgendamentoId(rel.agendamento_id);
                              setShowAgendamento(true);
                            }}
                          >
                            Ver agenda
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          <Paginacao
            limitePagina={paginacao?.limite || 50}
            paginasVizinhas={2}
            totalRegistros={totalRegistros}
            paginaInicio={paginacao?.pagina}
            handleProximaPagina={handleProximaPagina}
          />
        </div>
      </>
    </Container>
  );
};

export default ConferenciaExamesAlvaro;
