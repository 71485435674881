import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../../../hooks/toast';
import InputMoney from '../../../../components/InputMoney';
import PopUpConfirmacao from '../../../../components/PopUpConfirmacao';

import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';

import { IValoresProcedimento } from '../../ListaProcedimentos';
import { useClinica } from '../../../../hooks/clinica';
import SelectSync from '../../../../components/SelectSync';

interface componentProps {
  procedimento_id: number;
  handleSalvou(dados: IValoresProcedimento): void;
}
interface IFormDados {
  convenio_id: number;
  valor: number;
}

const AddValor: React.FC<componentProps> = ({
  procedimento_id,
  handleSalvou,
}) => {
  const { addToast } = useToast();
  const { buscaConvenios, optionsConvenio } = useClinica();
  const [loading, setLoading] = useState(false);

  const formRefValor = useRef<FormHandles>(null);

  useEffect(() => {
    async function Busca(): Promise<void> {
      await buscaConvenios();
    }
    if (!optionsConvenio || optionsConvenio.length === 0) Busca();
  }, [buscaConvenios, optionsConvenio]);

  const handleSubmit = useCallback(
    async (dados: IFormDados) => {
      try {
        setLoading(true);
        formRefValor.current?.setErrors({});
        const schema = Yup.object().shape({
          convenio_id: Yup.string().required(),
          valor: Yup.number().required(),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        const dadosFormatados = {
          ...dados,
          convenio_id: Number(dados.convenio_id),
          procedimento_id,
        };

        const response = await api.post<IValoresProcedimento>(
          `procedimentos/valor`,
          dadosFormatados,
        );
        handleSalvou(response.data);
      } catch (error: any) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefValor.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar valor',
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleSalvou, procedimento_id],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <PopUpConfirmacao>
      <h6>Add valor</h6>
      <Form ref={formRefValor} onSubmit={handleSubmit}>
        <SelectSync
          name="convenio_id"
          label="Convênio"
          placeholder="Selecione"
          isClearable={false}
          handleSelectChange={handleOnChangeVazio}
          options={optionsConvenio}
        />
        <div className="my-2">
          <InputMoney name="valor" label="Valor" placeholder="valor" />
        </div>
        <Button type="submit" size="sm" variant="primary" disabled={loading}>
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      </Form>
    </PopUpConfirmacao>
  );
};

export default AddValor;
