import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FiGrid,
  FiCalendar,
  FiUsers,
  FiSliders,
  FiFileText,
  FiMenu,
  FiLogOut,
  FiChevronRight,
  FiDollarSign,
  FiShoppingCart,
  FiUser,
  FiCode,
  FiClipboard,
} from 'react-icons/fi';

import { NavDropdown } from 'react-bootstrap';
import api from '../../services/api';

import { useAuth } from '../../hooks/auth';
import { useClinica } from '../../hooks/clinica';
import { useAgenda } from '../../hooks/agenda';

import Busca from './Busca';
import { TIPO_USUARIO } from '../../utils/funcoes';
import { optionsTipoUsuario } from '../../utils/options';

import { Profile } from './styles';

const MainStructure: React.FC = ({ children }) => {
  const { user } = useAuth();
  const { imagensClinica } = useClinica();
  const { retornaDadosAgenda } = useAgenda();

  const [agendaPadrao, setAgendaPadrao] = useState<string>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      const options = await retornaDadosAgenda();
      setAgendaPadrao(options[0].value || '1');
    }
    loadDados();
  }, [retornaDadosAgenda]);

  const tipoUsuario = useMemo(() => {
    return (
      optionsTipoUsuario.find(opt => opt.value === user.tipo.toString())
        ?.label || 'ND'
    );
  }, [user.tipo]);

  const Logo =
    imagensClinica.find(img => img.nome === 'logo')?.arquivo_url || '';
  const LogoIcone =
    imagensClinica.find(img => img.nome === 'logoIcone')?.arquivo_url || '';

  const handleSubMenu = useCallback((menu: string) => {
    const element = document.getElementById(menu);
    if (element) element.classList.toggle('submenu-active');
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await api.delete(`sessions/${user.id}`);
    } catch (error) {
      console.log('erro:', error);
    } finally {
      window.location.replace('/sair');
    }
  }, [user?.id]);

  return (
    <div className="body-container">
      <div id="body-pd">
        <header className="header d-print-none" id="header">
          <div className="d-flex align-items-center">
            <div className="header_toggle">
              <FiMenu id="header-toggle" />
            </div>
            <div className="pl-4">
              {user.tipo !== TIPO_USUARIO.MEDICO && <Busca />}
            </div>
          </div>
          <div>
            <Profile>
              <div>
                <NavDropdown
                  // eslint-disable-next-line prettier/prettier
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <img src={user.avatar_url} alt={user.nome} />
                    </>
                  }
                  id="dropdown-Func"
                  style={{ color: '#464844' }}
                  alignRight
                >
                  <NavDropdown.ItemText className="p-2 bg-light">
                    <div className="dropdown-item text-dark px-1">
                      <div className="d-flex justify-content-between">
                        <img
                          src={user.avatar_url}
                          alt={user.nome}
                          style={{ width: '35px', height: '35px' }}
                        />
                        <div className="pl-2">
                          <b>{user.nome}</b>
                          <span className="d-block text-muted">
                            {tipoUsuario}
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavDropdown.ItemText>

                  <NavDropdown.ItemText className="p-1">
                    <Link
                      to="/profile"
                      className="dropdown-item text-dark p-2"
                      role="button"
                    >
                      <FiUser size={18} className="mr-2" /> Editar Perfil
                    </Link>
                  </NavDropdown.ItemText>
                  <NavDropdown.ItemText className="p-1">
                    <div className="dropdown-item text-dark p-2">
                      <FiCode size={18} className="mr-2" /> Código {user.id}
                    </div>
                  </NavDropdown.ItemText>

                  <NavDropdown.ItemText className="p-1 border-top">
                    <button
                      type="button"
                      onClick={handleLogout}
                      className="dropdown-item text-dark p-2"
                    >
                      <FiLogOut size={18} className="mr-2" /> Sair
                    </button>
                  </NavDropdown.ItemText>
                </NavDropdown>
              </div>
            </Profile>
          </div>
        </header>

        <div className="l-navbar d-print-none" id="nav-bar">
          <nav className="nav">
            <div>
              <a
                href={
                  user.tipo !== TIPO_USUARIO.MEDICO
                    ? '/home'
                    : '/agendaatendimento'
                }
                className="nav_logo"
              >
                <img src={LogoIcone} alt="Logo" />
                <span className="nav_logo-name">
                  <img src={Logo} alt="Logo" />
                </span>
              </a>
              {user.tipo !== TIPO_USUARIO.MEDICO && (
                <div className="nav_list">
                  <Link
                    to="/dashboard"
                    className="nav_link active"
                    title="Dashboard"
                  >
                    <FiGrid size={16} />
                    <span className="nav_name">Dashboard</span>
                  </Link>
                  <Link to="/paciente" className="nav_link" title="Pacientes">
                    <FiUsers size={16} />
                    <span className="nav_name">Pacientes</span>
                  </Link>
                  <Link
                    to={`/agenda/${agendaPadrao}`}
                    className="nav_link"
                    title="Agendas"
                  >
                    <FiCalendar size={16} />
                    <span className="nav_name">Agendas</span>
                  </Link>

                  <Link to="/caixa" className="nav_link" title="Caixa">
                    <FiShoppingCart size={16} />
                    <span className="nav_name">Caixa</span>
                  </Link>

                  <button
                    type="button"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                    className="nav_link"
                    onClick={() => handleSubMenu('submenuConfiguracao')}
                  >
                    <FiSliders size={16} />
                    <span className="nav_name">
                      Configurações <FiChevronRight className="ml-2" />
                    </span>
                  </button>

                  <div id="submenuConfiguracao" className="submenu">
                    <Link to="/usuario" className="nav_link" title="Usuários">
                      <span className="nav_name"> Usuários</span>
                    </Link>

                    <Link
                      to="/profissional"
                      className="nav_link"
                      title="Profissionais"
                    >
                      <span className="nav_name"> Profissionais</span>
                    </Link>

                    {/* revisar */}
                    {/* <Link
                    to="/especiaidade"
                    className="nav_link"
                    title="Especialidades"
                  >
                    <span className="nav_name"> Especialidades</span>
                  </Link> */}

                    <Link
                      to="/agendacadastro"
                      className="nav_link"
                      title="Agendas"
                    >
                      <span className="nav_name">Agendas</span>
                    </Link>

                    <Link
                      to="/procedimento"
                      className="nav_link"
                      title="Procedimentos"
                    >
                      <span className="nav_name">Procedimentos</span>
                    </Link>

                    <Link to="/whatsapp" className="nav_link" title="Whatsapp">
                      <span className="nav_name">Whatsapp</span>
                    </Link>

                    <Link
                      to="/MedicoEncaminhamento"
                      className="nav_link"
                      title="Medico Encaminhamento"
                    >
                      <span className="nav_name">Medico Encaminhamento</span>
                    </Link>

                    <Link
                      to="/modelomensagem"
                      className="nav_link d-none"
                      title="Modelo Mensagem"
                    >
                      <span className="nav_name">Modelo Mensagem</span>
                    </Link>
                    <Link
                      to="/notafiscal"
                      className="nav_link d-none"
                      title="Nota Fiscal"
                    >
                      <span className="nav_name">Nota Fiscal</span>
                    </Link>
                  </div>

                  <button
                    type="button"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                    className="nav_link"
                    onClick={() => handleSubMenu('Exames')}
                  >
                    <FiClipboard size={16} />
                    <span className="nav_name">
                      Exames <FiChevronRight className="ml-2" />
                    </span>
                  </button>

                  <div id="Exames" className="submenu">
                    <Link
                      to="/examesSolicitados"
                      className="nav_link"
                      title="Exames Solicitados"
                    >
                      <span className="nav_name">Exames Solicitados</span>
                    </Link>
                  </div>

                  <button
                    type="button"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                    className="nav_link  d-none"
                    onClick={() => handleSubMenu('Despesas')}
                  >
                    <FiDollarSign size={16} />
                    <span className="nav_name">
                      Despesas <FiChevronRight className="ml-2" />
                    </span>
                  </button>

                  <div id="Despesas" className="submenu">
                    <Link to="/despesas" className="nav_link" title="Despesas">
                      <span className="nav_name">Despesas</span>
                    </Link>

                    <Link
                      to="/despesas"
                      className="nav_link"
                      title="Relatório Despesas"
                    >
                      <span className="nav_name">Relatório Despesas</span>
                    </Link>

                    <Link
                      to="/resumogerencial"
                      className="nav_link"
                      title="Resumo Gerencial"
                    >
                      <span className="nav_name"> Resumo Gerencial</span>
                    </Link>
                  </div>

                  <button
                    type="button"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                    className="nav_link"
                    onClick={() => handleSubMenu('submenuRelatorio')}
                  >
                    <FiFileText size={16} />
                    <span className="nav_name">
                      Relatórios <FiChevronRight className="ml-2" />
                    </span>
                  </button>

                  <div id="submenuRelatorio" className="submenu">
                    <Link
                      to="/fechamentocaixa"
                      className="nav_link"
                      title="Fechamento Caixa"
                    >
                      <span className="nav_name">Fechamento Caixa</span>
                    </Link>

                    <Link
                      to="/conferencia"
                      className="nav_link"
                      title="Conferencia"
                    >
                      <span className="nav_name">Conferencia</span>
                    </Link>

                    <Link
                      to="/fechamentoperiodo"
                      className="nav_link"
                      title="Recebimentos por Período"
                    >
                      <span className="nav_name">Recebimentos Período</span>
                    </Link>

                    <Link
                      to="/fechamentoconvenio"
                      className="nav_link"
                      title="Fechamento Convênio"
                    >
                      <span className="nav_name">Fechamento Convênio</span>
                    </Link>

                    <Link
                      to="/resumogerencial"
                      className="nav_link"
                      title="Resumo Gerencial"
                    >
                      <span className="nav_name"> Resumo Gerencial</span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={handleLogout}
              className="button-reset nav_link"
              title="Sair"
            >
              <FiLogOut size={16} /> Sair
            </button>
          </nav>
        </div>

        {/* Container Main start */}
        <div className="page-wrapper">{children}</div>
        {/* Container Main end */}
      </div>
    </div>
  );
};

export default MainStructure;
