import React, { useEffect, useRef, useState } from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { useField } from '@unform/core';

import { htmlDecode, htmlEncode } from '../../utils/funcoes';

// import { Container } from './styles';

export interface IOptionsModelo {
  text: string;
  value: string;
  label: string;
}
interface Props extends IAllProps {
  name: string;
  labelButton?: string;
  editorSimples?: boolean;
  height: number;
  modelo: IOptionsModelo[];
}
interface IOptionsEditor {
  type: 'menuitem';
  text: string;
  onAction(): void;
}

const EditorDocumento: React.FC<Props> = ({
  name,
  height,
  modelo,
  labelButton = 'Campos Personalizados',
  editorSimples = false,
}) => {
  const editorRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [editorIsInitialized, setEditorIsInitialized] = useState(false);
  const [texto, setTexto] = useState('');

  const plugins = editorSimples
    ? []
    : [
        'advlist lists charmap hr wordcount visualblocks visualchars ',
        'insertdatetime nonbreaking table paste pagebreak print code ',
      ];

  const toolbar = editorSimples
    ? [`undo redo | bold italic | menuButton`]
    : [
        `undo redo | fontselect fontsizeselect | bold italic underline |
    alignleft aligncenter alignright alignjustify | forecolor backcolor`,
        ' bullist numlist outdent indent | table | pagebreak print code | menuButton ',
      ];

  useEffect(() => {
    setTexto(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: editorRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <input
        className="d-none"
        value={texto}
        ref={editorRef}
        onChange={evt => setTexto(encodeURI(evt.target.value))}
      />
      <Editor
        apiKey="tti8mmhfb6q4ey6750twym2bm3qahj8d2l87pbit3pt9yf1c"
        onInit={() => {
          if (!editorIsInitialized) {
            setEditorIsInitialized(true);
          }
        }}
        initialValue={editorIsInitialized ? defaultValue : undefined}
        init={{
          height,
          encoding: 'xml',
          language: 'pt_BR',
          browser_spellcheck: true,
          contextmenu: false,
          menubar: false,
          fontsize_formats:
            '8px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 42px 48px 62px',
          plugins,
          toolbar,
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          setup(editor) {
            editor.ui.registry.addMenuButton('menuButton', {
              text: labelButton,
              fetch(callback) {
                const items: IOptionsEditor[] = modelo.map(model => ({
                  type: 'menuitem',
                  text: model.text,
                  onAction() {
                    editor.insertContent(htmlDecode(model.value));
                  },
                }));

                callback(items);
              },
            });
          },
        }}
        onEditorChange={(evt, ed) => setTexto(htmlEncode(ed.getContent()))}
      />
    </>
  );
};

export default EditorDocumento;
