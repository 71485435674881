import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { differenceInHours } from 'date-fns';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';
import { Box, LineForm } from '../../pages/Exame/styles';
import { LinkDetalhe } from './styles';

import Input from '../Exame/InputExame';
// import SelectAsync from '~/Components/SelectAsync';
import SelectSync from '../Exame/SelectSync';
import Toggle from '../Exame/Toggle';
import CheckBoxContainer from '../Exame/CheckboxContainer';
import Disappearedloading from '../Loading';

import DadosAdicionais from './DadosAdicionais';
import ValidaAdicionais from './ValidaAdicionais';
import getValidationErrors from '../../utils/getValidationErrors';
import getResponseErrors from '../../utils/getResponseErrors';
import { IExameItem } from '../../pages/Exame';

type ItemExameProps = {
  Item: IExameItem;
  setStatusExame(id: number, statusAtual: string): void;
};
interface IOptions {
  value: string;
  label: string;
}

const ItemExame: React.FC<ItemExameProps> = ({ Item, setStatusExame }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const [campos, setCampos] = useState(Item);
  const [editing, setEditing] = useState(true);
  const [asyncOptions, setAsyncOptions] = useState<IOptions[]>([]);
  // const [defaultRecipient, setDefaultRecipient] = useState(null);
  // const [inputValue, setInputValue] = useState('');

  const EhConfirmado = useCallback(() => {
    return campos.status === 'Confirmado';
  }, [campos.status]);

  const EhExcluido = useCallback(() => {
    try {
      return campos.status === 'Excluido';
    } catch (error) {
      return false;
    }
  }, [campos.status]);

  const FoiSolicitado = useCallback(() => {
    try {
      return campos.status === 'Solicitado';
    } catch (error) {
      return false;
    }
  }, [campos.status]);

  const EhCurva = useCallback(() => {
    return String(campos.ehCurva) === 'true';
  }, [campos.ehCurva]);

  const TemDadosAdicionais = useCallback(() => {
    return String(campos.temDados) !== '';
  }, [campos.temDados]);

  const TemAltura = useCallback(() => {
    return campos.temDados.includes('Altura');
  }, [campos.temDados]);

  const TemPeso = useCallback(() => {
    return campos.temDados.includes('Peso');
  }, [campos.temDados]);

  const TemVolume = useCallback(() => {
    return campos.temDados.includes('Volume');
  }, [campos.temDados]);

  const EhConfirmadoOuExcluido = useCallback(() => {
    return EhConfirmado() || EhExcluido();
  }, [EhConfirmado, EhExcluido]);

  const EhConfirmadoOuExcluidoOuFoiSolicitado = useCallback(() => {
    return EhConfirmadoOuExcluido() || FoiSolicitado();
  }, [EhConfirmadoOuExcluido, FoiSolicitado]);

  useEffect(() => {
    setLoading(true);
    async function loadItens(): Promise<void> {
      // console.log(JSON.parse(campos.listaMaterial).material);
      const { dataAtualizacao } = campos;

      const naoAtualizaMaterial =
        dataAtualizacao && differenceInHours(new Date(), dataAtualizacao) < 10;

      if (
        campos.listaMaterial &&
        campos.listaMaterial !== null &&
        campos.listaMaterial !== '{}' &&
        naoAtualizaMaterial
      ) {
        setAsyncOptions([
          { label: 'Selecione', value: '' },
          ...JSON.parse(campos.listaMaterial).material,
        ]);
        return;
      }

      try {
        const gr = await api.put(
          `laboratorio/AtualizaMaterial/${campos.codigoAlvaro}`,
        ); // ${query}`);

        setAsyncOptions([
          { label: 'Selecione', value: '' },
          ...gr.data.material,
        ]);
      } catch (error) {
        if (
          campos.listaMaterial &&
          campos.listaMaterial !== null &&
          campos.listaMaterial !== '{}'
        ) {
          setAsyncOptions([
            { label: 'Selecione', value: '' },
            ...JSON.parse(campos.listaMaterial).material,
          ]);
        } else {
          setAsyncOptions([]);
        }
      }
    }
    loadItens();
    setLoading(false);
  }, [campos, addToast]);

  const AlterarStatus = useCallback(
    async (status: string): Promise<void> => {
      setLoading(true);
      try {
        const {
          material,
          qtde,
          observacao,
          urgente,
          curva,
          dadosAdicionais,
          temDados,
        } = campos;

        const dados = {
          material,
          qtde,
          observacao,
          urgente,
          curva,
          dadosAdicionais,
          temDados,
          status,
        };

        await api.put(`exame/AtualizaItem/${campos.id}`, dados);

        setCampos({ ...campos, status });
        // atualizaStatusItem({ id: campos.id, status });

        setStatusExame(campos.id, status);

        setEditing(true);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível alterar status',
          type: 'error',
          description: mensagemErro,
        });
      }
      setLoading(false);
    },
    [addToast, campos, setStatusExame],
  );

  useEffect(() => {
    setEditing(!EhConfirmadoOuExcluidoOuFoiSolicitado());
  }, [EhConfirmadoOuExcluidoOuFoiSolicitado]);

  useEffect(() => {
    if (asyncOptions && asyncOptions.length > 0) {
      formRef.current?.setFieldValue(
        'material',
        campos.material !== '' ? campos.material : asyncOptions[0].value,
      );
    }
  }, [asyncOptions, campos.material]);

  const handleSubmit = useCallback(
    async (data): Promise<void> => {
      setLoading(true);
      if (!editing) {
        setEditing(true);
        setLoading(false);
        setCampos({ ...data, status: 'Confirmar' });
        setStatusExame(campos.id, 'Confirmar');
        // atualizaItem({ ...data, status: 'Confirmar' });
        // atualizaStatusItem({ id: campos.id, status: 'Confirmar' });
        return;
      }

      try {
        // Remove all previous errors
        formRef.current?.setErrors({});

        let adic = {};
        campos.temDados.split(',').forEach(cp => {
          adic = { ...adic, ...ValidaAdicionais(cp) };
        });

        const schema = Yup.object().shape({
          codigoAlvaro: Yup.string().required('Código do exame obrigatório'),
          material: Yup.string().required('Selecione o Material'),
          qtde: Yup.number()
            .integer('Digite apenas números')
            .typeError('Digite apenas números')
            .min(1, 'Digite um valor maior que zero')
            .required('Quantidade obrigatória'),
          ehCurva: Yup.boolean(),
          curva: Yup.string().when('ehCurva', {
            is: true,
            then: Yup.string()
              .min(1, 'Selecione um ponto de curva')
              .required('Selecione um ponto de curva.'),
          }),
          ...adic,
        });

        await schema.validate(
          {
            ...data,
            ehCurva: EhCurva(),
          },
          {
            abortEarly: false,
          },
        );

        let dadosAd = '';
        dadosAd += data.altura ? `altura=${data.altura}@carac#xA;` : '';
        dadosAd += data.peso ? `peso=${data.peso}@carac#xA;` : '';
        dadosAd += data.volume ? `volume=${data.volume}@carac#xA;` : '';
        dadosAd += data.hematocrito
          ? `hematocrito=${data.hematocrito}@carac#xA;`
          : '';
        dadosAd += data.tipoamostra
          ? `tipoamostra=${data.tipoamostra}@carac#xA;`
          : '';
        dadosAd += data.leucocitos
          ? `leucocitos=${data.leucocitos}@carac#xA;`
          : '';
        dadosAd += data.norovirusrna
          ? `norovirusrna=${data.norovirusrna}@carac#xA;`
          : '';
        dadosAd += data.idadematerna
          ? `idadematerna=${data.idadematerna}@carac#xA;`
          : '';
        dadosAd += data.dum ? `dum=${data.dum}@carac#xA;` : '';
        dadosAd += data.idadegestacionalsemanas
          ? `idadegestacionalsemanas=${data.idadegestacionalsemanas}@carac#xA;`
          : '';
        dadosAd += data.cpf ? `cpf=${data.cpf}@carac#xA;` : '';
        dadosAd += data.rg ? `rg=${data.rg}@carac#xA;` : '';
        dadosAd += data.numerocadeiacustodia
          ? `numerocadeiacustodia=${data.numerocadeiacustodia}@carac#xA;`
          : '';

        const dados = {
          material: data.material,
          qtde: data.qtde,
          observacao: data.observacao,
          urgente: data.urgente === 'true',
          status: 'Confirmado',
          curva: data.curva,
          dadosAdicionais: dadosAd,
          temDados: data.temDados,
        };

        await api.put(`exame/AtualizaItem/${data.id}`, dados);
        setCampos({ ...data, status: 'Confirmado' });
        // atualizaItem({ ...data, status: 'Confirmado' });
        // atualizaStatusItem({ id: campos.id, status: 'Confirmado' });
        setStatusExame(campos.id, 'Confirmado');
        setEditing(false);
        setLoading(false);
      } catch (error) {
        // console.log(err);
        if (error instanceof Yup.ValidationError) {
          // Validation failed
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Não foi possível atualizar dados',
            type: 'error',
            description: mensagemErro,
          });
        }
        setLoading(false);
      }
    },
    [EhCurva, addToast, campos, editing, setStatusExame],
  );

  return (
    <>
      {loading ? <Disappearedloading /> : ''}
      <Form
        autoComplete="off"
        ref={formRef}
        initialData={campos}
        onSubmit={handleSubmit}
      >
        <Box esmaecer={EhExcluido() || FoiSolicitado()}>
          <LineForm>
            <div style={{ width: '15%' }}>
              <Input
                label="Código do Exame"
                type="text"
                name="codigoAlvaro"
                placeholder="Código do Exame"
                maxLength={10}
                id="codigoAlvaro"
                disabled
              />
            </div>
            <div style={{ width: '25%' }}>
              <Input
                label="Nome do Exame"
                type="text"
                name="nome"
                placeholder="Nome do Exame"
                maxLength={50}
                id="nome"
                disabled
              />
            </div>
            <div style={{ display: 'grid', height: '14px', width: '30%' }}>
              <SelectSync
                name="material"
                id="material"
                options={asyncOptions}
                placeholder="Material"
                label="Material"
              />
            </div>
            <div style={{ width: '15%' }}>
              <Input
                label="Qtde. Amostras"
                type="number"
                name="qtde"
                placeholder="Qtde. Amostras"
                maxLength={2}
                min="0"
                max="99"
                id="qtde"
                disabled={!editing}
              />
            </div>
            <div
              style={{
                width: EhConfirmadoOuExcluido() ? '5%' : '0',
                visibility: EhConfirmadoOuExcluido() ? 'visible' : 'hidden',
                transition: 'width 0.2s ease-in-out',
              }}
            >
              <button
                onClick={() => AlterarStatus('Confirmar')}
                type="button"
                title="Editar"
                style={{
                  borderBottom: '0px',
                  border: 'none',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  fontSize: '25px',
                }}
              >
                <FaRegEdit />
              </button>
            </div>
          </LineForm>
          <LineForm
            style={{ display: 'block' }}
            invisivel={EhConfirmadoOuExcluidoOuFoiSolicitado() || !EhCurva()}
          >
            {EhCurva() ? <CheckBoxContainer name="curva" /> : null}
          </LineForm>
          <LineForm
            invisivel={
              EhConfirmadoOuExcluidoOuFoiSolicitado() || !TemDadosAdicionais()
            }
          >
            {TemDadosAdicionais() &&
              campos.temDados.split(',').map(cp => (
                <div
                  key={cp.replace(/\s/g, '')}
                  style={{
                    width: '30%',
                    display: !EhConfirmadoOuExcluidoOuFoiSolicitado()
                      ? 'block'
                      : 'none',
                    maxHeight: !EhConfirmadoOuExcluidoOuFoiSolicitado()
                      ? 'max-content'
                      : '0',
                  }}
                >
                  <DadosAdicionais Dado={cp} />
                </div>
              ))}
            <div
              style={{
                width: '30%',
                display:
                  !EhConfirmadoOuExcluidoOuFoiSolicitado() && TemAltura()
                    ? 'block'
                    : 'none',
                maxHeight:
                  !EhConfirmadoOuExcluidoOuFoiSolicitado() && TemAltura()
                    ? 'max-content'
                    : '0',
              }}
            >
              <Input
                label="Altura"
                type="text"
                name="altura"
                placeholder="Altura"
                maxLength={3}
                id="altura"
                disabled={!editing}
                unidade="cm"
              />
            </div>
            <div
              style={{
                width: '30%',
                display:
                  !EhConfirmadoOuExcluidoOuFoiSolicitado() && TemPeso()
                    ? 'block'
                    : 'none',
                maxHeight:
                  !EhConfirmadoOuExcluidoOuFoiSolicitado() && TemPeso()
                    ? 'max-content'
                    : '0',
              }}
            >
              <Input
                label="Peso"
                type="text"
                name="peso"
                placeholder="Peso"
                maxLength={6}
                id="peso"
                disabled={!editing}
                unidade="kg"
              />
            </div>

            <div
              style={{
                width: '30%',
                display:
                  !EhConfirmadoOuExcluidoOuFoiSolicitado() && TemVolume()
                    ? 'block'
                    : 'none',
                maxHeight:
                  !EhConfirmadoOuExcluidoOuFoiSolicitado() && TemVolume()
                    ? 'max-content'
                    : '0',
              }}
            >
              <Input
                label="Volume"
                type="text"
                name="volume"
                placeholder="Volume"
                id="volume"
                maxLength={6}
                disabled={!editing}
                unidade="ml"
              />
            </div>
          </LineForm>
          <LineForm invisivel={EhConfirmadoOuExcluidoOuFoiSolicitado()}>
            <div style={{ width: '44%' }}>
              <Input
                label="Observação"
                type="text"
                name="observacao"
                placeholder="Observação"
                maxLength={250}
                id="observacao"
                disabled={!editing}
              />
            </div>
            <div style={{ width: '130px' }}>
              <LinkDetalhe>
                <i>
                  <svg
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" />
                  </svg>
                </i>
                <a
                  tabIndex={-1}
                  id="txtNewExamDetails"
                  href="https://www.alvaroapoio.com.br/detalhe-exame?ex=GLICO"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Detalhes do Exame
                </a>
              </LinkDetalhe>
            </div>
            <div
              style={{
                width: '130px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Toggle nome="urgente" label="Urgente" isDisabled={!editing} />
            </div>
            <div
              style={{
                width: '3%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                onClick={() => AlterarStatus('Excluido')}
                type="button"
                title="Excluir"
                style={{
                  borderBottom: '0px',
                  border: 'none',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  fontSize: '25px',
                }}
              >
                <FaRegTrashAlt />
              </button>
            </div>
            <div style={{ width: '13%' }}>
              <button className="submit" type="submit">
                {loading ? 'Carregando' : 'Confirmar'}
              </button>
            </div>
          </LineForm>
        </Box>
        <Input type="hidden" name="id" id="id" />
        <Input type="hidden" name="ehCurva" id="ehCurva" />
        <Input type="hidden" name="temDados" id="temDados" />
      </Form>
    </>
  );
};

export default memo(ItemExame);
