import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';

import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

import { Label, Content, Erro } from './styles';

interface DataPickerProps {
  name: string;
  label: string;
  placeholder: string;
  className: string;
  id: string;
  maxLength?: number;
  type: string;
  dateFormat: string;
  disabled: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
}
const DataPicker: React.FC<DataPickerProps> = ({
  name,
  label,
  placeholder,
  ...rest
}) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState(defaultValue || null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: ref => {
        ref.clear();
      },
      setValue: (ref, valord) => {
        setDate(valord);
      },
    });
  }, [date, fieldName, registerField]);

  const handleFocus = useCallback(e => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(e => {
    setFocused(false);
  }, []);
  return (
    <>
      <Content>
        <Label htmlFor={name} exibe={date !== null || focused}>
          {label}
        </Label>
        <ReactDatePicker
          ref={datepickerRef}
          name={name}
          selected={date}
          onChange={setDate}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
          placeholderText={placeholder}
          autoComplete="new-password"
        />

        {error && <Erro className="error">{error}</Erro>}
      </Content>
    </>
  );
};

export default DataPicker;
