import React, { useState } from 'react';

import { Container, Button } from 'react-bootstrap';

import ModeloCadastro from './ModeloCadastro';

import ListaModelos from './ListaModelos';

export interface IModelo {
  id: number;
  nome: string;
  texto: string;
  tipo: number;
  ativo: boolean;
  ativoFormatado?: string;
  nomeTipo?: string;
  updated_at: Date;
  dataAtualizacaoFormatada: string;
  usuarioEditor: {
    id: number;
    nome: string;
  };
}

type pageProps = {
  modeloMensagem?: boolean;
};

const Modelo: React.FC<pageProps> = ({ modeloMensagem = false }) => {
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [modeloSelecionado, setModeloSelecionado] = useState<
    IModelo | undefined
  >(undefined);

  return (
    <Container fluid className="pt-2">
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Modelos de Mensagem
            </span>
          </div>
          <div className="col-auto d-flex justify-content-end">
            <div className="doc-badge me-3 small">
              {modeloMensagem === false && !showModalCadastro && (
                <div>
                  <Button
                    type="button"
                    variant="success"
                    onClick={() => {
                      setModeloSelecionado(undefined);
                      setShowModalCadastro(true);
                    }}
                  >
                    Novo Modelo
                  </Button>
                </div>
              )}
              {showModalCadastro && (
                <div>
                  <Button
                    type="button"
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      setModeloSelecionado(undefined);
                      setShowModalCadastro(false);
                    }}
                  >
                    Voltar para Lista
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <p>
        {modeloMensagem
          ? 'Configure o modelo de texto padrão utilizados nas comunicações com o paciente, como confirmação de agendamentos, aniversário, cobranças'
          : 'Configure o modelo de texto padrão utilizados nos prontuários, receitas e documentos gerados para o paciente.'}
      </p> */}

      <div className="card">
        <div className="card-header">
          Modelos utilizados nas comunicações com os pacientes
        </div>
        <div className="card-body p-0">
          {showModalCadastro && (
            <ModeloCadastro
              setShowModal={setShowModalCadastro}
              modelo={modeloSelecionado}
              modeloMensagem={modeloMensagem}
            />
          )}
          {!showModalCadastro && (
            <ListaModelos
              setShowModal={setShowModalCadastro}
              showModal={showModalCadastro}
              setModeloSelecionado={setModeloSelecionado}
              modeloMensagem={modeloMensagem}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Modelo;
