import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API || 'http://localhost:3333/', {
  query: { usu: 1 },
  transports: ['websocket', 'polling', 'flashsocket'],
});

export default socket;

// import io from 'socket.io-client';

// export interface IUsuario {
//   usuCodigo: string;
//   usuNome: string;
//   sessionId: string;
//   clinica: string;
//   codClinica: string;
//   pagina: string;
//   socketId?: string;
// }

// const socket = (dados: IUsuario): SocketIOClient.Socket => {
//   return io(process.env.REACT_APP_API || 'http://localhost:3333/', {
//     query: { ...dados },
//     transports: ['websocket', 'polling', 'flashsocket'],
//   });
// };

// export default socket;
