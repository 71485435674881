import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/constants';
// import RodapeBack from '../../assets/rodapeImpressao.png';

interface ContainerProps {
  duasVias?: boolean;
}

export const Content = styled.div<ContainerProps>`
  color: #000;
  background-color: transparent !important;
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 100%;
  height: 100%;
  max-width: 297mm;

  @media print {
    @page {
      ${props =>
        props.duasVias &&
        css`
          size: landscape;
          position: relative;
        `}
    }
  }
`;

export const Barra = styled.div<ContainerProps>`
  width: 100%;
  height: 28px;
  ${props =>
    props.duasVias &&
    css`
      width: 99%;
      height: 18px;
    `}
  background-color: ${Colors.verde};
`;

export const Footer = styled.div<ContainerProps>`
  position: relative;
  bottom: 0;
  font-size: 0.9rem;
  ${props =>
    props.duasVias &&
    css`
      zoom: 48%;
    `}
  h2 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 1px;
  }

  p {
    margin-bottom: 0;
  }

  @media print {
    position: absolute;
    bottom: 0;
    width: 100%;
    ${props =>
      props.duasVias &&
      css`
        position: relative;

        box-sizing: border-box;
        padding: 0 20px;
        overflow: hidden;
      `}
  }
`;

export const FooterBackGround = styled.div<ContainerProps>`
  height: 175px;
  background-position: center;
  ${props =>
    props.duasVias &&
    css`
      height: 165px;
      background-position: top center;
    `}
  background-size: cover;
  background-repeat: no-repeat;
`;

export const DadosClinica = styled.div`
  margin-left: 75px;
  margin-top: 0.25rem;
  color: ${Colors.verde};

  @media print {
    margin-left: 45px;
  }
`;

export const BordaTelefone = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${Colors.verde};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
