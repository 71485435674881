import styled from 'styled-components';

export const RelatorioHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-bottom-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.5rem 1.25rem;
  font-weight: bold;
`;
