import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import { FaCheckCircle } from 'react-icons/fa';

import api from '../../../services/api';

import {
  formatPrice,
  TIPO_USUARIO,
  ORIGEM_PAGAMENTO,
} from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';
import { useCategoria } from '../../../hooks/categoria';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import SelectSync, { IOption } from '../../../components/SelectSync';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import Pagamento from '../../../components/Pagamento';

import getValidationErrors from '../../../utils/getValidationErrors';

import getResponseErrors from '../../../utils/getResponseErrors';
import HeaderRelatorio from '../HeaderRelatorio';

interface IPagamento {
  descricao: string;
  valor: number;
  valorFormatado: string;
}

interface IDespesa {
  id: number;
  origen_id: number;
  data: Date;
  dataFormatado: string;
  descricao: string;
  valor: number;
  valorFormatado: string;
  clinica_id: number;
  pago: boolean;
  pagamento: IPagamento[];
}

interface IRelatorio {
  data: IDespesa[];
  count: number;
}
interface ITotais {
  totalPagoFormatado: string;
  totalPagarFormatado: string;
}

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  clinica_id: string;
  categoria_id: string;
  descricao: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

interface IDadosPagamento {
  tituloPagamento: string;
  pagamentoSelecionado?: number;
  codOrigem?: number;
}

const Despesas: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica, imagensClinica } = useClinica();

  const logo =
    imagensClinica.find(img => img.nome === 'logoRelatorio')?.arquivo_url || '';

  const { optionCategorias, buscaCategoria } = useCategoria();

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState<IDespesa[]>();
  const [totais, setTotais] = useState<ITotais>();
  const initialData = {
    dataInicio: format(new Date(), 'yyyy-MM-dd'),
    dataFim: format(new Date(), 'yyyy-MM-dd'),
    clinica_id: user.clinica_id.toString(),
  };
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinica, setClinica] = useState('');
  const [showModalPagamento, setShowModalPagamento] = useState(false);
  const [dadosPagamento, setDadosPagamento] = useState<
    IDadosPagamento | undefined
  >();

  const [optionsCategoria, setOptionsCategoria] = useState<IOption[]>();

  const exibeTotais = user.tipo === TIPO_USUARIO.ADMINISTRADOR;

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  useEffect(() => {
    async function loadPerfis(): Promise<void> {
      await buscaCategoria();
    }
    if (!optionCategorias || Object.keys(optionCategorias).length === 0)
      loadPerfis();
    setOptionsCategoria(optionCategorias.despesa);
  }, [buscaCategoria, optionCategorias]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          dataFim: Yup.date().required(),
          clinica_id: Yup.string().required(),
          categoria_id: Yup.string().nullable(true),
          descricao: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinica(
          optionsClinica.find(cli => cli.value === dados.clinica_id)?.label ||
            '',
        );

        const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
        const dataFimSelecionada = parseISO(dados.dataFim.toString());

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm'),
        });

        let parametrosOpcionais = dados.categoria_id
          ? `&categoria_id=${dados.categoria_id}`
          : '';
        parametrosOpcionais += dados.descricao
          ? `&descricao=${dados.descricao}`
          : '';

        const response = await api.get<IRelatorio>(
          `/relatorios/despesas?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}&clinica_id=${dados.clinica_id}${parametrosOpcionais}`,
        );

        let totalPago = 0;
        let totalPagar = 0;

        const { data } = response.data;

        const dadosFormatados = data.map(rel => {
          const dataFormatado = format(
            parseISO(rel.data.toString()),
            'dd/MM/yyyy',
          );

          rel.pago ? (totalPago += rel.valor) : (totalPagar += rel.valor);

          return {
            ...rel,
            dataFormatado,
            valorFormatado: formatPrice(rel.valor),
            pagamento: rel.pagamento
              ? rel.pagamento.map(des => {
                  return { ...des, valorFormatado: formatPrice(des.valor) };
                })
              : [{ descricao: '', valor: 0, valorFormatado: 'R$ 0,00' }],
          };
        });

        setDadosRelatorio(dadosFormatados);
        setTotais({
          totalPagoFormatado: formatPrice(totalPago),
          totalPagarFormatado: formatPrice(totalPagar),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica],
  );

  const handlePagamento = useCallback(
    (titulo: string, cprCodigo?: number, cprCodOrigem?: number) => {
      setDadosPagamento({
        tituloPagamento: titulo,
        pagamentoSelecionado: cprCodigo,
        codOrigem: cprCodOrigem,
      });

      setShowModalPagamento(true);
    },
    [],
  );

  const handleFecharModalPagamento = useCallback(async (load: boolean) => {
    setShowModalPagamento(false);
    setDadosPagamento(undefined);
    if (load) {
      formRefBusca.current?.submitForm();
      setDadosPagamento(undefined);
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading />}
      {showModalPagamento && (
        <Modal
          show={showModalPagamento}
          titulo={dadosPagamento?.tituloPagamento || ''}
          handleFecharModal={() => setShowModalPagamento(false)}
        >
          <Pagamento
            handleFechar={handleFecharModalPagamento}
            tipo={ORIGEM_PAGAMENTO.DESPESA.toString()}
            cprCodigo={dadosPagamento?.pagamentoSelecionado}
          />
        </Modal>
      )}

      <div className="d-print-none page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Despesas
            </span>
          </div>
          <div className="col-auto">
            <Button
              type="button"
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                handlePagamento(
                  'Inserir Despesa',
                  undefined,
                  undefined,
                  // eslint-disable-next-line prettier/prettier
                )}
            >
              Inserir Despesa
            </Button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-print-none">
          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
          >
            <Row>
              <Col md={2}>
                <Input name="dataInicio" label="De" type="date" required />
              </Col>
              <Col md={2}>
                <Input name="dataFim" label="Até" type="date" required />
              </Col>
              <Col md={2}>
                <SelectSync
                  name="categoria_id"
                  label="Categoria"
                  options={optionsCategoria || []}
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                />
              </Col>
              <Col md={2}>
                <Input name="descricao" label="Histórico" type="text" />
              </Col>
              <Col md={2}>
                <Select
                  name="clinica_id"
                  label="Clinica"
                  options={optionsClinica}
                  disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                />
              </Col>
              <Col md={2}>
                <br />
                <Button type="submit" size="sm" variant="outline-secondary">
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        {/* <div className="bg-white border p-2 my-3">
          <img src={logo} alt="Agenda Facil" /> Impresso em{' '}
          {dataExibe?.dataHora}
        </div> */}

        <HeaderRelatorio
          dataImpressao={dataExibe?.dataHora}
          titulo={`DESPESAS ${clinica ? ` - ${clinica}` : ''}${
            dataExibe?.dataDiaSemana ? ` - ${dataExibe?.dataDiaSemana}` : ''
          }`}
        />

        <div className="card-body p-0" style={{ minHeight: '300px' }}>
          <div className="table-responsive">
            <table className="table table-sm hover-table no-footer">
              <thead className="thead-light">
                <tr className="text-center">
                  <th style={{ width: '85px' }}>Data</th>
                  <th style={{ width: '100%' }}>Descrição</th>
                  <th style={{ minWidth: '135px' }}>Pagamento</th>
                  <th style={{ minWidth: '135px' }}>Valor</th>
                  <th style={{ width: '35px' }}>-</th>
                </tr>
              </thead>
              <tbody>
                {dadosRelatorio &&
                  dadosRelatorio.map(rel => (
                    <tr key={`despesas-${rel.id}`} data-id={rel.id}>
                      <td>{rel.dataFormatado}</td>
                      <td>{rel.descricao}</td>
                      <td>
                        {rel.pagamento.map(pag => (
                          <span
                            key={`pagD-${rel.id}-${pag.descricao}`}
                            className="d-block"
                          >
                            {pag.descricao}
                          </span>
                        ))}
                      </td>
                      <td>
                        {rel.pago ? (
                          rel.pagamento.map(pag => (
                            <span
                              key={`pagV-${rel.id}-${pag.descricao}`}
                              className="d-block"
                            >
                              {pag.valorFormatado}
                            </span>
                          ))
                        ) : (
                          <span
                            key={`naoPago-${rel.id}-${rel.descricao}`}
                            className="d-block"
                          >
                            {rel.valorFormatado}
                          </span>
                        )}
                      </td>

                      <td>
                        <Button
                          data-id={rel.id}
                          type="button"
                          size="sm"
                          style={{ padding: '1px 8px' }}
                          className={rel.pago ? 'bg-success' : 'bg-secondary'}
                          onClick={
                            () =>
                              handlePagamento(
                                `Pagar - ${rel.descricao}`,
                                rel.id,
                                rel.origen_id,
                              )
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        >
                          <FaCheckCircle size={14} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                {exibeTotais && dadosRelatorio && dadosRelatorio.length > 0 && (
                  <tr
                    key="fdfdsfsdfdf"
                    style={{
                      fontSize: 'Small',
                      height: '30px',
                      fontWeight: 'bold',
                    }}
                  >
                    <td> </td>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      Total Pago: {totais?.totalPagoFormatado}
                    </td>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      Total a Pagar: {totais?.totalPagarFormatado}
                    </td>
                  </tr>
                )}
                {!loading && dadosRelatorio?.length === 0 && (
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center' }}>
                      <h5 className="py-4">
                        Nenhuma cobrança encontrada com os filtros aplicados
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Despesas;
