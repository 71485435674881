import styled from 'styled-components';

export const ContainerDados = styled.div`
  background-color: #fff;
  padding: 6px;
  border: 1px solid #d7d7d7;
`;

export const RelatorioHeader = styled.div`
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-bottom-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.75rem 1.25rem;
  font-weight: bold;
  text-align: center;
`;
