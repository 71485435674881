import { Scope } from '@unform/core';
import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { optionsHorasCadastroAgenda } from '../../../../utils/funcoes';
import Input from '../../../../components/Input';
import SelectSync from '../../../../components/SelectSync';

// import { Container } from './styles';

const Quinzenal: React.FC = () => {
  const optionsHora = useMemo(() => {
    return optionsHorasCadastroAgenda();
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <>
      <div id="PanHorarioQuinzenal">
        <Scope path="horarioRecorrente">
          <Row className="pt-3">
            <Col md={6} lg={6} className="form-group">
              <Input
                name="HorDataIni"
                label="Data do primeiro dia para atendimento"
                placeholder="Selecione uma data"
                type="date"
                containerStyle={{ maxWidth: '200px' }}
              />
            </Col>
            <Col md={3} lg={3} className="form-group">
              <SelectSync
                name="HorDiasRecorre"
                options={[{ label: 'QUINZENAL', value: '15' }]}
                handleSelectChange={handleOnChangeVazio}
                placeholder="Selecione"
                label="Recorrência"
              />
            </Col>
            <Col md={3} lg={3} className="form-group">
              <Input
                name="HorMaxAgendamento"
                label="Max. Agendamentos"
                type="number"
                step="1"
                max="99"
                min="0"
              />
            </Col>
            <div className="w-100" />
            <Col md={3} lg={3} className="form-group">
              <SelectSync
                name="HorHoraIni"
                options={optionsHora}
                handleSelectChange={handleOnChangeVazio}
                placeholder="00:00"
                label="Horário Inicial"
              />
            </Col>
            <Col md={3} lg={3} className="form-group">
              <SelectSync
                name="HorIntervaloIni"
                options={[{ label: '00:00', value: '' }, ...optionsHora]}
                handleSelectChange={handleOnChangeVazio}
                placeholder="00:00"
                label="Intervalo início"
              />
            </Col>
            <Col md={3} lg={3} className="form-group">
              <SelectSync
                name="HorIntervaloFim"
                options={[{ label: '00:00', value: '' }, ...optionsHora]}
                handleSelectChange={handleOnChangeVazio}
                placeholder="00:00"
                label="Intervalo fim"
              />
            </Col>
            <Col md={3} lg={3} className="form-group">
              <SelectSync
                name="HorHoraFim"
                options={optionsHora}
                handleSelectChange={handleOnChangeVazio}
                placeholder="00:00"
                label="Horário Final"
              />
            </Col>
          </Row>
        </Scope>
      </div>
    </>
  );
};

export default Quinzenal;
