interface IColors {
  [key: string]: string;
}

export const Colors: IColors = {
  primary: '#49aad5',
  secondary: '#1B5A90',
  success: '#17D053',
  danger: '#F0142F',
  warning: '#FFBC00',
  info: '#009efb',
  cinza: '#63829c',
  muted: '#7E84A3',
  verde: '#006F7F',
  verdeClaro: '#188F8F',
  purple: '#9368e9',
};

export const Sizes = [8, 16, 24, 32];
