import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  InputHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { checkboxes } from './checkboxes';
import Checkbox from '../CheckBox';
import { Container, ContentPrincipal, ContentTitle } from './styles';

interface ICheck {
  name: string;
  key: string;
  label: string;
  checked?: boolean;
}

interface DivProps extends InputHTMLAttributes<HTMLDivElement> {
  name: string;
}

const CheckboxContainer: React.FC<DivProps> = ({ name, ...rest }) => {
  const checkRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [checkedItems, setCheckedItems] = useState<string>('');
  const [itens, setItens] = useState<ICheck[]>([]);

  /* constructor(props) {      super(props);      this.state = {         checkedItems: new Map(),      };
      this.handleChange = this.handleChange.bind(this);   } */

  useEffect(() => {
    // const its: iCkecks;
    // console.log('default', defaultValue);
    const i: ICheck[] = checkboxes().map(it => {
      // its[it.name] = false;
      return {
        ...it,
        checked: defaultValue.split(',').indexOf(it.label) !== -1,
      };
    });

    setItens(i);
    setCheckedItems(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkRef.current,
      path: 'value',
      clearValue: ref => {
        ref.clear();
      },
      setValue: (ref, valord) => {
        // setCheckedItems(valord);
      },
    });
  }, [checkedItems, fieldName, registerField]);

  const handleChange = useCallback(
    e => {
      const item = e.target.name;
      const isChecked = e.target.checked;

      const newItens = itens.map(it => {
        if (it.name === item) return { ...it, checked: isChecked };
        return it;
      });

      const its = newItens.filter((it: ICheck) => {
        return it.checked;
      });

      setCheckedItems(its.map(i => i.label).join(','));

      setItens(newItens);
    },
    [itens],
  );

  return (
    <div>
      <ContentTitle>
        <h2>Períodos de Curvas</h2>
      </ContentTitle>
      <ContentPrincipal>
        <input className="d-none" value={checkedItems} ref={checkRef} />
        {itens.map(item => (
          <Container key={item.key}>
            <Checkbox
              name={item.name}
              checked={item.checked}
              onChange={handleChange}
            />
            <label htmlFor={item.name}>{item.label}</label>
          </Container>
        ))}
      </ContentPrincipal>
    </div>
  );
};

export default CheckboxContainer;
