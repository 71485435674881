import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import getResponseErrors from '../utils/getResponseErrors';
import { useToast } from './toast';

interface IModelo {
  ModCodigo: number;
  ModNome: string;
  ModTexto: string;
  ModTipo: number;
  ModAtivo: boolean;
}

export interface IOptionsModelo {
  text: string;
  value: string;
}
export interface IFormBuscaModelo {
  nome?: string;
  tipo?: string;
  clinica?: string;
  ativo?: string;
}
interface ModeloContextData {
  loading: boolean;
  optionsModelo: IOptionsModelo[] | undefined;
  buscaOptionsModelo(busca: IFormBuscaModelo): Promise<void>;
  limparModelo(): void;
}

const ModeloContext = createContext<ModeloContextData>({} as ModeloContextData);

const ModeloProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const [optionsModelo, setOptionsModelo] = useState<
    IOptionsModelo[] | undefined
  >(() => {
    const optionsModelos = localStorage.getItem('@AgendaFacil:optionsModelo');
    if (optionsModelos) return JSON.parse(optionsModelos);

    return undefined;
  });

  const buscaOptionsModelo = useCallback(
    async (busca: IFormBuscaModelo) => {
      setLoading(true);
      try {
        const { nome, tipo, ativo } = busca;
        let query = '';
        if (nome && nome !== '') query = `ModNome=${nome}`;
        if (tipo && tipo !== '')
          query = `${query === '' ? '' : `${query}&`}ModTipo=${tipo}`;
        if (ativo && ativo !== '')
          query = `${query === '' ? '' : `${query}&`}ModAtivo=${ativo}`;
        query = `${query === '' ? '' : `${query}&`}ModModeloMensagem=false`;

        const response = await api.get(`/modelo?${query}`);
        const options: IOptionsModelo[] = response.data[0].map(
          (op: IModelo) => ({
            text: op.ModNome,
            value: op.ModTexto,
          }),
        );

        localStorage.setItem(
          '@AgendaFacil:optionsModelo',
          JSON.stringify(options),
        );
        setOptionsModelo(options);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar mensagens',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const limparModelo = useCallback(() => {
    localStorage.removeItem('@AgendaFacil:optionsModelo');
    setOptionsModelo(undefined);
  }, []);

  return (
    <ModeloContext.Provider
      value={{
        loading,
        buscaOptionsModelo,
        optionsModelo,
        limparModelo,
      }}
    >
      {children}
    </ModeloContext.Provider>
  );
};

function useModelo(): ModeloContextData {
  const context = useContext(ModeloContext);

  if (!context) {
    throw new Error('useModelo deve ser usado com o ModeloProvider');
  }

  return context;
}

export { ModeloProvider, useModelo };
