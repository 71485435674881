/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useCallback } from 'react';
// import IO from 'socket.io-client';
import { format } from 'date-fns';

import { Container, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { useToast } from '../../hooks/toast';

import { TIPO_USUARIO } from '../../utils/funcoes';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import getResponseErrors from '../../utils/getResponseErrors';
import ItensExame from './ItensExame';

export interface IExameItens {
  id: number;
  codigoAlvaro: string;
  exame_id: number;
  layout: string;
  status: string;
  codigoBarras: string;
  link?: string;
}

export interface IExame {
  id: number;
  agendamento_id: number;
  cliente_id: number;
  cliente_nome: string;
  cliente_sexo: string;
  data: Date;
  hora: Date;
  codigoLis?: string;
  codigoOS: string;
  observacao: string;
  incluido: boolean;
  link?: string;
  exameItensRetorno: IExameItens[];
}

export interface IBadge {
  posicao: Placement;
  titulo: string;
  estilo: string;
  itens: IExameItens[];
}

const ExamesSolicitados: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [exames, setExames] = useState<IExame[]>([]);
  const [botaoClicado, setBotaoClicado] = useState('');
  const [txtData, setTxtData] = useState(format(new Date(), 'yyyy-MM-dd'));

  const { addToast } = useToast();

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.ATENDENTE_CAIXA) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const ListarExames = useCallback(
    async (botao: string): Promise<void> => {
      const textoBotao = document.getElementById(botao)?.textContent;
      let requisicao = '/exame/ExamesEnviados';

      if (textoBotao) {
        setBotaoClicado(botao);

        if (textoBotao.trim() === 'Buscar') {
          // const teste: HTMLInputElement = document.getElementById('HidClinica');
          const teste = '1';
          if (!teste) {
            addToast({
              type: 'error',
              title: 'Digite uma data.',
            });

            return;
          }

          requisicao = `/exame/ExamesEnviados?dataFilter=${txtData}`;
        }
      }
      try {
        setLoading(true);
        const response = await api.get<IExame[]>(requisicao);
        setExames(response.data);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível buscar exames solicitados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, txtData],
  );

  return (
    <>
      <Container fluid className="mt-2">
        <header className="py-2">
          <h4 className="text-secondary pl-4"> Exames solicitados </h4>
        </header>
        <br className="clearfix" />
        <div className="bg-white p-3 border rounded">
          <p className="text-secondary mb-0">
            Consulte status dos exames solicitados
          </p>
          <br className="clearfix" />
          <form id="notaForm">
            <div className="row small">
              <div className="col-md-3 form-group">
                <label className="mr-1" htmlFor="TxtData">
                  <b> Data </b>
                </label>
                <input
                  name="TxtData"
                  id="TxtData"
                  type="date"
                  className="form-control form-control-sm"
                  onChange={e => setTxtData(e.target.value)}
                  value={txtData}
                />
              </div>
              <div className="col-md-2">
                <br />
                <button
                  type="button"
                  name="CmdBuscar"
                  id="CmdBuscar"
                  className="btn btn-sm btn-primary mt-md-2"
                  onClick={() => ListarExames('CmdBuscar')}
                  disabled={loading}
                >
                  {loading && botaoClicado === 'CmdBuscar'
                    ? 'Carregando'
                    : 'Buscar'}
                </button>
              </div>
            </div>
          </form>
          <span id="LblRespGrid" />
          <div>
            <Table striped bordered hover size="sm" id="GrdConsulta">
              <tbody>
                <tr className="bg-light">
                  <th> Paciente </th> <th> Nº OS </th> <th> Data </th>
                  <th> Status </th>
                  <th> - </th>
                </tr>
                {exames.map(ex => {
                  return <ItensExame key={`item-${ex.id}`} item={ex} />;
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ExamesSolicitados;
