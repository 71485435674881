/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addHours, format, isSameDay, isToday, parseISO } from 'date-fns';
import * as Yup from 'yup';

import { Button, Row, Col } from 'react-bootstrap';

import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useCategoria } from '../../hooks/categoria';

import SelectSync, { IOption } from '../SelectSync';
import Input from '../Input';
import InputMoney from '../InputMoney';
import InputFake from '../InputFake';
import Excluir from './Excluir';
import Estorno from './Estorno';
import PacienteCabecalho from '../PacienteCabecalho';

import {
  formatPrice,
  ORIGEM_PAGAMENTO,
  STATUS_AGENDA,
  TIPO_USUARIO,
} from '../../utils/funcoes';

import { Container } from './styles';
import api from '../../services/api';

import Modal from '../Modal';
import Desconto, { IDesconto } from './Desconto';
import Loading from '../Loading';
import CancelaBaixa from './CancelaBaixa';
import FormaPagamento from './FormaPagamento';
import { IAgendamentosPaciente, IPaciente } from '../Agendamento';
import getResponseErrors from '../../utils/getResponseErrors';
// import ControlesNotaFiscal from '../ControlesNotaFiscal';

interface IAgendamentoPaciente extends IAgendamentosPaciente {
  convenio_id: number;
}
export interface IPagamento {
  id?: number;
  contapr_id?: number;
  formaPagamento_id: number;
  data: Date;
  valor: number;
  excluido?: boolean;
  conferido?: boolean;
  usuarioPagamento?: {
    id: number;
    nome: string;
  };
  usuarioCancelamento?: {
    id: number;
    nome: string;
  };
  cancelamento_usuario_id?: number;
  // PagCanMotCodigo?: number; revisar
  // PagCanMotivo?: string;
  formaDePagamento?: IFormaPagamento;
  valorFormatado?: string;
}

type PagamentoProps = {
  cprCodigo?: number;
  tipo: string;
  pacCodigo?: number;
  handleFechar(load: boolean): void;
};
export interface IContaPR {
  id?: number;
  origem_id: number;
  origem?: number;
  categoria_id: number;
  dataVencimento: Date;
  valor: number;
  parcela: number;
  formaPagamento_id: number;
  dataPagamento?: Date;
  receita: number;
  pago: boolean;
  excluido: boolean;
  descricao: string;
  valorPago?: number;
  conferido?: boolean;
  clinica_id: number;
  ativo?: boolean;
  usuario_id: number;
  pagamento?: IPagamento[];
  motivo_usuario_id?: number;
  motivo_id?: number;
  descricaodesconto?: string;
  created_at?: Date;
  updated_at?: Date;
  DataAtualizacaoAgenda?: string;
  usuarioPagamento?: IUsuarioPagamento;
  // pagamentoAgenda?: IAgenda;
}
interface IUsuarioPagamento {
  id: number;
  email: string;
  nome: string;
  senha: string;
}
export interface IFormaPagamento {
  id: number;
  nome: string;
  movimento?: boolean;
  ativo?: boolean;
}

interface IFormProps extends FormHandles {
  descricao: string;
  categoria_id: string;
  parcela: string;
  CprValor: number;
  dataVencimento: Date;
  formaPagamento_id: number;
  valorPago: number;
  dataPagamento: Date;
  ChkPago: boolean;
}

const formaPagamentoPadrao = 1;

const Pagamento: React.FC<PagamentoProps> = ({
  cprCodigo,
  tipo,
  pacCodigo,
  handleFechar,
}) => {
  const { user } = useAuth();
  const { optionCategorias, buscaCategoria } = useCategoria();

  const [contaPRSelecionada, setContaPRSelecionada] = useState<
    IContaPR | undefined
  >(undefined);

  const [pagamentosRealizados, setPagamentosRealizados] = useState<
    IPagamento[]
  >([]);

  const [pagamentosAlterados, setPagamentosAlterados] = useState<IPagamento[]>(
    [],
  );
  const [paciente, setPaciente] = useState<IPaciente | undefined>(undefined);
  const [agendamento, setAgendamento] = useState<
    IAgendamentoPaciente | undefined
  >(undefined);

  const [dadosBaixa, setDadosBaixa] = useState<
    { nome?: string; data?: string; alteracao?: string } | undefined
  >(undefined);

  const [descontoAplicado, setDescontoAplicado] = useState<IDesconto>({
    UsuCodigo: 0,
    UsuNome: '',
    descricao: '',
    login: '',
    motivo: 0,
    valor: '0',
  });

  const [loading, setLoading] = useState(false);
  const [mensagemPermissaoAdmin, setMensagemPermissaoAdmin] = useState('');
  const [nomeCategoria, setNomeCategoria] = useState('');
  const [showModalExcluir, setShowModalExcluir] = useState(false);
  const [showModalDesconto, setShowModalDesconto] = useState(false);
  const [showModalCancelaBaixa, setShowModalCancelaBaixa] = useState(false);
  const [showModalFormaPagamento, setShowModalFormaPagamento] = useState(false);
  const [showModalEstorno, setShowModalEstorno] = useState(false);
  const [ehExame, setEhExame] = useState(false);

  const formRefPagamento = useRef<IFormProps>(null);

  const { addToast } = useToast();

  const [optionsCategoria, setOptionsCategoria] = useState<IOption[]>([]);

  const ehConsulta = useMemo(() => {
    return Number(tipo) === ORIGEM_PAGAMENTO.RECEITA;
  }, [tipo]);

  const ehDespesa = useMemo(() => {
    return Number(tipo) === ORIGEM_PAGAMENTO.DESPESA;
  }, [tipo]);
  const ehTroco = useMemo(() => {
    return Number(tipo) === ORIGEM_PAGAMENTO.TROCO;
  }, [tipo]);
  const ehSangria = useMemo(() => {
    return Number(tipo) === ORIGEM_PAGAMENTO.SANGRIA;
  }, [tipo]);

  const estaConferido = useMemo(() => {
    return contaPRSelecionada?.conferido;
  }, [contaPRSelecionada]);

  const ehNovo = useMemo(() => {
    return !cprCodigo;
  }, [cprCodigo]);

  const medicoAtendePeloSistema = useMemo(() => {
    return agendamento?.agenda?.atende === true;
  }, [agendamento]);

  const pagouHoje = useMemo(() => {
    return (
      contaPRSelecionada?.dataPagamento &&
      isSameDay(
        parseISO(contaPRSelecionada.dataPagamento.toString()),
        new Date(),
      )
    );
  }, [contaPRSelecionada?.dataPagamento]);

  const ehConsultaDoDia = useMemo(() => {
    return !agendamento || isToday(parseISO(agendamento.inicio.toString()));
  }, [agendamento]);

  useEffect(() => {
    if (!optionCategorias || Object.keys(optionCategorias).length === 0)
      buscaCategoria();
  }, [buscaCategoria, optionCategorias]);

  useEffect(() => {
    async function loadOptionsCategoria(): Promise<void> {
      try {
        if (Number(tipo) === ORIGEM_PAGAMENTO.DESPESA) {
          setOptionsCategoria(optionCategorias.despesa);
          return;
        }
        setOptionsCategoria(optionCategorias.receita);
      } catch (error) {
        handleFechar(false);
        addToast({
          title: 'Não foi possível buscar categorias',
          type: 'error',
        });
      }
    }
    if (ehDespesa) {
      loadOptionsCategoria();
    }
    if (ehConsulta) {
      loadOptionsCategoria();
    }
    const opts: IOption[] = [];
    if (ehSangria) {
      opts.push({
        label: 'Sangria',
        value: ORIGEM_PAGAMENTO.SANGRIA.toString(),
      });
      setOptionsCategoria(opts);
      setNomeCategoria('Sangria');
    }
    if (ehTroco) {
      opts.push({ label: 'Troco', value: ORIGEM_PAGAMENTO.TROCO.toString() });
      setOptionsCategoria(opts);
      setNomeCategoria('Troco');
    }
  }, [
    tipo,
    addToast,
    ehSangria,
    ehTroco,
    ehDespesa,
    ehConsulta,
    handleFechar,
    optionCategorias,
  ]);

  useEffect(() => {
    if (ehNovo) {
      const parcelaVencimento = {
        parcela: '1',
        dataVencimento: format(new Date(), 'yyyy-MM-dd'),
        formaPagamento_id: formaPagamentoPadrao.toString(),
        ChkPago: true,
      };
      if (ehSangria) {
        formRefPagamento.current?.setData({
          descricao: `Sangria - ${user.nome}`.toUpperCase(),
          categoria_id: ORIGEM_PAGAMENTO.SANGRIA.toString(),
          ...parcelaVencimento,
        });
        return;
      }
      if (ehTroco) {
        formRefPagamento.current?.setData({
          descricao: `Troco - ${user.nome}`.toUpperCase(),
          categoria_id: ORIGEM_PAGAMENTO.TROCO.toString(),
          ...parcelaVencimento,
        });
        return;
      }
      formRefPagamento.current?.setData({
        categoria_id: ORIGEM_PAGAMENTO.DESPESA.toString(),
        ...parcelaVencimento,
      });
    }
  }, [user.nome, ehSangria, ehTroco, ehNovo]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);

      let contaPrSelecionada: any;
      try {
        if (Number(tipo) === ORIGEM_PAGAMENTO.RECEITA) {
          const response = await api.get(
            `/agendamentos/${cprCodigo}/pagamento`,
          );

          setEhExame(false);

          contaPrSelecionada = response.data.pagamento;
          setPaciente({
            ...response.data.cliente,
            avatar_url: '',
            // avatar_url: response.data.paciente?.avatar_url
            //   ? response.data.paciente.avatar_url
            //   : '',
          });
          setAgendamento(response.data);

          setContaPRSelecionada(contaPrSelecionada);
        } else {
          const response = await api.get<IContaPR>(
            `/pagamento/${cprCodigo}?origem_id=${0}`,
          );

          contaPrSelecionada = response.data;
          setContaPRSelecionada(contaPrSelecionada);
        }

        const nome = contaPrSelecionada.usuarioPagamento?.nome;
        const data = contaPrSelecionada.dataPagamento
          ? format(
              parseISO(contaPrSelecionada.dataPagamento.toString()),
              'dd/MM/yyyy HH:mm',
            )
          : undefined;

        const alteracao = contaPrSelecionada.updated_at
          ? format(
              addHours(parseISO(contaPrSelecionada.updated_at.toString()), -3),
              'dd/MM/yyyy HH:mm',
            )
          : undefined;

        setDadosBaixa({ nome, data, alteracao });
        const pagamentos: IPagamento[] = contaPrSelecionada.pagamento
          ?.filter((pag: IPagamento) => pag.excluido === false)
          .map((pag: IPagamento) => {
            return {
              ...pag,
              valorFormatado: formatPrice(pag.valor),
            };
          });

        if (pagamentos && pagamentos.length > 0)
          setPagamentosRealizados(pagamentos);
        const categ = optionsCategoria.find(
          cat => cat.value === contaPrSelecionada.categoria_id.toString(),
        );

        if (categ) setNomeCategoria(categ.label);
        formRefPagamento.current?.setData({
          ...contaPrSelecionada,
          categoria_id: contaPrSelecionada.categoria_id.toString(),
          formaPagamento_id: contaPrSelecionada.formaPagamento_id.toString(),
          dataVencimento: format(
            parseISO(contaPrSelecionada.dataVencimento.toString()),
            'yyyy-MM-dd',
          ),
          dataPagamento: contaPrSelecionada.dataPagamento
            ? format(
                parseISO(contaPrSelecionada.dataPagamento.toString()),
                'yyyy-MM-dd',
              )
            : format(new Date(), 'yyyy-MM-dd'),
        });

        if (contaPrSelecionada.pago) {
          const desconto =
            contaPrSelecionada.CprValor - (contaPrSelecionada.valorPago || 0);
          if (desconto > 0)
            setDescontoAplicado({
              valor: desconto.toString(),
              UsuCodigo: Number(contaPrSelecionada.motivo_usuario_id || 0),
              UsuNome: '',
              descricao: contaPrSelecionada.descricaodesconto || '',
              login: '',
              motivo: Number(contaPrSelecionada.motivo_id || 0),
            });
        }
      } catch (error) {
        handleFechar(false);
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar registro de pagamento',
          type: 'error',
          description: mensagemErro,
        });
      }
      setLoading(false);
    }
    if (!ehNovo && optionsCategoria && optionsCategoria.length > 0) loadDados();
  }, [
    ehNovo,
    addToast,
    cprCodigo,
    optionsCategoria,
    setLoading,
    handleFechar,
    tipo,
    pacCodigo,
  ]);

  const estaPago = useMemo(() => {
    return contaPRSelecionada?.pago;
  }, [contaPRSelecionada?.pago]);

  const totalDesconto = useMemo(() => {
    return {
      valor: Number(descontoAplicado.valor),
      valorFormatado: formatPrice(-1 * Number(descontoAplicado.valor)),
    };
  }, [descontoAplicado.valor]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const validaDados = useCallback(async (dadosForm): Promise<boolean> => {
    try {
      formRefPagamento.current?.setErrors({});

      const schemaPadrao = Yup.object().shape({
        categoria_id: Yup.number()
          .min(1, 'Selecione uma Categoria')
          .typeError('Selecione uma categoria')
          .required('Categoria requerido'),
        dataVencimento: Yup.date().required(),
        valorPago: Yup.number()
          .typeError('Valor incorreto')
          .required('Valor requerido'),
        valor: Yup.number()
          .typeError('digite um número válido')
          .required('Valor requerido'),
        parcela: Yup.number()
          .typeError('Número de parcela')
          .required('Valor requerido'),
        descricao: Yup.string().required(),
      });

      await schemaPadrao.validate(dadosForm, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefPagamento.current?.setErrors(errors);
      }
      return false;
    }
  }, []);

  const total = useMemo(() => {
    const valor = pagamentosRealizados.reduce((tot, pag) => {
      return tot + Number(pag.valor);
    }, 0);
    return {
      valor,
      valorFormatado: formatPrice(valor),
    };
  }, [pagamentosRealizados]);

  const pegaValor = useCallback((): number | undefined => {
    return formRefPagamento.current?.getFieldValue('valor');
  }, []);

  const handleSalvar = useCallback(async () => {
    if (formRefPagamento.current) {
      const {
        categoria_id,
        dataVencimento,
        valor,
        parcela,
        descricao,
      } = formRefPagamento.current.getData();

      const dataHoraVenc = parseISO(
        `${dataVencimento} ${format(new Date(), 'HH:mm')}`,
      );

      let formaPagamento_id = formaPagamentoPadrao;
      const dataPagamento = new Date();
      let valorPago = valor;

      // let dataBaixa: Date | undefined = new Date();

      let pags = [
        {
          data: dataHoraVenc,
          formaPagamento_id,
          valor,
        },
      ];

      if (pagamentosRealizados.length > 0) {
        formaPagamento_id = pagamentosRealizados[0].formaPagamento_id;
        pags = pagamentosRealizados.map(pag => {
          return {
            data: new Date(),
            formaPagamento_id: pag.formaPagamento_id,
            valor: pag.valor,
          };
        });
      } else if (ehDespesa) {
        valorPago = 0;
        //  dataBaixa = undefined;
        pags = [];
      }

      const dadosForm: Omit<
        IContaPR,
        | 'clinica_id'
        | 'origem_id'
        | 'conferido'
        | 'receita'
        | 'pago'
        | 'usuario_id'
        | 'excluido'
        | 'origem'
      > = {
        categoria_id: Number(categoria_id),
        formaPagamento_id,
        dataVencimento: dataHoraVenc,
        valorPago,
        valor,
        parcela,
        descricao: descricao.toUpperCase(),
        dataPagamento,
      };

      if (ehTroco) dadosForm.categoria_id = ORIGEM_PAGAMENTO.TROCO;
      if (ehSangria) dadosForm.categoria_id = ORIGEM_PAGAMENTO.SANGRIA;

      const validado = await validaDados(dadosForm);

      if (!validado) {
        return;
      }

      setLoading(true);
      const camposOriginais = {
        origem_id: 0,
        pago:
          ehSangria ||
          ehTroco ||
          (ehDespesa && pagamentosRealizados.length > 0),
        dataPagamento:
          ehDespesa && pagamentosRealizados.length === 0
            ? undefined
            : dadosForm.dataPagamento,
        pagamento: pags,
      };

      const contaPR: IContaPR = {
        clinica_id: user.clinica_id,
        conferido: false,
        receita: ehConsulta ? 1 : Number(tipo),
        usuario_id: user.id,
        excluido: false,
        origem: Number(tipo),
        ...dadosForm,
        dataVencimento: dataHoraVenc,
        ...camposOriginais,
      };

      try {
        await api.post(`/pagamento/incluir`, contaPR);
        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
        });
        handleFechar(true);
      } catch (error: any) {
        const mensagemErro = getResponseErrors(error);
        const statusErro = error?.response?.status;
        if (statusErro === 403) {
          // setExibePermissaoAdmin(true);
          setMensagemPermissaoAdmin(mensagemErro);
        }
        addToast({
          type: 'error',
          title: 'Erro ao salvar pagamento',
          description: mensagemErro,
        });
      }
      setLoading(false);
    }
  }, [
    user.clinica_id,
    user.id,
    addToast,
    ehConsulta,
    validaDados,
    tipo,
    handleFechar,
    setLoading,
    ehSangria,
    ehTroco,
    ehDespesa,
    pagamentosRealizados,
  ]);

  const handleLiberarRecepcao = useCallback(async () => {
    if (!contaPRSelecionada) return;
    setLoading(true);
    try {
      await api.delete(`/liberaragendamento/${contaPRSelecionada.id}`, {
        data: {
          origem_id: contaPRSelecionada.origem_id,
          usuario_nome: user.nome,
          dataAtualizacaoAgenda: contaPRSelecionada.DataAtualizacaoAgenda || '',
        },
      });
      addToast({
        type: 'success',
        title: 'Agendamento liberado com sucesso',
      });
      handleFechar(true);
    } catch (error: any) {
      const mensagemErro = getResponseErrors(error);
      const statusErro = error?.response?.status;
      if (statusErro === 403) {
        // setExibePermissaoAdmin(true);
        setMensagemPermissaoAdmin(mensagemErro);
      }
      addToast({
        type: 'error',
        title: 'Erro ao liberar pra recepção',
        description: mensagemErro,
      });
    }
    setLoading(false);
  }, [contaPRSelecionada, addToast, setLoading, handleFechar, user.nome]);

  const handleCancelarAgendamento = useCallback(async () => {
    if (!contaPRSelecionada) return;
    setLoading(true);
    try {
      await api.delete(`/cancelaragenda/${contaPRSelecionada.id}`, {
        data: {
          origem_id: contaPRSelecionada.origem_id,
          PagCanUsuNome: user.nome,
          DataAtualizacaoAgenda: contaPRSelecionada.DataAtualizacaoAgenda || '',
        },
      });
      addToast({
        type: 'success',
        title: 'Agenda cancelada com sucesso',
      });
      handleFechar(true);
    } catch (error: any) {
      const mensagemErro = getResponseErrors(error);
      const statusErro = error?.response?.status;
      if (statusErro === 403) {
        // setExibePermissaoAdmin(true);
        setMensagemPermissaoAdmin(mensagemErro);
      }
      addToast({
        type: 'error',
        title: 'Erro ao cancelar agenda',
        description: mensagemErro,
      });
    }
    setLoading(false);
  }, [contaPRSelecionada, addToast, setLoading, handleFechar, user.nome]);

  const statusAgenda = useMemo(() => {
    if (!ehConsultaDoDia) return STATUS_AGENDA.PAGAMENTO_ANTECIPADO;
    if (!medicoAtendePeloSistema) return STATUS_AGENDA.ATENDIDO;
    if (medicoAtendePeloSistema) return STATUS_AGENDA.AGUARDANDO_ATENDIMENTO;
    return STATUS_AGENDA.CANCELADO;
  }, [medicoAtendePeloSistema, ehConsultaDoDia]);

  const valoresAdicionados = useCallback(
    (valor: number) => {
      if (
        parseFloat((total.valor + totalDesconto.valor).toFixed(2)) !== valor
      ) {
        addToast({
          title: 'Confira os valores',
          description:
            'O total de valores informados são diferentes do total da parcela.',
          type: 'error',
        });
        return false;
      }
      return true;
    },
    [addToast, total.valor, totalDesconto.valor],
  );
  const handleConfirmaPagamentoAtendimento = useCallback(async () => {
    if (!contaPRSelecionada) {
      addToast({
        type: 'error',
        title: 'Nenhum registro selecionado',
      });
      return;
    }

    if (formRefPagamento.current) {
      const {
        categoria_id,
        formaPagamento_id,
        dataVencimento,
        valor,
        parcela,
        descricao,
        dataPagamento,
      } = formRefPagamento.current.getData();

      const totalBateu = valoresAdicionados(Number(valor));

      if (!totalBateu) return;

      setLoading(true);

      const dataHoraVenc = parseISO(
        `${dataVencimento} ${format(new Date(), 'HH:mm')}`,
      );

      const dadosForm: Omit<
        IContaPR,
        | 'clinica_id'
        | 'origem_id'
        | 'conferido'
        | 'receita'
        | 'pago'
        | 'usuario_id'
        | 'excluido'
        | 'origem'
      > = {
        categoria_id: Number(categoria_id),
        formaPagamento_id,
        dataVencimento,
        valorPago: total.valor,
        valor,
        parcela,
        descricao: descricao.toUpperCase(),
        dataPagamento,
      };

      const validado = await validaDados(dadosForm);

      if (!validado) {
        setLoading(false);
        return;
      }

      const desconto =
        descontoAplicado.valor !== '0' && descontoAplicado.valor !== ''
          ? {
              motivo_usuario_id: descontoAplicado.UsuCodigo,
              motivo_id: descontoAplicado.motivo,
              descricaodesconto: descontoAplicado.descricao.toUpperCase(),
              descricao: `${dadosForm.descricao.toUpperCase()}`,
            }
          : {};
      // descricao: `${
      //   dadosForm.descricao
      // } Desconto de ${formatPrice(
      //   Number(descontoAplicado.valor),
      // )} liberado pelo usuário ${descontoAplicado.UsuNome}`,

      const pagamentos = pagamentosRealizados.map(pag => {
        return {
          data: new Date(),
          formaPagamento_id: pag.formaPagamento_id,
          valor: pag.valor,
        };
      });

      const contaPR: IContaPR = {
        ...contaPRSelecionada,
        origem_id: contaPRSelecionada?.origem_id || 0,
        dataVencimento: contaPRSelecionada?.dataVencimento || dataHoraVenc,
        receita: contaPRSelecionada?.receita === 1 ? 1 : 0,
        clinica_id: user.clinica_id,
        conferido: false,
        usuario_id: user.id,
        origem: Number(tipo),
        formaPagamento_id:
          pagamentos[0]?.formaPagamento_id || formaPagamentoPadrao,
        valorPago: total.valor,
        descricao: descricao.toUpperCase(),
        valor,
        categoria_id,
        parcela,

        excluido: false,
        pago: true,
        dataPagamento: new Date(),
        pagamento:
          pagamentos.length > 0
            ? pagamentos
            : [
                {
                  data: new Date(),
                  formaPagamento_id: formaPagamentoPadrao,
                  valor: 0,
                },
              ],
        ...desconto,
        DataAtualizacaoAgenda: ehDespesa
          ? ''
          : contaPRSelecionada.DataAtualizacaoAgenda || '',
      };

      // delete contaPR.pagamentoAgenda;
      // delete contaPR.dataBaixa;
      delete contaPR.updated_at;
      delete contaPR.usuarioPagamento;
      // delete contaPR.origem;

      const HisLog = ` <b>Usuário:</b> ${user.nome} <br />Pagamento${
        statusAgenda === STATUS_AGENDA.PAGAMENTO_ANTECIPADO ? 'Antecipado' : ''
      }  realizado, encaminhado para exame.<br> ${descricao}`;

      const dados = {
        id: contaPR.id,
        dataPagamento: contaPR.dataPagamento,
        formaPagamento_id: contaPR.formaPagamento_id,
        pago: contaPR.pago,
        valorPago: contaPR.valorPago,
        usuario_id: contaPR.usuario_id,
        motivo_id: contaPR.motivo_id,
        descricaoDesconto: contaPR.descricaodesconto,
        motivo_usuario_id: contaPR.motivo_usuario_id,
        pagamento: contaPR.pagamento,
        statusAgenda: ehDespesa ? undefined : statusAgenda,
        log: ehDespesa ? undefined : HisLog,
        profissional_id:
          statusAgenda === STATUS_AGENDA.ATENDIDO && !medicoAtendePeloSistema
            ? agendamento?.profissional_id
            : undefined,
      };

      try {
        await api.post(
          `/caixa/confirmaPagamento/${contaPRSelecionada.id}`,
          dados,
        );

        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
        });
        handleFechar(true);
      } catch (error: any) {
        const mensagemErro = getResponseErrors(error);
        const statusErro = error?.response?.status;
        if (statusErro === 403) {
          // setExibePermissaoAdmin(true);
          setMensagemPermissaoAdmin(mensagemErro);
        }
        addToast({
          type: 'error',
          title: 'Erro ao salvar pagamento',
          description: mensagemErro,
        });
      }
      setLoading(false);
    }
  }, [
    contaPRSelecionada,
    addToast,
    valoresAdicionados,
    total.valor,
    validaDados,
    descontoAplicado.valor,
    descontoAplicado.UsuCodigo,
    descontoAplicado.motivo,
    descontoAplicado.descricao,
    pagamentosRealizados,
    user.clinica_id,
    user.id,
    user.nome,
    tipo,
    ehDespesa,
    statusAgenda,
    medicoAtendePeloSistema,
    agendamento?.profissional_id,
    handleFechar,
  ]);

  const handleSalvaDesconto = useCallback(data => {
    setDescontoAplicado(data);
    setShowModalDesconto(false);
  }, []);

  const handleCancelarBaixa = useCallback(async () => {
    if (estaConferido) {
      addToast({
        type: 'error',
        title: 'Este pagamento já foi conferido.',
      });
      return;
    }
    setShowModalCancelaBaixa(true);
  }, [estaConferido, addToast]);

  return (
    <>
      {showModalExcluir && (
        <Modal
          show={showModalExcluir}
          titulo="Excluir"
          handleFecharModal={() => setShowModalExcluir(false)}
          large={false}
        >
          {cprCodigo && (
            <Excluir
              cprCodigo={cprCodigo}
              handleFechar={() => handleFechar(true)}
            />
          )}
        </Modal>
      )}
      {showModalCancelaBaixa && (
        <Modal
          show={showModalCancelaBaixa}
          titulo="Cancelar Baixa"
          handleFecharModal={() => setShowModalCancelaBaixa(false)}
          large={false}
        >
          {cprCodigo && !ehTroco && !ehSangria && (
            <CancelaBaixa
              cprCodigo={cprCodigo}
              dataAtualizacaoAgenda={
                contaPRSelecionada?.DataAtualizacaoAgenda || ''
              }
              ehDespesa={ehDespesa}
              handleFechar={() => handleFechar(true)}
            />
          )}
        </Modal>
      )}
      {showModalEstorno && (
        <Modal
          show={showModalEstorno}
          titulo="Estorno"
          handleFecharModal={() => setShowModalEstorno(false)}
          large={false}
        >
          {cprCodigo && contaPRSelecionada?.DataAtualizacaoAgenda && (
            <Estorno
              cprCodigo={cprCodigo}
              dataAtualizacaoAgenda={contaPRSelecionada.DataAtualizacaoAgenda}
              handleFechar={() => handleFechar(true)}
            />
          )}
        </Modal>
      )}
      {showModalDesconto && (
        <Modal
          show={showModalDesconto}
          titulo="Desconto"
          handleFecharModal={() => setShowModalDesconto(false)}
          large={false}
        >
          {cprCodigo && (
            <Desconto
              valorParcela={contaPRSelecionada ? contaPRSelecionada.valor : 0}
              handleFechar={() => setShowModalDesconto(false)}
              handleSalvar={handleSalvaDesconto}
              desconto={descontoAplicado}
            />
          )}
        </Modal>
      )}

      {showModalFormaPagamento && (
        <Modal
          show={showModalFormaPagamento}
          titulo="Alterar Forma de Pagamento"
          handleFecharModal={() => setShowModalFormaPagamento(false)}
          large
        >
          {cprCodigo && (
            <FormaPagamento
              valor={contaPRSelecionada?.valor || 0}
              id={contaPRSelecionada?.id || 0}
              ehSangria={false}
              ehTroco={false}
              estaPago={false}
              ehAlteracao
              ehDespesa={ehDespesa}
              descontoAplicado={descontoAplicado}
              pagamentosRealizados={pagamentosAlterados}
              handleAddedPagamento={setPagamentosAlterados}
              handleAddedDesconto={setDescontoAplicado}
              handleFechar={() => {
                setShowModalFormaPagamento(false);
                handleFechar(true);
                setPagamentosAlterados([]);
              }}
              pegaValor={pegaValor}
            />
          )}
        </Modal>
      )}
      <Container>
        {loading && <Loading backgroundBlack={false} />}

        <div className={`${ehSangria || ehDespesa || ehTroco ? 'd-none' : ''}`}>
          <Row
            className={`no-gutters ${
              estaPago && !medicoAtendePeloSistema && ehConsultaDoDia
                ? ''
                : 'd-none'
            }`}
          >
            <Col md="8" lg="8" className="pr-2">
              &nbsp;
            </Col>
            {/* revisar */}
            <Col
              md="4"
              lg="4"
              className="d-none justify-content-between align-items-center p-1 pr-2"
            >
              <a
                key="prontuario1"
                href={`impressaodocumento/${contaPRSelecionada?.origem_id}/prontuarioimpresso`}
                target="_blank"
                className="btn btn-sm btn-success"
                rel="noreferrer"
              >
                Prontuario
              </a>
              <a
                key="prontuario2"
                href={`impressaodocumento/${contaPRSelecionada?.origem_id}/pedidoexame`}
                target="_blank"
                className="btn btn-sm btn-success"
                rel="noreferrer"
              >
                Pedido Exame
              </a>
            </Col>
          </Row>
          <Row noGutters>
            <Col
              md="12"
              lg="12"
              className={`pr-2 ${agendamento ? '' : 'd-none'} mb-2`}
            >
              {paciente && (
                <PacienteCabecalho
                  paciente={paciente}
                  pacienteID={pacCodigo}
                  exibeEditar
                />
              )}
            </Col>
          </Row>
          <Row
            noGutters
            className={`${
              agendamento?.obs && agendamento?.obs !== '' ? '' : 'd-none'
            }`}
          >
            <Col md="12" lg="12" className="py-2">
              <div id="LitObservacao">
                <b>Observação:</b> {agendamento?.obs}{' '}
              </div>
            </Col>
          </Row>
        </div>
        <Form
          ref={formRefPagamento}
          // initialData={{ valorPago: '' }}
          onSubmit={handleOnChangeVazio}
        >
          <Row noGutters>
            <Col md="12" lg="12" className="form-group pr-2">
              <Input name="descricao" label="Histórico" disabled={!ehDespesa} />
            </Col>
          </Row>
          <Row noGutters>
            <Col
              md="4"
              lg="4"
              style={{ zIndex: 4 }}
              className={`form-group pr-2 ${
                ehDespesa && ehNovo ? '' : 'd-none'
              }`}
            >
              <SelectSync
                name="categoria_id"
                label="Categoria"
                placeholder="Selecione"
                isClearable={false}
                handleSelectChange={handleOnChangeVazio}
                options={optionsCategoria || { label: '', value: '' }}
              />
            </Col>
            <Col
              md="4"
              lg="4"
              className={`form-group pr-2 ${
                ehNovo && !ehTroco && !ehSangria ? 'd-none' : ''
              }`}
            >
              <InputFake label="Categoria" disabled value={nomeCategoria} />
            </Col>
            <Col md="2" lg="2" className="form-group pr-2">
              <Input name="parcela" label="Parcela" disabled />
            </Col>
            <Col md="3" lg="3" className="form-group pr-2">
              <InputMoney
                label="Valor"
                name="valor"
                autoComplete="nope"
                placeholder="Valor"
                disabled={!ehNovo}
              />
            </Col>
            <Col md="3" lg="3" className="form-group pr-2">
              <Input
                label="Vencimento"
                name="dataVencimento"
                type="date"
                disabled={!(ehDespesa && !estaPago)}
              />
            </Col>
          </Row>
        </Form>
        <Row className={`no-gutters ${ehSangria || ehTroco ? 'd-none' : ''}`}>
          <Col md="12" lg="12" className="form-group pr-2">
            {!ehSangria && !ehTroco && (
              <FormaPagamento
                valor={contaPRSelecionada?.valor || 0}
                id={contaPRSelecionada?.id || 0}
                dadosBaixa={dadosBaixa}
                ehSangria={ehSangria}
                estaPago={estaPago || false}
                ehTroco={ehTroco}
                ehDespesa={ehDespesa}
                handleAddedPagamento={setPagamentosRealizados}
                handleAddedDesconto={setDescontoAplicado}
                pagamentosRealizados={pagamentosRealizados}
                descontoAplicado={descontoAplicado}
                handleFechar={() => setShowModalFormaPagamento(false)}
                pegaValor={pegaValor}
                EhConvenio={agendamento && agendamento.convenio_id > 1}
              />
            )}
          </Col>
        </Row>

        <Row className="align-items-center border-top p-1">
          <Col md="12">
            <Container
              className={`light-grey text-left pt-3 ${
                user.tipo > TIPO_USUARIO.ATENDENTE_CAIXA ? 'd-none' : ''
              }`}
            >
              <>
                <Button
                  type="button"
                  variant="info"
                  style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                  size="sm"
                  className={ehNovo ? 'btn btn-sm btn-info mx-2' : 'd-none'}
                  onClick={handleSalvar}
                >
                  Salvar
                </Button>

                <Button
                  type="button"
                  variant="primary"
                  style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                  size="sm"
                  className={
                    !ehTroco && !ehSangria && !estaPago && !ehNovo
                      ? 'btn btn-sm btn-primary mx-2'
                      : 'd-none'
                  }
                  onClick={handleConfirmaPagamentoAtendimento}
                >
                  Confirmar Pagamento
                  {/* {`Confirmar ${
                    ehDespesa || !ehConsultaDoDia ? 'Pagamento' : 'Atendimento'
                  }`} */}
                </Button>

                <Button
                  type="button"
                  variant="outline-secondary"
                  style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                  size="sm"
                  className={
                    ehConsulta && !estaPago
                      ? 'btn btn-sm btn-outline-secondary mx-2'
                      : 'd-none'
                  }
                  onClick={handleLiberarRecepcao}
                >
                  Liberar para Recepção
                </Button>
                <Button
                  type="button"
                  variant="outline-danger"
                  style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                  size="sm"
                  className="d-none"
                  // className={
                  // ehConsulta &&
                  // !estaPago &&
                  // (ehConsultaDoDia || user.UsuCodigo === 1)
                  //   ? 'btn btn-sm btn-outline-danger mx-2'
                  //   : 'd-none'
                  // }
                  onClick={handleCancelarAgendamento}
                >
                  Cancelar Agendamento
                </Button>

                <Button
                  type="button"
                  variant="danger"
                  style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                  size="sm"
                  className={
                    !ehTroco &&
                    !ehSangria &&
                    estaPago &&
                    (pagouHoje || user.id === 1) &&
                    agendamento?.status !== STATUS_AGENDA.ESTORNO &&
                    agendamento?.status === STATUS_AGENDA.ATENDIDO &&
                    ehExame
                      ? 'btn btn-sm btn-danger mx-2'
                      : 'd-none'
                  }
                  onClick={handleCancelarBaixa}
                >
                  Cancela Baixa
                </Button>

                <Button
                  type="button"
                  variant="outline-danger"
                  style={{
                    paddingTop: '0.1rem',
                    paddingBottom: '0.1rem',
                    marginRight: '10px',
                  }}
                  size="sm"
                  className={
                    !ehTroco &&
                    !ehSangria &&
                    estaPago &&
                    (pagouHoje || user.id === 1) &&
                    agendamento?.status !== STATUS_AGENDA.ESTORNO
                      ? 'btn btn-sm btn-outline-danger mx-2'
                      : 'd-none'
                  }
                  onClick={() => setShowModalFormaPagamento(true)}
                >
                  Alterar Pagamento
                </Button>

                <Button
                  type="button"
                  variant="danger"
                  style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                  size="sm"
                  className={
                    !ehNovo && !ehConsulta && (pagouHoje || user.id === 1)
                      ? 'btn btn-sm btn-danger mx-2'
                      : 'd-none'
                  }
                  onClick={() => setShowModalExcluir(true)}
                >
                  Excluir
                </Button>

                <Button
                  type="button"
                  variant="outline-danger"
                  style={{
                    paddingTop: '0.1rem',
                    paddingBottom: '0.1rem',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                  size="sm"
                  className={
                    ehConsulta &&
                    estaPago &&
                    agendamento?.status !== STATUS_AGENDA.ESTORNO
                      ? 'btn btn-sm btn-outline-danger mx-2'
                      : 'd-none'
                  }
                  onClick={() => setShowModalEstorno(true)}
                >
                  Estorno Atendimento
                </Button>
              </>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Pagamento;
