import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

import { IOption } from '../components/SelectSync';
import { User } from './auth';
import { useToast } from './toast';
import getResponseErrors from '../utils/getResponseErrors';

type IProfissional = User;

interface ProfissionalContextData {
  loading: boolean;
  optionsProfissional: IOption[];
  buscaOptionsProfissional(): Promise<void>;
  limparProfissional(): void;
}

const ProfissionalContext = createContext<ProfissionalContextData>(
  {} as ProfissionalContextData,
);

const ProfissionalProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const [optionsProfissional, setOptionsProfissional] = useState<IOption[]>(
    () => {
      const optionsProfissionais = localStorage.getItem(
        '@Laboratorio:optionsProfissional',
      );
      if (optionsProfissionais) return JSON.parse(optionsProfissionais);

      return [] as IOption[];
    },
  );

  const buscaOptionsProfissional = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IProfissional[]>(
        `/users/profissionaisativos`,
      );

      const options = response.data.map((op: IProfissional) => ({
        value: op.id.toString(),
        label: op.nome,
      }));

      localStorage.setItem(
        '@Laboratorio:optionsProfissional',
        JSON.stringify(options),
      );

      setOptionsProfissional(options);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar os profissionais',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    async function load(): Promise<void> {
      const tokenLocal = localStorage.getItem('@Laboratorio:token');
      if (tokenLocal) {
        buscaOptionsProfissional;
      }
    }
    load();
  }, [buscaOptionsProfissional]);

  const limparProfissional = useCallback(() => {
    localStorage.removeItem('@Laboratorio:optionsProfissional');
    setOptionsProfissional([]);
  }, []);

  return (
    <ProfissionalContext.Provider
      value={{
        loading,
        buscaOptionsProfissional,
        optionsProfissional,
        limparProfissional,
      }}
    >
      {children}
    </ProfissionalContext.Provider>
  );
};

function useProfissional(): ProfissionalContextData {
  const context = useContext(ProfissionalContext);

  if (!context) {
    throw new Error(
      'useProfissional deve ser usado com o ProfissionalProvider',
    );
  }

  return context;
}

export { ProfissionalProvider, useProfissional };
