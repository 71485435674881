import React, { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';

import { Row, Col } from 'react-bootstrap';

import api from '../../services/api';

import { formatPrice } from '../../utils/funcoes';

import { useToast } from '../../hooks/toast';

import { ContainerDados } from './styles';

import Loading from '../Loading';

import getResponseErrors from '../../utils/getResponseErrors';
// import ControlesNotaFiscal from '../ControlesNotaFiscal';
import { useAuth } from '../../hooks/auth';

interface IContaPR {
  CprCodigo: number;
  CprOrigem: number;
  CprCodOrigem: number;
  CprDataVenc: Date;
  CprDataPag: Date;
  CprDataExibe: string;
  CprHistorico: string;
  CprValor: number;
  CprValorPag: number;
  CprPago: boolean;
  CprPagoFormatado: string;
  CprValorExibe: string;
}

interface ITotalPago {
  totalPagoAgenda: string;
  totalPagoCartao: string;
}

type PacienteProps = {
  pacCodigo: number;
};

const PacientePagamento: React.FC<PacienteProps> = ({ pacCodigo }) => {
  const { addToast } = useToast();
  const { clinica } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pagamentosAgenda, setPagamentosAgenda] = useState<IContaPR[]>();
  const [pagamentosCartao, setPagamentosCartao] = useState<IContaPR[]>();
  const [totalPago, setTotalPago] = useState<ITotalPago>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IContaPR[]>(
          `/pacientes/pagamentos/${pacCodigo}/`,
        );

        const dadosFormatados = response.data.map(rel => {
          return {
            ...rel,
            CprValorExibe: rel.CprPago
              ? formatPrice(rel.CprValorPag)
              : formatPrice(rel.CprValor),
            CprDataExibe: rel.CprPago
              ? format(parseISO(rel.CprDataPag.toString()), 'dd/MM/yyyy')
              : format(parseISO(rel.CprDataVenc.toString()), 'dd/MM/yyyy'),
            CprPagoFormatado: rel.CprPago === true ? 'Sim' : 'Não',
          };
        });

        const pagAgenda = dadosFormatados.filter(pag => pag.CprOrigem === 1);
        const pagCartao = dadosFormatados.filter(pag => pag.CprOrigem === 2);

        const totalPagoAgenda = pagAgenda
          .filter(pag => pag.CprPago)
          .reduce((tot, item) => {
            return tot + Number(item.CprValorPag);
          }, 0);

        const totalPagoCartao = pagCartao
          .filter(pag => pag.CprPago)
          .reduce((tot, item) => {
            return tot + Number(item.CprValorPag);
          }, 0);

        setPagamentosAgenda(pagAgenda);
        setPagamentosCartao(pagCartao);
        setTotalPago({
          totalPagoAgenda: formatPrice(totalPagoAgenda),
          totalPagoCartao: formatPrice(totalPagoCartao),
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (pacCodigo && pacCodigo > 0) {
      loadDados();
    }
  }, [addToast, pacCodigo]);

  return (
    <>
      {loading && <Loading />}
      <ContainerDados className="rounded">
        <Row>
          <Col md={6}>
            <h5>Pagamentos Agenda</h5>
            {pagamentosAgenda && pagamentosAgenda.length > 0 ? (
              <>
                <h6>Total Pago: {totalPago?.totalPagoAgenda}</h6>
                <table
                  className="table table-hover table-sm table-font-small"
                  style={{ borderWidth: '0px' }}
                >
                  <thead>
                    <tr className="bg-light text-dark">
                      <th style={{ width: '85px' }}>Data</th>
                      <th>Histórico</th>
                      <th style={{ width: '85px' }}>Valor</th>
                      <th style={{ width: '76px' }}>Nota Fiscal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagamentosAgenda.map(pag => (
                      <tr
                        key={`agenda-${pag.CprCodigo}`}
                        style={{
                          fontSize: '.7rem',
                          height: '30px',
                        }}
                      >
                        <td>{pag.CprDataExibe}</td>
                        <td>{pag.CprHistorico}</td>
                        <td>{pag.CprValorExibe}</td>
                        <td>
                          {/* {pag.CprPago && (
                            <ControlesNotaFiscal cprCodigo={pag.CprCodigo} />
                          )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>Sem pagamentos</p>
            )}
          </Col>
          <Col md={6}>
            <h5>Pagamentos Cartão Saúde</h5>
            {pagamentosCartao && pagamentosCartao.length > 0 ? (
              <>
                <h6>Total Pago: {totalPago?.totalPagoCartao}</h6>
                <table
                  className="table table-hover table-sm table-font-small"
                  style={{ borderWidth: '0px' }}
                >
                  <thead>
                    <tr className="bg-light text-dark">
                      <th style={{ width: '85px' }}>Data</th>
                      <th>Histórico</th>
                      <th style={{ width: '85px' }}>Pago</th>
                      <th style={{ width: '85px' }}>Valor</th>
                      <th style={{ width: '76px' }}>Nota Fiscal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagamentosCartao.map(pag => (
                      <tr
                        key={`agenda-${pag.CprCodigo}`}
                        style={{
                          fontSize: '.7rem',
                          height: '30px',
                        }}
                      >
                        <td>{pag.CprDataExibe}</td>
                        <td>{pag.CprHistorico}</td>
                        <td>{pag.CprPagoFormatado}</td>
                        <td>{pag.CprValorExibe}</td>
                        <td>
                          {/* {pag.CprPago && clinica.CliEmiteNFCartao && (
                            <ControlesNotaFiscal cprCodigo={pag.CprCodigo} />
                          )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>Sem pagamentos</p>
            )}
          </Col>
        </Row>
      </ContainerDados>
    </>
  );
};

export default PacientePagamento;
