import React, { useRef, useCallback, useState, useMemo } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/toast';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import SelectSync from '../../../components/SelectSync';
import getValidationErrors from '../../../utils/getValidationErrors';
import Toggle from '../../../components/Exame/Toggle';
import { IPerfil } from '../ListaPerfis';
import { optionsTipoPerfil } from '../../../utils/options';

type Props = {
  perfil?: IPerfil;
  addPerfil(dadosPerfil: IPerfil, perCodigo: number | undefined): void;
};

const PerfilCadastro: React.FC<Props> = ({ perfil, addPerfil }) => {
  const { addToast } = useToast();
  const [perCodigo, setPerCodigo] = useState<number | undefined>(
    perfil ? perfil.PerCodigo : undefined,
  );

  const formRefPerfil = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmitPerfil = useCallback(
    async (dados: IPerfil) => {
      try {
        formRefPerfil.current?.setErrors({});
        const schema = Yup.object().shape({
          PerNome: Yup.string()
            .min(3, 'Mínimo 3 caracteres')
            .required('Nome é obrigatório'),
          PerTipo: Yup.number().typeError('Selecione o Tipo').required(),
        });

        const perfilForm = {
          ...dados,
        };
        const ativo = dados.PerAtivo ? dados.PerAtivo.toString() : 'false';

        await schema.validate(perfilForm, {
          abortEarly: false,
        });

        addPerfil(
          {
            ...perfilForm,
            PerAtivo: ativo === 'true',
          },
          perCodigo,
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefPerfil.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar perfil',
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, addPerfil, perCodigo],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <div className="container">
      {loading && <Loading />}

      <Row noGutters>
        <Col md={12}>
          <Form
            ref={formRefPerfil}
            initialData={{
              ...perfil,
              PerTipo: perfil?.PerTipo ? perfil.PerTipo.toString() : '0',
            }}
            onSubmit={handleSubmitPerfil}
          >
            <Row>
              <Col md={12} className="form-group pr-2">
                <Input name="PerNome" label="Nome" maxLength={100} />
              </Col>
            </Row>
            <Row>
              <Col md={8} className="form-group pr-2">
                <SelectSync
                  name="PerTipo"
                  label="Tipo"
                  placeholder="Selecione"
                  handleSelectChange={handleOnChangeVazio}
                  options={optionsTipoPerfil}
                />
              </Col>

              <Col md={4} className="form-group pr-2">
                <Toggle nome="PerAtivo" label="Ativo" isDisabled={false} />
              </Col>
            </Row>
            <Row className="align-items-center border-top p-1">
              <Col md="12">
                <div className="light-grey text-left pt-3">
                  <>
                    <Button type="submit" variant="success" className="mt-2">
                      Salvar dados
                    </Button>
                  </>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default PerfilCadastro;
