import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getResponseErrors from '../../utils/getResponseErrors';
import ItensExame from '../../pages/ExamesSolicitados/ItensExame';
import { IExame } from '../../pages/ExamesSolicitados';

interface props {
  pacCodigo: number;
}

const PacienteExameLaboratorial: React.FC<props> = ({ pacCodigo }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [exames, setExames] = useState<IExame[]>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        setLoading(true);
        const retorno = await api.get<IExame[]>(
          `/exame/cliente/all/${pacCodigo}`,
        );

        setExames(retorno.data);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Erro ao buscar exames do paciente',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    loadDados();
  }, [addToast, pacCodigo]);

  return (
    <>
      {loading && <div>carregando...</div>}
      {!loading && exames && (
        <div className="table-responsive">
          <Table striped bordered hover size="sm" id="GrdConsulta">
            <tbody>
              <tr className="bg-light">
                <th> Paciente </th> <th> Nº OS </th> <th> Data </th>
                <th> Status </th>
                <th> - </th>
              </tr>
              {exames.map(ex => {
                return <ItensExame key={`item-${ex.id}`} item={ex} />;
              })}
            </tbody>
          </Table>

          {!exames && !loading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: '250px' }}
            >
              <h5>Nenhum exame encontrado para o paciente</h5>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PacienteExameLaboratorial;
