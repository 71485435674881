import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container } from 'react-bootstrap';

import MedicosCadastro from './MedicoCadastro';

import Input from '../../components/Input';
import SelectSync from '../../components/SelectSync';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import getValidationErrors from '../../utils/getValidationErrors';

import { optionsConselhoClasse } from '../../utils/options';
import ListaMedicos from './ListaMedicos';
import { TIPO_USUARIO } from '../../utils/funcoes';
import { useAuth } from '../../hooks/auth';

export interface IFormBusca {
  nome?: string;
  conselho?: string;
  numeroConselho?: string;
}

const MedicoEncaminhamento: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const [dadosForm, setDadosForm] = useState<IFormBusca | undefined>(undefined);

  const formRefBusca = useRef<FormHandles>(null);

  const [showModalCadastro, setShowModalCadastro] = useState(false);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        nome: Yup.string(),
        conselho: Yup.string(),
        numeroConselho: Yup.string(),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });
      setDadosForm(dados);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  // const handleAdd = useCallback(
  //   (dadosMedico: IMedico, medCodigo: number | undefined) => {
  //     if (listaRef.current) {
  //       listaRef.current.addMedico(dadosMedico, medCodigo);
  //     }
  //     setShowModalCadastro(false);
  //   },
  //   [],
  // );

  return (
    <Container fluid className="mt-2">
      {showModalCadastro && (
        <Modal
          show={showModalCadastro}
          titulo="Cadastro de Médicos p/Encaminhamentos"
          handleFecharModal={() => setShowModalCadastro(false)}
          large
        >
          <MedicosCadastro setShowModal={setShowModalCadastro} />
        </Modal>
      )}

      <div className="bg-white border p-2 mb-2">
        <div className="d-flex justify-content-between mb-2">
          <h2 id="topoBusca">Médicos</h2>
          <div>
            <Button
              type="button"
              variant="success"
              onClick={() => {
                setShowModalCadastro(true);
              }}
            >
              Novo Médico
            </Button>
          </div>
        </div>

        <Form ref={formRefBusca} onSubmit={handleSubmitBusca}>
          <div className="row">
            <div className="col-md-4 form-group">
              <Input
                name="nome"
                label="Nome"
                placeholder="NOME"
                maxLength={100}
                minLength={3}
              />
            </div>
            <div className="col-md-3">
              <SelectSync
                name="conselho"
                label="Conselho"
                placeholder="Selecione"
                isClearable
                handleSelectChange={handleOnChangeVazio}
                options={optionsConselhoClasse}
              />
            </div>
            <div className="col-md-3 form-group">
              <Input
                name="numeroConselho"
                label="Número Conselho"
                placeholder="NÚMERO CONSELHO"
                maxLength={100}
                minLength={3}
              />
            </div>
            <div className="col-md-2">
              <div className="input-group-append mt-3">
                <Button type="submit" variant="secondary-outline">
                  BUSCAR
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="bg-white border p-2">
        <ListaMedicos dados={dadosForm} />
      </div>
    </Container>
  );
};

export default MedicoEncaminhamento;
