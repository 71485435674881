import React, { useCallback } from 'react';
import { format, parseISO } from 'date-fns';

import ControlesNotaFiscal from '../../../components/ControlesNotaFiscal';
import { INotafiscal } from '../../../hooks/notafiscal';

type ItensNotaProps = {
  Item: INotafiscal;
};

const ItemTabela: React.FC<ItensNotaProps> = ({ Item }) => {
  const {
    contapr_id,
    nome,
    descricao,
    dataPagamento,
    formaPagto,
    situacao,
    numeroNFSe,
    vlrFormatado,
  } = Item;

  const montaSituacao = useCallback((): string => {
    if (situacao === 'Emitida') {
      return 'text-success';
    }
    if (formaPagto.includes('Cart')) {
      return 'text-danger';
    }
    return '';
  }, [formaPagto, situacao]);

  if (Item)
    return (
      <tr key={contapr_id} className={montaSituacao()}>
        <td>{nome}</td>
        <td>{descricao}</td>
        <td style={{ width: '7%', textAlign: 'end' }}>{vlrFormatado}</td>
        <td style={{ width: '8%', textAlign: 'center' }}>
          {format(parseISO(dataPagamento), 'dd/MM/yyyy')}
        </td>
        <td style={{ width: '10%' }}>{formaPagto}</td>
        <td className="align-content-end" style={{ width: '7%' }}>
          {situacao || 'Não gerada'}
        </td>
        <td style={{ width: '4%' }}>{contapr_id || ''}</td>
        <td style={{ width: '7%' }}>{numeroNFSe || ''}</td>
        <td
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: '75px',
          }}
        >
          <ControlesNotaFiscal contapr_id={contapr_id} dadosNota={Item} />
        </td>
      </tr>
    );
  return <span />;
};

export default ItemTabela;
