import React, { useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FaTrashAlt } from 'react-icons/fa';

import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationErrors';
import { formataCPF, formataTelefone } from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';

import { ButtonDelete } from './styles';

import SelectAsync from '../../SelectAsync';
import Skeleton from '../../Skeleton';

interface IResponsavel {
  id: number;
  nome: string;
  cpf: string;
  telefone: string;
  celular: string;
  email: string;
}

type ComponentProps = {
  id: number;
  responsavelCliente?: IResponsavel;
  // handleRetornaDados?(dadosCliente: IResponsavel): void;
};

const Responsavel: React.FC<ComponentProps> = ({
  id,
  responsavelCliente,
  // handleRetornaDados,
}) => {
  const { addToast } = useToast();
  const formRefCliente = useRef<FormHandles>(null);
  const [responsavel, setResponsavel] = useState<IResponsavel | undefined>(
    responsavelCliente
      ? {
          ...responsavelCliente,
          telefone: formataTelefone(responsavelCliente.telefone),
          celular: formataTelefone(responsavelCliente.celular),
          cpf: formataCPF(responsavelCliente.cpf),
        }
      : undefined,
  );
  const [loading, setLoading] = useState(false);

  const handleSubmitCliente = useCallback(
    async ({ value }) => {
      setLoading(true);
      if (!value) return;
      try {
        const response = await api.put<IResponsavel>(
          `/clientes/responsavel/${id}`,
          {
            responsavel_id: Number(value),
          },
        );

        setResponsavel({
          ...response.data,
          telefone: formataTelefone(response.data.telefone),
          celular: formataTelefone(response.data.celular),
          cpf: formataCPF(response.data.cpf),
        });

        addToast({ title: 'Dados atualizados', type: 'success' });
      } catch (error) {
        console.log('error: ', JSON.stringify(error), error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          console.log('erros', errors);
          formRefCliente.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar cliente',
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : '',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, id],
  );

  const handleDeleteResponsavel = useCallback(async () => {
    setLoading(true);

    try {
      await api.delete(`/clientes/responsavel/${id}`);
      setResponsavel(undefined);

      addToast({ title: 'Dados atualizados', type: 'success' });
    } catch (error) {
      console.log('error: ', JSON.stringify(error), error);

      addToast({
        type: 'error',
        title: 'Erro ao apagar dados',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : '',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, id]);

  return (
    <>
      {loading && <Skeleton height="75px" />}
      {responsavel && !loading && (
        <>
          <div className="d-flex w-100 justify-content-end">
            <ButtonDelete type="submit" onClick={handleDeleteResponsavel}>
              <FaTrashAlt size={16} />
            </ButtonDelete>
          </div>

          <div className="border p-2">
            <p className="pb-1">
              <span className="mb-2 d-block">
                <b>{responsavel.nome.toUpperCase()}</b>
              </span>
              CPF: {responsavel.cpf}
              {(responsavel.celular || responsavel.telefone) && (
                <span className="d-block">
                  Telefone: {responsavel.celular} {responsavel.telefone}
                </span>
              )}
            </p>
          </div>
        </>
      )}
      {!responsavel && !loading && (
        <Form ref={formRefCliente} onSubmit={handleSubmitCliente}>
          <SelectAsync
            name="id"
            label="Indicar Responsável legal"
            placeholder="Procurar..."
            urlApi={`clientes/combo?id=${id}&termoBusca=`}
            handleSelectChange={handleSubmitCliente}
          />
        </Form>
      )}
    </>
  );
};

export default Responsavel;
