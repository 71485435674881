import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FiSearch } from 'react-icons/fi';
import { addYears, format, startOfToday } from 'date-fns';
import Input from '../../Input';

interface IFormBusca {
  nome: string;
}

const Busca: React.FC = () => {
  const formRefBusca = useRef<FormHandles>(null);

  const handleSubmitBusca = useCallback((dados: IFormBusca) => {
    const hoje = startOfToday();
    const dataInicioFormatada = format(addYears(hoje, -3), 'yyyy-MM-dd');
    const dataFimFormatada = format(addYears(hoje, 1), 'yyyy-MM-dd');
    window.location.replace(
      `/agendabusca?paciente=${dados.nome}&data=${dataInicioFormatada}&datafim=${dataFimFormatada}`,
    );
  }, []);

  return (
    <Form ref={formRefBusca} onSubmit={handleSubmitBusca}>
      <Input
        name="nome"
        icon={FiSearch}
        containerStyle={{ border: '0' }}
        placeholder="Buscar agendamentos"
        textUppercase={false}
      />
    </Form>
  );
};

export default Busca;
