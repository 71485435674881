import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

import { IOption } from '../components/SelectSync';
import { useToast } from './toast';
import getResponseErrors from '../utils/getResponseErrors';

export interface IFormaPagamento {
  id: number;
  nome: string;
  movimento: boolean;
  ativo: boolean;
}

interface IOptionsFormasPagamento {
  optionFormaPagamento: IOption[];
}
interface IFormasPagamento {
  formasPagamento: IFormaPagamento[];
}

interface FormaPagamentoContextData {
  loading: boolean;
  optionsFormasPagamento: IOptionsFormasPagamento;
  formasPagamento: IFormasPagamento;
  buscaFormaPagamento(): Promise<void>;
  limparFormaPagamento(): void;
}

const FormaPagamentoContext = createContext<FormaPagamentoContextData>(
  {} as FormaPagamentoContextData,
);

const FormaPagamentoProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [formasPagamento, setFormasPagamento] = useState<IFormasPagamento>(
    () => {
      const formaPags = localStorage.getItem('@Laboratorio:formasPagamento');
      if (formaPags) return JSON.parse(formaPags);

      return {} as IFormasPagamento;
    },
  );

  const [
    optionsFormasPagamento,
    setOptionsFormasPagamento,
  ] = useState<IOptionsFormasPagamento>(() => {
    const optionFormaPags = localStorage.getItem(
      '@Laboratorio:optionsFormasPagamento',
    );
    if (optionFormaPags) return JSON.parse(optionFormaPags);

    return {} as IOptionsFormasPagamento;
  });

  const buscaFormaPagamento = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IFormaPagamento[]>(
        `/formapagamento?ativo=true`,
      );

      const options = response.data.map((op: IFormaPagamento) => ({
        value: op.id.toString(),
        label: op.nome,
      }));

      const localformasPagamento: IFormasPagamento = {
        formasPagamento: response.data,
      };

      const localOptions: IOptionsFormasPagamento = {
        optionFormaPagamento: options,
      };

      localStorage.setItem(
        '@Laboratorio:formasPagamento',
        JSON.stringify(localformasPagamento),
      );
      localStorage.setItem(
        '@Laboratorio:optionsFormasPagamento',
        JSON.stringify(localOptions),
      );
      setFormasPagamento(localformasPagamento);

      setOptionsFormasPagamento(localOptions);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar formas de pagamento',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    async function load(): Promise<void> {
      const tokenLocal = localStorage.getItem('@Laboratorio:token');
      if (tokenLocal) {
        buscaFormaPagamento();
      }
    }
    load();
  }, [buscaFormaPagamento]);

  const limparFormaPagamento = useCallback(() => {
    localStorage.removeItem('@Laboratorio:formasPagamento');
    localStorage.removeItem('@Laboratorio:optionsFormasPagamento');
    setOptionsFormasPagamento({} as IOptionsFormasPagamento);
    setFormasPagamento({} as IFormasPagamento);
  }, []);

  return (
    <FormaPagamentoContext.Provider
      value={{
        loading,
        formasPagamento,
        optionsFormasPagamento,
        buscaFormaPagamento,
        limparFormaPagamento,
      }}
    >
      {children}
    </FormaPagamentoContext.Provider>
  );
};

function useFormaPagamento(): FormaPagamentoContextData {
  const context = useContext(FormaPagamentoContext);

  if (!context) {
    throw new Error(
      'useFormaPagamento deve ser usado com o FormaPagamentoProvider',
    );
  }

  return context;
}

export { FormaPagamentoProvider, useFormaPagamento };
