import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import PacienteCadastro from '../../components/PacienteCadastro';

interface IUrlParams {
  id: string;
}

const PacienteCadastroPagina: React.FC = () => {
  const { params } = useRouteMatch<IUrlParams>();
  const { id } = params;
  const pacCodigo = useMemo(() => {
    return id ? Number(id) : undefined;
  }, [id]);

  return (
    <Container fluid className="pt-2">
      <PacienteCadastro id={pacCodigo} exibeMenu />
    </Container>
  );
};

export default PacienteCadastroPagina;
