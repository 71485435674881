/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Chart } from 'react-google-charts';

import {
  format,
  parseISO,
  endOfMonth,
  addMonths,
  eachMonthOfInterval,
  startOfMonth,
} from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import { formatPrice, TIPO_USUARIO } from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';

import Input from '../../../components/Input';
import SelectSync from '../../../components/SelectSync';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';

import { RelatorioHeader, ContainerResumo } from './styles';
import getResponseErrors from '../../../utils/getResponseErrors';
import PageInfo from '../../../components/PageInfo';
import { Colors } from '../../../styles/constants';

interface IItemConvenio {
  descricao: string;
  total: number;
  totalFormatado: string;
}

interface IRetornoResumoGerencial {
  clinica_id: number;
  nome: string;
  totalExames: number;
  totalExamesFormatado: string;
  totalDespesas: number;
  totalDespesasFormatado: string;
  totalReceitasFormatado: string;
  totalReceitaDespesaFormatado: string;
  totalConvenio: IItemConvenio[];
}

interface ITotais {
  totalExamesFormatado: string;
  totalDespesasFormatado: string;
  totalReceitasFormatado: string;
  totalReceitaDespesaFormatado: string;
}

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  clinica_id: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

export interface IItemResumoMes {
  descricao: string;
  mes: string;
  total: number;
}

interface IItemTabelaDados {
  mes: string;
  receita: string;
  despesa: string;
  atendimento: string;
  ticketMedio: string;
}

interface IRetornoResumoGerencialAcumulado {
  clinica_id: number;
  nome: string;
  valores: IItemResumoMes[];
}

interface IGenericType {
  [key: string]: any;
}

interface IGraficos {
  clinica: string;
  graficoReceitaDespesa: IGenericType[];
  graficoAtendimento: IGenericType[];
}

const ResumoGerencial: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica, imagensClinica } = useClinica();

  const graficoColors = [
    Colors.primary,
    Colors.verde,
    Colors.danger,
    Colors.cinza,
  ];

  const logo =
    imagensClinica.find(img => img.nome === 'logo')?.arquivo_url || '';

  const formRefBusca = useRef<FormHandles>(null);
  const formRefBuscaAcumulado = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState<
    IRetornoResumoGerencial[]
  >();
  const [totais, setTotais] = useState<ITotais>();
  const initialData = {
    dataInicio:
      new Date().getDate() < 15
        ? format(startOfMonth(addMonths(new Date(), -1)), 'yyyy-MM-dd')
        : format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    dataFim:
      new Date().getDate() < 15
        ? format(endOfMonth(addMonths(new Date(), -1)), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
    clinica_id: user.clinica_id.toString(),
  };

  const initialDataAcumulado = {
    dataInicio: format(addMonths(new Date(), -6), 'yyyy-MM'),
    dataFim: format(new Date(), 'yyyy-MM'),
    clinica_id: user.clinica_id.toString(),
  };

  const [dataExibe, setDataExibe] = useState<IDataExibe>();

  const [dataExibeAcumulado, setDataExibeAcumulado] = useState<IDataExibe>();
  const [clinica, setClinica] = useState('');

  const [graficos, setGraficos] = useState<IGraficos[]>();
  const [dadosTabela, setDadosTabela] = useState<IItemTabelaDados[]>();

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const informacoesPagina = `<b>Descrição:</b> Resumo de todos os lançamentos financeiros, receita e despesa de um determinado período.<br />Gráficos com acumulado mês a mês das Receitas x Despesas, comparativo de receita de Agendamentos x Cartão Saúde e número de atendimentos`;

  useEffect(() => {
    formRefBusca.current?.submitForm();
    formRefBuscaAcumulado.current?.submitForm();
  }, []);

  const handleSubmitBuscaAcumulado = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBuscaAcumulado.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          dataFim: Yup.date().required(),
          clinica_id: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        const dataInicioSelecionada = new Date(
          `${dados.dataInicio.toString()}-01 04:00:00`,
        );
        const dataFimSelecionada = endOfMonth(
          new Date(`${dados.dataFim.toString()}-01 04:00:00`),
        );

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibeAcumulado({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm'),
        });

        const parametrosOpcionais = dados.clinica_id
          ? `&clinica_id=${dados.clinica_id}`
          : '';

        const response = await api.get<IRetornoResumoGerencialAcumulado[]>(
          `/relatorios/resumogerencialacumulado?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}${parametrosOpcionais}`,
        );

        const meses = eachMonthOfInterval({
          start: dataInicioSelecionada,
          end: addMonths(dataFimSelecionada, -1),
        }).map(data => format(data, 'MM/yyyy'));

        const graficoReceitaDespesaLegenda: IGenericType[] = [
          ['Mes', 'Receitas', 'Despesas'],
        ];

        const graficoAtendimentoLegenda: IGenericType[] = [
          ['Mes', 'Atendimentos', { role: 'annotation' }],
        ];

        const graficoGeral: IGraficos[] = [];

        const dadosTabelaGeral: IItemTabelaDados[] = [];

        response.data.forEach(rel => {
          const resultado: IGenericType[] = [];
          const receitaDespesa: IGenericType[] = [];
          const atendimentosT: IGenericType[] = [];
          meses.forEach(mes => {
            const mesFiltrado = rel.valores.filter(val => val.mes === mes);
            const exames =
              mesFiltrado.find(val => val.descricao === 'exames')?.total || 0;
            const despesas =
              mesFiltrado.find(val => val.descricao === 'despesas')?.total || 0;
            const atendimentos =
              mesFiltrado.find(val => val.descricao === 'atendimentos')
                ?.total || 0;
            resultado.push([mes, exames]);
            receitaDespesa.push([mes, exames, despesas]);
            const ticket = exames / atendimentos;
            atendimentosT.push([
              mes,
              atendimentos,
              `Ticket ${formatPrice(ticket)}`,
            ]);
            dadosTabelaGeral.push({
              mes,
              receita: formatPrice(exames),
              despesa: formatPrice(despesas),
              atendimento: atendimentos.toString(),
              ticketMedio: formatPrice(ticket),
            });
          });

          graficoGeral.push({
            clinica: rel.nome,
            graficoReceitaDespesa: graficoReceitaDespesaLegenda.concat(
              receitaDespesa,
            ),
            graficoAtendimento: graficoAtendimentoLegenda.concat(atendimentosT),
          });
        });

        setDadosTabela(dadosTabelaGeral);
        setGraficos(graficoGeral);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBuscaAcumulado.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      setDadosRelatorio(undefined);
      setTotais(undefined);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          dataFim: Yup.date().required(),
          clinica_id: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinica(
          optionsClinica.find(cli => cli.value === dados.clinica_id)?.label ||
            '',
        );

        const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
        const dataFimSelecionada = parseISO(dados.dataFim.toString());

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm'),
        });

        const parametrosOpcionais = dados.clinica_id
          ? `&clinica_id=${dados.clinica_id}`
          : '';

        const response = await api.get<IRetornoResumoGerencial[]>(
          `/relatorios/resumogerencial?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}${parametrosOpcionais}`,
        );

        let totalExamesGeral = 0;
        let totalDespesasGeral = 0;
        let totalReceitasGeral = 0;
        let totalReceitaDespesaGeral = 0;

        const consultasFormatado = response.data.map(rel => {
          const totalReceitas = rel.totalExames;
          const totalReceitaDespesa = totalReceitas - rel.totalDespesas;

          totalExamesGeral += rel.totalExames;
          totalDespesasGeral += rel.totalDespesas;
          totalReceitasGeral += totalReceitas;
          totalReceitaDespesaGeral += totalReceitaDespesa;

          return {
            ...rel,
            totalExamesFormatado: formatPrice(rel.totalExames),
            totalDespesasFormatado: formatPrice(rel.totalDespesas),
            totalReceitasFormatado: formatPrice(totalReceitas),
            totalReceitaDespesaFormatado: formatPrice(totalReceitaDespesa),
            totalConvenio: rel.totalConvenio.map(forma => {
              return {
                ...forma,
                totalFormatado: formatPrice(forma.total),
              };
            }),
          };
        });

        setDadosRelatorio(consultasFormatado);
        setTotais({
          totalExamesFormatado: formatPrice(totalExamesGeral),
          totalDespesasFormatado: formatPrice(totalDespesasGeral),
          totalReceitasFormatado: formatPrice(totalReceitasGeral),
          totalReceitaDespesaFormatado: formatPrice(totalReceitaDespesaGeral),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading />}

      <div className="bg-white border p-2 my-3 d-flex justify-content-between align-items-center">
        <img src={logo} alt="Agenda facil" />
        <h2 id="topoBusca">Resumo Gerencial</h2>
        <div className="d-flex justify-content-between align-items-center">
          <span className="pr-2">
            Impresso em
            {dataExibe?.dataHora}{' '}
          </span>
          <PageInfo
            descricao={informacoesPagina}
            permissao={TIPO_USUARIO.SUPERVISOR}
          />
        </div>
      </div>

      <RelatorioHeader>
        Resumo Gerencial {`- ${clinica} -`} {dataExibe?.dataDiaSemana}
      </RelatorioHeader>
      <div className="bg-white border p-2">
        {optionsClinica.length > 0 && user && (
          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
            className="mb-2 d-print-none"
          >
            <Row>
              <Col md={2}>
                <Input name="dataInicio" label="De" type="date" required />
              </Col>
              <Col md={2}>
                <Input name="dataFim" label="Até" type="date" required />
              </Col>
              <Col md={2}>
                <SelectSync
                  name="clinica_id"
                  label="Clinica"
                  isDisabled={user.tipo > TIPO_USUARIO.ADMINISTRADOR}
                  isClearable={user.tipo === TIPO_USUARIO.ADMINISTRADOR}
                  options={optionsClinica}
                  handleSelectChange={handleOnChangeVazio}
                />
              </Col>
              <Col md={2}>
                <br />
                <Button type="submit" size="sm" variant="outline-secondary">
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        )}
        <div className="d-flex justify-content-center">
          {dadosRelatorio && (
            <>
              {dadosRelatorio.map(rel => (
                <ContainerResumo key={`clinica-${rel.clinica_id}`}>
                  <h5 style={{ textAlign: 'center' }}>{rel.nome}</h5>
                  <div
                    className="d-flex justify-content-center align-items-start"
                    style={{ gap: '30px' }}
                  >
                    <table
                      className="table table-hover table-sm table-font-small table-bordered"
                      style={{ borderWidth: '0px' }}
                    >
                      <thead>
                        <tr className="text-center">
                          <th>Tipo</th>
                          <th style={{ width: '95px' }}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Exames</td>
                          <td style={{ textAlign: 'right' }}>
                            {rel.totalExamesFormatado}
                          </td>
                        </tr>
                        <tr>
                          <td>Despesas(-)</td>
                          <td style={{ textAlign: 'right' }}>
                            {rel.totalDespesasFormatado}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td style={{ textAlign: 'right' }}>
                            {rel.totalReceitaDespesaFormatado}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {rel.totalConvenio.length > 0 && (
                      <table
                        className="table table-hover table-sm table-font-small table-bordered"
                        style={{ borderWidth: '0px' }}
                      >
                        <thead>
                          <tr className="text-center">
                            <th>Convênio</th>
                            <th style={{ width: '95px' }}>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rel.totalConvenio.map(conv => (
                            <tr
                              key={`convP-${rel.clinica_id}${conv.descricao}`}
                            >
                              <td>{conv.descricao}</td>
                              <td style={{ textAlign: 'right' }}>
                                {conv.totalFormatado}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </ContainerResumo>
              ))}
              {dadosRelatorio?.length > 1 && (
                <ContainerResumo>
                  <h5 style={{ textAlign: 'center' }}>Total Geral</h5>
                  <table
                    className="table table-hover table-sm table-font-small table-bordered"
                    style={{ borderWidth: '0px' }}
                  >
                    <thead>
                      <tr className="text-center">
                        <th>Tipo</th>
                        <th style={{ width: '95px' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Exames</td>
                        <td style={{ textAlign: 'right' }}>
                          {totais?.totalExamesFormatado}
                        </td>
                      </tr>
                      {/* Não faz sentido exibir agora, pq só tem exames
                       <tr>
                        <td>Receitas</td>
                        <td style={{ textAlign: 'right' }}>
                          {totais?.totalReceitasFormatado}
                        </td>
                      </tr> */}
                      <tr>
                        <td>Despesas(-)</td>
                        <td style={{ textAlign: 'right' }}>
                          {totais?.totalDespesasFormatado}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td style={{ textAlign: 'right' }}>
                          {totais?.totalReceitaDespesaFormatado}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ContainerResumo>
              )}
            </>
          )}
        </div>
      </div>

      <RelatorioHeader className="mt-2">
        Acumulado Mensal {`- ${clinica} -`} {dataExibeAcumulado?.dataDiaSemana}
      </RelatorioHeader>
      <div className="bg-white border p-2">
        <div className="d-print-none">
          {optionsClinica.length > 0 && user && (
            <Form
              ref={formRefBuscaAcumulado}
              initialData={initialDataAcumulado}
              onSubmit={handleSubmitBuscaAcumulado}
            >
              <Row>
                <Col md={3}>
                  <Input name="dataInicio" label="De" type="month" required />
                </Col>
                <Col md={3}>
                  <Input name="dataFim" label="Até" type="month" required />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="clinica_id"
                    label="Clinica"
                    isDisabled={user.tipo > TIPO_USUARIO.ADMINISTRADOR}
                    isClearable={user.tipo === TIPO_USUARIO.ADMINISTRADOR}
                    options={optionsClinica}
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                <Col md={2}>
                  <br />
                  <Button type="submit" size="sm" variant="outline-secondary">
                    BUSCAR
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>

        {graficos &&
          graficos.map(cli => (
            <div>
              <div>
                <Chart
                  key={`grafRec-${cli.clinica}`}
                  chartType="ColumnChart"
                  data={cli.graficoReceitaDespesa}
                  options={{
                    title: `Receita X Despesas ${cli.clinica}`,
                    legend: 'none',
                    colors: graficoColors,
                  }}
                  width="100%"
                  height="300px"
                  legendToggle
                />
              </div>
              <div>
                <Chart
                  key={`graf-${cli.clinica}`}
                  chartType="ColumnChart"
                  data={cli.graficoAtendimento}
                  options={{
                    title: `Atendimentos e Ticket Médio ${cli.clinica}`,
                    legend: 'none',
                    colors: graficoColors,
                  }}
                  width="100%"
                  height="300px"
                  legendToggle
                />
              </div>
            </div>
          ))}
        <br />
        {dadosTabela && (
          <>
            <h6>Resumo Analítico</h6>
            <div className="table-responsive">
              <table
                className="table table-sm hover-table no-footer"
                style={{ borderWidth: '0px' }}
              >
                <thead>
                  <tr>
                    <th>Mês</th>
                    <th>Receita</th>
                    <th>Despesa</th>
                    <th>Atendimento</th>
                    <th>Ticket Medio</th>
                  </tr>
                </thead>
                <tbody>
                  {dadosTabela.map(rel => (
                    <tr key={`tabelaDados-${rel.mes}`}>
                      <td>{rel.mes}</td>
                      <td>{rel.receita}</td>
                      <td>{rel.despesa}</td>
                      <td>{rel.atendimento}</td>
                      <td>{rel.ticketMedio}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default ResumoGerencial;
