import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import getResponseErrors from '../../utils/getResponseErrors';
import { formataIdade, formataCPF, TIPO_USUARIO } from '../../utils/funcoes';

import { IDadosRodape } from './index';

import { IPaciente } from './prontuarioImpresso';

interface IDocumento {
  AteCodigo: number;
  AteAgeCodigo: number;
  AteInicio: Date;
  AteInicioFormatado: string;
  AteTexto: string;
  medicoAtendimento: {
    UsuCodigo: number;
    UsuNome: string;
    UsuCRM: string;
    UsuConselho: string;
    UsuEspecialidade: string;
  };
  atendimentoAgenda: {
    AgeCodigo: number;
    AgeClinica: number;
  };
  paciente: IPaciente;
}

interface IComponentProps {
  AgeCodigo: number;
  handleDadosRodape?(dadosRodape: IDadosRodape): void;
}

const Prontuario: React.FC<IComponentProps> = ({
  AgeCodigo,
  handleDadosRodape,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useHistory();
  const [documento, setDocumento] = useState<IDocumento>();

  useEffect(() => {
    if (
      !(
        user.tipo === TIPO_USUARIO.ADMINISTRADOR ||
        user.tipo === TIPO_USUARIO.MEDICO
      )
    ) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const handleHTML = useCallback((texto: string) => {
    return texto
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
  }, []);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        const response = await api.get<IDocumento>(
          `/atendimentos/atendimentoimpressao/${AgeCodigo}`,
        );

        const dataNascimento = parseISO(
          response.data.paciente.PacDataNasc.toString(),
        );

        const dadosFormatados = {
          ...response.data,
          AteTexto: handleHTML(response.data.AteTexto),
          AteInicioFormatado: format(
            parseISO(response.data.AteInicio.toString()),
            'dd/MM/yyyy',
          ),
          paciente: {
            ...response.data.paciente,
            PacDataNasc: dataNascimento,
            PacDataNascFormatada: format(dataNascimento, 'dd/MM/yyyy'),
            PacIdade: formataIdade(dataNascimento, true),
            PacCPF: formataCPF(response.data.paciente.PacCPF),
          },
        };

        setDocumento(dadosFormatados);
        if (handleDadosRodape)
          handleDadosRodape({
            CliCodigo: response.data.atendimentoAgenda.AgeClinica,
            assinatura: {
              nome: response.data.medicoAtendimento.UsuNome,
              especialidade: response.data.medicoAtendimento.UsuEspecialidade,
              documento: response.data.medicoAtendimento.UsuCRM,
              conselho: response.data.medicoAtendimento.UsuConselho,
            },
          });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setTimeout(() => {
          window.print();
        }, 2000);
      }
    }
    loadDados();
  }, [AgeCodigo, addToast, handleHTML, handleDadosRodape]);

  return (
    <div>
      {documento && (
        <>
          <h5 className="text-center">PRONTUÁRIO DE ATENDIMENTO</h5>
          <p className="text-center mb-3">
            {documento.medicoAtendimento.UsuNome} {documento.AteInicioFormatado}
          </p>

          <table
            className="table table-hover -sm table-font-small table-bordered"
            style={{ borderWidth: '0px' }}
          >
            <tbody>
              <tr>
                <td colSpan={3}>
                  <b>NOME: </b> {documento.paciente.PacNome}
                </td>
              </tr>
              <tr>
                <td>
                  <b>RG: </b> {documento.paciente.PacRG}
                </td>
                <td>
                  <b>CPF: </b> {documento.paciente.PacCPF}
                </td>
                <td>
                  <b>SEXO: </b> {documento.paciente.PacSexo}
                </td>
              </tr>
              <tr>
                <td>
                  <b>DATA NASC: </b> {documento.paciente.PacDataNascFormatada}
                </td>
                <td colSpan={2}>
                  <b>IDADE: </b> {documento.paciente.PacIdade}
                </td>
              </tr>
            </tbody>
          </table>

          <div dangerouslySetInnerHTML={{ __html: documento.AteTexto }} />
        </>
      )}
    </div>
  );
};

export default Prontuario;
