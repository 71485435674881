import styled, { css } from 'styled-components';
import { shade, lighten } from 'polished';
import { Colors } from '../../styles/constants';

interface ButtonProps {
  variant: string;
}

export const Container = styled.button<ButtonProps>`
  width: auto;
  padding: 8px 16px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.2s;

  ${props =>
    props.variant.includes('-outline')
      ? css`
          background: transparent};
          color: ${Colors[props.variant.replace('-outline', '')]};
          border: 1px solid ${Colors[props.variant.replace('-outline', '')]};
          &:hover {
            color:${lighten(
              0.4,
              Colors[props.variant.replace('-outline', '')],
            )};
            background: ${shade(
              0.2,
              Colors[props.variant.replace('-outline', '')],
            )};
          }
        `
      : css`
          background: ${Colors[props.variant]};
          border: 1px solid ${Colors[props.variant]};
          &:hover {
            background: ${shade(0.2, Colors[props.variant])};
          }
        `}

  ${props =>
    props.disabled &&
    css`
      background: ${lighten(
        0.3,
        Colors[props.variant.replace('-outline', '')],
      )};
      cursor: not-allowed;
      &:hover {
        background: ${lighten(
          0.3,
          Colors[props.variant.replace('-outline', '')],
        )};
      }
    `};
`;
