import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  format,
  parseISO,
  differenceInYears,
  isAfter,
  addYears,
} from 'date-fns';

import { Row, Col, Button } from 'react-bootstrap';
import { FaFilePdf, FaTrashAlt } from 'react-icons/fa';

import api from '../../services/api';
import apiCep from '../../services/apiCep';

import getValidationErrors from '../../utils/getValidationErrors';
import {
  formataCPF,
  formataCep,
  formataTelefone,
  formataIdade,
  replaceCaracteres,
  validarCNPJ,
  validarCPF,
  htmlDecode,
  replaceHTMLparaWhatsapp,
} from '../../utils/funcoes';

import {
  optionsEscolaridade,
  optionsEstCivil,
  optionsSexo,
  optionsUF,
} from '../../utils/options';

import { useToast } from '../../hooks/toast';

import Input from '../Input';
import Avatar from '../Avatar';
import InputMask from '../InputMask';
import SelectSync from '../SelectSync';
import Loading from '../Loading';
import Modal from '../Modal';
import getResponseErrors from '../../utils/getResponseErrors';

import PacienteDocumento from '../PacienteDocumento';
import PacientePagamento from '../PacientePagamento';
import PacienteHistorico from '../PacienteHistorico';
import Responsavel from './Responsavel';
import PacienteExameLaboratorial from '../PacienteExameLaboratorial';

interface IPaciente {
  id: number;
  chave: string;
  nome: string;
  pessoaFisica: boolean;
  pessoaFisicaForm?: string;
  dataNasc: Date;
  dataNascFormatada: string;
  idade: string;
  cpf: string;
  rg: string;
  cnpj: string;
  ie: string;
  telefone: string;
  celular: string;
  email: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  cidadeCodigo: string;
  cep: string;
  complemento: string;
  uf: string;
  dataCadastro: Date;
  sexo: string;
  profissao: string;
  estadoCivil: string;
  escolaridade: string;
  ativo: boolean;
  avatar_url: string;
  restrito: boolean;
  foto?: string;
  senha: string;
  clinica_id?: number;
  convenio: string;
  responsavel_id?: number;
  responsavel?: {
    id: number;
    nome: string;
    telefone: string;
    celular: string;
    cpf: string;
    email: string;
  };
  textoMensagem: string;
}

type ComponentProps = {
  id?: number;
  exibeMenu?: boolean;
  handleRetornaDados?(dadosPaciente: IPaciente): void;
};

const PacienteCadastro: React.FC<ComponentProps> = ({
  id,
  exibeMenu = false,
  handleRetornaDados,
}) => {
  const { addToast } = useToast();
  const history = useHistory();
  const [paciente, setPaciente] = useState<IPaciente | undefined>(undefined);
  const formRefPaciente = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [showModalExcluir, setShowModalExcluir] = useState(false);
  const [showModalDadosAcesso, setShowModalDadosAcesso] = useState(false);
  const [showModalExames, setShowModalExames] = useState(false);
  const [menuSelecionado, setMenuSelecionado] = useState<string>();
  const [pessoaFisica, setPessoaFisica] = useState(true);
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [idade, setIdade] = useState('');
  const [limitDate, setLimitDate] = useState<{ max: string; min: string }>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IPaciente>(`/clientes/${id}/`);
        setPessoaFisica(response.data.pessoaFisica);
        setPaciente(response.data);

        const dataNasc =
          response.data.pessoaFisica &&
          response.data.dataNasc.toString().length > 9
            ? parseISO(response.data.dataNasc.toString())
            : new Date();
        setIdade(formataIdade(dataNasc, false));
        formRefPaciente.current?.setData({
          ...formRefPaciente.current?.getData(),
          ...response.data,
          dataNasc: format(dataNasc, 'yyyy-MM-dd'),
          telefone: formataTelefone(response.data.telefone),
          celular: formataTelefone(response.data.celular),
          cpf: formataCPF(response.data.cpf),
          cnpj: formataCPF(response.data.cnpj),
          cep: formataCep(response.data.cep),
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (id && id > 0) {
      loadDados();
    }
  }, [addToast, id]);

  useEffect(() => {
    setLimitDate({
      min: format(addYears(new Date(), -150), 'yyyy-MM-dd'),
      max: format(new Date(), 'yyyy-MM-dd'),
    });
  }, []);

  const handleBuscaCepApi = useCallback(async () => {
    if (formRefPaciente && formRefPaciente.current) {
      const cep = replaceCaracteres(
        String(formRefPaciente.current.getFieldValue('cep')),
      );

      if (cep && cep.length !== 8) {
        return;
      }

      setLoadingCEP(true);
      try {
        const response = await apiCep.get(`/${cep}/json/`);
        formRefPaciente.current?.setData({
          ...formRefPaciente.current?.getData(),
          bairro: response.data.bairro,
          cidade: response.data.localidade,
          cidadeCodigo: response.data.ibge,
          endereco: response.data.logradouro,
          uf: response.data.uf,
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível consultar o cep, confira seus dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoadingCEP(false);
      }
    }
  }, [addToast]);

  const handleSubmitPaciente = useCallback(
    async (dados: IPaciente) => {
      setLoading(true);
      try {
        formRefPaciente.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string()
            .min(6, 'Mínimo 6 caracteres')
            .required('Nome é obrigatório'),
          pessoaFisicaForm: Yup.string().required('Tipo é obrigatório'),
          dataNasc: Yup.date()
            .typeError('Digite uma data válida')
            .when('pessoaFisicaForm', {
              is: '1',
              then: Yup.date()
                .typeError('Digite uma data válida')
                .required('Data de Nascimento é obrigatório'),
            }),
          cpf: Yup.string()
            .nullable()
            .transform(x => x || null)
            .min(11, 'Mínimo 11 caracteres'),
          rg: Yup.string(),
          cnpj: Yup.string().when('pessoaFisicaForm', {
            is: '0',
            then: Yup.string()
              .min(18, 'Preencha corretamente o CNPJ')
              .required('CNPJ obrigatório'),
          }),
          ie: Yup.string(),
          PacTelefone: Yup.string()
            .nullable()
            .transform(x => x || null)
            .min(10, 'Mínimo 10 caracteres'),
          celular: Yup.string()
            .min(11, 'Mínimo 11 caracteres')
            .required('Obrigatório'),
          email: Yup.string(),
          endereco: Yup.string(),
          numero: Yup.string(),
          bairro: Yup.string(),
          cidade: Yup.string(),
          cidadeCodigo: Yup.string(),
          cep: Yup.string()
            .nullable()
            .transform(x => x || null)
            .min(8, 'Mínimo 8 caracteres'),
          complemento: Yup.string(),
          uf: Yup.string(),
          sexo: Yup.string().when('pessoaFisicaForm', {
            is: '1',
            then: Yup.string().required('Sexo é obrigatório'),
          }),
          profissao: Yup.string(),
          estCivil: Yup.string(),
          escolaridade: Yup.string(),
        });

        const novo = !id || id === 0;

        const pacienteForm = {
          ...dados,
          ...(novo ? { pessoaFisica: dados.pessoaFisicaForm === '1' } : {}),
          ...(dados.pessoaFisicaForm === '1' && {
            dataNasc: new Date(
              `${dados.dataNasc.toString().replace(/-/g, '/')} 03:00:00`,
            ),
            rg: dados.rg.toUpperCase(),
            cpf: replaceCaracteres(dados.cpf),
          }),
          nome: dados.nome.toUpperCase(),
          endereco: dados.endereco.toUpperCase(),
          numero: dados.numero.toUpperCase(),
          complemento: dados.complemento.toUpperCase(),
          bairro: dados.bairro.toUpperCase(),
          cidade: dados.cidade.toUpperCase(),
          email: dados.email.toLowerCase(),
          uf: dados.uf.toUpperCase(),
          telefone: replaceCaracteres(dados.telefone),
          celular: replaceCaracteres(dados.celular),
          cep: replaceCaracteres(dados.cep),
        };

        await schema.validate(pacienteForm, {
          abortEarly: false,
        });

        if (pacienteForm.cpf && !validarCPF(pacienteForm.cpf)) {
          formRefPaciente.current?.setFieldError('cpf', 'CPF inválido');
          addToast({
            type: 'error',
            title: 'CPF inválido',
          });
          return;
        }

        if (
          !pacienteForm.pessoaFisica &&
          pacienteForm.cnpj &&
          !validarCNPJ(pacienteForm.cnpj)
        ) {
          formRefPaciente.current?.setFieldError('cnpj', 'CNPJ inválido');
          addToast({
            type: 'error',
            title: 'CNPJ inválido',
          });
          return;
        }

        if (pacienteForm.pessoaFisica || dados.pessoaFisicaForm === '1') {
          const anos = differenceInYears(new Date(), pacienteForm.dataNasc);
          if (anos > 110) {
            formRefPaciente.current?.setFieldError('dataNasc', 'Data inválida');
            addToast({
              type: 'error',
              title: 'Data inválida',
              description: 'Data de nascimento não pode ser maior que 110 anos',
            });
            return;
          }
          if (anos < 0 || isAfter(pacienteForm.dataNasc, new Date())) {
            formRefPaciente.current?.setFieldError('dataNasc', 'Data inválida');
            addToast({
              type: 'error',
              title: 'Data inválida',
              description:
                'Data de nascimento não pode ser maior que data atual',
            });
            return;
          }
        }

        delete pacienteForm.pessoaFisicaForm;

        if (novo) {
          const response = await api.post<IPaciente>('/clientes', pacienteForm);

          setPaciente(response.data);
          const dataNasc =
            response.data.dataNasc &&
            response.data.dataNasc.toString().length > 9
              ? parseISO(response.data.dataNasc.toString())
              : new Date();
          setIdade(formataIdade(dataNasc, false));

          if (handleRetornaDados) {
            handleRetornaDados({
              ...response.data,
              idade: formataIdade(dataNasc),
              dataNasc,
              dataNascFormatada: format(dataNasc, 'dd/MM/yyyy'),
              ativo: response.data.ativo ? response.data.ativo : false,
              avatar_url: response.data.avatar_url
                ? response.data.avatar_url
                : '',
            });
          } else {
            addToast({ title: 'Paciente salvo', type: 'success' });
            window.location.href = `/pacientecadastro/${response.data.id}`;
          }
        } else {
          const response = await api.put<IPaciente>(
            `/clientes/${id}`,
            pacienteForm,
          );

          addToast({ title: 'Paciente atualizado', type: 'success' });
          if (handleRetornaDados) handleRetornaDados(response.data);
        }
      } catch (error: any) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefPaciente.current?.setErrors(errors);
          return;
        }

        if (error?.response?.data?.message?.includes('CPF já cadastrado')) {
          formRefPaciente.current?.setFieldError('cpf', 'CPF já cadastrado');
        }

        if (error?.response?.data?.message?.includes('E-mail já cadastrado')) {
          formRefPaciente.current?.setFieldError(
            'email',
            'E-mail já cadastrado',
          );
        }

        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Erro ao salvar paciente',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleRetornaDados, id],
  );

  const handleReturnNewAvatarUrl = useCallback((avatar_url: string) => {
    setPaciente(state => {
      return state && { ...state, avatar_url };
    });
  }, []);

  const handleDeletePaciente = useCallback(async () => {
    setLoading(true);
    setShowModalExcluir(false);
    try {
      await api.delete(`/clientes/${id}`);

      history.replace('/paciente');

      addToast({
        type: 'success',
        title: 'Registro excluído',
      });
    } catch (error) {
      const mensagemErro = getResponseErrors(error);

      addToast({
        type: 'error',
        title: 'Não foi possível excluir o registro',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, id, history]);

  const handleVerificaCPF = useCallback(async () => {
    try {
      formRefPaciente.current?.setFieldError('cpf', '');
      const cpf = replaceCaracteres(
        formRefPaciente.current?.getFieldValue('cpf').toString(),
      );
      if (cpf.length !== 11) return;

      if (!validarCPF(cpf)) {
        formRefPaciente.current?.setFieldError('cpf', 'CPF inválido');
        addToast({
          type: 'error',
          title: 'CPF inválido',
        });
        return;
      }
      const paciente_id = paciente && paciente.id ? `&id=${paciente.id}` : '';
      await api.get(`/clientes/verificacpfcadastrado?cpf=${cpf}${paciente_id}`);
    } catch (error) {
      const mensagemErroApi = getResponseErrors(error);
      if (mensagemErroApi === 'CPF já cadastrado')
        formRefPaciente.current?.setFieldError('cpf', 'CPF já Cadastrado');
      addToast({
        title: 'CPF inválido',
        type: 'error',
        description: mensagemErroApi,
      });
    }
  }, [addToast, paciente]);

  const handleVerificaEmail = useCallback(async () => {
    try {
      formRefPaciente.current?.setFieldError('email', '');
      const email = formRefPaciente.current?.getFieldValue('email').toString();

      if (email.length === 0) return;

      const paciente_id = paciente && paciente.id ? `&id=${paciente.id}` : '';
      await api.get(
        `/clientes/verificaemailcadastrado?email=${email}${paciente_id}`,
      );
    } catch (error) {
      const mensagemErroApi = getResponseErrors(error);
      if (mensagemErroApi === 'E-mail já cadastrado')
        formRefPaciente.current?.setFieldError('email', 'E-mail já Cadastrado');
      addToast({
        title: 'E-mail inválido',
        type: 'error',
        description: mensagemErroApi,
      });
    }
  }, [addToast, paciente]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handleEnviarWhatsapp = useCallback(async () => {
    try {
      if (paciente && paciente.id) {
        setLoading(true);
        const dadosMensagem = {
          message: paciente.textoMensagem,
          type: 'Envio acesso whatsapp',
          numeroTo: `55${paciente.celular}`,
        };
        await api.put(`/whatsapp/sendmessage/${paciente.id}`, dadosMensagem);

        addToast({
          title: 'Envio de Acesso',
          type: 'success',
          description: 'Dados enviados com sucesso.',
        });
      }
    } catch (error) {
      const mensagemErroApi = getResponseErrors(error);

      addToast({
        title: 'Envio de Acesso',
        type: 'error',
        description: mensagemErroApi,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, paciente]);

  const handleEnviarEmail = useCallback(async () => {
    try {
      if (paciente && paciente.id) {
        setLoading(true);
        const dadosMensagem = {
          name: paciente.nome,
          email: paciente.email,
          textoMensagem: paciente.textoMensagem,
        };
        await api.put(
          `/clientes/enviaEmailDadosAcesso/${paciente.id}`,
          dadosMensagem,
        );

        addToast({
          title: 'Envio de Acesso',
          type: 'success',
          description: 'Dados enviados com sucesso.',
        });
      }
    } catch (error) {
      const mensagemErroApi = getResponseErrors(error);

      addToast({
        title: 'Envio de Acesso',
        type: 'error',
        description: mensagemErroApi,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, paciente]);

  // const handleEnviarDadosAcesso = useCallback(async () => {
  //   try {
  //     if (paciente && paciente.id) {
  //       setLoading(true);
  //       await api.put(`/whatsapp/sendacesso/${paciente.id}`);

  //       addToast({
  //         title: 'Envio de Acesso',
  //         type: 'success',
  //         description: 'Dados enviados com sucesso.',
  //       });
  //     }
  //   } catch (error) {
  //     const mensagemErroApi = getResponseErrors(error);

  //     addToast({
  //       title: 'Envio de Acesso',
  //       type: 'error',
  //       description: mensagemErroApi,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [addToast, paciente, setLoading]);

  return (
    <>
      {loading && <Loading />}
      {showModalExcluir && (
        <Modal
          handleFecharModal={() => setShowModalExcluir(false)}
          show={showModalExcluir}
          titulo="Excluir registro"
          large={false}
        >
          <p className="my-3">
            Essa operação não pode ser desfeita, deseja realmente prosseguir?
          </p>
          <div className="d-flex justify-content-between mt-2">
            <Button
              type="submit"
              variant="secondary"
              size="sm"
              onClick={() => setShowModalExcluir(false)}
            >
              Não, voltar
            </Button>

            <Button
              type="button"
              variant="danger"
              onClick={handleDeletePaciente}
              size="sm"
            >
              <FaTrashAlt /> Sim, excluir
            </Button>
          </div>
        </Modal>
      )}

      {showModalDadosAcesso && (
        <Modal
          handleFecharModal={() => setShowModalDadosAcesso(false)}
          show={showModalDadosAcesso}
          titulo="Enviar dados de Acesso"
          large
        >
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: htmlDecode(paciente?.textoMensagem || ''),
            }}
          />
          <div className="d-flex justify-content-between mt-2">
            <Button
              type="button"
              variant="secondary"
              size="sm"
              onClick={handleEnviarWhatsapp}
              disabled={loading}
            >
              Enviar WhatsApp
            </Button>

            <CopyToClipboard
              text={replaceHTMLparaWhatsapp(paciente?.textoMensagem || '')}
            >
              <Button type="button" variant="info" size="sm" disabled={loading}>
                Copiar mensagem
              </Button>
            </CopyToClipboard>

            <Button
              type="button"
              variant="primary"
              onClick={handleEnviarEmail}
              size="sm"
              disabled={loading}
            >
              Enviar E-mail
            </Button>
          </div>
        </Modal>
      )}

      {id && menuSelecionado && (
        <Modal
          show={!!menuSelecionado}
          titulo={menuSelecionado}
          handleFecharModal={() => setMenuSelecionado(undefined)}
        >
          {menuSelecionado === 'Histórico' && (
            <PacienteHistorico cliente_id={id} />
          )}
          {menuSelecionado === 'Pagamentos' && (
            <PacientePagamento pacCodigo={id} />
          )}
          {menuSelecionado === 'Documentos' && (
            <PacienteDocumento pacCodigo={id} />
          )}
        </Modal>
      )}

      {id && showModalExames && (
        <Modal
          handleFecharModal={() => setShowModalExames(false)}
          show={showModalExames}
          titulo="Exames do paciente"
          large
        >
          <PacienteExameLaboratorial pacCodigo={id} />
        </Modal>
      )}

      <div className="card mt-2">
        <div className="card-header py-2">
          <div className=" d-flex justify-content-between align-items-center">
            <div className="">Cadastro de Paciente</div>
            {paciente && exibeMenu && (
              <div className="d-none">
                <Button
                  variant="secondary"
                  size="sm"
                  className="round-xxl"
                  onClick={() => setMenuSelecionado('Histórico')}
                >
                  Histórico
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  className="round-xxl mx-2"
                  onClick={() => setMenuSelecionado('Pagamentos')}
                >
                  Pagamentos
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  className="round-xxl"
                  onClick={() => setMenuSelecionado('Documentos')}
                >
                  Documentos
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <Row noGutters>
            <Col md={12}>
              <Form
                ref={formRefPaciente}
                // initialData={}
                onSubmit={handleSubmitPaciente}
                style={{ fontSize: '0.8rem' }}
              >
                <Input
                  type="hidden"
                  name="pessoaFisicaForm"
                  value={pessoaFisica ? '1' : '0'}
                />
                <Row>
                  {paciente ? (
                    <Col md={4} lg={3}>
                      <Avatar
                        codigo={paciente.id}
                        nome={paciente.nome}
                        avatar_url={paciente.avatar_url}
                        path="pacientes"
                        handleReturnNewAvatarUrl={handleReturnNewAvatarUrl}
                      />
                    </Col>
                  ) : (
                    <Col md={4} lg={3}>
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <h5 className="border py-2 px-3">Novo Paciente</h5>
                        {/* <ButtonGroup size="sm" style={{ display: 'inline' }}>
                          <Button
                            type="button"
                            size="sm"
                            variant={
                              pessoaFisica ? 'secondary' : 'outline-secondary'
                            }
                            onClick={() => setPessoaFisica(true)}
                          >
                            Pessoa Física
                          </Button>
                          <Button
                            type="button"
                            size="sm"
                            variant={
                              pessoaFisica ? 'outline-secondary' : 'secondary'
                            }
                            onClick={() => setPessoaFisica(false)}
                          >
                            Pessoa Jurídica
                          </Button>
                        </ButtonGroup> */}
                      </div>
                    </Col>
                  )}
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Input
                          name="nome"
                          label={pessoaFisica ? 'Nome' : 'Razão Social'}
                          maxLength={255}
                        />
                      </Col>
                      <Col md={6}>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          maxLength={100}
                          onBlur={handleVerificaEmail}
                        />
                      </Col>
                      <div className="w-100 mt-2" />
                      {pessoaFisica ? (
                        <>
                          <Col md={4}>
                            <InputMask
                              name="cpf"
                              label="CPF"
                              mask="999.999.999-99"
                              placeholder="000.000.000-00"
                              minLength={15}
                              onBlur={handleVerificaCPF}
                            />
                          </Col>
                          <Col md={4}>
                            <Input name="rg" maxLength={18} label="RG" />
                          </Col>
                          <Col md={4}>
                            <SelectSync
                              name="estadoCivil"
                              label="Estado Civil"
                              placeholder="Selecione"
                              handleSelectChange={handleOnChangeVazio}
                              options={optionsEstCivil}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col md={4}>
                            <InputMask
                              name="cnpj"
                              label="CNPJ"
                              mask="99.999.999/9999-99"
                              alwaysShowMask={false}
                              placeholder="00.000.000/0000-00"
                            />
                          </Col>
                          <Col md={4}>
                            <Input
                              name="ie"
                              maxLength={18}
                              label="Insc.Estadual"
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>

                <Row className="my-2">
                  {pessoaFisica && (
                    <Col md={4}>
                      {idade && (
                        <b style={{ float: 'right' }}>{`idade: ${idade}`}</b>
                      )}
                      <Input
                        name="dataNasc"
                        type="date"
                        label="Data de Nascimento"
                        max={limitDate && limitDate.max}
                        min={limitDate && limitDate.min}
                      />
                    </Col>
                  )}
                  <Col md={4}>
                    <InputMask
                      name="celular"
                      label="Celular"
                      mask="(99) 99999-9999"
                      alwaysShowMask={false}
                      placeholder="(00) 00000-0000"
                    />
                  </Col>
                  <Col md={4}>
                    <InputMask
                      name="telefone"
                      label="Telefone"
                      mask="(99) 99999-9999"
                      placeholder="(00) 00000-0000"
                    />
                  </Col>
                </Row>

                {pessoaFisica && (
                  <Row>
                    <Col md={4}>
                      <SelectSync
                        name="sexo"
                        label="Sexo"
                        placeholder="Selecione"
                        handleSelectChange={handleOnChangeVazio}
                        options={optionsSexo}
                      />
                    </Col>
                    <Col md={4}>
                      <SelectSync
                        name="escolaridade"
                        label="Escolaridade"
                        placeholder="Selecione"
                        handleSelectChange={handleOnChangeVazio}
                        options={optionsEscolaridade}
                      />
                    </Col>
                    <Col md={4}>
                      <Input
                        name="profissao"
                        maxLength={50}
                        label="Profissão"
                      />
                    </Col>
                  </Row>
                )}

                <Row className="mt-4">
                  <Col md="8">
                    <div className="card mb-1 rounded-0 h-100">
                      <div
                        className="card-header"
                        style={{ backgroundColor: '#fafafa' }}
                      >
                        <b>Endereço</b>
                      </div>
                      <div className="card-body">
                        {/* <h4 className="text-info border-bottom mt-3">Endereço</h4> */}
                        <Row>
                          <Col md={4} lg={3}>
                            <InputMask
                              name="cep"
                              label="CEP"
                              mask="99999-999"
                              placeholder="00000-000"
                              onBlur={handleBuscaCepApi}
                            />
                          </Col>
                          <Col md={4}>
                            <br />
                            <Button
                              size="sm"
                              className="mt-1"
                              variant="outline-secondary"
                              onClick={handleBuscaCepApi}
                            >
                              {loadingCEP ? 'buscando...' : 'Buscar Endereço'}
                            </Button>
                          </Col>
                          <div className="w-100 mt-2" />
                          <Col md={7}>
                            <Input
                              name="endereco"
                              maxLength={200}
                              label="Endereço"
                            />
                          </Col>
                          <Col md={2}>
                            <Input
                              name="numero"
                              maxLength={10}
                              label="Numero"
                            />
                          </Col>
                          <Col md={3}>
                            <Input
                              name="complemento"
                              maxLength={30}
                              label="Complemento"
                            />
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col md={4}>
                            <Input
                              name="bairro"
                              maxLength={30}
                              label="Bairro"
                            />
                          </Col>
                          <Col md={3}>
                            <Input
                              name="cidade"
                              maxLength={30}
                              label="Cidade"
                            />
                          </Col>
                          <Col md={2}>
                            <Input
                              name="cidadeCodigo"
                              maxLength={30}
                              disabled
                              label="Cidade Codigo"
                            />
                          </Col>
                          <Col md={3}>
                            <SelectSync
                              name="uf"
                              label="UF"
                              placeholder="Selecione"
                              handleSelectChange={handleOnChangeVazio}
                              options={optionsUF}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  {paciente && (
                    <Col>
                      <div className="card mb-1 rounded-0 h-100">
                        <div
                          className="card-header"
                          style={{ backgroundColor: '#fafafa' }}
                        >
                          <b>Responsável</b>
                        </div>
                        <div className="card-body">
                          <Responsavel
                            id={paciente.id}
                            responsavelCliente={paciente.responsavel}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>

                <div className="d-flex justify-content-around my-4">
                  <Button type="submit" variant="primary" className="px-md-5">
                    Salvar dados
                  </Button>

                  <Button
                    type="button"
                    variant="secondary"
                    className="px-md-5"
                    onClick={() => setShowModalDadosAcesso(true)}
                    // onClick={() => handleEnviarDadosAcesso()}
                  >
                    Enviar acesso
                  </Button>

                  {paciente && exibeMenu && (
                    <>
                      <Button
                        type="button"
                        variant="success"
                        className="px-md-5"
                        onClick={() => setShowModalExames(true)}
                      >
                        <FaFilePdf /> Exames
                      </Button>

                      <Button
                        type="button"
                        variant="outline-light"
                        className="btn-excluir"
                        onClick={() => setShowModalExcluir(true)}
                      >
                        <FaTrashAlt /> Excluir
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PacienteCadastro;
