import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Inputs, Label, Unidade } from './styles';
import { Erro, Content } from '../DatePicker/styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: React.CSSProperties;
  large?: boolean;
  label?: string;
  disabled?: boolean;
  unidade?: string;
  type: string;
}

const InputExame: React.FC<InputProps> = ({
  name,
  label,
  unidade,
  type,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [hasLabel, setHasLabel] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleChange = useCallback(
    e => {
      if (hasLabel && e.target.value === '') {
        setHasLabel(false);
      }
      if (!hasLabel && e.target.value !== '') {
        setHasLabel(true);
      }
    },
    [hasLabel],
  );

  const handleFocus = useCallback(e => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(e => {
    setFocused(false);
  }, []);

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
    // console.log(name, inputRef.current.value);
  }, [fieldName, name, registerField]);

  return (
    <>
      <Content invisivel={type === 'hidden'}>
        <Label htmlFor={name} exibe={hasLabel || focused || rest.disabled}>
          {label}
        </Label>
        <Inputs
          ref={inputRef}
          name={name}
          defaultValue={defaultValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="new-password"
          {...rest}
          type={type}
          className={error ? 'has-error' : ''}
        />
        <Unidade>{unidade || ''}</Unidade>

        {error && <Erro className="error">{error}</Erro>}
      </Content>
    </>
  );
};

export default InputExame;
