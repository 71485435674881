import styled from 'styled-components';
import { shade } from 'polished';

interface FormProps {
  hasError: boolean;
}

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(253deg, #0cc898, #1797d2, #864fe1);
  background-size: 300% 300%;
  -webkit-animation: Background 7.5s ease infinite;
  -moz-animation: Background 7.5s ease infinite;
  animation: Background 7.5s ease infinite;

  @-webkit-keyframes Background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @-moz-keyframes Background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes Background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const Content = styled.div`
  min-width: 400px;
  padding: 60px 30px;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;

  @media screen and (max-width: 632px) {
    min-width: 90%;
    padding: 30px 12px;
  }

  img {
    width: 100%;
    max-width: 250px;
  }

  > form {
    margin: 24px 0 0 0;
    width: 100%;
    max-width: 400px;

    h1 {
      font-size: 26px;
      color: #3a3a3a;
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 16px;
    }

    a {
      color: #3b3b3b;
      display: block;
      text-decoration: none;
      margin-top: 18px;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.6, '#3b3b3b')};
      }
    }
  }
`;

export const Background = styled.div`
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;
  > div {
    width: 100%;
    text-align: center;
  }

  img {
    width: 100%;
    max-width: 300px;
  }
  background: conic-gradient(
      from -45deg at calc(100% / 3) calc(100% / 3),
      #47d3ff 90deg,
      #0000 0
    ),
    conic-gradient(
      from -135deg at calc(100% / 3) calc(2 * 100% / 3),
      #47d3ff 90deg,
      #49aad5 0 135deg,
      #0000 0
    ),
    conic-gradient(
      from 135deg at calc(2 * 100% / 3) calc(2 * 100% / 3),
      #47d3ff 90deg,
      #49aad5 0 135deg,
      #0000 0
    ),
    conic-gradient(
      from 45deg at calc(2 * 100% / 3) calc(100% / 3),
      #47d3ff 90deg,
      #49aad5 0 135deg,
      #0000 0,
      #47d3ff 0 225deg,
      #49aad5 0
    );
  background-size: 72px 72px;
`;

export const Form = styled.form<FormProps>``;

export const Error = styled.span`
  display: block;
  color: #c53030;
  margin-top: 8px;
`;
