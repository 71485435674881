import React from 'react';

import { Modal as ModalBoot } from 'react-bootstrap';

type ModalProps = {
  titulo: string;
  show: boolean;
  handleFecharModal(): void;
  large?: boolean;
  tituloAdicional?: string;
  className?: string;
};

const Modal: React.FC<ModalProps> = ({
  titulo = 'Modal',
  handleFecharModal,
  large = true,
  tituloAdicional = '',
  className = '',
  children,
  show,
}) => (
  <ModalBoot
    show={show}
    onHide={handleFecharModal}
    backdrop="static"
    size={large ? 'lg' : 'sm'}
    className={className}
  >
    <ModalBoot.Header closeButton>
      <ModalBoot.Title style={{ fontSize: '20px', textAlign: 'center' }}>
        {titulo}
      </ModalBoot.Title>
    </ModalBoot.Header>
    <ModalBoot.Body>{children}</ModalBoot.Body>
  </ModalBoot>
);

export default Modal;
