import React, { useState, useEffect } from 'react';

import { Button } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

import Modal from '../Modal';

type ComponentProps = {
  titulo?: string;
  descricao: string;
  permissao: number;
};

const PageInfo: React.FC<ComponentProps> = ({
  titulo = 'Informações da página',
  descricao,
  permissao,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [permissaoFormatado, setPermissaoFormatado] = useState('');

  useEffect(() => {
    switch (permissao) {
      case 1: {
        setPermissaoFormatado('ADMINISTRADORES');
        break;
      }
      case 2: {
        setPermissaoFormatado('ADMINISTRADORES E GERENTES');
        break;
      }
      case 3: {
        setPermissaoFormatado('ADMINISTRADORES, GERENTES E CAIXA');
        break;
      }
      case 4: {
        setPermissaoFormatado('TODOS');
        break;
      }
      default: {
        setPermissaoFormatado('NÃO INFORMADO');
      }
    }
  }, [permissao]);

  return (
    <div>
      <Modal
        show={showModal}
        titulo={titulo}
        handleFecharModal={() => setShowModal(false)}
        large
      >
        <div className="py-3">
          <p>
            <b>Quem pode acessar:</b> {permissaoFormatado}
          </p>

          <div dangerouslySetInnerHTML={{ __html: descricao }} />
        </div>
      </Modal>
      <Button
        size="sm"
        variant="outline-info"
        className="border-0"
        onClick={() => setShowModal(true)}
      >
        <FaInfoCircle size={20} />
      </Button>
    </div>
  );
};

export default PageInfo;
