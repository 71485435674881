import React from 'react';

interface Props {
  name: string;
  label?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const Checkbox: React.FC<InputProps> = ({
  name,
  checked,
  label = '',
  onChange,
}) => (
  <input type="checkbox" name={name} checked={checked} onChange={onChange} />
);

export default Checkbox;
