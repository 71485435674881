/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import {
  TIPO_USUARIO,
  formataTelefone,
  formataIdade,
} from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import Select from '../../../components/Select';
import Loading from '../../../components/Loading';
import Paginacao from '../../../components/Paginacao';

import Conferido from './Conferido';

import getValidationErrors from '../../../utils/getValidationErrors';
import getResponseErrors from '../../../utils/getResponseErrors';

interface IUsuario {
  UsuCodigo: number;
  UsuNome: string;
  UsuDataNasc: Date;
  UsuDataNascFormatado: string;
  idade: string;
  UsuTelefone: string;
  UsuTelefoneFormatado: string;
  UsuEmail: string;
  UsuFoto: string;
  RegCodigo?: number;
  dataRegistro: Date;
  conferido: boolean;
  avatar_url?: string;
}

interface IFormBusca {
  termoBusca: string;
  tipo: string;
}

interface IPaginacao {
  termoBusca: string;
  tipo: string;
  limite: number;
  pagina: number;
}

const optionsBusca = [
  { value: 'hoje', label: 'Hoje' },
  { value: 'mes', label: 'Mês' },
];

const optionsTipo = [
  { value: 'todos', label: 'Todos' },
  { value: 'medico', label: 'Médicos' },
  { value: 'usuario', label: 'Usuários' },
];

const AniversariantesUsuario: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState<IUsuario[]>();
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    termoBusca: 'Hoje',
    tipo: 'todos',
    pagina: 1,
    limite: 20,
  });
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );
  // const [totais, setTotais] = useState<ITotais>();

  useEffect(() => {
    if (user.tipo === TIPO_USUARIO.MEDICO) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        if (!paginacao) return;
        const response = await api.get(
          `/users/aniversariantes?termoBusca=${paginacao.termoBusca}&tipo=${paginacao.tipo}&pagina=${paginacao.pagina}&limite=${paginacao.limite}`,
        );

        const aniversariantesApi: IUsuario[] = response.data[0];

        setTotalRegistros(
          response.data[1] === 0 ? undefined : response.data[1],
        );

        const dadosFormatados = aniversariantesApi.map(usu => {
          const dataNasc =
            usu.UsuDataNasc && usu.UsuDataNasc.toString().length > 9
              ? parseISO(usu.UsuDataNasc.toString())
              : new Date();

          return {
            ...usu,
            UsuDataNascFormatado: format(dataNasc, 'dd/MM/yyyy'),
            UsuTelefoneFormatado: formataTelefone(usu.UsuTelefone),
            idade: formataIdade(dataNasc, false),
            avatar_url: usu.avatar_url ? usu.avatar_url : '',
          };
        });

        setDadosRelatorio(dadosFormatados);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível carregar dados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }

    if (paginacao) loadDados();
  }, [addToast, paginacao]);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        termoBusca: Yup.string().required(),
        tipo: Yup.string().required(),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });

      setPaginacao({
        termoBusca: dados.termoBusca,
        tipo: dados.tipo,
        pagina: 1,
        limite: 20,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading />}
      <>
        <div className="d-print-none bg-white border p-2 mb-2">
          <div className="p-2 mb-2">
            <h2 id="topoBusca">Aniversariantes</h2>
          </div>

          <Form ref={formRefBusca} onSubmit={handleSubmitBusca}>
            <Row>
              <Col md={2}>
                <Select name="termoBusca" label="" options={optionsBusca} />
              </Col>
              <Col md={2}>
                <Select name="tipo" label="" options={optionsTipo} />
              </Col>
              <Col md={2}>
                <Button type="submit" size="sm" variant="outline-secondary">
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="bg-white border p-2">
          {dadosRelatorio && (
            <>
              <b>{totalRegistros} aniversariantes</b>
              <table
                className="table table-hover table-sm table-font-small table-bordered"
                style={{ borderWidth: '0px' }}
              >
                <thead>
                  <tr className="text-center">
                    <th>Usuários</th>
                    <th style={{ minWidth: '100px' }}>Data Nasc.</th>
                    <th style={{ minWidth: '80px' }}>Idade</th>
                    <th style={{ minWidth: '120px' }}>Telefone</th>
                    <th style={{ minWidth: '120px' }}>E-mail</th>
                    <th style={{ width: '70px' }}>Conferido</th>
                  </tr>
                </thead>
                <tbody>
                  {dadosRelatorio &&
                    dadosRelatorio.map(rel => (
                      <tr
                        key={`des-${rel.UsuCodigo}`}
                        style={{
                          fontSize: 'Small',
                          height: '30px',
                        }}
                      >
                        <td>{rel.UsuNome}</td>
                        <td>{rel.UsuDataNascFormatado}</td>
                        <td>{rel.idade}</td>
                        <td>{rel.UsuTelefoneFormatado}</td>
                        <td>{rel.UsuEmail}</td>
                        <td>
                          <Conferido
                            UsuCodigo={rel.UsuCodigo}
                            conferido={rel.conferido}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
          <Paginacao
            limitePagina={paginacao?.limite || 20}
            paginasVizinhas={2}
            totalRegistros={totalRegistros}
            paginaInicio={paginacao?.pagina}
            handleProximaPagina={handleProximaPagina}
          />
        </div>
      </>
    </Container>
  );
};

export default AniversariantesUsuario;
