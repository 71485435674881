import React, { useCallback, useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';

import { Row, Col, Button } from 'react-bootstrap';
import {
  FaTrashAlt,
  FaPrint,
  FaReply,
  FaFileSignature,
  FaRegCopy,
} from 'react-icons/fa';

import { position } from 'polished';
import api from '../../services/api';

import { TIPO_DOCUMENTO } from '../../utils/funcoes';
import { Colors } from '../../styles/constants';

import { useToast } from '../../hooks/toast';

import { ContainerDados } from './styles';
import DocumentoCadastro from './cadastro';
import AssinaDocumento from './assina';

import Modal from '../Modal';
import Loading from '../Loading';

import getResponseErrors from '../../utils/getResponseErrors';

export interface IDocumento {
  DocCodigo: number;
  DocTipo: number;
  DocData: Date;
  DocDataFormatado: string;
  DocNome: string;
  DocArquivo: string;
  assinado: boolean;
  arquivo_url: string;
  link: string;
  medico: { UsuCodigo: number; UsuNome: string };
}

type PacienteProps = {
  pacCodigo: number;
};

const PacienteDocumento: React.FC<PacienteProps> = ({ pacCodigo }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [showModalAssina, setShowModalAssina] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState<number>();
  const [docGeral, setDocGeral] = useState<IDocumento[]>();
  const [receita, setReceita] = useState<IDocumento[]>();
  const [
    documentoSelecionado,
    setDocumentoSelecionado,
  ] = useState<IDocumento>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IDocumento[]>(
          `/documentos/paciente/${pacCodigo}/`,
        );

        const dadosFormatados = response.data.map(rel => {
          const assinado = !!(rel.arquivo_url && rel.arquivo_url.length > 0);
          return {
            ...rel,
            DocDataFormatado: format(
              parseISO(rel.DocData.toString()),
              'dd/MM/yyyy',
            ),
            assinado,
            link: assinado
              ? rel.arquivo_url
              : `/impressaodocumento/${rel.DocCodigo}/documento`,
          };
        });

        setDocGeral(
          dadosFormatados.filter(
            doc => doc.DocTipo === TIPO_DOCUMENTO.ATESTADO,
          ),
        );
        setReceita(
          dadosFormatados.filter(doc => doc.DocTipo === TIPO_DOCUMENTO.RECEITA),
        );
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (pacCodigo && pacCodigo > 0) {
      loadDados();
    }
  }, [addToast, pacCodigo]);

  const handleDelete = useCallback(
    async (codigo: number, tipo: number) => {
      setLoading(true);
      try {
        await api.delete(`documentos/${codigo}`);

        if (tipo === TIPO_DOCUMENTO.ATESTADO) {
          setDocGeral(state => {
            return state?.filter(doc => doc.DocCodigo !== codigo);
          });
        }
        if (tipo === TIPO_DOCUMENTO.RECEITA) {
          setReceita(state => {
            return state?.filter(doc => doc.DocCodigo !== codigo);
          });
        }
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível excluir o dependente',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleRegistroIncluido = useCallback(
    (dados: IDocumento) => {
      try {
        const dadosFormatados = {
          ...dados,
          DocDataFormatado: format(
            parseISO(dados.DocData.toString()),
            'dd/MM/yyyy',
          ),
          assinado: false,
          link: `/impressaodocumento/${dados.DocCodigo}/documento`,
        };

        if (dados.DocTipo === TIPO_DOCUMENTO.ATESTADO) {
          setDocGeral(state => state?.concat([dadosFormatados]));
        }
        if (dados.DocTipo === TIPO_DOCUMENTO.RECEITA) {
          setReceita(state => state?.concat([dadosFormatados]));
        }
        setShowCadastro(false);
        setTipoSelecionado(undefined);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível excluir o dependente',
          type: 'error',
          description: mensagemErro,
        });
      }
    },
    [addToast],
  );

  const handleAtualizaDadosAssinatura = useCallback(
    (dados: IDocumento) => {
      try {
        const dadosFormatados = {
          ...dados,
          DocDataFormatado: format(
            parseISO(dados.DocData.toString()),
            'dd/MM/yyyy',
          ),
          assinado: true,
          link: dados.arquivo_url,
        };

        if (dados.DocTipo === TIPO_DOCUMENTO.ATESTADO) {
          setDocGeral(state =>
            state
              ?.filter(doc => doc.DocCodigo !== dados.DocCodigo)
              .concat([dadosFormatados]),
          );
        }
        if (dados.DocTipo === TIPO_DOCUMENTO.RECEITA) {
          setReceita(state =>
            state
              ?.filter(doc => doc.DocCodigo !== dados.DocCodigo)
              .concat([dadosFormatados]),
          );
        }
        setShowCadastro(false);
        setTipoSelecionado(undefined);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível atualizar a listagem',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setShowModalAssina(false);
        setDocumentoSelecionado(undefined);
      }
    },
    [addToast],
  );

  return (
    <>
      {loading && <Loading backgroundBlack={false} />}

      {showModalAssina && documentoSelecionado && (
        <Modal
          show={showModalAssina}
          titulo="Assinar Documento com Certificado"
          handleFecharModal={() => {
            setShowModalAssina(false);
            setDocumentoSelecionado(undefined);
          }}
        >
          <AssinaDocumento
            documento={documentoSelecionado}
            handleDadosSalvos={dados => handleAtualizaDadosAssinatura(dados)}
          />
        </Modal>
      )}

      <ContainerDados className="rounded">
        {showCadastro && tipoSelecionado ? (
          <>
            <Button
              type="button"
              variant="outline-primary"
              size="sm"
              className="my-2"
              onClick={() => {
                setShowCadastro(false);
                setTipoSelecionado(undefined);
              }}
            >
              <FaReply size={16} /> Voltar para lista
            </Button>
            <DocumentoCadastro
              docTipo={tipoSelecionado}
              pacCodigo={pacCodigo}
              handleDadosSalvos={handleRegistroIncluido}
            />
          </>
        ) : (
          <Row>
            <Col md={6}>
              <div className="d-flex justify-content-between aling-items-center my-2">
                <h5>Documentos</h5>
                <Button
                  type="button"
                  onClick={() => {
                    setTipoSelecionado(TIPO_DOCUMENTO.ATESTADO);
                    setShowCadastro(true);
                  }}
                  variant="primary"
                  size="sm"
                >
                  Adicionar
                </Button>
              </div>
              {docGeral && docGeral.length > 0 ? (
                <table
                  className="table table-hover table-sm table-font-small"
                  style={{ borderWidth: '0px' }}
                >
                  <thead>
                    <tr className="bg-light text-dark">
                      <th style={{ width: '85px' }}>Data</th>
                      <th>Profissional</th>
                      <th>Título</th>
                      <th style={{ width: '60px', textAlign: 'center' }}>-</th>
                      <th style={{ width: '60px', textAlign: 'center' }}>-</th>
                      <th style={{ width: '60px', textAlign: 'center' }}>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docGeral.map(pag => (
                      <tr
                        key={`agenda-${pag.DocCodigo}`}
                        style={{
                          fontSize: '.7rem',
                          height: '30px',
                        }}
                      >
                        <td>{pag.DocDataFormatado}</td>
                        <td>{pag.medico.UsuNome}</td>
                        <td>{pag.DocNome}</td>
                        <td className="text-center">
                          {!pag.assinado ? (
                            <Button
                              type="button"
                              size="sm"
                              title="Assinar com Certificado"
                              variant="outline-primary"
                              onClick={
                                () => {
                                  setDocumentoSelecionado(pag);
                                  setShowModalAssina(true);
                                }
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            >
                              Assinar
                            </Button>
                          ) : (
                            <FaFileSignature size={18} color={Colors.primary} />
                          )}
                        </td>
                        <td className="text-center">
                          <a href={pag.link} rel="noreferrer" target="_blank">
                            <FaPrint size={16} />
                          </a>
                        </td>
                        <td className="text-center">
                          {!pag.assinado && (
                            <Button
                              type="button"
                              size="sm"
                              variant="outline-primary"
                              onClick={
                                () =>
                                  handleDelete(
                                    pag.DocCodigo,
                                    TIPO_DOCUMENTO.ATESTADO,
                                  )
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            >
                              <FaTrashAlt size={16} />
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Nenhum documento gerado</p>
              )}
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-between aling-items-center my-2">
                <h5>Receitas</h5>
                <Button
                  type="button"
                  onClick={() => {
                    setTipoSelecionado(TIPO_DOCUMENTO.RECEITA);
                    setShowCadastro(true);
                  }}
                  variant="primary"
                  size="sm"
                >
                  Adicionar Receita
                </Button>
              </div>
              {receita && receita.length > 0 ? (
                <table
                  className="table table-hover table-sm table-font-small"
                  style={{ borderWidth: '0px' }}
                >
                  <thead>
                    <tr className="bg-light text-dark">
                      <th style={{ width: '85px' }}>Data</th>
                      <th>Profissional</th>
                      <th style={{ width: '60px', textAlign: 'center' }}>-</th>
                      <th style={{ width: '45px', textAlign: 'center' }}>-</th>
                      <th style={{ width: '45px', textAlign: 'center' }}>-</th>
                      <th style={{ width: '60px', textAlign: 'center' }}>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {receita.map(pag => (
                      <tr
                        key={`receitas-${pag.DocCodigo}`}
                        style={{
                          fontSize: '.7rem',
                          height: '30px',
                        }}
                      >
                        <td>{pag.DocDataFormatado}</td>
                        <td>{pag.medico.UsuNome}</td>
                        <td className="text-center">
                          {!pag.assinado ? (
                            <Button
                              type="button"
                              size="sm"
                              title="Assinar com Certificado"
                              variant="outline-primary"
                              onClick={
                                () => {
                                  setDocumentoSelecionado(pag);
                                  setShowModalAssina(true);
                                }
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            >
                              Assinar
                            </Button>
                          ) : (
                            <FaFileSignature size={18} color={Colors.primary} />
                          )}
                        </td>
                        <td
                          className="text-center"
                          style={{ position: 'relative' }}
                        >
                          <a href={pag.link} rel="noreferrer" target="_blank">
                            <FaPrint size={16} />
                          </a>
                        </td>
                        <td
                          className="text-center"
                          style={{ position: 'relative' }}
                        >
                          <a
                            href={`${pag.link}?vias=2`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <FaPrint size={16} />

                            <FaRegCopy
                              size={10}
                              style={{
                                position: 'absolute',
                                top: '6px',
                                left: '29px',
                              }}
                            />
                          </a>
                        </td>
                        <td className="text-center">
                          {!pag.assinado && (
                            <Button
                              type="button"
                              size="sm"
                              variant="outline-primary"
                              onClick={
                                () =>
                                  handleDelete(
                                    pag.DocCodigo,
                                    TIPO_DOCUMENTO.RECEITA,
                                  )
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            >
                              <FaTrashAlt size={16} />
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Nenhuma receita gerada</p>
              )}
            </Col>
          </Row>
        )}
      </ContainerDados>
    </>
  );
};

export default PacienteDocumento;
