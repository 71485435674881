import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

import { useToast } from './toast';
import { useClinica } from './clinica';

import getResponseErrors from '../utils/getResponseErrors';

export interface User {
  id: number;
  nome: string;
  email: string;
  avatar_url: string;
  tipo: number;
  clinica_id: number;
}

export interface Clinica {
  id: number;
  ativo: boolean;
  bairro: string;
  cep: string;
  cnpj: string;
  cidade: string;
  cidadeCodigo: string;
  codigoRioSulWeb: number;
  complemento: string;
  cor: string;
  email: string;
  emiteNotaFiscal: boolean;
  emiteNFAutomatica: boolean;
  emiteNFCartao: boolean;
  endereco: string;
  facebook: string;
  ie: string;
  instagram: string;
  nome: string;
  nomeAbreviado: string;
  numero: string;
  razaoSocial: string;
  simplesNacional: boolean;
  site: string;
  telefone: string;
  telefone2: string;
  uf: string;
  epresentante: string;
}
interface AuthState {
  token: string;
  user: User;
  clinica: Clinica;
}

interface SignInCredentials {
  email: string;
  password: string;
  codClinica: number;
}

interface AuthContextData {
  user: User;
  clinica: Clinica;
  signIn(credenctials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { imagensClinica } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Laboratorio:token');
    const user = localStorage.getItem('@Laboratorio:user');
    const clinica = localStorage.getItem('@Laboratorio:clinica');
    if (token && user && clinica) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      // api.defaults.headers.codClinica = `1`;

      return { token, user: JSON.parse(user), clinica: JSON.parse(clinica) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password, codClinica }) => {
      try {
        const response = await api.post('sessions', {
          email,
          senha: password,
          clinica_id: codClinica,
        });

        const { token, user, clinica } = response.data;

        if (!user.avatar_url) user.avatar_url = semFoto;

        localStorage.setItem('@Laboratorio:token', token);
        localStorage.setItem('@Laboratorio:user', JSON.stringify(user));
        localStorage.setItem('@Laboratorio:clinica', JSON.stringify(clinica));

        api.defaults.headers.authorization = `Bearer ${token}`;
        api.defaults.headers.codClinica = user.UsuClinica;

        setData({ token, user, clinica });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: mensagemErro,
        });
      }
    },
    [addToast, semFoto],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Laboratorio:token');
    localStorage.removeItem('@Laboratorio:user');
    localStorage.removeItem('@Laboratorio:clinica');
    // localStorage.clear();

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      const userWithAvatar = {
        ...user,
        ...(!user.avatar_url && { avatar_url: semFoto }),
      };
      localStorage.setItem('@Laboratorio:user', JSON.stringify(userWithAvatar));

      setData({
        token: data.token,
        user: userWithAvatar,
        clinica: data.clinica,
      });
    },
    [semFoto, data.token, data.clinica],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        clinica: data.clinica,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser usado com o AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
