import React, { useEffect, useRef, useState } from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { useField } from '@unform/core';

// // import { useModelo, IOptionsModelo } from '../../hooks/modelo';
import { htmlEncode } from '../../utils/funcoes';

// import { Container } from './styles';

interface Props extends IAllProps {
  name: string;
  height: number;
}
// interface IOptionsEditor {
//   type: 'menuitem';
//   text: string;
//   onAction(): void;
// }

const EditorTexto: React.FC<Props> = ({ name, height }) => {
  // const {
  //   buscaOptionsModelo,
  //   optionsModelo,
  //   loading: loadingModelo,
  // } = useModelo();
  const editorRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [editorIsInitialized, setEditorIsInitialized] = useState(false);
  const [texto, setTexto] = useState('');

  useEffect(() => {
    setTexto(defaultValue);
  }, [defaultValue]);

  // useEffect(() => {
  //   if (!optionsModelo) {
  //     buscaOptionsModelo({ ativo: 'true', tipo: '3' });
  //   }
  // }, [buscaOptionsModelo, optionsModelo]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: editorRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  // const modelo: IOptionsModelo[] | undefined = optionsModelo;

  return (
    <>
      <input
        className="d-none"
        value={texto}
        ref={editorRef}
        onChange={evt => setTexto(encodeURI(evt.target.value))}
      />
      {/* {!loadingModelo && ( */}
      <Editor
        apiKey="tti8mmhfb6q4ey6750twym2bm3qahj8d2l87pbit3pt9yf1c"
        onInit={() => {
          if (!editorIsInitialized) {
            setEditorIsInitialized(true);
          }
        }}
        initialValue={editorIsInitialized ? defaultValue : undefined}
        init={{
          height,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code pagebreak',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | pagebreak | myButton1 | menuButton ',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size: 14px; }',
          // setup(editor) {
          // editor.ui.registry.addMenuButton('menuButton', {
          //   text: 'Modelos',
          //   fetch(callback) {
          //     if (modelo) {
          //       const items: IOptionsEditor[] = modelo.map(model => ({
          //         type: 'menuitem',
          //         text: model.text,
          //         onAction() {
          //           editor.insertContent(htmlDecode(model.value));
          //         },
          //       }));
          //       callback(items);
          //     }
          //   },
          // });
          // },
        }}
        onEditorChange={(evt, ed) => setTexto(htmlEncode(ed.getContent()))}
      />
      {/* )} */}
    </>
  );
};

export default EditorTexto;
