import styled from 'styled-components';

export const Skelet = styled.div`
  background-color: #ebebeb;
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
    transform: translateX(-100%);
    animation-name: loading-skeleton;
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  @keyframes loading-skeleton {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
`;
