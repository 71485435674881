import styled from 'styled-components';
import { lighten, darken } from 'polished';

interface BoxProps {
  esmaecer?: boolean;
}

interface LineFormProps {
  invisivel?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  > h2 {
    padding: 10px 0;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.3px;
    text-align: left;
    color: #7a7a7a;
    line-height: 30px;
  }

  div#botoes.orders-new__footer {
    margin-top: 4px;
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 0;
    height: 56px;
    background-color: white;
    border: 1px solid #ddd;
  }

  div.md-layout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex: 1;
    flex: 1;
    margin-top: 7px;
  }

  div.md-flex-40 {
    min-width: 40%;
    -ms-flex: 0 1 40%;
    flex: 0 1 40%;
  }
  div.md-align-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-left: 15px;
  }

  div.md-align-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-right: 15px;
  }

  .orders-new__exam__-button {
    width: 182px !important;
  }
  .btn-septenary {
    font-weight: 400;
    background-color: #c2c2c2;
    text-decoration: none;
  }

  .btn-septenary:hover {
    background-color: ${darken(0.08, '#c2c2c2')};
  }

  .btn-medium {
    width: 100%;
    height: 36px;
    letter-spacing: 0.5px;
    display: -ms-flexbox;
    display: flex;
  }
  .btn-medium,
  .btn-small {
    text-transform: uppercase;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .btn {
    cursor: pointer;
    height: 44px;
    font-size: 13px;
    font-weight: 700;
    border: 0;
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: 0.3s;
    color: #fff;
    border-radius: 6px;
    margin: 0;
  }

  div.endButton {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  div#botoesFim.false {
    display: none !important;
  }

  .btn-primary {
    background-color: #7f1a54;
  }
  .btn-primary:hover {
    background-color: ${lighten(0.07, '#7f1a54')};
  }
`;

export const Box = styled.div<BoxProps>`
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 20px 20px 1px 20px;
  margin-bottom: 40px;
  opacity: ${props => (props.esmaecer ? '0.4' : '1')};

  > span {
    position: absolute;
    right: 20px;
    top: 38px;
    font-size: 12px;
    color: #a80009;
  }

  button.submit {
    height: 36px;
    width: 160px;
    background-color: #7f1a54;
    cursor: pointer;
    height: 44px;
    font-size: 13px;
    font-weight: 700;
    border: 0;
    //transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: 0.3s;
    color: #fff;
    border-radius: 6px;
    margin: 0;
    :hover {
      background-color: ${lighten(0.05, '#7f1a54')};
    }
  }
  button.submit.Confirmado {
    background-color: #62c28e;
  }
`;

export const LineForm = styled.div<LineFormProps>`
  display: flex;

  max-height: ${props => (props.invisivel ? '0' : 'max-content')};
  visibility: ${props => (props.invisivel ? 'hidden' : 'Visible')};
  transition: max-height 0.2s ease-in-out;
  flex-direction: row;
  justify-content: space-between;
  padding: ${props => (props.invisivel ? '0' : '6px 0')};
  h2 {
    font-size: 12px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #7f1a54;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
