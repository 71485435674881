/* eslint-disable no-return-assign */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import qs from 'query-string';
import { format, parseISO, isToday } from 'date-fns';

import { Button, Row, Col, Container } from 'react-bootstrap';
import { useRouteMatch } from 'react-router';

import { FiCheckCircle, FiRotateCcw } from 'react-icons/fi';
import Loading from '../../components/Loading';
import Timer from '../../components/Timer';
import api from '../../services/api';
import {
  formataCPF,
  formataIdade,
  formataTelefone,
  htmlDecode,
} from '../../utils/funcoes';
import EditorTexto from '../../components/EditorTexto';

import { useToast } from '../../hooks/toast';
import { useClinica } from '../../hooks/clinica';
import PacienteCabecalho from '../../components/PacienteCabecalho';
import { IPaciente, IAgendamento } from '../../components/Agendamento';
import { IConfiguracaoAgenda } from '../AgendaCadastro/AgendasCadastro';
import PacienteHistorico from '../../components/PacienteHistorico';
import getResponseErrors from '../../utils/getResponseErrors';
import PacienteDocumento from '../../components/PacienteDocumento';

interface IUrlParams {
  id: string;
}

interface IProcedimento {
  agendamenti_id: number;
  procedimento_id: number;
  valor: number;
  procedimentoAgendado: {
    id: number;
    nome: string;
    duracao: number;
  };
}

interface IAtendimento {
  id?: number;
  profissional_id?: number;
  agendamento_id: number;
  inicio: Date;
  fim?: Date;
  texto: string;
}
interface IAgendamentoRetorno
  extends Omit<
    IAgendamento,
    'agendamentos' | 'atendimentos' | 'faltas' | 'procedimentos'
  > {
  agenda: IConfiguracaoAgenda;
  procedimentos: IProcedimento[];
  atendimento?: IAtendimento;
}

const PacienteAtendimento: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  function useQuery(): URLSearchParams {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const { params } = useRouteMatch<IUrlParams>();
  const { id } = params;
  const { addToast } = useToast();
  const { imagensClinica } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const formRefTexto = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [ageClinica, setAgeClinica] = useState<number | undefined>(undefined);
  const [paciente, setPaciente] = useState<IPaciente | undefined>(undefined);

  const [procedimentos, setProcedimentos] = useState<IProcedimento[]>([]);

  const [atendimento, setAtendimento] = useState<IAtendimento | undefined>(
    undefined,
  );

  const [botaoAtivo, setBotaoAtivo] = useState('');

  const emAtendimento = useMemo(() => {
    return atendimento && atendimento.id && atendimento.id > 0;
  }, [atendimento]);

  const ehDeHoje = useMemo(() => {
    return (
      atendimento &&
      atendimento.inicio &&
      isToday(parseISO(atendimento.inicio.toString()))
    );
  }, [atendimento]);

  const dataInicial = useMemo(() => {
    try {
      if (atendimento) {
        const dtIni = format(
          parseISO(atendimento.inicio.toString()),
          'yyyy-MM-dd HH:mm:ss',
        );
        return dtIni;
      }
    } catch (error) {
      console.log(error);
    }
    return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
  }, [atendimento]);

  const codigoAgenda = useMemo(() => {
    return query.get('agenda') || undefined;
  }, [query]);

  const dataAgenda = useMemo(() => {
    return query.get('data') || undefined;
  }, [query]);

  const somenteLista = useMemo(() => {
    return query.get('somenteLista') === 'true';
  }, [query]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IAgendamentoRetorno>(
          `/atendimentos/${id}/detalhe?integracaoAlvaro=0`,
        );

        if (response.data) {
          setAgeClinica(response.data.clinica_id);
          const dataNasc = parseISO(response.data.cliente.dataNasc.toString());
          setPaciente({
            ...response.data.cliente,
            telefone: formataTelefone(response.data.cliente.telefone),
            celular: formataTelefone(response.data.cliente.celular),
            cpf: formataCPF(response.data.cliente.cpf),
            dataNascFormatada: format(dataNasc, 'dd/MM/yyyy'),
            idade: formataIdade(dataNasc),
            avatar_url: response.data.cliente.avatar_url || semFoto,
          });

          if (response.data.atendimento) {
            setAtendimento(response.data.atendimento);
          }

          setProcedimentos(response.data.procedimentos);
        }
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível buscar o agendamento',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }
    if (id && Number(id) !== 0) loadDados();
  }, [id, addToast, semFoto]);

  const handleCriarAtendimento = async (): Promise<void> => {
    if (paciente && ageClinica) {
      const criaExame: IAtendimento = {
        agendamento_id: Number(id),
        inicio: new Date(),
        texto: '',
      };

      try {
        setLoading(true);
        const response = await api.post('atendimentos', criaExame);
        setAtendimento(response.data);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Erro ao inserir atendimento',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSalvarDados = useCallback(
    async (dados: IAtendimento) => {
      setLoading(true);
      const { id: atendimento_id, texto, fim } = dados;
      const dadosFormatado = { texto, fim: fim || new Date() };

      try {
        const response = await api.put(`atendimentos/${atendimento_id}`, {
          ...dadosFormatado,
        });

        setAtendimento(response.data);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Erro ao salvar texto',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleVoltar = useCallback((): void => {
    const queryParams = qs.parse(location.search);
    history.push({
      pathname: '../agendaatendimento',
      search: qs.stringify({
        ...queryParams,
        data: dataAgenda,
        agenda: codigoAgenda,
        somenteLista,
      }),
    });
  }, [codigoAgenda, location.search, dataAgenda, somenteLista, history]);

  const handleSubmitTexto = useCallback(
    async dadosForm => {
      try {
        if (atendimento && atendimento.id) {
          const dados = {
            ...atendimento,
            texto: dadosForm.editortexto,
          };

          await handleSalvarDados(dados);
        }
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Erro ao salvar texto',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, atendimento, handleSalvarDados],
  );

  const handleFinaliza = useCallback(async () => {
    try {
      if (atendimento && atendimento.id) {
        const dados = {
          ...atendimento,
          texto: formRefTexto.current?.getFieldValue('editortexto'),

          fim: new Date(),
        };

        await handleSalvarDados(dados);

        handleVoltar();
      }
    } catch (error) {
      const mensagem = getResponseErrors(error);
      addToast({
        type: 'error',
        title: 'Erro ao salvar texto',
        description: mensagem,
      });
    }
  }, [addToast, atendimento, handleVoltar, handleSalvarDados]);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading backgroundBlack={false} />}

      <div className="d-flex justify-content-between">
        <h5 className="p-2 text-secondary">ID de Atendimento #{id}</h5>
        <div>
          <Button
            type="button"
            variant="outline-info"
            size="sm"
            className="py-2 border-0"
            onClick={handleVoltar}
          >
            <FiRotateCcw /> Voltar para Agenda
          </Button>
        </div>
      </div>

      <Row>
        <Col md={8}>
          <div className="card mb-2">
            <div className="card-header">
              <h5 className="card-title">Paciente</h5>
            </div>

            <div className="card-body">
              {paciente && (
                <PacienteCabecalho
                  paciente={paciente}
                  pacienteID={paciente.id}
                  // procedimentos={procedimentos}
                  idadeCompleta
                />
              )}
            </div>
          </div>
        </Col>
        <Col>
          <div className="card">
            <div className="card-header">
              <h5 id="topoBusca" className="card-title">
                Procedimentos
              </h5>
            </div>

            <div className="card-body p-0" style={{ minHeight: '88px' }}>
              <div className="table-responsive">
                <table className="datatable table table-sm table-borderless hover-table dataTable no-footer">
                  <tbody>
                    {procedimentos &&
                      procedimentos.length > 0 &&
                      procedimentos.map(pro => (
                        <tr
                          key={pro.procedimento_id}
                          data-id={pro.procedimento_id}
                        >
                          <td>{pro.procedimentoAgendado.nome}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {paciente && botaoAtivo === 'historico' && (
                <PacienteHistorico cliente_id={paciente.id} />
              )}
              {paciente && botaoAtivo === 'atestado' && (
                <PacienteDocumento pacCodigo={paciente.id} />
              )}
            </div>
          </div>
        </Col>
      </Row>
      {emAtendimento && ehDeHoje && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <small className="text-info">Duração da consulta</small>

              <Timer
                id={paciente ? Number(paciente.id) : 0}
                key={`timer-${paciente?.id}`}
                textId={`timer-${paciente?.id}`}
                dataInicial={dataInicial}
                limite={40}
              />
            </div>
            <div>
              <Button
                type="button"
                variant="primary"
                className="mb-2 py-2"
                onClick={handleFinaliza}
                disabled={loading}
              >
                <FiCheckCircle /> Finalizar Atendimento
              </Button>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Prontuário de atendimento</h5>
            </div>

            <div className="card-body p-0">
              <Form
                ref={formRefTexto}
                initialData={{
                  editortexto: htmlDecode(atendimento?.texto || ''),
                }}
                onSubmit={handleSubmitTexto}
              >
                <EditorTexto height={300} name="editortexto" />
                <div className="d-flex w-100 justify-content-end my-1 pr-1">
                  <Button
                    type="submit"
                    variant="primary"
                    className="px-5"
                    disabled={loading}
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}

      {!emAtendimento && (
        <Button
          type="button"
          variant="primary"
          size="sm"
          className="mb-2 py-2"
          onClick={handleCriarAtendimento}
          disabled={loading}
        >
          <FiCheckCircle /> Registrar Atendimento
        </Button>
      )}
    </Container>
  );
};

export default PacienteAtendimento;
