import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { parseISO, addHours, format } from 'date-fns';

import { FiTrash } from 'react-icons/fi';
import { optionsHorasCadastroAgenda } from '../../../../utils/funcoes';

import Toggle from '../../../../components/Exame/Toggle';
import Input from '../../../../components/Input';
import SelectSync from '../../../../components/SelectSync';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useToast } from '../../../../hooks/toast';
import Loading from '../../../../components/Loading';
import api from '../../../../services/api';
import getResponseErrors from '../../../../utils/getResponseErrors';

// import { Container } from './styles';

interface IListagemDiaNaoAgendar {
  id: number;
  data: Date | string;
  horaIni: string;
  horaFim: string;
  obs: string;
}

interface IDiasNaoAgendar extends IListagemDiaNaoAgendar {
  agenda_id: number;
  diaTodo?: string;
}

type Props = {
  agenda_id: number;
};
const DiasNaoAgendar: React.FC<Props> = ({ agenda_id }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [listagemDiaNaoAgendar, setListagemDiaNaoAgendar] = useState<
    IListagemDiaNaoAgendar[]
  >([]);
  const formRefdiasNaoAgendar = useRef<FormHandles>(null);
  const optionsHora = useMemo(() => {
    return optionsHorasCadastroAgenda();
  }, []);

  useEffect(() => {
    async function buscarDias(): Promise<void> {
      try {
        setLoading(true);

        const response = await api.get(`/dianaoagendar/${agenda_id}`);

        const listagemDiasNaoAgendarApi: IListagemDiaNaoAgendar[] = response.data.map(
          (diaNaoAgendar: IListagemDiaNaoAgendar) => {
            const Dia = parseISO(diaNaoAgendar.data.toString());

            return { ...diaNaoAgendar, data: format(Dia, 'dd/MM/yyyy') };
          },
        );

        const listagemDiasNaoAgendarApiFormatada = listagemDiasNaoAgendarApi.map(
          lista => {
            return {
              ...lista,
            };
          },
        );

        setListagemDiaNaoAgendar(listagemDiasNaoAgendarApiFormatada);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dias não agendar',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    buscarDias();
  }, [agenda_id, addToast]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const addDiaNaoAgendar = useCallback(
    async (dadosDiaNaoAgendar: IDiasNaoAgendar) => {
      setLoading(true);
      try {
        const response = await api.post<IDiasNaoAgendar>(
          '/dianaoagendar',
          dadosDiaNaoAgendar,
        );

        const { id, data, horaIni, horaFim, obs } = response.data;
        const Dia = parseISO(data.toString());

        const retornDiaNaoAgendar: IListagemDiaNaoAgendar = {
          id,
          data: format(Dia, 'dd/MM/yyyy'),
          horaIni,
          horaFim,
          obs,
        };

        setListagemDiaNaoAgendar(state => {
          return state
            ? [retornDiaNaoAgendar].concat(
                state.filter(dia => dia.id !== retornDiaNaoAgendar.id),
              )
            : [retornDiaNaoAgendar];
        });

        addToast({ title: 'Exceção na agenda salva', type: 'success' });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível adicionar exceção na agenda',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleSubmitDiasNaoAgendar = useCallback(
    async (dados: IDiasNaoAgendar) => {
      try {
        formRefdiasNaoAgendar.current?.setErrors({});
        const schema = Yup.object().shape({
          data: Yup.date()
            .min(new Date(), 'A data precisa ser maior que a data de hoje')
            .required('informe a data inicial'),
          diaTodo: Yup.boolean(),
          horaIni: Yup.string().when('diaTodo', {
            is: !true,
            then: Yup.string().required('informe a hora inicial'),
          }),
          horaFim: Yup.string().when('diaTodo', {
            is: !true,
            then: Yup.string().required('informe a hora final'),
          }),
          obs: Yup.string().required('informe a observação'),
        });

        const diaTodo = dados.diaTodo ? dados.diaTodo.toString() : 'false';
        const diasNaoAgendarForm = {
          ...dados,
          diaTodo: diaTodo === 'true',
        };

        await schema.validate(diasNaoAgendarForm, {
          abortEarly: false,
        });

        const dadosEnvio: IDiasNaoAgendar = {
          ...diasNaoAgendarForm,
          obs: diasNaoAgendarForm.obs.toUpperCase(),
          agenda_id,
          data: addHours(parseISO(diasNaoAgendarForm.data.toString()), 10),
          horaIni: diasNaoAgendarForm.diaTodo
            ? '00:00'
            : diasNaoAgendarForm.horaIni,
          horaFim: diasNaoAgendarForm.diaTodo
            ? '00:00'
            : diasNaoAgendarForm.horaFim,
          diaTodo: undefined,
        };

        await addDiaNaoAgendar(dadosEnvio);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefdiasNaoAgendar.current?.setErrors(errors);
          return;
        }
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Erro ao salvar dia não agendar',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, addDiaNaoAgendar, agenda_id],
  );

  const handleEnviar = useCallback(() => {
    formRefdiasNaoAgendar.current?.submitForm();
  }, []);

  const deleteDiaNaoAgendar = useCallback(
    async (codigo: number) => {
      setLoading(true);
      try {
        await api.delete(`/dianaoagendar/${codigo}`);
        setListagemDiaNaoAgendar(DiasState =>
          DiasState?.filter(dia => dia.id !== codigo),
        );
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível deletar registro',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      {loading && <Loading />}
      <hr className="mt-1 mb-3" />

      <Row noGutters>
        <Col md={12}>
          <Form
            ref={formRefdiasNaoAgendar}
            initialData={{ diaTodo: false }}
            onSubmit={handleSubmitDiasNaoAgendar}
          >
            <Row className="small">
              <Col md={3} className="form-group">
                <Input
                  name="data"
                  label="Dia não agendar"
                  placeholder="Selecione uma data"
                  type="date"
                />
              </Col>
              <Col md={1} className="form-group">
                <Toggle
                  nome="diaTodo"
                  classSpam={{
                    paddingRight: '10px',
                    marginBottom: '7px',
                    fontWeight: 'bold',
                  }}
                  label="Dia Todo"
                  isDisabled={false}
                />
              </Col>
              <Col md={2} className="form-group">
                <SelectSync
                  name="horaIni"
                  options={optionsHora}
                  handleSelectChange={handleOnChangeVazio}
                  placeholder="00:00"
                  label=" ou De"
                />
              </Col>
              <Col md={2} className="form-group">
                <SelectSync
                  name="horaFim"
                  options={optionsHora}
                  handleSelectChange={handleOnChangeVazio}
                  placeholder="00:00"
                  label="Até"
                />
              </Col>

              <Col md={2} className="form-group">
                <Input
                  name="obs"
                  label="Observação"
                  placeholder="Ex. Viagem, Médico..."
                  type="text"
                  maxLength={100}
                />
              </Col>
              <Col md={2} className="form-group">
                <Button
                  type="button"
                  variant="outline-primary"
                  size="sm"
                  className="mt-3"
                  onClick={() => handleEnviar()}
                >
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <div>
            {listagemDiaNaoAgendar.length > 0 ? (
              <div className="table-responsive">
                <table className="datatable table table-borderless hover-table dataTable no-footer">
                  <thead className="thead-light">
                    <tr>
                      <th>Data</th>
                      <th>Inicio</th>
                      <th>Inicio</th>
                      <th>Obs</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listagemDiaNaoAgendar.map(lista => (
                      <tr
                        key={`diasNaoAgendar-${lista.id}`}
                        style={{ fontSize: 'Small' }}
                      >
                        <td style={{ width: '65px' }}>{lista.data}</td>
                        <td style={{ width: '55px' }}>{lista.horaIni}</td>
                        <td style={{ width: '55px' }}>{lista.horaFim}</td>
                        <td>{lista.obs}</td>
                        <td className="w3-padding-0" style={{ width: '40px' }}>
                          <Button
                            type="button"
                            variant="outline-danger"
                            className="py-0 border-0"
                            title={`${lista.id}`}
                            onClick={() => {
                              deleteDiaNaoAgendar(lista.id);
                            }}
                          >
                            <FiTrash />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h6 className="text-center">
                Nenhuma dia adicionado como exceção.
              </h6>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DiasNaoAgendar;
