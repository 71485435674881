import React, { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import getResponseErrors from '../../utils/getResponseErrors';
import { formataIdade, formataCPF } from '../../utils/funcoes';

import { IDadosRodape } from './index';

export interface IPaciente {
  PacCodigo: number;
  PacNome: string;
  PacDataNasc: Date;
  PacSexo: string;
  PacDataNascFormatada: string;
  PacIdade: string;
  PacCPF: string;
  PacRG: string;
}

export interface IProntuario {
  agendamento: {
    AgeCodigo: number;
    AgeInicio: Date;
    AgeInicioFormatado: string;
    AgeClinica: number;
  };
  paciente: IPaciente;
  agenda: {
    ConCodigo: number;
    ConNome: string;
  };
  agendamentos: {
    AgeInicio: Date;
    AgeInicioFormatado: string;
    PerNome: string;
    PerTipo: number;
    PerTipoDescricao: string;
    procedimentos: string;
  }[];
}

interface IComponentProps {
  AgeCodigo: number;
  handleDadosRodape?(dadosRodape: IDadosRodape): void;
}

const ProntuarioImpresso: React.FC<IComponentProps> = ({
  AgeCodigo,
  handleDadosRodape,
}) => {
  const { addToast } = useToast();
  const [prontuario, setProntuario] = useState<IProntuario>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        const response = await api.get<IProntuario>(
          `/agendamentos/${AgeCodigo}/prontuarioimpresso`,
        );

        const dataNascimento = parseISO(
          response.data.paciente.PacDataNasc.toString(),
        );

        const dadosFormatados: IProntuario = {
          ...response.data,
          agendamento: {
            ...response.data.agendamento,
            AgeInicioFormatado: format(
              parseISO(response.data.agendamento.AgeInicio.toString()),
              'dd/MM/yyyy',
            ),
          },
          paciente: {
            ...response.data.paciente,
            PacDataNasc: dataNascimento,
            PacDataNascFormatada: format(dataNascimento, 'dd/MM/yyyy'),
            PacIdade: formataIdade(dataNascimento, true),
            PacCPF: formataCPF(response.data.paciente.PacCPF),
          },
          agendamentos: response.data.agendamentos.map(age => {
            return {
              ...age,
              AgeInicioFormatado: format(
                parseISO(age.AgeInicio.toString()),
                'dd/MM/yyyy',
              ),
            };
          }),
        };

        setProntuario(dadosFormatados);
        if (handleDadosRodape)
          handleDadosRodape({
            CliCodigo: response.data.agendamento.AgeClinica,
          });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setTimeout(() => {
          window.print();
        }, 2000);
      }
    }
    loadDados();
  }, [AgeCodigo, addToast, handleDadosRodape]);

  return (
    <div style={{ fontSize: '1.2rem' }}>
      {prontuario && (
        <>
          <h5 className="text-center">PRONTUÁRIO DE ATENDIMENTO</h5>
          <p className="text-center mb-3">
            {prontuario.agenda.ConNome}{' '}
            {prontuario.agendamento.AgeInicioFormatado}
          </p>

          <table
            className="table table-hover table-sm table-font-small table-bordered"
            style={{ borderWidth: '0px' }}
          >
            <tbody>
              <tr>
                <td colSpan={3}>
                  <b>NOME: </b> {prontuario.paciente.PacNome}
                </td>
              </tr>
              <tr>
                <td>
                  <b>RG: </b> {prontuario.paciente.PacRG}
                </td>
                <td>
                  <b>CPF: </b> {prontuario.paciente.PacCPF}
                </td>
                <td>
                  <b>SEXO: </b> {prontuario.paciente.PacSexo}
                </td>
              </tr>
              <tr>
                <td>
                  <b>DATA NASC: </b> {prontuario.paciente.PacDataNascFormatada}
                </td>
                <td colSpan={2}>
                  <b>IDADE: </b> {prontuario.paciente.PacIdade}
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ lineHeight: '19px' }}>
                  {prontuario.agendamentos.map(pro => (
                    <span className="pr-2" key={`pro-${pro.AgeInicio}`}>
                      <b>{pro.PerTipoDescricao}:</b> <u>{pro.PerNome}</u> -
                      {pro.AgeInicioFormatado} - {pro.procedimentos}
                    </span>
                  ))}
                </td>
              </tr>
              {[...Array(40)].map((x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={`tr-${i}rewr`}>
                  <td colSpan={3}>
                    <br />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ProntuarioImpresso;
