import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Container, Button } from 'react-bootstrap';

import { useHistory } from 'react-router';
import { addSeconds } from 'date-fns';
import api, { apiGenerica } from '../../services/api';

import Loading from '../../components/Loading';
import { TIPO_USUARIO } from '../../utils/funcoes';

import {
  ContainerQR,
  ContainerText,
  ContainerWhatsapp,
  LandingTitle,
  Lista,
  Status,
} from './styles';
import { useAuth } from '../../hooks/auth';
import Timer from './Timer';
import socket from '../../services/socket';
import FilaWhatsapp from './Fila';

interface IInstanceUser {
  id?: string;
  name?: string;
}
interface IInstanceData {
  instance_key: string;
  phone_connected: boolean;
  webhookUrl: string | null;
  user: IInstanceUser;
}
interface IReturnQRCode {
  error?: boolean;
  message?: string;
  qrcode?: string;
  url?: string;
  validade?: Date;
}
export interface IReturnInstanceInfo {
  error: boolean;
  message: string;
  key?: string;
  webhook?: {
    enabled: boolean;
    webhookUrl: string | null;
  };
  qrcode?: IReturnQRCode;
  instance_data?: IInstanceData;
}
interface IConnectionMessage {
  type: string;
  body: any;
  instanceKey: string;
}

const Whatsapp: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [instanceInfo, setInstanceInfo] = useState<IReturnInstanceInfo>({
    error: true,
    message: 'não conectado',
  });

  const [loading, setLoading] = useState(false);

  const verificaStatus = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IReturnInstanceInfo>('whatsapp/status');
      setInstanceInfo(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleNewMessage = (data: IConnectionMessage): void => {
      if (
        data.instanceKey === instanceInfo.key &&
        ['open', 'close'].includes(data.body.connection)
      )
        verificaStatus();
    };

    socket.on('conexaoWhatsapp', handleNewMessage);
    return () => {
      socket.removeEventListener('conexaoWhatsapp', handleNewMessage);
    };
  }, [instanceInfo.key, verificaStatus]);

  useEffect(() => {
    verificaStatus();
  }, [verificaStatus]);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const handleInitInstance = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IReturnInstanceInfo>(
        'whatsapp/initInstance',
      );
      setInstanceInfo(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLogoutInstance = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IReturnInstanceInfo>(
        'whatsapp/logoutInstance',
      );
      setInstanceInfo(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    async function loadQrCode(): Promise<void> {
      setLoading(true);
      try {
        if (instanceInfo.qrcode?.url !== undefined) {
          const response = await apiGenerica(
            instanceInfo.qrcode?.url,
          ).get<IReturnQRCode>('');
          console.log(response);
          setInstanceInfo(state => {
            return {
              ...state,
              qrcode: {
                ...response.data,
                url: undefined,
                validade: addSeconds(new Date(), 55),
              },
            };
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    if (instanceInfo.instance_data?.phone_connected !== true) loadQrCode();
  }, [instanceInfo]);

  const instanciaConectada = useMemo(() => {
    return (
      instanceInfo.instance_data !== undefined &&
      instanceInfo.instance_data.phone_connected
    );
  }, [instanceInfo.instance_data]);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading backgroundBlack={false} />}
      <div className="bg-white border p-2 mb-2" style={{ minHeight: '400px' }}>
        <h2>Integração Whatsapp</h2>
        <p className="my-4">
          Conecte o whatsapp para envio automático de mensagens. Para conectar
          basta escanear o qrcode.
        </p>
        <Status conectado={instanciaConectada}>
          <Button
            type="button"
            variant="danger"
            // style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
            //  size="sm"
            className={
              !instanceInfo.instance_data ||
              !instanceInfo.instance_data.phone_connected
                ? 'btn btn-sm btn-danger mx-2'
                : 'd-none'
            }
            onClick={handleInitInstance}
          >
            Whatsapp Desconectado
          </Button>

          <Button
            type="button"
            variant="success"
            // style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
            //  size="sm"
            className={
              instanciaConectada ? 'btn btn-sm btn-success mx-2' : 'd-none'
            }
            onClick={handleLogoutInstance}
          >
            Whatsapp Conectado
          </Button>
          <span>
            {!instanceInfo.instance_data ||
            !instanceInfo.instance_data.phone_connected
              ? 'Para conectar, clique no botão acima e escaneie o QRCode'
              : `número conectado: ${
                  instanceInfo.instance_data.user.id?.split(':')[0]
                }`}
          </span>
        </Status>
        {instanceInfo.qrcode &&
          instanceInfo.qrcode.qrcode &&
          instanceInfo.qrcode.qrcode !== '' && (
            <div>
              <ContainerWhatsapp>
                <ContainerText>
                  <LandingTitle>
                    Conecte o WhatsApp no sistema Agenda Fácil
                  </LandingTitle>
                  <div style={{ height: '40px' }}>
                    {instanceInfo.qrcode.validade && (
                      <Timer
                        id={1}
                        dataInicial={instanceInfo.qrcode.validade.toString()}
                        textId="adsasd"
                        tempoRegressivo
                        limit={{ alertAt: 100, dangerAt: 55, stopAt: 0 }}
                        handleAtingiuLimite={
                          () =>
                            setInstanceInfo(state => {
                              return { ...state, qrcode: undefined };
                            })
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    )}
                  </div>
                  <Lista>
                    <li>Abra o WhatsApp no seu celular.</li>
                    <li>
                      <span>
                        Toque em{' '}
                        <strong>
                          <span>Mais opções </span>
                          <span />
                        </strong>{' '}
                        ou{' '}
                        <strong>
                          <span>Configurações</span>
                          <span />
                        </strong>{' '}
                        e selecione <strong>Aparelhos conectados</strong>.
                      </span>
                    </li>
                    <li>
                      <span>
                        Toque em <strong>Conectar um aparelho</strong>
                      </span>
                    </li>
                    <li>
                      Aponte seu celular para esta tela para capturar o código.
                    </li>
                  </Lista>
                </ContainerText>

                <ContainerQR>
                  <div>
                    <img src={instanceInfo.qrcode?.qrcode} alt="whatsapp" />
                  </div>
                </ContainerQR>
              </ContainerWhatsapp>
            </div>
          )}

        {instanciaConectada && <FilaWhatsapp />}
      </div>
    </Container>
  );
};

export default Whatsapp;
