import React, { useState, useEffect } from 'react';

import { Container } from 'react-bootstrap';

// import api from '../../services/api';

import Loading from '../../components/Loading';

const imagemConstrucao =
  'https://app-valim.s3.sa-east-1.amazonaws.com/outros/emConstrucao.jpg';

const EmConstrucao: React.FC = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        // const response = await api.get('sessions/logado');
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    loadDados();
  }, []);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading backgroundBlack={false} />}
      <div
        className="bg-white border p-2 mb-2 text-center"
        style={{ minHeight: '400px' }}
      >
        <h3 className="my-4">RETORNE EM ALGUNS DIAS</h3>
        <h1 className="text-warning">PÁGINA EM CONSTRUÇÃO...</h1>

        <img
          src={imagemConstrucao}
          style={{ width: '100%', maxWidth: '700px' }}
          alt="em construção..."
        />
      </div>
    </Container>
  );
};

export default EmConstrucao;
