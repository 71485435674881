/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import { formataCPF, formatPrice, TIPO_USUARIO } from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';
import { useEspecialidade } from '../../../hooks/especialidade';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import SelectSync, { IOption } from '../../../components/SelectSync';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';

import { arrayGroupBy } from '../../../utils/funcoes';

import getResponseErrors from '../../../utils/getResponseErrors';
import HeaderRelatorio from '../HeaderRelatorio';

interface IRelatorio {
  id: number;
  tipo: string;
  inicio: Date;
  inicioFormatado: string;
  status: number;
  cliente: {
    id: number;
    nome: string;
    cpf: string;
    cnpj: string;
    dataNasc: Date;
    dataNascFormatada?: string;
  };
  procedimento: string;
  convenio_id: number;
  procedimento_valor: number;
  procedimento_valorFormatado: string;
  agenda_id: number;
  agenda_nome: string;
  especialidade_id: number;
  contapr: {
    clinica_id: number;
    valor: number;
    valorPago: number;
    valorFormatado: string;
    valorPagoFormatado: string;
  };
}

interface IRelatorioFormatado {
  grupo: string;
  totalDesconto: number;
  totalDescontoFormatado: string;
  total: number;
  totalFormatado: string;
  totalPago: number;
  totalPagoFormatado: string;
  dados: IRelatorio[];
}

interface ITotais {
  totalDescontoFormatado: string;
  totalFormatado: string;
  totalPagoFormatado: string;
}

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  clinica_id: string;
  convenio_id: string;
  especialidade_id: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

const FechamentoDia: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica, buscaConvenios, optionsConvenio } = useClinica();

  const { optionsEspecialidade, buscaEspecialidade } = useEspecialidade();

  const [optionEspecialidade, setOptionEspecialidade] = useState<IOption[]>([]);

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [convenioSelecionado, setConvenioSelecionado] = useState('');
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorioFormatado[]>();
  const [totais, setTotais] = useState<ITotais>();
  const initialData = {
    dataInicio: format(new Date(), 'yyyy-MM-dd'),
    dataFim: format(new Date(), 'yyyy-MM-dd'),
    clinica_id: user.clinica_id.toString(),
  };
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinicaSelecionada, setClinicaSelecionada] = useState('');

  const exibeTotais = true;

  useEffect(() => {
    async function loadEspecialidades(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadEspecialidades();
  }, [buscaEspecialidade, optionsEspecialidade]);

  useEffect(() => {
    async function Busca(): Promise<void> {
      await buscaConvenios();
    }
    if (!optionsConvenio || optionsConvenio.length === 0) Busca();
  }, [buscaConvenios, optionsConvenio]);

  useEffect(() => {
    try {
      setOptionEspecialidade(optionsEspecialidade.optionEspecialidades);
    } catch (error) {
      addToast({
        title: 'Não foi possível buscar Especialidades',
        type: 'error',
      });
    }
  }, [addToast, optionsEspecialidade]);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          dataFim: Yup.date().required(),
          clinica_id: Yup.string().required(),
          convenio_id: Yup.string().required(),
          especialidade_id: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinicaSelecionada(
          optionsClinica.find(cli => cli.value === dados.clinica_id)?.label ||
            '',
        );

        const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
        const dataFimSelecionada = parseISO(dados.dataFim.toString());

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        });

        let parametrosOpcionais = dados.especialidade_id
          ? `&especialidade_id=${dados.especialidade_id}`
          : '';
        parametrosOpcionais += dados.convenio_id
          ? `&convenio_id=${dados.convenio_id}`
          : '';

        const response = await api.get<IRelatorio[]>(
          `/relatorios/procedimentosrealizadosvalor?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}&clinica_id=${dados.clinica_id}${parametrosOpcionais}`,
        );

        let totalGeral = 0;
        let totalGeralDesconto = 0;
        let totalGeralPago = 0;

        const dadosFormatados = response.data.map(rel => {
          return {
            ...rel,
            inicioFormatado: format(
              parseISO(rel.inicio.toString()),
              'dd/MM/yyyy',
            ),
            procedimento_valorFormatado: formatPrice(rel.procedimento_valor)
              .replace(/\s/g, '')
              .replace('R$', ''),
            contapr: {
              ...rel.contapr,
              valorFormatado: formatPrice(rel.contapr.valor),
              valorPagoFormatado: formatPrice(rel.contapr.valorPago),
            },
            cliente: {
              ...rel.cliente,
              cpf: formataCPF(rel.cliente.cpf),
              dataNascFormatada: format(
                parseISO(rel.cliente.dataNasc.toString()),
                'dd/MM/yyyy',
              ),
            },
          };
        });

        const ordenado = arrayGroupBy(dadosFormatados, i => i.agenda_nome);

        const ordenadoFormatado = ordenado.map(ord => {
          const totalPorAgendamento = Array.from(
            new Set(ord.map(dado => dado.id)),
          ).map(id => {
            return {
              id,
              valorPago: ord.find(dad => dad.id === id)?.contapr.valorPago,
            };
          });

          const totalPago = totalPorAgendamento.reduce((tot, item) => {
            return tot + Number(item.valorPago);
          }, 0);

          totalGeralPago += totalPago;

          const total = ord.reduce((tot, item) => {
            return tot + Number(item.procedimento_valor);
          }, 0);
          totalGeral += total;

          const totalDesconto = total - totalPago;
          totalGeralDesconto += totalDesconto;

          return {
            grupo: ord[0].agenda_nome,
            totalDesconto,
            totalDescontoFormatado: formatPrice(totalDesconto),
            total,
            totalFormatado: formatPrice(total),
            totalPago,
            totalPagoFormatado: formatPrice(totalPago),
            dados: ord,
          };
        });

        setDadosRelatorio(ordenadoFormatado);
        setTotais({
          totalFormatado: formatPrice(totalGeral),
          totalPagoFormatado: formatPrice(totalGeralPago),
          totalDescontoFormatado: formatPrice(totalGeralDesconto),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica],
  );

  const handleChangeConvenio = useCallback(({ label }: IOption) => {
    setConvenioSelecionado(label.toUpperCase());
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading />}
      <div className="d-print-none page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Fechamento por convênio
            </span>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-print-none">
          {optionsClinica.length > 0 && user && (
            <Form
              ref={formRefBusca}
              initialData={initialData}
              onSubmit={handleSubmitBusca}
            >
              <Row>
                <Col md={2}>
                  <Input name="dataInicio" label="De" type="date" required />
                </Col>
                <Col md={2}>
                  <Input name="dataFim" label="Até" type="date" required />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="convenio_id"
                    label="Convênio"
                    options={optionsConvenio}
                    isClearable
                    handleSelectChange={handleChangeConvenio}
                  />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="especialidade_id"
                    label="Especialidade"
                    options={optionEspecialidade}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>

                <Col md={2}>
                  <Select
                    name="clinica_id"
                    label="Clinica"
                    options={optionsClinica}
                    disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                  />
                </Col>
                <Col md={2}>
                  <br />
                  <Button type="submit" size="sm" variant="outline-secondary">
                    BUSCAR
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>

        <HeaderRelatorio
          dataImpressao={dataExibe?.dataHora}
          titulo={`FECHAMENTO CONVÊNIO ${convenioSelecionado} ${
            clinicaSelecionada.length > 0 ? `- ${clinicaSelecionada} -` : '-'
          } ${dataExibe?.dataDiaSemana || ''}`}
        />

        <div className="card-body p-0" style={{ minHeight: '300px' }}>
          <div className="table-responsive">
            <table className="table table-sm hover-table no-footer">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: '80px' }}>Data</th>
                  <th>Paciente</th>
                  <th>CPF</th>
                  <th>Data Nasc.</th>
                  <th>Procedimento</th>
                  <th style={{ minWidth: '115px' }}>Valor</th>
                </tr>
              </thead>
              <tbody>
                {dadosRelatorio &&
                  dadosRelatorio.map(rel => (
                    <React.Fragment key={`pro-${rel.grupo}`}>
                      <tr
                        style={{
                          fontSize: 'Small',
                          height: '30px',
                          fontWeight: 'bold',
                        }}
                      >
                        <td colSpan={exibeTotais ? 5 : 5}>
                          <div className="d-flex justify-content-around">
                            {rel.grupo}{' '}
                            <span>Nº Registros: {rel.dados.length}</span>
                          </div>
                        </td>
                        {exibeTotais && (
                          <>
                            <td style={{ textAlign: 'right' }}>
                              Total: {rel.totalFormatado}
                            </td>
                            {/* <td>Total Pago: {rel.totalPagoFormatado}</td> */}
                          </>
                        )}
                      </tr>
                      {rel.dados.map(relatorio => (
                        <tr
                          key={`proced-${rel.grupo}-${relatorio.id}-${relatorio.procedimento}`}
                          data-id={relatorio.id}
                          style={{ fontSize: '.7rem', height: '30px' }}
                        >
                          <td>{relatorio.inicioFormatado}</td>
                          <td>{relatorio.cliente.nome}</td>
                          <td>{relatorio.cliente.cpf}</td>
                          <td>{relatorio.cliente.dataNascFormatada}</td>
                          <td>{relatorio.procedimento}</td>
                          <td>{relatorio.procedimento_valorFormatado}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                {exibeTotais && dadosRelatorio && dadosRelatorio.length > 0 && (
                  <tr
                    key="fdfdsfsdfdf"
                    style={{
                      fontSize: 'Small',
                      height: '30px',
                      fontWeight: 'bold',
                    }}
                  >
                    <td colSpan={6} style={{ textAlign: 'right' }}>
                      Total Geral: {totais?.totalFormatado}
                    </td>

                    {/* <td colSpan={2} style={{ textAlign: 'right' }}>
                      Total Pago: {totais?.totalPagoFormatado}
                    </td> */}
                  </tr>
                )}
                {!loading && dadosRelatorio?.length === 0 && (
                  <tr>
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                      <h5 className="py-4">
                        Nenhum pagamento registrado com os filtros aplicados
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FechamentoDia;
