import styled, { css } from 'styled-components';
import { Colors } from '../../styles/constants';

interface DiaCabecalhoProps {
  Disponivel?: boolean;
}

export const Container = styled.div`
  background-color: #fff;
  padding: 6px;
  margin: 10px;
  border: 1px solid #d7d7d7;
`;

export const NomeAgenda = styled.h6`
  color: ${Colors.info};
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderAgenda = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0;

  button {
    & + button {
      margin-left: 4px;
    }
  }
`;

export const Calendario = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
`;

export const DiaCabecalho = styled.h5<DiaCabecalhoProps>`
  width: 100%;
  height: 18px;
  padding: 2px;
  margin-bottom: 0;
  text-align: center;
  font-size: 11px;
  text-transform: capitalize;
  border-top: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  border-left: 1px solid #d7d7d7;
  ${props =>
    !props.Disponivel &&
    css`
      background-color: #f6f6f6;
      cursor: not-allowed;
    `}
`;

export const Hora = styled.div`
  width: 45px;

  > div {
    border: 1px solid #d7d7d7;
    border-right-width: 0;
    height: 40px;
    line-height: 40px;

    text-align: center;

    span {
      vertical-align: super;
      font-size: 9px;
    }

    & + div {
      border-top-width: 0;
    }
  }
`;

export const DiasContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Marcacao = styled.div`
  flex: 1;
  padding-bottom: 6px;

  > div {
    border: 1px solid #d7d7d7;

    & + div {
      border-top-width: 0;
    }
  }
`;

export const AgendamentoContainer = styled.div`
  //width: 96%;
  height: 39px;
  background-color: #cec0c0;
  color: #fff;
  position: absolute;
  top: 18px;
  display: flex;
  //align-items: center;
  border-radius: 4px;
  padding: 4px 8px;

  svg {
    margin-right: 6px;
  }
`;

export const CalendarioAddContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  color: #a1a1a1;

  align-items: center;
  justify-content: center;
  //border: 1px solid #d7d7d7;

  /* & + div {
    border-top: 1px solid #d7d7d7;
  } */

  span {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 14px;
    place-content: center;
    align-items: center;
    background-color: #f6f6f6;
    cursor: not-allowed;
  }
`;

export const ButtonAdd = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
    font-weight: bold;
  }
`;

export const AgendaDoDiaContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 20px;
  row-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: auto auto auto;
  }

  @media (max-width: 580px) {
    grid-template-columns: auto auto;
  }
`;

export const AgendaDoDiaButton = styled.button<DiaCabecalhoProps>`
  position: relative;
  cursor: pointer;
  border: 1px solid ${props => (props.Disponivel ? '#28a745' : '#cecece')};
  border-radius: 4px;
  background-color: transparent;
  padding: 4px;
  height: 70px;

  h6 {
    font-size: 0.9rem;
    ${props =>
      props.Disponivel &&
      css`
        font-weight: bold;
      `}
  }

  svg {
    position: absolute;
    top: 5px;
    left: 8px;
  }

  &:hover {
    background-color: #e8e8e8;
    color: #000;
    font-weight: bold;
  }
`;
