import React, { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { FiCheckSquare } from 'react-icons/fi';
import { Row, Col } from 'react-bootstrap';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import getResponseErrors from '../../utils/getResponseErrors';

import { IDadosRodape } from './index';
import { formataCPF, formataIdade } from '../../utils/funcoes';

import { Exame } from './styles';

export interface IPaciente {
  PacCodigo: number;
  PacNome: string;
  PacDataNasc: Date;
  PacSexo: string;
  PacDataNascFormatada: string;
  PacRG: string;
  PacCPF: string;
  PacIdade: string;
}

export interface IPedidoExame {
  PedCodigo: number;
  PedMedUsuCodigo?: number;
  PedPacCodigo: number;
  PedCliCodigo: number;
  PedIndicacao: string;
  paciente: IPaciente;
  medico: {
    UsuCodigo: number;
    UsuNome: string;
    UsuCRM: string;
    UsuConselho: string;
    UsuEspecialidade: string;
  };
  procedimentos: [
    {
      ProNome: string;
      ProCodigo: number;
    },
  ];
}

interface IComponentProps {
  PedCodigo: number;
  handleDadosRodape?(dadosRodape: IDadosRodape): void;
}

// impressao do pedido de exame mesmo sem estar agendado, somente com dados do exame em si
const PedidoExameNaoAgendado: React.FC<IComponentProps> = ({
  PedCodigo,
  handleDadosRodape,
}) => {
  const { addToast } = useToast();
  const [pedido, setPedido] = useState<IPedidoExame>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        const response = await api.get<IPedidoExame>(
          `/pedidoExames/impressao/${PedCodigo}`,
        );

        const dataNascimento = parseISO(
          response.data.paciente.PacDataNasc.toString(),
        );

        const dadosFormatados: IPedidoExame = {
          ...response.data,
          paciente: {
            ...response.data.paciente,
            PacDataNasc: dataNascimento,
            PacDataNascFormatada: format(dataNascimento, 'dd/MM/yyyy'),
            PacIdade: formataIdade(dataNascimento, true),
            PacCPF: formataCPF(response.data.paciente.PacCPF),
          },
        };

        setPedido(dadosFormatados);
        if (handleDadosRodape)
          handleDadosRodape({
            CliCodigo: response.data.PedCliCodigo,
            assinatura: {
              nome: dadosFormatados.medico.UsuNome,
              especialidade: dadosFormatados.medico.UsuEspecialidade,
              documento: dadosFormatados.medico.UsuCRM,
              conselho: dadosFormatados.medico.UsuConselho,
            },
          });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setTimeout(() => {
          window.print();
        }, 2000);
      }
    }
    loadDados();
  }, [PedCodigo, addToast, handleDadosRodape]);

  return (
    <div style={{ fontSize: '1rem' }}>
      {pedido && (
        <>
          <h5 className="text-center mb-5">REQUISIÇÃO DE EXAMES</h5>

          <table
            className="table table-hover table-sm table-bordered"
            style={{ borderWidth: '0px' }}
          >
            <tbody style={{ fontSize: '1rem' }}>
              <tr>
                <td>
                  <b>NOME: </b> {pedido.paciente.PacNome}
                </td>
                <td>
                  <b>SEXO: </b> {pedido.paciente.PacSexo}
                </td>
              </tr>
              <tr>
                <td>
                  <b>RG: </b> {pedido.paciente.PacRG}
                </td>
                <td>
                  <b>CPF: </b> {pedido.paciente.PacCPF}
                </td>
              </tr>
              <tr>
                <td>
                  <b>DATA NASC: </b> {pedido.paciente.PacDataNascFormatada}
                </td>
                <td>
                  <b>IDADE: </b> {pedido.paciente.PacIdade}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>INDICAÇÃO: </b> {pedido.PedIndicacao}
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <Row className="mt-3">
            {pedido.procedimentos.map(pro => (
              <Col md={6} key={`pro-${pro.ProCodigo}`}>
                <Exame>
                  <FiCheckSquare size={22} /> {pro.ProNome}
                </Exame>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default PedidoExameNaoAgendado;
