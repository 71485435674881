/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';

import { format, parseISO } from 'date-fns';

import { Button, Row, Col } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';

import PacienteCadastro from '../PacienteCadastro';
import Modal from '../Modal';

import { formataCPF, formataTelefone, formataIdade } from '../../utils/funcoes';

import { Container } from './styles';
import { IPaciente, IProcedimentoAgendamento } from '../Agendamento';

import getResponseErrors from '../../utils/getResponseErrors';
import { useToast } from '../../hooks/toast';
import { useClinica } from '../../hooks/clinica';

export type IPacienteFormatado = IPaciente;

type PacienteProps = {
  pacienteID?: number;
  paciente: IPacienteFormatado;
  procedimentos?: IProcedimentoAgendamento[];
  exibeEditar?: boolean;
  exibeInfoCartao?: boolean;
  idadeCompleta?: boolean;
  handleAlterouDados?(dados: IPaciente): void;
};

const PacienteCabecalho: React.FC<PacienteProps> = ({
  paciente,
  pacienteID,
  exibeEditar,
  exibeInfoCartao,
  idadeCompleta = false,
  procedimentos,
  handleAlterouDados,
}) => {
  const { addToast } = useToast();
  const { imagensClinica } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const [pacienteFormatado, setPacienteFormatado] = useState<IPaciente>();
  const [nomeProcedimentos, setNomeProcedimentos] = useState<
    string | undefined
  >(undefined);
  const [showModalPacienteCadastro, setShowModalPacienteCadastro] = useState(
    false,
  );

  useEffect(() => {
    const dataNasc = parseISO(paciente.dataNasc.toString());
    setPacienteFormatado({
      ...paciente,
      telefone: formataTelefone(paciente.telefone),
      celular: formataTelefone(paciente.celular),
      cpf:
        paciente.cpf && paciente.cpf.length >= 11
          ? formataCPF(paciente.cpf)
          : 'Não cadastrado',
      dataNascFormatada: format(dataNasc, 'dd/MM/yyyy'),
      idade: formataIdade(dataNasc, idadeCompleta),
      avatar_url:
        paciente.avatar_url && paciente.avatar_url !== ''
          ? paciente.avatar_url
          : semFoto,
    });
  }, [paciente, idadeCompleta, semFoto]);

  useEffect(() => {
    if (procedimentos) {
      const nomes = procedimentos.map(proc => proc.nome).join(',');
      setNomeProcedimentos(nomes);
    }
  }, [procedimentos]);

  const handleAtualizouDadosPaciente = useCallback(
    (dados: IPaciente) => {
      if (dados) {
        try {
          const dataNasc = new Date(dados.dataNasc.toString());
          setPacienteFormatado({
            ...dados,
            dataNasc,
            nome: dados.nome,
            email: dados.email,
            telefone: formataTelefone(dados.telefone),
            celular: formataTelefone(dados.celular),
            cpf:
              dados.cpf && dados.cpf.length === 11
                ? formataCPF(dados.cpf)
                : 'Não cadastrado',
            dataNascFormatada: format(dataNasc, 'dd/MM/yyyy'),
            idade: formataIdade(dataNasc, idadeCompleta),
            avatar_url:
              dados.avatar_url && dados.avatar_url !== ''
                ? dados.avatar_url
                : semFoto,
          });
          if (handleAlterouDados) handleAlterouDados(dados);
          setShowModalPacienteCadastro(false);
        } catch (error) {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Erro ao atualizar state dados paciente',
            type: 'error',
            description: mensagemErro,
          });
        }
      }
    },
    [idadeCompleta, semFoto, addToast, handleAlterouDados],
  );

  return (
    <Container>
      {showModalPacienteCadastro && paciente && (
        <Modal
          show={showModalPacienteCadastro}
          titulo="Cadastro do Paciente"
          handleFecharModal={() => {
            setShowModalPacienteCadastro(false);
          }}
        >
          <PacienteCadastro
            exibeMenu={false}
            id={pacienteID}
            handleRetornaDados={dados => handleAtualizouDadosPaciente(dados)}
          />
        </Modal>
      )}

      {pacienteFormatado && (
        <Row>
          <Col md={12}>
            <div className="d-flex">
              <img
                className="rounded"
                style={{ height: '58px', maxWidth: '60px' }}
                src={pacienteFormatado.avatar_url}
                alt="avatar"
              />
              <div className="w-100 ml-3">
                <Row className=" ">
                  <Col md={6}>
                    <span className="user-name d-flex align-items-center">
                      {pacienteFormatado.nome}
                    </span>
                  </Col>
                  <Col>
                    {exibeEditar && (
                      <Button
                        type="button"
                        variant="outline-primary"
                        style={{
                          paddingTop: '0',
                          paddingBottom: '0',
                          lineHeight: '0.5',
                          border: '0',
                          fontSize: '.86rem',
                        }}
                        onClick={() => setShowModalPacienteCadastro(true)}
                      >
                        <FiEdit size={17} /> Editar
                      </Button>
                    )}
                  </Col>
                </Row>
                <div className="w-100 border-bottom pt-1" />
                <Row className="pt-2">
                  <Col
                    md={6}
                    className="d-flex flex-column justify-content-between"
                  >
                    <span>
                      Nascimento: <b>{pacienteFormatado.dataNascFormatada}</b>
                    </span>
                    <span className="text-muted">
                      {`${pacienteFormatado.sexo}, ${pacienteFormatado.idade}`}
                    </span>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <span>
                      CPF: <b>{pacienteFormatado.cpf}</b>
                    </span>
                    <span>
                      Telefone:{' '}
                      <b>
                        {pacienteFormatado?.celular}{' '}
                        {pacienteFormatado?.telefone}
                      </b>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              {procedimentos && procedimentos.length > 0 && (
                <span>
                  Procedimentos: <b>{nomeProcedimentos}</b>
                </span>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default PacienteCabecalho;
