import React from 'react';

import { FaSpinner } from 'react-icons/fa';

import { Container, Load } from './styles';

type LoadProps = {
  backgroundBlack?: boolean;
};

const Loading: React.FC<LoadProps> = ({ backgroundBlack = true }) => (
  <Container backgroundBlack={backgroundBlack}>
    <Load>
      <FaSpinner size={22} /> carregando...
    </Load>
  </Container>
);

export default Loading;
