import React, { useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

import Input from '../../../../components/Input';
import getResponseErrors from '../../../../utils/getResponseErrors';

type ConferidoProps = {
  UsuCodigo: number;
  conferido: boolean;
};

const Conferido: React.FC<ConferidoProps> = ({ UsuCodigo, conferido }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const name = `nameUsu-${UsuCodigo}`;

  const [loading, setLoading] = useState(false);
  const [conferid, setConferid] = useState(conferido);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    try {
      if (!conferid) {
        await api.post<ConferidoProps>(`users/aniversariantes`, {
          RegUsuCodigo: UsuCodigo,
        });
      } else {
        await api.delete(`users/aniversariantes/${UsuCodigo}`);
      }

      setConferid(!conferid);
    } catch (error) {
      const mensagem = getResponseErrors(error);
      addToast({
        type: 'error',
        title: 'Não foi possível executar esta operação',
        description: mensagem,
      });
    } finally {
      setLoading(false);
    }
  }, [UsuCodigo, addToast, conferid]);

  const handleSubmit = useCallback(() => {
    console.log('clicou');
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input
        label=""
        disabled={loading}
        name={name}
        onClick={handleOnClick}
        type="checkbox"
        onChange={handleSubmit}
        checked={conferid}
        containerStyle={{ borderWidth: 0 }}
      />
    </Form>
  );
};

export default Conferido;
