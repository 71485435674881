import React, { useEffect, useState } from 'react';

import { FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';

import {
  Content,
  Footer,
  FooterBackGround,
  Barra,
  BordaTelefone,
  DadosClinica,
} from './styles';
import api from '../../../services/api';

import getResponseErrors from '../../../utils/getResponseErrors';
import { formataCep, formataTelefone } from '../../../utils/funcoes';

import Loading from '../../Loading';

export interface IClinica {
  CliCodigo: number;
  CliNome: string;
  CliNomeAbreviado: string;
  CliCNPJ: string;
  CliRazaoSocial: string;
  CliIE: string;
  CliSimplesNacional: boolean;
  CliTelefone: string;
  CliTelefone2: string;
  CliTelefoneFormatado: string;
  CliTelefone2Formatado: string;
  CliEmail: string;
  CliFacebook: string;
  CliInstagram: string;
  CliSite: string;
  CliEndereco: string;
  CliNumero: string;
  CliBairro: string;
  CliCidade: string;
  CliCidadeCodigo: string;
  CliCEP: string;
  CliComplemento: string;
  CliUF: string;
  CliContrato: boolean;
  CliProAnamnese: boolean;
  CliProDocumento: boolean;
  CliProAnexo: boolean;
  CliPagamentoAntecipado: boolean;
  CliCor: string;
  CliCodigoRioSulWeb: number;
  CliAtivo: boolean;
  CliNegociacaoCartao: boolean;
  CliMedicoAgendaCompleta: boolean;
  CliOrdenaPorPagamento: boolean;
  CliValidaDadosNF: boolean;
  CliEmiteNFAutomatica: boolean;
  CliEmiteNFCartao: boolean;
}

type ComponentProps = {
  CliCodigo?: number;
  assinatura?: {
    nome: string;
    especialidade: string;
    documento: string;
    conselho: string;
  };
  rodape?: boolean;
  duasVias?: boolean;
};

const LayoutImpressao: React.FC<ComponentProps> = ({
  CliCodigo,
  assinatura,
  rodape = true,
  duasVias,
  children,
}) => {
  const { addToast } = useToast();
  const { imagensClinica } = useClinica();
  const [loading, setLoading] = useState(false);
  const [clinica, setClinica] = useState<IClinica>();

  const logo =
    imagensClinica.find(img => img.nome === 'logo')?.arquivo_url || '';
  const rodapeImpressao =
    imagensClinica.find(img => img.nome === 'rodapeImpressao')?.arquivo_url ||
    '';

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IClinica>(`/clinicas/${CliCodigo}`);
        const dadosFormatados: IClinica = {
          ...response.data,
          CliCEP: formataCep(response.data.CliCEP),
          CliTelefoneFormatado: formataTelefone(response.data.CliTelefone),
          CliTelefone2Formatado: formataTelefone(response.data.CliTelefone2),
        };
        setClinica(dadosFormatados);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Houve um erro ao buscar os dados',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (CliCodigo && rodape) {
      loadDados();
    }
  }, [addToast, CliCodigo, rodape]);

  return (
    <div className="container-fluid p-print-0">
      <Content duasVias={duasVias}>
        {loading && <Loading />}
        <Barra duasVias={duasVias} />
        <div className="container-fluid px-2">
          <br />
          {React.createElement('div', {}, children)}
        </div>
        {rodape && (
          <Footer duasVias={duasVias}>
            {assinatura && (
              <div
                className={`text-center ${duasVias && 'mt-5'} `}
                style={{ marginBottom: '-50px' }}
              >
                ______________________________________
                <br />
                {assinatura.nome}
                <br />
                {assinatura.especialidade && (
                  <>
                    {assinatura.especialidade} <br />
                  </>
                )}
                {assinatura.documento && (
                  <>
                    {assinatura.conselho} {assinatura.documento} <br />
                  </>
                )}
                <br />
              </div>
            )}

            <FooterBackGround
              duasVias={duasVias}
              className="row"
              style={{ backgroundImage: `url(${rodapeImpressao})` }}
            >
              <div className="col-5">
                <DadosClinica>
                  <h2>{clinica?.CliNomeAbreviado}</h2>
                  <p>
                    {clinica?.CliEndereco}, {clinica?.CliNumero}{' '}
                    {clinica?.CliComplemento}, {clinica?.CliBairro}
                    <br />
                    {clinica?.CliCidade}/{clinica?.CliUF} - {clinica?.CliCEP}
                  </p>
                  <div className="d-flex align-items-center">
                    {clinica?.CliTelefoneFormatado && (
                      <>
                        <BordaTelefone>
                          <FiPhone size={14} />
                        </BordaTelefone>
                        <span className="pl-1 mr-3">
                          {clinica.CliTelefoneFormatado}
                        </span>
                      </>
                    )}
                    {clinica?.CliTelefone2Formatado && (
                      <>
                        <FaWhatsapp size={22} />
                        <span className="pl-1">
                          {clinica.CliTelefone2Formatado}
                        </span>
                      </>
                    )}
                  </div>
                </DadosClinica>
              </div>
              <div className="col-3">
                <div className="d-flex align-items-end h-100 text-white pb-1">
                  <div>
                    {clinica?.CliFacebook && (
                      <>
                        <FaFacebook size={22} /> /{clinica?.CliFacebook}
                      </>
                    )}
                    <br />
                    {clinica?.CliInstagram && (
                      <>
                        <FaInstagram size={22} /> /{clinica?.CliInstagram}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex align-items-end h-100 text-white">
                  <div className="text-center pb-1">
                    <img
                      style={{ width: '228px', marginBottom: '2px' }}
                      src={logo}
                      alt={clinica?.CliNomeAbreviado || ''}
                    />
                    <br />
                    {clinica?.CliSite && (
                      <span style={{ fontSize: '1.3rem' }}>
                        {clinica.CliSite}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </FooterBackGround>
          </Footer>
        )}
      </Content>
    </div>
  );
};

export default LayoutImpressao;
