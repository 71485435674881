import styled from 'styled-components';

interface CartaoProps {
  emDia: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  font-size: 13px;
`;

export const PacienteContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  min-height: 55px;
  line-height: 19px;

  img {
    float: left;
    border-radius: 4px;
    margin-right: 8px;
    width: 40px;
  }

  span {
    padding-right: 4px;

    & + span {
      border-left: 1px solid #000;
      padding-left: 4px;
    }
  }
`;

export const CartaoSaude = styled.div<CartaoProps>`
  display: flex;
  align-items: center;
  height: 37px;
  color: ${props => (props.emDia ? '#007bff' : '#dc3545')};

  svg {
    margin-right: 3px;
  }
`;

export const PermissaoAdmin = styled.div`
  min-width: 300px;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);

  > div {
    padding: 20px 12px;
  }

  b {
    display: block;
  }
`;
