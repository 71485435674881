import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Row, Col, Button } from 'react-bootstrap';

import {
  parseISO,
  format,
  addYears,
  differenceInYears,
  isAfter,
} from 'date-fns/esm';
import api from '../../../services/api';
import apiCep from '../../../services/apiCep';

import getValidationErrors from '../../../utils/getValidationErrors';
import {
  formataTelefone,
  replaceCaracteres,
  formataCPF,
  formataCep,
  validarCPF,
} from '../../../utils/funcoes';
import {
  optionsHorasLogin,
  optionsUF,
  IOptions,
  optionsConselhoClasse,
} from '../../../utils/options';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
// import { useMedico } from '../../../hooks/medico';

import { ContainerDados } from './styles';

import Input from '../../../components/Input';
import InputCheck from '../../../components/InputCheck';
import Select from '../../../components/Select';
import SelectSync from '../../../components/SelectSync';
import Avatar from '../../../components/Avatar';
import InputMask from '../../../components/InputMask';
import Loading from '../../../components/Loading';

import { IUsuario, ITipoUsuario } from '../index';
import getResponseErrors from '../../../utils/getResponseErrors';
import { useAuth } from '../../../hooks/auth';

interface IUsuarioCadastro extends IUsuario {
  ativoFormatado?: string;
  senha: string;
  confirmacaoSenha?: string;
}

type UsuarioProps = {
  id?: number;
  tipoUsuario: ITipoUsuario;
  ehAdmin: boolean;
  handleRetornaDados?(dadosUsuario: IUsuario): void;
};

const UsuarioCadastro: React.FC<UsuarioProps> = ({
  id,
  tipoUsuario,
  ehAdmin,
  handleRetornaDados,
}) => {
  const { addToast } = useToast();
  const { optionsClinica } = useClinica();
  // const { limparMedico } = useMedico();
  const { user } = useAuth();
  const [usuario, setUsuario] = useState<IUsuario | undefined>(undefined);
  const formRefUsuario = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [alteraSenha, setAlteraSenha] = useState(false);
  const [optionsHorasLoginInicio, setOptionsHorasLoginInicio] = useState<
    IOptions[]
  >([]);
  const [optionsHorasLoginFim, setOptionsHorasLoginFim] = useState<IOptions[]>(
    [],
  );
  const [limitDate, setLimitDate] = useState<{ max: string; min: string }>();

  const novo = useMemo(() => {
    return !id || id === 0;
  }, [id]);

  useEffect(() => {
    const horas = optionsHorasLogin();
    setOptionsHorasLoginInicio(horas.filter(hor => hor.label !== '23:59'));
    setOptionsHorasLoginFim(horas.filter(hor => hor.label !== '00:00'));
  }, []);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IUsuario>(`/users/${id}/`);

        const dataNasc =
          response.data.dataNasc && response.data.dataNasc.toString().length > 9
            ? parseISO(response.data.dataNasc.toString())
            : new Date();

        const dadosFormatados = {
          ...response.data,
          // ativoFormatado: 'false',
          cpf: formataCPF(response.data.cpf),
          cep: formataCep(response.data.cep),
          clinica_id: response.data.clinica_id.toString(),
          ativoFormatado:
            response.data.ativo.toString() === 'true' ? 'true' : 'false',
          telefone: formataTelefone(response.data.telefone),
        };

        setUsuario(dadosFormatados);

        formRefUsuario.current?.setFieldValue(
          'tipo',
          dadosFormatados.tipo.toString(),
        );
        formRefUsuario.current?.setFieldValue(
          'dataNasc',
          format(dataNasc, 'yyyy-MM-dd'),
        );
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível carregar dados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }
    if (id && id > 0) {
      loadDados();
    }
  }, [addToast, id]);

  useEffect(() => {
    setLimitDate({
      min: format(addYears(new Date(), -150), 'yyyy-MM-dd'),
      max: format(new Date(), 'yyyy-MM-dd'),
    });
  }, []);

  const handleSubmitUsuario = useCallback(
    async (dados: IUsuarioCadastro) => {
      setLoading(true);
      try {
        formRefUsuario.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string()
            .min(10, 'Mínimo 10 caracteres')
            .required('Nome é obrigatório'),
          telefone: Yup.string()
            .nullable()
            .transform(x => x || null)
            .min(10, 'Mínimo 10 caracteres'),
          email: Yup.string().email().required(),
          dataNasc: Yup.date().required(),
          tipo: Yup.string().required(),
          loginIni: Yup.string().min(5).required(),
          loginFim: Yup.string().min(5).required(),
          clinica_id: Yup.string().required(),
          conselhoNumero: Yup.string(),
          conselhoNome: Yup.string(),
          rg: Yup.string(),
          cpf: Yup.string()
            .nullable()
            .transform(x => x || null)
            .min(11, 'CPF Mínimo 11 caracteres'),
          ativoFormatado: Yup.boolean().required(),
          senha: Yup.string(),
          confirmacaoSenha: Yup.string()
            .when('senha', {
              is: (value: string) => value && !!value.length,
              then: Yup.string().min(6, 'Obrigatório mínimo 6 dígitos'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('senha'), null], 'Senhas devem ser iguais'),
          endereco: Yup.string(),
          numero: Yup.string(),
          bairro: Yup.string(),
          cidade: Yup.string(),
          cep: Yup.string()
            .nullable()
            .transform(x => x || null)
            .min(8, 'Mínimo 8 caracteres'),
          complemento: Yup.string(),
          uf: Yup.string(),
        });

        const clienteForm = {
          ...dados,
          nome: dados.nome.toUpperCase(),
          dataNasc: new Date(
            `${dados.dataNasc.toString().replace(/-/g, '/')} 03:00:00`,
          ),
          tipo: Number(dados.tipo),
          ativo: dados.ativoFormatado === 'true',
          clinica_id: Number(dados.clinica_id),
          rg: dados.rg.toUpperCase(),
          endereco: dados.endereco.toUpperCase(),
          numero: dados.numero.toUpperCase(),
          complemento: dados.complemento.toUpperCase(),
          bairro: dados.bairro.toUpperCase(),
          cidade: dados.cidade.toUpperCase(),
          email: dados.email.toLowerCase(),
          uf: dados.uf.toUpperCase(),
          ...(tipoUsuario.ehMedico && {
            conselhoNumero: dados.conselhoNumero.toUpperCase(),
            especialidade: dados.especialidade.toUpperCase(),
          }),
          telefone: replaceCaracteres(dados.telefone),
          cpf: replaceCaracteres(dados.cpf),
          cep: replaceCaracteres(dados.cep),
        };

        await schema.validate(clienteForm, {
          abortEarly: false,
        });

        if (
          Number(clienteForm.loginIni.replace(/:/g, '')) >=
          Number(clienteForm.loginFim.replace(/:/g, ''))
        ) {
          formRefUsuario.current?.setFieldError(
            'loginFim',
            'Horário final não pode ser menor que inicial',
          );
          addToast({
            type: 'error',
            title: 'Horário inválido',
            description: 'Horário final não pode ser menor que inicial',
          });
          return;
        }

        if (clienteForm.cpf && !validarCPF(clienteForm.cpf)) {
          formRefUsuario.current?.setFieldError('cpf', 'CPF inválido');
          addToast({
            type: 'error',
            title: 'CPF inválido',
          });
          return;
        }

        const anos = differenceInYears(new Date(), clienteForm.dataNasc);
        if (anos > 110) {
          formRefUsuario.current?.setFieldError('dataNasc', 'Data inválida');
          addToast({
            type: 'error',
            title: 'Data inválida',
            description: 'Data de nascimento não pode ser maior que 110 anos',
          });
          return;
        }
        if (anos < 0 || isAfter(clienteForm.dataNasc, new Date())) {
          formRefUsuario.current?.setFieldError('dataNasc', 'Data inválida');
          addToast({
            type: 'error',
            title: 'Data inválida',
            description: 'Data de nascimento não pode ser maior que data atual',
          });
          return;
        }

        delete clienteForm.confirmacaoSenha;
        delete clienteForm.ativoFormatado;

        if (novo) {
          const response = await api.post<IUsuario>('/users', clienteForm);

          setUsuario(response.data);

          // if (tipoUsuario.ehMedico) limparMedico();

          if (handleRetornaDados) {
            handleRetornaDados(response.data);
          } else {
            addToast({ title: 'Usuário salvo', type: 'success' });
          }
        } else {
          const response = await api.put<IUsuario>(`/users/${id}`, clienteForm);

          //   if (tipoUsuario.ehMedico) limparMedico();

          if (handleRetornaDados) {
            handleRetornaDados(response.data);
          } else {
            addToast({ title: 'Usuário atualizado', type: 'success' });
          }
        }
      } catch (error: any) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefUsuario.current?.setErrors(errors);
          return;
        }

        if (error?.response?.data?.message.includes('CPF já cadastrado')) {
          formRefUsuario.current?.setFieldError('cpf', 'CPF já cadastrado');
        }

        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível salvar usuário',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [novo, addToast, tipoUsuario.ehMedico, handleRetornaDados, id],
  );

  const handleReturnNewAvatarUrl = useCallback((avatar_url: string) => {
    setUsuario(state => {
      return state && { ...state, avatar_url };
    });
  }, []);

  const handleBuscaCepApi = useCallback(async () => {
    if (formRefUsuario && formRefUsuario.current) {
      const cep = replaceCaracteres(
        String(formRefUsuario.current.getFieldValue('cep')),
      );

      if (!cep || cep.length !== 8) {
        addToast({
          type: 'error',
          title: 'Digite um CEP válido',
        });
        return;
      }

      setLoadingCEP(true);
      try {
        const response = await apiCep.get(`/${cep}/json/`);

        if (response.data.erro) {
          addToast({
            type: 'error',
            title: 'CEP não encontrado',
          });
          return;
        }

        formRefUsuario.current?.setData({
          ...formRefUsuario.current?.getData(),
          bairro: response.data.bairro,
          cidade: response.data.localidade,
          // cidadeCodigo: response.data.ibge,
          endereco: response.data.logradouro,
          uf: response.data.uf,
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possível consultar o cep, confira seus dados',
        });
      } finally {
        setLoadingCEP(false);
      }
    }
  }, [addToast]);

  const handleChangeTipo = useCallback(
    (value: string) => {
      if (value !== '1' && (!id || id === 0)) {
        formRefUsuario.current?.setData({
          ...formRefUsuario.current?.getData(),
          loginIni: '07:00:00',
          loginFim: '19:00:00',
        });
      }
    },
    [id],
  );

  return (
    <>
      {loading && <Loading />}
      <Row>
        <Col md={12}>
          <ContainerDados className="rounded">
            {(novo || usuario) && (
              <Form
                ref={formRefUsuario}
                initialData={
                  usuario
                    ? {
                        ...usuario,
                        loginIni: `${usuario.loginIni}:00`,
                        loginFim: `${usuario.loginFim}:00`,
                      }
                    : { clinica_id: user.clinica_id }
                }
                onSubmit={handleSubmitUsuario}
              >
                <Row>
                  {usuario && (
                    <Col md={4} lg={3}>
                      <Avatar
                        codigo={usuario.id}
                        nome={usuario.nome}
                        avatar_url={usuario.avatar_url}
                        path="usuarios"
                        handleReturnNewAvatarUrl={
                          arquivo => handleReturnNewAvatarUrl(arquivo)
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    </Col>
                  )}
                  <Col>
                    <Row>
                      <Col md={12}>
                        <Input name="nome" label="Nome" maxLength={255} />
                      </Col>

                      <div className="w-100 mt-2" />
                      <Col md={8}>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          maxLength={100}
                        />
                      </Col>

                      <Col md={4}>
                        <InputMask
                          name="telefone"
                          label="Telefone"
                          mask="(99) 99999-9999"
                          placeholder="(00) 00000-0000"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row noGutters className="mt-2">
                  <Col md={3} className="pr-md-2">
                    <SelectSync
                      name="tipo"
                      label="Tipo"
                      options={tipoUsuario.options}
                      handleSelectChange={
                        dados => handleChangeTipo(dados.value)
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                    <div className={tipoUsuario.ehMedico ? '' : 'd-none'}>
                      <Input name="especialidade" label="Especialidade" />
                    </div>
                  </Col>
                  <Col md={3} className="pr-md-2">
                    <Select
                      name="clinica_id"
                      label="Clinica"
                      options={optionsClinica}
                      disabled={!ehAdmin}
                    />
                  </Col>
                  <Col md={3} className="pr-md-2">
                    <Select
                      name="loginIni"
                      label="Login de"
                      options={optionsHorasLoginInicio}
                    />
                  </Col>
                  <Col md={3}>
                    <Select
                      name="loginFim"
                      label="Até"
                      options={optionsHorasLoginFim}
                    />
                  </Col>

                  <div className="w-100 mt-2" />
                  <Col md={3} className="pr-md-2">
                    <InputMask
                      name="cpf"
                      label="CPF"
                      mask="999.999.999-99"
                      placeholder="000.000.000-00"
                      minLength={15}
                    />
                  </Col>
                  <Col md={3} className="pr-md-2">
                    <Input name="rg" label="RG" minLength={7} />
                  </Col>
                  <Col md={3} className="pr-md-2">
                    <Input
                      name="dataNasc"
                      type="date"
                      label="Data de Nascimento"
                      max={limitDate && limitDate.max}
                      min={limitDate && limitDate.min}
                    />
                  </Col>

                  {tipoUsuario.ehMedico && (
                    <>
                      <div className="w-100 mt-2" />
                      <Col md={4} className="pr-md-2">
                        <Select
                          name="conselhoNome"
                          label="Conselho Classe"
                          options={optionsConselhoClasse}
                        />
                      </Col>
                      <Col md={3} className="pr-md-2">
                        <Input
                          name="conselhoNumero"
                          label="Nº Registro"
                          minLength={4}
                        />
                      </Col>
                    </>
                  )}
                  <Col md={1}>
                    <InputCheck
                      name="ativoFormatado"
                      label="Ativo"
                      isDisabled={false}
                      classSpam={{
                        display: 'block',
                        paddingRight: '10px',
                        marginBottom: '9px',
                        fontWeight: 'bold',
                      }}
                    />
                  </Col>
                </Row>

                <h4 className="text-muted border-bottom mt-2">Endereço</h4>
                <Row>
                  <Col md={3} lg={2}>
                    <InputMask
                      name="cep"
                      label="CEP"
                      mask="99999-999"
                      placeholder="00000-000"
                      onBlur={handleBuscaCepApi}
                    />
                  </Col>
                  <Col md={4}>
                    <br />
                    <Button
                      size="sm"
                      variant="outline-secondary mt-1"
                      onClick={handleBuscaCepApi}
                    >
                      {loadingCEP ? 'buscando...' : 'Buscar Endereço'}
                    </Button>
                  </Col>
                  <div className="w-100 mt-2" />
                  <Col md={7} lg={8}>
                    <Input name="endereco" maxLength={200} label="Endereço" />
                  </Col>
                  <Col md={2}>
                    <Input name="numero" maxLength={10} label="Numero" />
                  </Col>
                  <Col md={2}>
                    <Input
                      name="complemento"
                      maxLength={30}
                      label="Complemento"
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={3}>
                    <Input name="bairro" maxLength={30} label="Bairro" />
                  </Col>
                  <Col md={3}>
                    <Input name="cidade" maxLength={30} label="Cidade" />
                  </Col>
                  <Col md={3}>
                    <Select
                      name="uf"
                      label="UF"
                      placeholder="Selecione"
                      options={optionsUF}
                    />
                  </Col>
                </Row>

                {(!usuario || alteraSenha) && (
                  <Row className="mt-2">
                    <Col md={4}>
                      <Input
                        name="senha"
                        label="Senha"
                        type="password"
                        textUppercase={false}
                      />
                    </Col>
                    <Col md={4}>
                      <Input
                        name="confirmacaoSenha"
                        label="Confirmação de Senha"
                        type="password"
                        autoComplete="nope"
                        textUppercase={false}
                      />
                    </Col>
                  </Row>
                )}

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <Button type="submit" size="sm" variant="primary">
                    Salvar dados
                  </Button>

                  {!novo && (
                    <Button
                      type="button"
                      size="sm"
                      variant="outline-secondary"
                      // className="float-right"
                      onClick={() => setAlteraSenha(true)}
                    >
                      Alterar senha
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </ContainerDados>
        </Col>
      </Row>
    </>
  );
};

export default UsuarioCadastro;
