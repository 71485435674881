import React, { useCallback, useState, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Row, Col, Button } from 'react-bootstrap';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import { ContainerDados } from './styles';

import Loading from '../Loading';
import Input from '../Input';
import InputFake from '../InputFake';

import getResponseErrors from '../../utils/getResponseErrors';
import getValidationErrors from '../../utils/getValidationErrors';

import { IDocumento } from './index';

interface IForm {
  password: string;
}

type ComponentProps = {
  documento: IDocumento;
  handleDadosSalvos?(dadosDocumento: IDocumento): void;
};

const AssinaDocumento: React.FC<ComponentProps> = ({
  documento,
  handleDadosSalvos,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const [bloqueiaAssinatura, _] = useState(false);
  // const [bloqueiaAssinatura, _] = useState(
  //   user.UsuCodigo !== documento.medico.UsuCodigo,
  // );

  const handleSubmit = useCallback(
    async (dados: IForm) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('Senha do certificado é obrigatória'),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        const response = await api.post<IDocumento>(
          `/documentos/assinadocumento/${documento.DocCodigo}`,
          {
            ...dados,
          },
        );
        if (handleDadosSalvos)
          handleDadosSalvos({ ...documento, ...response.data });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível salvar os dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, documento, handleDadosSalvos],
  );

  return (
    <>
      {loading && <Loading />}
      {bloqueiaAssinatura ? (
        <h4 className="my-5">
          Somente o profissional que gerou o documento pode assinar
        </h4>
      ) : (
        <ContainerDados className="rounded">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <InputFake
                  label="Profissional"
                  value={documento.medico.UsuNome}
                />
              </Col>
              <Col md={3} className="my-2">
                <Input
                  name="password"
                  label="Senha do Certificado"
                  type="password"
                  required
                />
              </Col>
              <Col md={12}>
                <Button type="submit" variant="success" disabled={loading}>
                  Assinar Documento
                </Button>
              </Col>
            </Row>
          </Form>
        </ContainerDados>
      )}
    </>
  );
};

export default AssinaDocumento;
