import React, { useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container } from 'react-bootstrap';

import PerfilCadastro from './PerfilCadastro';

import Input from '../../components/Input';
import SelectSync from '../../components/SelectSync';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import getValidationErrors from '../../utils/getValidationErrors';

import { optionsTipoPerfil } from '../../utils/options';
import ListaPerfils, { IPerfil, RefObject } from './ListaPerfis';

export interface IFormBusca {
  nome?: string;
  tipo?: string;
}

const Perfil: React.FC = () => {
  const listaRef = useRef<RefObject>(null);
  const [dadosForm, setDadosForm] = useState<IFormBusca | undefined>(undefined);

  const formRefBusca = useRef<FormHandles>(null);

  const [showModalCadastro, setShowModalCadastro] = useState(false);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        nome: Yup.string(),
        tipo: Yup.string(),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });
      setDadosForm(dados);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handleAdd = useCallback(
    (dadosPerfil: IPerfil, perCodigo: number | undefined) => {
      if (listaRef.current) {
        listaRef.current.addPerfil(dadosPerfil, perCodigo);
      }
      setShowModalCadastro(false);
    },
    [],
  );

  return (
    <Container fluid className="mt-2">
      {showModalCadastro && (
        <Modal
          show={showModalCadastro}
          titulo="Cadastro de Perfil"
          handleFecharModal={() => setShowModalCadastro(false)}
          large
        >
          <PerfilCadastro addPerfil={handleAdd} />
        </Modal>
      )}

      <div className="bg-white border p-2 mb-2">
        <div className="d-flex justify-content-between mb-2">
          <h2 id="topoBusca">Perfis</h2>
          <div>
            <Button
              type="button"
              variant="success"
              onClick={() => {
                setShowModalCadastro(true);
              }}
            >
              Novo Perfil
            </Button>
          </div>
        </div>

        <Form ref={formRefBusca} onSubmit={handleSubmitBusca}>
          <div className="row">
            <div className="col-md-4 form-group">
              <Input
                name="nome"
                label="Perfil"
                placeholder="NOME"
                maxLength={100}
                minLength={3}
              />
            </div>
            <div className="col-md-2">
              <SelectSync
                name="tipo"
                label="Tipo"
                placeholder="Selecione"
                isClearable
                handleSelectChange={handleOnChangeVazio}
                options={optionsTipoPerfil}
              />
            </div>
            <div className="col-md-2">
              <div className="input-group-append mt-3">
                <Button type="submit" variant="secondary-outline">
                  BUSCAR
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="bg-white border p-2">
        <ListaPerfils ref={listaRef} dados={dadosForm} />
      </div>
    </Container>
  );
};

export default Perfil;
