import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';

import { IOption } from '../components/SelectSync';
import { useToast } from './toast';
import getResponseErrors from '../utils/getResponseErrors';

interface AgendaContextData {
  loading: boolean;
  retornaDadosAgenda(): Promise<IOption[]>;
  limparAgenda(): void;
}

interface IAgenda {
  id: number;
  nome: string;
}

const AgendaContext = createContext<AgendaContextData>({} as AgendaContextData);

const AgendaProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const retornaDadosAgenda = useCallback(async () => {
    setLoading(true);
    try {
      const optionAgendas = localStorage.getItem('@Laboratorio:optionAgendas');

      if (optionAgendas) {
        return JSON.parse(optionAgendas);
        // setData(JSON.parse(optionAgendas));
      }

      const response = await api.get<IAgenda[]>('agendas');

      const agendaFormatada = response.data.map(con => {
        return { value: con.id.toString(), label: con.nome };
      });

      agendaFormatada.push({ value: '0', label: 'Selecione' });

      localStorage.setItem(
        '@Laboratorio:optionAgendas',
        JSON.stringify(agendaFormatada),
      );
      return agendaFormatada;
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar agendas',
        type: 'error',
        description: mensagemErro,
      });
    } finally {
      setLoading(false);
    }
    return [];
  }, [addToast]);

  const limparAgenda = useCallback(() => {
    localStorage.removeItem('@Laboratorio:optionAgendas');
  }, []);

  return (
    <AgendaContext.Provider
      value={{
        loading,
        retornaDadosAgenda,
        limparAgenda,
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

function useAgenda(): AgendaContextData {
  const context = useContext(AgendaContext);

  if (!context) {
    throw new Error('useAgenda deve ser usado com o AgendaProvider');
  }

  return context;
}

export { AgendaProvider, useAgenda };
