import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';

import api from '../../../services/api';
import { IAgendamentoBusca } from '../index';
import getResponseErrors from '../../../utils/getResponseErrors';

import { useToast } from '../../../hooks/toast';

import Modal from '../../../components/Modal';
import Agendamento from '../../../components/Agendamento';

interface componentProps {
  agendamento: IAgendamentoBusca;
  tipoFalta: boolean;
}

export interface IAgendamentoSelecionado {
  id: number;
  agenda_id: number;
}

const Linha: React.FC<componentProps> = ({ agendamento, tipoFalta }) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [showAgendamento, setShowAgendamento] = useState(false);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<
    IAgendamentoSelecionado | undefined
  >(undefined);

  const handleDeleteFalta = useCallback(
    async (codigo: number) => {
      try {
        setLoading(true);

        await api.delete(`/agendamentos/falta/${codigo}`);
        // setPaginacao(state => {
        //   return state ? { ...state, dataFake: new Date() } : undefined;
        // });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar os dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      {showAgendamento && agendamentoSelecionado && (
        <Modal
          show={showAgendamento}
          titulo="Agendamento"
          handleFecharModal={
            () => {
              setAgendamentoSelecionado(undefined);
              setShowAgendamento(false);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <Agendamento
            agenda_id={agendamentoSelecionado.agenda_id}
            especialidadesAgenda={undefined}
            data={undefined}
            id={agendamentoSelecionado.id}
            handleFecharAgendamento={agenda => {
              setShowAgendamento(false);
              // setPaginacao(state => {
              //   return state ? { ...state, dataFake: new Date() } : undefined;
              // });
            }}
          />
        </Modal>
      )}

      <tr>
        <td>
          <span className="user-name">{agendamento.dataFormatada}</span>
          <br />
          {agendamento.horaFormatada}
        </td>

        <td>
          <h2 className="table-avatar">
            {/* <a href={`/pacientecadastro/${agendamento.id}`}>
            <img
              className="avatar avatar-img"
              src={agendamento.avatar_url}
              alt="avatar"
            />
          </a> */}
            <a href={`/pacientecadastro/${agendamento.id}`}>
              <span className="user-name">{agendamento.cliente.nome}</span>
              <span className="text-muted">
                {agendamento.cliente.telefonesFormatado}
              </span>
            </a>
          </h2>
        </td>
        <td>{agendamento.agenda_nome}</td>
        <td>{agendamento.statusFormatado}</td>
        <td>{agendamento.guia}</td>
        <td>
          <Button
            type="button"
            variant="primary"
            size="sm"
            data-id={agendamento.id}
            onClick={() => {
              setAgendamentoSelecionado({
                id: agendamento.id,
                agenda_id: agendamento.agenda_id,
              });
              setShowAgendamento(true);
            }}
          >
            {!tipoFalta ? 'Agendamento' : 'Reagendar'}
          </Button>
        </td>

        <td>
          {!tipoFalta ? (
            <Button
              type="button"
              variant="outline-secondary"
              size="sm"
              data-id={agendamento.id}
              onClick={() =>
                history.push(
                  `agenda/${agendamento.agenda_id}?data=${agendamento.dataFormatadaApi}`,
                  // eslint-disable-next-line prettier/prettier
                          )}
            >
              Agenda
            </Button>
          ) : (
            <Button
              type="button"
              variant="outline-danger"
              size="sm"
              data-id={agendamento.id}
              onClick={() => handleDeleteFalta(agendamento.id)}
            >
              <FaTrashAlt size={18} />
            </Button>
          )}
        </td>
      </tr>
    </>
  );
};

export default Linha;
