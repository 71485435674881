/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { InputHTMLAttributes, useRef, useEffect, useState } from 'react';
import classnames from 'classnames';

import { useField } from '@unform/core';
import './index.css';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  theme?: string;
  isDisabled: boolean;
  nameclass?: string;
  classSpam?: { [key: string]: string };
}

const Toggle: React.FC<ToggleProps> = ({
  name,
  theme,
  label,
  isDisabled,
  nameclass,
  classSpam = { paddingLeft: '10px' },
  ...restProps
}) => {
  const checkRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [enabled, setEnabled] = useState(defaultValue === 'true');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkRef.current,
      getValue: ref => {
        if (!ref) {
          return false;
        }
        return ref.getAttribute('value');
      },
    });
  }, [fieldName, registerField]);

  const toggleSwitch: React.MouseEventHandler<HTMLDivElement> = evt => {
    evt.persist();
    evt.preventDefault();
    if (isDisabled) return;
    setEnabled(!enabled);
  };

  // Use default as a fallback theme if valid theme is not passed
  const switchTheme = theme || 'default';

  const switchClasses = classnames(`switch switch--${switchTheme}`, nameclass);

  const togglerClasses = classnames(
    'switch-toggle',
    `switch-toggle--${enabled ? 'on' : 'off'}`,
  );

  return (
    <>
      <span style={classSpam}>{label}</span>
      <div
        className={switchClasses}
        value={enabled}
        {...restProps}
        ref={checkRef}
      >
        <div className={togglerClasses} onClick={toggleSwitch} />
      </div>
    </>
  );
};

export default Toggle;
