import styled from 'styled-components';

interface ContainerProps {
  exibe?: boolean;
}

export const Inputs = styled.input`
  width: 100%;
  height: 32px;
  padding: 0;
  display: block;
  flex: 1;
  border: none;
  background: none;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: font-size;
  font-size: 16px;
  color: #303030;
  font-family: inherit;
  line-height: 32px;

  border-bottom: 1px solid #d3d3d3;
  ::-webkit-inner-spin-button {
    height: 44px;
  }
  ::-webkit-calendar-picker-indicator {
  }
  ::placeholder {
    color: #303030;
    font-size: 12px;
  }
`;

export const Label = styled.label<ContainerProps>`
  position: absolute;
  pointer-events: auto;
  top: 0px;
  line-height: 20px;

  opacity: ${props => (props.exibe ? '1' : '0')};
  font-size: 12px;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: opacity;
  :focus-within {
    top: 0;
    opacity: 1;
  }
`;

export const Span = styled.span`
  pointer-events: auto;
  top: 0px;

  opacity: 1;
  font-size: 12px;
`;
