import React, { useCallback, useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';

import { Badge, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaBarcode, FaFilePdf, FaSpinner } from 'react-icons/fa';
import { IExame, IBadge } from '..';
import api from '../../../services/api';
import getResponseErrors from '../../../utils/getResponseErrors';
import { useToast } from '../../../hooks/toast';
import { Spinner } from '../../../components/Agendamento/styles';

// import { Container } from './styles';

type Props = {
  item: IExame;
};

const ItensExame: React.FC<Props> = ({ item }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [clicou, setClicou] = useState(false);

  useEffect(() => {
    setClicou(false);
  }, [item]);

  const montaBadge = useCallback(
    ({ estilo, itens, posicao, titulo }: IBadge) => {
      return (
        <OverlayTrigger
          trigger="click"
          key={posicao}
          placement={posicao}
          overlay={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Popover id="popover-positioned-total">
              <Popover.Title as="h3">{titulo}</Popover.Title>
              <Popover.Content>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                  }}
                >
                  {itens.map(it => (
                    <div className="p-1">{it.codigoAlvaro}</div>
                  ))}
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <Badge style={{ width: '27.05px' }} variant={`${estilo} p-2 mr-2`}>
            {itens.length}
          </Badge>
        </OverlayTrigger>
      );
    },
    [],
  );

  const examesSolitados = item.exameItensRetorno.filter(
    it => it.status.toLocaleUpperCase() === 'SOLICITADO',
  );

  const examesRecebido = item.exameItensRetorno.filter(
    it => it.status.toLocaleUpperCase() === 'RECEBIDO',
  );

  const examesResultado = item.exameItensRetorno.filter(
    it => it.status.toLocaleUpperCase() === 'RESULTADO',
  );

  const examesBaixado = item.exameItensRetorno.filter(
    it => it.status.toLocaleUpperCase() === 'BAIXADO',
  );

  const handleAtualizaRetornoAmostras = useCallback(async () => {
    setLoading(true);
    try {
      if (examesRecebido.length > 0) {
        const codExames = examesRecebido
          .map(it => {
            return `${it.id};${it.codigoAlvaro}`;
          })
          .join();
        await api.get(
          `/laboratorio/atualizaRetornoAmostras?codigoOS=${item.codigoOS}&codExames=${codExames}`,
        );
      }
      if (examesSolitados.length > 0) {
        const codigosBarra = _.uniq(examesSolitados.map(it => it.codigoBarras));

        codigosBarra.forEach(async value => {
          await api.get(
            `/laboratorio/atualizaSituacaoAmostra?codBarras=${value}`,
          );
        });
      }
      if (examesResultado.length > 0) {
        const codExames = examesResultado.map(it => {
          return `${it.id};${it.codigoAlvaro}`;
        });

        const dadosRequest = {
          idOrdemServico: item.codigoOS,
          exames: codExames,
        };
        await api.post(`/laboratorio/baixarResultadoPDF`, dadosRequest);
      }

      if (examesBaixado.length > 0) {
        const codExames = examesBaixado.map(it => {
          return `${it.id};${it.codigoAlvaro}`;
        });

        const dadosRequest = {
          idOrdemServico: item.codigoOS,
          exames: codExames,
        };
        await api.post(`/laboratorio/baixarResultadoPDF`, dadosRequest);
      }
      setClicou(true);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível buscar registro de pagamento',
        type: 'error',
        description: mensagemErro,
      });
    }
    setLoading(false);
  }, [
    addToast,
    examesRecebido,
    examesSolitados,
    examesResultado,
    examesBaixado,
    item.codigoOS,
  ]);

  return (
    <tr key={item.id}>
      <td>{item.cliente_nome}</td>
      <td>{item.codigoOS}</td>
      <td style={{ width: '8%', textAlign: 'center' }}>
        {format(parseISO(item.data.toString()), 'dd/MM/yyyy')}
      </td>
      <td>
        {montaBadge({
          titulo: 'Exames Solicitados',
          estilo: 'primary',
          posicao: 'left',
          itens: item.exameItensRetorno,
        })}{' '}
        {montaBadge({
          titulo: 'Em análise',
          estilo: 'info',
          posicao: 'top',
          itens: item.exameItensRetorno.filter(
            it => it.status !== 'Resultado' && it.status !== 'Baixado',
          ),
        })}{' '}
        {montaBadge({
          titulo: 'Resultados',
          estilo: 'success',
          posicao: 'right',
          itens: item.exameItensRetorno.filter(
            it => it.status === 'Resultado' || it.status === 'Baixado',
          ),
        })}
        <Button
          className={`${
            (examesSolitados.length === 0 &&
              examesResultado.length === 0 &&
              examesRecebido.length === 0 &&
              examesBaixado.length === 0) ||
            (!loading && clicou)
              ? 'd-none'
              : ''
          }`}
          type="button"
          data-id={`recebido-${item.id}`}
          size="sm"
          style={{ padding: '1px 8px' }}
          variant="outline-danger"
          onClick={handleAtualizaRetornoAmostras}
          title="verificarExames"
        >
          {loading ? (
            <Spinner>
              <FaSpinner />
            </Spinner>
          ) : (
            <FaBarcode />
          )}
          <span> verificar Exames</span>
        </Button>
      </td>
      <td>
        {item.link ? (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <FaFilePdf size={20} />
          </a>
        ) : (
          ''
        )}
      </td>
    </tr>
  );
};

export default ItensExame;
