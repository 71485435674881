/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { Colors } from '../../styles/constants';

interface PagoProps {
  ehPagamentoAntecipado?: boolean;
  ehSangria?: boolean;
}

export const Container = styled.div`
  background-color: #fff;
  padding: 6px;
  margin: 10px;
`;
export const ContainerPago = styled.div`
  background-color: #fff;
  padding: 6px;
  margin-right: 10px;
  min-height: 90vh;
`;
export const Pago = styled.li<PagoProps>`
  cursor: pointer;
  p {
    margin-bottom: 0px;

    b {
      color: ${props =>
        props.ehPagamentoAntecipado
          ? Colors.primary
          : props.ehSangria
          ? Colors.danger
          : Colors.success};
    }
  }
`;

export const NomeAgenda = styled.h6`
  color: ${Colors.info};
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div:first-of-type {
    margin-left: 13px;
  }
  h4 {
    margin-right: 15px;
    margin-top: 5px;
  }
`;

export const HeaderCaixa = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  button {
    width: 100%;
  }
`;

export const Calendario = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
`;

export const Hora = styled.div`
  width: 45px;

  > div {
    border: 1px solid #d7d7d7;
    border-right-width: 0;
    height: 40px;
    line-height: 40px;

    text-align: center;

    span {
      vertical-align: super;
      font-size: 9px;
    }

    & + div {
      border-top-width: 0;
    }
  }
`;

export const DiasContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Marcacao = styled.div`
  flex: 1;
  padding-bottom: 6px;

  > div {
    border: 1px solid #d7d7d7;

    & + div {
      border-top-width: 0;
    }
  }
`;

export const AgendamentoContainer = styled.div`
  //width: 96%;
  height: 39px;
  background-color: #cec0c0;
  color: #fff;
  position: absolute;
  top: 18px;
  display: flex;
  //align-items: center;
  border-radius: 4px;
  padding: 4px 8px;

  svg {
    margin-right: 6px;
  }
`;

export const CalendarioAddContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  color: #a1a1a1;

  align-items: center;
  justify-content: center;
  //border: 1px solid #d7d7d7;

  /* & + div {
    border-top: 1px solid #d7d7d7;
  } */

  span {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 14px;
    place-content: center;
    align-items: center;
    background-color: #f6f6f6;
    cursor: not-allowed;
  }
`;

export const ButtonAdd = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
    font-weight: bold;
  }
`;
