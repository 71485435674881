import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, Button } from 'react-bootstrap';

import { FiPlusCircle } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';
import { useEspecialidade } from '../../hooks/especialidade';
// import { useMedico } from '../../hooks/medico';
import { useClinica } from '../../hooks/clinica';
import { useAuth } from '../../hooks/auth';

import SelectSync, { IOption } from '../../components/SelectSync';

import Modal from '../../components/Modal';

import getValidationErrors from '../../utils/getValidationErrors';
import ListaAgendas, { IDadosLista, RefObject } from './ListaAgendas';
import AgendasCadastroPagina, { IConfiguracaoAgenda } from './AgendasCadastro';

import { TIPO_USUARIO } from '../../utils/funcoes';

export interface IFormBusca {
  especialidade_id?: string;
  clinica_id?: string;
  situacao?: string;
  atende?: string;
}

const AgendaCadastro: React.FC = () => {
  const listaRef = useRef<RefObject>(null);
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { optionsEspecialidade, buscaEspecialidade } = useEspecialidade();
  // const { optionsMedico, buscaOptionsMedico } = useMedico();
  const { optionsClinica } = useClinica();
  const [dadosForm, setDadosForm] = useState<IFormBusca | undefined>(undefined);

  const formRefBusca = useRef<FormHandles>(null);

  const [showModalCadastro, setShowModalCadastro] = useState(false);

  const [optionEspecialidade, setOptionEspecialidade] = useState<IOption[]>([]);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const initialData = {
    situacao: 'true',
    clinica: user.clinica_id.toString(),
  };

  useEffect(() => {
    async function loadPerfis(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadPerfis();
  }, [buscaEspecialidade, optionsEspecialidade]);

  // useEffect(() => {
  //   async function loadMedicos(): Promise<void> {
  //     await buscaOptionsMedico();
  //   }
  //   if (!optionsMedico || Object.keys(optionsMedico).length === 0)
  //     loadMedicos();
  // }, [buscaOptionsMedico, optionsMedico]);

  useEffect(() => {
    try {
      setOptionEspecialidade(optionsEspecialidade.optionEspecialidades);
    } catch (error) {
      addToast({
        title: 'Não foi possível buscar perfis',
        type: 'error',
      });
    }
  }, [addToast, optionsEspecialidade]);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        clinica_id: Yup.string(),
        especialidade_id: Yup.string(),
        situacao: Yup.string().nullable(true),
        atende: Yup.string().nullable(true),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });

      setDadosForm(dados);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handleAdd = useCallback(
    async (
      dadosConfiguracaoAgenda: IConfiguracaoAgenda,
      dadosLista: IDadosLista,
      conCodigo: number | undefined,
    ) => {
      if (listaRef.current) {
        await listaRef.current.addAgenda(
          dadosConfiguracaoAgenda,
          dadosLista,
          conCodigo,
        );
        setShowModalCadastro(false);
      }
    },
    [],
  );

  return (
    <Container fluid className="pt-2">
      {showModalCadastro && (
        <Modal
          show={showModalCadastro}
          titulo="Cadastro de Agendas"
          handleFecharModal={() => setShowModalCadastro(false)}
          large
        >
          <AgendasCadastroPagina addAgenda={handleAdd} />
        </Modal>
      )}

      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Agendas
            </span>
          </div>
          <div className="col-auto d-flex justify-content-end align-items-center">
            <Button
              type="button"
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setShowModalCadastro(true);
              }}
            >
              <FiPlusCircle /> Nova Agenda
            </Button>
            {/* <div className="doc-badge me-3 small pl-3">
              Total registros{' '}
              <span className="bg-danger text-white px-2 py-1">
                 {totalRegistros}
              </span>
            </div> */}
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
          >
            <div className="row no-gutters">
              <div className="col-md-2 mr-1">
                <SelectSync
                  name="especialidade_id"
                  label="Especialidade"
                  placeholder="Selecione"
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                  options={optionEspecialidade}
                />
              </div>
              <div className="col-md-2 mr-1">
                <SelectSync
                  name="atende"
                  label="Atende Consultório"
                  placeholder="Selecione"
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                  options={[
                    { label: 'Todos', value: '' },
                    { label: 'Sim', value: 'true' },
                    { label: 'Não', value: 'false' },
                  ]}
                />
              </div>
              <div className="col-md-2 mr-1">
                <SelectSync
                  name="clinica_id"
                  label="Clínica"
                  placeholder="Selecione"
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                  options={optionsClinica}
                  disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                />
              </div>
              <div className="col-md-2 mr-1">
                <SelectSync
                  name="situacao"
                  label="Situação"
                  placeholder="Selecione"
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                  options={[
                    { label: 'Todos', value: '' },
                    { label: 'Ativo', value: 'true' },
                    { label: 'Desativado', value: 'false' },
                  ]}
                />
              </div>
              <div className="col-md-1 pl-2">
                <br />
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  className="mt-1"
                >
                  BUSCAR
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="card-body p-0">
          <ListaAgendas ref={listaRef} dados={dadosForm} />
        </div>
      </div>
    </Container>
  );
};

export default AgendaCadastro;
