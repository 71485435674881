import React, { useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
// import { Container } from './styles';
import { Button, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../../hooks/auth';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import SelectSync, { IOption } from '../../SelectSync';
import TextArea from '../../TextArea';
import IMotivo from '../../../utils/interface/IMotivo';
import Loading from '../../Loading';
import getResponseErrors from '../../../utils/getResponseErrors';
import { TIPO_MOTIVO } from '../../../utils/funcoes';

interface IDadosCancelamento {
  cancelamento_usuario_id: number;
  cancelamento_motivo_id: number;
  cancelamentoDescricao: string;
}

type Props = {
  cprCodigo: number;
  handleFechar(): void;
};

const Excluir: React.FC<Props> = ({ cprCodigo, handleFechar }) => {
  const formRefExcluir = useRef(null);
  const { user } = useAuth();
  const { addToast } = useToast();

  const [optionsMotivo, setOptionsMotivo] = useState<IOption[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadOptionsMotivo(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IMotivo[]>(
          `/motivos?ativo=true&tipo=${TIPO_MOTIVO.EXCLUIR_PAGAMENTO}`,
        );

        const options = response.data.map((op: IMotivo) => ({
          value: op.id.toString(),
          label: op.nome,
        }));

        setOptionsMotivo(options);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar motivos',
          type: 'error',
          description: mensagemErro,
        });
      }
      setLoading(false);
    }
    loadOptionsMotivo();
  }, [addToast]);

  const handleCancelar = useCallback(
    async data => {
      const { motivo, observacao } = data;
      if (motivo === '') {
        addToast({
          type: 'error',
          title: 'Selecione o motivo',
        });
        return;
      }
      if (observacao === '') {
        addToast({
          type: 'error',
          title: 'Observação é um campo obrigatório',
        });
        return;
      }
      setLoading(true);
      try {
        const dadosCancelamento: IDadosCancelamento = {
          cancelamento_usuario_id: user.id,
          cancelamento_motivo_id: motivo,
          cancelamentoDescricao: observacao,
        };
        await api.delete<boolean>(`/pagamento/excluir/${cprCodigo}`, {
          data: dadosCancelamento,
        });
        addToast({
          type: 'success',
          title: 'Registro excluído com sucesso',
        });
        handleFechar();
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        // const statusErro = error?.response?.status;
        // if (statusErro === 403) {
        //   setExibePermissaoAdmin(true);
        //   setMensagemPermissaoAdmin(mensagemErro);
        // }
        addToast({
          type: 'error',
          title: 'Erro ao excluir pagamento',
          description: mensagemErro,
        });
      }
      setLoading(false);
    },
    [addToast, cprCodigo, handleFechar, user.id, user.nome],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <>
      {loading && <Loading backgroundBlack={false} />}
      <Form ref={formRefExcluir} onSubmit={handleCancelar}>
        <Row className="no-gutters">
          <Col md="12" lg="12" className="form-group pr-2">
            <SelectSync
              name="motivo"
              label="Motivo"
              placeholder="Selecione"
              isClearable={false}
              handleSelectChange={handleOnChangeVazio}
              options={optionsMotivo || [{ label: '', value: '' }]}
            />
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col md="12" lg="12" className="form-group pr-2">
            <TextArea name="observacao" label="Observação" rows={3} />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button type="submit" className="mt-2" size="sm">
            Salvar Exclusão
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Excluir;
