import React, { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import getResponseErrors from '../../utils/getResponseErrors';

import { IDadosRodape } from './index';

interface IDocumento {
  DocCodigo: number;
  DocCliCodigo: number;
  DocNome: string;
  DocData: Date;
  DocTipo: number;
  DocTexto: string;
  medico: {
    UsuCodigo: number;
    UsuNome: string;
    UsuCRM: string;
    UsuConselho: string;
    UsuEspecialidade: string;
  };
}

interface IComponentProps {
  DocCodigo: number;
  duasVias?: boolean;
  handleDadosRodape?(dadosRodape: IDadosRodape): void;
  handleTerminouBusca?(): void;
}

const Documento: React.FC<IComponentProps> = ({
  DocCodigo,
  duasVias,
  handleDadosRodape,
  handleTerminouBusca,
}) => {
  const { addToast } = useToast();
  const [documento, setDocumento] = useState<IDocumento>();
  const [zoom, setZoom] = useState(() => {
    return duasVias ? { zoom: '80%' } : {};
  });

  const handleHTML = useCallback((texto: string) => {
    return texto
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
  }, []);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      try {
        const response = await api.get<IDocumento>(`/documentos/${DocCodigo}`);

        const dadosFormatados = {
          ...response.data,
          DocTexto: handleHTML(response.data.DocTexto),
        };

        setDocumento(dadosFormatados);
        if (handleDadosRodape)
          handleDadosRodape({
            CliCodigo: response.data.DocCliCodigo,
            assinatura: {
              nome: response.data.medico.UsuNome,
              especialidade: response.data.medico.UsuEspecialidade,
              documento: response.data.medico.UsuCRM,
              conselho: response.data.medico.UsuConselho,
            },
          });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        if (handleTerminouBusca) handleTerminouBusca();
      }
    }
    loadDados();
  }, [DocCodigo, addToast, handleHTML, handleDadosRodape, handleTerminouBusca]);

  return (
    <>
      {documento && (
        <div
          style={zoom}
          dangerouslySetInnerHTML={{ __html: documento.DocTexto }}
        />
      )}
    </>
  );
};

export default Documento;
