import React, { useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErrors';
import getResponseErrors from '../../../utils/getResponseErrors';
import { optionsConselhoClasse, optionsUF } from '../../../utils/options';
import SelectSync from '../../../components/SelectSync';
import Select from '../../../components/Select';
import AsyncSelect from '../../../components/SelectAsync';

export interface IMedico {
  id: number;
  nome: string;
  numeroConselho: string;
  nomeConselho: string;
  uf: string;
}

type Props = {
  medico?: IMedico;
  setShowModal(values: boolean): void;
};

const MedicoCadastro: React.FC<Props> = ({ medico, setShowModal }) => {
  const { addToast } = useToast();
  const medCodigo = medico ? medico.id : undefined;

  const formRefMedico = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [medicoUnificar, setMedicoUnificar] = useState<number | undefined>(
    undefined,
  );

  const addMedico = useCallback(
    async (dadosMedico: IMedico, codigoMedico: number | undefined) => {
      setLoading(true);

      try {
        if (codigoMedico) {
          await api.put<IMedico>(`/medico/${codigoMedico}`, dadosMedico);
        } else {
          await api.post<IMedico>('/medico', dadosMedico);
        }

        addToast({ title: 'Médico salvo', type: 'success' });
        setShowModal(false);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível salvar médico',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setShowModal],
  );

  const handleSubmitMedico = useCallback(
    async (dados: IMedico) => {
      try {
        formRefMedico.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string()
            .min(3, 'Mínimo 3 caracteres')
            .required('Nome é obrigatório'),
          numeroConselho: Yup.string()
            .min(3, 'Mínimo 3 caracteres')
            .required('Nome é obrigatório'),
          nomeConselho: Yup.string()
            .min(3, 'Mínimo 3 caracteres')
            .required('Nome é obrigatório'),
          uf: Yup.string()
            .min(2, 'Mínimo 2 caracteres')
            .required('Nome é obrigatório'),
        });

        const { id, nome, nomeConselho, numeroConselho, uf } = dados;

        const medicoForm = {
          id,
          nome,
          nomeConselho,
          numeroConselho,
          uf,
        };

        await schema.validate(medicoForm, {
          abortEarly: false,
        });

        await addMedico(
          {
            ...medicoForm,
          },
          medCodigo,
        );
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefMedico.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar médico',
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, addMedico, medCodigo],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handleSelectChange = useCallback(({ value }) => {
    if (value && value !== '') {
      setMedicoUnificar(Number(value));
    }
  }, []);

  const handleUnificar = useCallback(async () => {
    if (medCodigo === medicoUnificar)
      addToast({
        title: 'Unificar Médico',
        type: 'error',
        description: 'Mesmo médico selecionado.',
      });

    setLoading(true);

    try {
      const dadosUnificar = { idUnificar: medicoUnificar };
      await api.put(`/medico/unificar/${medCodigo}`, dadosUnificar);

      addToast({ title: 'Médico unificado com sucesso', type: 'success' });
    } catch (error) {
      const mensagem = getResponseErrors(error);
      addToast({
        type: 'error',
        title: 'Não foi possível unificar médico',
        description: mensagem,
      });
    } finally {
      setLoading(false);
    }
  }, [medCodigo, medicoUnificar, addToast]);

  return (
    <>
      {loading && <Loading />}
      <div className="container">
        <Row noGutters>
          <Col md={12}>
            <Form
              ref={formRefMedico}
              initialData={medico}
              onSubmit={handleSubmitMedico}
            >
              <Row>
                <Col md={12} className="form-group pr-2">
                  <Input name="nome" label="Nome" maxLength={100} />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="form-group pr-2">
                  <Select
                    name="nomeConselho"
                    label="Conselho Classe"
                    options={optionsConselhoClasse}
                  />
                </Col>
                <Col md={4} className="pr-md-2">
                  <Input
                    name="numeroConselho"
                    label="Nº Registro"
                    minLength={4}
                  />
                </Col>
                <Col md={4} className="form-group pr-2">
                  <SelectSync
                    name="uf"
                    label="UF"
                    placeholder="Selecione"
                    handleSelectChange={handleOnChangeVazio}
                    options={optionsUF}
                    isDisabled={medCodigo !== undefined}
                  />
                </Col>
              </Row>

              <Row className={`${medCodigo === undefined ? 'd-none' : ''}`}>
                <Col md={6} className="form-group pr-2">
                  <AsyncSelect
                    urlApi="medico/combo?termoBusca="
                    name="AgeMedico"
                    isDisabled={medCodigo === undefined}
                    label="Medico Unificar"
                    placeholder="Digite para buscar"
                    handleSelectChange={handleSelectChange}
                    isClearable
                  />
                </Col>
                <Col md="6">
                  <div className="light-grey text-left pt-3">
                    <>
                      <Button
                        type="button"
                        variant="primary"
                        onClick={() => handleUnificar()}
                        disabled={loading}
                      >
                        Unificar
                      </Button>
                    </>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center border-top p-1">
                <Col md="12">
                  <div className="light-grey text-left pt-3">
                    <>
                      <Button
                        type="button"
                        variant="success"
                        className="mt-2"
                        onClick={() => formRefMedico.current?.submitForm()}
                        disabled={loading}
                      >
                        Salvar dados
                      </Button>
                    </>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MedicoCadastro;
