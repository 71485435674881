import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { BiPrinter } from 'react-icons/bi';
import { useClinica } from '../../../hooks/clinica';

import { RelatorioHeader } from './styles';

type ComponentProps = {
  dataImpressao?: string;
  titulo: string;
};

const HeaderRelatorio: React.FC<ComponentProps> = ({
  dataImpressao,
  titulo,
}) => {
  const { imagensClinica } = useClinica();

  const logo =
    imagensClinica.find(img => img.nome === 'logoRelatorio')?.arquivo_url || '';

  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="bg-white border p-2 my-3">
        <img src={logo} style={{ width: '200px' }} alt="Logo Clinica" />{' '}
        {dataImpressao && `Impresso em ${dataImpressao}`}
      </div>

      <RelatorioHeader>
        {titulo.length > 0 && `${titulo}`}

        <Button
          size="sm"
          variant="outline-secodary"
          onClick={handlePrint}
          title="Imprimir"
        >
          <BiPrinter size={19} />
        </Button>
      </RelatorioHeader>
    </>
  );
};

export default HeaderRelatorio;
