const optionsSexo = [
  {
    value: 'FEMININO',
    label: 'FEMININO',
  },
  {
    value: 'MASCULINO',
    label: 'MASCULINO',
  },
];

const optionsEscolaridade = [
  {
    value: 'FUNDAMENTAL',
    label: 'FUNDAMENTAL',
  },
  {
    value: 'MÉDIO',
    label: 'MÉDIO',
  },
  {
    value: 'SUPERIOR',
    label: 'SUPERIOR',
  },
];

const optionsEstCivil = [
  {
    value: 'CASADO(A)',
    label: 'CASADO(A)',
  },
  {
    value: 'COMPANHEIRO(A)',
    label: 'COMPANHEIRO(A)',
  },
  {
    value: 'DIVORCIADO(A)',
    label: 'DIVORCIADO(A)',
  },
  {
    value: 'SEPARADO(A)',
    label: 'SEPARADO(A)',
  },
  {
    value: 'SOLTEIRO(A)',
    label: 'SOLTEIRO(A)',
  },
  {
    value: 'VIÚVO(A)',
    label: 'VIÚVO(A)',
  },
];

const optionsUF = [
  { value: 'AC', label: 'ACRE' },
  { value: 'AL', label: 'ALAGOAS' },
  { value: 'AP', label: 'AMAPÁ' },
  { value: 'AM', label: 'AMAZONAS' },
  { value: 'BA', label: 'BAHIA' },
  { value: 'CE', label: 'CEARÁ' },
  { value: 'DF', label: 'DISTRITO FEDERAL' },
  { value: 'ES', label: 'ESPÍRITO SANTO' },
  { value: 'GO', label: 'GOIÁS' },
  { value: 'MA', label: 'MARANHÃO' },
  { value: 'MT', label: 'MATO GROSSO' },
  { value: 'MS', label: 'MATO GROSSO DO SUL' },
  { value: 'MG', label: 'MINAS GERAIS' },
  { value: 'PA', label: 'PARÁ' },
  { value: 'PB', label: 'PARAÍBA' },
  { value: 'PR', label: 'PARANÁ' },
  { value: 'PE', label: 'PERNAMBUCO' },
  { value: 'PI', label: 'PIAUÍ' },
  { value: 'RJ', label: 'RIO DE JANEIRO' },
  { value: 'RN', label: 'RIO GRANDE DO NORTE' },
  { value: 'RS', label: 'RIO GRANDE DO SUL' },
  { value: 'RO', label: 'RONDÔNIA' },
  { value: 'RR', label: 'RORAIMA' },
  { value: 'SC', label: 'SANTA CATARINA' },
  { value: 'SP', label: 'SÃO PAULO' },
  { value: 'SE', label: 'SERGIPE' },
  { value: 'TO', label: 'TOCANTIS' },
];

const optionsVencimentoCartao = [
  {
    value: '5',
    label: '5',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '30',
    label: '30',
  },
];

const optionsTipoCartao = [
  {
    value: '1',
    label: 'NORMAL',
  },
  {
    value: '2',
    label: 'EMPRESA',
  },
  {
    value: '3',
    label: 'NORMAL 2022',
  },
  {
    value: '4',
    label: 'EMPRESA 2022',
  },
  {
    value: '5',
    label: 'EMPRESARIAL',
  },
];

const optionsTipoUsuario = [
  {
    value: '0',
    label: 'TODOS',
  },
  {
    value: '1',
    label: 'ADMINISTRADOR',
  },
  {
    value: '2',
    label: 'GERENTE',
  },
  {
    value: '3',
    label: 'ATENDENTE/CAIXA',
  },
  {
    value: '4',
    label: 'ATENDENTE',
  },
  {
    value: '5',
    label: 'MÉDICO',
  },
];

const optionsTipoPerfil = [
  { label: 'MÉDICO', value: '2' },
  { label: 'EXAME', value: '1' },
  { label: 'DENTISTA', value: '3' },
];

const optionsTipoModelo = [
  { label: 'ATENDIMENTO', value: '3' },
  { label: 'DOCUMENTO', value: '4' },
  { label: 'RECEITA', value: '1' },
  { label: 'RECIBO', value: '2' },
];

const optionsTipoMensagem = [
  { label: 'CONFIRMACAO WHATSAPP', value: '100' },
  { label: 'CONFIRMACAO EMAIL', value: '110' },
  { label: 'COBRANCA WHATSAPP', value: '200' },
  { label: 'COBRANCA EMAIL', value: '210' },
  { label: 'ANIVERSARIO WHATSAPP', value: '300' },
  { label: 'ANIVERSARIO EMAIL', value: '310' },
];

const optionsTipoContratoMedico = [
  {
    label: 'Por Hora',
    value:
      'O CONTRATANTE compromete-se remunerar o CONTRATADO pelos serviços prestados aos seus usuários [VALOR_CONTRATO] ([VALOR_EXTENSO]) por hora de atendimento, sendo acordado [QUANTIDADE_ATENDIMENTO] pacientes por hora, preferencialmente em dinheiro, mediante recibo.',
  },
  {
    label: 'Por Atendimento',
    value:
      'O CONTRATANTE compromete-se remunerar o CONTRATADO pelos serviços prestados aos seus usuários [VALOR_CONTRATO] ([VALOR_EXTENSO]) por atendimento, preferencialmente em dinheiro, mediante recibo.',
  },
  {
    label: 'Por Percentual',
    value:
      'O CONTRATANTE compromete-se remunerar o CONTRATADO pelos serviços prestados aos seus usuários [VALOR_CONTRATO]% do faturamento, preferencialmente em dinheiro, mediante recibo.',
  },
];

const optionsTipoPagarOutrasClinicas = [
  {
    label: 'TODOS',
    value: '0',
  },
  {
    label: 'CONSULTAS E EXAMES',
    value: '1',
  },
  {
    label: 'CARTÃO + SAÚDE',
    value: '2',
  },
];

const optionsConselhoClasse = [
  {
    value: 'CRM',
    label: 'CRM (Conselho Regional de Medicina)',
  },
  {
    value: 'CRO',
    label: 'CRO (Conselho Regional de Odontologia)',
  },
  {
    value: 'CRP',
    label: 'CRP (Conselho Regional de Psicologia)',
  },
  {
    value: 'CRF',
    label: 'CRF (Conselho Regional de Farmácia)',
  },
  {
    value: 'CRN',
    label: 'CRN (Conselho Regional de Nutrição)',
  },
  {
    value: 'COREN',
    label: 'COREN (Conselho Regional de Enfermagem)',
  },
  {
    value: 'Crefito',
    label: 'Crefito (Conselho Regional Fisioterapia Terapia Ocupacional)',
  },
  {
    value: 'Crefono',
    label: 'Crefono (Conselho Regional de Fonoaudiologia)',
  },
];

export interface IOptions {
  value: string;
  label: string;
  isDisabled?: boolean;
}

function optionsHorasLogin(): IOptions[] {
  const horas = [];
  // eslint-disable-next-line no-plusplus
  for (let h = 0; h < 23; h++) {
    const hora = `${`00${h}`.substr(-2)}:00`;
    horas.push({ label: hora, value: `${hora}:00` });
    // const horaMeia = `${`00${h}`.substr(-2)}:30`;
    // horas.push({ label: horaMeia, value: horaMeia });
  }
  horas.push({ label: '23:59', value: '23:59:00' });
  return horas;
}

export type pathToUpload = 'usuarios' | 'pacientes' | 'cartoes' | 'outros';

export {
  optionsSexo,
  optionsEscolaridade,
  optionsEstCivil,
  optionsUF,
  optionsVencimentoCartao,
  optionsTipoCartao,
  optionsHorasLogin,
  optionsTipoUsuario,
  optionsTipoPerfil,
  optionsTipoModelo,
  optionsTipoMensagem,
  optionsTipoContratoMedico,
  optionsTipoPagarOutrasClinicas,
  optionsConselhoClasse,
};
