import styled from 'styled-components';

interface CartaoProps {
  emDia: boolean;
}

export const Container = styled.div`
  width: 100%;
  font-size: 13px;
`;

export const CartaoSaude = styled.div<CartaoProps>`
  display: flex;
  align-items: center;
  height: 37px;

  > a {
    color: ${props => (props.emDia ? '#007bff' : '#dc3545')};
  }

  svg {
    margin-right: 3px;
  }
`;

export const PermissaoAdmin = styled.div`
  min-width: 300px;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);

  > div {
    padding: 20px 12px;
  }

  b {
    display: block;
  }
`;

export const Spinner = styled.span`
  svg {
    animation: fa-spin 2s infinite linear;
    @keyframes fa-spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
`;
