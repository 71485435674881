import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO, isBefore, startOfToday } from 'date-fns';
import { FiArrowUpCircle } from 'react-icons/fi';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';
import SelectSync from '../../components/SelectSync';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import Agendamento from '../../components/Agendamento';
import Paginacao from '../../components/Paginacao';

import Linha from './Linha';
import Skeleton from './Skeleton';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAgenda } from '../../hooks/agenda';

import getResponseErrors from '../../utils/getResponseErrors';

import {
  optionsStatusAgendamento,
  formataTelefone,
  STATUS_AGENDA,
} from '../../utils/funcoes';

export interface IAgendamento {
  id: number;
  chave: string;
  agenda_id: number;
  inicio: Date;
  fim: Date;
  dataFormatada: string;
  horaFormatada: string;
  origem: number;
  status: number;
  confirmado: number;
  statusFormatado: string;
  cliente: {
    nome: string;
    telefone: string;
    celular: string;
    email: string;
    telefonesFormatado: string;
  };
  especialidade_nome: string;
  dataUltimaNotificacao?: Date;
  dataUltimaNotificacaoFormatado: string;
  textoMensagem: string;
}

interface IAgendamentoReturnApi {
  data: IAgendamento[];
  count: number;
}

export interface IAgendamentoSelecionado {
  id: number;
  agenda_id: number;
}

interface IFormBusca {
  agenda_id: number;
  inicio: Date;
}

interface IPaginacao {
  agenda_id?: number;
  inicio: Date;
  dataFake: Date;
  limite: number;
  pagina: number;
}

export interface IOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

const AgendaEnvioConfirmacao: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRefBusca = useRef<FormHandles>(null);

  const [showAgendamento, setShowAgendamento] = useState(false);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<
    IAgendamentoSelecionado | undefined
  >(undefined);
  const [agendamentos, setAgendamentos] = useState<IAgendamento[] | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [paginacao, setPaginacao] = useState<IPaginacao>();
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );
  const { retornaDadosAgenda } = useAgenda();
  const [optionsAgenda, setOptionsAgenda] = useState<IOption[]>([]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      const options = await retornaDadosAgenda();
      setOptionsAgenda(options);
    }
    loadDados();
  }, [retornaDadosAgenda]);

  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const initialData = useMemo(() => {
    let inicio = format(new Date(), 'yyyy-MM-dd');
    if (query.get('data') && query.get('data')?.length === 10) {
      inicio = format(parseISO(`${query.get('data')} 03:00:00`), 'yyyy-MM-dd');
    }

    const agenda_id = query.get('agenda') || 0;

    return { inicio, agenda_id };
  }, [query]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          agenda_id: Yup.number()
            .nullable()
            .transform(x => x || null),
          inicio: Yup.date().required('Início precisa ser uma data'),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setAgendamentos(undefined);

        const { inicio, agenda_id } = dados;

        const inicioAge = parseISO(inicio.toString());

        if (isBefore(inicioAge, startOfToday())) {
          addToast({
            title: 'Confirmação não está disponível para datas passadas',
            type: 'error',
            description: 'A data mínima é o dia atual',
          });
          return;
        }

        setPaginacao({
          ...(agenda_id.toString().length > 0
            ? { agenda_id: Number(agenda_id) }
            : {}),
          inicio: parseISO(inicio.toString()),
          dataFake: new Date(),
          pagina: 1,
          limite: 20,
        });
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        }
      }
    },
    [addToast],
  );

  useEffect(() => {
    formRefBusca.current?.submitForm();
  }, []);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        if (!paginacao) return;

        let parametros = '';
        const { inicio, agenda_id } = paginacao;
        if (agenda_id) parametros += `&ConCodigo=${agenda_id}`;

        const InicioFormatado = format(inicio, 'yyyy-MM-dd');

        const response = await api.get<IAgendamentoReturnApi>(
          `/agendamentos/buscaparaconfirmacao?&pagina=${paginacao.pagina}&limite=${paginacao.limite}&inicio=${InicioFormatado}${parametros}`,
        );
        const { data, count } = response.data;

        setTotalRegistros(count);

        const agendamentosFormatados = data.map(age => {
          const inicioAge = parseISO(age.inicio.toString());

          const dataUltimaNotificacaoFormatado = age.dataUltimaNotificacao
            ? format(
                parseISO(age.dataUltimaNotificacao.toString()),
                'dd/MM/yyyy HH:mm',
              )
            : '';

          const telefonesFormatado =
            age.cliente.telefone && age.cliente.telefone.length > 0
              ? `${formataTelefone(age.cliente.celular)} - ${formataTelefone(
                  age.cliente.telefone,
                )}`
              : formataTelefone(age.cliente.celular);

          return {
            ...age,
            inicio: inicioAge,
            dataFormatada: format(inicioAge, 'dd/MM/yyyy'),
            horaFormatada: `${format(inicioAge, 'HH:mm')} - ${format(
              parseISO(age.fim.toString()),
              'HH:mm',
            )}`,
            dataUltimaNotificacaoFormatado,
            statusFormatado:
              optionsStatusAgendamento.find(sta =>
                age.status === STATUS_AGENDA.AGENDADO
                  ? sta.value === (age.confirmado + 1 >= 2 ? 2 : 1).toString()
                  : sta.value === age.status.toString(),
              )?.label || 'vazio',
            cliente: {
              ...age.cliente,
              telefonesFormatado,
            },
          };
        });

        setAgendamentos(agendamentosFormatados);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar agendamentos',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }

    if (paginacao) loadDados();
  }, [addToast, paginacao]);

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading />}

      {showAgendamento && agendamentoSelecionado && (
        <Modal
          show={showAgendamento}
          titulo="Agendamento"
          handleFecharModal={
            () => {
              setAgendamentoSelecionado(undefined);
              setShowAgendamento(false);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <Agendamento
            agenda_id={agendamentoSelecionado.agenda_id}
            especialidadesAgenda={undefined}
            data={undefined}
            id={agendamentoSelecionado.id}
            handleFecharAgendamento={agenda => {
              setShowAgendamento(false);
              setPaginacao(state => {
                return state ? { ...state, dataFake: new Date() } : undefined;
              });
            }}
          />
        </Modal>
      )}

      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Envio de Confirmação de Agendamentos
              {/* <Button
                type="button"
                variant="outline-black"
                size="sm"
                className="border-0"
                onClick={() => history.push('/agendas')}
              >
                Voltar para agendas
              </Button> */}
            </span>
          </div>
          <div className="col-auto d-flex justify-content-end">
            <div className="doc-badge me-3 small">
              Total registros{' '}
              <span className="bg-danger text-white px-2 py-1">
                {totalRegistros}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
          >
            <Row noGutters>
              <Col sm={6} md={3} lg={2} className="pr-1">
                <Input name="inicio" label="Data" type="date" />
              </Col>
              <Col sm={12} md={4} lg={2} className="pr-1">
                <SelectSync
                  name="agenda_id"
                  label="Agenda"
                  handleSelectChange={handleOnChangeVazio}
                  options={optionsAgenda}
                />
              </Col>
              <Col sm={12} md={3} lg={1} className="pr-1">
                <Button
                  type="submit"
                  size="sm"
                  className="mt-3"
                  variant="primary"
                >
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="datatable table table-borderless hover-table dataTable no-footer">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: '108px' }}>Data</th>
                  <th>Paciente</th>
                  <th>Agenda</th>
                  <th>Status</th>
                  <th style={{ width: '130px' }}>-</th>
                  <th style={{ width: '110px' }}>-</th>
                </tr>
              </thead>
              <tbody>
                {agendamentos &&
                  !loading &&
                  agendamentos.map(age => (
                    <Linha agendamento={age} key={age.id} />
                  ))}
                {loading && (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </>
                )}
                <tr>
                  <td colSpan={6} />
                </tr>
              </tbody>
            </table>

            {(!agendamentos || agendamentos.length === 0) && !loading && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: '250px' }}
              >
                <h5>
                  Envie uma mensagem de confirmação para o paciente através do
                  Whatsapp.
                </h5>
              </div>
            )}
          </div>

          {paginacao && (
            <Paginacao
              limitePagina={paginacao?.limite || 20}
              paginasVizinhas={2}
              totalRegistros={totalRegistros}
              paginaInicio={paginacao?.pagina}
              handleProximaPagina={handleProximaPagina}
            />
          )}

          {agendamentos && (
            <a
              href="#topoBusca"
              className="btn btn-primary"
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '35px',
                opacity: 0.8,
              }}
            >
              <FiArrowUpCircle size={20} />
            </a>
          )}
        </div>
      </div>
    </Container>
  );
};

export default AgendaEnvioConfirmacao;
