import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { FaTrashAlt, FaWhatsapp } from 'react-icons/fa';
import { Button, Col, Row } from 'react-bootstrap';

import { useToast } from '../../../hooks/toast';

import getResponseErrors from '../../../utils/getResponseErrors';

import Input from '../../../components/Input';
import Loading from '../../../components/Loading';
import Paginacao from '../../../components/Paginacao';
import PageInfo from '../../../components/PageInfo';

import {
  formataTelefone,
  optionsTipoMensagem,
  TIPO_USUARIO,
} from '../../../utils/funcoes';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useClinica } from '../../../hooks/clinica';
import getValidationErrors from '../../../utils/getValidationErrors';
import SelectSync from '../../../components/SelectSync';
import Select from '../../../components/Select';
import { ItemLinha, ItemLista } from './styles';

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  clinica_id: string;
  tipoMensagem: string;
}

interface IPaginacao {
  dataInicio: Date;
  dataFim: Date;
  clinica_id: number;
  tipoMensagem: string;
  limite: number;
  pagina: number;
}

interface IFilaWhatsapp {
  id: number;
  numeroTo: string;
  mensagem: string;
  dataEnvio: Date;
  dataEnvioFormatada?: string;
  tabela: string;
  codigoTabela: number;
  clinica_id: number;
  created_at: Date;
}

const FilaWhatsapp: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica } = useClinica();
  const formRefBusca = useRef<FormHandles>(null);

  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );

  const [itensFila, setItensFila] = useState<IFilaWhatsapp[] | undefined>(
    undefined,
  );

  const [loading, setLoading] = useState(false);

  const initialData = useMemo(() => {
    const dataInicio = new Date();
    const dataFim = new Date();

    const clinica_id = user.clinica_id.toString();

    return {
      dataInicio: format(dataInicio, 'yyyy-MM-dd'),
      dataFim: format(dataFim, 'yyyy-MM-dd'),
      clinica_id,
      tipoMensagem: '',
      submitAutomatico: true,
    };
  }, [user.clinica_id]);

  const [paginacao, setPaginacao] = useState<IPaginacao | undefined>(() => {
    if (initialData?.submitAutomatico) {
      return {
        dataInicio: parseISO(`${initialData.dataInicio} 03:00:00`),
        dataFim: parseISO(`${initialData.dataFim} 03:00:00`),
        clinica_id: Number(initialData.clinica_id),
        tipoMensagem: '',
        pagina: 1,
        limite: 50,
      };
    }
    return undefined;
  });

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        if (!paginacao) return;
        const {
          dataInicio,
          dataFim,
          tipoMensagem,
          clinica_id,
          limite,
          pagina,
        } = paginacao;
        const dataInicioFormatada = format(dataInicio, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFim, 'yyyy-MM-dd');

        const clinicaBusca = clinica_id ? `&clinica_id=${clinica_id}` : '';

        setItensFila(undefined);

        const response = await api.get<{
          data: IFilaWhatsapp[];
          count: number;
        }>(
          `/filawhatsapp/busca?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}&tipoMensagem=${tipoMensagem}${clinicaBusca}&limite=${limite}&pagina=${pagina}`,
        );
        const dadosApi: IFilaWhatsapp[] = response.data.data.map(fila => {
          return {
            ...fila,
            dataEnvioFormatada: format(
              parseISO(fila.dataEnvio.toString()),
              'dd/MM/yyyy',
            ),
            tabela:
              optionsTipoMensagem.find(op => op.value === fila.tabela)?.label ||
              '',
            numeroTo: formataTelefone(
              fila.numeroTo.substring(2, fila.numeroTo.length),
            ),
          };
        });
        setItensFila(dadosApi);
        setTotalRegistros(
          response.data.count === 0 ? undefined : response.data.count,
        );
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível carregar dados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }

    if (paginacao) loadDados();
  }, [paginacao, addToast]);

  // const handleSalvaLembrete = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const dados = {
  //       LemCprCodigo: contaPR.CprCodigo,
  //       LemMetodo: 'Whatsapp',
  //     };
  //     await api.post(`/pagamento/lembrete`, dados);
  //     setDataUltimoEnvioFormatado(format(new Date(), 'dd/MM/yyyy HH:mm'));
  //   } catch (error) {
  //     const mensagemErro = getResponseErrors(error);
  //     addToast({
  //       title: 'Não foi possível salvar a lembrete',
  //       type: 'error',
  //       description: mensagemErro,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [addToast, contaPR.CprCodigo]);

  // const handleEnviaLembrete = useCallback((contapr: IListagemVencendo) => {
  //   const textoDecode = htmlDecode(contapr.textoMensagem || '');
  //   const textoMensagem = encodeURIComponent(textoDecode);

  //   const linkWhatsapp = `https://api.whatsapp.com/send?phone=55${contapr?.celular}&text=${textoMensagem}`;
  //   window.open(linkWhatsapp, '_blank');
  // }, []);

  const informacoesPagina = `<b>Descrição:</b> Exibe a listagem de mensagens de whatsapp pendentes de envio dentro de um determinado período. `;

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        dataInicio: Yup.date().required(),
        dataFim: Yup.date().required(),
        clinica_id: Yup.string().nullable(true),
        tipoMensagem: Yup.string(),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });

      const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
      const dataFimSelecionada = parseISO(dados.dataFim.toString());

      setPaginacao({
        dataInicio: dataInicioSelecionada,
        dataFim: dataFimSelecionada,
        clinica_id: Number(dados.clinica_id),
        tipoMensagem: dados.tipoMensagem,
        pagina: 1,
        limite: 50,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  const handleEnviaMensagem = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        const retornoMensagem = await api.put<IFilaWhatsapp>(
          `/filawhatsapp/send/${id}`,
        );

        setItensFila(state => {
          if (!state) return undefined;
          return state.filter(item => item.id !== id);
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível enviar mensagem',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleDeleteMensagem = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        const retornoMensagem = await api.delete<number | null | undefined>(
          `/filawhatsapp/${id}`,
        );

        if (
          retornoMensagem.data !== null &&
          retornoMensagem.data !== undefined &&
          retornoMensagem.data > 0
        )
          setItensFila(state => {
            if (!state) return undefined;
            return state.filter(item => item.id !== id);
          });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível enviar mensagem',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      {loading && <Loading />}
      <div className="d-print-none bg-white border p-2 mb-2">
        <div className="p-2 mb-2 d-flex justify-content-between">
          <h2 id="topoBusca">Fila de mensagens pendentes do whatsapp</h2>
          <PageInfo
            descricao={informacoesPagina}
            permissao={TIPO_USUARIO.SUPERVISOR}
          />
        </div>
        {optionsClinica.length > 0 && user && (
          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
          >
            <Row>
              <Col md={2}>
                <Input name="dataInicio" label="De" type="date" />
              </Col>
              <Col md={2}>
                <Input name="dataFim" label="Até" type="date" />
              </Col>
              <Col md={2}>
                <Select
                  name="tipoMensagem"
                  label="Tipo"
                  options={optionsTipoMensagem}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChange={() => {}}
                />
              </Col>
              <Col md={2}>
                <SelectSync
                  name="clinica_id"
                  label="Clinica"
                  options={optionsClinica}
                  isDisabled
                  // isDisabled={user.UsuTipo !== TIPO_USUARIO.ADMINISTRADOR}
                  // isClearable={user.UsuTipo === TIPO_USUARIO.ADMINISTRADOR}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  handleSelectChange={() => {}}
                />
              </Col>
              <Col md={2}>
                <br />
                <Button type="submit" size="sm" variant="outline-secondary">
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      {itensFila ? (
        <>
          <b>Total de {totalRegistros} registros</b>
          <Row noGutters className="bg-light py-2 border-top border-bottom">
            <Col md={2} lg={2}>
              <b>Número</b>
            </Col>
            <Col md={5} lg={6}>
              <b>mensagem</b>
            </Col>
            <Col md={2} lg={1}>
              <b>DataEnvio</b>
            </Col>
            <Col md={2} lg={2}>
              <b>Tipo</b>
            </Col>
            <Col md={1} lg={1}>
              -
            </Col>
          </Row>
          {itensFila.map(item => (
            <ItemLinha
              className="row no-gutters"
              key={`fila-${item.id}`}
              data-id={item.id}
            >
              <div className="col-md-2 col-lg-2">
                <ItemLista>{item.numeroTo}</ItemLista>
              </div>
              <div className="col-md-5 col-lg-6">
                <ItemLista>{item.mensagem}</ItemLista>
              </div>
              <div className="col-md-2 col-lg-1">
                <ItemLista>{item.dataEnvioFormatada}</ItemLista>
              </div>
              <div className="col-md-2 col-lg-2">
                <ItemLista>{item.tabela}</ItemLista>
              </div>
              <div className="col-md-1 col-lg-1">
                <ItemLista>
                  <Button
                    type="button"
                    variant="success"
                    size="sm"
                    className="ml-1"
                    data-id={item.id}
                    onClick={() => handleEnviaMensagem(item.id)}
                  >
                    <FaWhatsapp size={16} />
                  </Button>

                  <Button
                    type="button"
                    variant="outline-danger"
                    size="sm"
                    className="ml-1"
                    data-id={item.id}
                    onClick={() => handleDeleteMensagem(item.id)}
                  >
                    <FaTrashAlt size={16} />
                  </Button>
                </ItemLista>
              </div>
            </ItemLinha>
          ))}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '250px' }}
        >
          <h5>
            Envie uma mensagem de lembrete de vencimento para o paciente através
            do Whatsapp.
          </h5>
        </div>
      )}

      <Paginacao
        limitePagina={paginacao?.limite || 20}
        paginasVizinhas={2}
        totalRegistros={totalRegistros}
        paginaInicio={paginacao?.pagina}
        handleProximaPagina={handleProximaPagina}
      />
    </>
  );
};

export default FilaWhatsapp;
