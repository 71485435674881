import React, { useState } from 'react';

import IDadosAgendamento from '../../../components/Pagamento/IDadosAgendamento';

import Modal from '../../../components/Modal';
import Agendamento from '../../../components/Agendamento';

interface componentProps {
  agendamento: IDadosAgendamento;
  handleClick(): void;
}

export interface IAgendamentoSelecionado {
  id: number;
  agenda_id: number;
}

const LinhaAgendamentos: React.FC<componentProps> = ({
  agendamento,
  handleClick,
}) => {
  const [showAgendamento, setShowAgendamento] = useState(false);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<
    IAgendamentoSelecionado | undefined
  >(undefined);

  return (
    <>
      {showAgendamento && agendamentoSelecionado && (
        <Modal
          show={showAgendamento}
          titulo="Agendamento"
          handleFecharModal={
            () => {
              setAgendamentoSelecionado(undefined);
              setShowAgendamento(false);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <Agendamento
            agenda_id={agendamentoSelecionado.agenda_id}
            especialidadesAgenda={undefined}
            data={undefined}
            id={agendamentoSelecionado.id}
            handleFecharAgendamento={agenda => {
              setShowAgendamento(false);
              // setPaginacao(state => {
              //   return state ? { ...state, dataFake: new Date() } : undefined;
              // });
            }}
          />
        </Modal>
      )}

      <tr role="button" onClick={handleClick}>
        <td style={{ width: '120px' }}>
          <div
            title={`${agendamento.id}`}
            className={`px-3 py-2 text-white rounded text-center ${
              agendamento.pagamentoAntecipado ? 'bg-secondary' : 'bg-primary'
            }`}
          >
            {agendamento.pagamentoAntecipado ? 'Antecipado' : 'Hoje'}
          </div>
        </td>
        <td>
          <span className="user-name ">{agendamento.cliente.nome}</span>
          <br />
          <span>{agendamento.agenda.nome}</span>{' '}
          {agendamento.dataPagamento ? (
            <span className="badge badge-success">recebido</span>
          ) : (
            <span className="badge badge-danger">em aberto</span>
          )}
        </td>
        <td>
          <span className="user-name d-block">Data Agendamento</span>
          <span>
            {agendamento.agendamento.inicioFormatado} - {agendamento.horaIni}
          </span>
        </td>
        <td>
          <span className="user-name d-block">
            Valor {agendamento.pago ? 'pago' : ''}
          </span>
          <span className="user-name d-block">
            {agendamento.pago
              ? agendamento.valorPagoFormatado
              : agendamento.valorFormatado}
          </span>
        </td>
      </tr>
    </>
  );
};

export default LinhaAgendamentos;
