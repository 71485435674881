import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';

import { format, parseISO, parse } from 'date-fns';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { sexo, estados } from './dados';

import { Container, Box, LineForm } from './styles';

import Input from '../../components/Exame/InputExame';
import DatePicker from '../../components/Exame/DatePicker';
import SelectSync from '../../components/Exame/SelectSync';
import ItemExame from '../../components/ItemExame';
import Disappearedloading from '../../components/Loading';

import RecuperaAdicionais from '../../components/ItemExame/RecuperaAdicionais';
import getValidationErrors from '../../utils/getValidationErrors';

interface IExameInfo {
  id: number;
  agendamento_id: number;
  cliente_id: number;
  cliente_nome: string;
  cliente_sexo: string;
  cliente_dataNasc: Date | null;
  data: Date | null;
  hora: Date | null;
  observacao: string;
  uf: string;

  codigoLis: Date | null;
  age: number;
}
export interface IExamesAlvaro {
  codigo: string;
  nome: string;
  material: string;
  dadoObrigatorio: string;
  codigoAMB: string;
  dataAtualizacao: string;
  ehCurva: string;
  ehDescritivo: string;
  listaMaterial: string;
}

export interface IExameItem {
  id: number;
  codigoAlvaro: string;
  material: string;
  codigoBarras: string;
  identificacao: string;
  dadosAdicionais: string;
  urgente: boolean | null;
  exame_id: number;
  qtde: number;
  observacao: string;
  status: string;
  curva: string;
  ehCurva: string;
  temDados: string;
  examesAlvaro?: IExamesAlvaro;
  listaMaterial: string;
  dataAtualizacao?: Date;
}
// ListaMaterial: "{\"codigoAlvaro\":\"HDL\",\"material\":[{\"value\":\"543\",\"label\":\"543 - soro\"}]}"
interface IExame {
  id: number;
  agendamento_id: number;
  cliente_id: number;
  cliente_nome: string;
  cliente_sexo: string;
  data: string;
  hora: string;
  cliente_dataNasc: string;
  codigoLis: string;
  codOS: string;
  observacao: string;
  incluido: boolean;
  baseBarras: string;
  usuario_id: number;
  link: string;
  clinica_id: string;
  exameItens: IExameItem[];
}

const Exame: React.FC = () => {
  //  const dispatch = useDispatch();
  // const statusExames = useSelector(state => state.status.statusExames);

  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(true);
  const [status, setStatus] = useState('Confirmar');
  const [exames, setExames] = useState<IExameItem[]>([]);
  const [validado, setValidado] = useState(false);
  const [statusExames, setStatusExames] = useState<{ [key: number]: string }>(
    {},
  );
  const [id, setId] = useState<string | null>(null);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const calcularIdade = useCallback((aniversario: string): number => {
    const dataNascimento = parse(
      format(parseISO(aniversario), 'yyyy-MM-dd'),
      'yyyy-MM-dd',
      new Date(),
    );

    const diferenca = Date.now() - dataNascimento.getTime();
    const idade = new Date(diferenca); // miliseconds from epoch
    return Math.abs(idade.getUTCFullYear() - 1970);
  }, []);

  const AlteraStatusExame = useCallback(
    (idExame: number, statusAtual: string) => {
      let valido = false;
      setStatusExames(state => {
        const statusAlterado = state;
        statusAlterado[idExame] = statusAtual;

        valido = Object.keys(statusAlterado).every((ex: string) => {
          return (
            statusAlterado[Number(ex)] === 'Confirmado' ||
            statusAlterado[Number(ex)] === 'Excluido'
          );
        });
        setValidado(valido);
        return statusAlterado;
      });
    },
    [],
  );

  useEffect(() => {
    setId(query.get('id'));
  }, [query]);

  useEffect(() => {
    exames.map(item => {
      AlteraStatusExame(item.id, item.status);
      return {
        [item.id]: item.status,
      };
    });
  }, [exames, AlteraStatusExame]);

  useEffect(() => {
    async function loadExame(): Promise<void> {
      setLoading(true);
      try {
        const retorno = await api.get<IExame>(`exame/?id=${id}`); // ${query}`);
        // console.log(retorno.data);
        if (retorno.data === null) {
          setLoading(false);
          setStatus('Incluido');
          addToast({
            type: 'info',
            title: 'Exame não encontrado',
          });
          return;
        }

        const ex: IExameInfo = {
          id: Number(id),
          agendamento_id: retorno.data.agendamento_id,
          cliente_id: retorno.data.cliente_id,
          cliente_nome: retorno.data.cliente_nome,
          cliente_sexo:
            retorno.data.cliente_sexo.toUpperCase() === 'MASCULINO' ? 'M' : 'F',
          data: retorno.data.data
            ? parse(
                format(parseISO(retorno.data.data), 'yyyy-MM-dd'),
                'yyyy-MM-dd',
                new Date(),
              )
            : null,
          cliente_dataNasc: retorno.data.cliente_dataNasc
            ? parse(
                format(parseISO(retorno.data.cliente_dataNasc), 'yyyy-MM-dd'),
                'yyyy-MM-dd',
                new Date(),
              )
            : null,
          hora: retorno.data.hora
            ? parse(
                format(parseISO(retorno.data.hora), 'HH:mm'),
                'HH:mm',
                new Date(),
              )
            : null,
          codigoLis: retorno.data.codigoLis
            ? parse(
                format(parseISO(retorno.data.codigoLis), 'yyyy-MM-dd'),
                'yyyy-MM-dd',
                new Date(),
              )
            : null,
          age: calcularIdade(retorno.data.cliente_dataNasc),
          observacao: retorno.data.observacao,
          uf: 'RJ',
        };

        formRef.current?.setData(ex);
        if (retorno.data.incluido === true) {
          setStatus('Incluido');
        }

        const itmEx: IExameItem[] = retorno.data.exameItens.map(item => {
          let adic = {};

          if (item.dadosAdicionais !== '') {
            const dadosad = item.dadosAdicionais.split('@carac#xA;');

            dadosad.forEach(cp => {
              adic = { ...adic, ...RecuperaAdicionais(cp) };
            });
          }

          return {
            id: item.id,
            codigoAlvaro: item.codigoAlvaro,
            material: item.material,
            codigoBarras: item.codigoBarras,
            identificacao: item.identificacao,
            dadosAdicionais: item.dadosAdicionais,
            urgente: item.urgente,
            exame_id: item.exame_id,
            nome: item.examesAlvaro ? item.examesAlvaro.nome : '',
            qtde: item.qtde === 0 ? 1 : item.qtde,
            observacao: item.observacao,
            status: item.status, // 'Confirmar', // Confirmar, Confirmado, Excluído
            ehCurva: String(
              item.examesAlvaro ? item.examesAlvaro.ehCurva === 'S' : 'N',
            ),
            curva: item.curva,
            temDados: item.examesAlvaro
              ? item.examesAlvaro.dadoObrigatorio
              : '',
            listaMaterial: item.examesAlvaro
              ? item.examesAlvaro.listaMaterial
              : '',
            ...adic,
            dataAtualizacao: item.examesAlvaro?.dataAtualizacao
              ? parseISO(item.examesAlvaro?.dataAtualizacao)
              : undefined,
          };
        });

        setExames(itmEx);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.status
        ) {
          addToast({
            type: 'error',
            title: error.response.data.message,
          });
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro desconhecido!',
          });
        }

        /* setTimeout(() => {
               window.location = `https://agendafacilsp.med.br/AgendaAtendimento?Agenda=${agenda}`;
            }, 3000); */
      }
    }
    // ${query}`);
    if (!id) return;

    if (id) loadExame();
  }, [addToast, calcularIdade, id]);

  const handleSubmit = useCallback(
    async (data: IExameInfo): Promise<void> => {
      setLoading(true);
      if (!editing) {
        setEditing(true);
        setLoading(false);
        setStatus('Confirmar');
        return;
      }

      try {
        // Remove all previous errors
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cliente_nome: Yup.string().required('Nome do Paciente obrigatório'),
          cliente_dataNasc: Yup.date().required(
            'Data de Nascimento obrigatório',
          ),
          cliente_sexo: Yup.string().required('Campo Sexo obrigatório'),
          data: Yup.date().required('Data de Coleta obrigatório'),
          hora: Yup.date().required('Hora de Coleta obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await await api.put(`exame/${query.get('id')}`, {
          data: data.data,
          hora: data.hora,
          observacao: data.observacao,
        });
        setStatus('Confirmado');
        setEditing(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: `Ocorreu um erro so salvar dados${error}`,
          });
        }
        setLoading(false);
      }
    },
    [addToast, editing, query],
  );

  const enviaSolicitacaoLis = useCallback(async () => {
    setLoading(true);
    // console.log('Enviou LIS');
    try {
      const retorno = await api.get(`exame/examesimples/${query.get('id')}`);

      // console.dir(retorno);
      if (!retorno.data.incluido) {
        setLoading(false);
        if (retorno.data.mensagem) {
          addToast({
            type: 'error',
            title: retorno.data.mensagem,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro desconhecido ao buscar dados',
        });
        return;
      }
      addToast({
        type: 'success',
        title: `Solicitacao Enviada com sucesso`,
      });
      setStatus('Incluido');
      window.history.go(-2);
      // window.location = document.getElementById('HidUrlAgenda')?.value || '';
    } catch (err) {
      addToast({
        type: 'error',
        title: `Ocorreu um erro so salvar dados${err}`,
      });
    }
    setLoading(false);
  }, [addToast, query]);

  const handleVoltar = useCallback(() => {
    const dataAgenda = query.get('data');
    const codigoAgenda = query.get('agenda');
    history.push({
      pathname: '/agendaatendimento',
      search: `?data=${dataAgenda}&agenda=${codigoAgenda}`,
    });
  }, [history, query]);

  return (
    <Container>
      <input type="hidden" value="something" />
      <h2>Exames - Cadastrar Ordem de Serviço</h2>
      {loading ? <Disappearedloading /> : ''}
      <Form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
        <Box>
          <LineForm>
            <h2>Dados do Paciente</h2>
          </LineForm>
          <LineForm>
            <div style={{ width: '40%' }}>
              <Input
                label="Nome do paciente *"
                type="text"
                name="cliente_nome"
                placeholder="Nome do paciente *"
                maxLength={60}
                id="cliente_nome"
                disabled
              />
            </div>
            <div style={{ display: 'grid', height: '14px', width: '12%' }}>
              <DatePicker
                className="react-datepicker__input-container"
                id="cliente_dataNasc"
                name="cliente_dataNasc"
                maxLength={10}
                dateFormat="dd/MM/yyyy"
                type="text"
                placeholder="Data de nascimento *"
                label="Data de nascimento *"
                disabled
              />
            </div>
            <div style={{ width: '4%' }}>
              <Input
                type="text"
                name="age"
                disabled
                placeholder="Idade"
                label="Idade"
                maxLength={3}
                id="age"
              />
            </div>
            <div style={{ display: 'grid', height: '14px', width: '10%' }}>
              <SelectSync
                name="cliente_sexo"
                id="cliente_sexo"
                options={sexo}
                placeholder="Sexo"
                label="Sexo"
                isDisabled
              />
            </div>
            <div style={{ width: '22%' }}>
              <Input
                type="text"
                name="osIdLis"
                placeholder="Código OS LIS"
                label="Código OS LIS"
                maxLength={30}
                id="osIdLis"
              />
            </div>
          </LineForm>
          <LineForm>
            <div style={{ width: '16%' }}>
              <Input
                type="text"
                name="idLis"
                placeholder="Código do paciente (ID LIS)"
                label="Código do paciente (ID LIS)"
                maxLength={20}
                id="idLis"
              />
            </div>
            <div style={{ display: 'grid', height: '14px', width: '12%' }}>
              <DatePicker
                type="text"
                placeholder="Data da coleta"
                label="Data da coleta"
                id="data"
                name="data"
                dateFormat="dd/MM/yyyy"
                className="react-datepicker__input-container"
                disabled={!editing}
              />
            </div>
            <div style={{ width: '9%' }}>
              <DatePicker
                type="text"
                placeholder="Hora da coleta"
                label="Hora da coleta"
                id="hora"
                name="hora"
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeIntervals={15}
                className="react-datepicker__input-container"
                disabled={!editing}
              />
            </div>
            <div style={{ width: '33%' }}>
              <Input
                type="text"
                name="doctor.TGOname"
                placeholder="Médico"
                label="Médico"
                maxLength={60}
                id="doctor.name"
              />
            </div>
            <div style={{ width: '14%' }}>
              <Input
                type="text"
                name="doctorid"
                placeholder="CRM"
                id="doctorid"
                label="CRM"
                maxLength={10}
              />
            </div>
            <div style={{ display: 'grid', height: '14px', width: '8%' }}>
              <SelectSync
                name="uf"
                id="uf"
                options={estados}
                placeholder="Estado"
                label="Estado"
              />
            </div>
          </LineForm>
          <LineForm>
            <div style={{ width: '85%' }}>
              <Input
                name="observacao"
                placeholder="Observação"
                id="observacao"
                label="Observação"
                maxLength={250}
                type="text"
                disabled={!editing}
              />
            </div>

            <div
              style={{
                width: '13%',
                display: status === 'Incluido' ? 'none' : 'inherit',
              }}
            >
              <button className={`submit ${status}`} type="submit">
                {loading ? 'Carregando' : status}
              </button>
            </div>
          </LineForm>
          <Input type="hidden" name="id" id="id" />
        </Box>
      </Form>
      {exames &&
        exames.length > 0 &&
        exames.map(ex => (
          <ItemExame
            key={`item${ex.codigoAlvaro}`}
            setStatusExame={AlteraStatusExame}
            Item={ex}
          />
        ))}
      <LineForm />
      <div id="botoes" className="md-layout orders-new__footer">
        <div id="botoesInicio" className="ml-5">
          <div className="md-flex-40">
            {/* <a
              href="#"
              id="btnCancelar"
              className="btn btn-medium btn-septenary orders-new__exam__-button"
              rel="noopener noreferrer"
            > */}
            <Button
              variant="outline-light"
              className="text-dark ml-2"
              size="sm"
              onClick={handleVoltar}
            >
              Cancelar (voltar para lista)
            </Button>
            {/* <span>Cancelar (voltar para lista)</span> */}
          </div>
        </div>
        <div
          id="botoesFim"
          className={`md-layout endButton md-align-end md-flex-40 ${
            validado && status === 'Confirmado'
          }`}
        >
          <button
            onClick={enviaSolicitacaoLis}
            type="button"
            id="btnSalvar"
            className="btn btn-medium btn-primary orders-new__exam__-button"
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Salvar'}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Exame;
