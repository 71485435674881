import styled, { css } from 'styled-components';

interface ContentProps {
  backgroundBlack?: boolean;
}

export const Container = styled.div<ContentProps>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 999;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${props =>
    props.backgroundBlack &&
    css`
      background-color: rgba(0, 0, 0, 0.4);
    `}
`;

export const Load = styled.div`
  z-index: 1000;
  background-color: #fff;
  height: 60px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 14px;
    animation: fa-spin 2s infinite linear;
    @keyframes fa-spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
`;
