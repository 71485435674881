import React, { useCallback, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { Scope } from '@unform/core';
import Input from '../../../../components/Input';
import Toggle from '../../../../components/Exame/Toggle';
import SelectSync from '../../../../components/SelectSync';
import { optionsHorasCadastroAgenda } from '../../../../utils/funcoes';

// import { Container } from './styles';
type Props = {
  dia: string;
  nDia: number;
};
const Horario: React.FC<Props> = ({ dia, nDia }) => {
  const optionsHora = useMemo(() => {
    return optionsHorasCadastroAgenda();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <>
      <Scope path={`horarios[${nDia - 1}]`}>
        <Col md={2}>
          <Input
            type="hidden"
            className="d-none"
            name="dia"
            defaultValue={`${nDia}`}
          />
          <div
            className="d-flex justify-content-between"
            style={{ width: '85px' }}
          >
            <Toggle
              nome="selecionado"
              classSpam={{ paddingRight: '5px' }}
              label={dia}
              isDisabled={false}
            />
          </div>
        </Col>
        <Col md={2}>
          <SelectSync
            name="horaIni"
            options={optionsHora}
            handleSelectChange={handleOnChangeVazio}
            placeholder="00:00"
            label=""
            className="pb-1"
          />
        </Col>
        <Col md={2}>
          <SelectSync
            name="intervaloIni"
            options={[{ label: '00:00', value: '' }, ...optionsHora]}
            handleSelectChange={handleOnChangeVazio}
            placeholder="00:00"
            label=""
          />
        </Col>
        <Col md={2}>
          <SelectSync
            name="intervaloFim"
            options={[{ label: '00:00', value: '' }, ...optionsHora]}
            handleSelectChange={handleOnChangeVazio}
            placeholder="00:00"
            label=""
            className="pb-2"
          />
        </Col>
        <Col md={2}>
          <SelectSync
            name="horaFim"
            options={optionsHora}
            handleSelectChange={handleOnChangeVazio}
            placeholder="00:00"
            label=""
            className="pb-2"
          />
        </Col>
        <Col md={2}>
          <Input
            name="maxAgendamento"
            label=""
            type="number"
            step="1"
            max="100"
            min="0"
          />
        </Col>
      </Scope>
    </>
  );
};

export default Horario;
