/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import { TIPO_USUARIO } from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';
import { useEspecialidade } from '../../../hooks/especialidade';

import Input from '../../../components/Input';
import SelectSync, { IOption } from '../../../components/SelectSync';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';

import getResponseErrors from '../../../utils/getResponseErrors';
import PageInfo from '../../../components/PageInfo';
import HeaderRelatorio from '../HeaderRelatorio';

interface IRelatorio {
  total: number;
  PerNome: string;
  CliNome: string;
  ProNome: string;
}

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  CliCodigo: string;
  PerCodigo: string;
  PerCodigoIgnorar: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

const QuantidadeProcedimentos: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica } = useClinica();

  const { optionsEspecialidade, buscaEspecialidade } = useEspecialidade();

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorio[]>();
  const [optionEspecialidade, setOptionEspecialidade] = useState<IOption[]>([]);

  const initialData = {
    dataInicio: format(new Date(), 'yyyy-MM-dd'),
    dataFim: format(new Date(), 'yyyy-MM-dd'),
    CliCodigo: user.clinica_id.toString(),
  };
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinica, setClinica] = useState('');

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const informacoesPagina = `<b>Descrição:</b> Exibe o total de procedimentos realizados em um determinado período.`;

  useEffect(() => {
    async function loadPerfis(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadPerfis();
  }, [buscaEspecialidade, optionsEspecialidade]);

  useEffect(() => {
    try {
      setOptionEspecialidade(optionsEspecialidade.optionEspecialidades);
    } catch (error) {
      addToast({
        title: 'Não foi possível buscar perfis',
        type: 'error',
      });
    }
  }, [addToast, optionsEspecialidade]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          dataFim: Yup.date().required(),
          CliCodigo: Yup.string().nullable(true),
          PerCodigo: Yup.string().nullable(true),
          PerCodigoIgnorar: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinica(
          optionsClinica.find(cli => cli.value === dados.CliCodigo)?.label ||
            '',
        );

        const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
        const dataFimSelecionada = parseISO(dados.dataFim.toString());

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        });

        let parametrosOpcionais = dados.PerCodigo
          ? `&PerCodigo=${dados.PerCodigo}`
          : '';
        parametrosOpcionais += dados.PerCodigoIgnorar
          ? `&PerCodigoIgnorar=${dados.PerCodigoIgnorar}`
          : '';
        parametrosOpcionais += dados.CliCodigo
          ? `&CliCodigo=${dados.CliCodigo}`
          : '';

        const response = await api.get<IRelatorio[]>(
          `/relatorios/quantidadeprocedimentos?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}${parametrosOpcionais}`,
        );

        setDadosRelatorio(response.data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading />}
      <>
        <div className="d-print-none bg-white border p-2 mb-2">
          <div className="p-2 mb-2 d-flex justify-content-between">
            <h2 id="topoBusca">Quantidade de Procedimentos</h2>
            <PageInfo
              descricao={informacoesPagina}
              permissao={TIPO_USUARIO.SUPERVISOR}
            />
          </div>
          {optionsClinica.length > 0 && user && (
            <Form
              ref={formRefBusca}
              initialData={initialData}
              onSubmit={handleSubmitBusca}
            >
              <Row>
                <Col md={2}>
                  <Input name="dataInicio" label="De" type="date" required />
                </Col>
                <Col md={2}>
                  <Input name="dataFim" label="Até" type="date" required />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="PerCodigo"
                    label="Perfil"
                    options={optionEspecialidade}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="PerCodigoIgnorar"
                    label="Ignorar Perfil"
                    options={optionEspecialidade}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="CliCodigo"
                    label="Clinica"
                    options={optionsClinica}
                    disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                <Col md={1}>
                  <br />
                  <Button type="submit" size="sm" variant="outline-secondary">
                    BUSCAR
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>

        <HeaderRelatorio
          dataImpressao={dataExibe?.dataHora}
          titulo={`QUANTIDADE DE PROCEDIMENTOS ${
            clinica.length > 0 ? `- ${clinica} -` : '-'
          } ${dataExibe?.dataDiaSemana || ''}`}
        />

        <div className="bg-white border p-2" style={{ minHeight: '300px' }}>
          {dadosRelatorio && (
            <>
              <table
                className="table table-hover table-sm table-font-small table-bordered"
                style={{ borderWidth: '0px' }}
              >
                <thead>
                  <tr className="text-center">
                    <th style={{ minWidth: '150px' }}>Clinica</th>
                    <th style={{ minWidth: '150px' }}>Perfil</th>
                    <th style={{ width: '100%' }}>Procedimento</th>
                    <th style={{ minWidth: '150px' }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {dadosRelatorio &&
                    dadosRelatorio.map(rel => (
                      <tr
                        key={`despesas-${rel.PerNome}${rel.ProNome}`}
                        style={{ fontSize: '.7rem', height: '30px' }}
                      >
                        <td>{rel.CliNome}</td>
                        <td>{rel.PerNome}</td>
                        <td>{rel.ProNome}</td>
                        <td>{rel.total}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </>
    </Container>
  );
};

export default QuantidadeProcedimentos;
