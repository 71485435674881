import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FiMail, FiLock, FiUser } from 'react-icons/fi';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import Avatar from '../../components/Avatar';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import { Container, Content } from './styles';
import api from '../../services/api';
import getResponseErrors from '../../utils/getResponseErrors';

interface ProfileFormData {
  nome: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const { user, updateUser } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required(),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          old_password: Yup.string(),
          password: Yup.string().when('old_passowrd', {
            is: (value: string | any[]) => value && value.length > 0,
            then: Yup.string().required(),
            otherwise: Yup.string(),
          }),
          password_confirmation: Yup.string()
            .when('old_passowrd', {
              is: (value: string) => value && !!value.length,
              then: Yup.string().min(6, 'Obrigatório mínimo 6 dígitos'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          nome,
          email,
          old_password,
          password,
          password_confirmation,
        } = data;

        const formData = {
          nome: nome.toUpperCase(),
          email: email.toLowerCase(),
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data);
        history.push('/dashboard');

        addToast({
          type: 'success',
          title: 'Perfil Atualizado!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          return;
        }
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível atualizar perfil',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, updateUser],
  );

  const handleNovoAvatar = useCallback(
    async avatarUrl => {
      updateUser({ ...user, avatar_url: avatarUrl });
    },
    [updateUser, user],
  );

  return (
    <Container>
      {loading && <Loading />}
      <Content>
        <Form
          ref={formRef}
          initialData={{ nome: user.nome, email: user.email }}
          onSubmit={handleSubmit}
        >
          <Avatar
            nome={user.nome}
            avatar_url={user.avatar_url}
            codigo={user.id}
            path="usuarios"
            circular
            handleReturnNewAvatarUrl={avatarUrl => handleNovoAvatar(avatarUrl)}
          />

          <h1>Editar perfil</h1>

          <Input
            icon={FiUser}
            name="nome"
            autoComplete="nope"
            containerStyle={{ marginBottom: '.3rem' }}
            placeholder="Digite seu nome"
          />
          <Input icon={FiMail} name="email" placeholder="Digite seu e-mail" />

          <Input
            containerStyle={{ marginTop: 24, marginBottom: '.3rem' }}
            icon={FiLock}
            name="old_password"
            type="password"
            placeholder="Senha atual"
            textUppercase={false}
          />
          <div className="d-flex justify-content-between my-3">
            <Input
              icon={FiLock}
              containerStyle={{ marginBottom: '.3rem' }}
              name="password"
              type="password"
              placeholder="Nova senha"
              textUppercase={false}
            />

            <Input
              icon={FiLock}
              containerStyle={{ marginBottom: '.3rem' }}
              name="password_confirmation"
              type="password"
              placeholder="Confirmar senha"
              textUppercase={false}
            />
          </div>
          <Button type="submit" disabled={loading}>
            {loading ? 'Atualizando...' : 'Confirmar mudanças'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
