interface ICheck {
  name: string;
  key: string;
  label: string;
}

export function checkboxes(): ICheck[] {
  const checks: ICheck[] = [];
  for (let index = 0; index < 156; index += 5) {
    checks.push({
      name: `check${index}min`,
      key: `${index}min`,
      label: `${index} min`,
    });
  }

  let values = ['165', '170', '175', '180', '200', '210'];
  values.map(it => {
    checks.push({
      name: `check${it}min`,
      key: `${it}min`,
      label: `${it} min`,
    });
    return it;
  });

  for (let index = 240; index < 601; index += 60) {
    checks.push({
      name: `check${index}min`,
      key: `${index}min`,
      label: `${index} min`,
    });
  }

  values = ['800', '900', '940', '960', '1230', '1380', '1440'];
  values.map(it => {
    checks.push({
      name: `check${it}min`,
      key: `${it}min`,
      label: `${it} min`,
    });
    return it;
  });

  values = ['Basal', 'Pré', 'Pós', 'Pós-prandial', 'Manhã', 'Tarde', 'Noite'];

  values.map(it => {
    checks.push({
      name: `check${it}`,
      key: `${it}`,
      label: `${it}`,
    });
    return it;
  });

  return checks;
}
