import React, { useEffect, useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, Button } from 'react-bootstrap';

import { FiPlusCircle } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';
import { useEspecialidade } from '../../hooks/especialidade';
import ProcedimentoCadastro from './ProcedimentoCadastro';

import Input from '../../components/Input';
import SelectSync, { IOption } from '../../components/SelectSync';

import Modal from '../../components/Modal';

import getValidationErrors from '../../utils/getValidationErrors';
import ListaProcedimentos, {
  IProcedimento,
  RefObject,
} from './ListaProcedimentos';

export interface IFormBusca {
  nome?: string;
  especialidade_id?: string;
  codigoAlvaro?: string;
  situacao?: string;
}

const Procedimento: React.FC = () => {
  const listaRef = useRef<RefObject>(null);
  const { addToast } = useToast();
  const { optionsEspecialidade, buscaEspecialidade } = useEspecialidade();
  const [dadosForm, setDadosForm] = useState<IFormBusca | undefined>(undefined);

  const formRefBusca = useRef<FormHandles>(null);

  const [totalRegistros, setTotalRegistros] = useState(0);

  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [optionEspecialidade, setOptionEspecialidade] = useState<IOption[]>([]);

  useEffect(() => {
    async function loadPerfis(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadPerfis();
  }, [buscaEspecialidade, optionsEspecialidade]);

  useEffect(() => {
    try {
      setOptionEspecialidade(optionsEspecialidade.optionEspecialidades);
    } catch (error) {
      addToast({
        title: 'Não foi possível buscar as especialidades',
        type: 'error',
      });
    }
  }, [addToast, optionsEspecialidade]);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        nome: Yup.string(),
        codigoAlvaro: Yup.string(),
        especialidade_id: Yup.string(),
        situacao: Yup.string().nullable(true),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });
      setDadosForm(dados);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handleAdd = useCallback(
    async (
      dadosProcedimento: IProcedimento,
      perf: IOption | undefined,
      proCodigo: number | undefined,
    ) => {
      if (listaRef.current) {
        const retorno = await listaRef.current.addProcedimento(
          dadosProcedimento,
          perf,
          proCodigo,
        );
        if (retorno) setShowModalCadastro(false);
        return true;
      }
      return false;
    },
    [],
  );

  const handleSetTotalRegistros = useCallback((total: number) => {
    setTotalRegistros(total);
  }, []);

  return (
    <Container fluid className="pt-2">
      {showModalCadastro && (
        <Modal
          show={showModalCadastro}
          titulo="Cadastro de Procedimento"
          handleFecharModal={() => setShowModalCadastro(false)}
          large
        >
          <ProcedimentoCadastro id={0} addProcedimento={handleAdd} />
        </Modal>
      )}

      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Procedimentos
            </span>
          </div>
          <div className="col-auto d-flex justify-content-end align-items-center">
            <Button
              type="button"
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setShowModalCadastro(true);
              }}
            >
              <FiPlusCircle /> Novo Procedimento
            </Button>
            <div className="doc-badge me-3 ml-3">
              Total registros{' '}
              <span className="bg-danger text-white px-2 py-1">
                {totalRegistros}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Form ref={formRefBusca} onSubmit={handleSubmitBusca}>
            <div className="row">
              <div className="col-md-3">
                <Input
                  name="nome"
                  label="Procedimento"
                  placeholder="NOME"
                  maxLength={100}
                  minLength={3}
                />
              </div>
              <div className="col-md-3">
                <SelectSync
                  name="especialidade_id"
                  label="Especialidade"
                  placeholder="Selecione"
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                  options={optionEspecialidade}
                />
              </div>
              <div className="col-md-2">
                <Input
                  name="codigoAlvaro"
                  label="Cod. Álvaro"
                  maxLength={20}
                  minLength={2}
                />
              </div>
              <div className="col-md-2">
                <SelectSync
                  name="situacao"
                  label="Situação"
                  placeholder="Selecione"
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                  options={[
                    { label: 'Todos', value: '' },
                    { label: 'Ativo', value: 'true' },
                    { label: 'Desativado', value: 'false' },
                  ]}
                />
              </div>
              <div className="col-md-2">
                <br />
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  className="mt-1"
                >
                  BUSCAR
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="card-body p-0">
          <ListaProcedimentos
            ref={listaRef}
            dados={dadosForm}
            handleRetornaTotal={handleSetTotalRegistros}
          />
        </div>
      </div>
    </Container>
  );
};

export default Procedimento;
