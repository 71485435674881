import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Horario from '../Horario';

// import { Container } from './styles';
interface IDiasHorarios {
  [key: number]: { dia: string };
}

const DiasHorarios: React.FC = () => {
  const [diasHorarios, setDiasHorarios] = useState<IDiasHorarios>({
    1: { dia: 'Dom' },
    2: { dia: 'Seg' },
    3: { dia: 'Ter' },
    4: { dia: 'Qua' },
    5: { dia: 'Qui' },
    6: { dia: 'Sex' },
    7: { dia: 'Sab' },
  });
  return (
    <>
      <Row className="d-flex align-items-center bg-light mb-2 py-1">
        <Col md={2}>
          <b>Dia</b>
        </Col>
        <Col md={2}>
          <b>Início</b>
        </Col>
        <Col md={2}>
          <b>Iní. Intervalo</b>
        </Col>
        <Col md={2}>
          <b>Fim Intervalo</b>
        </Col>
        <Col md={2}>
          <b>Fim</b>
        </Col>
        <Col md={2}>
          <b style={{ fontSize: '0.8rem' }}>Máx. Agendamentos</b>
        </Col>
      </Row>
      <Row>
        {Object.keys(diasHorarios).map((dia: string) => {
          return (
            <Horario
              dia={diasHorarios[Number(dia)].dia}
              nDia={Number(dia)}
              key={`horario${dia}`}
            />
          );
        })}
      </Row>
    </>
  );
};

export default DiasHorarios;
