import styled from 'styled-components';

export const PopUp = styled.div`
  min-width: 300px;
  position: absolute;
  background-color: #fff;
  padding: 16px 10px;
  z-index: 10;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  b {
    display: block;
  }
`;
