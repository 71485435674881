import { Form } from '@unform/web';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import * as Yup from 'yup';

// import { Container } from './styles';
import { Button, Col, Row } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import IMotivo from '../../../utils/interface/IMotivo';
import Input from '../../Input';
import InputMoney from '../../InputMoney';
import TextArea from '../../TextArea';
import SelectSync, { IOption } from '../../SelectSync';
import Loading from '../../Loading';
import getValidationErrors from '../../../utils/getValidationErrors';
import getResponseErrors from '../../../utils/getResponseErrors';
import { TIPO_MOTIVO } from '../../../utils/funcoes';

interface IFormProps extends FormHandles {
  loginDesconto: string;
  senhaDesconto: string;
  motivo: string;
  valor: string;
  descricao: string;
}

export interface IDesconto {
  UsuCodigo: number;
  UsuNome: string;
  login: string;
  motivo: number;
  valor: string;
  descricao: string;
}

type Props = {
  valorParcela: number;
  desconto: IDesconto;
  handleFechar(): void;
  handleSalvar(data: IDesconto): void;
};

const Desconto: React.FC<Props> = ({
  handleFechar,
  handleSalvar,
  desconto,
  valorParcela,
}) => {
  const formRefDesconto = useRef<IFormProps>(null);
  const { addToast } = useToast();

  const [optionsMotivo, setOptionsMotivo] = useState<IOption[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadOptionsMotivo(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IMotivo[]>(
          `/motivos?ativo=true&tipo=${TIPO_MOTIVO.DESCONTO}`,
        );

        const options = response.data.map((op: IMotivo) => ({
          value: op.id.toString(),
          label: op.nome,
        }));

        setOptionsMotivo(options);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar motivos',
          type: 'error',
          description: mensagemErro,
        });
      }
      setLoading(false);
    }
    loadOptionsMotivo();
  }, [addToast]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const validaDados = useCallback(async (dadosForm): Promise<boolean> => {
    try {
      formRefDesconto.current?.setErrors({});

      const schemaPadrao = Yup.object().shape({
        loginDesconto: Yup.string().required('Login obrigatório'),
        senhaDesconto: Yup.string().required('Senha obrigatório'),
        motivo: Yup.string()
          .min(1, 'Motivo obrigatório')
          .required('Motivo obrigatório'),
        valor: Yup.number()
          .typeError('digite um número válido')
          .min(1, 'Digite valor maior que 0')
          .required('Valor obrigatório'),
        descricao: Yup.string().required('Descrição obrigatório'),
      });

      await schemaPadrao.validate(dadosForm, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefDesconto.current?.setErrors(errors);
      }
      return false;
    }
  }, []);

  const handleSalvarDesconto = useCallback(
    async (data: IFormProps) => {
      const validado = await validaDados(data);

      if (!validado) return;

      const { loginDesconto, motivo, valor, senhaDesconto, descricao } = data;

      if (Number(valor) > Number(valorParcela)) {
        addToast({
          title: `Desconto não pode ser maior que ${valorParcela}`,
          type: 'error',
        });
        return;
      }

      const body = {
        email: loginDesconto.toUpperCase(),
        senha: senhaDesconto,
        exigeAdmin: false,
      };

      try {
        const response = await api.post(`validaadmin/validagerenteadmin`, body);
        const { UsuNome, UsuCodigo } = response.data;

        handleSalvar({
          login: loginDesconto,
          motivo: Number(motivo),
          valor,
          descricao,
          UsuCodigo,
          UsuNome,
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Permissão negada',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [handleSalvar, addToast, valorParcela, validaDados],
  );

  const initialDataForm = useMemo(() => {
    if (!desconto)
      return {
        UsuCodigo: 0,
        UsuNome: '',
        loginDesconto: '',
        senhaDesconto: '',
        motivo: '',
        valor: '',
        descricao: '',
      };
    return {
      UsuCodigo: desconto.UsuCodigo,
      UsuNome: desconto.UsuNome,
      loginDesconto: desconto.login,
      motivoDesconto: desconto.motivo,
      valor: desconto.valor,
      descricao: desconto.descricao,
    };
  }, [desconto]);

  useEffect(() => {
    formRefDesconto.current?.setData(initialDataForm);
  }, [initialDataForm]);

  const handleSubmit = useCallback(() => {
    formRefDesconto.current?.submitForm();
  }, []);
  return (
    <>
      {loading && <Loading backgroundBlack={false} />}
      <Form
        ref={formRefDesconto}
        // initialData={initialDataForm}
        onSubmit={handleSalvarDesconto}
      >
        <div>
          <Row noGutters>
            <Col md="6" lg="6" className="form-group pr-2">
              <Input name="loginDesconto" label="Código ou Email" />
            </Col>
            <Col md="6" lg="6" className="form-group pr-2 mb-0">
              <Input
                name="senhaDesconto"
                label="Senha"
                autoComplete="off"
                type="password"
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col md="6" lg="6" className="form-group pr-2 mb-0">
              <SelectSync
                name="motivo"
                label="Motivo"
                placeholder="Selecione"
                isClearable={false}
                handleSelectChange={handleOnChangeVazio}
                options={optionsMotivo || [{ label: '', value: '' }]}
              />
              <InputMoney
                name="valor"
                label="Valor Desconto"
                autoComplete="nope"
                placeholder="Desconto"
              />
            </Col>
            <Col md="6" lg="6" className="form-group pr-2 mb-0">
              <TextArea name="descricao" label="Descrição" rows={4} />
            </Col>
          </Row>

          <Button
            type="button"
            variant="primary mt-2"
            size="sm"
            onClick={handleSubmit}
          >
            Inserir Desconto
          </Button>

          <Button
            type="button"
            variant="outline-secondary mt-2 float-right"
            size="sm"
            onClick={handleFechar}
          >
            Voltar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Desconto;
