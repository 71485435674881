import styled from 'styled-components';

export const ButtonAdd = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
    font-weight: bold;
  }
`;
