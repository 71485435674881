import React, { useEffect } from 'react';
import { useAgenda } from '../../hooks/agenda';

import { useAuth } from '../../hooks/auth';
import { useCategoria } from '../../hooks/categoria';
import { useClinica } from '../../hooks/clinica';
import { useFormaPagamento } from '../../hooks/formapagamento';
import { useProfissional } from '../../hooks/profissional';
import { useEspecialidade } from '../../hooks/especialidade';
import { useModelo } from '../../hooks/modelo';

const SignOut: React.FC = () => {
  const { signOut } = useAuth();
  const { limparCategoria } = useCategoria();
  const { limparProfissional } = useProfissional();
  const { limparClinica } = useClinica();
  const { limparEspecialidade } = useEspecialidade();
  const { limparFormaPagamento } = useFormaPagamento();
  const { limparAgenda } = useAgenda();
  const { limparModelo } = useModelo();

  useEffect(() => {
    limparCategoria();
    limparProfissional();
    limparEspecialidade();
    limparFormaPagamento();
    limparClinica();
    signOut();
    limparAgenda();
    // limparModelo();
  }, [
    signOut,
    limparCategoria,
    limparClinica,
    limparFormaPagamento,
    limparEspecialidade,
    limparProfissional,
    limparAgenda,
    limparModelo,
  ]);

  return <div />;
};

export default SignOut;
