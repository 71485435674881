import React, { useEffect, useState, useCallback } from 'react';
import * as Yup from 'yup';
import validarCpf from '../../utils/validarCpf';
import api from '../../services/api';

import Button from '../Button';
import { useToast } from '../../hooks/toast';
import getResponseErrors from '../../utils/getResponseErrors';
import { formatPrice } from '../../utils/funcoes';

interface IDadosRps {
  nome: string;
  cnpj: string;
  ie: string;
  endereco: string;
  numero: string;
  bairro: string;
  cep: string;
  cidadeCodigo: string;
  cidade: string;
  uf: string;
  fone: string;
  email: string;
  descricao: string;
  vlr: string;
}

interface IRpsDTO {
  clinica: {
    cpfCnpj: string;
    inscricaoMunicipal: string;
    razaoSocial: string;
    simplesNacional: number;
    logradouro: string;
    numero: string;
    complemento?: string;
    bairro: string;
    codigoCidade: string;
    cep: string;
    email: string;
    nome: string;
    uf: string;
  };

  cliente: {
    nome: string;
    cpfcnpj: string;
    ie: string;
    endereco: string;
    numero: string;
    complemento?: string;
    bairro: string;
    cep: string;
    cidadeCodigo: string;
    cidade: string;
    uf?: string;
    fone?: string;
    email: string;
    id: number; // PacCodigo?: number;
    nome_responsavel: string;
    cnpj_responsavel: string;
    ie_responsavel: string;
  };

  contapr: {
    id: number;
    dataPagamento?: Date;
    historico: string;
    vlr: number;
    clinica_id?: number;
  };
  especialidade_id?: number;

  servico: {
    id: number;
    especialidade_id: number;
    codigoServico: string;
    descricao: string;
    cnae: string;
    aliquota: string;
    codigoTributacao?: string;
    tipoTributacao: number;
  };
}

interface ITagErro {
  [key: string]: {
    valor: string;
    erro: boolean;
  };
}

interface IValidationError {
  value: any;
  path?: string;
  errors: string[];
}
interface IValidationErrors {
  value?: any;
  path?: string;
  errors?: string[];
  inner?: IValidationError[];
}

const schema = Yup.object().shape({
  nome: Yup.string().required('Nome obrigatório'),
  cnpj: Yup.string().required('CPF obrigatório'),
  ie: Yup.string().required('RG obrigatório'),
  endereco: Yup.string().required('Endereço obrigatório'),
  numero: Yup.string().required('Número obrigatório'),
  bairro: Yup.string().required('Bairro obrigatório'),
  cep: Yup.string().required('CEP obrigatório'),
  cidadeCodigo: Yup.string().required('Código da Cidade obrigatório'),
  cidade: Yup.string().required('Cidade obrigatória'),
  uf: Yup.string().required('Estado obrigatório'),
  fone: Yup.string().required('Telefone obrigatório'),
  email: Yup.string().email('Email inválido').required('E-mail obrigatório'),
  descricao: Yup.string().required('Serviço obrigatório'),
  vlr: Yup.string().required('Valor obrigatório'),
});

type DadosNotaProps = {
  codigo: string;
  handleFecharModal(): void;
};

const DadosNota: React.FC<DadosNotaProps> = ({ codigo, handleFecharModal }) => {
  const [dados, setDados] = useState<ITagErro>({});
  const [erroDados, setErroDados] = useState(false);
  const [nomeBotao, setNomeBotao] = useState('Carregando...');

  const { addToast } = useToast();

  const EnviarRps = useCallback(async () => {
    setNomeBotao('Carregando...');
    try {
      await api.post(`/emissaonfse/registraNFSE/${codigo}`);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar dados da Nota Fiscal',
        type: 'error',
        description: mensagemErro,
      });
    }
    setNomeBotao('Gerar Nota Fiscal');
    handleFecharModal();
  }, [handleFecharModal, codigo, addToast]);

  const ForcarEmissao = useCallback(async () => {
    setNomeBotao('Carregando...');
    try {
      await api.post(`/emissaonfse/registraNFSEAuto/${codigo}?auto=1`);
    } catch (error) {
      const mensagemErro = getResponseErrors(error);
      addToast({
        title: 'Não foi possível carregar dados da Nota Fiscal',
        type: 'error',
        description: mensagemErro,
      });
    }
    setNomeBotao('Gerar Nota Fiscal');
    handleFecharModal();
  }, [handleFecharModal, codigo, addToast]);

  useEffect(() => {
    async function load(): Promise<void> {
      try {
        const nf = await api.get<IRpsDTO>(
          `/rps/DadosTomador?contapr_id=${codigo}`,
        );

        const retorno: IDadosRps = {
          nome: nf.data.cliente.nome,
          cnpj: nf.data.cliente.cpfcnpj,
          ie: nf.data.cliente.ie,
          endereco: nf.data.cliente.endereco,
          numero: nf.data.cliente.numero,
          bairro: nf.data.cliente.bairro,
          cep: nf.data.cliente.cep,
          cidadeCodigo: nf.data.cliente.cidadeCodigo,
          cidade: nf.data.cliente.cidade,
          uf: nf.data.cliente.uf || 'rj',
          fone: nf.data.cliente.fone || '',
          email: nf.data.cliente.email,
          descricao: nf.data.contapr.historico,
          vlr: formatPrice(nf.data.contapr.vlr),
        };
        console.log('retorno', retorno);
        let erro: IValidationErrors = {};
        let errocpf = false;
        try {
          await schema.validate(retorno, {
            abortEarly: false,
          });

          if (retorno.cnpj && validarCpf(retorno.cnpj)) {
            // this.CmdConfirmar.removeAttribute('disabled');
            console.log('dentro cpf  validado');
          } else {
            errocpf = true;
            console.log('dentro cpf NÂO validado');
          }
        } catch (error: any) {
          console.dir(error);
          erro = error;
        }

        const retornoTagErro: ITagErro = {};
        Object.keys(schema.fields).forEach((it: string) => {
          Object.entries(retorno).forEach(([key, value]) => {
            if (key === it)
              retornoTagErro[it] = {
                valor: value,
                erro: false,
              };
          });
        });
        if (errocpf) {
          retornoTagErro.cpf.erro = true;
        }

        setErroDados(!!erro.inner);
        if (erro.inner) {
          erro.inner.forEach(err => {
            if (err.path)
              try {
                retornoTagErro[err.path].erro = true;
              } catch (error2) {
                console.log(err.path);
              }
          });
        }
        setDados(retornoTagErro);
      } catch (error) {
        console.log(error);
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível validar dados',
          type: 'error',
          description: mensagemErro,
        });
      }
      setNomeBotao('Gerar Nota Fiscal');
    }
    load();
  }, [codigo, addToast]);

  const nomeCampo = useCallback(campo => {
    if (campo === 'ie') return 'RG';
    if (campo === 'cnpj') return 'CPF';
    if (campo === 'vlr') return 'Valor';
    if (campo === 'fone') return 'telefone';
    if (campo === 'uf') return 'estado';
    return campo;
  }, []);
  return (
    <>
      <ul
        style={{
          display: 'grid',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '10px',
          margin: '0',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        {Object.keys(schema.fields).map(it =>
          dados[it] ? (
            <li
              style={{
                listStyle: 'none',
                padding: '1px 7px 10px 7px',
                display: 'block',
              }}
              key={it}
            >
              <span
                style={{
                  padding: '0 5px',
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  marginTop: '10px',
                }}
              >
                {nomeCampo(it)}
              </span>
              <div
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '16px',
                  padding: '6px 10px 7px 10px',
                  minHeight: '32px',
                  backgroundColor: dados[it].erro ? 'red' : '#fff',
                }}
              >
                {dados[it].valor}
              </div>
            </li>
          ) : (
            ''
          ),
        )}
      </ul>
      <div className="float-right">
        <Button
          disabled={!erroDados}
          type="button"
          className="btn btn-sm btn-primary md-2 mb-2 mr-3"
          onClick={() => ForcarEmissao()}
        >
          Forçar Emissão
        </Button>

        <Button
          disabled={erroDados}
          type="button"
          className="btn btn-sm btn-primary md-2 mb-2 mr-3"
          onClick={() => EnviarRps()}
          name={nomeBotao}
        >
          {nomeBotao}
        </Button>
      </div>
    </>
  );
};

export default DadosNota;
