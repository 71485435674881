import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignOut from '../pages/SignOut';
import Repository from '../pages/Repository';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Dashboard from '../pages/Dashboard';
import Agenda from '../pages/Agenda';
import Agendas from '../pages/Agendas';
import AgendaBusca from '../pages/AgendaBusca';
import AgendaEnvioConfirmacao from '../pages/AgendaEnvioConfirmacao';
import AgendaAtendimento from '../pages/AgendaAtendimento';
import Paciente from '../pages/Paciente';
import Aniversariantes from '../pages/Relatorios/Aniversariantes';
import PacienteCadastro from '../pages/PacienteCadastro';
import Profile from '../pages/Profile';
import Caixa from '../pages/Caixa';
import Procedimento from '../pages/Procedimento';
import AgendaCadastro from '../pages/AgendaCadastro';
import PacienteAtendimento from '../pages/PacienteAtendimento';
import Usuario from '../pages/Usuario/Usuario';
import Medico from '../pages/Usuario/Medico';
import AniversariantesUsuario from '../pages/Relatorios/AniversariantesUsuario';
import Configuracao from '../pages/Configuracao';

import FechamentoCaixa from '../pages/Relatorios/FechamentoCaixa';
import FechamentoPeriodo from '../pages/Relatorios/FechamentoPeriodo';
import FechamentoConferencia from '../pages/Relatorios/FechamentoConferencia';
import QuantidadeProcedimentos from '../pages/Relatorios/QuantidadeProcedimentos';
import ResumoGerencial from '../pages/Relatorios/ResumoGerencial';
import ProcedimentosRealizadosValor from '../pages/Relatorios/ProcedimentosRealizadosValor';

import Despesas from '../pages/Relatorios/Despesas';
import DespesasResumo from '../pages/Relatorios/DespesasResumo';

import SemPermissao from '../pages/SemPermissao';
import Perfil from '../pages/Perfil';
import ModeloDocumento from '../pages/Modelo/Documento';
import ModeloMensagem from '../pages/Modelo/Mensagem';
import EmConstrucao from '../pages/EmConstrucao';

import ImpressaoDocumento from '../pages/ImpressaoDocumento';
import FechamentoParceiro from '../pages/Relatorios/FechamentoParceiro';
import Nota from '../pages/Nota';
import Exame from '../pages/Exame';
import ExamesSolicitados from '../pages/ExamesSolicitados';
import ConferenciaExamesAlvaro from '../pages/Relatorios/ConferenciaExamesAlvaro';
import LucroExamesAlvaro from '../pages/Relatorios/LucroExamesAlvaro';
import PacienteAtendimentoAlvaro from '../pages/PacienteAtendimentoAlvaro';
import Whatsapp from '../pages/Whatsapp';
import MedicoEncaminhamento from '../pages/Medico';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/sair" component={SignOut} showHeader={false} isPrivate />
    <Route
      path="/emconstrucao"
      titulo="Em Construção"
      component={EmConstrucao}
      isPrivate
    />

    <Route path="/agendas" titulo="Agendas" component={Agendas} isPrivate />
    <Route path="/agenda/:id" component={Agenda} isPrivate />
    <Route
      path="/agendabusca"
      titulo="Busca na Agenda"
      component={AgendaBusca}
      isPrivate
    />
    <Route
      path="/agendaenvioconfirmacao"
      titulo="Envio de Confirmação Agenda"
      component={AgendaEnvioConfirmacao}
      isPrivate
    />
    <Route
      path="/agendaatendimento"
      component={AgendaAtendimento}
      titulo="Atendimento"
      isPrivate
    />
    <Route path="/paciente" titulo="Pacientes" component={Paciente} isPrivate />
    <Route
      path="/aniversariantes"
      titulo="Aniversariantes"
      component={Aniversariantes}
      isPrivate
    />
    <Route
      path="/pacientecadastro/:id"
      component={PacienteCadastro}
      isPrivate
    />

    <Route
      path="/dashboard"
      titulo="Dashboard"
      component={Dashboard}
      isPrivate
    />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/repositories/:repository+" component={Repository} />
    <Route path="/caixa" titulo="Caixa" component={Caixa} isPrivate />
    <Route
      path="/procedimento"
      titulo="Procedimentos"
      component={Procedimento}
      showSideBar
      isPrivate
    />
    <Route
      path="/agendaCadastro"
      titulo="Cadastro de Agendas"
      component={AgendaCadastro}
      isPrivate
      showSideBar
    />

    <Route
      path="/pacienteatendimento/:id"
      titulo="Atendimento"
      component={PacienteAtendimento}
      isPrivate
    />
    <Route
      path="/pacienteatendimentoalvaro/:id"
      component={PacienteAtendimentoAlvaro}
      isPrivate
    />
    <Route
      path="/usuario"
      titulo="Usuários"
      component={Usuario}
      isPrivate
      showSideBar
    />
    <Route
      path="/perfil"
      titulo="Perfis"
      component={Perfil}
      isPrivate
      showSideBar
    />
    <Route
      path="/profissional"
      titulo="Profissionais"
      component={Medico}
      isPrivate
      showSideBar
    />

    <Route
      path="/usuariosaniversariantes"
      titulo="Aniversariantes"
      component={AniversariantesUsuario}
      isPrivate
      showSideBar
    />
    <Route
      path="/modelodocumento"
      titulo="Modelo de Documentos"
      component={ModeloDocumento}
      isPrivate
      showSideBar
    />
    <Route
      path="/modelomensagem"
      titulo="Modelo de Mensagens"
      component={ModeloMensagem}
      isPrivate
      showSideBar
    />
    <Route
      path="/notasfiscais"
      titulo="Notas Fiscais"
      component={Nota}
      isPrivate
      showSideBar
    />
    <Route path="/exame" component={Exame} isPrivate />
    <Route
      path="/examessolicitados"
      component={ExamesSolicitados}
      showSideBar
      isPrivate
    />
    <Route
      path="/whatsapp"
      titulo="Whatsapp"
      component={Whatsapp}
      showSideBar
      isPrivate
    />
    <Route
      path="/configuracao"
      titulo="Administrativo"
      component={Configuracao}
      showSideBar
      isPrivate
    />
    <Route
      path="/fechamentocaixa"
      titulo="Fechamento de Caixa"
      component={FechamentoCaixa}
      showSideBar
      isPrivate
    />
    <Route
      path="/fechamentoperiodo"
      titulo="Recebimentos por Período"
      component={FechamentoPeriodo}
      showSideBar
      isPrivate
    />
    <Route
      path="/conferencia"
      titulo="Conferencia de Recebimentos"
      component={FechamentoConferencia}
      showSideBar
      isPrivate
    />

    <Route
      path="/fechamentoterceiro"
      titulo="Procedimentos Realizados Valor"
      component={FechamentoParceiro}
      showSideBar
      isPrivate
    />
    <Route
      path="/quantidadeprocedimentos"
      titulo="Quantidade de Procedimentos"
      component={QuantidadeProcedimentos}
      showSideBar
      isPrivate
    />

    <Route
      path="/resumogerencial"
      titulo="Resumo Gerencial"
      component={ResumoGerencial}
      showSideBar
      isPrivate
    />

    <Route
      path="/fechamentoconvenio"
      titulo="Fechamento Convênio"
      component={ProcedimentosRealizadosValor}
      showSideBar
      isPrivate
    />

    <Route
      path="/despesas"
      titulo="Despesas"
      component={Despesas}
      showSideBar
      isPrivate
    />

    <Route
      path="/despesasresumo"
      titulo="Demonstrativo Despesas"
      component={DespesasResumo}
      showSideBar
      isPrivate
    />

    <Route
      path="/conferenciaexamesalvaro"
      component={ConferenciaExamesAlvaro}
      titulo="Conferencia Exames Alvaro"
      isPrivate
      showSideBar
    />

    <Route
      path="/lucroexamesalvaro"
      component={LucroExamesAlvaro}
      titulo="Lucro Exames Alvaro"
      isPrivate
      showSideBar
    />

    <Route
      path="/impressaodocumento/:id/:tipo"
      component={ImpressaoDocumento}
      titulo="Impressão de Documentos"
      isPrivate
      showHeader={false}
    />

    <Route
      path="/MedicoEncaminhamento"
      titulo="Médico para encaminhamento"
      component={MedicoEncaminhamento}
      showSideBar
      isPrivate
    />

    <Route path="/sempermissao" component={SemPermissao} isPrivate />

    {/* <Route path="/" component={() => <h1>404</h1>} isPrivate /> */}

    <Route path="*" component={Dashboard} isPrivate />
  </Switch>
);

export default Routes;
