import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { AgendaProvider } from './agenda';
import { CategoriaProvider } from './categoria';
import { FormaPagamentoProvider } from './formapagamento';
import { EspecialidadeProvider } from './especialidade';
import { ProfissionalProvider } from './profissional';
import { ClinicaProvider } from './clinica';
import { NotaFiscalProvider } from './notafiscal';
import { ModeloProvider } from './modelo';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <ClinicaProvider>
      <AuthProvider>
        <FormaPagamentoProvider>
          <CategoriaProvider>
            <NotaFiscalProvider>
              <ProfissionalProvider>
                <EspecialidadeProvider>
                  <AgendaProvider>
                    {/* <ModeloProvider>  */}
                    {children}
                    {/* </ModeloProvider> */}
                  </AgendaProvider>
                </EspecialidadeProvider>
              </ProfissionalProvider>
            </NotaFiscalProvider>
          </CategoriaProvider>
        </FormaPagamentoProvider>
      </AuthProvider>
    </ClinicaProvider>
  </ToastProvider>
);

export default AppProvider;
