import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import { FaPencilAlt } from 'react-icons/fa';
import { isToday } from 'date-fns/esm';
import api from '../../../services/api';

import {
  formatPrice,
  ORIGEM_PAGAMENTO,
  TIPO_USUARIO,
  arrayGroupBy,
} from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';
import { useEspecialidade } from '../../../hooks/especialidade';
// import { useMedico } from '../../../hooks/medico';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import SelectSync, { IOption } from '../../../components/SelectSync';
import Loading from '../../../components/Loading';
import Tooltip from '../../../components/Tooltip';
import Modal from '../../../components/Modal';

import Conferido from './Conferido';

import getValidationErrors from '../../../utils/getValidationErrors';

import getResponseErrors from '../../../utils/getResponseErrors';

import Pagamento from '../../../components/Pagamento';
// import PageInfo from '../../../components/PageInfo';
import HeaderRelatorio from '../HeaderRelatorio';
import { useFormaPagamento } from '../../../hooks/formapagamento';

interface IDadosPagamento {
  tituloPagamento: string;
  pagamentoSelecionado?: number;
  codOrigem?: number;
}

interface IDadosPagamentoOrigem extends IDadosPagamento {
  origem_id: number;
  cliente_id: number;
}
interface IPagamento {
  descricao: string;
  valor: number;
  valorFormatado: string;
}

interface IFechamento {
  id: number;
  dataP: Date;
  dataPFormatado: string;
  dataPagamento: Date;
  dataPagamentoFormatado: string;
  ehPagamentoAntecipado: boolean;
  descricao: string;
  agenda_nome: string;
  conferido: boolean;
  valorTotal: number;
  origem_id?: number;
  obs?: { texto: string; cor: string; historico?: string };
  desconto?: {
    descricao: string;
    usuario_nome: string;
    valor: number;
    valorFormatado: string;
  };
  pagamento: IPagamento[];
  cliente_id: number;
  doDia: boolean;
  ehEstorno: boolean;
}

interface IRelatorio {
  data: IFechamento[];
  count: number;
}
interface IRelatorioFormatado {
  grupo: string;
  totalDesconto: number;
  totalDescontoFormatado: string;
  total: number;
  totalFormatado: string;
  dados: IFechamento[];
}

interface ITotais {
  totalDescontoFormatado: string;
  totalFormatado: string;
  totalAntecipadoFormatado: string;
}

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  clinica_id: string;
  especialidade_id: string;
  // ConUsuCodigo: string;
  formaPagto: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

type pageProps = {
  tipo: string;
};

const FechamentoDia: React.FC<pageProps> = ({ tipo }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica } = useClinica();

  const { optionsEspecialidade, buscaEspecialidade } = useEspecialidade();
  // const { optionsMedico, buscaOptionsMedico } = useMedico();
  const { optionsFormasPagamento, buscaFormaPagamento } = useFormaPagamento();

  const [optionEspecialidade, setOptionEspecialidade] = useState<IOption[]>([]);

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [showModalPagamento, setShowModalPagamento] = useState(false);
  const [dadosPagamento, setDadosPagamento] = useState<
    IDadosPagamentoOrigem | undefined
  >();
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorioFormatado[]>();
  const [totais, setTotais] = useState<ITotais>();
  const initialData = {
    dataInicio: format(new Date(), 'yyyy-MM-dd'),
    dataFim: format(new Date(), 'yyyy-MM-dd'),
    clinica_id: user.clinica_id.toString(),
  };
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinica, setClinica] = useState('');

  const paginaTipo =
    tipo && tipo === 'fechamentoconferencia'
      ? {
          titulo: 'Conferencia de Recebimentos',
          tipoConferencia: true,
        }
      : { titulo: 'Recebimentos por Período', tipoConferencia: false };

  const exibeTotais = user.tipo <= TIPO_USUARIO.SUPERVISOR;
  const exibeConferencia =
    paginaTipo.tipoConferencia && user.tipo <= TIPO_USUARIO.SUPERVISOR;

  const informacoesPagina = paginaTipo.tipoConferencia
    ? `<b>Descrição:</b> Relatório para conferencia de todos os pagamentos realizados no dia.<br /> Quando estiver sendo realizado no dia corrente, habilita o campo de edição para que possa ser realizado ajuste no lançamento.`
    : `<b>Descrição:</b> Exibe todos os pagamentos realizados em um determinado período`;

  useEffect(() => {
    async function loadPerfis(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadPerfis();
  }, [buscaEspecialidade, optionsEspecialidade]);

  // useEffect(() => {
  //   async function loadMedicos(): Promise<void> {
  //     await buscaOptionsMedico();
  //   }
  //   if (!optionsMedico || Object.keys(optionsMedico).length === 0)
  //     loadMedicos();
  // }, [buscaOptionsMedico, optionsMedico]);

  useEffect(() => {
    async function loadFormaPagto(): Promise<void> {
      await buscaFormaPagamento();
    }
    if (
      !optionsFormasPagamento.optionFormaPagamento ||
      Object.keys(optionsFormasPagamento.optionFormaPagamento).length === 0
    )
      loadFormaPagto();
  }, [buscaFormaPagamento, optionsFormasPagamento]);

  useEffect(() => {
    try {
      setOptionEspecialidade(optionsEspecialidade.optionEspecialidades);
    } catch (error) {
      addToast({
        title: 'Não foi possível buscar perfis',
        type: 'error',
      });
    }
  }, [addToast, optionsEspecialidade]);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          ...(exibeConferencia ? {} : { dataFim: Yup.date().required() }),
          clinica_id: Yup.string().required(),
          especialidade_id: Yup.string().nullable(true),
          // ConUsuCodigo: Yup.string().nullable(true),
          formaPagto: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinica(
          optionsClinica.find(cli => cli.value === dados.clinica_id)?.label ||
            '',
        );

        const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
        const dataFimSelecionada = exibeConferencia
          ? dataInicioSelecionada
          : parseISO(dados.dataFim.toString());

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        });

        // let parametrosOpcionais = dados.ConUsuCodigo
        //   ? `&ConUsuCodigo=${dados.ConUsuCodigo}`
        //   : '';
        let parametrosOpcionais = dados.especialidade_id
          ? `&especialidade_id=${dados.especialidade_id}`
          : '';
        parametrosOpcionais +=
          dados.formaPagto && paginaTipo.tipoConferencia
            ? `&formaPagto=${dados.formaPagto}`
            : '';

        const response = await api.get<IRelatorio>(
          `/relatorios/fechamentodia?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}&clinica_id=${dados.clinica_id}${parametrosOpcionais}`,
        );

        let totalGeral = 0;
        let totalGeralDesconto = 0;
        let totalGeralAntecipado = 0;

        const { data } = response.data;

        const dadosFormatados = data.map(rel => {
          const dataPFormatado = format(
            parseISO(rel.dataP.toString()),
            'dd/MM/yyyy',
          );
          const dataPagamentoFormatado = format(
            parseISO(rel.dataPagamento.toString()),
            'dd/MM/yyyy',
          );

          const ehPagamentoAntecipado =
            dataPFormatado !== dataPagamentoFormatado;
          if (ehPagamentoAntecipado) totalGeralAntecipado += rel.valorTotal;

          const doDia = isToday(dataInicioSelecionada);
          let ehEstorno = false;

          const desconto = rel.desconto
            ? {
                ...rel.desconto,
                valorFormatado: formatPrice(rel.desconto.valor),
              }
            : undefined;
          let obs;
          if (rel.valorTotal < 0) {
            ehEstorno = true;
            obs = {
              texto: 'ESTORNO',
              historico:
                desconto && desconto.valor < 0
                  ? `USUÁRIO: ${desconto.usuario_nome}, DESCRIÇÃO: ${desconto.descricao}`
                  : undefined,
              cor: 'text-danger',
            };
          } else if (desconto && desconto.valor !== 0) {
            obs = {
              texto: desconto.valorFormatado,
              historico: `USUÁRIO: ${desconto.usuario_nome}, DESCRIÇÃO: ${desconto.descricao}`,
              cor: '',
            };
          } else if (rel.valorTotal === 0) {
            // if (rel.agenda_nome.includes('ECG') || rel.agenda_nome.includes('EXAMES')) {
            //   obs = {
            //     texto: 'GRATUITO',
            //     historico: undefined,
            //     cor: 'text-success',
            //   };
            // } else {
            obs = {
              texto: rel.descricao,
              historico: undefined,
              cor: 'text-info',
            };
            // }
          } else {
            obs = undefined;
          }

          return {
            ...rel,
            dataPFormatado,
            dataPagamentoFormatado,
            // descricao:
            //   rel.agenda_nome === '0 - CARTÃO + SAÚDE'
            //     ? `${rel.descricao} - ${rel.descricao}`
            //     : rel.descricao,
            origem_id: ORIGEM_PAGAMENTO.RECEITA,
            ehPagamentoAntecipado,
            obs,
            pagamento: rel.pagamento
              ? rel.pagamento.map(des => {
                  return { ...des, valorFormatado: formatPrice(des.valor) };
                })
              : [{ descricao: '', valor: 0, valorFormatado: 'R$ 0,00' }],
            desconto,
            ehEstorno,
            doDia,
          };
        });

        const ordenado = arrayGroupBy(dadosFormatados, i => i.agenda_nome);

        const ordenadoFormatado = ordenado.map(ord => {
          const totalDesconto = ord.reduce((tot, item) => {
            return tot + Number(item.desconto?.valor || 0);
          }, 0);
          totalGeralDesconto += totalDesconto;

          const total = ord.reduce((tot, item) => {
            return tot + Number(item.valorTotal);
          }, 0);
          totalGeral += total;

          return {
            grupo: ord[0].agenda_nome,
            totalDesconto,
            totalDescontoFormatado: formatPrice(totalDesconto),
            total,
            totalFormatado: formatPrice(total),
            dados: ord,
          };
        });

        setDadosRelatorio(ordenadoFormatado);
        setTotais({
          totalFormatado: formatPrice(totalGeral),
          totalAntecipadoFormatado: formatPrice(totalGeralAntecipado),
          totalDescontoFormatado: formatPrice(totalGeralDesconto),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica, exibeConferencia, paginaTipo.tipoConferencia],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handlePagamento = useCallback((dados: IDadosPagamentoOrigem) => {
    setDadosPagamento(dados);

    setShowModalPagamento(true);
  }, []);

  const handleFecharModalPagamento = useCallback(async (load: boolean) => {
    setShowModalPagamento(false);
    if (load) {
      formRefBusca.current?.submitForm();
      setDadosPagamento(undefined);
    }
  }, []);

  const handleSetConferido = useCallback(
    (cprCodigo: number, conferido: boolean) => {
      const novoArray = dadosRelatorio?.map(itens => {
        const novosDados = itens.dados.map(dados => {
          if (dados.id === cprCodigo) return { ...dados, conferido };
          return dados;
        });
        return { ...itens, dados: novosDados };
      });
      setDadosRelatorio(novoArray);
    },
    [dadosRelatorio],
  );

  return (
    <Container fluid className="pt-2">
      {loading && <Loading />}
      {showModalPagamento && (
        <Modal
          show={showModalPagamento}
          titulo={dadosPagamento?.tituloPagamento || ''}
          handleFecharModal={() => setShowModalPagamento(false)}
        >
          <Pagamento
            handleFechar={handleFecharModalPagamento}
            tipo={
              dadosPagamento?.origem_id?.toString() ||
              ORIGEM_PAGAMENTO.RECEITA.toString()
            }
            cprCodigo={dadosPagamento?.pagamentoSelecionado}
            pacCodigo={
              dadosPagamento && dadosPagamento.cliente_id > 0
                ? dadosPagamento.cliente_id
                : undefined
            }
          />
        </Modal>
      )}

      <div className="d-print-none page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              {paginaTipo.titulo}
            </span>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-print-none">
          {optionsClinica.length > 0 && user && (
            <Form
              ref={formRefBusca}
              initialData={initialData}
              onSubmit={handleSubmitBusca}
            >
              <Row>
                <Col md={2}>
                  <Input name="dataInicio" label="De" type="date" required />
                </Col>
                {!paginaTipo.tipoConferencia && (
                  <Col md={2}>
                    <Input name="dataFim" label="Até" type="date" required />
                  </Col>
                )}
                <Col md={2}>
                  <SelectSync
                    name="especialidade_id"
                    label="Especialidade"
                    options={optionEspecialidade}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                {/* <Col md={2}>
                  <SelectSync
                    name="ConUsuCodigo"
                    label="Médico"
                    options={optionsMedico}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col> */}

                <Col
                  md={2}
                  className={paginaTipo.tipoConferencia ? '' : 'd-none'}
                >
                  <SelectSync
                    name="formaPagto"
                    label="Forma de Pagamento"
                    options={optionsFormasPagamento.optionFormaPagamento}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                <Col md={2}>
                  <Select
                    name="clinica_id"
                    label="Clinica"
                    options={optionsClinica}
                    disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                  />
                </Col>
                <Col md={2}>
                  <br />
                  <Button type="submit" size="sm" variant="outline-secondary">
                    BUSCAR
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>

        <HeaderRelatorio
          dataImpressao={dataExibe?.dataHora}
          titulo={`${paginaTipo.titulo.toUpperCase()} ${
            clinica.length > 0 ? `- ${clinica} -` : '-'
          } ${dataExibe?.dataDiaSemana || ''}`}
        />

        <div className="card-body p-0" style={{ minHeight: '300px' }}>
          <div className="table-responsive">
            <table className="table table-sm hover-table no-footer">
              <thead className="thead-light">
                <tr>
                  <th>Data</th>
                  <th>Descrição</th>
                  <th style={{ minWidth: '150px' }}>Desconto</th>
                  <th style={{ minWidth: '125px' }}>Pagamento</th>
                  <th style={{ minWidth: '115px' }}>Valor</th>
                  {exibeConferencia && (
                    <>
                      <th style={{ minWidth: '70px' }}>Conferido</th>
                      <th style={{ minWidth: '70px' }}> - </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {dadosRelatorio &&
                  dadosRelatorio.map(rel => (
                    <React.Fragment key={`des-${rel.grupo}`}>
                      <tr
                        style={{
                          fontSize: 'Small',
                          height: '30px',
                          fontWeight: 'bold',
                        }}
                      >
                        <td colSpan={exibeTotais ? 2 : 5}>{rel.grupo}</td>
                        {exibeTotais && (
                          <>
                            <td>Total Desc: {rel.totalDescontoFormatado}</td>
                            <td>Nº Registros: {rel.dados.length}</td>
                            <td>Total: {rel.totalFormatado}</td>
                          </>
                        )}

                        {exibeConferencia && (
                          <>
                            <td />
                            <td />
                          </>
                        )}
                      </tr>
                      {rel.dados.map(relatorio => (
                        <tr
                          key={`despesas-${rel.grupo}-${relatorio.id}`}
                          data-id={relatorio.id}
                          style={{ fontSize: '.7rem', height: '30px' }}
                          className={
                            relatorio.ehPagamentoAntecipado ? 'text-muted' : ''
                          }
                        >
                          <td>{relatorio.dataPFormatado}</td>
                          <td>{relatorio.descricao}</td>
                          <td>
                            {relatorio.obs &&
                              (relatorio.obs.historico ? (
                                <Tooltip
                                  className={relatorio.obs.cor}
                                  title={relatorio.obs.historico}
                                >
                                  {relatorio.obs.texto}
                                </Tooltip>
                              ) : (
                                <span className={relatorio.obs.cor}>
                                  {relatorio.obs.texto}
                                </span>
                              ))}
                          </td>
                          <td>
                            {relatorio.pagamento.map(pag => (
                              <span
                                key={`pagD-${relatorio.id}-${pag.descricao}`}
                                className="d-block"
                              >
                                {pag.descricao}
                              </span>
                            ))}
                          </td>
                          <td>
                            {relatorio.pagamento.map(pag => (
                              <span
                                key={`pagV-${relatorio.id}-${pag.descricao}`}
                                className="d-block"
                              >
                                {pag.valorFormatado}
                              </span>
                            ))}
                          </td>
                          {exibeConferencia && (
                            <>
                              <td>
                                <Conferido
                                  id={relatorio.id}
                                  conferido={relatorio.conferido}
                                  handleConferir={handleSetConferido}
                                />
                              </td>
                              <td>
                                <Button
                                  type="button"
                                  variant="primary-outline"
                                  className={`py-0 ${
                                    (relatorio.doDia &&
                                      !relatorio.ehEstorno &&
                                      exibeTotais &&
                                      !relatorio.conferido) ||
                                    user.id === 1
                                      ? ''
                                      : 'd-none'
                                  }`}
                                  title={`${relatorio.id}`}
                                  onClick={
                                    () =>
                                      handlePagamento({
                                        origem_id:
                                          relatorio.origem_id ||
                                          ORIGEM_PAGAMENTO.RECEITA,
                                        tituloPagamento: `${relatorio.descricao}`,
                                        pagamentoSelecionado: relatorio.id,
                                        cliente_id: relatorio.cliente_id,
                                      })
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }
                                >
                                  <FaPencilAlt />
                                </Button>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                {exibeTotais && dadosRelatorio && dadosRelatorio.length > 0 && (
                  <tr
                    key="fdfdsfsdfdf"
                    style={{
                      fontSize: 'Small',
                      height: '30px',
                      fontWeight: 'bold',
                    }}
                  >
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      {totais?.totalAntecipadoFormatado !== 'R$0,00'
                        ? `Total Antecipado: ${totais?.totalAntecipadoFormatado}`
                        : ''}
                    </td>
                    <td>Total Desc: {totais?.totalDescontoFormatado}</td>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      Total Geral: {totais?.totalFormatado}
                    </td>
                    {exibeConferencia && (
                      <>
                        <td />
                        <td />
                      </>
                    )}
                  </tr>
                )}
                {!loading && dadosRelatorio?.length === 0 && (
                  <tr>
                    <td colSpan={7} style={{ textAlign: 'center' }}>
                      <h5 className="py-4">
                        Nenhum pagamento registrado com os filtros aplicados
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FechamentoDia;
