import styled, { css } from 'styled-components';
import { Colors } from '../../styles/constants';

interface DiaCabecalhoProps {
  temHorarioDisponivel?: boolean;
}

export const Container = styled.div`
  background-color: #fff;
  /* padding: 6px;
  margin: 10px;
  border: 1px solid #ebecf2; */
`;

export const HeaderAgenda = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0;

  button {
    & + button {
      margin-left: 4px;
    }
  }
`;

export const Calendario = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 450px;
  overflow-y: scroll;
`;

export const DiaCabecalho = styled.h5<DiaCabecalhoProps>`
  width: 100%;
  height: 30px;
  padding: 5px 2px;
  margin-bottom: 0;
  text-align: center;
  font-size: 0.9rem;
  color: ${Colors.secondary};
  font-weight: 600;
  text-transform: capitalize;
  border-top: 1px solid #ebecf2;
  border-right: 1px solid #ebecf2;
  border-left: 1px solid #ebecf2;
  ${props =>
    !props.temHorarioDisponivel &&
    css`
      background-color: #f6f6f6;
      cursor: not-allowed;
    `}
`;

export const Hora = styled.div`
  width: 45px;

  > div {
    border: 1px solid #ebecf2;
    border-right-width: 0;
    height: 50px;
    line-height: 50px;
    font-size: 0.8rem;
    text-align: right;
    padding: 0 3px 0 0;

    span {
      vertical-align: super;
      font-size: 9px;
    }

    & + div {
      border-top-width: 0;
    }
  }
`;

export const DiasContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Marcacao = styled.div`
  flex: 1;
  padding-bottom: 6px;

  > div {
    border: 1px solid #ebecf2;

    & + div {
      border-top-width: 0;
    }
  }
`;

export const AgendamentoContainer = styled.div`
  height: 47px;
  background-color: #cec0c0;
  color: #fff;
  position: absolute;
  top: 18px;
  display: flex;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }
`;

export const CalendarioAddContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;

  color: #a1a1a1;

  align-items: center;
  justify-content: center;
  //border: 1px solid #ebecf2;

  /* & + div {
    border-top: 1px solid #ebecf2;
  } */

  span {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 14px;
    place-content: center;
    align-items: center;
    background-color: #f6f6f6;
    cursor: not-allowed;
  }
`;

export const ButtonAdd = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  color: ${Colors.muted};
  // color: #1e1e1e;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
    font-weight: bold;
  }
`;

export const ButtonAgendamentoDoDia = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;

  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  padding: 0.25rem;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  width: 100%;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
    font-weight: bold;
  }

  svg {
    + svg {
      margin-left: 3px;
    }
  }
`;
