import React, { ChangeEvent, useCallback, useState } from 'react';

import { FiCamera } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';

import { useToast } from '../../hooks/toast';
import { useClinica } from '../../hooks/clinica';

import { Content, AvatarInput, Load } from './styles';
import api from '../../services/api';
import { pathToUpload } from '../../utils/options';

type AvatarProps = {
  codigo: number;
  nome: string;
  avatar_url: string | undefined;
  path: pathToUpload;
  circular?: boolean;
  handleReturnNewAvatarUrl(avatarUrl: string): void;
};

const Avatar: React.FC<AvatarProps> = ({
  nome,
  avatar_url,
  path,
  codigo,
  circular = false,
  handleReturnNewAvatarUrl,
}) => {
  const { addToast } = useToast();
  const { imagensClinica } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';
  const [avatarUrl, setAvatarUrl] = useState(
    avatar_url && avatar_url.length > 10 ? avatar_url : semFoto,
  );
  const [loading, setLoading] = useState(false);

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setLoading(true);
        try {
          const data = new FormData();

          data.append('avatar', e.target.files[0]);
          const pathFormatado = path === 'usuarios' ? 'users' : path;
          const response = await api.patch(
            `/${pathFormatado}/avatar/${codigo}`,
            data,
          );
          setAvatarUrl(response.data.avatar_url);

          handleReturnNewAvatarUrl(response.data.avatar_url);

          addToast({
            type: 'success',
            title: 'Avatar atualizado!',
          });
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Houve um erro ao atualziar o avatar',
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [addToast, handleReturnNewAvatarUrl, path, codigo],
  );

  return (
    <Content>
      {loading && (
        <Load>
          <FaSpinner size={22} /> carregando...
        </Load>
      )}
      <AvatarInput circular={circular}>
        <img src={avatarUrl} alt={nome} />
        <label htmlFor="avatar">
          <FiCamera />

          <input type="file" id="avatar" onChange={handleAvatarChange} />
        </label>
      </AvatarInput>
    </Content>
  );
};

export default Avatar;
