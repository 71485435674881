import React, { useCallback, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FiEdit, FiSave, FiTrash, FiXCircle } from 'react-icons/fi';

import { useToast } from '../../../../hooks/toast';
import InputMoney from '../../../../components/InputMoney';
import PopUpConfirmacao from '../../../../components/PopUpConfirmacao';

import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import { formatPrice } from '../../../../utils/funcoes';

import { IValoresProcedimento } from '../../ListaProcedimentos';

interface componentProps {
  valorConvenio: IValoresProcedimento;
  handleDeletou(convenio_id: number): void;
}
interface IFormDados {
  valor: number;
}

const LinhaValor: React.FC<componentProps> = ({
  valorConvenio,
  handleDeletou,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showAlterar, setShowAlterar] = useState(false);
  const [showExcluir, setShowExcluir] = useState(false);
  const [
    valorConvenioLocal,
    setvalorConvenioLocal,
  ] = useState<IValoresProcedimento>(valorConvenio);
  const formRefValor = useRef<FormHandles>(null);

  const handleAlterarValor = useCallback(
    async (dados: IFormDados) => {
      try {
        setLoading(true);
        formRefValor.current?.setErrors({});
        const schema = Yup.object().shape({
          valor: Yup.number().required(),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        const dadosFormatados = {
          convenio_id: valorConvenioLocal.convenio_id,
          procedimento_id: valorConvenioLocal.procedimento_id,
          valor: dados.valor,
        };

        await api.post(`procedimentos/valor`, dadosFormatados);

        setvalorConvenioLocal(state => {
          return {
            ...state,
            valor: dados.valor,
            valorFormatado: formatPrice(dados.valor),
          };
        });
        setShowAlterar(false);
      } catch (error: any) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefValor.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar valor',
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, valorConvenioLocal],
  );

  const handleDeleteValor = useCallback(async () => {
    try {
      setLoadingDelete(true);

      await api.delete(
        `procedimentos/valor/${valorConvenioLocal.procedimento_id}?convenio_id=${valorConvenioLocal.convenio_id}`,
      );

      setShowExcluir(false);
      handleDeletou(valorConvenioLocal.convenio_id);
    } catch (error: any) {
      console.log(error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefValor.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao atualizar valor',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
      });
    } finally {
      setLoadingDelete(false);
    }
  }, [
    addToast,
    handleDeletou,
    valorConvenioLocal.convenio_id,
    valorConvenioLocal.procedimento_id,
  ]);

  return (
    <tr
      className={showAlterar ? 'bg-light' : ''}
      data-id={valorConvenio.convenio_id}
    >
      <td>{valorConvenio.convenio}</td>
      <td>
        {!showAlterar && valorConvenioLocal.valorFormatado}
        <Form
          ref={formRefValor}
          initialData={valorConvenioLocal}
          onSubmit={handleAlterarValor}
        >
          {showAlterar && <InputMoney name="valor" placeholder="valor" />}
        </Form>
      </td>
      {!showAlterar && (
        <>
          <td>
            <Button
              type="button"
              variant="outline-primary"
              className="py-0"
              onClick={() => setShowAlterar(true)}
            >
              <FiEdit />
            </Button>
          </td>

          <td>
            <Button
              type="button"
              variant="outline-secondary"
              className="py-0"
              onClick={() => setShowExcluir(true)}
            >
              <FiTrash />
            </Button>
            {showExcluir && (
              <PopUpConfirmacao>
                <h5 className="text-center">
                  Atenção essa operação não poderá ser desfeita!
                </h5>
                <h6 className="text-center">
                  Excluir valor de{' '}
                  <span className="text-danger">
                    {valorConvenioLocal.convenio}
                  </span>
                  ?
                </h6>
                <div className="d-flex justify-content-around mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    className="py-0"
                    disabled={loadingDelete}
                    onClick={() => setShowExcluir(false)}
                  >
                    Não, voltar
                  </Button>
                  <Button
                    type="button"
                    variant="outline-secondary"
                    className="py-0"
                    disabled={loadingDelete}
                    onClick={() => handleDeleteValor()}
                  >
                    sim, excluir
                  </Button>
                </div>
              </PopUpConfirmacao>
            )}
          </td>
        </>
      )}
      {showAlterar && (
        <>
          <td>
            {!loading ? (
              <Button
                type="button"
                variant="outline-primary"
                className="py-0"
                title="Salvar"
                disabled={loading}
                onClick={() => formRefValor.current?.submitForm()}
              >
                <FiSave size={16} />
              </Button>
            ) : (
              <div className="spinner-border" />
            )}
          </td>

          <td>
            <Button
              type="button"
              variant="outline-secondary"
              className="py-0"
              title="Cancelar"
              disabled={loading}
              onClick={() => setShowAlterar(false)}
            >
              <FiXCircle size={16} />
            </Button>
          </td>
        </>
      )}
    </tr>
  );
};

export default LinhaValor;
