import React, { useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

import MainStructure from '../components/MainStructure';
import { TIPO_USUARIO } from '../utils/funcoes';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  showHeader?: boolean;
  titulo?: string;
  showSideBar?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  showHeader = true,
  titulo,
  showSideBar = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  useEffect(() => {
    if (titulo) document.title = titulo;
    window.scrollTo(0, 10);
  }, [titulo]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <>
            {user &&
            user.tipo === TIPO_USUARIO.MEDICO &&
            !location.pathname.toString().match('agendaatendimento') &&
            !location.pathname.toString().match('pacienteatendimento') &&
            !location.pathname.toString().match('pacienteatendimentoalvaro') &&
            !location.pathname.toString().match('profile') &&
            !location.pathname.toString().match('exame') &&
            !location.pathname.toString().match('impressaodocumento') &&
            !location.pathname.toString().match('sair') ? (
              <>
                <Redirect
                  to={{
                    pathname: '/agendaatendimento',
                    state: { from: location },
                  }}
                />
              </>
            ) : (
              <>
                {isPrivate ? (
                  <MainStructure>
                    <Component />
                  </MainStructure>
                ) : (
                  <Component />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/dashboard',
                state: { from: location },
              }}
            />
          </>
        );
      }}
    />
  );
};

export default Route;
