import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { parseISO, format } from 'date-fns';

import {
  FiArrowUpCircle,
  FiSearch,
  FiGift,
  FiPlusCircle,
} from 'react-icons/fi';
import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../services/api';

import {
  formataTelefone,
  formataCPF,
  formataCNPJ,
  formataIdade,
} from '../../utils/funcoes';

import { useToast } from '../../hooks/toast';
import { useClinica } from '../../hooks/clinica';

import Input from '../../components/Input';
import Paginacao from '../../components/Paginacao';

import Linha from './Linha';
import SkeletonCliente from './Skeleton';

import getValidationErrors from '../../utils/getValidationErrors';

import getResponseErrors from '../../utils/getResponseErrors';

export interface ICliente {
  id: number;
  pessoaFisica: boolean;
  nome: string;
  dataNasc: Date;
  cpf: string;
  cnpj: string;
  telefone: string;
  celular: string;
  email: string;
  avatar_url: string;
  idade: string;
  sexo: string;
  dataNascFormatada: string;
  telefoneFormatado: string;
  convenio: string;
}

interface IReturnCliente {
  data: ICliente[];
  count: number;
}

interface IFormBusca {
  termoBusca: string;
}

interface IPaginacao {
  termoBusca: string;
  limite: number;
  pagina: number;
}

const Cliente: React.FC = () => {
  const { addToast } = useToast();
  const { imagensClinica } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const history = useHistory();
  const formRefBusca = useRef<FormHandles>(null);
  const [clientes, setClientes] = useState<ICliente[] | undefined>(undefined);
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [paginacao, setPaginacao] = useState<IPaginacao>();

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        if (!paginacao) return;
        const response = await api.get<IReturnCliente>(
          `/clientes?termoBusca=${paginacao.termoBusca}&pagina=${paginacao.pagina}&limite=${paginacao.limite}`,
        );
        const pacientesApi = response.data.data;
        const total = response.data.count;

        const pacientesFormatados = pacientesApi.map(cli => {
          const dataNasc =
            cli.dataNasc && cli.dataNasc.toString().length > 9
              ? parseISO(cli.dataNasc.toString())
              : new Date();

          const telefoneFormatado =
            cli.telefone && cli.telefone.length > 0
              ? `${formataTelefone(cli.celular)} - ${formataTelefone(
                  cli.telefone,
                )}`
              : formataTelefone(cli.celular);

          return {
            ...cli,
            dataNascFormatada: format(dataNasc, 'dd/MM/yyyy'),
            telefoneFormatado,
            cpf: `CPF: ${formataCPF(cli.cpf)}`,
            cnpj: `CNPJ: ${formataCNPJ(cli.cnpj)}`,
            idade: formataIdade(dataNasc, false),
            avatar_url: cli.avatar_url ? cli.avatar_url : semFoto,
            convenio: 'particular',
          };
        });

        setClientes(pacientesFormatados);

        setTotalRegistros(total);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível buscar os dados do paciente',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }

    if (paginacao) loadDados();
  }, [addToast, paginacao, semFoto]);

  useEffect(() => {
    setPaginacao({
      termoBusca: '',
      pagina: 1,
      limite: 20,
    });
  }, []);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        termoBusca: Yup.string(),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });

      setClientes(undefined);

      setPaginacao({
        termoBusca: dados.termoBusca,
        pagina: 1,
        limite: 20,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  return (
    <Container fluid className="content">
      <>
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <Button
                type="button"
                variant="outline-primary"
                onClick={() => history.push('/pacientecadastro/0')}
              >
                <FiPlusCircle size={18} /> Novo Paciente
              </Button>

              {/* Revisar */}
              {/* <Button
                type="button"
                variant="outline-secondary"
                className="ml-3"
                onClick={() => history.push('/aniversariantes')}
              >
                <FiGift size={18} />
              </Button> */}
            </div>
            <div className="col-auto d-flex justify-content-end">
              <div className="doc-badge me-3">
                Total Pacientes{' '}
                <span className="bg-danger text-white px-2 py-1">
                  {totalRegistros}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-3">
                <h5 id="topoBusca" className="card-title">
                  Pacientes
                </h5>
              </div>
              <div className="col d-flex justify-content-end">
                <Form ref={formRefBusca} onSubmit={handleSubmitBusca}>
                  <Row>
                    <Col sm={12} md={9}>
                      <div className="d-flex">
                        <div style={{ flex: 1, minWidth: '250px' }}>
                          <Input
                            name="termoBusca"
                            placeholder="Procurar por nome, telefone ou cpf"
                            textUppercase={false}
                            maxLength={100}
                            minLength={3}
                          />
                        </div>
                        <div>
                          <Button
                            type="submit"
                            size="sm"
                            variant="outline-secondary"
                            className="border-0"
                          >
                            <FiSearch />
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={3} className="text-right" />
                  </Row>
                </Form>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="datatable table table-borderless hover-table dataTable no-footer">
                <thead className="thead-light">
                  <tr>
                    <th style={{ width: '90px' }}>Codigo</th>
                    <th>Nome</th>
                    <th>Contato</th>
                    <th>Identificação</th>
                  </tr>
                </thead>
                <tbody>
                  {clientes &&
                    !loading &&
                    clientes.map(cliente => (
                      <Linha key={`cliente-${cliente.id}`} cliente={cliente} />
                    ))}
                  {loading && (
                    <>
                      <SkeletonCliente />
                      <SkeletonCliente />
                    </>
                  )}
                  <tr>
                    <td colSpan={4} />
                  </tr>
                </tbody>
              </table>

              {!clientes && !loading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: '250px' }}
                >
                  <h5>
                    Pesquise clientes pelo Nome, pelo Telefone ou pelo CPF
                  </h5>
                </div>
              )}
            </div>

            {paginacao && (
              <Paginacao
                limitePagina={paginacao?.limite || 20}
                paginasVizinhas={2}
                totalRegistros={totalRegistros}
                paginaInicio={paginacao?.pagina}
                handleProximaPagina={handleProximaPagina}
              />
            )}

            {clientes && (
              <a
                href="#topoBusca"
                className="btn btn-primary"
                style={{
                  position: 'fixed',
                  bottom: '20px',
                  right: '35px',
                  opacity: 0.8,
                }}
              >
                <FiArrowUpCircle size={20} />
              </a>
            )}
          </div>
        </div>
      </>
    </Container>
  );
};

export default Cliente;
