import React from 'react';

import LayoutDrSaude from './DrSaude';

type ComponentProps = {
  CliCodigo?: number;
  assinatura?: {
    nome: string;
    especialidade: string;
    documento: string;
    conselho: string;
  };
  rodape?: boolean;
  duasVias?: boolean;
};

const LayoutImpressao: React.FC<ComponentProps> = ({
  CliCodigo,
  assinatura,
  rodape = true,
  duasVias,
  children,
}) => {
  return (
    <LayoutDrSaude
      CliCodigo={CliCodigo}
      assinatura={assinatura}
      rodape={rodape}
      duasVias={duasVias}
    >
      {children}
    </LayoutDrSaude>
  );
};

export default LayoutImpressao;
