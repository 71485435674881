/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import { formatPrice, TIPO_USUARIO } from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';
import { useEspecialidade } from '../../../hooks/especialidade';
import { useProfissional } from '../../../hooks/profissional';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import SelectSync, { IOption } from '../../../components/SelectSync';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';

import { arrayGroupBy } from '../../../utils/funcoes';

import getResponseErrors from '../../../utils/getResponseErrors';
import PageInfo from '../../../components/PageInfo';
import HeaderRelatorio from '../HeaderRelatorio';

interface IRelatorio {
  AgeCodigo: number;
  AgeTipo: string;
  AgeInicio: Date;
  AgeInicioFormatado: string;
  AgeStatus: number;
  AgeClinica: number;
  PacNome: string;
  Procedimento: string;
  ProValor: number;
  ProValorFormatado: string;
  ProValorRealTotal: number;
  ProValorRealTotalFormatado: string;
  ConCodigo: number;
  ConNome: string;
  ConUsuCodigo: number;
  PerCodigo: number;
  CprClinica: number;
  CprValor: number;
  CprValorPag: number;
  CprValorFormatado: string;
  CprValorPagFormatado: string;
  FormaPag: string;
}

interface IRelatorioFormatado {
  grupo: string;
  totalDesconto: number;
  totalDescontoFormatado: string;
  total: number;
  totalFormatado: string;
  totalPago: number;
  totalPagoFormatado: string;
  totalDinheiro: number;
  totalCredito: number;
  totalDebito: number;
  totalDinheiroFormatado: string;
  totalCreditoFormatado: string;
  totalDebitoFormatado: string;
  dados: IRelatorio[];
}

interface ITotais {
  totalDescontoFormatado: string;
  totalFormatado: string;
  totalPagoFormatado: string;
}

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  CliCodigo: string;
  PerCodigo: string;
  ConUsuCodigo: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

const FechamentoParceiro: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user, clinica } = useAuth();
  const { optionsClinica } = useClinica();

  const { optionsEspecialidade, buscaEspecialidade } = useEspecialidade();
  const { optionsProfissional, buscaOptionsProfissional } = useProfissional();

  const [optionEspecialidade, setOptionEspecialidade] = useState<IOption[]>([]);

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorioFormatado[]>();
  const [totais, setTotais] = useState<ITotais>();
  const initialData = {
    dataInicio: format(new Date(), 'yyyy-MM-dd'),
    dataFim: format(new Date(), 'yyyy-MM-dd'),
    CliCodigo: user.clinica_id.toString(),
  };
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinicaSelecionada, setClinicaSelecionada] = useState('');

  const exibeTotais = user.tipo === TIPO_USUARIO.ADMINISTRADOR;

  const informacoesPagina = `<b>Descrição:</b> Feito para fazer o acerto de contas com o terceiro, <b>Raio-X e Tomografia</b>, pega a quantidade de procedimento e multiplica pelo valor que está na descrição do procedimento.`;

  useEffect(() => {
    async function loadPerfis(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadPerfis();
  }, [buscaEspecialidade, optionsEspecialidade]);

  useEffect(() => {
    async function loadMedicos(): Promise<void> {
      await buscaOptionsProfissional();
    }
    if (!optionsProfissional || Object.keys(optionsProfissional).length === 0)
      loadMedicos();
  }, [buscaOptionsProfissional, optionsProfissional]);

  useEffect(() => {
    try {
      setOptionEspecialidade(optionsEspecialidade.optionEspecialidades);
    } catch (error) {
      addToast({
        title: 'Não foi possível buscar perfis',
        type: 'error',
      });
    }
  }, [addToast, optionsEspecialidade]);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          dataFim: Yup.date().required(),
          CliCodigo: Yup.string().required(),
          PerCodigo: Yup.string().nullable(true),
          ConUsuCodigo: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinicaSelecionada(
          optionsClinica.find(cli => cli.value === dados.CliCodigo)?.label ||
            '',
        );

        const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
        const dataFimSelecionada = parseISO(dados.dataFim.toString());

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        });

        let parametrosOpcionais = dados.ConUsuCodigo
          ? `&ConUsuCodigo=${dados.ConUsuCodigo}`
          : '';
        parametrosOpcionais += dados.PerCodigo
          ? `&PerCodigo=${dados.PerCodigo}`
          : '';

        const response = await api.get<IRelatorio[]>(
          `/relatorios/procedimentosrealizadosvalor?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}&CliCodigo=${dados.CliCodigo}${parametrosOpcionais}`,
        );

        let totalGeral = 0;
        let totalGeralDesconto = 0;
        let totalGeralPago = 0;

        const regexp = new RegExp('[^0-9]', 'gi');
        const dadosFormatados = response.data.map(rel => {
          const valor = rel.Procedimento.replace(regexp, '');
          const valorReal = (Number(valor) / 100) * rel.ProValor;
          return {
            ...rel,
            AgeInicioFormatado: format(
              parseISO(rel.AgeInicio.toString()),
              'dd/MM/yyyy',
            ),
            ProValorFormatado: formatPrice(rel.ProValor)
              .replace(/\s/g, '')
              .replace('R$', ''),
            CprValorFormatado: formatPrice(rel.CprValor),
            CprValorPagFormatado: formatPrice(rel.CprValorPag),
            ProValorRealTotal: valorReal,
            ProValorRealTotalFormatado: formatPrice(valorReal),
          };
        });

        const ordenado = arrayGroupBy(dadosFormatados, i => i.ConNome);

        const ordenadoFormatado = ordenado.map(ord => {
          const totalPorAgendamento = Array.from(
            new Set(ord.map(dado => dado.AgeCodigo)),
          ).map(AgeCodigo => {
            return {
              AgeCodigo,
              CprValorPag: ord.find(dad => dad.AgeCodigo === AgeCodigo)
                ?.CprValorPag,
            };
          });

          const totalPago = totalPorAgendamento.reduce((tot, item) => {
            return tot + Number(item.CprValorPag);
          }, 0);

          totalGeralPago += totalPago;

          const total = ord.reduce((tot, item) => {
            return tot + Number(item.ProValor);
          }, 0);

          const pagoDinheiro = ord.filter(item => item.FormaPag === 'Dinheiro');

          const pagoCredito = ord.filter(
            item => item.FormaPag === 'Cartão de Crédito',
          );

          const pagoDebito = ord.filter(
            item => item.FormaPag === 'Cartão de Débito',
          );

          const totalDinheiro = pagoDinheiro.reduce((tot, item) => {
            return tot + Number(item.ProValorRealTotal);
          }, 0);

          const totalCredito = pagoCredito.reduce((tot, item) => {
            return tot + Number(item.ProValorRealTotal);
          }, 0);

          const totalDebito = pagoDebito.reduce((tot, item) => {
            return tot + Number(item.ProValorRealTotal);
          }, 0);

          totalGeral += total;

          const totalDesconto = total - totalPago;
          totalGeralDesconto += totalDesconto;

          return {
            grupo: ord[0].ConNome,
            totalDesconto,
            totalDescontoFormatado: formatPrice(totalDesconto),
            total,
            totalFormatado: formatPrice(total),
            totalPago,
            totalPagoFormatado: formatPrice(totalPago),
            totalCredito,
            totalDebito,
            totalDinheiro,
            totalCreditoFormatado: formatPrice(totalCredito),
            totalDebitoFormatado: formatPrice(totalDebito),
            totalDinheiroFormatado: formatPrice(totalDinheiro),
            dados: ord,
          };
        });

        setDadosRelatorio(ordenadoFormatado);
        setTotais({
          totalFormatado: formatPrice(totalGeral),
          totalPagoFormatado: formatPrice(totalGeralPago),
          totalDescontoFormatado: formatPrice(totalGeralDesconto),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="mt-2">
      {loading && <Loading />}
      <>
        <div className="d-print-none bg-white border p-2 mb-2">
          <div className="p-2 mb-2 d-flex justify-content-between">
            <h2 id="topoBusca">Procedimentos Realizados Valor</h2>
            <PageInfo
              descricao={informacoesPagina}
              permissao={TIPO_USUARIO.SUPERVISOR}
            />
          </div>

          {optionsClinica.length > 0 && user && (
            <Form
              ref={formRefBusca}
              initialData={initialData}
              onSubmit={handleSubmitBusca}
            >
              <Row>
                <Col md={2}>
                  <Input name="dataInicio" label="De" type="date" required />
                </Col>
                <Col md={2}>
                  <Input name="dataFim" label="Até" type="date" required />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="PerCodigo"
                    label="Perfil"
                    options={optionEspecialidade}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                <Col md={2}>
                  <SelectSync
                    name="ConUsuCodigo"
                    label="Médico"
                    options={optionsProfissional}
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                  />
                </Col>
                <Col md={2}>
                  <Select
                    name="CliCodigo"
                    label="Clinica"
                    options={optionsClinica}
                    disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                  />
                </Col>
                <Col md={2}>
                  <br />
                  <Button type="submit" size="sm" variant="outline-secondary">
                    BUSCAR
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>

        <HeaderRelatorio
          dataImpressao={dataExibe?.dataHora}
          titulo={`FECHAMENTO DE TERCEIRO (RAIO-X e TOMOGRAFIA) ${
            clinicaSelecionada.length > 0 ? `- ${clinicaSelecionada} -` : '-'
          } ${dataExibe?.dataDiaSemana || ''}`}
        />

        <div className="bg-white border p-2">
          {dadosRelatorio && (
            <>
              <table
                className="table table-hover table-sm table-font-small table-bordered"
                style={{ borderWidth: '0px' }}
              >
                <thead>
                  <tr className="text-center">
                    <th style={{ width: '80px' }}>Data</th>
                    <th>Paciente</th>
                    <th>Procedimento</th>
                    <th style={{ minWidth: '115px' }}>Valor</th>
                    <th>Forma de Pagto</th>
                    <th>Valor Total Real</th>
                  </tr>
                </thead>
                <tbody>
                  {dadosRelatorio &&
                    dadosRelatorio.map(rel => (
                      <React.Fragment key={`pro-${rel.grupo}`}>
                        <tr
                          style={{
                            fontSize: 'Small',
                            height: '30px',
                            fontWeight: 'bold',
                          }}
                        >
                          <td colSpan={6}>
                            <div className="d-flex justify-content-between">
                              {rel.grupo}{' '}
                              <span>Nº Registros: {rel.dados.length}</span>
                            </div>
                          </td>
                        </tr>
                        {rel.dados.map(relatorio => (
                          <tr
                            key={`proced-${rel.grupo}-${relatorio.AgeCodigo}-${relatorio.Procedimento}`}
                            data-id={relatorio.AgeCodigo}
                            style={{ fontSize: '.7rem', height: '30px' }}
                          >
                            <td>{relatorio.AgeInicioFormatado}</td>
                            <td>{relatorio.PacNome}</td>
                            <td>{relatorio.Procedimento}</td>
                            <td>{relatorio.ProValorFormatado}</td>
                            <td>{relatorio.FormaPag}</td>
                            <td>{relatorio.ProValorRealTotalFormatado}</td>
                          </tr>
                        ))}
                        <tr
                          style={{
                            fontSize: 'Small',
                            height: '30px',
                            fontWeight: 'bold',
                          }}
                        >
                          <td colSpan={exibeTotais ? 2 : 6}>
                            <div className="d-flex justify-content-between">
                              {rel.grupo}{' '}
                              <span>Nº Registros: {rel.dados.length}</span>
                            </div>
                          </td>
                          {exibeTotais && (
                            <>
                              <td>Total Pago: {rel.totalPagoFormatado}</td>
                              <td>
                                Total Dinheiro: {rel.totalDinheiroFormatado}
                              </td>
                              <td>Total Débito: {rel.totalDebitoFormatado}</td>
                              <td>
                                Total Crédito: {rel.totalCreditoFormatado}
                              </td>
                            </>
                          )}
                        </tr>
                      </React.Fragment>
                    ))}
                  {/* {exibeTotais && (
                    <tr
                      key="fdfdsfsdfdf"
                      style={{
                        fontSize: 'Small',
                        height: '30px',
                        fontWeight: 'bold',
                      }}
                    >
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        Total Geral: {totais?.totalFormatado}
                      </td>
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        Total Pago: {totais?.totalPagoFormatado}
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </table>
            </>
          )}
        </div>
      </>
    </Container>
  );
};

export default FechamentoParceiro;
