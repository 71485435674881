import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { differenceInMinutes } from 'date-fns';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Button from '../../components/Button';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/auth';
import { useClinica } from '../../hooks/clinica';

import { Container, Content } from './styles';
import Loading from '../../components/Loading';

interface SignInFormData {
  email: string;
  password: string;
  clinica: string;
}

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const {
    optionsClinica,
    buscaClinicas,
    imagensClinica,
    dataHoraServidor,
    loading: carregando,
  } = useClinica();

  const Logo =
    imagensClinica.find(img => img.nome === 'logo')?.arquivo_url || '';
  // const BackLogin =
  //   imagensClinica.find(img => img.nome === 'backLogin')?.arquivo_url || '';

  useEffect(() => {
    if (!optionsClinica || optionsClinica.length === 0) buscaClinicas();
  }, [optionsClinica, buscaClinicas]);

  const exibeComboClinica = optionsClinica && optionsClinica.length > 1;

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
          clinica: Yup.string().required('Clínica obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await signIn({
          email: data.email,
          password: data.password,
          codClinica: Number(data.clinica),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          return;
        }
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [signIn],
  );

  const horaLocalEstaCerta = useMemo(() => {
    if (!dataHoraServidor) return true;
    const horaServer = new Date(dataHoraServidor.toString());
    return Math.abs(differenceInMinutes(horaServer, new Date())) <= 60;
  }, [dataHoraServidor]);

  return (
    <Container>
      {carregando && <Loading />}
      {/* <Background /> */}
      <Content>
        <img src={Logo} alt="Logo Clinica" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Login</h1>
          <p className="text-muted">Faça login com sua conta para continuar</p>
          <Input
            name="email"
            required
            placeholder="Digite seu e-mail"
            textUppercase={false}
          />
          <br />
          <Input
            name="password"
            type="password"
            required
            placeholder="Senha"
            textUppercase={false}
          />
          <br />
          <div className={!exibeComboClinica ? 'd-none' : ''}>
            <Select name="clinica" options={optionsClinica} />
          </div>

          {optionsClinica && horaLocalEstaCerta && (
            <Button loading={loading} type="submit" className="mt-2 w-100">
              Login
            </Button>
          )}

          <br />
          {!optionsClinica && (
            <div className="alert alert-danger p-2">
              Houve uma falha na conexão, caso o problema persista entre em
              contato com o suporte.
            </div>
          )}
          {!carregando && !horaLocalEstaCerta && (
            <div className="alert alert-danger p-2">
              Data e hora configurada no computador incompátivel com o servidor.
              Por Favor, faça o ajuste de data e hora do computador antes de
              realizar o login
            </div>
          )}

          {/* <Link to="/forgot-password">Esqueci minha senha</Link> */}
        </Form>
      </Content>
    </Container>
  );
};

export default SignIn;
