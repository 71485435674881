/* eslint-disable prefer-destructuring */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import Select, {
  GroupTypeBase,
  OptionTypeBase,
  Props as SelectProps,
  Styles,
} from 'react-select';

import { useField } from '@unform/core';

import { Label } from './styles';

import { Content, Erro } from '../DatePicker/styles';

export interface ProviderProps {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  containerStyle?: React.CSSProperties;
  options: IOption[];
  tipoBusca?: boolean;
  // icon?: React.ComponentType<IconBaseProps>;
}

const SelectSync: React.FC<Props> = ({ name, label, ...rest }) => {
  const selectRef = useRef(null);
  const [hasLabel, setHasLabel] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [focused, setFocused] = useState(false);
  const handleChange = useCallback(e => {
    if (!e) {
      setHasLabel(false);
      return;
    }
    setHasLabel(true);
  }, []);

  const customStyles: Partial<
    Styles<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>
  > = {
    indicatorSeparator: (provided: ProviderProps) => ({
      ...provided,
      display: 'none',
    }),
    container: (provided: ProviderProps) => ({
      ...provided,
      position: 'relative',
      boxSizing: 'border-box',
      width: '100% !important',
    }),
    control: (provided: ProviderProps) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: '100%',
      display: 'flex',
      height: '32px',
      padding: 0,
      border: 'none',
      background: 'none',
      transition: 'all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)',
      transitionProperty: 'fontSize',
      fontSize: 16,
      color: '#303030',
      fontFamily: 'inherit',
      lineHeight: '32px',

      borderBottom: '1px solid #d3d3d3',
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      // path: 'state.value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        if (value && value !== '') setHasLabel(true);
        try {
          // eslint-disable-next-line no-param-reassign
          ref.state.value = ref.select.props.options.filter(
            (option: OptionTypeBase) => option.value === value,
          )[0];
        } catch (err) {
          // console.log(error);
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <>
      <Content>
        <Label htmlFor={name} exibe={hasLabel || focused}>
          {label}
        </Label>
        <Select
          styles={customStyles}
          classNamePrefix="react-select"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={selectRef}
          isLoading={false}
          // options={options}
          defaultValue={defaultValue}
          // components={
          //   tipoBusca && { DropdownIndicator, IndicatorSeparator: null }
          // }
          {...rest}
        />

        {error && <Erro className="error">{error}</Erro>}
      </Content>
    </>
  );
};
export default SelectSync;
