import React from 'react';
import InputExame from '../Exame/InputExame';

type DadosAdicionaisProps = {
  Dado: string;
};

const DadosAdicionais: React.FC<DadosAdicionaisProps> = ({ Dado }) => {
  switch (Dado) {
    case 'Volume urinário':
      return (
        <InputExame
          label="Volume urinário"
          type="text"
          name="volume"
          placeholder="Volume urinário"
          maxLength={6}
          id="volume"
          unidade="ml"
        />
      );

    case 'Volume':
      return (
        <InputExame
          label="Volume"
          type="text"
          name="volume"
          placeholder="Volume"
          maxLength={6}
          id="volume"
          unidade="ml"
        />
      );

    case 'Peso':
      return (
        <InputExame
          label="Peso"
          type="text"
          name="peso"
          placeholder="Peso"
          maxLength={3}
          id="peso"
          unidade="kg"
        />
      );

    case 'Altura':
      return (
        <InputExame
          label="Altura"
          type="text"
          name="altura"
          placeholder="Altura"
          maxLength={3}
          id="altura"
          unidade="cm"
        />
      );

    case 'Valor do Hematócrito':
      return (
        <InputExame
          label="Valor do Hematócrito"
          type="text"
          name="hematocrito"
          placeholder="Valor do Hematócrito"
          maxLength={3}
          id="hematocrito"
          unidade="dc"
        />
      );

    case 'Tipo de amostra:':
      return (
        <InputExame
          label="Tipo de amostra"
          type="text"
          name="tipoamostra"
          placeholder="Tipo de amostra"
          maxLength={3}
          id="tipoamostra"
        />
      );

    case 'Tipo de Amostra':
      return (
        <InputExame
          label="Tipo de amostra"
          type="text"
          name="tipoamostra"
          placeholder="Tipo de amostra"
          maxLength={3}
          id="tipoamostra"
        />
      );

    case 'Número de leucócitos':
      return (
        <InputExame
          label="Número de leucócitos"
          type="text"
          name="leucocitos"
          placeholder="Número de leucócitos"
          maxLength={3}
          id="leucocitos"
          unidade="cédula/ml"
        />
      );

    case 'NOROVÍRUS RNA':
      return (
        <InputExame
          label="NOROVÍRUS RNA"
          type="text"
          name="norovirusrna"
          placeholder="NOROVÍRUS RNA"
          maxLength={3}
          id="norovirusrna"
        />
      );

    case 'Idade Materna':
      return (
        <InputExame
          label="Idade Materna"
          type="text"
          name="idadematerna"
          placeholder="Idade Materna"
          maxLength={3}
          id="idadematerna"
          unidade="anos"
        />
      );

    case 'Data da última menstruação':
      return (
        <InputExame
          label="DUM"
          type="text"
          name="dum"
          placeholder="DUM"
          maxLength={8}
          id="dum"
          unidade="(DDMMAAAA)"
        />
      );

    case 'Idade Gestacional (semanas)':
      return (
        <InputExame
          label="Idade Gestacional (semanas)"
          type="text"
          name="idadegestacionalsemanas"
          placeholder="Idade Gestacional (semanas)"
          maxLength={3}
          id="idadegestacionalsemanas"
          unidade="nº sem"
        />
      );

    case 'CPF':
      return (
        <InputExame
          label="CPF"
          type="text"
          name="cpf"
          placeholder="CPF"
          maxLength={11}
          id="cpf"
        />
      );

    case 'RG':
      return (
        <InputExame
          label="RG"
          type="text"
          name="rg"
          placeholder="RG"
          maxLength={10}
          id="rg"
        />
      );

    case 'NÚMERO DA CADEIA DE CUSTÓDIA':
      return (
        <InputExame
          label="NÚMERO DA CADEIA DE CUSTÓDIA"
          type="text"
          name="numerocadeiacustodia"
          placeholder="NÚMERO DA CADEIA DE CUSTÓDIA"
          maxLength={3}
          id="numerocadeiacustodia"
        />
      );

    default:
      return <span />;
  }
};

export default DadosAdicionais;
