/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import { ORIGEM_PAGAMENTO, TIPO_USUARIO } from '../../../utils/funcoes';

import { IOptions } from '../../../utils/options';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';

import Input from '../../../components/Input';
import SelectSync from '../../../components/SelectSync';
import Loading from '../../../components/Loading';

import Modal from '../../../components/Modal';
import Pagamento from '../../../components/Pagamento';

import getValidationErrors from '../../../utils/getValidationErrors';

import getResponseErrors from '../../../utils/getResponseErrors';

import HeaderRelatorio from '../HeaderRelatorio';
import PageInfo from '../../../components/PageInfo';
import Fechamento from './Fechamento';

interface IDadosPagamento {
  tituloPagamento: string;
  pagamentoSelecionado?: number;
  codOrigem?: number;
}

interface IItem {
  codigo: number;
  descricao: string;
  valor: number;
  valorFormatado: string;
}

interface IRelatorio {
  despesas: IItem[];
  totalDespesas: number;
  totalDespesasFormatado: string;
  receitas: IItem[];
  totalReceitas: number;
  totalReceitasFormatado: string;
  trocos: IItem[];
  sangrias: IItem[];
  totalCaixaFormatado: string;
  totalGeral: number;
  totalGeralFormatado: string;
}

interface IFormBusca {
  data: Date;
  clinica_id: string;
  usuario_id: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

interface IDadosPagamentoOrigem extends IDadosPagamento {
  CprOrigem: number;
}

const FechamentoCaixa: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [showModalPagamento, setShowModalPagamento] = useState(false);

  const { optionsClinica } = useClinica();

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dadosBusca, setDadosBusca] = useState<IFormBusca>();
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [nomeClinica, setNomeClinica] = useState('');
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [dadosPagamento, setDadosPagamento] = useState<
    IDadosPagamentoOrigem | undefined
  >();
  const [optionsUsuario, setOptionsUsuario] = useState<IOptions[]>([]);
  const [loadingUsuario, setLoadingUsuario] = useState(false);

  // verificar esta permissão
  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.ATENDENTE_CAIXA) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const informacoesPagina = `<b>Descrição:</b> Relatório do espelho do caixa, com todos os pagamentos recebidos no dia e todas as despesas lançadas. `;

  const initialData = {
    data: format(new Date(), 'yyyy-MM-dd'),
    clinica_id: user.clinica_id.toString(),
  };

  const handleBuscaUsuariosBaixa = useCallback(
    async (dados: IFormBusca) => {
      try {
        setLoadingUsuario(true);
        const dataFormatada = format(dados.data, 'yyyy-MM-dd');

        const response = await api.get<IOptions[]>(
          `/relatorios/usuariosfechamentocaixa?data=${dataFormatada}&clinica_id=${dados.clinica_id}`,
        );

        setOptionsUsuario(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingUsuario(false);
      }
    },

    [],
  );

  useEffect(() => {
    handleBuscaUsuariosBaixa({
      clinica_id: user.clinica_id.toString(),
      usuario_id: '0',
      data: new Date(),
    });
  }, [handleBuscaUsuariosBaixa, user.clinica_id]);

  const handleChangeData = useCallback(() => {
    if (formRefBusca && formRefBusca.current) {
      const dataSelecionada = String(
        formRefBusca.current.getFieldValue('data'),
      );

      if (dataSelecionada.length !== 10) return;

      const clinica_id = String(
        formRefBusca.current.getFieldValue('clinica_id'),
      );

      handleBuscaUsuariosBaixa({
        clinica_id,
        usuario_id: '0',
        data: parseISO(dataSelecionada),
      });
    }
  }, [handleBuscaUsuariosBaixa]);

  const handleChangeClinica = useCallback(
    ({ value, label }) => {
      if (formRefBusca && formRefBusca.current) {
        const dataSelecionada = String(
          formRefBusca.current.getFieldValue('data'),
        );

        if (dataSelecionada.length !== 10) return;

        handleBuscaUsuariosBaixa({
          clinica_id: value,
          usuario_id: '0',
          data: parseISO(dataSelecionada),
        });
      }
    },
    [handleBuscaUsuariosBaixa],
  );

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      console.log('optionsUsuario', optionsUsuario);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          data: Yup.date().required(),
          clinica_id: Yup.string().required(),
          usuario_id: Yup.string().nullable(true),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setNomeClinica(
          optionsClinica.find(cli => cli.value === dados.clinica_id)?.label ||
            '',
        );

        const dataSelecionada = parseISO(dados.data.toString());

        const dataFormatada = format(dataSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: format(dataSelecionada, 'dd/MM/yyyy EEEE', {
            locale: ptBR,
          }).toUpperCase(),
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        });

        setNomeUsuario(
          optionsUsuario.find(cli => cli.value === dados.usuario_id)?.label ||
            '',
        );

        setDadosBusca({
          ...dados,
          data: dataSelecionada,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica, optionsUsuario],
  );

  const handleFecharModalPagamento = useCallback(async (load: boolean) => {
    setShowModalPagamento(false);
    if (load) {
      formRefBusca.current?.submitForm();
      setDadosPagamento(undefined);
    }
  }, []);

  const handlePagamento = useCallback(
    (dados: IDadosPagamentoOrigem) => {
      if (user.id === 1) {
        setDadosPagamento(dados);

        setShowModalPagamento(true);
      }
    },
    [user.id],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading />}
      {showModalPagamento && (
        <Modal
          show={showModalPagamento}
          titulo={dadosPagamento?.tituloPagamento || ''}
          handleFecharModal={() => setShowModalPagamento(false)}
        >
          <Pagamento
            handleFechar={handleFecharModalPagamento}
            tipo={
              dadosPagamento?.CprOrigem?.toString() ||
              ORIGEM_PAGAMENTO.SANGRIA.toString()
            }
            cprCodigo={dadosPagamento?.pagamentoSelecionado}
          />
        </Modal>
      )}

      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              Fechamento de Caixa
            </span>
          </div>
        </div>
      </div>

      {optionsClinica.length > 0 && user && (
        <>
          <div className="card">
            <div className="card-header">
              <Form
                ref={formRefBusca}
                initialData={initialData}
                onSubmit={handleSubmitBusca}
              >
                <Row>
                  <Col md={3}>
                    <Input
                      name="data"
                      label="Data"
                      type="date"
                      required
                      onBlur={handleChangeData}
                    />
                  </Col>
                  <Col md={3}>
                    <SelectSync
                      name="usuario_id"
                      label="Colaborador"
                      options={optionsUsuario}
                      isClearable
                      handleSelectChange={handleOnChangeVazio}
                      isLoading={loadingUsuario}
                    />
                  </Col>
                  <Col md={3}>
                    <SelectSync
                      name="clinica_id"
                      label="Clinica"
                      options={optionsClinica}
                      disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                      handleSelectChange={handleChangeClinica}
                    />
                  </Col>
                  <Col md={2}>
                    <br />
                    <Button type="submit" size="sm" variant="outline-secondary">
                      BUSCAR
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>

            <HeaderRelatorio
              dataImpressao={dataExibe?.dataHora}
              titulo={`FECHAMENTO DE CAIXA ${
                nomeClinica.length > 0 ? `- ${nomeClinica} -` : '-'
              } ${dataExibe?.dataDiaSemana || ''}${
                nomeUsuario.length > 0 ? ` - ${nomeUsuario}` : ''
              }`}
            />

            <div className="card-body p-0">
              {dadosBusca && (
                <Fechamento
                  data={dadosBusca.data}
                  clinica_id={Number(dadosBusca.clinica_id)}
                  usuario_id={Number(dadosBusca.usuario_id)}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default FechamentoCaixa;
