import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Row, Col } from 'react-bootstrap';
import { FiPlusCircle } from 'react-icons/fi';

import { useEspecialidade } from '../../../hooks/especialidade';
import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';

import Input from '../../../components/Input';
import SelectSync, { IOption } from '../../../components/SelectSync';
import getValidationErrors from '../../../utils/getValidationErrors';
import InputFake from '../../../components/InputFake';
import Toggle from '../../../components/Exame/Toggle';
import { IProcedimento, IValoresProcedimento } from '../ListaProcedimentos';
import Loading from '../../../components/Loading';
import api from '../../../services/api';
import { formatPrice } from '../../../utils/funcoes';
import getResponseErrors from '../../../utils/getResponseErrors';

import Linha from './Linha';
import AddValor from './AddValor';

type Props = {
  id: number;
  addProcedimento(
    dadosProcedimento: IProcedimento,
    espec?: IOption,
    id?: number,
  ): Promise<boolean>;
};

interface IExameAlvaro {
  codigo: string;
  nome: string;
}

const ProcedimentoCadastroPagina: React.FC<Props> = ({
  id,
  addProcedimento,
}) => {
  const {
    optionsEspecialidade,
    buscaEspecialidade,
    especialidade,
  } = useEspecialidade();
  const { addToast } = useToast();
  const { buscaConvenios, optionsConvenio } = useClinica();
  const proCodigo = useMemo(() => {
    return id ? Number(id) : undefined;
  }, [id]);

  const [procedimento, setProcedimento] = useState<IProcedimento>();
  const [procedimentoValor, setProcedimentoValor] = useState<
    IValoresProcedimento[]
  >();
  const [loading, setLoading] = useState(false);
  const [loadingExameAlvaro, setLoadingExameAlvaro] = useState(false);
  const [showAddValor, setShowAddValor] = useState(false);
  const [nomeEspecialidade, setNomeEspecialidade] = useState('');
  const formRefProcedimento = useRef<FormHandles>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optionEspecialidades, _] = useState<IOption[]>(
    optionsEspecialidade.optionEspecialidades,
  );
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState<
    number | undefined
  >(undefined);
  const [exameAlvaroNome, setExameAlvaroNome] = useState<string>();

  useEffect(() => {
    async function loadEspecialidades(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadEspecialidades();
  }, [buscaEspecialidade, optionsEspecialidade]);

  useEffect(() => {
    async function Busca(): Promise<void> {
      await buscaConvenios();
    }
    if (!optionsConvenio || optionsConvenio.length === 0) Busca();
  }, [buscaConvenios, optionsConvenio, id]);

  const handleFormataValoresConvenio = useCallback(
    (dados: IValoresProcedimento[]) => {
      const procedimentoValorFormatado = dados.map(val => {
        return {
          ...val,
          convenio:
            optionsConvenio.find(
              conv => conv.value === val.convenio_id.toString(),
            )?.label || 'ND',
          valorFormatado: formatPrice(val.valor),
        };
      });
      return procedimentoValorFormatado;
    },
    [optionsConvenio],
  );

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IProcedimento>(`procedimentos/${id}`);

        const procedimentoValorFormatado = handleFormataValoresConvenio(
          response.data.valoresProcedimento,
        );

        setNomeEspecialidade(response.data.especialidade?.nome || 'ND');
        setProcedimento(response.data);
        setProcedimentoValor(procedimentoValorFormatado);
        if (response.data.exameAlvaro)
          setExameAlvaroNome(response.data.exameAlvaro.nome);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar procedimentos',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (id && id > 0) loadDados();
  }, [addToast, handleFormataValoresConvenio, id]);

  const ehNovo = useMemo(() => {
    return !proCodigo || proCodigo === 0;
  }, [proCodigo]);

  const ehIntegracaoAlvaro = useMemo(() => {
    const especialidadeCodigo = procedimento
      ? procedimento.especialidade_id
      : especialidadeSelecionada;
    const especci = especialidade.especialidade.find(
      per => per.id === especialidadeCodigo,
    );
    if (especci && especci.integracaoAlvaro) return true;
    return false;
  }, [especialidade.especialidade, especialidadeSelecionada, procedimento]);

  const validaEspecialidade = useMemo(() => {
    if (ehNovo)
      return {
        especialidade_id: Yup.number()
          .integer('Especialidade obrigatório')
          .typeError('Especialidade obrigatório')
          .required('Especialidade obrigatório'),
      };
    return {};
  }, [ehNovo]);

  const handleSubmitProcedimento = useCallback(
    async (dados: IProcedimento) => {
      setLoading(true);
      try {
        let adic = {};
        const ativo = dados.ativo ? dados.ativo.toString() : 'false';

        adic = {
          ...adic,
          ...validaEspecialidade,
          ...(ativo === 'true' && {}),
          ...(ehIntegracaoAlvaro && {
            codigoAlvaro: Yup.string()
              .min(2, 'Mínimo 2 caracteres')
              .required('Código Álvaro é obrigatório'),
          }),
        };

        formRefProcedimento.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string()
            .min(3, 'Mínimo 3 caracteres')
            .required('Nome é obrigatório'),
          duracao: Yup.number().required(),
          ...adic,
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        const especialidadeCodigo = procedimento
          ? procedimento.especialidade_id
          : especialidadeSelecionada;
        const especci = optionEspecialidades.find(
          per => per.value === especialidadeCodigo?.toString(),
        );

        if (ehNovo) {
          await addProcedimento(
            {
              ...dados,
              nome: dados.nome.toUpperCase(),
              ativo: ativo === 'true',
              ...(!ehIntegracaoAlvaro && { codigoAlvaro: undefined }),
            },
            especci,
          );
        } else {
          await addProcedimento(
            {
              ...dados,
              nome: dados.nome.toUpperCase(),
              ativo: ativo === 'true',
              especialidade_id: undefined,
              especialidade: undefined,
              ...(!ehIntegracaoAlvaro && { codigoAlvaro: undefined }),
            },
            especci,
            proCodigo,
          );
        }
      } catch (error: any) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefProcedimento.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar procedimento',
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      validaEspecialidade,
      ehIntegracaoAlvaro,
      procedimento,
      especialidadeSelecionada,
      optionEspecialidades,
      ehNovo,
      addProcedimento,
      proCodigo,
      addToast,
    ],
  );

  const handleOnChange = useCallback((dados: IOption) => {
    setEspecialidadeSelecionada(Number(dados.value));
  }, []);

  const handleDeletouConvenioValor = useCallback((convenio_id: number) => {
    setProcedimentoValor(state => {
      return state
        ? state.filter(conv => conv.convenio_id !== convenio_id)
        : undefined;
    });
  }, []);

  const handleChangeCodigoAlvaro = useCallback(async () => {
    setLoadingExameAlvaro(true);
    setExameAlvaroNome(undefined);
    try {
      const codigoAlvaro = formRefProcedimento.current?.getFieldValue(
        'codigoAlvaro',
      );
      if (codigoAlvaro && codigoAlvaro.length > 1) {
        const response = await api.get<IExameAlvaro>(
          `exame/examealvaro/${codigoAlvaro}`,
        );
        if (response.data) {
          setExameAlvaroNome(response.data.nome);
        } else {
          addToast({
            type: 'error',
            title: 'Código Álvaro não encontrato',
            description: 'Entre em contato com suporte para verificação',
          });
          setExameAlvaroNome(
            'Código Álvaro não encontrato. Entre em contato com suporte',
          );
        }
      }
    } catch (error: any) {
      console.log(error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefProcedimento.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao consultar codigo alvaro',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
      });
    } finally {
      setLoadingExameAlvaro(false);
    }
  }, [addToast]);

  const handleSalvouNovoValor = useCallback(
    (dados: IValoresProcedimento) => {
      const valorAdicionado = handleFormataValoresConvenio([dados]);
      setProcedimentoValor(state => {
        const valoresCadastrados = state?.filter(
          conv => conv.convenio_id !== valorAdicionado[0].convenio_id,
        );

        if (valoresCadastrados) {
          const valoresAtualizados = valoresCadastrados.concat(valorAdicionado);
          return valoresAtualizados;
        }

        return valorAdicionado;
      });
      setShowAddValor(false);
    },
    [handleFormataValoresConvenio],
  );

  return (
    <div className="container">
      {loading && <Loading />}
      <Row noGutters>
        {(ehNovo || procedimento) && (
          <Col md={12}>
            <Form
              ref={formRefProcedimento}
              initialData={procedimento}
              onSubmit={handleSubmitProcedimento}
            >
              <Row noGutters>
                <Col
                  md={3}
                  className={`form-group pr-2 ${proCodigo ? 'd-none' : ''}`}
                >
                  <SelectSync
                    name="especialidade_id"
                    label="Especialidade"
                    placeholder="Selecione"
                    handleSelectChange={handleOnChange}
                    options={optionEspecialidades}
                  />
                </Col>
                <Col
                  md={3}
                  className={`form-group pr-2 ${proCodigo ? '' : 'd-none'}`}
                >
                  <InputFake
                    label="Especialidade"
                    disabled
                    value={nomeEspecialidade}
                  />
                </Col>

                <Col md={5} className="form-group pr-2">
                  <Input name="nome" label="Nome" maxLength={100} />
                </Col>

                <Col md={2} className="form-group pr-2">
                  <Input
                    name="duracao"
                    label="Duração Padrão"
                    type="number"
                    max={120}
                    min={5}
                    step={5}
                    maxLength={3}
                  />
                </Col>
                <Col md={1} className="form-group pr-2">
                  <b className="d-block">Ativo</b>
                  <Toggle nome="ativo" label="" isDisabled={false} />
                </Col>
                {ehIntegracaoAlvaro && (
                  <>
                    <div className="w-100" />
                    <Col md={3} className="form-group pr-2">
                      <Input
                        name="codigoAlvaro"
                        label="Código Álvaro"
                        onBlur={handleChangeCodigoAlvaro}
                        max={20}
                      />
                    </Col>
                    <Col md={9}>
                      <InputFake
                        value={
                          loadingExameAlvaro
                            ? 'Procurando correspondência...'
                            : exameAlvaroNome
                        }
                        label="Exame Álvaro"
                        disabled
                      />
                    </Col>
                  </>
                )}
                <div className="w-100" />
                <Col md={2} className="">
                  <Button
                    type="submit"
                    variant="primary"
                    size="sm"
                    disabled={loading}
                  >
                    {loading ? 'Salvando...' : 'Salvar dados'}
                  </Button>
                </Col>
              </Row>
            </Form>
            {proCodigo && (
              <>
                {showAddValor && (
                  <AddValor
                    procedimento_id={proCodigo}
                    handleSalvou={dados => handleSalvouNovoValor(dados)}
                  />
                )}
                <div className="table-responsive mt-2">
                  <table className="datatable table table-borderless hover-table dataTable no-footer">
                    <thead className="thead-light">
                      <tr className="bg-light" style={{ fontSize: 'Small' }}>
                        <th>Convênio</th>
                        <th style={{ width: '140px' }}>Valor</th>
                        <th style={{ width: '55px', textAlign: 'center' }}>
                          <Button
                            type="button"
                            size="sm"
                            variant="outline-primary"
                            className="py-0 px-2"
                            onClick={() => setShowAddValor(true)}
                          >
                            <FiPlusCircle size={17} />
                          </Button>
                        </th>
                        <th style={{ width: '55px' }}>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {procedimento &&
                        procedimentoValor &&
                        procedimentoValor.map(tabela => (
                          <Linha
                            key={`${tabela.convenio_id}-${tabela.procedimento_id}-${tabela.valor}`}
                            valorConvenio={tabela}
                            handleDeletou={
                              dados => handleDeletouConvenioValor(dados)
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ProcedimentoCadastroPagina;
