import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { FiEdit, FiTrash } from 'react-icons/fi';
import { FaTrashAlt } from 'react-icons/fa';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Paginacao from '../../../components/Paginacao';

import Modal from '../../../components/Modal';
import SkeletonLista from './Skeleton';

import { IFormBusca } from '../index';
import AgendasCadastroPagina, { IConfiguracaoAgenda } from '../AgendasCadastro';
import getResponseErrors from '../../../utils/getResponseErrors';

export interface IPaginacao {
  termoBusca: IFormBusca;
  limite: number;
  pagina: number;
}

export interface IListagemAgendas {
  id: number;
  nome: string;
  ativo: boolean;
  nomeAbreviado: string;
  tempoPadrao: number;
  atende: boolean;
  profissionalAgendaCompleta: boolean;
  integracaoAlvaro: boolean;
}

interface IAgendas
  extends Omit<IListagemAgendas, 'profissionalAgendaCompleta'> {
  atendeFormatado?: string;
  ativoFormatado?: string;
}

export interface IDadosLista {
  especialidade?: string;
  clinica?: string;
}

export interface IRetornoListagemAgenda {
  data: IListagemAgendas[];
  count: number;
}
export interface RefObject {
  addAgenda(
    dadosConfiguracaoAgenda: IConfiguracaoAgenda,
    dadosLista: IDadosLista,
    conCodigo: number | undefined,
  ): Promise<void>;
}
type Props = {
  dados?: IFormBusca;
  ref: Ref<RefObject>;
};

const ListaAgendas: React.FC<Props> = forwardRef(
  (prop: Props, ref: Ref<RefObject>) => {
    const [showModalCadastro, setShowModalCadastro] = useState(false);
    const [showModalExcluir, setShowModalExcluir] = useState<number>();
    const { addToast } = useToast();
    const [paginacao, setPaginacao] = useState<IPaginacao | undefined>(
      undefined,
    );
    const [loading, setLoading] = useState(false);
    const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
      undefined,
    );
    const [listagemAgendas, setListagemAgendas] = useState<IAgendas[]>([]);

    const [agendaSelecionada, setAgendaSelecionada] = useState<
      number | undefined
    >(undefined);

    const [labels, setLabels] = useState<IDadosLista | undefined>(undefined);

    useEffect(() => {
      setPaginacao({
        termoBusca: prop.dados || {
          especialidade_id: '',
          situacao: 'true',
          atende: '',
          clinica_id: '',
        },
        pagina: 1,
        limite: 20,
      });
    }, [prop.dados]);

    useEffect(() => {
      async function buscaAgendas(): Promise<void> {
        if (paginacao) {
          try {
            setLoading(true);
            const {
              especialidade_id,
              situacao,
              clinica_id,
              atende,
            } = paginacao.termoBusca;
            let query = '';
            if (especialidade_id !== '')
              query = `${query}&especialidade_id=${especialidade_id}`;
            if (situacao !== '') query = `${query}&ativo=${situacao}`;
            if (clinica_id !== '') query = `${query}&clinica_id=${clinica_id}`;
            if (atende !== '') query = `${query}&atende=${atende}`;

            const response = await api.get<IRetornoListagemAgenda>(
              `/listagemagendas?pagina=${paginacao.pagina}&limite=${paginacao.limite}${query}`,
            );

            const { data, count } = response.data;

            const listagemAgendasApiFormatada = data.map(lista => {
              return {
                ...lista,
                atendeFormatado: lista.atende ? 'Sim' : 'Não',
                ativoFormatado: lista.ativo ? 'Sim' : 'Não',
              };
            });

            setTotalRegistros(count);

            setListagemAgendas(listagemAgendasApiFormatada);
          } catch (error) {
            const mensagemErro = getResponseErrors(error);
            addToast({
              title: 'Não foi possível listar agendas',
              type: 'error',
              description: mensagemErro,
            });
          } finally {
            setLoading(false);
          }
        }
      }
      if (paginacao) buscaAgendas();
    }, [paginacao, addToast]);

    const handleDeleteAgenda = useCallback(
      async (codigo: number) => {
        setShowModalExcluir(undefined);
        setLoading(true);
        try {
          await api.delete(`/agendas/${codigo}`);
          setListagemAgendas(AgendasState =>
            AgendasState?.filter(con => con.id !== codigo),
          );
          addToast({
            type: 'success',
            title: 'Agenda excluída',
          });
        } catch (error) {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Não foi possível deletar agenda',
            type: 'error',
            description: mensagemErro,
          });
        } finally {
          setLoading(false);
        }
      },
      [addToast],
    );

    const addAgenda = useCallback(
      async (
        dadosConfiguracaoAgenda: IConfiguracaoAgenda,
        dadosLista: IDadosLista,
        id: number | undefined,
      ) => {
        let response;
        setLoading(true);
        try {
          if (id) {
            response = await api.put<IConfiguracaoAgenda>(
              `/agendas/${id}`,
              dadosConfiguracaoAgenda,
            );
          } else {
            response = await api.post<IConfiguracaoAgenda>(
              '/agendas',
              dadosConfiguracaoAgenda,
            );
          }
          const {
            id: conCodigo,
            nome,
            ativo,
            atende,
            tempoPadrao,
            integracaoAlvaro,
          } = response.data;

          const retornoConfiguracaoAgenda: IAgendas = {
            id: conCodigo,
            nome,
            ativo,
            atende,
            tempoPadrao,
            integracaoAlvaro,
            nomeAbreviado: dadosLista.clinica || '',
            atendeFormatado: atende ? 'Sim' : 'Não',
            ativoFormatado: ativo ? 'Sim' : 'Não',
          };

          setListagemAgendas(state => {
            return state
              ? [retornoConfiguracaoAgenda].concat(
                  state.filter(
                    config => config.id !== retornoConfiguracaoAgenda.id,
                  ),
                )
              : [retornoConfiguracaoAgenda];
          });
          setShowModalCadastro(false);
          addToast({ title: 'Agenda salvo', type: 'success' });
        } catch (error) {
          const mensagemErro = getResponseErrors(error);
          addToast({
            title: 'Não foi possível salvar agenda',
            type: 'error',
            description: mensagemErro,
            timeAlive: 10000,
          });
        } finally {
          setLoading(false);
        }
      },
      [addToast],
    );

    useImperativeHandle(ref, () => ({ addAgenda }));

    const handleProximaPagina = useCallback((page: number) => {
      setPaginacao(state => {
        return state && { ...state, pagina: page };
      });
    }, []);

    const handleEditar = useCallback(
      (conCodigo: number, nomeLabels: IDadosLista) => {
        setAgendaSelecionada(conCodigo);
        setShowModalCadastro(true);
        setLabels(nomeLabels);
      },
      [],
    );

    return (
      <>
        {showModalCadastro && (
          <Modal
            titulo="Cadastro de Agenda"
            handleFecharModal={() => setShowModalCadastro(false)}
            large
            show={showModalCadastro}
          >
            <AgendasCadastroPagina
              id={agendaSelecionada}
              addAgenda={addAgenda}
              labels={labels}
            />
          </Modal>
        )}

        {showModalExcluir && (
          <Modal
            handleFecharModal={() => setShowModalExcluir(undefined)}
            show={!!showModalExcluir}
            titulo="Excluir registro"
            large={false}
          >
            <p className="my-3">
              Essa operação não pode ser desfeita, deseja realmente prosseguir?
            </p>
            <div className="d-flex justify-content-between mt-2">
              <Button
                type="submit"
                variant="secondary"
                // size="sm"
                onClick={() => setShowModalExcluir(undefined)}
              >
                Não, voltar
              </Button>

              <Button
                type="button"
                variant="danger"
                onClick={() => handleDeleteAgenda(showModalExcluir)}
              >
                <FaTrashAlt /> Sim, excluir
              </Button>
            </div>
          </Modal>
        )}

        <div className="table-responsive">
          <table className="datatable table table-borderless hover-table dataTable no-footer">
            <thead className="thead-light">
              <tr className="bg-light" style={{ fontSize: 'Small' }}>
                <th>Nome Agenda</th>
                <th style={{ width: '137px' }}>Temp. Consulta</th>
                <th>Clínica</th>
                <th style={{ width: '166px' }}>Atende Consultorio</th>
                <th>Ativo</th>
                <th style={{ width: '55px' }}>&nbsp;</th>
                <th style={{ width: '55px' }}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {listagemAgendas &&
                !loading &&
                listagemAgendas.map(lista => (
                  <tr key={lista.id}>
                    <td>{lista.nome}</td>
                    <td>{lista.tempoPadrao}</td>
                    <td>{lista.nomeAbreviado}</td>
                    <td>{lista.atendeFormatado}</td>
                    <td>{lista.ativoFormatado}</td>
                    <td>
                      <Button
                        type="button"
                        variant="primary-outline"
                        className="py-0"
                        title={`${lista.id}`}
                        onClick={
                          () =>
                            handleEditar(lista.id, {
                              clinica: lista.nomeAbreviado,
                            })
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        <FiEdit />
                      </Button>
                    </td>

                    <td>
                      <Button
                        type="button"
                        variant="secondary-outline"
                        className="py-0"
                        title={`${lista.id}`}
                        onClick={() => {
                          setShowModalExcluir(lista.id);
                        }}
                      >
                        <FiTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              {loading && (
                <>
                  <SkeletonLista />
                  <SkeletonLista />
                  <SkeletonLista />
                </>
              )}
              <tr>
                <td colSpan={6} />
              </tr>
            </tbody>
          </table>

          {(!listagemAgendas || listagemAgendas.length === 0) && !loading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: '250px' }}
            >
              <h5>
                Pesquise agendas por especialidade, atende consultório, clínica
                e/ou Situação
              </h5>
            </div>
          )}
        </div>
        {paginacao && (
          <Paginacao
            limitePagina={paginacao?.limite || 20}
            paginasVizinhas={2}
            totalRegistros={totalRegistros}
            paginaInicio={paginacao?.pagina}
            handleProximaPagina={handleProximaPagina}
          />
        )}
      </>
    );
  },
);

export default ListaAgendas;
