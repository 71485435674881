/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useState } from 'react';
import {
  FaDownload,
  FaFilePdf,
  FaShareSquare,
  FaSpinner,
} from 'react-icons/fa';

import { useAuth } from '../../hooks/auth';
import { INotafiscal } from '../../hooks/notafiscal';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import getResponseErrors from '../../utils/getResponseErrors';
import { Spinner } from '../Agendamento/styles';
import DadosNota from '../DadosNota';
import Loading from '../Loading';
import Modal from '../Modal';

// import { Container } from './styles';

type Props = {
  contapr_id: number;
  dadosNota?: INotafiscal;
};

interface ISituacaoNota {
  contapr_id: number;
  link?: string;
  linkNota?: string;
  situacao?: string;
}

const ControlesNotaFiscal: React.FC<Props> = ({ contapr_id, dadosNota }) => {
  const { addToast } = useToast();
  const { clinica } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [situacao, setSituacao] = useState<ISituacaoNota | undefined>({
    link: dadosNota?.link,
    linkNota: dadosNota?.link,
    situacao: dadosNota?.situacao || '',
    contapr_id: dadosNota?.contapr_id || contapr_id,
  });

  useEffect(() => {
    async function loadSituacao(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<ISituacaoNota>(
          `/notafiscal/situacao/${contapr_id}`,
        );

        const notasFormatadas = {
          ...response.data,
          linkNota:
            response.data.link && response.data.link.includes('google')
              ? response.data.link
              : response.data.linkNota,
        };
        setSituacao(notasFormatadas);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dados de situação da nota fiscal',
          type: 'error',
          description: mensagemErro,
        });
      }
      setLoading(false);
    }

    if (!dadosNota) {
      loadSituacao();
    } else {
      setSituacao(dadosNota);
    }
  }, [addToast, contapr_id, dadosNota]);

  const baixarPDF = useCallback(
    async contaprId => {
      try {
        await api.put(`emissaonfse/consultaNFSE/${contaprId}`);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível baixar PDF',
          type: 'error',
          description: mensagemErro,
        });
      }
    },
    [addToast],
  );

  const verificaSituacao = useCallback(
    async contaprId => {
      try {
        await api.put(`emissaonfse/consultaNFSE/${contaprId}`);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi verificar situação',
          type: 'error',
          description: mensagemErro,
        });
      }
    },
    [addToast],
  );

  return (
    <>
      {loading && <Loading backgroundBlack={false} />}
      <div className="d-flex justify-content-end">
        {situacao && situacao.situacao === 'Emitida' && !situacao.linkNota && (
          <button
            type="button"
            id={`link${situacao.contapr_id}`}
            title="Gerar PDF"
            className="btn btn-sm btn-outline-success"
            onClick={() => baixarPDF(situacao.contapr_id)}
          >
            <FaDownload id={`ilink${situacao.contapr_id}`} />
          </button>
        )}
        {situacao && situacao.linkNota && (
          <a
            href={situacao.linkNota || ''}
            title="Ver NF"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '34px' }}
            className="btn btn-sm btn-outline-primary"
          >
            <FaFilePdf />
          </a>
        )}
        {situacao && situacao.situacao?.toUpperCase() === 'PROCESSANDO' && (
          <button
            type="button"
            id={`Verifica${situacao.contapr_id}`}
            title="Verificar Situação"
            className="btn btn-sm btn-outline-primary"
            onClick={() => verificaSituacao(situacao.contapr_id)}
          >
            <Spinner>
              <FaSpinner />
            </Spinner>
          </button>
        )}
        {situacao &&
          (situacao.situacao?.toUpperCase() === 'REJEITADO' ||
            (situacao.situacao?.toUpperCase() !== 'PROCESSANDO' &&
              situacao.situacao?.toUpperCase() !== 'EMITIDA')) && (
            <button
              type="button"
              id={situacao.contapr_id?.toString()}
              className="btn btn-sm btn-outline-primary"
              onClick={() => setShowModal(true)}
              title="Emitir Nota fiscal"
              disabled={!clinica.emiteNotaFiscal}
            >
              <FaShareSquare id={`i${situacao.contapr_id}`} />
            </button>
          )}
        {showModal && (
          <Modal
            show={showModal}
            titulo=" Dados da Nota Fiscal"
            handleFecharModal={() => setShowModal(false)}
          >
            <div>
              <DadosNota
                codigo={String(contapr_id)}
                handleFecharModal={() => setShowModal(false)}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ControlesNotaFiscal;
