import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Paginacao from '../../../components/Paginacao';
import MedicosCadastro, { IMedico } from '../MedicoCadastro';

import Modal from '../../../components/Modal';

import { IFormBusca } from '..';
import getResponseErrors from '../../../utils/getResponseErrors';

export interface IPaginacao {
  termoBusca: IFormBusca;
  limite: number;
  pagina: number;
}

// export interface RefObject {
//   addMedico(dadosMedico: IMedico, id: number | undefined): Promise<void>;
// }
type Props = {
  dados?: IFormBusca;
};

const ListaMedicos: React.FC<Props> = forwardRef((prop: Props) => {
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const { addToast } = useToast();
  const [paginacao, setPaginacao] = useState<IPaginacao | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );
  const [medicos, setMedicos] = useState<IMedico[]>([]);

  const [medicoSelecionado, setMedicoSelecionado] = useState<
    IMedico | undefined
  >(undefined);

  useEffect(() => {
    setPaginacao({
      termoBusca: prop.dados || { nome: '', conselho: '', numeroConselho: '' },
      pagina: 1,
      limite: 20,
    });
  }, [prop.dados]);

  useEffect(() => {
    async function buscaMedicos(): Promise<void> {
      if (paginacao) {
        try {
          setLoading(true);
          const { nome, conselho, numeroConselho } = paginacao.termoBusca;
          let query = '';
          if (nome !== '') query = `&nome=${nome}`;
          if (conselho !== '') query = `${query}&nomeConselho=${conselho}`;
          if (numeroConselho !== '')
            query = `${query}&numeroConselho=${numeroConselho}`;

          const response = await api.get(
            `/medico?pagina=${paginacao.pagina}&limite=${paginacao.limite}${query}`,
          );

          const medicosApi: IMedico[] = response.data.dados;

          const MedicosFormatado = medicosApi.map((med: IMedico) => {
            return {
              ...med,
            };
          });

          setTotalRegistros(
            response.data.total === 0 ? undefined : response.data.total,
          );

          setMedicos(MedicosFormatado);
        } catch (error) {
          console.log(error);
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar médicos',
            description: mensagem,
          });
          setTotalRegistros(0);
          setMedicos([]);
        } finally {
          setLoading(false);
        }
      }
    }
    if (paginacao) buscaMedicos();
  }, [paginacao, addToast]);

  const handleDeleteMedico = useCallback(
    async (codigo: number) => {
      setLoading(true);
      try {
        await api.delete(`/medico/${codigo}`);
        setMedicos(medicosState =>
          medicosState?.filter(med => med.id !== codigo),
        );
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível deletar médico',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  // const addMedico = useCallback(
  //   async (dadosMedico: IMedico, id: number | undefined) => {
  //     let response;
  //     setLoading(true);

  //     try {
  //       if (id) {
  //         response = await api.put<IMedico>(
  //           `/medico/${id}`,
  //           dadosMedico,
  //         );
  //       } else {
  //         response = await api.post<IMedico>('/medico', dadosMedico);
  //       }

  //       const retornoMedico: IMedico = {
  //         ...response.data,
  //       };

  //       setMedicos(state => {
  //         return state
  //           ? [retornoMedico].concat(
  //               state.filter(
  //                 med => med.id !== retornoMedico.id,
  //               ),
  //             )
  //           : [retornoMedico];
  //       });
  //       setShowModalCadastro(false);
  //       addToast({ title: 'Médico salvo', type: 'success' });
  //     } catch (error) {
  //       const mensagem = getResponseErrors(error);
  //       addToast({
  //         type: 'error',
  //         title: 'Não foi possível salvar médico',
  //         description: mensagem,
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [addToast],
  // );

  // useImperativeHandle(ref, () => ({ addMedico }));

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  // const handleDeleteMedico = useCallback(
  //   async (codigo: number) => {
  //     try {
  //       await deleteMedico(codigo);
  //     } catch (error) {
  //       const mensagemErro = getResponseErrors(error);

  //       addToast({
  //         type: 'error',
  //         title: 'Não foi possível excluir o médico',
  //         description: mensagemErro,
  //       });
  //     }
  //   },
  //   [addToast, deleteMedico],
  // );

  const handleEditar = useCallback(medico => {
    setMedicoSelecionado(medico);
    setShowModalCadastro(true);
  }, []);

  return (
    <>
      {showModalCadastro && (
        <Modal
          show={showModalCadastro}
          titulo="Cadastro de Médico p/Encaminhamento"
          handleFecharModal={() => setShowModalCadastro(false)}
          large
        >
          <MedicosCadastro
            medico={medicoSelecionado}
            setShowModal={setShowModalCadastro}
          />
        </Modal>
      )}
      {loading && <Loading />}

      {medicos && medicos.length > 0 ? (
        <>
          <table
            className="table table-hover table-sm table-font-small"
            style={{ borderWidth: '0px' }}
          >
            <tbody>
              <tr className="bg-light" style={{ fontSize: 'Small' }}>
                <th scope="col">Nome</th>
                <th scope="col">Conselho</th>
                <th scope="col">Número</th>
                <th scope="col">&nbsp;</th>
                <th scope="col">&nbsp;</th>
              </tr>
              {medicos.map((med: IMedico) => (
                <tr
                  key={`medico-${med.id}`}
                  style={{ fontSize: 'Small', height: '30px' }}
                >
                  <td>{med.nome}</td>
                  <td style={{ width: '100px' }}>{med.nomeConselho}</td>
                  <td style={{ width: '60px' }}>{med.numeroConselho}</td>
                  <td className="p-0" style={{ width: '40px' }}>
                    <Button
                      type="button"
                      variant="primary-outline"
                      className="py-0"
                      title={med.id.toString()}
                      onClick={() => handleEditar(med)}
                    >
                      <FaPencilAlt />
                    </Button>
                  </td>

                  <td className="p-0" style={{ width: '40px' }}>
                    <Button
                      type="button"
                      variant="secondary-outline"
                      className="py-0"
                      title={med.id.toString()}
                      onClick={() => {
                        handleDeleteMedico(med.id);
                      }}
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginacao
            limitePagina={paginacao?.limite || 15}
            paginasVizinhas={2}
            totalRegistros={totalRegistros}
            paginaInicio={paginacao?.pagina}
            handleProximaPagina={handleProximaPagina}
          />
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '250px' }}
        >
          <h5>
            Nenhum registro encontrado. Pesquise médicos pelo Nome e/ou tipo
          </h5>
        </div>
      )}
    </>
  );
});

export default ListaMedicos;
