import { format, parseISO } from 'date-fns';
import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';
import { formatPrice } from '../utils/funcoes';
import getResponseErrors from '../utils/getResponseErrors';
import { useToast } from './toast';

export interface INotafiscal {
  contapr_id: number;
  dataPagamento: string;
  dataPagamentoFormatado?: string;
  formaPagto: string;
  codigoVerificacao?: string;
  linkNota?: string;
  nome: string;
  descricao: string;
  situacao?: string;
  numeroNFSe?: string;
  vlr: number;
  vlrFormatado: string;
  link?: string;
  arquivo?: string;
  serie?: string;
  clinica_id: number;
}

interface NotaFiscalContextData {
  loading: boolean;
  notasFiscais: INotafiscal[];
  buscaNotas(requisicao: string): Promise<void>;
  setNotaFiscal(dadosNota: INotafiscal): void;
}

const NotaFiscalContext = createContext<NotaFiscalContextData>(
  {} as NotaFiscalContextData,
);

const NotaFiscalProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const [notasFiscais, setNotasFiscais] = useState<INotafiscal[]>([]);

  const buscaNotas = useCallback(
    async requisicao => {
      setLoading(true);
      try {
        const response = await api.get<INotafiscal[]>(requisicao);

        if (response.data.length === 0) {
          addToast({ type: 'info', title: 'Nenhum registro encontrado' });
          setNotasFiscais([]);
          return;
        }
        const notas = response.data.map(item => {
          return {
            ...item,
            vlrFormatado: formatPrice(item.vlr),
            dataPagamentoFormatado: format(
              parseISO(item.dataPagamento),
              'dd/MM/yyyy',
            ),
            contapr_id: item.contapr_id,
          };
        });
        setNotasFiscais(notas);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar notas',
          type: 'error',
          description: mensagemErro,
        });
        setNotasFiscais([]);
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const setNotaFiscal = useCallback(
    (dadosNota: INotafiscal) => {
      const index = notasFiscais.findIndex(
        nf => nf.contapr_id === dadosNota.contapr_id,
      );
      const arrayNotas = notasFiscais.map(nf => nf);
      arrayNotas[index] = {
        ...notasFiscais[index],
        situacao: dadosNota.situacao ? dadosNota.situacao : 'Processando',
        link: dadosNota.link,
        numeroNFSe: dadosNota.numeroNFSe,
        codigoVerificacao: dadosNota.codigoVerificacao,
        linkNota: dadosNota.linkNota,
      };
      setNotasFiscais(arrayNotas);
    },
    [notasFiscais],
  );

  return (
    <NotaFiscalContext.Provider
      value={{ loading, notasFiscais, buscaNotas, setNotaFiscal }}
    >
      {children}
    </NotaFiscalContext.Provider>
  );
};

function useNotaFiscal(): NotaFiscalContextData {
  const context = useContext(NotaFiscalContext);

  if (!context) {
    throw new Error('useNotaFiscal deve ser usado com o NotaFiscalProvider');
  }

  return context;
}

export { NotaFiscalProvider, useNotaFiscal };
