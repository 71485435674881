import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { Form } from '@unform/web';
// import { Container } from './styles';
import { Button, Row, Col, Card } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { useAuth } from '../../../hooks/auth';

import { useToast } from '../../../hooks/toast';
import { useFormaPagamento } from '../../../hooks/formapagamento';
import api from '../../../services/api';

import SelectSync, { IOption } from '../../SelectSync';
import Loading from '../../Loading';
import InputMoney from '../../InputMoney';
import getValidationErrors from '../../../utils/getValidationErrors';
import { formatPrice } from '../../../utils/funcoes';
import Desconto, { IDesconto } from '../Desconto';
import Modal from '../../Modal';
import getResponseErrors from '../../../utils/getResponseErrors';

import { IPagamento } from '../index';

interface IFormProps extends FormHandles {
  formaPagamento_id: number;
  valorPago: number;
  CprDataPag: Date;
}
interface IAlterarFormaPagamento {
  id: number;
  formaPagamento_id: number;
  valorPago: number;
  usuario_id: number;
  motivo_id?: number;
  descricaoDesconto?: string;
  motivo_usuario_id?: number;
  pagamento: IPagamento[];
}

type Props = {
  id: number;
  valor: number;
  estaPago: boolean;
  ehTroco: boolean;
  ehSangria: boolean;
  EhConvenio?: boolean;
  ehDespesa: boolean;
  ehAlteracao?: boolean;
  dadosBaixa?: { nome?: string; data?: string; alteracao?: string };
  pagamentosRealizados: IPagamento[];
  descontoAplicado: IDesconto;
  handleAddedPagamento(dados: IPagamento[]): void;
  handleAddedDesconto(dados: IDesconto): void;
  handleFechar(): void;
  pegaValor(): number | undefined;
};

const FormaPagamento: React.FC<Props> = ({
  id,
  valor,
  estaPago,
  ehAlteracao,
  dadosBaixa,
  ehDespesa,
  ehTroco = false,
  ehSangria = false,
  EhConvenio,
  pagamentosRealizados,
  descontoAplicado,
  handleAddedPagamento,
  handleAddedDesconto,
  handleFechar,
  pegaValor,
}) => {
  const formRefFormaPagamento = useRef<IFormProps>(null);
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsFormasPagamento, buscaFormaPagamento } = useFormaPagamento();

  const [optionsFormaPagamento, setOptionsFormaPagamento] = useState<IOption[]>(
    [],
  );

  const [showModalDesconto, setShowModalDesconto] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !optionsFormasPagamento ||
      Object.keys(optionsFormasPagamento).length === 0
    )
      buscaFormaPagamento();
  }, [buscaFormaPagamento, optionsFormasPagamento]);

  useEffect(() => {
    if (optionsFormasPagamento.optionFormaPagamento)
      setOptionsFormaPagamento(
        optionsFormasPagamento.optionFormaPagamento.filter(
          op => op.label.toUpperCase() !== 'CONVÊNIO',
        ),
      );
  }, [optionsFormasPagamento.optionFormaPagamento]);

  const total = useMemo(() => {
    const valorT = pagamentosRealizados.reduce((tot, pag) => {
      return tot + Number(pag.valor);
    }, 0);
    return {
      valorT,
      valorFormatado: formatPrice(valorT),
    };
  }, [pagamentosRealizados]);

  useEffect(() => {
    if (EhConvenio && optionsFormasPagamento.optionFormaPagamento) {
      const formaPag = optionsFormasPagamento.optionFormaPagamento.find(
        op => op.label.toUpperCase() === 'CONVÊNIO',
      );

      if (!formaPag) return;

      if (
        !pagamentosRealizados.find(
          pg => pg.formaPagamento_id === Number(formaPag.value),
        )
      )
        handleAddedPagamento([
          {
            contapr_id: id,
            formaPagamento_id: Number(formaPag.value),
            data: new Date(),
            valor: total.valorT,
            formaDePagamento: {
              id: Number(formaPag.value),
              nome: formaPag.label,
            },
            valorFormatado: total.valorFormatado,
          },
        ]);
    }
  }, [
    id,
    EhConvenio,
    handleAddedPagamento,
    optionsFormasPagamento,
    total,
    pagamentosRealizados,
  ]);

  const totalDesconto = useMemo(() => {
    return {
      valor: Number(descontoAplicado.valor),
      valorFormatado: formatPrice(-1 * Number(descontoAplicado.valor)),
    };
  }, [descontoAplicado.valor]);

  const resta = useMemo(() => {
    const valorDigitado = valor > 0 ? valor : pegaValor() || 0;
    const soma = parseFloat(
      (valorDigitado - total.valorT - totalDesconto.valor).toFixed(2),
    );
    return {
      valor: soma,
      valorFormatado: formatPrice(soma),
    };
  }, [total.valorT, totalDesconto.valor, valor, pegaValor]);

  const validaDadosPagamento = useCallback(
    async (dadosForm): Promise<boolean> => {
      try {
        formRefFormaPagamento.current?.setErrors({});

        const schemaPadrao = Yup.object().shape({
          formaPagamento_id: Yup.number()
            .typeError('Selecione a forma de pagamento')
            .required('Valor requerido'),

          valorPago: Yup.number()
            .min(0.01, 'valor tem que ser maior que 0')
            .typeError('Valor incorreto')
            .required('Valor requerido'),
        });

        await schemaPadrao.validate(dadosForm, {
          abortEarly: false,
        });
        return true;
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefFormaPagamento.current?.setErrors(errors);
        }
        return false;
      }
    },
    [],
  );

  const handleAddPagamento = useCallback(async () => {
    const valido = await validaDadosPagamento(
      formRefFormaPagamento.current?.getData(),
    );

    if (!valido) return;
    const cprFormaPag = formRefFormaPagamento.current?.getFieldValue(
      'formaPagamento_id',
    );

    const cprValorPag = formRefFormaPagamento.current?.getFieldValue(
      'valorPago',
    );
    const cprDataPag = formRefFormaPagamento.current?.getFieldValue(
      'CprDataPag',
    );

    const pagamentoSelecionado = optionsFormaPagamento.filter(
      pag => pag.value === String(cprFormaPag),
    );

    if (!pagamentoSelecionado[0]) return;
    if (pagamentoSelecionado[0].isDisabled) return;

    const valorDigitado = valor > 0 ? valor : pegaValor() || 0;

    const valorPassouDoTotal =
      total.valorT + totalDesconto.valor + Number(cprValorPag) > valorDigitado;
    if (valorPassouDoTotal) {
      addToast({
        title: 'Valor digitado maior que o total.',
        type: 'error',
      });
      return;
    }

    handleAddedPagamento([
      ...pagamentosRealizados,
      {
        contapr_id: id,
        formaPagamento_id: cprFormaPag,
        data: cprDataPag || new Date(),
        valor: cprValorPag,
        formaDePagamento: {
          id: cprFormaPag,
          nome: pagamentoSelecionado[0].label,
        },
        valorFormatado: formatPrice(cprValorPag),
      },
    ]);

    setOptionsFormaPagamento(state =>
      state.map(opt => {
        return {
          ...opt,
          isDisabled: opt.isDisabled
            ? opt.isDisabled
            : opt.value === cprFormaPag,
        };
      }),
    );
    formRefFormaPagamento.current?.setFieldValue('valorPago', '');
  }, [
    valor,
    optionsFormaPagamento,
    id,
    validaDadosPagamento,
    addToast,
    total.valorT,
    totalDesconto.valor,
    handleAddedPagamento,
    pagamentosRealizados,
    pegaValor,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const handleSalvaDesconto = useCallback(
    data => {
      handleAddedDesconto(data);
      setShowModalDesconto(false);
    },
    [handleAddedDesconto],
  );

  const fechouTotal = useMemo(() => {
    if (!valor) return true;
    return total.valorT === Number(valor);
  }, [valor, total.valorT]);

  const handleRemovePagamento = useCallback(
    formaPagamento_id => {
      const pagamentoAtual = pagamentosRealizados.filter(
        pags => pags.formaPagamento_id !== formaPagamento_id,
      );

      setOptionsFormaPagamento(state =>
        state.map(opt => {
          return {
            ...opt,
            isDisabled: opt.isDisabled
              ? opt.isDisabled && opt.value !== formaPagamento_id
              : opt.isDisabled,
          };
        }),
      );

      handleAddedPagamento(pagamentoAtual);
    },
    [pagamentosRealizados, handleAddedPagamento],
  );

  const handleAlterarFormaPagamento = useCallback(async () => {
    if (id === 0) {
      addToast({
        type: 'error',
        title: 'Nenhum registro selecionado',
      });
      return;
    }
    if (formRefFormaPagamento.current) {
      if (resta.valor !== 0) {
        addToast({
          title: 'Confira os valores',
          description:
            'O total de valores informados são diferentes do total da parcela.',
          type: 'error',
        });

        return;
      }

      setLoading(true);

      const desconto =
        descontoAplicado.valor !== '0' && descontoAplicado.valor !== ''
          ? {
              motivo_usuario_id: descontoAplicado.UsuCodigo,
              motivo_id: descontoAplicado.motivo,
              descricaoDesconto: descontoAplicado.descricao,
            }
          : {};

      const pagamentos =
        pagamentosRealizados.length > 0
          ? pagamentosRealizados.map(pag => {
              return {
                data: new Date(),
                formaPagamento_id: pag.formaPagamento_id,
                valor: pag.valor,
              };
            })
          : [
              {
                data: new Date(),
                formaPagamento_id: 9,
                valor: 0,
              },
            ];

      const formaPagamento_id = pagamentos[0]?.formaPagamento_id || 9;
      const DadosAtualizados: IAlterarFormaPagamento = {
        id,
        formaPagamento_id,
        usuario_id: user.id,
        valorPago: total.valorT,
        pagamento: pagamentos,
        ...desconto,
      };

      try {
        await api.post(`/caixa/alterarformapagamento/${id}`, DadosAtualizados);

        addToast({
          type: 'success',
          title: 'Alterado com sucesso',
        });
        if (ehAlteracao) handleFechar();
      } catch (error) {
        const mensagemErro = getResponseErrors(error);

        addToast({
          type: 'error',
          title: 'Erro ao alterar pagamento',
          description: mensagemErro,
        });
      }
      setLoading(false);
    }
  }, [
    id,
    user.id,
    addToast,
    setLoading,
    total.valorT,
    resta.valor,
    pagamentosRealizados,
    descontoAplicado,
    ehAlteracao,
    handleFechar,
  ]);

  return (
    <>
      {showModalDesconto && (
        <Modal
          show={showModalDesconto}
          titulo="Desconto"
          handleFecharModal={() => setShowModalDesconto(false)}
          large={false}
        >
          {id && (
            <Desconto
              valorParcela={valor || 0}
              handleFechar={() => setShowModalDesconto(false)}
              handleSalvar={handleSalvaDesconto}
              desconto={descontoAplicado}
            />
          )}
        </Modal>
      )}
      <div className={ehAlteracao ? 'container' : ''}>
        {loading && <Loading backgroundBlack={false} />}
        <Form
          ref={formRefFormaPagamento}
          onSubmit={() => console.log('submit')}
        >
          <div>
            <Row
              id="PanPags"
              className={`${estaPago || EhConvenio ? 'd-none' : ''}`}
            >
              <Col md="6" xl="6" className="form-group pr-2">
                <SelectSync
                  name="formaPagamento_id"
                  label="Forma Pagamento"
                  placeholder="Selecione"
                  isClearable={false}
                  handleSelectChange={handleOnChangeVazio}
                  options={optionsFormaPagamento || { label: '', value: '' }}
                />
              </Col>
              <Col md="2" xl="2" className="form-group pr-2">
                <InputMoney
                  label="Valor"
                  name="valorPago"
                  autoComplete="nope"
                  placeholder="Valor"
                />
              </Col>
              <Col xl={1} md={1}>
                <Button
                  type="button"
                  style={{ marginTop: '18px' }}
                  size="sm"
                  variant="outline-info"
                  onClick={() => {
                    handleAddPagamento();
                  }}
                >
                  <FaPlusCircle />
                </Button>
              </Col>
              <Col xl={3} md={3} style={{ textAlign: 'end' }}>
                <Button
                  type="button"
                  variant="outline-success"
                  style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                  size="sm"
                  className={
                    descontoAplicado.motivo === 0 &&
                    valor !== 0 &&
                    !ehDespesa &&
                    !fechouTotal
                      ? 'btn btn-sm btn-outline-success mt-3 p-1'
                      : 'd-none'
                  }
                  onClick={() => setShowModalDesconto(true && !fechouTotal)}
                >
                  Adicionar Desconto
                </Button>
              </Col>
            </Row>
          </div>
        </Form>

        {((pagamentosRealizados && pagamentosRealizados.length > 0) ||
          descontoAplicado.motivo !== 0) && (
          <>
            <Row>
              <Col xl={estaPago ? 7 : 12} md={estaPago ? 7 : 12}>
                <Row className="mt-2">
                  <Col xl={8} md={8}>
                    Forma de pagamento
                  </Col>
                  <Col xl={3} md={3}>
                    Valor
                  </Col>
                  <Col xl={1} md={1} />
                </Row>

                {pagamentosRealizados.map(pags => (
                  <Row
                    className="align-items-center border-bottom py-1"
                    key={`pagamento-${
                      pags.id ? pags.id.toString() : 111111111 * Math.random()
                    }`}
                  >
                    <Col xl={8} md={8}>
                      {pags.formaDePagamento?.nome}
                    </Col>
                    <Col xl={3} md={3}>
                      {pags.valorFormatado}
                    </Col>
                    <Col xl={1} md={1}>
                      {!estaPago && !EhConvenio && (
                        <Button
                          type="button"
                          variant="outline-danger"
                          size="sm"
                          className="py-0"
                          onClick={() => {
                            handleRemovePagamento(pags.formaPagamento_id);
                          }}
                        >
                          <FaTrashAlt />
                        </Button>
                      )}
                    </Col>
                  </Row>
                ))}
                <Row
                  className={`align-items-center border-bottom py-1 text-danger ${
                    descontoAplicado.motivo !== 0 ? '' : 'd-none'
                  }`}
                >
                  <Col md={8}>
                    <span title={descontoAplicado.descricao}>Desconto</span>
                  </Col>
                  <Col xl={3} md={3}>
                    {totalDesconto.valorFormatado}
                  </Col>
                  <Col xl={1} md={1}>
                    {!estaPago && (
                      <Button
                        type="button"
                        variant="outline-danger"
                        size="sm"
                        className="py-0"
                        onClick={() => {
                          handleAddedDesconto({
                            UsuCodigo: 0,
                            UsuNome: '',
                            descricao: '',
                            login: '',
                            motivo: 0,
                            valor: '0',
                          });
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className="align-items-center py-2">
                  <Col md={8}>
                    <b>Valor Total</b>
                  </Col>
                  <Col xl={3} md={3}>
                    <b>{total.valorFormatado}</b>
                  </Col>
                  <Col xl={1} md={1}>
                    &nbsp;
                  </Col>
                </Row>
                <Row
                  className={`py-2 border-top ${
                    estaPago || resta.valor === 0 ? 'd-none' : ''
                  }`}
                >
                  <Col md={9}>
                    <b>Resta</b>
                  </Col>
                  <Col xl={3} md={3}>
                    <b>{resta.valorFormatado}</b>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={5}
                md={5}
                className={`pr-0 ${
                  estaPago && dadosBaixa?.nome ? '' : 'd-none'
                }`}
              >
                <Card>
                  <Card.Header as="h5">Informações da Baixa</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <b>Data Baixa:</b> {dadosBaixa?.data}
                      <br />
                      <b>Usuário:</b> {dadosBaixa?.nome}
                      <br />
                      <b>Ult. Alteração:</b> {dadosBaixa?.alteracao}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <Row className={!ehAlteracao ? 'd-none' : ''}>
          <Col md="12" xl="12">
            <Button
              type="button"
              variant="success"
              style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
              size="sm"
              className="btn btn-sm btn-success mx-1"
              onClick={handleAlterarFormaPagamento}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FormaPagamento;
