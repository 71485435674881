import React, { useRef, useCallback, useState, useMemo } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, Button, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/toast';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import SelectSync from '../../../components/SelectSync';
import getValidationErrors from '../../../utils/getValidationErrors';
import Toggle from '../../../components/Exame/Toggle';
import { optionsTipoModelo } from '../../../utils/options';
import EditorDocumento from '../../../components/EditorDocumento';
import {
  htmlDecode,
  optionsProfissionalModelo,
  optionsPacienteModelo,
  optionsClinicaModelo,
  optionsMensagemConfirmacaModelo,
  optionsMensagemAniversarioModelo,
  optionsMensagemCobrancaModelo,
  TIPO_DOCUMENTO,
} from '../../../utils/funcoes';

import api from '../../../services/api';
import getResponseErrors from '../../../utils/getResponseErrors';
import { IModelo } from '..';

type Props = {
  modeloMensagem: boolean;
  modelo?: IModelo;
  tipo?: number;
  setShowModal(value: boolean): void;
};

const ModeloCadastro: React.FC<Props> = ({
  modelo,
  modeloMensagem,
  tipo,
  setShowModal,
}) => {
  const { addToast } = useToast();
  const [modCodigo, setModCodigo] = useState<number | undefined>(
    modelo ? modelo.id : undefined,
  );

  const modTipo = useMemo(() => {
    if (modelo) return modelo.tipo;
    if (tipo) return tipo;
    return 0;
  }, [tipo, modelo]);

  const optionModelo = useMemo(() => {
    if (modeloMensagem === false) {
      return [
        ...optionsProfissionalModelo,
        ...optionsPacienteModelo,
        ...optionsClinicaModelo,
      ];
    }

    if (modeloMensagem && modTipo === TIPO_DOCUMENTO.CONFIRMACAO_WHATSAPP) {
      return optionsMensagemConfirmacaModelo;
    }

    if (modeloMensagem && modTipo === TIPO_DOCUMENTO.ANIVERSARIO_WHATSAPP) {
      return optionsMensagemAniversarioModelo;
    }

    if (modeloMensagem && modTipo === TIPO_DOCUMENTO.COBRANCA_WHATSAPP) {
      return optionsMensagemCobrancaModelo;
    }

    return [];
  }, [modTipo, modeloMensagem]);

  const formRefModelo = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmitModelo = useCallback(
    async (dados: IModelo) => {
      try {
        setLoading(true);
        formRefModelo.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string()
            .min(3, 'Mínimo 3 caracteres')
            .required('Nome é obrigatório'),
          ...(!modCodigo && {
            tipo: Yup.number().typeError('Selecione o Tipo').required(),
          }),
        });

        const modeloForm = {
          ...dados,
        };
        const ativo = dados.ativo ? dados.ativo.toString() : 'false';

        await schema.validate(modeloForm, {
          abortEarly: false,
        });

        const dadosModelo = {
          ...modeloForm,
          nome: modeloForm.nome.toUpperCase(),
          ativo: modeloMensagem ? true : ativo === 'true',
          ...(!modCodigo && modeloMensagem === true && { tipo }),
        };

        if (modCodigo) {
          await api.put<IModelo>(`/modelos/${modCodigo}`, dadosModelo);
        } else {
          await api.post<IModelo>('/modelos', {
            ...dadosModelo,
            ModModeloMensagem: modeloMensagem,
          });
        }
        setShowModal(false);
        addToast({ title: 'Modelo salvo', type: 'success' });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefModelo.current?.setErrors(errors);
          return;
        }

        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Erro ao salvar modelo',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    },
    [modCodigo, modeloMensagem, tipo, setShowModal, addToast],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading />}

      <Row noGutters>
        <Col md={12}>
          <Form
            ref={formRefModelo}
            initialData={{
              ...modelo,
              tipo: modelo?.tipo ? modelo.tipo.toString() : '0',
              texto: htmlDecode(modelo?.texto || ''),
            }}
            onSubmit={handleSubmitModelo}
          >
            <Row>
              <Col md={9} className="form-group pr-2">
                <Input
                  name="nome"
                  label="Nome"
                  maxLength={100}
                  disabled={modeloMensagem}
                />
              </Col>
              {!modCodigo && modeloMensagem === false && (
                <Col md={2} className="form-group pr-2">
                  <SelectSync
                    name="tipo"
                    label="Tipo"
                    placeholder="Selecione"
                    handleSelectChange={handleOnChangeVazio}
                    options={optionsTipoModelo}
                  />
                </Col>
              )}
              {modeloMensagem === false && (
                <Col md={1} className="form-group pr-2 pt-2">
                  <Toggle
                    nome="ativo"
                    label="Ativo"
                    classSpam={{ marginTop: '2px' }}
                    isDisabled={false}
                  />
                </Col>
              )}
            </Row>
            <Row className=" p-1">
              <Col md="9">
                <EditorDocumento
                  name="texto"
                  height={390}
                  modelo={optionModelo}
                  editorSimples={modeloMensagem}
                />
                <div className="light-grey text-left pt-3">
                  <>
                    <Button type="submit" variant="primary" className="mt-2">
                      Salvar dados
                    </Button>
                  </>
                </div>
              </Col>
              <Col md="3">
                <div className="card">
                  <div className="card-header">Campos automaticos</div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="datatable table table-sm table-borderless hover-table dataTable no-footer">
                        <tbody>
                          {modeloMensagem === false && (
                            <>
                              <tr>
                                <td className="text-center bg-dark text-white">
                                  <b>Paciente</b>
                                </td>
                              </tr>
                              {optionsPacienteModelo.map(op => (
                                <tr key={`modeloCadastroPaciente-${op.text}`}>
                                  <td>
                                    <b>{op.text}</b> {op.label}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-center bg-dark text-white">
                                  <b>Profissional</b>
                                </td>
                              </tr>
                              {optionsProfissionalModelo.map(op => (
                                <tr key={`modeloCadastroMedico-${op.text}`}>
                                  <td>
                                    <b>{op.text}</b> {op.label}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-center bg-dark text-white">
                                  <b>Clinica</b>
                                </td>
                              </tr>
                              {optionsClinicaModelo.map(op => (
                                <tr key={`modeloCadastroClinica-${op.text}`}>
                                  <td>
                                    <b>{op.text}</b> {op.label}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                          {modeloMensagem &&
                            modTipo === TIPO_DOCUMENTO.CONFIRMACAO_WHATSAPP && (
                              <>
                                <tr className="thead-light">
                                  <th className="text-center">
                                    <b>Dados Agendamento</b>
                                  </th>
                                </tr>
                                {optionsMensagemConfirmacaModelo.map(op => (
                                  <tr key={`modeloMensagemConfirma-${op.text}`}>
                                    <td>
                                      <b>{op.text}</b> {op.label}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          {modeloMensagem &&
                            modTipo === TIPO_DOCUMENTO.ANIVERSARIO_WHATSAPP && (
                              <>
                                <tr>
                                  <td className="text-center bg-dark text-white">
                                    <b>Dados Paciente</b>
                                  </td>
                                </tr>
                                {optionsMensagemAniversarioModelo.map(op => (
                                  <tr key={`modeloNiverConfirma-${op.text}`}>
                                    <td>
                                      <b>{op.text}</b> {op.label}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}

                          {modeloMensagem &&
                            modTipo === TIPO_DOCUMENTO.COBRANCA_WHATSAPP && (
                              <>
                                <tr>
                                  <td className="text-center bg-dark text-white">
                                    <b>Dados Paciente</b>
                                  </td>
                                </tr>
                                {optionsMensagemCobrancaModelo.map(op => (
                                  <tr key={`modeloNiverConfirma-${op.text}`}>
                                    <td>
                                      <b>{op.text}</b> {op.label}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ModeloCadastro;
