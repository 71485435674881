import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Row, Col, Button } from 'react-bootstrap';

import { format } from 'date-fns';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useProfissional } from '../../hooks/profissional';

import { ContainerDados } from './styles';

import Loading from '../Loading';
import Input from '../Input';
import SelectSync from '../SelectSync';
import EditorDocumento, { IOptionsModelo } from '../EditorDocumento';

import getResponseErrors from '../../utils/getResponseErrors';
import getValidationErrors from '../../utils/getValidationErrors';
import { TIPO_USUARIO, TIPO_DOCUMENTO } from '../../utils/funcoes';

import { IDocumento } from './index';

interface IModelo {
  ModCodigo: number;
  ModTipo: number;
  ModNome: string;
  ModTexto: string;
}

interface IFormBusca {
  DocUsuCodigo: number;
  DocData: Date;
  DocNome: string;
  DocTexto: string;
  Modelo: string;
}

type PacienteProps = {
  pacCodigo: number;
  docTipo: number;
  handleDadosSalvos?(dadosDocumento: IDocumento): void;
};

const DocumentoCadastro: React.FC<PacienteProps> = ({
  pacCodigo,
  docTipo,
  handleDadosSalvos,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsProfissional, buscaOptionsProfissional } = useProfissional();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [optionsModelo, setOptionsModelo] = useState<IOptionsModelo[]>();

  const initialData = {
    DocNome: docTipo === TIPO_DOCUMENTO.RECEITA ? 'RECEITA' : 'DOCUMENTO',
    DocData: format(new Date(), 'yyyy-MM-dd'),
    DocUsuCodigo: user.id.toString(),
  };
  const [bloqueiaComboProfissional, setBboqueiaComboProfissional] = useState(
    user.tipo === TIPO_USUARIO.MEDICO,
  );

  useEffect(() => {
    async function loadProfissionals(): Promise<void> {
      await buscaOptionsProfissional();
    }
    if (!optionsProfissional || Object.keys(optionsProfissional).length === 0)
      loadProfissionals();
  }, [buscaOptionsProfissional, optionsProfissional]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<[IModelo[], number]>(
          `/modelo?ModTipo=${docTipo}&ModModeloMensagem=false&ModAtivo=true`,
        );

        const options = response.data[0].map(rel => {
          return {
            label: rel.ModNome,
            value: rel.ModTexto,
            text: rel.ModNome,
          };
        });

        setOptionsModelo(options);
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível buscar dados',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (docTipo && docTipo > 0) {
      loadDados();
    }
  }, [addToast, docTipo]);

  const handleSubmit = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          DocData: Yup.date().required('Data é obrigatório'),
          DocNome: Yup.string().required('Título é obrigatório'),
          DocTexto: Yup.string().required('Texto é obrigatório'),
          DocUsuCodigo: Yup.number().required('Médico é obrigatório'),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        const response = await api.post<IDocumento>(`/documentos`, {
          ...dados,
          DocNome: dados.DocNome.toUpperCase(),
          DocPacCodigo: pacCodigo,
          DocTipo: docTipo,
          DocCliCodigo: user.clinica_id,
        });
        if (handleDadosSalvos) handleDadosSalvos(response.data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [pacCodigo, docTipo, user.clinica_id, handleDadosSalvos, addToast],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  return (
    <>
      {loading && <Loading />}
      <ContainerDados className="rounded">
        {optionsProfissional && Object.keys(optionsProfissional).length > 0 && (
          <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <SelectSync
                  name="DocUsuCodigo"
                  label="Médico"
                  options={optionsProfissional}
                  isClearable
                  handleSelectChange={handleOnChangeVazio}
                  isDisabled={bloqueiaComboProfissional}
                />
              </Col>
              <Col md={3}>
                <Input name="DocNome" label="Título" required />
              </Col>
              <Col md={3}>
                <Input name="DocData" label="Data" type="date" required />
              </Col>
              <Col md={12} className="my-2">
                {optionsModelo && (
                  <EditorDocumento
                    modelo={optionsModelo}
                    name="DocTexto"
                    labelButton="Modelos"
                    height={500}
                  />
                )}
              </Col>
              <Col md={12}>
                <Button type="submit" variant="success" disabled={loading}>
                  Salvar Documento
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </ContainerDados>
    </>
  );
};

export default DocumentoCadastro;
