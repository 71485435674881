import styled from 'styled-components';

interface StatusProps {
  conectado: boolean;
}

export const Status = styled.div<StatusProps>`
  justify-content: center;
  display: grid;
  color: ${props => (props.conectado ? 'green' : 'red')};
  span {
    font-size: 1rem;
    margin: 20px 0;
  }
`;

export const ContainerWhatsapp = styled.div`
  display: flex;
  @media (max-width: 790px) {
    display: block;
  }
`;

export const ContainerText = styled.div`
  flex: 1 1 auto;
  max-width: 68%;
  @media (max-width: 790px) {
    flex: none;
  }
`;

export const LandingTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  color: #41525d;
  margin-bottom: 7px;
`;

export const Lista = styled.ol`
  padding: 0 0 0 24px;
  list-style: none;
  list-style-type: decimal;
  margin: 0;
  li {
    font-size: 16px;
    line-height: 25px;
    color: #3b4a54;
    display: list-item;
    strong {
      display: inline-block;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;

export const ContainerQR = styled.div`
  div {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    width: 264px;
    height: 264px;
    overflow: hidden;
    border-radius: 2px;
  }
`;
