import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FiPlusCircle } from 'react-icons/fi';
import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../services/api';

import { formataTelefone, TIPO_USUARIO } from '../../utils/funcoes';

import { useToast } from '../../hooks/toast';
import { useClinica } from '../../hooks/clinica';
import { useAuth } from '../../hooks/auth';

import { optionsTipoUsuario, IOptions } from '../../utils/options';

import Input from '../../components/Input';
import Select from '../../components/Select';
import Paginacao from '../../components/Paginacao';
import Modal from '../../components/Modal';

import SkeletonUsuario from './Skeleton';

import UsuarioCadastro from './UsuarioCadastro/index';

import getValidationErrors from '../../utils/getValidationErrors';
import getResponseErrors from '../../utils/getResponseErrors';
import Linha from './Linha';

export interface IUsuario {
  id: number;
  nome: string;
  dataNasc: Date;
  dataNascFormatado: string;
  email: string;
  telefone: string;
  tipo: number;
  especialidade: string;
  conselhoNome: string;
  conselhoNumero: string;
  loginIni: string;
  loginFim: string;
  cpf: string;
  rg: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  cep: string;
  uf: string;
  complemento: string;
  ativo: boolean;
  clinica_id: string;
  avatar_url: string;
  situacao: string;
  cssLinha: string;
  telefoneFormatado: string;
  tipoFormatado: string;
  horarioLogin: string;
  exibeBotaoAcao: boolean;
}

interface IRetornoUsuario {
  data: IUsuario[];
  count: number;
}

interface IFormBusca {
  nome: string;
  tipo: string;
  ativo: string;
  clinica_id: string;
}

interface IPaginacao {
  nome?: string;
  tipo?: string;
  ativo?: string;
  clinica_id?: string;
  limite: number;
  pagina: number;
}

export interface ITipoUsuario {
  ehMedico: boolean;
  label: string;
  options: IOptions[];
}

type pageProps = {
  Tipo: string;
};

const Usuario: React.FC<pageProps> = ({ Tipo }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { optionsClinica, imagensClinica } = useClinica();
  const { user } = useAuth();
  const ehAdmin = useMemo(() => {
    return user.tipo === TIPO_USUARIO.ADMINISTRADOR;
  }, [user.tipo]);

  const formRefBusca = useRef<FormHandles>(null);

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) history.push('/sempermissao');

    if (!ehAdmin && Tipo !== 'profissional')
      formRefBusca.current?.setFieldValue(
        'clinica_id',
        user.clinica_id.toString(),
      );
  }, [history, user, ehAdmin, Tipo]);

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const tipoUsuario =
    Tipo === 'profissional'
      ? {
          ehMedico: true,
          label: 'Profissional',
          options: optionsTipoUsuario.filter(
            tip => tip.value === TIPO_USUARIO.MEDICO.toString(),
          ),
        }
      : {
          ehMedico: false,
          label: 'Usuário',
          options: ehAdmin
            ? optionsTipoUsuario.filter(
                tip => tip.value !== TIPO_USUARIO.MEDICO.toString(),
              )
            : optionsTipoUsuario.filter(
                tip =>
                  tip.value !== TIPO_USUARIO.MEDICO.toString() &&
                  tip.value !== TIPO_USUARIO.ADMINISTRADOR.toString(),
              ),
        };

  const [usuarios, setUsuarios] = useState<IUsuario[] | undefined>(undefined);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<number>();
  const [totalRegistros, setTotalRegistros] = useState<number | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    nome: undefined,
    ativo: 'true',
    tipo: undefined,
    clinica_id:
      ehAdmin || tipoUsuario.ehMedico ? undefined : user.clinica_id.toString(),
    limite: 20,
    pagina: 1,
  });

  const handleFormataUsuarios = useCallback(
    (dados: IUsuario[]) => {
      const usuariosFormatados = dados.map(usu => {
        const tipoFormatado =
          optionsTipoUsuario.find(tip => tip.value === usu.tipo.toString())
            ?.label || '';
        return {
          ...usu,
          telefoneFormatado: formataTelefone(usu.telefone),
          situacao: usu.ativo === true ? 'Sim' : 'Não',
          cssLinha: usu.ativo === true ? '' : 'text-muted',
          avatar_url: usu.avatar_url ? usu.avatar_url : semFoto,
          horarioLogin: `${usu.loginIni} as ${usu.loginFim}`,
          tipoFormatado,
          exibeBotaoAcao:
            (ehAdmin ||
              (!ehAdmin && usu.tipo !== TIPO_USUARIO.ADMINISTRADOR)) &&
            !usu.nome.includes('FRANQUIA'),
        };
      });

      return usuariosFormatados;
    },
    [semFoto, ehAdmin],
  );

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        if (!paginacao) return;

        const { pagina, limite, nome, ativo, tipo, clinica_id } = paginacao;

        let querySql = '';
        if (nome && nome.length > 0) querySql += `&nome=${nome}`;
        if (ativo && ativo !== '0') querySql += `&ativo=${ativo}`;
        if (clinica_id && clinica_id !== '0')
          querySql += `&clinica_id=${clinica_id}`;
        if (tipoUsuario.ehMedico) {
          querySql += `&tipo=${TIPO_USUARIO.MEDICO}`;
        }
        if (!tipoUsuario.ehMedico && tipo && tipo !== '0')
          querySql += `&tipo=${tipo}`;

        const response = await api.get<IRetornoUsuario>(
          `/users/busca?pagina=${pagina}&limite=${limite}${querySql}`,
        );
        const { data, count } = response.data;

        setTotalRegistros(count);

        const usuariosFormatados = handleFormataUsuarios(data);

        setUsuarios(usuariosFormatados);
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível buscar dados',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }

    if (paginacao) loadDados();
  }, [addToast, paginacao, handleFormataUsuarios, tipoUsuario.ehMedico]);

  const handleSubmitBusca = useCallback(async (dados: IFormBusca) => {
    try {
      formRefBusca.current?.setErrors({});
      const schema = Yup.object().shape({
        nome: Yup.string(),
        ativo: Yup.string().nullable(true),
        tipo: Yup.string().nullable(true),
        clinica_id: Yup.string().nullable(true),
      });

      await schema.validate(dados, {
        abortEarly: false,
      });

      setUsuarios(undefined);

      setPaginacao({
        ...dados,
        pagina: 1,
        limite: 20,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefBusca.current?.setErrors(errors);
      }
    }
  }, []);

  const handleDeleteCliente = useCallback(
    async (codigo: number) => {
      setLoading(true);
      try {
        await api.delete(`/users/${codigo}`);
        setUsuarios(state => state?.filter(usu => usu.id !== codigo));
        addToast({
          type: 'success',
          title: 'Registro excluído',
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);

        addToast({
          type: 'error',
          title: 'Não foi possível excluir o registro',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleProximaPagina = useCallback((page: number) => {
    setPaginacao(state => {
      return state && { ...state, pagina: page };
    });
  }, []);

  const handleSalvouUsuario = useCallback(
    (usuario: IUsuario) => {
      const usuarioFormatado = handleFormataUsuarios([usuario]);
      setUsuarios(state => {
        const users = state?.filter(use => use.id !== usuario.id);
        if (users) return users.concat(usuarioFormatado);
        return usuarioFormatado;
      });
      setTotalRegistros(state => (state ? state + 1 : 1));
      setUsuarioSelecionado(undefined);
      setShowModalCadastro(false);
    },
    [handleFormataUsuarios],
  );

  return (
    <Container fluid className="pt-2">
      {showModalCadastro && (usuarioSelecionado || usuarioSelecionado === 0) && (
        <Modal
          show={showModalCadastro}
          titulo={`Cadastro de ${tipoUsuario.label}`}
          large
          handleFecharModal={() => {
            setShowModalCadastro(false);
            setUsuarioSelecionado(undefined);
          }}
        >
          <UsuarioCadastro
            id={0}
            tipoUsuario={tipoUsuario}
            ehAdmin={ehAdmin}
            handleRetornaDados={usuario => handleSalvouUsuario(usuario)}
          />
        </Modal>
      )}

      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <span id="topoBusca" className="card-title">
              {tipoUsuario.label}
            </span>
          </div>
          <div className="col-auto d-flex justify-content-end align-items-center">
            <Button
              type="button"
              variant="outline-primary mr-3"
              size="sm"
              onClick={() => {
                setUsuarioSelecionado(0);
                setShowModalCadastro(true);
              }}
            >
              <FiPlusCircle size={18} /> Novo {tipoUsuario.label}
            </Button>
            <div className="doc-badge me-3 small">
              Total registros{' '}
              <span className="bg-danger text-white px-2 py-1">
                {totalRegistros}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Form
            ref={formRefBusca}
            initialData={{ ativo: 'true' }}
            onSubmit={handleSubmitBusca}
          >
            <Row>
              <Col md={4}>
                <Input name="nome" label="Nome" maxLength={100} minLength={3} />
              </Col>
              <Col md={2}>
                <Select
                  name="tipo"
                  label="Tipo"
                  options={tipoUsuario.options}
                />
              </Col>
              <Col md={2}>
                <Select
                  name="ativo"
                  label="Situação"
                  options={[
                    { value: '0', label: 'Todos' },
                    { value: 'true', label: 'Ativo' },
                    { value: 'false', label: 'Desativado' },
                  ]}
                />
              </Col>
              <Col md={2} className={Tipo === 'medico' ? 'd-none' : ''}>
                <Select
                  name="clinica_id"
                  label="Clinica"
                  options={Tipo === 'profissional' ? [] : optionsClinica}
                  disabled={
                    user.tipo === TIPO_USUARIO.SUPERVISOR &&
                    Tipo !== 'profissional'
                  }
                />
              </Col>
              <Col md={2}>
                <br />
                <Button type="submit" size="sm" variant="outline-secondary">
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="datatable table table-borderless hover-table dataTable no-footer">
              <thead className="thead-light">
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Acesso</th>
                  <th>Ativo</th>
                  {tipoUsuario.ehMedico && (
                    <>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </>
                  )}
                  <th style={{ width: '55px' }}>&nbsp;</th>
                  <th style={{ width: '55px' }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {usuarios &&
                  !loading &&
                  usuarios.map(usuario => (
                    <Linha
                      key={`usuario-${usuario.id}`}
                      usuario={usuario}
                      ehAdmin={ehAdmin}
                      tipoUsuario={tipoUsuario}
                    />
                  ))}
                {loading && (
                  <>
                    <SkeletonUsuario />
                    <SkeletonUsuario />
                    <SkeletonUsuario />
                  </>
                )}
                <tr>
                  <td colSpan={6} />
                </tr>
              </tbody>
            </table>

            {(!usuarios || usuarios.length === 0) && !loading && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: '250px' }}
              >
                <h5>Pesquise usuários pelo nome, tipo e/ou Situação.</h5>
              </div>
            )}
          </div>

          {paginacao && (
            <Paginacao
              limitePagina={paginacao?.limite || 20}
              paginasVizinhas={2}
              totalRegistros={totalRegistros}
              paginaInicio={paginacao?.pagina}
              handleProximaPagina={handleProximaPagina}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Usuario;
