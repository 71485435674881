import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { format, parseISO } from 'date-fns';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { FaClock, FaPlay, FaStop } from 'react-icons/fa';
import { useRouteMatch } from 'react-router';
import { isNull } from 'lodash';
import { FiRotateCcw } from 'react-icons/fi';
import Loading from '../../components/Loading';
import api from '../../services/api';
import { formataCPF, formataIdade, formataTelefone } from '../../utils/funcoes';

import { Titulo } from './styles';

import { useToast } from '../../hooks/toast';
import { useClinica } from '../../hooks/clinica';
import PacienteCabecalho from '../../components/PacienteCabecalho';
import {
  IPaciente,
  IProcedimentoAgendamento,
} from '../../components/Agendamento';
import { IExamesAlvaro } from '../Exame';
import { useAuth } from '../../hooks/auth';
import getResponseErrors from '../../utils/getResponseErrors';

interface IUrlParams {
  id: string;
}

interface ICreateExame {
  agendamento_id: number;
  cliente_id: number;
  cliente_nome: string;
  cliente_sexo: string;
  data: string;
  cliente_dataNasc: Date;
  usuario_id: number;
  clinica_id: number;
  exameItens: {
    codigoAlvaro: string;
    material: string;
    qtde: number;
  }[];
}
interface IExame {
  id?: number;
  agendamento_id: number;
  cliente_id: number;
  cliente_nome: string;
  cliente_sexo: string;
  data: string;
  hora: string;
  cliente_dataNasc: string;
  codigoLis: string;
  codigoOS: string;
  observacao: string;
  incluido: boolean;
  baseBarras: string;
  usuario_id: number;
  link: string;
  clinica_id: string;
}

export interface IProcedimentoAgendamentoAlvaro
  extends IProcedimentoAgendamento {
  validado?: boolean;
  codigoAlvaro?: string;
  exameAlvaro?: IExamesAlvaro;
}

const PacienteAtendimentoAlvaro: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  function useQuery(): URLSearchParams {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const { params } = useRouteMatch<IUrlParams>();
  const { id } = params;
  const { addToast } = useToast();
  const { imagensClinica } = useClinica();

  const semFoto =
    imagensClinica.find(img => img.nome === 'semFoto')?.arquivo_url || '';

  const [loading, setLoading] = useState(false);
  const [ageClinica, setAgeClinica] = useState<number | undefined>(undefined);
  const [paciente, setPaciente] = useState<IPaciente | undefined>(undefined);
  const [procedimentos, setProcedimentos] = useState<
    IProcedimentoAgendamentoAlvaro[]
  >([]);

  const [exame, setExame] = useState<IExame[]>([]);

  const [codigoAgenda, setCodigoAgenda] = useState(() => {
    return query.get('agenda') || undefined;
  });
  const [dataAgenda, setDataAgenda] = useState(() => {
    return query.get('data') || undefined;
  });

  const [somenteLista, setSomenteLista] = useState(() => {
    return query.get('somenteLista') === 'true';
  });

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get(
          `/atendimentos/${id}/detalhe?integracaoAlvaro=1`,
        );

        if (response.data) {
          setAgeClinica(response.data.clinica_id);
          setPaciente({
            ...response.data.cliente,
            id: response.data.cliente_id,
            telefone: formataTelefone(response.data.cliente.telefone),
            celular: formataTelefone(response.data.cliente.celular),
            cpf: formataCPF(response.data.cliente.cpf),
            dataNascFormatada: format(
              parseISO(response.data.cliente.dataNasc),
              'dd/MM/yyyy',
            ),
            idade: formataIdade(parseISO(response.data.cliente.dataNasc)),
            avatar_url: response.data.cliente.avatar_url || semFoto,
          });

          setProcedimentos(
            response.data.procedimentosValorCobrado.map((pro: any) => {
              const { procedimentoAgendado } = pro;
              return {
                ...procedimentoAgendado,
                id: procedimentoAgendado.id,
                nome: procedimentoAgendado.nome,
                duracao: procedimentoAgendado.duracao,
                codigoAlvaro:
                  procedimentoAgendado.codigoAlvaro &&
                  procedimentoAgendado.codigoAlvaro !== null
                    ? procedimentoAgendado.codigoAlvaro
                    : '---',
                validado:
                  procedimentoAgendado.codigoAlvaro !== '' &&
                  procedimentoAgendado.codigoAlvaro !== '---' &&
                  procedimentoAgendado.codigoAlvaro !== null &&
                  procedimentoAgendado.exameAlvaro,
                exameAlvaro: procedimentoAgendado.exameAlvaro,
              };
            }),
          );

          if (response.data.exames) {
            const exameRealizado = response.data.exames.map(
              (exa: IExame) => exa,
            );
            setExame(exameRealizado);
          }
          if (response.data.pacienteValidado !== '')
            addToast({
              title: 'Dados do Paciente inválidos',
              description: response.data.pacienteValidado,
              type: 'error',
            });

          if (response.data.exameInvalidos)
            addToast({
              title: 'Dados do exame(s) inválido(s)',
              description: response.data.exameInvalidos,
              type: 'error',
            });
        }
      } catch (error) {
        const mensagem = getResponseErrors(error);
        addToast({
          type: 'error',
          title: 'Não foi possível buscar o agendamento',
          description: mensagem,
        });
      } finally {
        setLoading(false);
      }
    }
    if (id && Number(id) !== 0) loadDados();
  }, [id, addToast, semFoto]);

  const handleVoltar = useCallback(() => {
    const queryParams = qs.parse(location.search);
    history.push({
      pathname: '../agendaatendimento',
      search: qs.stringify({
        ...queryParams,
        data: dataAgenda,
        agenda: codigoAgenda,
      }),
    });
  }, [codigoAgenda, dataAgenda, history, location.search]);

  const gerouOS = useMemo(() => {
    const exameID = exame[0] ? exame[0].id : undefined;
    const exameRealizado = exame?.find((exa: IExame) => !isNull(exa.codigoOS));
    if (!exameRealizado) return { gerou: false, CodExame: exameID };
    return {
      gerou: exameRealizado && exameRealizado.codigoOS !== '',
      CodExame: exameID,
    };
  }, [exame]);

  const handleCriarExame = useCallback(async () => {
    if (paciente && ageClinica) {
      if (gerouOS.CodExame) {
        history.push({
          pathname: '../exame',
          search: qs.stringify({
            id: gerouOS.CodExame,
            data: dataAgenda,
            agenda: codigoAgenda,
          }),
        });
      } else {
        try {
          const criaExame: ICreateExame = {
            agendamento_id: Number(id),
            clinica_id: ageClinica,
            data: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            cliente_id: paciente.id,
            cliente_dataNasc: parseISO(paciente.dataNasc.toString()),
            cliente_nome: paciente.nome,
            cliente_sexo: paciente.sexo,
            usuario_id: user.id,
            exameItens: procedimentos.map(proc => ({
              codigoAlvaro: proc.exameAlvaro?.codigo || '',
              material: proc.exameAlvaro?.material || '',
              qtde: 1,
            })),
          };

          setLoading(true);

          const response = await api.post('exame', criaExame);
          if (response.data.id)
            history.push({
              pathname: '../exame',
              search: qs.stringify({
                id: response.data.id,
                data: dataAgenda,
                agenda: codigoAgenda,
                somenteLista,
              }),
            });
        } catch (error) {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Erro ao criar exame',
            description: mensagem,
          });
        } finally {
          setLoading(false);
        }
      }
    }
  }, [
    ageClinica,
    id,
    paciente,
    procedimentos,
    user.id,
    history,
    codigoAgenda,
    dataAgenda,
    gerouOS,
    addToast,
    somenteLista,
  ]);

  const handleAbrirExame = useCallback(() => {
    if (gerouOS.CodExame) {
      history.push({
        pathname: '../exame',
        search: qs.stringify({
          id: gerouOS.CodExame,
          data: dataAgenda,
          agenda: codigoAgenda,
          somenteLista,
        }),
      });
    }
  }, [codigoAgenda, gerouOS.CodExame, dataAgenda, somenteLista, history]);

  return (
    <Container fluid className="pt-2">
      {loading && <Loading backgroundBlack={false} />}

      <div className="d-flex justify-content-between">
        <h5 className="p-2 text-secondary">ID de Atendimento #{id}</h5>
        <div>
          <Button
            type="button"
            variant="outline-info"
            size="sm"
            className="py-2 border-0"
            onClick={handleVoltar}
          >
            <FiRotateCcw /> Voltar para Agenda
          </Button>
        </div>
      </div>

      <Row>
        <Col md={12}>
          <div className="card mb-2">
            <div className="card-header">
              <h5 className="card-title">Paciente</h5>
            </div>

            <div className="card-body">
              {paciente && (
                <PacienteCabecalho
                  paciente={paciente}
                  pacienteID={paciente.id}
                  procedimentos={procedimentos}
                  exibeEditar
                  idadeCompleta
                />
              )}
            </div>
          </div>
        </Col>
      </Row>

      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Button
            type="button"
            variant="success"
            className={`mb-1 ${!gerouOS.CodExame ? '' : 'd-none'}`}
            onClick={() => handleCriarExame()}
          >
            <FaPlay size="12" />
            Iniciar Atendimento
          </Button>
        </div>
        <div>
          <Button
            type="button"
            variant="danger"
            className={`mb-1 ${
              !gerouOS.gerou && gerouOS.CodExame ? '' : 'd-none'
            }`}
            onClick={() => handleAbrirExame()}
          >
            <FaPlay size="12" />
            Gerar Pedido Exame
          </Button>
        </div>
      </div>

      <Row>
        <Col>
          <div className="mt-1 mb-2" style={{ minHeight: '350px' }}>
            <Row>
              <Col md="3">
                <Titulo>Exames a realizar</Titulo>
              </Col>
              <Col md="9" />
            </Row>
            <div className="w-100" />

            <div className="bg-white p-2 border rounded">
              <table className="table table-hover table-sm border">
                <tbody>
                  <tr>
                    <th>Procedimento Clínica</th>
                    <th>Código Alvaro</th>
                    <th>Descrição Alvaro</th>
                    <th>Dados Obrigatórios</th>
                  </tr>
                  {procedimentos &&
                    procedimentos.map(proc => (
                      <tr key={proc.id}>
                        <td>{proc.nome}</td>
                        <td>{proc.exameAlvaro?.codigo}</td>
                        <td>{proc.exameAlvaro?.nome || 'NÃO CADASTRADO'}</td>
                        <td>{proc.exameAlvaro?.dadoObrigatorio}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PacienteAtendimentoAlvaro;
