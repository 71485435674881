import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface AvatarProps {
  circular: boolean;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
`;

export const AvatarInput = styled.div<AvatarProps>`
  position: relative;
  align-self: center;
  ${props =>
    props.circular
      ? css`
          img {
            width: 186px;
            height: 186px;
            border-radius: 50%;
          }
        `
      : css`
          img {
            width: 100%;
            max-width: 175px;
            max-height: 120px;
            min-height: 80px;
            border-radius: 8px;
          }
        `};
  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #ff9000;
    border-radius: 50%;
    right: 3px;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s svg {
      width: 20px;
      height: 20px;
    }
    cursor: pointer;
    input {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const Load = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  height: 45px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 14px;
    animation: fa-spin 2s infinite linear;
    @keyframes fa-spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
`;

export const Exame = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;
