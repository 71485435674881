/* eslint-disable no-plusplus */
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  addYears,
  addMonths,
} from 'date-fns';

import { Colors } from '../styles/constants';

const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

function formatNumber2CasasDecimais(numero: string): string {
  return Number.parseFloat(numero).toFixed(2);
}

function replaceCaracteres(texto: string | undefined): string {
  if (!texto) return '';
  return texto.replace(/[^\d]/g, '');
}

function somenteLetrasENumeros(texto: string): string {
  // letras, numeros e espaco
  return texto.replace(/[^A-Za-z0-9]+/g, '');
}

function replaceCurrency(data: string): string {
  return data
    .replace(' ', '')
    .replace('R$', '')
    .replace('.', '')
    .replace(',', '.');
}

function formataTelefone(tel: string): string {
  if (!tel || tel.length === 0) return '';
  const retorno = tel.replace(/[^\d]/g, '');

  if (retorno.length === 11) {
    return retorno.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
  }

  return retorno.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
}

function formataCPF(inputString: string): string {
  if (!inputString) return '';
  const retorno = inputString.replace(/[^\d]/g, '');

  return retorno.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

function formataCNPJ(inputString: string): string {
  if (!inputString) return '';
  const retorno = inputString.replace(/[^\d]/g, '');

  return retorno.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  );
}

function formataCep(cep: string): string {
  const retorno = cep.replace(/[^\d]/g, '');
  return retorno.replace(/(\d{5})(\d{3})/, '$1-$2');
}

function formataIdade(date: Date, completa?: boolean): string {
  const result = [];
  const now = new Date();
  let age = date;

  const years = differenceInYears(now, age);
  if (!completa) {
    return `${years} anos`;
  }
  if (years > 0) {
    result.push(`${years} anos`);
    age = addYears(age, years);
  }

  const months = differenceInMonths(now, age);
  if (months > 0) {
    result.push(`${months} meses`);
    age = addMonths(age, months);
  }

  const days = differenceInDays(now, age);
  if (days > 0) {
    result.push(`${days} dias`);
  }

  return result.join(', ');
}

interface IOptions {
  value: string;
  label: string;
}

const ORIGEM_NOTIFICACAO = {
  CONFIRMACAO_AGENDAMENTOS: 'Confirma agendamento?',
  LEMBRETE_AGENDAMENTO: 'Lembrete de agendamento',
  LEMBRETE_VENCIMENTO: 'Lembrete de vencimento',
  LEMBRETE_VENCIDO: 'Lembrete vencido',
  CONVITE_PARTICULAR: 'Convite particular',
};

const optionsTipoMensagem = [
  { value: '', label: 'Todos' },
  {
    value: ORIGEM_NOTIFICACAO.CONFIRMACAO_AGENDAMENTOS,
    label: 'Confirmação de agendamento',
  },
  {
    value: ORIGEM_NOTIFICACAO.LEMBRETE_AGENDAMENTO,
    label: ORIGEM_NOTIFICACAO.LEMBRETE_AGENDAMENTO,
  },
  {
    value: ORIGEM_NOTIFICACAO.LEMBRETE_VENCIMENTO,
    label: ORIGEM_NOTIFICACAO.LEMBRETE_VENCIMENTO,
  },
  {
    value: ORIGEM_NOTIFICACAO.CONVITE_PARTICULAR,
    label: ORIGEM_NOTIFICACAO.CONVITE_PARTICULAR,
  },
];

function optionsHorasAtendimento(): IOptions[] {
  const horarios = [
    { value: '07:00', label: '07:00' },
    { value: '07:05', label: '07:05' },
    { value: '07:10', label: '07:10' },
    { value: '07:15', label: '07:15' },
    { value: '07:20', label: '07:20' },
    { value: '07:25', label: '07:25' },
    { value: '07:30', label: '07:30' },
    { value: '07:35', label: '07:35' },
    { value: '07:40', label: '07:40' },
    { value: '07:45', label: '07:45' },
    { value: '07:50', label: '07:50' },
    { value: '07:55', label: '07:55' },
    { value: '08:00', label: '08:00' },
    { value: '08:05', label: '08:05' },
    { value: '08:10', label: '08:10' },
    { value: '08:15', label: '08:15' },
    { value: '08:20', label: '08:20' },
    { value: '08:25', label: '08:25' },
    { value: '08:30', label: '08:30' },
    { value: '08:35', label: '08:35' },
    { value: '08:40', label: '08:40' },
    { value: '08:45', label: '08:45' },
    { value: '08:50', label: '08:50' },
    { value: '08:55', label: '08:55' },
    { value: '09:00', label: '09:00' },
    { value: '09:05', label: '09:05' },
    { value: '09:10', label: '09:10' },
    { value: '09:15', label: '09:15' },
    { value: '09:20', label: '09:20' },
    { value: '09:25', label: '09:25' },
    { value: '09:30', label: '09:30' },
    { value: '09:35', label: '09:35' },
    { value: '09:40', label: '09:40' },
    { value: '09:45', label: '09:45' },
    { value: '09:50', label: '09:50' },
    { value: '09:55', label: '09:55' },
    { value: '10:00', label: '10:00' },
    { value: '10:05', label: '10:05' },
    { value: '10:10', label: '10:10' },
    { value: '10:15', label: '10:15' },
    { value: '10:20', label: '10:20' },
    { value: '10:25', label: '10:25' },
    { value: '10:30', label: '10:30' },
    { value: '10:35', label: '10:35' },
    { value: '10:40', label: '10:40' },
    { value: '10:45', label: '10:45' },
    { value: '10:50', label: '10:50' },
    { value: '10:55', label: '10:55' },
    { value: '11:00', label: '11:00' },
    { value: '11:05', label: '11:05' },
    { value: '11:10', label: '11:10' },
    { value: '11:15', label: '11:15' },
    { value: '11:20', label: '11:20' },
    { value: '11:25', label: '11:25' },
    { value: '11:30', label: '11:30' },
    { value: '11:35', label: '11:35' },
    { value: '11:40', label: '11:40' },
    { value: '11:45', label: '11:45' },
    { value: '11:50', label: '11:50' },
    { value: '11:55', label: '11:55' },
    { value: '12:00', label: '12:00' },
    { value: '12:05', label: '12:05' },
    { value: '12:10', label: '12:10' },
    { value: '12:15', label: '12:15' },
    { value: '12:20', label: '12:20' },
    { value: '12:25', label: '12:25' },
    { value: '12:30', label: '12:30' },
    { value: '12:35', label: '12:35' },
    { value: '12:40', label: '12:40' },
    { value: '12:45', label: '12:45' },
    { value: '12:50', label: '12:50' },
    { value: '12:55', label: '12:55' },
    { value: '13:00', label: '13:00' },
    { value: '13:05', label: '13:05' },
    { value: '13:10', label: '13:10' },
    { value: '13:15', label: '13:15' },
    { value: '13:20', label: '13:20' },
    { value: '13:25', label: '13:25' },
    { value: '13:30', label: '13:30' },
    { value: '13:35', label: '13:35' },
    { value: '13:40', label: '13:40' },
    { value: '13:45', label: '13:45' },
    { value: '13:50', label: '13:50' },
    { value: '13:55', label: '13:55' },
    { value: '14:00', label: '14:00' },
    { value: '14:05', label: '14:05' },
    { value: '14:10', label: '14:10' },
    { value: '14:15', label: '14:15' },
    { value: '14:20', label: '14:20' },
    { value: '14:25', label: '14:25' },
    { value: '14:30', label: '14:30' },
    { value: '14:35', label: '14:35' },
    { value: '14:40', label: '14:40' },
    { value: '14:45', label: '14:45' },
    { value: '14:50', label: '14:50' },
    { value: '14:55', label: '14:55' },
    { value: '15:00', label: '15:00' },
    { value: '15:05', label: '15:05' },
    { value: '15:10', label: '15:10' },
    { value: '15:15', label: '15:15' },
    { value: '15:20', label: '15:20' },
    { value: '15:25', label: '15:25' },
    { value: '15:30', label: '15:30' },
    { value: '15:35', label: '15:35' },
    { value: '15:40', label: '15:40' },
    { value: '15:45', label: '15:45' },
    { value: '15:50', label: '15:50' },
    { value: '15:55', label: '15:55' },
    { value: '16:00', label: '16:00' },
    { value: '16:05', label: '16:05' },
    { value: '16:10', label: '16:10' },
    { value: '16:15', label: '16:15' },
    { value: '16:20', label: '16:20' },
    { value: '16:25', label: '16:25' },
    { value: '16:30', label: '16:30' },
    { value: '16:35', label: '16:35' },
    { value: '16:40', label: '16:40' },
    { value: '16:45', label: '16:45' },
    { value: '16:50', label: '16:50' },
    { value: '16:55', label: '16:55' },
    { value: '17:00', label: '17:00' },
    { value: '17:05', label: '17:05' },
    { value: '17:10', label: '17:10' },
    { value: '17:15', label: '17:15' },
    { value: '17:20', label: '17:20' },
    { value: '17:25', label: '17:25' },
    { value: '17:30', label: '17:30' },
    { value: '17:35', label: '17:35' },
    { value: '17:40', label: '17:40' },
    { value: '17:45', label: '17:45' },
    { value: '17:50', label: '17:50' },
    { value: '17:55', label: '17:55' },
    { value: '18:00', label: '18:00' },
    { value: '18:05', label: '18:05' },
    { value: '18:10', label: '18:10' },
    { value: '18:15', label: '18:15' },
    { value: '18:20', label: '18:20' },
    { value: '18:25', label: '18:25' },
    { value: '18:30', label: '18:30' },
    { value: '18:35', label: '18:35' },
    { value: '18:40', label: '18:40' },
    { value: '18:45', label: '18:45' },
    { value: '18:50', label: '18:50' },
    { value: '18:55', label: '18:55' },
    { value: '19:00', label: '19:00' },
    { value: '19:05', label: '19:05' },
    { value: '19:10', label: '19:10' },
    { value: '19:15', label: '19:15' },
    { value: '19:20', label: '19:20' },
    { value: '19:25', label: '19:25' },
    { value: '19:30', label: '19:30' },
    { value: '19:35', label: '19:35' },
    { value: '19:40', label: '19:40' },
    { value: '19:45', label: '19:45' },
    { value: '19:50', label: '19:50' },
    { value: '19:55', label: '19:55' },
    { value: '20:00', label: '20:00' },
    { value: '20:05', label: '20:05' },
    { value: '20:10', label: '20:10' },
    { value: '20:15', label: '20:15' },
    { value: '20:20', label: '20:20' },
    { value: '20:25', label: '20:25' },
    { value: '20:30', label: '20:30' },
    { value: '20:35', label: '20:35' },
    { value: '20:40', label: '20:40' },
    { value: '20:45', label: '20:45' },
    { value: '20:50', label: '20:50' },
    { value: '20:55', label: '20:55' },
  ];

  return horarios;
}

const optionsStatusAgendamento = [
  { value: '-2', label: 'ESTORNO' },
  { value: '-1', label: 'FALTOU' },
  { value: '0', label: 'CANCELADO' },
  { value: '1', label: 'AGUARDANDO CONFIRMAÇÃO' },
  { value: '2', label: 'CONFIRMADO' },
  { value: '3', label: 'CAIXA' },
  { value: '4', label: 'AGUARDANDO ATENDIMENTO' },
  { value: '5', label: 'ATENDIDO' },
  { value: '6', label: 'PAGAMENTO ANTECIPADO' },
  { value: '00', label: 'SELECIONE' },
];

const optionsConfirmado = [
  { value: '0', label: 'AGUARDANDO CONFIRMAÇÃO' },
  { value: '1', label: 'CONFIRMADO' },
  { value: '2', label: 'CONFIRMADO ON-LINE' },
  { value: '3', label: 'NÃO VAI' },
];

const statusAgendamento = [
  { id: -2, nome: 'ESTORNO', color: Colors.danger, icon: 'ban' },
  { id: -1, nome: 'FALTOU', color: Colors.danger, icon: 'ban' },
  { id: 0, nome: 'CANCELADO', color: Colors.danger, icon: 'ban' },
  {
    id: 1,
    nome: 'AGUARDANDO CONFIRMAÇÃO',
    color: Colors.primary,
    icon: 'question',
  },
  { id: 2, nome: 'CONFIRMADO', color: Colors.primary, icon: 'check' },
  { id: 3, nome: 'CAIXA', color: Colors.info, icon: 'money' },
  {
    id: 4,
    nome: 'AGUARDANDO ATENDIMENTO',
    color: Colors.purple,
    icon: 'clock',
  },
  { id: 5, nome: 'ATENDIDO', color: Colors.success, icon: 'thumbs' },
  {
    id: 6,
    nome: 'PAGAMENTO ANTECIPADO',
    color: Colors.success,
    icon: 'calendar',
  },
];

function completaZero(
  numero: number,
  caracter: string,
  position: number,
): string {
  return `${caracter}${numero}`.slice(-position);
}

const optionsDuracao = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
  { value: '30', label: '30' },
  { value: '35', label: '35' },
  { value: '40', label: '40' },
  { value: '45', label: '45' },
  { value: '50', label: '50' },
  { value: '55', label: '55' },
  { value: '60', label: '60' },
  { value: '65', label: '65' },
  { value: '70', label: '70' },
  { value: '75', label: '75' },
  { value: '80', label: '80' },
  { value: '85', label: '85' },
  { value: '90', label: '90' },
  { value: '95', label: '95' },
  { value: '100', label: '100' },
  { value: '105', label: '105' },
  { value: '110', label: '110' },
  { value: '115', label: '115' },
  { value: '120', label: '120' },
];

function optionsHorasCadastroAgenda(): IOptions[] {
  const horas = [];
  // eslint-disable-next-line no-plusplus
  for (let h = 5; h < 23; h++) {
    const hora = `${`00${h}`.substr(-2)}:00`;
    horas.push({ label: hora, value: hora });
    const horaMeia = `${`00${h}`.substr(-2)}:30`;
    horas.push({ label: horaMeia, value: horaMeia });
  }
  return horas;
}

const ORIGEM_PAGAMENTO = {
  RECEITA: 1,
  DESPESA: 2,
  TROCO: 5,
  SANGRIA: 6,
};

const STATUS_AGENDA = {
  ESTORNO: -2,
  FALTA: -1,
  CANCELADO: 0,
  AGENDADO: 1,
  ENCAMINHADO_CAIXA: 3,
  AGUARDANDO_ATENDIMENTO: 4,
  ATENDIDO: 5,
  PAGAMENTO_ANTECIPADO: 6,
};

const CONFIRMACAO_AGENDA = {
  AGUARDANDO_CONFIRMACAO: 0,
  CONFIRMADO: 1,
  CONFIRMADO_ONLINE: 2,
  NAO_VAI: 3,
};

const TIPO_USUARIO = {
  ADMINISTRADOR: 1,
  SUPERVISOR: 2,
  ATENDENTE_CAIXA: 3,
  ATENDENTE: 4,
  MEDICO: 5,
};

const TIPO_DOCUMENTO = {
  RECEITA: 1,
  RECIBO: 2,
  ATENDIMENTO: 3,
  ATESTADO: 4,
  CONFIRMACAO_WHATSAPP: 100,
  CONFIRMACAO_EMAIL: 110,
  COBRANCA_WHATSAPP: 200,
  COBRANCA_EMAIL: 210,
  ANIVERSARIO_WHATSAPP: 300,
  ANIVERSARIO_EMAIL: 310,
};

const TIPO_DOCUMENTO_APENAS = [1, 2, 3, 4];

const TIPO_PERFIL = {
  EXAME: 1,
  CONSULTA: 2,
};

const TIPO_MOTIVO = {
  DESCONTO: 1,
  CANCELAMENTO_BAIXA: 2,
  EXCLUIR_PAGAMENTO: 3,
  ESTORNO: 4,
};

const ORIGEM_CONTRATO = {
  1: 'NOVO CARTÃO',
  2: 'CANCELAMENTO CARTÃO',
  3: 'RENOVAÇÃO CARTÃO',
  4: 'CANCELAMENTO CARTÃO SEM FIDELIDADE',
  6: 'MÉDICO',
  10: 'NOVA LINHA',
  11: 'CANCELAMENTO LINHA',
};

const TIPO_CONTRATO = {
  NOVO_CARTAO: 1,
  CANCELA_CARTAO: 2,
  RENOVA_CARTAO: 3,
  CANCELA_CARTAO_SEM_FIDELIDADE: 4,
  MEDICO: 6,
  ODONTO: 7,
  NOVO_CHIP: 10,
  CANCELA_CHIP: 11,
};

const ORIGEM_AGENDAMENTO = {
  SISTEMA: 1,
  ONLINE: 2,
};

const optionsPacienteModelo = [
  {
    text: '[NOME_PACIENTE]',
    value: '&nbsp;[NOME_PACIENTE]&nbsp;',
    label: 'Nome do paciente',
  },
  {
    text: '[CPF_PACIENTE]',
    value: '&nbsp;[CPF_PACIENTE]&nbsp;',
    label: 'CPF Paciente',
  },
  {
    text: '[RG_PACIENTE]',
    value: '&nbsp;[RG_PACIENTE]&nbsp;',
    label: 'RG Paciente',
  },
  {
    text: '[TELEFONE1_PACIENTE]',
    value: '&nbsp;[TELEFONE1_PACIENTE]&nbsp;',
    label: 'Celular do responsável',
  },
  {
    text: '[TELEFONE2_PACIENTE]',
    value: '&nbsp;[TELEFONE2_PACIENTE]&nbsp;',
    label: 'Telefone do responsável',
  },
  {
    text: '[EMAIL_PACIENTE]',
    value: '&nbsp;[EMAIL_PACIENTE]&nbsp;',
    label: 'E-mail do Paciente',
  },
  {
    text: '[DATA_NASCIMENTO_PACIENTE]',
    value: '&nbsp;[DATA_NASCIMENTO_PACIENTE]&nbsp;',
    label: 'Data de Nascimento',
  },
  {
    text: '[IDADE_PACIENTE]',
    value: '&nbsp;[IDADE_PACIENTE]&nbsp;',
    label: 'Idade do paciente',
  },
  {
    text: '[SEXO_PACIENTE]',
    value: '&nbsp;[SEXO_PACIENTE]&nbsp;',
    label: 'Sexo do paciente',
  },
  {
    text: '[ENDERECO_COMPLETO_PACIENTE]',
    value: '&nbsp;[ENDERECO_COMPLETO_PACIENTE]&nbsp;',
    label: 'Endereço completo do Paciente',
  },
  {
    text: '[PROFISSAO_PACIENTE]',
    value: '&nbsp;[PROFISSAO_PACIENTE]&nbsp;',
    label: 'Profissão do Paciente',
  },
  {
    text: '[ESTADO_CIVIL_PACIENTE]',
    value: '&nbsp;[ESTADO_CIVIL_PACIENTE]&nbsp;',
    label: 'Estado Civil do Paciente',
  },
  {
    text: '[RESPONSAVEL_PACIENTE]',
    value: '&nbsp;[RESPONSAVEL_PACIENTE]&nbsp;',
    label: 'Nome do responsável',
  },
  {
    text: '[RESPONSAVEL_CPF_PACIENTE]',
    value: '&nbsp;[RESPONSAVEL_CPF_PACIENTE]&nbsp;',
    label: 'CPF do responsável',
  },
];

const optionsProfissionalModelo = [
  {
    text: '[NOME_PROFISSIONAL]',
    value: '&nbsp;[NOME_PROFISSIONAL]&nbsp;',
    label: 'Nome do Profissional',
  },
  {
    text: '[ESPECIALIDADE_PROFISSIONAL]',
    value: '&nbsp;[ESPECIALIDADE_PROFISSIONAL]&nbsp;',
    label: 'Especialidade do Profissional',
  },
  {
    text: '[CRM_PROFISSIONAL]',
    value: '&nbsp;[CRM_PROFISSIONAL]&nbsp;',
    label: 'CRM do Profissional',
  },
  {
    text: '[DATA_ATUAL]',
    value: '&nbsp;[DATA_ATUAL]&nbsp;',
    label: 'Data Atual',
  },
];

const optionsMedicoModelo = [
  {
    text: '[NOME_MEDICO]',
    value: '&nbsp;[NOME_MEDICO]&nbsp;',
    label: 'Nome do médico',
  },
  {
    text: '[CPF_MEDICO]',
    value: '&nbsp;[CPF_MEDICO]&nbsp;',
    label: 'CPF médico',
  },
  {
    text: '[ESPECIALIDADE_MEDICO]',
    value: '&nbsp;[ESPECIALIDADE_MEDICO]&nbsp;',
    label: 'Especialidade',
  },
  {
    text: '[CRM_MEDICO]',
    value: '&nbsp;[CRM_MEDICO]&nbsp;',
    label: 'CRM',
  },
  {
    text: '[REMUNERACAO_MEDICO]',
    value: '&nbsp;[REMUNERACAO_MEDICO]&nbsp;',
    label: 'REMUNERAÇÃO',
  },
  {
    text: '[ENDERECO_COMPLETO_MEDICO]',
    value: '&nbsp;[ENDERECO_COMPLETO_MEDICO]&nbsp;',
    label: 'Endereço completo do Médico',
  },
  {
    text: '[DATA_CONTRATO_MEDICO]',
    value: '&nbsp;[DATA_CONTRATO_MEDICO]&nbsp;',
    label: 'DATA INÍCIO CONTRATO',
  },
  {
    text: '[VALIDADE_CONTRATO_MEDICO]',
    value: '&nbsp;[VALIDADE_CONTRATO_MEDICO]&nbsp;',
    label: 'VALIDADE DO CONTRATO',
  },
];

const optionsDadosCartaoModelo = [
  {
    text: '[NUMERO_CARTAO]',
    value: '&nbsp;[NUMERO_CARTAO]&nbsp;',
    label: 'Número do Cartão Saúde',
  },
  {
    text: '[VALOR_PARCELA]',
    value: '&nbsp;[VALOR_PARCELA]&nbsp;',
    label: 'Valor da parcela do Cartão',
  },
  {
    text: '[VALOR_PARCELA_ESCRITO]',
    value: '&nbsp;[VALOR_PARCELA_ESCRITO]&nbsp;',
    label: 'Valor da parcela por extenso',
  },
  {
    text: '[VALOR_TOTAL]',
    value: '&nbsp;[VALOR_TOTAL]&nbsp;',
    label: 'Valor a vista do Cartão',
  },
  {
    text: '[VALOR_TOTAL_ESCRITO]',
    value: '&nbsp;[VALOR_TOTAL_ESCRITO]&nbsp;',
    label: 'Valor a vista por extenso',
  },
  {
    text: '[DATA_PARCELA1]',
    value: '&nbsp;[DATA_PARCELA1]&nbsp;',
    label: 'Data da 1º parcela',
  },
  {
    text: '[DATA_ADESAO]',
    value: '&nbsp;[DATA_ADESAO]&nbsp;',
    label: 'Data da Adesão/Contrato',
  },
  {
    text: '[VALOR_MULTA]',
    value: '&nbsp;[VALOR_MULTA]&nbsp;',
    label: 'Valor da multa contratual',
  },
];

const optionsClinicaModelo = [
  {
    text: '[NOME_CLINICA]',
    value: '&nbsp;[NOME_CLINICA]&nbsp;',
    label: ' Nome da Clínica',
  },
  {
    text: '[REPRESENTANTE_CLINICA]',
    value: '&nbsp;[REPRESENTANTE_CLINICA]&nbsp;',
    label: ' Representante da Clínica',
  },
  {
    text: '[CNPJ_CLINICA]',
    value: '&nbsp;[CNPJ_CLINICA]&nbsp;',
    label: ' CNPJ da Clínica',
  },
  {
    text: '[TELEFONE_CLINICA]',
    value: '&nbsp;[TELEFONE_CLINICA]&nbsp;',
    label: ' Telefone da Clínica',
  },
  {
    text: '[TELEFONE2_CLINICA]',
    value: '&nbsp;[TELEFONE2_CLINICA]&nbsp;',
    label: ' Telefone 2 da Clínica',
  },
  {
    text: '[ENDERECO_COMPLETO_CLINICA]',
    value: '&nbsp;[ENDERECO_COMPLETO_CLINICA]&nbsp;',
    label: ' Endereço completo da Clínica',
  },
  {
    text: '[CIDADE_CLINICA]',
    value: '&nbsp;[CIDADE_CLINICA]&nbsp;',
    label: ' Cidade da Clínica',
  },
  {
    text: '[ESTADO_CLINICA]',
    value: '&nbsp;[ESTADO_CLINICA]&nbsp;',
    label: ' Estado/UF da Clínica',
  },
  {
    text: '[EMAIL_CLINICA]',
    value: '&nbsp;[EMAIL_CLINICA]&nbsp;',
    label: ' E-mail da Clínica',
  },
  {
    text: '[DATA_ATUAL]',
    value: '&nbsp;[DATA_ATUAL]&nbsp;',
    label: ' Formato 01 de Janeiro de 1990',
  },
];

const optionsMensagemConfirmacaModelo = [
  {
    text: '[NOME_PACIENTE]',
    value: '&nbsp;[NOME_PACIENTE]&nbsp;',
    label: ' Nome do Paciente',
  },
  {
    text: '[DATA_AGENDAMENTO]',
    value: '&nbsp;[DATA_AGENDAMENTO]&nbsp;',
    label: ' Data do Agendamento',
  },
  {
    text: '[HORA_AGENDAMENTO]',
    value: '&nbsp;[HORA_AGENDAMENTO]&nbsp;',
    label: ' Hora do Agendamento',
  },
  {
    text: '[NOME_AGENDA]',
    value: '&nbsp;[NOME_AGENDA]&nbsp;',
    label: ' Agenda',
  },
  {
    text: '[ESPECIALIDADE_AGENDADA]',
    value: '&nbsp;[ESPECIALIDADE_AGENDADA]&nbsp;',
    label: ' Especialidade agendada',
  },
  {
    text: '[NOME_CLINICA]',
    value: '&nbsp;[NOME_CLINICA]&nbsp;',
    label: ' Nome da Clínica',
  },
  {
    text: '[TELEFONE_CLINICA]',
    value: '&nbsp;[TELEFONE_CLINICA]&nbsp;',
    label: ' Telefone da Clínica',
  },
  {
    text: '[TELEFONE2_CLINICA]',
    value: '&nbsp;[TELEFONE2_CLINICA]&nbsp;',
    label: ' Telefone 2 da Clínica',
  },
  {
    text: '[ENDERECO_COMPLETO_CLINICA]',
    value: '&nbsp;[ENDERECO_COMPLETO_CLINICA]&nbsp;',
    label: ' Endereço completo da Clínica',
  },
  {
    text: '[CIDADE_CLINICA]',
    value: '&nbsp;[CIDADE_CLINICA]&nbsp;',
    label: ' Cidade da Clínica',
  },
  {
    text: '[ESTADO_CLINICA]',
    value: '&nbsp;[ESTADO_CLINICA]&nbsp;',
    label: ' Estado/UF da Clínica',
  },
  {
    text: '[EMAIL_CLINICA]',
    value: '&nbsp;[EMAIL_CLINICA]&nbsp;',
    label: ' E-mail da Clínica',
  },
];

const optionsMensagemAniversarioModelo = [
  {
    text: '[NOME_PACIENTE]',
    value: '&nbsp;[NOME_PACIENTE]&nbsp;',
    label: 'Nome do paciente',
  },
  {
    text: '[DATA_NASCIMENTO_PACIENTE]',
    value: '&nbsp;[DATA_NASCIMENTO_PACIENTE]&nbsp;',
    label: 'Data de Nascimento',
  },
  {
    text: '[IDADE_PACIENTE]',
    value: '&nbsp;[IDADE_PACIENTE]&nbsp;',
    label: 'Idade do paciente',
  },
];

const optionsMensagemCobrancaModelo = [
  {
    text: '[RESPONSAVEL_CARTAO]',
    value: '&nbsp;[RESPONSAVEL_CARTAO]&nbsp;',
    label: ' Nome do Titular ou Responsável(caso tenha)',
  },
  {
    text: '[NUMERO_CARTAO]',
    value: '&nbsp;[NUMERO_CARTAO]&nbsp;',
    label: ' Nº do Cartão',
  },
  {
    text: '[PARCELAS_ATRASO]',
    value: '&nbsp;[PARCELAS_ATRASO]&nbsp;',
    label: ' Data das Parcelas Atrasadas',
  },
  {
    text: '[TOTAL_PARCELAS]',
    value: '&nbsp;[TOTAL_PARCELAS]&nbsp;',
    label: ' Valor Total Parcelas Atrasadas',
  },
  {
    text: '[NOME_CLINICA]',
    value: '&nbsp;[NOME_CLINICA]&nbsp;',
    label: ' Nome da Clínica',
  },
  {
    text: '[TELEFONE_CLINICA]',
    value: '&nbsp;[TELEFONE_CLINICA]&nbsp;',
    label: ' Telefone da Clínica',
  },
  {
    text: '[TELEFONE2_CLINICA]',
    value: '&nbsp;[TELEFONE2_CLINICA]&nbsp;',
    label: ' Telefone 2 da Clínica',
  },
  {
    text: '[ENDERECO_COMPLETO_CLINICA]',
    value: '&nbsp;[ENDERECO_COMPLETO_CLINICA]&nbsp;',
    label: ' Endereço completo da Clínica',
  },
  {
    text: '[CIDADE_CLINICA]',
    value: '&nbsp;[CIDADE_CLINICA]&nbsp;',
    label: ' Cidade da Clínica',
  },
  {
    text: '[ESTADO_CLINICA]',
    value: '&nbsp;[ESTADO_CLINICA]&nbsp;',
    label: ' Estado/UF da Clínica',
  },
  {
    text: '[EMAIL_CLINICA]',
    value: '&nbsp;[EMAIL_CLINICA]&nbsp;',
    label: ' E-mail da Clínica',
  },
];

const optionsChipModelo = [
  {
    text: '[NUMERO_CARTAO]',
    value: '&nbsp;[NUMERO_CARTAO]&nbsp;',
    label: 'Número do Cartão Saúde',
  },
  {
    text: '[NUMERO_LINHAS]',
    value: '&nbsp;[NUMERO_LINHAS]&nbsp;',
    label: 'Número das linhas',
  },
  {
    text: '[TOTAL_LINHAS]',
    value: '&nbsp;[TOTAL_LINHAS]&nbsp;',
    label: 'Total de linhas contratadas',
  },
  {
    text: '[VALOR_CONTRATO]',
    value: '&nbsp;[VALOR_CONTRATO]&nbsp;',
    label: 'Valor total das linhas',
  },
  {
    text: '[VALOR_CONTRATO_ESCRITO]',
    value: '&nbsp;[VALOR_CONTRATO_ESCRITO]&nbsp;',
    label: 'Valor total por extenso',
  },
  {
    text: '[DATA_ADESAO]',
    value: '&nbsp;[DATA_ADESAO]&nbsp;',
    label: 'Data da Adesão/Contrato',
  },
];

const optionsChipCancelaModelo = [
  {
    text: '[NUMERO_CARTAO]',
    value: '&nbsp;[NUMERO_CARTAO]&nbsp;',
    label: 'Número do Cartão Saúde',
  },
  {
    text: '[NUMERO_LINHAS]',
    value: '&nbsp;[NUMERO_LINHAS]&nbsp;',
    label: 'Número das linhas',
  },
  {
    text: '[DATA_ADESAO]',
    value: '&nbsp;[DATA_ADESAO]&nbsp;',
    label: 'Data da Adesão/Contrato',
  },
];

const isRepeatingNumber = (str: string): boolean => /^(\d)(\1){10}$/.test(str);

const validarCPF = (input: string): boolean => {
  const cpf = input.replace(/\D/g, '');

  if (
    cpf === '' ||
    cpf.length !== 11 ||
    !/^\d{11}$/.test(cpf) ||
    isRepeatingNumber(cpf)
  ) {
    return false;
  }

  // eslint-disable-next-line radix
  const digits = cpf.split('').map(x => parseInt(x));

  for (let j = 0; j < 2; j++) {
    let sum = 0;

    for (let i = 0; i < 9 + j; i++) {
      sum += digits[i] * (10 + j - i);
    }

    let checkDigit = 11 - (sum % 11);

    if (checkDigit === 10 || checkDigit === 11) {
      checkDigit = 0;
    }

    if (checkDigit !== digits[9 + j]) {
      return false;
    }
  }

  return true;
};

function validarCNPJ(input: string): boolean {
  const cnpj = input.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

function htmlEncode(str: string): string {
  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
}

function htmlDecode(text: string): string {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

function replaceHTMLparaWhatsapp(texto: string): string {
  return texto
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '\n')
    .replace(/<br \/>/g, '\n')
    .replace(/<br>/g, '\n')
    .replace(/<strong>/g, '*')
    .replace(/<\/strong>/g, '*')
    .replace(/<b>/g, '*')
    .replace(/<\/b>/g, '*')
    .replace(/<i>/g, '_')
    .replace(/<\/i>/g, '_')
    .replace(/<div>/g, '')
    .replace(/<\/div>/g, '');
}

const arrayGroupBy = <T, K>(list: T[], getKey: (item: T) => K): T[][] => {
  const map = new Map<K, T[]>();
  list.forEach(item => {
    const key = getKey(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map.values());
};

const reducer = (accumulator: number, currentValue: number): number =>
  accumulator + currentValue;

export {
  formatPrice,
  formatNumber2CasasDecimais,
  replaceCaracteres,
  somenteLetrasENumeros,
  replaceCurrency,
  formataTelefone,
  formataCPF,
  formataCNPJ,
  formataCep,
  formataIdade,
  optionsHorasAtendimento,
  optionsStatusAgendamento,
  optionsDuracao,
  statusAgendamento,
  ORIGEM_PAGAMENTO,
  STATUS_AGENDA,
  CONFIRMACAO_AGENDA,
  optionsConfirmado,
  TIPO_USUARIO,
  TIPO_DOCUMENTO,
  TIPO_DOCUMENTO_APENAS,
  TIPO_PERFIL,
  TIPO_CONTRATO,
  TIPO_MOTIVO,
  ORIGEM_CONTRATO,
  ORIGEM_AGENDAMENTO,
  optionsTipoMensagem,
  optionsPacienteModelo,
  optionsMedicoModelo,
  optionsDadosCartaoModelo,
  optionsClinicaModelo,
  optionsProfissionalModelo,
  optionsMensagemConfirmacaModelo,
  optionsMensagemAniversarioModelo,
  optionsMensagemCobrancaModelo,
  optionsChipModelo,
  optionsChipCancelaModelo,
  optionsHorasCadastroAgenda,
  validarCPF,
  validarCNPJ,
  completaZero,
  htmlEncode,
  htmlDecode,
  replaceHTMLparaWhatsapp,
  arrayGroupBy,
  reducer,
};
