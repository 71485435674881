import React from 'react';

import { Container } from 'react-bootstrap';

const SemPermissao: React.FC = () => {
  return (
    <Container fluid className="mt-2">
      <div className="bg-white border p-2 mb-2" style={{ minHeight: '400px' }}>
        <h2>Permissão Negada</h2>
        <p className="my-4">
          Você não tem permissão para acessar essa página, consulte o
          administador para mais informações.
        </p>
      </div>
    </Container>
  );
};

export default SemPermissao;
