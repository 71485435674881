/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Chart } from 'react-google-charts';

import { format, parseISO } from 'date-fns';

import { Container, Button, Row, Col } from 'react-bootstrap';

import api from '../../../services/api';

import {
  formatPrice,
  formatNumber2CasasDecimais,
  TIPO_USUARIO,
} from '../../../utils/funcoes';

import { useToast } from '../../../hooks/toast';
import { useClinica } from '../../../hooks/clinica';
import { useAuth } from '../../../hooks/auth';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';

import { RelatorioHeader } from './styles';
import getResponseErrors from '../../../utils/getResponseErrors';

interface IDespesa {
  descricao: string;
  valor: number;
  valorFormatado: string;
  percentualDespesa: number;
  percentualReceita: number;
  percentualDespesaFormatado: string;
  percentualReceitaFormatado: string;
}

interface IRelatorio {
  receitas: number;
  despesas: IDespesa[];
}

interface ITotais {
  totalDespesasFormatado: string;
  totalReceitasFormatado: string;
  totalPercentualDespesaReceita: string;
}

interface IFormBusca {
  dataInicio: Date;
  dataFim: Date;
  CliCodigo: string;
}

interface IDataExibe {
  dataHora: string;
  dataDiaSemana: string;
}

interface IGenericType {
  [key: string]: any;
}

const DespesasResumo: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { optionsClinica, imagensClinica } = useClinica();

  const logo =
    imagensClinica.find(img => img.nome === 'logoRelatorio')?.arquivo_url || '';

  const formRefBusca = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorio>();
  const [graficoDespesas, setGraficoDespesas] = useState<IGenericType[]>();
  const [totais, setTotais] = useState<ITotais>();
  const initialData = {
    dataInicio: format(new Date(), 'yyyy-MM-dd'),
    dataFim: format(new Date(), 'yyyy-MM-dd'),
    CliCodigo: user.clinica_id.toString(),
  };
  const [dataExibe, setDataExibe] = useState<IDataExibe>();
  const [clinica, setClinica] = useState('');

  useEffect(() => {
    if (user.tipo > TIPO_USUARIO.SUPERVISOR) {
      history.push('/sempermissao');
    }
  }, [history, user.tipo]);

  const handleSubmitBusca = useCallback(
    async (dados: IFormBusca) => {
      setLoading(true);
      try {
        formRefBusca.current?.setErrors({});
        const schema = Yup.object().shape({
          dataInicio: Yup.date().required(),
          dataFim: Yup.date().required(),
          CliCodigo: Yup.string().required(),
        });

        await schema.validate(dados, {
          abortEarly: false,
        });

        setClinica(
          optionsClinica.find(cli => cli.value === dados.CliCodigo)?.label ||
            '',
        );

        const dataInicioSelecionada = parseISO(dados.dataInicio.toString());
        const dataFimSelecionada = parseISO(dados.dataFim.toString());

        const dataInicioFormatada = format(dataInicioSelecionada, 'yyyy-MM-dd');
        const dataFimFormatada = format(dataFimSelecionada, 'yyyy-MM-dd');

        setDataExibe({
          dataDiaSemana: `${format(
            dataInicioSelecionada,
            'dd/MM/yyyy',
          )} até ${format(dataFimSelecionada, 'dd/MM/yyyy')}`,
          dataHora: format(new Date(), 'dd/MM/yyyy HH:mm'),
        });

        const response = await api.get<IRelatorio>(
          `/relatorios/despesasresumo?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}&CliCodigo=${dados.CliCodigo}`,
        );

        const totalDespesas = response.data.despesas.reduce((tot, item) => {
          return tot + Number(item.valor);
        }, 0);

        const totalReceitas = response.data.receitas;

        const despesas = response.data.despesas.map(rel => {
          const percentualDespesa = (rel.valor / totalDespesas) * 100;
          const percentualReceita = (rel.valor / totalReceitas) * 100;

          return {
            ...rel,
            valorFormatado: formatPrice(rel.valor),
            percentualDespesa,
            percentualReceita,
            percentualDespesaFormatado: `${formatNumber2CasasDecimais(
              percentualDespesa.toString(),
            )}%`,
            percentualReceitaFormatado: `${formatNumber2CasasDecimais(
              percentualReceita.toString(),
            )}%`,
          };
        });

        const graficoLegenda: IGenericType[] = [['Despesa', 'Total']];

        const despesasGrafico: IGenericType[] = response.data.despesas.map(
          rel => {
            return [rel.descricao, rel.valor];
          },
        );

        const percentualGeral = (totalDespesas / totalReceitas) * 100;

        setDadosRelatorio({ despesas, receitas: totalReceitas });
        setTotais({
          totalReceitasFormatado: formatPrice(totalReceitas),
          totalDespesasFormatado: formatPrice(totalDespesas),
          totalPercentualDespesaReceita: `${formatNumber2CasasDecimais(
            percentualGeral.toString(),
          )}%`,
        });

        setGraficoDespesas(graficoLegenda.concat(despesasGrafico));
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefBusca.current?.setErrors(errors);
        } else {
          const mensagem = getResponseErrors(error);
          addToast({
            type: 'error',
            title: 'Não foi possível carregar dados',
            description: mensagem,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, optionsClinica],
  );

  return (
    <Container fluid className="mt-2">
      {loading && <Loading />}
      <>
        <div className="d-print-none bg-white border p-2 mb-2">
          <div className="p-2 mb-2">
            <h2 id="topoBusca">Demonstrativo de Despesas</h2>
          </div>

          <Form
            ref={formRefBusca}
            initialData={initialData}
            onSubmit={handleSubmitBusca}
          >
            <Row>
              <Col md={2}>
                <Input name="dataInicio" label="De" type="date" required />
              </Col>
              <Col md={2}>
                <Input name="dataFim" label="Até" type="date" required />
              </Col>
              <Col md={3}>
                <Select
                  name="CliCodigo"
                  label="Clinica"
                  options={optionsClinica}
                  disabled={user.tipo !== TIPO_USUARIO.ADMINISTRADOR}
                />
              </Col>
              <Col md={2}>
                <br />
                <Button type="submit" size="sm" variant="outline-secondary">
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="bg-white border p-2 my-3">
          <img src={logo} alt="Agenda Facil" /> Impresso em{' '}
          {dataExibe?.dataHora}
        </div>

        <RelatorioHeader>
          DESPESAS {`- ${clinica} -`} {dataExibe?.dataDiaSemana}
        </RelatorioHeader>
        <div className="bg-white border p-2" style={{ minHeight: '300px' }}>
          {dadosRelatorio && (
            <>
              <table
                className="table table-hover table-sm table-font-small table-bordered"
                style={{ borderWidth: '0px' }}
              >
                <thead>
                  <tr className="text-center">
                    <th style={{ width: '100%' }}>Categoria</th>
                    <th style={{ minWidth: '150px' }}>Total</th>
                    <th style={{ minWidth: '150px' }}>% Despesa</th>
                    <th style={{ minWidth: '150px' }}>% Receita</th>
                  </tr>
                </thead>
                <tbody>
                  {dadosRelatorio &&
                    dadosRelatorio.despesas.map(rel => (
                      <tr
                        key={`despesas-${rel.descricao}`}
                        style={{ fontSize: '.7rem', height: '30px' }}
                      >
                        <td>{rel.descricao}</td>
                        <td>{rel.valorFormatado}</td>
                        <td>{rel.percentualDespesaFormatado}</td>
                        <td>{rel.percentualReceitaFormatado}</td>
                      </tr>
                    ))}

                  <tr
                    key="fdfdsfsdfdf"
                    style={{
                      fontSize: 'Small',
                      height: '30px',
                      fontWeight: 'bold',
                    }}
                  >
                    <td> </td>
                    <td style={{ textAlign: 'right' }}>
                      Total Despesas: {totais?.totalDespesasFormatado}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      Total Receitas: {totais?.totalReceitasFormatado}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      Percentual Despesa/Receita:{' '}
                      {totais?.totalPercentualDespesaReceita}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {graficoDespesas && (
            <Chart
              chartType="PieChart"
              data={graficoDespesas}
              options={{
                title: `% Despesas`,
              }}
              width="100%"
              height="400px"
              legendToggle
            />
          )}
        </div>
      </>
    </Container>
  );
};

export default DespesasResumo;
