import React, { useState, useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns';

// import { Container } from './styles';
import { Badge } from 'react-bootstrap';
import { FaClock } from 'react-icons/fa';
import { completaZero } from '../../utils/funcoes';

type Props = {
  id: number;
  textId: string;
  texto?: string;
  dataInicial: string;
  limite: number;
};

const Timer: React.FC<Props> = ({
  id,
  textId,
  texto = '',
  dataInicial,
  limite,
}) => {
  const [tempo, setTempo] = useState('');
  const [estilo, setEstilo] = useState('bg-success');

  useEffect(() => {
    const countDownDate = new Date(dataInicial).getTime();
    const agora = new Date();

    const x = setInterval(() => {
      if (limite > 0) {
        const horas = agora.getTime();
        agora.setTime(horas + 1000);
        const distance = differenceInMilliseconds(
          agora,
          countDownDate > 0 ? countDownDate : 1,
        );
        const hour = Math.floor(
          (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = `0${Math.floor((distance % (1000 * 60)) / 1000)}`.slice(
          -2,
        );
        try {
          let horario = `${completaZero(minutes, '0', 2)}:${seconds}`;
          if (hour > 0) horario = `${completaZero(hour, '0', 2)}:${horario}`;

          setTempo(horario);
          if (distance >= 600000 && minutes < 900000) setEstilo('bg-warning');
          if (distance >= 900000) setEstilo('bg-danger');
        } catch (error) {
          return '';
        }
      }
      return '';
    }, 1000);
    return () => clearInterval(x);
  }, [dataInicial, limite]);

  return (
    <>
      <div
        id="duracao"
        className={`alert alert-secondary border text-center text-light ${estilo}`}
        style={{ fontSize: '26px' }}
      >
        <span id="tempo">
          <FaClock size="12" />
        </span>
        <span id="numberCountdown">{tempo}</span>
      </div>
    </>
  );
};

export default Timer;
