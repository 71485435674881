import styled from 'styled-components';

interface CartaoProps {
  emDia: boolean;
}

export const Container = styled.div`
  font-size: 13px;
`;

export const PacienteContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  min-height: 55px;
  line-height: 19px;

  img {
    float: left;
    border-radius: 4px;
    margin-right: 8px;
    width: 58px;
  }

  div {
    span {
      padding-right: 4px;
    }
  }
`;

export const CartaoSaude = styled.div<CartaoProps>`
  display: flex;
  align-items: center;
  height: 37px;

  > a {
    color: ${props => (props.emDia ? '#007bff' : '#dc3545')};
  }

  svg {
    margin-right: 3px;
  }
`;
