import { createGlobalStyle } from 'styled-components';
import { Colors } from './constants';

export default createGlobalStyle`
* {
  margin:0;
  padding:0;
  outline:0;
  box-sizing: border-box;
}

/*------------------
Navigation
-----------------*/
:root{--header-height: 2.5rem;--nav-width: 68px;--first-color: #fff;--first-color-light: #131523;--white-color: #49aad5;--body-font: 'Nunito', sans-serif;--normal-font-size: 0.9rem;--z-fixed: 100}
  *,::before,::after{box-sizing: border-box}
   .body-container{position: relative;margin: var(--header-height) 0 0 0;padding: 0;font-family: var(--body-font);font-size: var(--normal-font-size);transition: .5s}
  a{text-decoration: none}
  .header{width: 100%;height: var(--header-height);position: fixed;top: 0;left: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 1rem;background-color: #fff;z-index: var(--z-fixed);transition: .5s; border-bottom: 1px solid #F5F6FA}
  .header_toggle{color: var(--first-color-light);font-size: 1.5rem;cursor: pointer; padding-left: calc(var(--nav-width) + 10px);}
  .header_img{width: 35px;height: 35px;display: flex;justify-content: center;border-radius: 50%;overflow: hidden}
  .header_img img{width: 40px}
  .l-navbar{position: fixed;top: 0;left: -30%;width: var(--nav-width);height: 100vh;background-color: var(--first-color);padding: .5rem 1rem 0 0;transition: .5s;z-index: var(--z-fixed); border-right: 1px solid #F5F6FA}

  .nav{height: 100%;display: flex;flex-direction: column;justify-content: space-between;overflow: hidden; overflow-y: auto; overflow-x: hidden;}
  .nav_logo, .nav_link{display: grid;grid-template-columns: max-content max-content;align-items: center;column-gap: 1rem;padding: .5rem 0 .5rem 1.5rem}
  .nav_logo{margin-bottom: 2rem}
  .nav_logo-icon{font-size: 1.25rem;color: var(--white-color)}
  .nav_logo-name{color: var(--white-color);font-weight: 700}
  .nav_logo-name img{width: 100px}
  .nav_link{position: relative;color: var(--first-color-light);margin-bottom: 1.5rem;transition: .3s}
  .nav_link:hover{color: var(--white-color)}
  .nav_icon{font-size: 1.25rem}
  .show-nav{left: 0}
  .body-pd{padding-left: calc(var(--nav-width) + 0.2rem)}
  .active{color: var(--white-color)}
  .nav .active::before{content: '';position: absolute;left: 0;width: 2px;height: 32px;background-color: var(--white-color)}
  .height-100{height:100vh}
  @media screen and (min-width: 768px)
  {
    .body-container{margin: calc(var(--header-height) + 1rem) 0 0 0;padding-left: calc(var(--nav-width) + 0.2rem)}
    .header{height: calc(var(--header-height) + 1rem);padding: 0 2rem 0 0}
    .header_img{width: 40px;height: 40px}
    .header_img img{width: 45px}
    .l-navbar{left: 0;padding: 7px .75rem 0 0}
    .l-navbar:hover, .show-nav{width: calc(var(--nav-width) + 156px)}
    .body-pd{padding-left: calc(var(--nav-width) + 88px)}
    }

/*--------- NAVIGATION SCROLLBAR ----------*/

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: ${Colors.primary};
}

 /*-------- Menu SubMenu --------*/

.submenu {
  display: none;
}

.submenu > a {
  text-decoration: none;
  margin-bottom: 0!important;
  color: ${Colors.muted};
}
.submenu > a > span {
  padding-left: 23px;
}

.submenu-active {
  display:block!important;
  background-color: #fff;
  // position: absolute;
  z-index: 10;
  padding: 0 8px 4px 8px;
  margin-top: -18px;
}



body {
  background-color:#fff;
  -webkit-font-smoothing: antialiased;
}

@media print {
  body {
  background-color:transparent;
  }

  .p-print-0{
    padding:0;
  }
}

body, input, button {
  font: 14px Roboto, sans-serif;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.button-reset{
  background: transparent;
  border: 0;

}

/*-----------------
	14. Content
-----------------------*/

.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper {
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem;
}
.page-header {
    margin-bottom: 1.875rem;
}
.content-wrap {
	display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    min-height: calc(100vh - 60px);
}
.page-header .breadcrumb {
	background-color: transparent;
	color: #6c757d;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 0;
	padding: 0;
}
.page-header .breadcrumb a {
	color: #333;
}
.page-title {
	color: #333;
	margin-bottom: 5px;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/
.border { border-color: #F5F6FA!important}
.btn.focus,
.btn:focus {
	box-shadow: unset;
}
.btn-white {
    background-color: #fff;
    border-color: #E6E9F4;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.btn{
  border-radius: 0.5rem;
}
.bg-primary,
.badge-primary {
	background-color: #49aad5 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
	background-color: #621aff !important;
}
.bg-success,
.badge-success {
	background-color: #22cc62 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
	background-color: #1eae55 !important;
}
.bg-info,
.badge-info {
	background-color: #009efb !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
	background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #FFBC00 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
	background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #F0142F !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
	background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}

.bg-purple,
.badge-purple,
.btn-purple {
	background-color: #9368e9 !important;
}
.btn-outline-purple {
  border-color: #9368e9 !important;
  color: #9368e9;
}
.btn-outline-purple:hover {
  border-color: #9368e9 !important;
  background-color: #9368e9;
  color: #fff;
}

.btn-excluir:hover {
  border-color: #e63333 !important;
  background-color: #e63333;
  color: #fff;
}

.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #49aad5 !important;
}
.text-secondary,
.dropdown-menu > li > a.text-secondary {
	color: #1B5A90 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #17D053 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #F0142F !important;
}
.text-yellow {
	color: #FFBC00 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
	color: #7E84A3 !important;
}
.btn-primary {
	background-color: #49aad5;
	border: 1px solid #49aad5;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #287fa6;
	border: 1px solid #287fa6;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #287fa6;
	border: 1px solid #287fa6;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #287fa6;
	border-color: #287fa6;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #49aad5;
	border-color: #49aad5;
	color: #fff;
}
.bg-secondary { background-color: #1B5A90!important; }
.btn-secondary {
  background-color: #1B5A90;
	border: 1px solid #1B5A90;
}
.btn-secondary:hover {
	background-color: #08508e;
	border: 1px solid #08508e;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #22cc62;
	border: 1px solid #22cc62
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #1eae55;
	border: 1px solid #1eae55;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #1eae55;
	border: 1px solid #1eae55
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #1eae55;
	border-color: #1eae55;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled,
.btn-success:disabled {
	background-color: #22cc62;
	border-color: #22cc62;
	color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled,
.btn-info:disabled {
	background-color: #009efb;
	border-color: #009efb;
	color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled,
.btn-warning:disabled {
	background-color: #ffbc34;
	border-color: #ffbc34;
	color: #fff;
}
.badge-secondary {
	background-color: #621aff !important;
}
.btn-danger {
	background-color: #ef3737;
	border: 1px solid #ef3737;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #ec1313;
	border: 1px solid #ec1313;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #ec1313;
	border: 1px solid #ec1313;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #ec1313;
	border-color: #ec1313;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #f62d51;
	border-color: #f62d51;
	color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-outline-primary {
	color: #49aad5;
	border-color: #49aad5;
}
.btn-outline-primary:hover {
	background-color: #49aad5;
	border-color: #49aad5;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #49aad5;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #49aad5;
	border-color: #49aad5;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-secondary {
    color: #1B5A90;
    border-color: #1B5A90;
}
.btn-outline-secondary:hover {
    background-color: #1B5A90;
    border-color: #1B5A90;
}
.btn-check:active+.btn-outline-secondary, .btn-check:checked+.btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
	background-color: #1B5A90;
    border-color: #1B5A90;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #1B5A90;
}
.btn-outline-success {
	color: #22cc62;
	border-color: #22cc62;
}
.btn-outline-success:hover {
	background-color: #22cc62;
	border-color: #22cc62;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #22cc62;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #22cc62;
	border-color: #22cc62;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #00bcd4;
    border-color: #00bcd4;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #00bcd4;
	border-color: #00bcd4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
	background-color: transparent;
	color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #00bcd4;
	border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #ef3737;
	border-color: #ef3737;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #ef3737;
	border-color: #ef3737;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	background-color: transparent;
	color: #ef3737;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #ef3737;
	border-color: #ef3737;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
	color: #ababab;
	border-color: #e6e6e6;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #ababab;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #49aad5;
	border-color: #49aad5;
}
.pagination > li > a,
.pagination > li > span {
	color: #131523;
}
.page-link:hover {
	color: #49aad5;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #49aad5;
	border-color: #49aad5;
}
.dropdown-menu {
	border: 1px solid #eff2f7;
	border-radius: .8rem;
	transform-origin: left top 0;
	background-color: #fff;
	box-shadow: 0 0 1.25rem rgba(31,45,61,.08);
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.dropdown-menu {
	font-size: 14px;
}
.card {
    background: rgba(255, 255, 255, 0.8);
	border: 1px solid #EEF1FE;
	border-radius: 5px;
    -webkit-box-shadow: 0px 7px 10px #EEF1FE;
	box-shadow: 0px 7px 10px #EEF1FE;
    margin-bottom: 30px;
}
.card-body {
    position: relative;
    padding: 15px;
}
.card .card-header {
	border-color: #f0f1f5;
	background-color: #fff;
	padding: 15px;
	border-bottom: 1px solid #E6E9F4;
}
.card-header:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.card-footer:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem;
}
.table .thead-light th {
    color: #131523;
    background-color: #F4F6FF;
    border-color: #eff2f7;
	border-bottom: 0;
}
.card .card-header .card-title {
	margin-bottom: 0;
}
.modal-footer.text-left {
	text-align: left;
}
.modal-footer.text-center {
	text-align: center;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #49aad5;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before {
	border-bottom-color: #49aad5;
}
.bg-info-light {
	background-color: rgba(2, 182, 179, 0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247, 0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54, 0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0, 0.12) !important;
	color: #f39c12 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107, 0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255, 0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71, 0.12) !important;
	color: #283447 !important;
}


#root {

}
.table {
font-weight: normal;
}
.table thead{
  font-size: 13px;
}
.table th{
  height: 34px;
  vertical-align: middle!important;
  border-bottom-width: 0!important;
}
.table tbody{
  font-size: 12px;
}

table tr:hover{
  background-color: #e6e6e6;
}

.table td{
  vertical-align: middle!important;
}

.modal-body{
  padding-top: .5rem;
}

.modal-content{
  border-radius: 1rem;
}

.modal-header{
  padding: .5rem 1rem;
  border-bottom:0;
}

.modal-header .close {
  padding: 1.4rem 1rem 1rem 1rem;
}

.close > span{
  color: #e63c3c;
  border: 2px solid #e63c3c;
  margin: 0;
  padding: 0 0.38rem;
  border-radius: 50%;
}

.tabela .tabela-contrato{
    border-collapse:collapse;
}
.tabela .tabela-contrato td{
    border:1px solid #000;
    padding: 2px 8px;
}

table.table td h2 a {
	color: #131523;
}

table.table td h2 span {
	display: block;
	margin-top: 3px;
}

h2.table-avatar {
	align-items: center;
	display: inline-flex;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	white-space: nowrap;
}
h2.table-avatar img {
	border-radius: 6px;
	margin-right: 12px;
}

.avatar {
	position: relative;
	display: inline-block;
	width: 42px;
	height: 42px;
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.user-name {
	color: #131523;
	font-weight: 600;
}
`;
