import styled from 'styled-components';

export const ContainerResumo = styled.div`
  & + div {
    margin-left: 20px;
  }

  & > table {
    min-width: 270px;
    max-width: 450px;
  }
`;

export const RelatorioHeader = styled.div`
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-bottom-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.75rem 1.25rem;
  font-weight: bold;
  text-align: center;
`;

export const ContainerFlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 350px;

  @media (max-width: 800px) {
    flex: 0 0 100%;
  }

  & > div {
    flex: 1 1 350px;

    @media (max-width: 800px) {
      flex: 0 0 100%;
    }
  }
`;
