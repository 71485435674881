/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { InputHTMLAttributes, useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import isString from 'lodash/isString';

import { useField } from '@unform/core';
// import isBoolean from 'lodash/isBoolean';
// import isFunction from 'lodash/isFunction';
import './index.css';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  nome: string;
  label: string;
  theme?: string;
  isDisabled: boolean;
  // onStateChanged(value: boolean): void;
  nameclass?: string;
  classSpam?: { [key: string]: string };
  handleOnChange?(valor: boolean): void;
}

const Toggle: React.FC<ToggleProps> = ({
  nome,
  theme,
  label,
  isDisabled,
  nameclass,
  classSpam = { paddingLeft: '10px' },
  handleOnChange = (valor: boolean) => {
    console.log(valor);
  },
  ...restProps
}) => {
  const checkRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(nome);
  const [enabled, setEnabled] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkRef.current,
      // path: 'value',
      getValue: ref => {
        if (!ref) {
          return false;
        }
        return ref.getAttribute('value');
      },
      // setValue: (ref, value) => {
      //   setEnabled(value);
      // },
    });
  }, [fieldName, registerField]);

  // const toggleSwitch = useCallback(
  //   evt => {

  //     // Augument the event object with SWITCH_STATE
  //     //  const switchEvent = Object.assign(evt, { SWITCH_STATE: enabled });

  //     // Execute the callback functions
  //     // isFunction(onClick) && onClick(switchEvent);
  //     //   isFunction(onStateChanged) && onStateChanged(enabled);
  //   },
  //   [enabled, isDisabled],
  // );

  const toggleSwitch: React.MouseEventHandler<HTMLDivElement> = evt => {
    evt.persist();
    evt.preventDefault();
    if (isDisabled) return;
    setEnabled(!enabled);
    handleOnChange(!enabled);
  };

  // Use default as a fallback theme if valid theme is not passed
  const switchTheme = theme && isString(theme) ? theme : 'default';

  const switchClasses = classnames(`switch switch--${switchTheme}`, nameclass);

  const togglerClasses = classnames(
    'switch-toggle',
    `switch-toggle--${enabled ? 'on' : 'off'}`,
  );

  return (
    <>
      <span style={classSpam}>{label}</span>
      <div
        className={switchClasses}
        value={enabled}
        {...restProps}
        ref={checkRef}
      >
        <div className={togglerClasses} onClick={toggleSwitch} />
      </div>
    </>
  );
};

export default Toggle;
