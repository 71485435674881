import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  variant?:
    | 'success'
    | 'primary'
    | 'secondary'
    | 'success-outline'
    | 'primary-outline'
    | 'secondary-outline'
    | 'danger';
  type: 'button' | 'submit' | 'reset';
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  variant = 'primary',
  type,
  ...rest
}) => (
  <Container disabled={loading} variant={variant} type={type} {...rest}>
    {loading ? 'Carregando...' : children}
  </Container>
);

export default Button;
