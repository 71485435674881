import React from 'react';

import { ICliente } from '../index';

interface componentProps {
  cliente: ICliente;
}

const Linha: React.FC<componentProps> = ({ cliente }) => {
  return (
    <tr>
      <td>
        <a href={`/pacientecadastro/${cliente.id}`} className="text-reset">
          <b>#{cliente.id}</b>
        </a>
      </td>
      <td>
        <h2 className="table-avatar">
          <a href={`/pacientecadastro/${cliente.id}`}>
            <img
              className="avatar avatar-img"
              src={cliente.avatar_url}
              alt="avatar"
            />
          </a>
          <a href={`/pacientecadastro/${cliente.id}`}>
            <span className="user-name">{cliente.nome}</span>
            <span className="text-muted">
              {cliente.pessoaFisica && `${cliente.sexo}, ${cliente.idade}`}
            </span>
          </a>
        </h2>
      </td>

      <td>
        <span className="user-name d-block mb-1">
          {cliente.telefoneFormatado}
        </span>
        {cliente.email}
      </td>

      <td>
        {cliente.pessoaFisica ? (
          <>
            <span className="user-name d-block mb-1">{cliente.cpf}</span>
            {cliente.dataNascFormatada}
          </>
        ) : (
          <>
            <span className="user-name d-block mb-1">{cliente.cnpj}</span>
          </>
        )}
      </td>
    </tr>
  );
};

export default Linha;
