import React from 'react';

import Skeleton from '../../../../components/Skeleton';

interface ComponentProps {
  linhas?: number;
}

const SkeletonCliente: React.FC<ComponentProps> = ({ linhas = 1 }) => {
  return (
    <tr>
      <td>
        <Skeleton height="40px" />
      </td>
      <td>
        <Skeleton height="40px" />
      </td>

      <td>
        <Skeleton height="40px" />
      </td>

      <td>
        <Skeleton height="40px" />
      </td>
      <td>
        <Skeleton height="40px" />
      </td>
      <td>
        <Skeleton height="40px" />
      </td>
    </tr>
  );
};

export default SkeletonCliente;
