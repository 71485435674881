/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useCallback, useRef, useMemo } from 'react';

import { Container, Button } from 'react-bootstrap';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns/esm';
import { useToast } from '../../hooks/toast';
import socket from '../../services/socket';

import { INotafiscal, useNotaFiscal } from '../../hooks/notafiscal';

import Input from '../../components/Input';
import SelectSync, { IOption } from '../../components/SelectSync';
import ListaNotas from './ListaNotas';
import Skeleton from './ListaNotas/Skeleton';

const optionsTipo: IOption[] = [
  { value: 'Todos', label: 'Todos' },
  { value: 'EXAMES', label: 'Exames' },
];

const optionsPagamento: IOption[] = [
  { value: 'Cartão', label: 'Cartão' },
  { value: 'Outros', label: 'Outros' },
];

const Nota: React.FC = () => {
  const formRefBusca = useRef<FormHandles>(null);

  const { loading, notasFiscais, buscaNotas, setNotaFiscal } = useNotaFiscal();

  const { addToast } = useToast();

  const initialValues = {
    tipo: 'Todos',
    data: format(new Date(), 'yyyy-MM-dd'),
    formaPagto: 'Cartão',
  };

  useEffect(() => {
    const handleNewMessage = (data: INotafiscal): void => {
      setNotaFiscal(data);
    };

    socket.on('notaFiscal', handleNewMessage);
    return () => {
      socket.removeEventListener('notaFiscal', handleNewMessage);
    };
  }, [notasFiscais, setNotaFiscal]);

  const handleSubmitBusca = useCallback(
    async (botao: string): Promise<void> => {
      let requisicao = 'rps/selectNotaFiscalPendente';

      if (botao.trim() !== 'pendentes') {
        // const teste: HTMLInputElement = document.getElementById('HidClinica');
        const teste = '1';
        if (!teste) {
          addToast({
            type: 'error',
            title: 'Digite uma data.',
          });

          return;
        }
        let tipoNota = '';
        const tipo = formRefBusca.current?.getFieldValue('tipo');
        const fPagto = formRefBusca.current?.getFieldValue('formaPagto');
        if (tipo !== 'Todos') tipoNota = `&tipo=${tipo}`;
        const formaPagto = `&pagto=${fPagto}`;

        const txtData = formRefBusca.current?.getFieldValue('data');

        requisicao = `rps/selectNotaFiscal/?dataFilter=${txtData}${tipoNota}${formaPagto}`;
      }

      buscaNotas(requisicao);
    },
    [addToast, buscaNotas],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  const totalRegistros = useMemo(() => {
    if (notasFiscais) return notasFiscais.length;
    return 0;
  }, [notasFiscais]);

  return (
    <>
      <Container fluid className="pt-2">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <span id="topoBusca" className="card-title">
                Notas Fiscais
              </span>
            </div>
            <div className="col-auto d-flex justify-content-end align-items-center">
              <span />
              <div className="doc-badge me-3 ml-3">
                Total registros{' '}
                <span className="bg-danger text-white px-2 py-1">
                  {totalRegistros}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <Form
              ref={formRefBusca}
              initialData={initialValues}
              onSubmit={() => handleSubmitBusca('')}
            >
              <div className="row">
                <div className="col-md-3">
                  <Input
                    name="data"
                    label="Data"
                    placeholder="DATA"
                    type="date"
                  />
                </div>
                <div className="col-md-3">
                  <SelectSync
                    name="tipo"
                    label="Tipo"
                    placeholder="Selecione"
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                    options={optionsTipo}
                  />
                </div>
                <div className="col-md-2">
                  <SelectSync
                    name="formaPagto"
                    label="Forma Pagto."
                    placeholder="Selecione"
                    isClearable
                    handleSelectChange={handleOnChangeVazio}
                    options={optionsPagamento}
                  />
                </div>
                <div className="col-md-2">
                  <br />
                  <Button
                    type="submit"
                    variant="primary"
                    size="sm"
                    className="mt-1"
                  >
                    BUSCAR
                  </Button>
                </div>
                <div className="col-md-2">
                  <br />
                  <Button
                    type="button"
                    variant="primary"
                    size="sm"
                    className="mt-1"
                    onClick={() => handleSubmitBusca('pendentes')}
                  >
                    BUSCAR PENDENTES
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="datatable table table-borderless hover-table dataTable no-footer">
                <thead className="thead-light">
                  <tr className="bg-light" style={{ fontSize: 'Small' }}>
                    <th>Nome</th>
                    <th>Serviço</th>
                    <th style={{ width: '80px' }}>Valor</th>
                    <th style={{ width: '60px' }}>Data Pagto</th>
                    <th style={{ width: '80px' }}>Forma Pagto</th>
                    <th style={{ width: '55px' }}>Situação</th>
                    <th style={{ width: '55px' }}>RPS</th>
                    <th style={{ width: '55px' }}>Nota Fiscal</th>
                    <th style={{ width: '55px' }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    notasFiscais &&
                    notasFiscais.map(nota => {
                      return <ListaNotas key={nota.contapr_id} Item={nota} />;
                    })}
                  {loading && (
                    <>
                      <Skeleton />
                      <Skeleton />
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Nota;
