import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Row, Col } from 'react-bootstrap';
import { parseISO, format, startOfDay } from 'date-fns';
import { useEspecialidade } from '../../../hooks/especialidade';
import { useClinica } from '../../../hooks/clinica';
import { useToast } from '../../../hooks/toast';

import Loading from '../../../components/Loading';
import InputFake from '../../../components/InputFake';
import SelectSync, { IOption } from '../../../components/SelectSync';
import TextArea from '../../../components/TextArea';
import getValidationErrors from '../../../utils/getValidationErrors';
import Toggle from '../../../components/Exame/Toggle';
import api from '../../../services/api';
import { useProfissional } from '../../../hooks/profissional';
import DiasHorarios from './DiasHorarios';
import Quinzenal from './Quinzenal';
import { optionsDuracao } from '../../../utils/funcoes';
import DiasNaoAgendar from './DiasNaoAgendar';
import { IDadosLista } from '../ListaAgendas';
import getResponseErrors from '../../../utils/getResponseErrors';

interface IHorario {
  id?: number;
  agenda_id?: number;
  intervaloIni: string;
  intervaloFim: string;
  horaIni: string;
  horaFim: string;
  maxAgendamento?: number;
}

interface IHorarioRecorrente extends IHorario {
  dataIni: Date | string;
  diasRecorre: number | string;
}

interface IHorarios extends IHorario {
  dia: number | string;
  selecionado?: boolean;
}

export interface IConfiguracaoAgenda {
  id: number;
  especialidade_ids?: string;
  codsEspecialidade?: string[];
  especialidade_nomes?: string;
  profissional_id?: number;
  profissional: string;
  nome: string;
  tempoPadrao: number;
  obs: string;
  atende: boolean;
  enviaEmail: boolean;
  diasEmail: number;
  clinica_id?: number;
  semanal: number;
  pagamentoAntecipado: boolean;
  profissionalAgendaCompleta: boolean;
  ordenaPorPagamento: boolean;
  integracaoAlvaro: boolean;
  ativo: boolean;
  created_at: Date;
  updated_at: Date;
  horarios?: IHorarios[];
  horarioRecorrente?: IHorarioRecorrente;
}

type Props = {
  id?: number;
  labels?: IDadosLista;
  addAgenda(
    dadosAgenda: IConfiguracaoAgenda,
    dadosLista: IDadosLista,
    ageCodigo?: number,
  ): void;
};

const AgendasCadastroPagina: React.FC<Props> = ({ id, labels, addAgenda }) => {
  const { optionsEspecialidade, buscaEspecialidade } = useEspecialidade();
  const { optionsClinica } = useClinica();
  const { optionsProfissional, buscaOptionsProfissional } = useProfissional();
  const { addToast } = useToast();

  const [tipoAgendaSemanal, setTipoAgendaSemanal] = useState(true);
  const formRefConfiguracaoAgenda = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optionsEspecialidades, _] = useState<IOption[]>(
    optionsEspecialidade.optionEspecialidades,
  );
  const [agenda, setAgenda] = useState<IConfiguracaoAgenda | undefined>(
    undefined,
  );

  const optionsOrdenacao = [
    {
      label: 'Horário Agendado',
      value: 'false',
    },
    {
      label: 'Chegada / Pagamento',
      value: 'true',
    },
  ];

  const optionsVisualizacaoMedico = [
    {
      label: 'Vê todos agendamentos',
      value: 'true',
    },
    {
      label: 'Somente lista espera',
      value: 'false',
    },
  ];

  function recuperaHorarios(horarios: IHorarios[] | undefined): IHorarios[] {
    const dias = [1, 2, 3, 4, 5, 6, 7];
    const horariosFormatado = dias.map(dia => {
      const horariosDia = {
        dia: dia.toString(),
        horaFim: '',
        horaIni: '',
        intervaloFim: '',
        intervaloIni: '',
        selecionado: false,
        maxAgendamento: 0,
      };
      let buscaHorario = horarios?.find(horario => horario.dia === dia);
      if (buscaHorario)
        buscaHorario = {
          ...buscaHorario,
          selecionado: true,
        };
      return { ...horariosDia, ...buscaHorario };
    });
    return horariosFormatado;
  }

  useEffect(() => {
    async function loadPerfis(): Promise<void> {
      await buscaEspecialidade();
    }
    if (!optionsEspecialidade || Object.keys(optionsEspecialidade).length === 0)
      loadPerfis();
  }, [buscaEspecialidade, optionsEspecialidade]);

  useEffect(() => {
    async function loadProfissionais(): Promise<void> {
      await buscaOptionsProfissional();
    }
    if (!optionsProfissional || Object.keys(optionsProfissional).length === 0)
      loadProfissionais();
  }, [buscaOptionsProfissional, optionsProfissional]);

  useEffect(() => {
    async function loadDados(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get<IConfiguracaoAgenda>(
          `agendas/${id}/detalhe`,
        );

        const horarios = recuperaHorarios(response.data.horarios);
        const horarioRecorrente = response.data.horarioRecorrente && {
          ...response.data.horarioRecorrente,
          dataIni: format(
            parseISO(response.data.horarioRecorrente.dataIni.toString()),
            'yyyy-MM-dd',
          ),
          diasRecorre: response.data.horarioRecorrente.diasRecorre.toString(),
        };

        const profissional = optionsProfissional.find(
          med => med.value === response.data.profissional_id?.toString(),
        );

        setAgenda({
          ...response.data,
          profissional: profissional?.label || '',
          horarios,
          horarioRecorrente,
        });
      } catch (error) {
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Não foi possível carregar dados da agenda',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    }
    if (id) loadDados();
  }, [id, addToast, optionsProfissional]);

  useEffect(() => {
    if (agenda) {
      setTipoAgendaSemanal(agenda.semanal === 1);
    }
  }, [agenda]);

  const ehNovo = useMemo(() => {
    return !id || id === 0;
  }, [id]);

  const handleSubmitConfiguracao = useCallback(
    async (dados: IConfiguracaoAgenda) => {
      try {
        setLoading(true);
        let adic = {};
        const validaHorarios = {
          horarios: Yup.array().of(
            Yup.object().shape({
              selecionado: Yup.boolean(),
              intervaloIni: Yup.string(),
              intervaloFim: Yup.string(),
              horaIni: Yup.string().when('selecionado', {
                is: true,
                then: Yup.string().required('informe a hora inicial'),
              }),
              horaFim: Yup.string().when('selecionado', {
                is: true,
                then: Yup.string().required('informe a hora final'),
              }),
              maxAgendamento: Yup.string().when('selecionado', {
                is: true,
                then: Yup.string()
                  .min(1, 'selecione o número máximo de agendamentos')
                  .required('informe o número máximo de agendamentos'),
              }),
            }),
          ),
        };
        const validaHorarioRecorrente = {
          horarioRecorrente: Yup.object().shape({
            intervaloIni: Yup.string(),
            intervaloFim: Yup.string(),
            horaIni: Yup.string().required('informe a hora inicial'),
            horaFim: Yup.string().required('informe a hora final'),
            dataIni: Yup.date().required('informe a data inicial'),
            diasRecorre: Yup.number().required(),
            maxAgendamento: Yup.number().required(
              'informe o número máximo de agendamentos',
            ),
          }),
        };
        if (tipoAgendaSemanal) adic = { ...adic, ...validaHorarios };
        if (!tipoAgendaSemanal) adic = { ...adic, ...validaHorarioRecorrente };

        const cods = `${dados.codsEspecialidade}`;

        let especialidade = '';
        if (ehNovo) {
          if (!dados.codsEspecialidade) {
            formRefConfiguracaoAgenda.current?.setFieldError(
              'codsEspecialidade',
              'Selecione pelo menos uma especialidade',
            );
            addToast({
              title: 'Nenhuma especialidade selecionada',
              type: 'error',
              description: 'Selecione pelo menos uma especialidade',
            });
            return;
          }

          especialidade = dados.codsEspecialidade
            .map(
              cod =>
                optionsEspecialidades.find(per => per.value === cod.toString())
                  ?.label,
            )
            .join(' / ');
        }

        formRefConfiguracaoAgenda.current?.setErrors({});
        const schema = Yup.object().shape({
          ...(ehNovo ? { especialidade_ids: Yup.string().required() } : {}),
          ...(ehNovo ? { profissional_id: Yup.number().required() } : {}),
          ...(ehNovo ? { clinica_id: Yup.number().required() } : {}),
          tempoPadrao: Yup.number().required(),
          obs: Yup.string(),
          // diasEmail: Yup.number().required(),
          // semanal: Yup.number().required(),
          // ConIntegracao: Yup.number().required(),
          // ConMaxAgendamento: Yup.number().required(),
          ...adic,
        });

        // const pagamentoAntecipado = dados.pagamentoAntecipado
        //   ? dados.pagamentoAntecipado.toString()
        //   : 'false';
        const profissionalAgendaCompleta = dados.profissionalAgendaCompleta
          ? dados.profissionalAgendaCompleta.toString()
          : 'false';
        const ordenaPorPagamento = dados.ordenaPorPagamento
          ? dados.ordenaPorPagamento.toString()
          : 'false';
        const integracaoAlvaro = dados.integracaoAlvaro
          ? dados.integracaoAlvaro.toString()
          : 'false';
        const ativo = dados.ativo ? dados.ativo.toString() : 'false';
        const atende = dados.atende ? dados.atende.toString() : 'false';
        // const enviaEmail = dados.enviaEmail
        //   ? dados.enviaEmail.toString()
        //   : 'false';

        const configuracaoForm = {
          ...dados,
          obs: dados.obs.toUpperCase(),
          enviaEmail: false,
          pagamentoAntecipado: true,
          diasEmail: 1,
          profissional_id: dados.profissional_id,
          // enviaEmail: enviaEmail === 'true',
          // pagamentoAntecipado: pagamentoAntecipado === 'true',
          profissionalAgendaCompleta: profissionalAgendaCompleta === 'true',
          ordenaPorPagamento: ordenaPorPagamento === 'true',
          integracaoAlvaro: integracaoAlvaro === 'true',
          ativo: ativo === 'true',
          atende: atende === 'true',
          ...(ehNovo && {
            especialidade_ids: cods,
            // especialidade_ids: cods
            //   .split(',')
            //   .filter(it => it !== '')
            //   .map(item => Number(item)),
          }),
        };

        await schema.validate(configuracaoForm, {
          abortEarly: false,
        });

        //  const profissional = optionsProfissional.find(
        //    med => med.value === configuracaoForm.profissional_id?.toString(),
        //  );

        const clinica = optionsClinica.find(
          cli => cli.value === configuracaoForm.clinica_id?.toString(),
        );

        const horarios = configuracaoForm.horarios
          ?.filter(
            (hor: IHorarios) =>
              hor.selecionado && hor.selecionado.toString() === 'true',
          )
          .map(
            (hor: IHorarios): IHorarios => ({
              dia: hor.dia,
              horaFim: hor.horaFim,
              horaIni: hor.horaIni,
              intervaloFim: hor.intervaloFim,
              intervaloIni: hor.intervaloIni,
              maxAgendamento: hor.maxAgendamento,
            }),
          );

        const horarioRecorrenteFormatado = configuracaoForm.horarioRecorrente && {
          ...configuracaoForm.horarioRecorrente,
          dataIni: startOfDay(
            parseISO(configuracaoForm.horarioRecorrente.dataIni.toString()),
          ),
        };

        const dadosEnvio: IConfiguracaoAgenda = {
          ...configuracaoForm,
          horarios,
          horarioRecorrente: horarioRecorrenteFormatado,
          semanal: tipoAgendaSemanal ? 1 : 0,
          profissional_id: ehNovo
            ? configuracaoForm.profissional_id
            : undefined,
          clinica_id: ehNovo ? configuracaoForm.clinica_id : undefined,
          integracaoAlvaro: configuracaoForm.integracaoAlvaro,
        };

        delete dadosEnvio.codsEspecialidade;
        delete dadosEnvio.especialidade_nomes;

        if (ehNovo) {
          await addAgenda(dadosEnvio, {
            especialidade,
            clinica: clinica?.label,
          });
        } else {
          await addAgenda(
            dadosEnvio,
            {
              especialidade: labels?.especialidade,
              clinica: labels?.clinica,
            },
            id,
          );
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefConfiguracaoAgenda.current?.setErrors(errors);
          return;
        }
        const mensagemErro = getResponseErrors(error);
        addToast({
          title: 'Erro ao salvar procedimento',
          type: 'error',
          description: mensagemErro,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      addAgenda,
      ehNovo,
      optionsClinica,
      id,
      optionsEspecialidades,
      tipoAgendaSemanal,
      labels,
    ],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOnChangeVazio = useCallback(() => {}, []);

  // const diasAviso = useMemo((): IOption[] => {
  //   const horas = [];
  //   // eslint-disable-next-line no-plusplus
  //   for (let h = 1; h < 7; h++) {
  //     horas.push({ label: `${h}`, value: `${h}` });
  //   }
  //   return horas;
  // }, []);

  return (
    <div className="container">
      {loading && <Loading />}

      <Row noGutters>
        <Col md={12}>
          {(ehNovo || agenda) && (
            <>
              <Form
                ref={formRefConfiguracaoAgenda}
                initialData={{
                  ...agenda,
                  profissional_id: agenda?.profissional_id?.toString(),
                  // Conespecialidades_ids: agenda?.Conespecialidades_ids?.toString(),
                  clinica_id: agenda?.clinica_id?.toString(),
                  tempoPadrao: agenda?.tempoPadrao.toString(),
                  profissionalAgendaCompleta: agenda?.profissionalAgendaCompleta.toString(),
                  ordenaPorPagamento: agenda?.ordenaPorPagamento.toString(),
                  diasEmail: agenda?.diasEmail.toString(),
                  horarios: agenda
                    ? agenda.horarios
                    : recuperaHorarios(undefined),
                }}
                onSubmit={handleSubmitConfiguracao}
              >
                <Row>
                  <Col md={8} className="form-group pr-2">
                    {ehNovo ? (
                      <SelectSync
                        name="codsEspecialidade"
                        label="Especialidades"
                        placeholder="Selecione"
                        handleSelectChange={handleOnChangeVazio}
                        isMulti
                        options={optionsEspecialidades}
                        isDisabled={!ehNovo}
                        size="gr"
                      />
                    ) : (
                      <InputFake
                        label="Especialidade"
                        value={agenda?.especialidade_nomes}
                        disabled
                      />
                    )}
                  </Col>
                  <Col md={4} className="form-group pr-2">
                    {ehNovo ? (
                      <SelectSync
                        name="profissional_id"
                        label="Profissional"
                        placeholder="Selecione"
                        handleSelectChange={handleOnChangeVazio}
                        options={optionsProfissional}
                        isDisabled={!ehNovo}
                      />
                    ) : (
                      <InputFake
                        label="Profissional"
                        value={agenda?.profissional}
                        disabled
                      />
                    )}

                    <SelectSync
                      name="clinica_id"
                      label="Clínica"
                      placeholder="Selecione"
                      handleSelectChange={handleOnChangeVazio}
                      options={optionsClinica}
                      isDisabled={!ehNovo}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="button"
                      variant={tipoAgendaSemanal ? 'info' : 'outline-info'}
                      className="mt-2 mr-2"
                      onClick={() => setTipoAgendaSemanal(true)}
                    >
                      Semanal
                    </Button>

                    <Button
                      type="button"
                      variant={tipoAgendaSemanal ? 'outline-info' : 'info'}
                      className="mt-2"
                      onClick={() => setTipoAgendaSemanal(false)}
                    >
                      Recorrente
                    </Button>
                  </Col>
                </Row>
                <div className="card" style={{ padding: '0 16px' }}>
                  {tipoAgendaSemanal && <DiasHorarios />}
                  {!tipoAgendaSemanal && <Quinzenal />}
                </div>
                <Row className="pt-2">
                  <Col md={12} lg={12} className="form-group pr-2">
                    <TextArea name="obs" label="Observação" rows={3} />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col md={2} lg={2} className="form-group pr-2">
                    <SelectSync
                      name="tempoPadrao"
                      label="Tempo Agendamento"
                      placeholder="Selecione"
                      handleSelectChange={handleOnChangeVazio}
                      options={optionsDuracao}
                    />
                  </Col>
                  <Col
                    md={2}
                    lg={2}
                    className="form-group pr-2"
                    style={{ display: 'grid', textAlign: 'center' }}
                  >
                    <Toggle
                      nome="atende"
                      classSpam={{
                        paddingRight: '10px',
                        marginBottom: '7px',
                        fontWeight: 'bold',
                      }}
                      label="Atende Consultório"
                      isDisabled={false}
                    />
                  </Col>

                  <Col md={3} className="form-group pr-2">
                    <SelectSync
                      name="profissionalAgendaCompleta"
                      label="Visualização dos Agendamentos"
                      placeholder="Selecione"
                      handleSelectChange={handleOnChangeVazio}
                      options={optionsVisualizacaoMedico}
                    />
                  </Col>
                  <Col md={3} className="form-group pr-2">
                    <SelectSync
                      name="ordenaPorPagamento"
                      label="Ordenação Atendimento Por"
                      placeholder="Selecione"
                      handleSelectChange={handleOnChangeVazio}
                      options={optionsOrdenacao}
                    />
                  </Col>

                  {/* <Col
                    md={3}
                    className="form-group pr-2"
                    style={{ display: 'grid', textAlign: 'center' }}
                  >
                    <Toggle
                      nome="enviaEmail"
                      classSpam={{
                        paddingRight: '10px',
                        marginBottom: '7px',
                        fontWeight: 'bold',
                      }}
                      label="Confirmação Auto."
                      isDisabled
                    />
                  </Col>
                  <Col md={2} className="form-group pr-2">
                    <SelectSync
                      name="diasEmail"
                      label="Dias antes Consulta"
                      placeholder="dias"
                      handleSelectChange={handleOnChangeVazio}
                      options={diasAviso}
                      containerStyle={{ maxWidth: '140px' }}
                    />
                  </Col> */}
                  <Col
                    md={2}
                    className="form-group pr-2"
                    style={{ display: 'grid', textAlign: 'center' }}
                  >
                    <Toggle
                      nome="integracaoAlvaro"
                      label="Integracao Álvaro"
                      isDisabled={false}
                      classSpam={{
                        paddingRight: '10px',
                        marginBottom: '7px',
                        fontWeight: 'bold',
                      }}
                    />
                  </Col>
                  <Col
                    md={2}
                    className="form-group pr-2"
                    style={{ display: 'grid', textAlign: 'center' }}
                  >
                    <Toggle
                      nome="ativo"
                      label="Ativo"
                      isDisabled={false}
                      classSpam={{
                        paddingRight: '10px',
                        marginBottom: '7px',
                        fontWeight: 'bold',
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  {/* <Col
                    md={3}
                    className="form-group pr-2"
                    style={{ display: 'grid', textAlign: 'center' }}
                  >
                    <Toggle
                      nome="pagamentoAntecipado"
                      label="Permite Pagamento Antecipado?"
                      isDisabled={false}
                      classSpam={{
                        paddingRight: '10px',
                        marginBottom: '7px',
                        fontWeight: 'bold',
                      }}
                    />
                  </Col> */}

                  <Col md={12}>
                    <div className="text-right align-items-center p-1">
                      <>
                        <Button type="submit" variant="primary">
                          Salvar dados
                        </Button>
                      </>
                    </div>
                  </Col>
                </Row>
              </Form>
              {!ehNovo && <DiasNaoAgendar agenda_id={id || 0} />}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AgendasCadastroPagina;
