import React, { useRef, useEffect, useCallback } from 'react';
import { OptionTypeBase } from 'react-select';
import Select, { Props as AsyncProps } from 'react-select/async';
import { useField } from '@unform/core';
import debounce from 'debounce-promise';
import { FiAlertCircle } from 'react-icons/fi';

import { Error } from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getResponseErrors from '../../utils/getResponseErrors';

export interface ProviderProps {
  [key: string]: any;
}

interface IResponse {
  value: string;
  label: string;
}

interface Props extends AsyncProps<OptionTypeBase, boolean> {
  name: string;
  label: string;
  urlApi: string;
  size?: 'sm' | 'lg' | 'gr';
  handleSelectChange(data: IResponse): void;
}

function loading(): string {
  return 'Procurando...';
}

function semDados(): string {
  return 'Sem opções';
}

const AsyncSelect: React.FC<Props> = ({
  name,
  label,
  urlApi,
  size = 'sm',
  handleSelectChange,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const { addToast } = useToast();
  const options = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }

      try {
        const normalizedtext = inputValue
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const response = await api.get<IResponse[]>(
          `${urlApi}${normalizedtext}`,
        );

        return response.data;
      } catch (err) {
        const mensagemErro = getResponseErrors(err);
        addToast({
          title: 'Não foi possível carregar options',
          type: 'error',
          description: mensagemErro,
        });
        return [];
      }
    },
    [urlApi, addToast],
  );

  const promiseOptionsValueDebounced = debounce(options, 1000);

  function tamanho(sz: string): number {
    switch (sz) {
      case 'sm':
        return 31;
      case 'gr':
        return 60;
      default:
        return 38;
    }
  }
  const customStyles = {
    control: (provided: ProviderProps) => ({
      ...provided,
      borderWidth: '1px',
      borderRadius: '16px',
      height: tamanho(size),
      minHeight: tamanho(size),
      alignContent: 'center',
      ...(error ? { borderColor: 'red' } : {}),
    }),
    input: (provided: ProviderProps) => ({
      ...provided,

      width: '100%',
    }),
    singleValue: (provided: ProviderProps) => ({
      ...provided,
      fontSize: size === 'sm' ? '.8rem' : '.9rem',
      width: '100%',
    }),
    valueContainer: (provided: ProviderProps) => ({
      ...provided,
      paddingTop: 0,
      paddingBotton: 0,
      width: '100%',
    }),
    option: (provided: ProviderProps) => ({
      ...provided,
      padding: 10,
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleOnChange = useCallback(
    (selected: OptionTypeBase | null) => {
      if (selected) {
        handleSelectChange({ value: selected?.value, label: selected?.label });
      }
    },
    [handleSelectChange],
  );

  return (
    <>
      {label && (
        <span
          style={{ marginBottom: '4px', display: 'block', fontWeight: 'bold' }}
        >
          {label}
        </span>
      )}
      <Select
        cacheOptions
        defaultValue={defaultValue}
        loadingMessage={loading}
        noOptionsMessage={semDados}
        onChange={handleOnChange}
        styles={customStyles}
        ref={selectRef}
        classNamePrefix="react-select"
        loadOptions={inputValue => promiseOptionsValueDebounced(inputValue)}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </>
  );
};

export default AsyncSelect;
